import { CalendarToday } from '@mui/icons-material';
import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import Blocked from '../../assets/images/svg/Blocked.svg';
import LeftArrow from '../../assets/images/svg/left-arrow.svg';
import StackOfCoins from '../../assets/images/svg/StackofCoins.svg';
import TotalSales from '../../assets/images/svg/TotalSales.svg';
import UpDown from '../../assets/images/svg/Up-Down.svg';
import Logs from './Logs';
import TransactionHistoryList from './TransactionHistoryList';

const status = [
  {
    value: 'date',
    label: 'This Month',
  },
  {
    value: 'month',
    label: 'This Year',
  },
];

const IconWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary[200],
  borderRadius: '10px',
  width: '52px',
  height: '52px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledMainCard = styled(MainCard)(({ theme }) => ({
  height: '200px',
  border: `1px solid ${theme.palette.divider}`,
}));

const StyledCallCard = styled(MainCard)(({ theme }) => ({
  height: '430px',
  border: `1px solid ${theme.palette.divider}`,
}));

const DateRangeButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#fff',
  color: '#000',
  border: '1px solid rgba(0, 0, 0, 0.1)',
  borderRadius: '8px',
  padding: '8px 16px',
  textTransform: 'none',
  fontWeight: 400,
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
}));
const BackButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#fff',
  color: '#000',
  borderRadius: '50%',
  textTransform: 'none',
  fontWeight: 400,
  padding: '4px',
  minWidth: '32px',
  height: '32px',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
}));
const UsersDetails = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <>
      <Stack
        direction="row"
        alignItems={'baseline'}
        justifyContent={'space-between'}
        flexDirection={'row'}
        mb={2}
      >
        <Box display="flex" alignItems="baseline">
          <BackButton
            direction="row"
            alignItems="center"
            onClick={() => navigate(-1)}
          >
            <Box component={'img'} src={LeftArrow} />
          </BackButton>
          <Stack mr={4}>
            <Typography variant="h3">Kolomoni</Typography>
            <Typography variant="h5">ade.adebayo@kolomoni.org</Typography>
            <Typography variant="h5">Registered on: Apr 02 , 2024 </Typography>
            <Typography variant="h5" color={theme.palette.secondary.dark}>
              Direct debit payment method : 0.4% + 100 (click to change)
            </Typography>
          </Stack>
        </Box>
        <Stack>
          <DateRangeButton
            startIcon={<CalendarToday sx={{ fontSize: 20 }} />}
            onClick={() => console.log('Select date range')}
          >
            Select Date Range
          </DateRangeButton>
        </Stack>
      </Stack>
      <Box>
        <Grid container spacing={3}>
          {/* Left side - Two big cards */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <StyledCallCard>
                  <Stack direction="column" spacing={3}>
                    <IconWrapper>
                      <Box component={'img'} src={UpDown} />
                    </IconWrapper>
                    <Stack spacing={1}>
                      <Typography variant="h3">52</Typography>
                      <Box>
                        <Typography
                          variant="body1"
                          fontSize={18}
                          marginBottom={3}
                        >
                          Total Calls
                        </Typography>
                      </Box>
                    </Stack>
                    <Divider mt={3} />
                    <Stack>
                      <Typography variant="body2" fontSize={18}>
                        Credit Assessment: 35
                      </Typography>
                      <Typography variant="body2" fontSize={18}>
                        BVN: 7
                      </Typography>
                      <Typography variant="body2" fontSize={18}>
                        Direct Debit: 10
                      </Typography>
                    </Stack>
                  </Stack>
                </StyledCallCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledCallCard>
                  <Stack direction="column" spacing={3}>
                    <IconWrapper>
                      <Box component={'img'} src={StackOfCoins} />
                    </IconWrapper>
                    <Stack spacing={1}>
                      <Typography variant="h3">32</Typography>
                      <Typography variant="body1" fontSize={18}>
                        Active Units
                      </Typography>
                      <Typography variant="body1" textAlign={'end'}>
                        Last updated 03/01/2024
                      </Typography>
                    </Stack>
                    <Divider mt={3} />
                    <Stack>
                      <Typography variant="body2" fontSize={18}>
                        Credit Assessment: 15
                      </Typography>
                      <Typography variant="body2" fontSize={18}>
                        BVN: 7
                      </Typography>
                      <Typography variant="body2" fontSize={18}>
                        Direct Debit: 10
                      </Typography>
                    </Stack>
                  </Stack>
                </StyledCallCard>
              </Grid>
            </Grid>
          </Grid>

          {/* Right side - Four small cards */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <StyledMainCard>
                  <Stack direction="column" spacing={3}>
                    <IconWrapper>
                      <Box component={'img'} src={TotalSales} />
                    </IconWrapper>
                    <Stack spacing={1}>
                      <Typography variant="h3">20</Typography>
                      <Typography variant="body1" fontSize={18}>
                        Units Used
                      </Typography>
                    </Stack>
                  </Stack>
                </StyledMainCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledMainCard>
                  <Stack direction="column" spacing={3}>
                    <IconWrapper>
                      <Box component={'img'} src={Blocked} />
                    </IconWrapper>
                    <Stack spacing={1}>
                      <Typography variant="h3">0</Typography>
                      <Typography variant="body1" fontSize={18}>
                        Blocked Requests
                      </Typography>
                    </Stack>
                  </Stack>
                </StyledMainCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledMainCard>
                  <Stack direction="column">
                    <IconWrapper>
                      <Box component={'img'} src={TotalSales} />
                    </IconWrapper>
                    <Stack spacing={1}>
                      <Typography variant="h3">52</Typography>
                      <Typography variant="body1" fontSize={18}>
                        Successful Requests
                      </Typography>
                    </Stack>
                  </Stack>
                </StyledMainCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledMainCard>
                  <Stack direction="column">
                    <IconWrapper>
                      <Box component={'img'} src={TotalSales} />
                    </IconWrapper>
                    <Stack spacing={1}>
                      <Typography variant="h3">0</Typography>
                      <Typography variant="body1" fontSize={18}>
                        Failed Requests
                      </Typography>
                    </Stack>
                  </Stack>
                </StyledMainCard>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Logs />
      <TransactionHistoryList />
    </>
  );
};

export default UsersDetails;
