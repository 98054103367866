import { lazy } from "react";

// project imports
import MinimalLayout from "layout/MinimalLayout";
import NavMotion from "layout/NavMotion";
import { Navigate } from 'react-router-dom';
import Loadable from 'ui-component/Loadable';
import ConfomationVerification from 'views/authentication/auth/ConformationEmail';

// login routing
const AuthLogin = Loadable(
  lazy(() => import("views/authentication/auth/Login"))
);


// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  element: (
    <NavMotion>
      <MinimalLayout />
    </NavMotion>
  ),
  children: [
    {
      path: '/',
      element: <Navigate to="/login" replace />, // Redirect from root to /login
    },
    {
      path: '/login',
      element: <AuthLogin />,
    },
    {
      path: '/activateBusinessPartnerUsers/:token',
      element: <ConfomationVerification />,
    },
  ],
};

export default LoginRoutes;
