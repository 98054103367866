// material-ui
import { Avatar, Box, Menu, MenuItem, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";

// project imports
import { useDispatch, useSelector } from "store";
import { openDrawer } from "store/slices/menu";
import LogoSection from "../LogoSection";

// assets
import { IconLock, IconLogout, IconMenu2 } from '@tabler/icons-react';
import { useNavigate } from "react-router-dom";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePassword = () => {
    navigate("/confirm-change-password");
    handleClose();
  };

  const handleLogout = () => {
    navigate("/login");
    handleClose();
  };

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#ff6600",
            cursor: "pointer",
          }}
          onClick={() => dispatch(openDrawer(!drawerOpen))}
        >
          <Box
            component="span"
            sx={{
              display: { xs: "none", md: "block", marginRight: "45px" },
              flexGrow: 1,
            }}
          >
            <LogoSection />
          </Box>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              overflow: "hidden",
              transition: "all .2s ease-in-out",
              background:
                theme.palette.mode === "dark"
                  ? theme.palette.dark.main
                  : theme.palette.secondary.light,
              color:
                theme.palette.mode === "dark"
                  ? theme.palette.secondary.main
                  : theme.palette.secondary.dark,
              "&:hover": {
                background:
                  theme.palette.mode === "dark"
                    ? theme.palette.secondary.main
                    : theme.palette.secondary.dark,
                color:
                  theme.palette.mode === "dark"
                    ? theme.palette.secondary.light
                    : theme.palette.secondary.light,
              },
            }}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="20px" />
          </Avatar>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#ff6600",
            cursor: "pointer",
          }}
          onClick={handleClick}
        >
          <Typography
            variant="subtitle1"
            sx={{ marginRight: "10px", color: "#ff6600" }}
          >
            My Account
          </Typography>
        </Box>
      </Box>

      {/* menu dropdown */}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleChangePassword}>
          <IconLock
            className="uil uil-lock-alt font-size-18 align-middle me-1 text-muted"
            style={{ marginRight: "8px" }}
          />
          Change Password
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleLogout();
          }}
        >
          <IconLogout
            className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"
            style={{ marginRight: "8px" }}
          />
          Log Out
        </MenuItem>
      </Menu>
    </>
  );
};

export default Header;
