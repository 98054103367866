// third-party
import { FormattedMessage } from "react-intl";

// assets
import { IconReportAnalytics } from '@tabler/icons-react';
const icons = {
  IconReportAnalytics,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const reports = {
  id: 'reports',
  type: 'group',
  children: [
    {
      id: 'reports',
      title: <FormattedMessage id="reports" />,
      type: 'item',
      url: '/reports',
      icon: icons.IconReportAnalytics,
      breadcrumbs: false,
    },
  ],
};

export default reports;
