import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
// import { toast } from 'react-toastify';
import getAxiosInstance from 'api';
import toast from 'react-hot-toast';
import { Alert, Col, Container, Row } from 'reactstrap';
import logoActive from '../../../../src/logo-active.png';


const ConfomationVerification = (props) => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertStatus, setAlertStatus] = useState('');
  const [visible, setAlert] = useState(false);

  useEffect(() => {
    document.body.className = 'authentication-bg';
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = '';
    };
  });

  useEffect(() => {
    setLoader(true);
    buyPlan(1);
  }, []);

  const buyPlan = (id) => {
    getAxiosInstance(
      `businessPartner/activateBusinessPartnerUsers/${token}`,
      'put',
      {
        planId: id,
      }
    )
      .then((res) => {
        if (res.data.codes === 200) {
          setAlertMessage(res.data.message);
          setAlertStatus('success');
          setAlert(true);
          localStorage.removeItem('userId');
          setTimeout(() => {
            navigate('/registration-success');
          }, 2000);
        }
        if (res.data.codes === 400) {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setAlertMessage('Activation Link Expired');
        setAlertStatus('danger');
        setAlert(true);
      });
  };

  return (
    <React.Fragment>
      <div className="pt-sm-5">
        <Container>
          <Row>
            <div className="col-12 text-center">
              <div className="home-wrapper">
                <Link to="/" className="mb-5 d-block auth-logo">
                  <img
                    src={logoActive}
                    alt=""
                    height="50"
                    className="logo logo-dark"
                  />
                </Link>

                <Row className="justify-content-md-center">
                  <Col lg={6}>
                    <Alert
                      color={alertStatus}
                      isOpen={visible}
                      toggle={() => setAlert(false)}
                      fade={false}
                    >
                      {alertMessage}
                    </Alert>
                  </Col>
                </Row>
                <Row>
                  {loader && (
                    <div id="preloader" className="position-relative">
                      <div id="status">
                        <div className="spinner">
                          <i className="uil-shutter-alt spin-icon"></i>
                        </div>
                      </div>
                    </div>
                  )}
                </Row>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ConfomationVerification.propTypes = {
  history: PropTypes.object,
};

export default ConfomationVerification;