import FilterListIcon from '@mui/icons-material/FilterList';
import { Autocomplete, Box, Button, Card, InputAdornment, Menu, MenuItem, OutlinedInput, Stack, styled, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Typography, useTheme } from '@mui/material';
import { shouldForwardProp } from '@mui/system';
import { visuallyHidden } from '@mui/utils';
import { IconSearch } from '@tabler/icons-react';
import LogoSection from 'layout/MainLayout/LogoSection';
import { useState } from 'react';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  border: '1px solid #DEDEDE',
  color: '#212120',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '500',
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(
  ({ theme }) => ({
    width: 350,
    marginLeft: 16,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: '8px',
    border: '1px solid rgba(33, 33, 32, 0.10)',
    background: '#FFFFFF',

    '& input': {
      background: 'transparent !important',
      paddingLeft: '4px !important',
    },
    [theme.breakpoints.down('lg')]: {
      width: 250,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginLeft: 4,
      background:
        theme.palette.mode === 'dark' ? theme.palette.dark[800] : '#fff',
    },
  })
);
const headCells = [
  {
    id: 'no',
    numeric: false,
    disablePadding: false,
    label: 'Product',
  },
  {
    id: 'id',
    numeric: true,
    disablePadding: false,
    label: 'Called at',
  }, 
];
const ProductType = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Direct Debit',
    value: 'mandate',
  },
  {
    label: 'BVN',
    value: 'bvn',
  },
  {
    label: 'Credit Risk',
    value: 'consent',
  },
];

function EnhancedTableHead({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const StyledTableCellHead = styled(TableCell)(({ theme }) => ({
    backgroundColor: '#F0F0F0',
    color: 'rgba(33, 33, 32, 0.60)',
    border: '1px solid #DEDEDE',
  }));

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCellHead
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : undefined}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCellHead>
        ))}
      </TableRow>
    </TableHead>
  );
}
const TransactionHistoryList = () => {
    const theme = useTheme()
    const [order, setOrder] = useState('DESC');
    const [orderBy, setOrderBy] = useState('id');
    const [selected, setSelected] = useState([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [dense] = useState(false);
    const [limit, setLimit] = useState(5);
    const [anchorEl, setAnchorEl] = useState(null);
    const [apiList, setApiList] = useState([]);
    const [total, setTotal] = useState();
    const [isFilter, setIsFilter] = useState(true);
    const [selectedLogType, setSelectedLogType] = useState(ProductType[0]);
    
    
      const handleMenuClick = (event) => {
        setAnchorEl(event?.currentTarget);
      };
        const handleClose = () => {
          setAnchorEl(null);
        };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'ASC';
    setOrder(isAsc ? 'DESC' : 'ASC');
    setOrderBy(property);
  };
    const isSelected = () => {};
    const handleClick = (event, name) => {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
    };
      const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };

      const handleChangeRowsPerPage = (event) => {
        setLimit(event.target.value);
      };
  return (
    <>
      <Stack
        direction={'row'}
        flexWrap={'wrap'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography variant="h3">Transaction History</Typography>

        <Stack
          direction={'row'}
          flexWrap={'wrap'}
          justifyContent={'space-between'}
          alignItems={'center'}
          gap={2}
        >
          <Autocomplete
            id="log-type-autocomplete"
            options={ProductType}
            getOptionLabel={(option) => option.label}
             value={selectedLogType || ''}
            //  onChange={(event, newValue) => {
            //    setSelectedLogType(newValue || logType[0]);
            //  }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
            
          <OutlineInputStyle
            id="input-search-header"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search"
            startAdornment={
              <InputAdornment position="start">
                <IconSearch
                  stroke={1.5}
                  size="16px"
                  color={theme.palette.grey[500]}
                />
              </InputAdornment>
            }
            aria-describedby="search-helper-text"
            inputProps={{ 'aria-label': 'weight' }}
          />
        </Stack>
      </Stack>

      <Card sx={{ mt: 2 }}>
        <>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}

          {/* table */}
          <TableContainer>
            <Table
              sx={{
                minWidth: 750,
                backgroundColor: 'white',
              }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                // onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={total}
              />
              <TableBody>
                {apiList.length ? (
                  <>
                    {apiList.map((row, index) => {
                      if (typeof row === 'number') return null;
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        ></TableRow>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* table pagination */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      </Card>
    </>
  );
};

export default TransactionHistoryList;