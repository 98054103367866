import { Box, CircularProgress, styled, Typography } from '@mui/material';
import React from 'react';

const ProgressValue = styled(Typography)(({ theme }) => ({
  fontSize: '32px',
  fontWeight: 500,
  color: '#212120',
}));

const ProgressWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const CenteredBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  textAlign: 'center',
}));

const CircularProgressBar = ({
  width,
  size,
  title,
  value,
  progress,
  color,
  remainingColor,
  header,
}) => {
  return (
    <>
      <Typography
        sx={{ fontSize: '16px', color: '#212120', marginBottom: '10px' }}
      >
        {header}
      </Typography>
      <ProgressWrapper>
        <CircularProgress
          variant="determinate"
          value={width}
          size={size}
          thickness={2}
          sx={{
            color: '#FFA48F',
            position: 'absolute',
          }}
        />
        <CircularProgress
          variant="determinate"
          value={progress}
          size={180}
          thickness={2}
          sx={{
            color: '#FC5719',
            '& .MuiCircularProgress-circle': {
              strokeLinecap: 'round',
            },
            '& .MuiCircularProgress-root': {
              transform: 'rotate(-90deg)',
            },
          }}
        />
        <CenteredBox>
          <Typography sx={{ fontSize: '16px', color: '#888' }}>
            {title}
          </Typography>
          <ProgressValue>{value}</ProgressValue>
        </CenteredBox>
      </ProgressWrapper>
    </>
  );
};

export default CircularProgressBar;