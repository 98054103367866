import {
  Autocomplete,
  Box,
  Button,
  Card,
  CircularProgress,
  InputAdornment,
  OutlinedInput,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { IconSearch } from '@tabler/icons-react';
import getAxiosInstance from 'api';
import moment from 'moment';
import { useEffect, useState, useCallback } from 'react';
import toast from 'react-hot-toast';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  border: '1px solid #DEDEDE',
  color: '#212120',
  fontSize: '14px',
  fontWeight: '500',
}));

const DateRangeButton = styled(Button)(() => ({
  backgroundColor: '#fff',
  color: '#000',
  border: '1px solid rgba(0, 0, 0, 0.1)',
  borderRadius: '8px',
  padding: '8px 16px',
  textTransform: 'none',
  fontWeight: 400,
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
}));

const OutlineInputStyle = styled(OutlinedInput)(() => ({
  width: 350,
  marginLeft: 16,
  paddingLeft: 16,
  paddingRight: 16,
  borderRadius: '8px',
  border: '1px solid rgba(33, 33, 32, 0.10)',
  background: '#FFFFFF',
  '& input': {
    background: 'transparent !important',
    paddingLeft: '4px !important',
  },
}));

const headCells = [
  { id: 'email', label: 'Email Address' },
  { id: 'name', label: 'Name' },
  { id: 'partnertype', label: 'Type' },
  { id: 'createdAt', label: 'Date' },
];

const type = [
  { label: 'All', value: '' },
  { label: 'Online', value: 'Online' },
  { label: 'API', value: 'API' },
];

const EnhancedTableHead = ({ order, orderBy, onRequestSort }) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const HistoryList = () => {
  const theme = useTheme();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('createdAt');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState({
    type: type[0],
  });
  const [calls, setCalls] = useState([]);
  const [total, setTotal] = useState(0);
  const [selectedDate, setSelectedDate] = useState([
    moment().subtract(1, 'months').toDate(),
    moment().toDate(),
  ]);
  const [loading, setLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);

  const fetchData = useCallback(() => {
    setLoading(true);
    getAxiosInstance(
      `adminOrchestrator/dashboard/getBusinessPartnersByDateRange`,
      'post',
      {
        startDate: moment(selectedDate[0]).format('MM-DD-YYYY'),
        endDate: moment(selectedDate[1]).format('MM-DD-YYYY'),
        page: page + 1,
        limit,
        search,
        filter: {
          partnerType:
            (filter.type?.value !== '' && filter.type?.value) || type[0].value,
        },
      },
      { 'journey-type': 'Dashboard' }
    )
      .then((res) => {
        if (res?.data?.codes === 200) {
          setCalls(res?.data?.data?.items || []);
          setTotal(res?.data?.data?.total || 0);
        } else {
          setCalls([]);
          setTotal(0);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || 'Error fetching data');
      })
      .finally(() => setLoading(false));
  }, [selectedDate, page, limit, search, filter]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilterChange = (key, newValue) => {
    setFilterLoading(true);
    setFilter({ ...filter, [key]: newValue || type[0] });
    setTimeout(() => {
      setFilterLoading(false);
    }, 300);
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h3">Logs</Typography>
        <Stack direction="row" spacing={2} alignItems="center">
          <Autocomplete
            options={type}
            getOptionLabel={(option) => option.label}
            value={filter.type}
            onChange={(event, newValue) => handleFilterChange('type', newValue)}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
            loading={filterLoading}
          />
          <DateRangePicker
            appearance="default"
            placeholder="Select Date Range"
            value={selectedDate}
            onChange={(value) => setSelectedDate(value)}
            showOneCalendar
          />
          <OutlineInputStyle
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search"
            startAdornment={
              <InputAdornment position="start">
                <IconSearch
                  stroke={1.5}
                  size="16px"
                  color={theme.palette.grey[500]}
                />
              </InputAdornment>
            }
          />
        </Stack>
      </Stack>

      <Card sx={{ mt: 2 }}>
        {loading ? (
          <Box
            sx={{ display: 'flex', justifyContent: 'center', height: '100px' }}
          >
            <CircularProgress color="secondary" />
          </Box>
        ) : (
          <>
            <TableContainer>
              <Table sx={{ minWidth: 750 }} size="medium">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {calls.length ? (
                    calls.map((row, index) => (
                      <TableRow hover key={index}>
                        <StyledTableCell>{row?.emailAddress}</StyledTableCell>
                        <StyledTableCell align="left">
                          {row?.companyName}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row?.partnerType}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {moment(row?.createdAt).format(
                            'MMM DD, YYYY hh:mm A'
                          )}
                        </StyledTableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No records found.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={total}
              rowsPerPage={limit}
              page={page}
              onPageChange={(e, newPage) => setPage(newPage)}
              onRowsPerPageChange={(e) =>
                setLimit(parseInt(e.target.value, 10))
              }
            />
          </>
        )}
      </Card>
    </>
  );
};

export default HistoryList;
