import { FormattedMessage } from 'react-intl';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
// assets
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import { IconReportAnalytics } from '@tabler/icons-react';
const icons = {
  AccountBalanceWalletIcon,
  IconReportAnalytics,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const settlementAccount = {
  id: 'settlement-account',
  type: 'group',
  children: [
    {
      id: 'settlement-account',
      title: <FormattedMessage id="Settlement Account" />,
      type: 'item',
      url: '/settlement-account',
      icon: icons.AccountBalanceWalletIcon,
      breadcrumbs: false,
    },
    {
      id: 'Calls',
      title: <FormattedMessage id="Calls" />,
      type: 'item',
      url: '/calls',
      icon: icons.IconReportAnalytics,
      breadcrumbs: false,
    },
  ],
};

export default settlementAccount;
