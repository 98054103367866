import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';

// Material-UI & Emotion
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

// Third-party
import rtlPlugin from 'stylis-plugin-rtl';
import useConfig from 'hooks/useConfig';

const RTLLayout = ({ children }) => {
  const { rtlLayout } = useConfig();

  // ✅ Ensure document direction updates only when rtlLayout changes
  useEffect(() => {
    document.dir = rtlLayout ? 'rtl' : 'ltr';
  }, [rtlLayout]);

  // ✅ Prevent multiple re-creations of cache (Fix for React 18)
  const cacheRtl = useMemo(() => {
    return createCache({
      key: rtlLayout ? 'mui-rtl' : 'mui',
      prepend: true,
      stylisPlugins: rtlLayout ? [rtlPlugin] : [],
    });
  }, [rtlLayout]);

  return <CacheProvider value={cacheRtl}>{children}</CacheProvider>;
};

RTLLayout.propTypes = {
  children: PropTypes.node,
};

export default RTLLayout;
