import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

export default function SessionInactivePopup({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Session Expired</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your session has expired. Please log in again.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          style={{ backgroundColor: '#ff6600', color: 'white' }}
          variant="contained"
          onClick={onClose}
        >
          Login Again
        </Button>
      </DialogActions>
    </Dialog>
  );
}
