import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
  const accessToken = JSON.parse(localStorage.getItem('userData'))?.accessToken;
  const navigate = useNavigate();

  useEffect(() => {
    if (!accessToken) {
      navigate('login', { replace: true });
    }
  }, [accessToken, navigate]);

  return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
