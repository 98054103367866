import { Box, Grid, MenuItem, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import ReactJson from 'react18-json-view';
import 'react18-json-view/src/style.css';
import MainCard from "ui-component/cards/MainCard";
const status = [
  // {
  //   value: 'date',
  //   label: 'Last 7 Days',
  // },
  {
    value: "date",
    label: "This Month",
  },
  {
    value: "month",
    label: "This Year",
  },
];
const SampleResponse = () => {
  const [bankName, setBankName] = useState([]);
  const [selectedBank, setSelectedBank] = useState("WEMA");
  useEffect(() => {
    const bankName = [
      {
        label: "WEMA",
        value: "WEMA",
        data: {
        data: [
              {
                  "title": "Fund Prepaid Card",
                  "amount": 2000,
                  "type": "Default",
                  "date": "2022-09-08T15:58:55.147",
                  "narration": "Fund Prepaid Card 425405419924",
                  "status": "Successfull",
                  "creditType": "Debit",
                  "sender": "AYA PRINCE OGHENEVWEDE",
                  "senderAccountNumber": "0122926565",
                  "destinationBank": "WEMA/ALAT",
                  "destinationAccountNumber": "0254713387",
                  "recieverName": "PRINCE AYA",
                  "referenceId": "425405419924",
                  "isViewReceiptEnabled": true,
                  "tranId": " S3279409"
              },
              {
                  "title": "Goal Created:TEST",
                  "amount": 500,
                  "type": "Default",
                  "date": "2022-09-07T00:00:00",
                  "narration": "Goal Created:TEST",
                  "status": "Default",
                  "creditType": "Debit",
                  "referenceId": "",
                  "isViewReceiptEnabled": false,
                  "tranId": "      M41"
              },
              {
                  "title": "Goal topup:TEST",
                  "amount": 3,
                  "type": "Default",
                  "date": "2022-09-07T00:00:00",
                  "narration": "Goal topup:TEST",
                  "status": "Default",
                  "creditType": "Debit",
                  "referenceId": "",
                  "isViewReceiptEnabled": false,
                  "tranId": "      M43"
              },
              {
                  "title": "Goal Created:FLEXI",
                  "amount": 1000,
                  "type": "Default",
                  "date": "2022-09-07T00:00:00",
                  "narration": "Goal Created:FLEXI",
                  "status": "Default",
                  "creditType": "Debit",
                  "referenceId": "",
                  "isViewReceiptEnabled": false,
                  "tranId": "      M45"
              },
              {
                  "title": "Goal Created:SPEND AND SAVE",
                  "amount": 2000,
                  "type": "Default",
                  "date": "2022-09-07T00:00:00",
                  "narration": "Goal Created:SPEND AND SAVE",
                  "status": "Default",
                  "creditType": "Debit",
                  "referenceId": "",
                  "isViewReceiptEnabled": false,
                  "tranId": "   M12256"
              },
              {
                  "title": "ALAT TRANSFER FROM MICHEAL OLAGBEM MICHEAL OLAGBE",
                  "amount": 50000,
                  "type": "Default",
                  "date": "2022-09-07T00:00:00",
                  "narration": "ALAT TRANSFER FROM MICHEAL OLAGBEM MICHEAL OLAGBE",
                  "status": "Default",
                  "creditType": "Credit",
                  "referenceId": "335977883501",
                  "isViewReceiptEnabled": false,
                  "tranId": " S3279710"
              }
          ],
          "status": true,
          "balance" : 50000
        },
      },
      {
        label: "Providus",
        value: "Providus",
        data: {
            "responseCode": "00",
            "responseDescription": "Generation successful",
            "oTransList": [
              {
                "valDate": "2025/Feb/12",
                "intDate": "2025/Feb/12",
                "traAmt": "2000",
                "curCode": "NGN",
                "remarks": "FROM STERLING/ JOSEPH ABISOLA OJO-Transfer from JOSEPH ABISOLA OJO to Ojo Joseph Abisola/000001250212150611808335001140",
                "drCrDesc": "C",
                "balAfter": "2195.86",
                "docNum": "908676",
                "traDate": "2025/Feb/12",
                "explEng": "INWARD TRANSFER (N)"
              },
              {
                "valDate": "2025/Feb/12",
                "intDate": "2025/Feb/12",
                "traAmt": "450",
                "curCode": "NGN",
                "remarks": "Income for Statement Generation on 6506200432 by e-doc",
                "drCrDesc": "D",
                "balAfter": "195.86",
                "docNum": "0",
                "traDate": "2025/Feb/12",
                "explEng": "COLLECTION"
              },
              {
                "valDate": "2025/Feb/12",
                "intDate": "2025/Feb/12",
                "traAmt": "1050",
                "curCode": "NGN",
                "remarks": "Charge for Statement Generation on 6506200432 by e-doc",
                "drCrDesc": "D",
                "balAfter": "645.86",
                "docNum": "0",
                "traDate": "2025/Feb/12",
                "explEng": "COLLECTION"
              },
              {
                "valDate": "2025/Feb/12",
                "intDate": "2025/Feb/12",
                "traAmt": "1000",
                "curCode": "NGN",
                "remarks": "FROM STERLING/ JOSEPH ABISOLA OJO-Transfer from JOSEPH ABISOLA OJO to Ojo Joseph Abisola/000001250212113858371625554940",
                "drCrDesc": "C",
                "balAfter": "1695.86",
                "docNum": "511125",
                "traDate": "2025/Feb/12",
                "explEng": "INWARD TRANSFER (N)"
              },
              {
                "valDate": "2025/Feb/12",
                "intDate": "2025/Feb/12",
                "traAmt": "450",
                "curCode": "NGN",
                "remarks": "Income for Statement Generation on 6506200432 by e-doc",
                "drCrDesc": "D",
                "balAfter": "695.86",
                "docNum": "0",
                "traDate": "2025/Feb/12",
                "explEng": "COLLECTION"
              },
              {
                "valDate": "2025/Feb/12",
                "intDate": "2025/Feb/12",
                "traAmt": "1050",
                "curCode": "NGN",
                "remarks": "Charge for Statement Generation on 6506200432 by e-doc",
                "drCrDesc": "D",
                "balAfter": "1145.86",
                "docNum": "0",
                "traDate": "2025/Feb/12",
                "explEng": "COLLECTION"
              },
              {
                "valDate": "2025/Feb/12",
                "intDate": "2025/Feb/12",
                "traAmt": "2000",
                "curCode": "NGN",
                "remarks": "FROM STERLING/ JOSEPH ABISOLA OJO-Transfer from JOSEPH ABISOLA OJO to Ojo Joseph Abisola/000001250212112637882522250428",
                "drCrDesc": "C",
                "balAfter": "2195.86",
                "docNum": "491995",
                "traDate": "2025/Feb/12",
                "explEng": "INWARD TRANSFER (N)"
              },
              {
                "valDate": "2025/Feb/12",
                "intDate": "2025/Feb/12",
                "traAmt": "0.75",
                "curCode": "NGN",
                "remarks": "VAT ON FEE:MOB: To Access Bank|UWUMAROGIE JULIET ./20250212103621780440055686817",
                "drCrDesc": "D",
                "balAfter": "195.86",
                "docNum": "0",
                "traDate": "2025/Feb/12",
                "explEng": "VAT"
              },
              {
                "valDate": "2025/Feb/12",
                "intDate": "2025/Feb/12",
                "traAmt": "10",
                "curCode": "NGN",
                "remarks": "FEE:MOB: To Access Bank|UWUMAROGIE JULIET ./20250212103621780440055686817",
                "drCrDesc": "D",
                "balAfter": "196.61",
                "docNum": "0",
                "traDate": "2025/Feb/12",
                "explEng": "COMMISSION"
              },
              {
                "valDate": "2025/Feb/12",
                "intDate": "2025/Feb/12",
                "traAmt": "2000",
                "curCode": "NGN",
                "remarks": "MOB: To Access Bank|UWUMAROGIE JULIET ./20250212103621780440055686817",
                "drCrDesc": "D",
                "balAfter": "206.61",
                "docNum": "0",
                "traDate": "2025/Feb/12",
                "explEng": "OUTWARD TRANSFER (E)"
              },
              {
                "valDate": "2025/Feb/11",
                "intDate": "2025/Feb/11",
                "traAmt": "450",
                "curCode": "NGN",
                "remarks": "Income for Statement Generation on 6506200432 by e-doc",
                "drCrDesc": "D",
                "balAfter": "2206.61",
                "docNum": "0",
                "traDate": "2025/Feb/11",
                "explEng": "COLLECTION"
              },
              {
                "valDate": "2025/Feb/11",
                "intDate": "2025/Feb/11",
                "traAmt": "1050",
                "curCode": "NGN",
                "remarks": "Charge for Statement Generation on 6506200432 by e-doc",
                "drCrDesc": "D",
                "balAfter": "2656.61",
                "docNum": "0",
                "traDate": "2025/Feb/11",
                "explEng": "COLLECTION"
              },
              {
                "valDate": "2025/Feb/11",
                "intDate": "2025/Feb/11",
                "traAmt": "450",
                "curCode": "NGN",
                "remarks": "Income for Statement Generation on 6506200432 by e-doc",
                "drCrDesc": "D",
                "balAfter": "3706.61",
                "docNum": "0",
                "traDate": "2025/Feb/11",
                "explEng": "COLLECTION"
              },
              {
                "valDate": "2025/Feb/11",
                "intDate": "2025/Feb/11",
                "traAmt": "1050",
                "curCode": "NGN",
                "remarks": "Charge for Statement Generation on 6506200432 by e-doc",
                "drCrDesc": "D",
                "balAfter": "4156.61",
                "docNum": "0",
                "traDate": "2025/Feb/11",
                "explEng": "COLLECTION"
              },
              {
                "valDate": "2025/Feb/11",
                "intDate": "2025/Feb/11",
                "traAmt": "4000",
                "curCode": "NGN",
                "remarks": "FROM STERLING/ JOSEPH ABISOLA OJO-Transfer from JOSEPH ABISOLA OJO to Ojo Joseph Abisola/000001250211112850288172727130",
                "drCrDesc": "C",
                "balAfter": "5206.61",
                "docNum": "548173",
                "traDate": "2025/Feb/11",
                "explEng": "INWARD TRANSFER (N)"
              },
              {
                "valDate": "2025/Feb/10",
                "intDate": "2025/Feb/10",
                "traAmt": "450",
                "curCode": "NGN",
                "remarks": "Income for Statement Generation on 6506200432 by e-doc",
                "drCrDesc": "D",
                "balAfter": "1206.61",
                "docNum": "0",
                "traDate": "2025/Feb/10",
                "explEng": "COLLECTION"
              },
              {
                "valDate": "2025/Feb/10",
                "intDate": "2025/Feb/10",
                "traAmt": "1050",
                "curCode": "NGN",
                "remarks": "Charge for Statement Generation on 6506200432 by e-doc",
                "drCrDesc": "D",
                "balAfter": "1656.61",
                "docNum": "0",
                "traDate": "2025/Feb/10",
                "explEng": "COLLECTION"
              },
              {
                "valDate": "2025/Feb/10",
                "intDate": "2025/Feb/10",
                "traAmt": "2000",
                "curCode": "NGN",
                "remarks": "FROM STERLING/ JOSEPH ABISOLA OJO-Transfer from JOSEPH ABISOLA OJO to Ojo Joseph Abisola/000001250210174500100099031734",
                "drCrDesc": "C",
                "balAfter": "2706.61",
                "docNum": "1318927",
                "traDate": "2025/Feb/10",
                "explEng": "INWARD TRANSFER (N)"
              },
              {
                "valDate": "2025/Feb/10",
                "intDate": "2025/Feb/10",
                "traAmt": "450",
                "curCode": "NGN",
                "remarks": "Income for Statement Generation on 6506200432 by e-doc",
                "drCrDesc": "D",
                "balAfter": "706.61",
                "docNum": "0",
                "traDate": "2025/Feb/10",
                "explEng": "COLLECTION"
              },
              {
                "valDate": "2025/Feb/10",
                "intDate": "2025/Feb/10",
                "traAmt": "1050",
                "curCode": "NGN",
                "remarks": "Charge for Statement Generation on 6506200432 by e-doc",
                "drCrDesc": "D",
                "balAfter": "1156.61",
                "docNum": "0",
                "traDate": "2025/Feb/10",
                "explEng": "COLLECTION"
              },
              {
                "valDate": "2025/Feb/10",
                "intDate": "2025/Feb/10",
                "traAmt": "1000",
                "curCode": "NGN",
                "remarks": "FROM STERLING/ JOSEPH ABISOLA OJO-Transfer from JOSEPH ABISOLA OJO to Ojo Joseph Abisola/000001250210132400865035899365",
                "drCrDesc": "C",
                "balAfter": "2206.61",
                "docNum": "811680",
                "traDate": "2025/Feb/10",
                "explEng": "INWARD TRANSFER (N)"
              },
              {
                "valDate": "2025/Feb/07",
                "intDate": "2025/Feb/07",
                "traAmt": "1.88",
                "curCode": "NGN",
                "remarks": "MOB: To MONIEPOINT MICROFINANCE BANK|ARTEE INDUSTRIES LIMITED -SPAR ILUPEJU ./000023250209204917008141177308",
                "drCrDesc": "D",
                "balAfter": "1206.61",
                "docNum": "4413936",
                "traDate": "2025/Feb/07",
                "explEng": "VAT"
              },
              {
                "valDate": "2025/Feb/07",
                "intDate": "2025/Feb/07",
                "traAmt": "25",
                "curCode": "NGN",
                "remarks": "MOB: To MONIEPOINT MICROFINANCE BANK|ARTEE INDUSTRIES LIMITED -SPAR ILUPEJU ./000023250209204917008141177308",
                "drCrDesc": "D",
                "balAfter": "1208.49",
                "docNum": "4413936",
                "traDate": "2025/Feb/07",
                "explEng": "COMMISSION"
              },
              {
                "valDate": "2025/Feb/07",
                "intDate": "2025/Feb/07",
                "traAmt": "5160",
                "curCode": "NGN",
                "remarks": "MOB: To MONIEPOINT MICROFINANCE BANK|ARTEE INDUSTRIES LIMITED -SPAR ILUPEJU ./000023250209204917008141177308",
                "drCrDesc": "D",
                "balAfter": "1233.49",
                "docNum": "4413936",
                "traDate": "2025/Feb/07",
                "explEng": "OUTWARD TRANSFER (N)"
              },
              {
                "valDate": "2025/Feb/07",
                "intDate": "2025/Feb/07",
                "traAmt": "450",
                "curCode": "NGN",
                "remarks": "Income for Statement Generation on 6506200432 by e-doc",
                "drCrDesc": "D",
                "balAfter": "6393.49",
                "docNum": "0",
                "traDate": "2025/Feb/07",
                "explEng": "COLLECTION"
              },
              {
                "valDate": "2025/Feb/07",
                "intDate": "2025/Feb/07",
                "traAmt": "1050",
                "curCode": "NGN",
                "remarks": "Charge for Statement Generation on 6506200432 by e-doc",
                "drCrDesc": "D",
                "balAfter": "6843.49",
                "docNum": "0",
                "traDate": "2025/Feb/07",
                "explEng": "COLLECTION"
              },
              {
                "valDate": "2025/Feb/07",
                "intDate": "2025/Feb/07",
                "traAmt": "1000",
                "curCode": "NGN",
                "remarks": "Mob: Airtime for <MTN> <1000.00> on <Ojo Joseph Abisola> <6506200432>/ 6506200432/ 1739113265803/AIRTIME/AIRTIME",
                "drCrDesc": "D",
                "balAfter": "7893.49",
                "docNum": "0",
                "traDate": "2025/Feb/07",
                "explEng": "ACCOUNT TRANSFERS"
              },
              {
                "valDate": "2025/Feb/07",
                "intDate": "2025/Feb/07",
                "traAmt": "0.75",
                "curCode": "NGN",
                "remarks": "MOB: To MONIEPOINT MICROFINANCE BANK|POS Transfer - ANNAYIM KASIR ./000023250209134442008141133767",
                "drCrDesc": "D",
                "balAfter": "8893.49",
                "docNum": "3921108",
                "traDate": "2025/Feb/07",
                "explEng": "VAT"
              },
              {
                "valDate": "2025/Feb/07",
                "intDate": "2025/Feb/07",
                "traAmt": "10",
                "curCode": "NGN",
                "remarks": "MOB: To MONIEPOINT MICROFINANCE BANK|POS Transfer - ANNAYIM KASIR ./000023250209134442008141133767",
                "drCrDesc": "D",
                "balAfter": "8894.24",
                "docNum": "3921108",
                "traDate": "2025/Feb/07",
                "explEng": "COMMISSION"
              },
              {
                "valDate": "2025/Feb/07",
                "intDate": "2025/Feb/07",
                "traAmt": "700",
                "curCode": "NGN",
                "remarks": "MOB: To MONIEPOINT MICROFINANCE BANK|POS Transfer - ANNAYIM KASIR ./000023250209134442008141133767",
                "drCrDesc": "D",
                "balAfter": "8904.24",
                "docNum": "3921108",
                "traDate": "2025/Feb/07",
                "explEng": "OUTWARD TRANSFER (N)"
              },
              {
                "valDate": "2025/Feb/07",
                "intDate": "2025/Feb/07",
                "traAmt": "1.88",
                "curCode": "NGN",
                "remarks": "MOB: To MONIEPOINT MICROFINANCE BANK|Yakoyo Abula Joint - Yakoyo Abula Joint Victoria Island Branch 3 ./000023250209132550008141132357",
                "drCrDesc": "D",
                "balAfter": "9604.24",
                "docNum": "3900600",
                "traDate": "2025/Feb/07",
                "explEng": "VAT"
              },
              {
                "valDate": "2025/Feb/07",
                "intDate": "2025/Feb/07",
                "traAmt": "25",
                "curCode": "NGN",
                "remarks": "MOB: To MONIEPOINT MICROFINANCE BANK|Yakoyo Abula Joint - Yakoyo Abula Joint Victoria Island Branch 3 ./000023250209132550008141132357",
                "drCrDesc": "D",
                "balAfter": "9606.12",
                "docNum": "3900600",
                "traDate": "2025/Feb/07",
                "explEng": "COMMISSION"
              },
              {
                "valDate": "2025/Feb/07",
                "intDate": "2025/Feb/07",
                "traAmt": "10700",
                "curCode": "NGN",
                "remarks": "MOB: To MONIEPOINT MICROFINANCE BANK|Yakoyo Abula Joint - Yakoyo Abula Joint Victoria Island Branch 3 ./000023250209132550008141132357",
                "drCrDesc": "D",
                "balAfter": "9631.12",
                "docNum": "3900600",
                "traDate": "2025/Feb/07",
                "explEng": "OUTWARD TRANSFER (N)"
              },
              {
                "valDate": "2025/Feb/07",
                "intDate": "2025/Feb/07",
                "traAmt": "1.88",
                "curCode": "NGN",
                "remarks": "VAT ON FEE:MOB: To OPAY DIGITAL SERVICES LIMITED|OREOLUWA  MODUPE ABADAHUNSI ./2025020912334247C039091207238",
                "drCrDesc": "D",
                "balAfter": "20331.12",
                "docNum": "0",
                "traDate": "2025/Feb/07",
                "explEng": "VAT"
              },
              {
                "valDate": "2025/Feb/07",
                "intDate": "2025/Feb/07",
                "traAmt": "25",
                "curCode": "NGN",
                "remarks": "FEE:MOB: To OPAY DIGITAL SERVICES LIMITED|OREOLUWA  MODUPE ABADAHUNSI ./2025020912334247C039091207238",
                "drCrDesc": "D",
                "balAfter": "20333",
                "docNum": "0",
                "traDate": "2025/Feb/07",
                "explEng": "COMMISSION"
              },
              {
                "valDate": "2025/Feb/07",
                "intDate": "2025/Feb/07",
                "traAmt": "6000",
                "curCode": "NGN",
                "remarks": "MOB: To OPAY DIGITAL SERVICES LIMITED|OREOLUWA  MODUPE ABADAHUNSI ./2025020912334247C039091207238",
                "drCrDesc": "D",
                "balAfter": "20358",
                "docNum": "0",
                "traDate": "2025/Feb/07",
                "explEng": "OUTWARD TRANSFER (E)"
              },
              {
                "valDate": "2025/Feb/07",
                "intDate": "2025/Feb/07",
                "traAmt": "0.75",
                "curCode": "NGN",
                "remarks": "VAT ON FEE:MOB: To Zenith Bank Plc|RCCG  THE NEW HOUSE ./20250209111931100571013850936",
                "drCrDesc": "D",
                "balAfter": "26358",
                "docNum": "0",
                "traDate": "2025/Feb/07",
                "explEng": "VAT"
              },
              {
                "valDate": "2025/Feb/07",
                "intDate": "2025/Feb/07",
                "traAmt": "10",
                "curCode": "NGN",
                "remarks": "FEE:MOB: To Zenith Bank Plc|RCCG  THE NEW HOUSE ./20250209111931100571013850936",
                "drCrDesc": "D",
                "balAfter": "26358.75",
                "docNum": "0",
                "traDate": "2025/Feb/07",
                "explEng": "COMMISSION"
              },
              {
                "valDate": "2025/Feb/07",
                "intDate": "2025/Feb/07",
                "traAmt": "3000",
                "curCode": "NGN",
                "remarks": "MOB: To Zenith Bank Plc|RCCG  THE NEW HOUSE ./20250209111931100571013850936",
                "drCrDesc": "D",
                "balAfter": "26368.75",
                "docNum": "0",
                "traDate": "2025/Feb/07",
                "explEng": "OUTWARD TRANSFER (E)"
              },
              {
                "valDate": "2025/Feb/07",
                "intDate": "2025/Feb/07",
                "traAmt": "0.75",
                "curCode": "NGN",
                "remarks": "MOB: To First Bank of Nigeria|ADEWOLE TEMIDAYO OMOJOWO ./000023250208232401008141094043",
                "drCrDesc": "D",
                "balAfter": "29368.75",
                "docNum": "3340024",
                "traDate": "2025/Feb/07",
                "explEng": "VAT"
              },
              {
                "valDate": "2025/Feb/07",
                "intDate": "2025/Feb/07",
                "traAmt": "10",
                "curCode": "NGN",
                "remarks": "MOB: To First Bank of Nigeria|ADEWOLE TEMIDAYO OMOJOWO ./000023250208232401008141094043",
                "drCrDesc": "D",
                "balAfter": "29369.5",
                "docNum": "3340024",
                "traDate": "2025/Feb/07",
                "explEng": "COMMISSION"
              },
              {
                "valDate": "2025/Feb/07",
                "intDate": "2025/Feb/07",
                "traAmt": "1000",
                "curCode": "NGN",
                "remarks": "MOB: To First Bank of Nigeria|ADEWOLE TEMIDAYO OMOJOWO ./000023250208232401008141094043",
                "drCrDesc": "D",
                "balAfter": "29379.5",
                "docNum": "3340024",
                "traDate": "2025/Feb/07",
                "explEng": "OUTWARD TRANSFER (N)"
              },
              {
                "valDate": "2025/Feb/07",
                "intDate": "2025/Feb/07",
                "traAmt": "1.88",
                "curCode": "NGN",
                "remarks": "MOB: To Sterling Bank|JOSEPH ABISOLA OJO ./000023250208090616008140994484",
                "drCrDesc": "D",
                "balAfter": "30379.5",
                "docNum": "1996856",
                "traDate": "2025/Feb/07",
                "explEng": "VAT"
              },
              {
                "valDate": "2025/Feb/07",
                "intDate": "2025/Feb/07",
                "traAmt": "25",
                "curCode": "NGN",
                "remarks": "MOB: To Sterling Bank|JOSEPH ABISOLA OJO ./000023250208090616008140994484",
                "drCrDesc": "D",
                "balAfter": "30381.38",
                "docNum": "1996856",
                "traDate": "2025/Feb/07",
                "explEng": "COMMISSION"
              },
              {
                "valDate": "2025/Feb/07",
                "intDate": "2025/Feb/07",
                "traAmt": "20200",
                "curCode": "NGN",
                "remarks": "MOB: To Sterling Bank|JOSEPH ABISOLA OJO ./000023250208090616008140994484",
                "drCrDesc": "D",
                "balAfter": "30406.38",
                "docNum": "1996856",
                "traDate": "2025/Feb/07",
                "explEng": "OUTWARD TRANSFER (N)"
              },
              {
                "valDate": "2025/Feb/07",
                "intDate": "2025/Feb/07",
                "traAmt": "0.75",
                "curCode": "NGN",
                "remarks": "VAT ON FEE:MOB: To OPAY DIGITAL SERVICES LIMITED|RABIU ADISA ADEBUNMI ./2025020808542055C038068348225",
                "drCrDesc": "D",
                "balAfter": "50606.38",
                "docNum": "0",
                "traDate": "2025/Feb/07",
                "explEng": "VAT"
              },
              {
                "valDate": "2025/Feb/07",
                "intDate": "2025/Feb/07",
                "traAmt": "10",
                "curCode": "NGN",
                "remarks": "FEE:MOB: To OPAY DIGITAL SERVICES LIMITED|RABIU ADISA ADEBUNMI ./2025020808542055C038068348225",
                "drCrDesc": "D",
                "balAfter": "50607.13",
                "docNum": "0",
                "traDate": "2025/Feb/07",
                "explEng": "COMMISSION"
              },
              {
                "valDate": "2025/Feb/07",
                "intDate": "2025/Feb/07",
                "traAmt": "1500",
                "curCode": "NGN",
                "remarks": "MOB: To OPAY DIGITAL SERVICES LIMITED|RABIU ADISA ADEBUNMI ./2025020808542055C038068348225",
                "drCrDesc": "D",
                "balAfter": "50617.13",
                "docNum": "0",
                "traDate": "2025/Feb/07",
                "explEng": "OUTWARD TRANSFER (E)"
              },
              {
                "valDate": "2025/Feb/07",
                "intDate": "2025/Feb/07",
                "traAmt": "0.75",
                "curCode": "NGN",
                "remarks": "VAT ON FEE:MOB: To OPAY DIGITAL SERVICES LIMITED|Taiwo  ademola kareem ./2025020718384334C038060106449",
                "drCrDesc": "D",
                "balAfter": "52117.13",
                "docNum": "0",
                "traDate": "2025/Feb/07",
                "explEng": "VAT"
              },
              {
                "valDate": "2025/Feb/07",
                "intDate": "2025/Feb/07",
                "traAmt": "10",
                "curCode": "NGN",
                "remarks": "FEE:MOB: To OPAY DIGITAL SERVICES LIMITED|Taiwo  ademola kareem ./2025020718384334C038060106449",
                "drCrDesc": "D",
                "balAfter": "52117.88",
                "docNum": "0",
                "traDate": "2025/Feb/07",
                "explEng": "COMMISSION"
              },
              {
                "valDate": "2025/Feb/07",
                "intDate": "2025/Feb/07",
                "traAmt": "4000",
                "curCode": "NGN",
                "remarks": "MOB: To OPAY DIGITAL SERVICES LIMITED|Taiwo  ademola kareem ./2025020718384334C038060106449",
                "drCrDesc": "D",
                "balAfter": "52127.88",
                "docNum": "0",
                "traDate": "2025/Feb/07",
                "explEng": "OUTWARD TRANSFER (E)"
              },
              {
                "valDate": "2025/Feb/07",
                "intDate": "2025/Feb/07",
                "traAmt": "450",
                "curCode": "NGN",
                "remarks": "Income for Statement Generation on 6506200432 by e-doc",
                "drCrDesc": "D",
                "balAfter": "56127.88",
                "docNum": "0",
                "traDate": "2025/Feb/07",
                "explEng": "COLLECTION"
              },
              {
                "valDate": "2025/Feb/07",
                "intDate": "2025/Feb/07",
                "traAmt": "1050",
                "curCode": "NGN",
                "remarks": "Charge for Statement Generation on 6506200432 by e-doc",
                "drCrDesc": "D",
                "balAfter": "56577.88",
                "docNum": "0",
                "traDate": "2025/Feb/07",
                "explEng": "COLLECTION"
              },
              {
                "valDate": "2025/Feb/07",
                "intDate": "2025/Feb/07",
                "traAmt": "450",
                "curCode": "NGN",
                "remarks": "Income for Statement Generation on 6506200432 by e-doc",
                "drCrDesc": "D",
                "balAfter": "57627.88",
                "docNum": "0",
                "traDate": "2025/Feb/07",
                "explEng": "COLLECTION"
              },
              {
                "valDate": "2025/Feb/07",
                "intDate": "2025/Feb/07",
                "traAmt": "1050",
                "curCode": "NGN",
                "remarks": "Charge for Statement Generation on 6506200432 by e-doc",
                "drCrDesc": "D",
                "balAfter": "58077.88",
                "docNum": "0",
                "traDate": "2025/Feb/07",
                "explEng": "COLLECTION"
              },
              {
                "valDate": "2025/Feb/06",
                "intDate": "2025/Feb/06",
                "traAmt": "0.75",
                "curCode": "NGN",
                "remarks": "MOB: To MONIEPOINT MICROFINANCE BANK|POS Transfer - OLIVER MBAM ./000023250206201633008140812395",
                "drCrDesc": "D",
                "balAfter": "59127.88",
                "docNum": "1629196",
                "traDate": "2025/Feb/06",
                "explEng": "VAT"
              },
              {
                "valDate": "2025/Feb/06",
                "intDate": "2025/Feb/06",
                "traAmt": "10",
                "curCode": "NGN",
                "remarks": "MOB: To MONIEPOINT MICROFINANCE BANK|POS Transfer - OLIVER MBAM ./000023250206201633008140812395",
                "drCrDesc": "D",
                "balAfter": "59128.63",
                "docNum": "1629196",
                "traDate": "2025/Feb/06",
                "explEng": "COMMISSION"
              },
              {
                "valDate": "2025/Feb/06",
                "intDate": "2025/Feb/06",
                "traAmt": "3000",
                "curCode": "NGN",
                "remarks": "MOB: To MONIEPOINT MICROFINANCE BANK|POS Transfer - OLIVER MBAM ./000023250206201633008140812395",
                "drCrDesc": "D",
                "balAfter": "59138.63",
                "docNum": "1629196",
                "traDate": "2025/Feb/06",
                "explEng": "OUTWARD TRANSFER (N)"
              },
              {
                "valDate": "2025/Feb/06",
                "intDate": "2025/Feb/06",
                "traAmt": "450",
                "curCode": "NGN",
                "remarks": "Income for Statement Generation on 6506200432 by e-doc",
                "drCrDesc": "D",
                "balAfter": "62138.63",
                "docNum": "0",
                "traDate": "2025/Feb/06",
                "explEng": "COLLECTION"
              },
              {
                "valDate": "2025/Feb/06",
                "intDate": "2025/Feb/06",
                "traAmt": "1050",
                "curCode": "NGN",
                "remarks": "Charge for Statement Generation on 6506200432 by e-doc",
                "drCrDesc": "D",
                "balAfter": "62588.63",
                "docNum": "0",
                "traDate": "2025/Feb/06",
                "explEng": "COLLECTION"
              },
              {
                "valDate": "2025/Feb/06",
                "intDate": "2025/Feb/06",
                "traAmt": "450",
                "curCode": "NGN",
                "remarks": "Income for Statement Generation on 6506200432 by e-doc",
                "drCrDesc": "D",
                "balAfter": "63638.63",
                "docNum": "0",
                "traDate": "2025/Feb/06",
                "explEng": "COLLECTION"
              },
              {
                "valDate": "2025/Feb/06",
                "intDate": "2025/Feb/06",
                "traAmt": "1050",
                "curCode": "NGN",
                "remarks": "Charge for Statement Generation on 6506200432 by e-doc",
                "drCrDesc": "D",
                "balAfter": "64088.63",
                "docNum": "0",
                "traDate": "2025/Feb/06",
                "explEng": "COLLECTION"
              },
              {
                "valDate": "2025/Feb/06",
                "intDate": "2025/Feb/06",
                "traAmt": "450",
                "curCode": "NGN",
                "remarks": "Income for Statement Generation on 6506200432 by e-doc",
                "drCrDesc": "D",
                "balAfter": "65138.63",
                "docNum": "0",
                "traDate": "2025/Feb/06",
                "explEng": "COLLECTION"
              },
              {
                "valDate": "2025/Feb/06",
                "intDate": "2025/Feb/06",
                "traAmt": "1050",
                "curCode": "NGN",
                "remarks": "Charge for Statement Generation on 6506200432 by e-doc",
                "drCrDesc": "D",
                "balAfter": "65588.63",
                "docNum": "0",
                "traDate": "2025/Feb/06",
                "explEng": "COLLECTION"
              },
              {
                "valDate": "2025/Feb/06",
                "intDate": "2025/Feb/06",
                "traAmt": "1.88",
                "curCode": "NGN",
                "remarks": "VAT ON FEE:MOB: To Access Bank|MARY ADEJUMO ./20250206105140010440015651813",
                "drCrDesc": "D",
                "balAfter": "66638.63",
                "docNum": "0",
                "traDate": "2025/Feb/06",
                "explEng": "VAT"
              },
              {
                "valDate": "2025/Feb/06",
                "intDate": "2025/Feb/06",
                "traAmt": "25",
                "curCode": "NGN",
                "remarks": "FEE:MOB: To Access Bank|MARY ADEJUMO ./20250206105140010440015651813",
                "drCrDesc": "D",
                "balAfter": "66640.51",
                "docNum": "0",
                "traDate": "2025/Feb/06",
                "explEng": "COMMISSION"
              },
              {
                "valDate": "2025/Feb/06",
                "intDate": "2025/Feb/06",
                "traAmt": "50000",
                "curCode": "NGN",
                "remarks": "MOB: To Access Bank|MARY ADEJUMO ./20250206105140010440015651813",
                "drCrDesc": "D",
                "balAfter": "66665.51",
                "docNum": "0",
                "traDate": "2025/Feb/06",
                "explEng": "OUTWARD TRANSFER (E)"
              },
              {
                "valDate": "2025/Feb/06",
                "intDate": "2025/Feb/06",
                "traAmt": "3.75",
                "curCode": "NGN",
                "remarks": "VAT ON FEE:MOB: To Access Bank|MARY ADEJUMO ./20250206102548520440015651813",
                "drCrDesc": "D",
                "balAfter": "116665.51",
                "docNum": "0",
                "traDate": "2025/Feb/06",
                "explEng": "VAT"
              },
              {
                "valDate": "2025/Feb/06",
                "intDate": "2025/Feb/06",
                "traAmt": "50",
                "curCode": "NGN",
                "remarks": "FEE:MOB: To Access Bank|MARY ADEJUMO ./20250206102548520440015651813",
                "drCrDesc": "D",
                "balAfter": "116669.26",
                "docNum": "0",
                "traDate": "2025/Feb/06",
                "explEng": "COMMISSION"
              },
              {
                "valDate": "2025/Feb/06",
                "intDate": "2025/Feb/06",
                "traAmt": "500000",
                "curCode": "NGN",
                "remarks": "MOB: To Access Bank|MARY ADEJUMO ./20250206102548520440015651813",
                "drCrDesc": "D",
                "balAfter": "116719.26",
                "docNum": "0",
                "traDate": "2025/Feb/06",
                "explEng": "OUTWARD TRANSFER (E)"
              },
              {
                "valDate": "2025/Feb/05",
                "intDate": "2025/Feb/05",
                "traAmt": "1.88",
                "curCode": "NGN",
                "remarks": "MOB: To GTBank Plc|ANINYEI ROSALYN AWELEKANMA ./000023250205092857008140577322",
                "drCrDesc": "D",
                "balAfter": "616719.26",
                "docNum": "320316",
                "traDate": "2025/Feb/05",
                "explEng": "VAT"
              },
              {
                "valDate": "2025/Feb/05",
                "intDate": "2025/Feb/05",
                "traAmt": "25",
                "curCode": "NGN",
                "remarks": "MOB: To GTBank Plc|ANINYEI ROSALYN AWELEKANMA ./000023250205092857008140577322",
                "drCrDesc": "D",
                "balAfter": "616721.14",
                "docNum": "320316",
                "traDate": "2025/Feb/05",
                "explEng": "COMMISSION"
              },
              {
                "valDate": "2025/Feb/05",
                "intDate": "2025/Feb/05",
                "traAmt": "20000",
                "curCode": "NGN",
                "remarks": "MOB: To GTBank Plc|ANINYEI ROSALYN AWELEKANMA ./000023250205092857008140577322",
                "drCrDesc": "D",
                "balAfter": "616746.14",
                "docNum": "320316",
                "traDate": "2025/Feb/05",
                "explEng": "OUTWARD TRANSFER (N)"
              },
              {
                "valDate": "2025/Feb/04",
                "intDate": "2025/Feb/04",
                "traAmt": "1.88",
                "curCode": "NGN",
                "remarks": "VAT ON FEE:MOB: To Keystone Bank|WUESE ORNGUGA EMMANUEL ./20250204225650230826046341987",
                "drCrDesc": "D",
                "balAfter": "636746.14",
                "docNum": "0",
                "traDate": "2025/Feb/05",
                "explEng": "VAT"
              },
              {
                "valDate": "2025/Feb/04",
                "intDate": "2025/Feb/04",
                "traAmt": "25",
                "curCode": "NGN",
                "remarks": "FEE:MOB: To Keystone Bank|WUESE ORNGUGA EMMANUEL ./20250204225650230826046341987",
                "drCrDesc": "D",
                "balAfter": "636748.02",
                "docNum": "0",
                "traDate": "2025/Feb/05",
                "explEng": "COMMISSION"
              },
              {
                "valDate": "2025/Feb/04",
                "intDate": "2025/Feb/04",
                "traAmt": "5400",
                "curCode": "NGN",
                "remarks": "MOB: To Keystone Bank|WUESE ORNGUGA EMMANUEL ./20250204225650230826046341987",
                "drCrDesc": "D",
                "balAfter": "636773.02",
                "docNum": "0",
                "traDate": "2025/Feb/05",
                "explEng": "OUTWARD TRANSFER (E)"
              },
              {
                "valDate": "2025/Feb/04",
                "intDate": "2025/Feb/04",
                "traAmt": "1.88",
                "curCode": "NGN",
                "remarks": "MOB: To StanbicIBTC Bank|WUESE EMMANUEL ./000023250204225027008140545662",
                "drCrDesc": "C",
                "balAfter": "642173.02",
                "docNum": "1706369",
                "traDate": "2025/Feb/05",
                "explEng": "REVERSAL E-BRANCH/EXTERNAL CHANNELS"
              },
              {
                "valDate": "2025/Feb/04",
                "intDate": "2025/Feb/04",
                "traAmt": "25",
                "curCode": "NGN",
                "remarks": "MOB: To StanbicIBTC Bank|WUESE EMMANUEL ./000023250204225027008140545662",
                "drCrDesc": "C",
                "balAfter": "642171.14",
                "docNum": "1706369",
                "traDate": "2025/Feb/05",
                "explEng": "REVERSAL E-BRANCH/EXTERNAL CHANNELS"
              },
              {
                "valDate": "2025/Feb/04",
                "intDate": "2025/Feb/04",
                "traAmt": "5400",
                "curCode": "NGN",
                "remarks": "MOB: To StanbicIBTC Bank|WUESE EMMANUEL ./000023250204225027008140545662",
                "drCrDesc": "C",
                "balAfter": "642146.14",
                "docNum": "1706369",
                "traDate": "2025/Feb/05",
                "explEng": "REVERSAL E-BRANCH/EXTERNAL CHANNELS"
              },
              {
                "valDate": "2025/Feb/04",
                "intDate": "2025/Feb/04",
                "traAmt": "1.88",
                "curCode": "NGN",
                "remarks": "MOB: To StanbicIBTC Bank|WUESE EMMANUEL ./000023250204225027008140545662",
                "drCrDesc": "D",
                "balAfter": "636746.14",
                "docNum": "1706369",
                "traDate": "2025/Feb/05",
                "explEng": "VAT"
              },
              {
                "valDate": "2025/Feb/04",
                "intDate": "2025/Feb/04",
                "traAmt": "25",
                "curCode": "NGN",
                "remarks": "MOB: To StanbicIBTC Bank|WUESE EMMANUEL ./000023250204225027008140545662",
                "drCrDesc": "D",
                "balAfter": "636748.02",
                "docNum": "1706369",
                "traDate": "2025/Feb/05",
                "explEng": "COMMISSION"
              },
              {
                "valDate": "2025/Feb/04",
                "intDate": "2025/Feb/04",
                "traAmt": "5400",
                "curCode": "NGN",
                "remarks": "MOB: To StanbicIBTC Bank|WUESE EMMANUEL ./000023250204225027008140545662",
                "drCrDesc": "D",
                "balAfter": "636773.02",
                "docNum": "1706369",
                "traDate": "2025/Feb/05",
                "explEng": "OUTWARD TRANSFER (N)"
              },
              {
                "valDate": "2025/Feb/04",
                "intDate": "2025/Feb/04",
                "traAmt": "450",
                "curCode": "NGN",
                "remarks": "Income for Statement Generation on 6506200432 by e-doc",
                "drCrDesc": "D",
                "balAfter": "642173.02",
                "docNum": "0",
                "traDate": "2025/Feb/04",
                "explEng": "COLLECTION"
              },
              {
                "valDate": "2025/Feb/04",
                "intDate": "2025/Feb/04",
                "traAmt": "1050",
                "curCode": "NGN",
                "remarks": "Charge for Statement Generation on 6506200432 by e-doc",
                "drCrDesc": "D",
                "balAfter": "642623.02",
                "docNum": "0",
                "traDate": "2025/Feb/04",
                "explEng": "COLLECTION"
              },
              {
                "valDate": "2025/Feb/04",
                "intDate": "2025/Feb/04",
                "traAmt": "450",
                "curCode": "NGN",
                "remarks": "Income for Statement Generation on 6506200432 by e-doc",
                "drCrDesc": "D",
                "balAfter": "643673.02",
                "docNum": "0",
                "traDate": "2025/Feb/04",
                "explEng": "COLLECTION"
              },
              {
                "valDate": "2025/Feb/04",
                "intDate": "2025/Feb/04",
                "traAmt": "1050",
                "curCode": "NGN",
                "remarks": "Charge for Statement Generation on 6506200432 by e-doc",
                "drCrDesc": "D",
                "balAfter": "644123.02",
                "docNum": "0",
                "traDate": "2025/Feb/04",
                "explEng": "COLLECTION"
              },
              {
                "valDate": "2025/Feb/04",
                "intDate": "2025/Feb/04",
                "traAmt": "0.75",
                "curCode": "NGN",
                "remarks": "MOB: To Sterling Bank|JOSEPH ABISOLA OJO ./000023250204135136008140476964",
                "drCrDesc": "D",
                "balAfter": "645173.02",
                "docNum": "834860",
                "traDate": "2025/Feb/04",
                "explEng": "VAT"
              },
              {
                "valDate": "2025/Feb/04",
                "intDate": "2025/Feb/04",
                "traAmt": "10",
                "curCode": "NGN",
                "remarks": "MOB: To Sterling Bank|JOSEPH ABISOLA OJO ./000023250204135136008140476964",
                "drCrDesc": "D",
                "balAfter": "645173.77",
                "docNum": "834860",
                "traDate": "2025/Feb/04",
                "explEng": "COMMISSION"
              },
              {
                "valDate": "2025/Feb/04",
                "intDate": "2025/Feb/04",
                "traAmt": "3000",
                "curCode": "NGN",
                "remarks": "MOB: To Sterling Bank|JOSEPH ABISOLA OJO ./000023250204135136008140476964",
                "drCrDesc": "D",
                "balAfter": "645183.77",
                "docNum": "834860",
                "traDate": "2025/Feb/04",
                "explEng": "OUTWARD TRANSFER (N)"
              },
              {
                "valDate": "2025/Feb/04",
                "intDate": "2025/Feb/04",
                "traAmt": "3000",
                "curCode": "NGN",
                "remarks": "MOB: Trf from Ojo Joseph Abisola  65******0432  to  WILLIAMS DANIEL UKPONO - 65******2754 .",
                "drCrDesc": "D",
                "balAfter": "648183.77",
                "docNum": "0",
                "traDate": "2025/Feb/04",
                "explEng": "ACCOUNT TRANSFERS"
              },
              {
                "valDate": "2025/Feb/04",
                "intDate": "2025/Feb/04",
                "traAmt": "0.75",
                "curCode": "NGN",
                "remarks": "VAT ON FEE:MOB: To OPAY DIGITAL SERVICES LIMITED|PHILIPS  OLAWUNMI OJO ./2025020409134007C037034533712",
                "drCrDesc": "D",
                "balAfter": "651183.77",
                "docNum": "0",
                "traDate": "2025/Feb/04",
                "explEng": "VAT"
              },
              {
                "valDate": "2025/Feb/04",
                "intDate": "2025/Feb/04",
                "traAmt": "10",
                "curCode": "NGN",
                "remarks": "FEE:MOB: To OPAY DIGITAL SERVICES LIMITED|PHILIPS  OLAWUNMI OJO ./2025020409134007C037034533712",
                "drCrDesc": "D",
                "balAfter": "651184.52",
                "docNum": "0",
                "traDate": "2025/Feb/04",
                "explEng": "COMMISSION"
              },
              {
                "valDate": "2025/Feb/04",
                "intDate": "2025/Feb/04",
                "traAmt": "1500",
                "curCode": "NGN",
                "remarks": "MOB: To OPAY DIGITAL SERVICES LIMITED|PHILIPS  OLAWUNMI OJO ./2025020409134007C037034533712",
                "drCrDesc": "D",
                "balAfter": "651194.52",
                "docNum": "0",
                "traDate": "2025/Feb/04",
                "explEng": "OUTWARD TRANSFER (E)"
              },
              {
                "valDate": "2025/Feb/03",
                "intDate": "2025/Feb/03",
                "traAmt": "0.75",
                "curCode": "NGN",
                "remarks": "VAT ON FEE:MOB: To OPAY DIGITAL SERVICES LIMITED|EMMANUEL   ALADEOLA ./2025020315385499C038033036095",
                "drCrDesc": "D",
                "balAfter": "652694.52",
                "docNum": "0",
                "traDate": "2025/Feb/03",
                "explEng": "VAT"
              },
              {
                "valDate": "2025/Feb/03",
                "intDate": "2025/Feb/03",
                "traAmt": "10",
                "curCode": "NGN",
                "remarks": "FEE:MOB: To OPAY DIGITAL SERVICES LIMITED|EMMANUEL   ALADEOLA ./2025020315385499C038033036095",
                "drCrDesc": "D",
                "balAfter": "652695.27",
                "docNum": "0",
                "traDate": "2025/Feb/03",
                "explEng": "COMMISSION"
              },
              {
                "valDate": "2025/Feb/03",
                "intDate": "2025/Feb/03",
                "traAmt": "1500",
                "curCode": "NGN",
                "remarks": "MOB: To OPAY DIGITAL SERVICES LIMITED|EMMANUEL   ALADEOLA ./2025020315385499C038033036095",
                "drCrDesc": "D",
                "balAfter": "652705.27",
                "docNum": "0",
                "traDate": "2025/Feb/03",
                "explEng": "OUTWARD TRANSFER (E)"
              },
              {
                "valDate": "2025/Feb/03",
                "intDate": "2025/Feb/03",
                "traAmt": "0.75",
                "curCode": "NGN",
                "remarks": "MOB: To MONIEPOINT MICROFINANCE BANK|POS Transfer - VICTOR NWARIBEAKU ./000023250203142423008140336427",
                "drCrDesc": "D",
                "balAfter": "654205.27",
                "docNum": "3442145",
                "traDate": "2025/Feb/03",
                "explEng": "VAT"
              },
              {
                "valDate": "2025/Feb/03",
                "intDate": "2025/Feb/03",
                "traAmt": "10",
                "curCode": "NGN",
                "remarks": "MOB: To MONIEPOINT MICROFINANCE BANK|POS Transfer - VICTOR NWARIBEAKU ./000023250203142423008140336427",
                "drCrDesc": "D",
                "balAfter": "654206.02",
                "docNum": "3442145",
                "traDate": "2025/Feb/03",
                "explEng": "COMMISSION"
              },
              {
                "valDate": "2025/Feb/03",
                "intDate": "2025/Feb/03",
                "traAmt": "5000",
                "curCode": "NGN",
                "remarks": "MOB: To MONIEPOINT MICROFINANCE BANK|POS Transfer - VICTOR NWARIBEAKU ./000023250203142423008140336427",
                "drCrDesc": "D",
                "balAfter": "654216.02",
                "docNum": "3442145",
                "traDate": "2025/Feb/03",
                "explEng": "OUTWARD TRANSFER (N)"
              },
              {
                "valDate": "2025/Feb/03",
                "intDate": "2025/Feb/03",
                "traAmt": "1.88",
                "curCode": "NGN",
                "remarks": "VAT ON FEE:MOB: To PALMPAY|ELIJAH OLUWAJEDALO OGUNJOBI ./2025020310272383B998162690996",
                "drCrDesc": "D",
                "balAfter": "659216.02",
                "docNum": "0",
                "traDate": "2025/Feb/03",
                "explEng": "VAT"
              },
              {
                "valDate": "2025/Feb/03",
                "intDate": "2025/Feb/03",
                "traAmt": "25",
                "curCode": "NGN",
                "remarks": "FEE:MOB: To PALMPAY|ELIJAH OLUWAJEDALO OGUNJOBI ./2025020310272383B998162690996",
                "drCrDesc": "D",
                "balAfter": "659217.9",
                "docNum": "0",
                "traDate": "2025/Feb/03",
                "explEng": "COMMISSION"
              },
              {
                "valDate": "2025/Feb/03",
                "intDate": "2025/Feb/03",
                "traAmt": "30000",
                "curCode": "NGN",
                "remarks": "MOB: To PALMPAY|ELIJAH OLUWAJEDALO OGUNJOBI ./2025020310272383B998162690996",
                "drCrDesc": "D",
                "balAfter": "659242.9",
                "docNum": "0",
                "traDate": "2025/Feb/03",
                "explEng": "OUTWARD TRANSFER (E)"
              },
              {
                "valDate": "2025/Feb/03",
                "intDate": "2025/Feb/03",
                "traAmt": "1.88",
                "curCode": "NGN",
                "remarks": "MOB: To First Bank of Nigeria|OJO JOSEPH ABISOLA ./000023250202212931008140259300",
                "drCrDesc": "D",
                "balAfter": "689242.9",
                "docNum": "2583166",
                "traDate": "2025/Feb/03",
                "explEng": "VAT"
              },
              {
                "valDate": "2025/Feb/03",
                "intDate": "2025/Feb/03",
                "traAmt": "25",
                "curCode": "NGN",
                "remarks": "MOB: To First Bank of Nigeria|OJO JOSEPH ABISOLA ./000023250202212931008140259300",
                "drCrDesc": "D",
                "balAfter": "689244.78",
                "docNum": "2583166",
                "traDate": "2025/Feb/03",
                "explEng": "COMMISSION"
              },
              {
                "valDate": "2025/Feb/03",
                "intDate": "2025/Feb/03",
                "traAmt": "15000",
                "curCode": "NGN",
                "remarks": "MOB: To First Bank of Nigeria|OJO JOSEPH ABISOLA ./000023250202212931008140259300",
                "drCrDesc": "D",
                "balAfter": "689269.78",
                "docNum": "2583166",
                "traDate": "2025/Feb/03",
                "explEng": "OUTWARD TRANSFER (N)"
              },
              {
                "valDate": "2025/Feb/03",
                "intDate": "2025/Feb/03",
                "traAmt": "0.75",
                "curCode": "NGN",
                "remarks": "VAT ON FEE:MOB: To OPAY DIGITAL SERVICES LIMITED|ADEWALE OLUWATOBI ADEYEMI ./2025020206103075C037063804388",
                "drCrDesc": "D",
                "balAfter": "704269.78",
                "docNum": "0",
                "traDate": "2025/Feb/03",
                "explEng": "VAT"
              },
              {
                "valDate": "2025/Feb/03",
                "intDate": "2025/Feb/03",
                "traAmt": "10",
                "curCode": "NGN",
                "remarks": "FEE:MOB: To OPAY DIGITAL SERVICES LIMITED|ADEWALE OLUWATOBI ADEYEMI ./2025020206103075C037063804388",
                "drCrDesc": "D",
                "balAfter": "704270.53",
                "docNum": "0",
                "traDate": "2025/Feb/03",
                "explEng": "COMMISSION"
              },
              {
                "valDate": "2025/Feb/03",
                "intDate": "2025/Feb/03",
                "traAmt": "3000",
                "curCode": "NGN",
                "remarks": "MOB: To OPAY DIGITAL SERVICES LIMITED|ADEWALE OLUWATOBI ADEYEMI ./2025020206103075C037063804388",
                "drCrDesc": "D",
                "balAfter": "704280.53",
                "docNum": "0",
                "traDate": "2025/Feb/03",
                "explEng": "OUTWARD TRANSFER (E)"
              },
              {
                "valDate": "2025/Feb/03",
                "intDate": "2025/Feb/03",
                "traAmt": "1.88",
                "curCode": "NGN",
                "remarks": "VAT ON FEE:MOB: To United Bank for Africa|AMINU  SEGUN ALEX ./20250201210104780332061749490",
                "drCrDesc": "D",
                "balAfter": "707280.53",
                "docNum": "0",
                "traDate": "2025/Feb/03",
                "explEng": "VAT"
              },
              {
                "valDate": "2025/Feb/03",
                "intDate": "2025/Feb/03",
                "traAmt": "25",
                "curCode": "NGN",
                "remarks": "FEE:MOB: To United Bank for Africa|AMINU  SEGUN ALEX ./20250201210104780332061749490",
                "drCrDesc": "D",
                "balAfter": "707282.41",
                "docNum": "0",
                "traDate": "2025/Feb/03",
                "explEng": "COMMISSION"
              },
              {
                "valDate": "2025/Feb/03",
                "intDate": "2025/Feb/03",
                "traAmt": "10000",
                "curCode": "NGN",
                "remarks": "MOB: To United Bank for Africa|AMINU  SEGUN ALEX ./20250201210104780332061749490",
                "drCrDesc": "D",
                "balAfter": "707307.41",
                "docNum": "0",
                "traDate": "2025/Feb/03",
                "explEng": "OUTWARD TRANSFER (E)"
              },
              {
                "valDate": "2025/Feb/03",
                "intDate": "2025/Feb/03",
                "traAmt": "3.75",
                "curCode": "NGN",
                "remarks": "VAT ON FEE:MOB: To PALMPAY|CHIJIOKE DANIEL UBALEKU ./2025020116265509B998024191880",
                "drCrDesc": "D",
                "balAfter": "717307.41",
                "docNum": "0",
                "traDate": "2025/Feb/03",
                "explEng": "VAT"
              },
              {
                "valDate": "2025/Feb/03",
                "intDate": "2025/Feb/03",
                "traAmt": "50",
                "curCode": "NGN",
                "remarks": "FEE:MOB: To PALMPAY|CHIJIOKE DANIEL UBALEKU ./2025020116265509B998024191880",
                "drCrDesc": "D",
                "balAfter": "717311.16",
                "docNum": "0",
                "traDate": "2025/Feb/03",
                "explEng": "COMMISSION"
              },
              {
                "valDate": "2025/Feb/03",
                "intDate": "2025/Feb/03",
                "traAmt": "80000",
                "curCode": "NGN",
                "remarks": "MOB: To PALMPAY|CHIJIOKE DANIEL UBALEKU ./2025020116265509B998024191880",
                "drCrDesc": "D",
                "balAfter": "717361.16",
                "docNum": "0",
                "traDate": "2025/Feb/03",
                "explEng": "OUTWARD TRANSFER (E)"
              },
              {
                "valDate": "2025/Feb/03",
                "intDate": "2025/Feb/03",
                "traAmt": "3.75",
                "curCode": "NGN",
                "remarks": "VAT ON FEE:MOB: To OPAY DIGITAL SERVICES LIMITED|JOHN  MOTUNRAYO AKANBI ./2025020116005350C038038700256",
                "drCrDesc": "D",
                "balAfter": "797361.16",
                "docNum": "0",
                "traDate": "2025/Feb/03",
                "explEng": "VAT"
              },
              {
                "valDate": "2025/Feb/03",
                "intDate": "2025/Feb/03",
                "traAmt": "50",
                "curCode": "NGN",
                "remarks": "FEE:MOB: To OPAY DIGITAL SERVICES LIMITED|JOHN  MOTUNRAYO AKANBI ./2025020116005350C038038700256",
                "drCrDesc": "D",
                "balAfter": "797364.91",
                "docNum": "0",
                "traDate": "2025/Feb/03",
                "explEng": "COMMISSION"
              },
              {
                "valDate": "2025/Feb/03",
                "intDate": "2025/Feb/03",
                "traAmt": "140000",
                "curCode": "NGN",
                "remarks": "MOB: To OPAY DIGITAL SERVICES LIMITED|JOHN  MOTUNRAYO AKANBI ./2025020116005350C038038700256",
                "drCrDesc": "D",
                "balAfter": "797414.91",
                "docNum": "0",
                "traDate": "2025/Feb/03",
                "explEng": "OUTWARD TRANSFER (E)"
              },
              {
                "valDate": "2025/Feb/03",
                "intDate": "2025/Feb/03",
                "traAmt": "0.75",
                "curCode": "NGN",
                "remarks": "VAT ON FEE:MOB: To PALMPAY|ADEOLA OLAOYE GBADEBO ./2025020113414922B998155663507",
                "drCrDesc": "D",
                "balAfter": "937414.91",
                "docNum": "0",
                "traDate": "2025/Feb/03",
                "explEng": "VAT"
              },
              {
                "valDate": "2025/Feb/03",
                "intDate": "2025/Feb/03",
                "traAmt": "10",
                "curCode": "NGN",
                "remarks": "FEE:MOB: To PALMPAY|ADEOLA OLAOYE GBADEBO ./2025020113414922B998155663507",
                "drCrDesc": "D",
                "balAfter": "937415.66",
                "docNum": "0",
                "traDate": "2025/Feb/03",
                "explEng": "COMMISSION"
              },
              {
                "valDate": "2025/Feb/03",
                "intDate": "2025/Feb/03",
                "traAmt": "2100",
                "curCode": "NGN",
                "remarks": "MOB: To PALMPAY|ADEOLA OLAOYE GBADEBO ./2025020113414922B998155663507",
                "drCrDesc": "D",
                "balAfter": "937425.66",
                "docNum": "0",
                "traDate": "2025/Feb/03",
                "explEng": "OUTWARD TRANSFER (E)"
              },
              {
                "valDate": "2025/Feb/03",
                "intDate": "2025/Feb/03",
                "traAmt": "1.88",
                "curCode": "NGN",
                "remarks": "VAT ON FEE:MOB: To Access Bank|EMMANUEL CHIDI  IFEZULIGBO ./20250201122548080440731276965",
                "drCrDesc": "D",
                "balAfter": "939525.66",
                "docNum": "0",
                "traDate": "2025/Feb/03",
                "explEng": "VAT"
              },
              {
                "valDate": "2025/Feb/03",
                "intDate": "2025/Feb/03",
                "traAmt": "25",
                "curCode": "NGN",
                "remarks": "FEE:MOB: To Access Bank|EMMANUEL CHIDI  IFEZULIGBO ./20250201122548080440731276965",
                "drCrDesc": "D",
                "balAfter": "939527.54",
                "docNum": "0",
                "traDate": "2025/Feb/03",
                "explEng": "COMMISSION"
              },
              {
                "valDate": "2025/Feb/03",
                "intDate": "2025/Feb/03",
                "traAmt": "17500",
                "curCode": "NGN",
                "remarks": "MOB: To Access Bank|EMMANUEL CHIDI  IFEZULIGBO ./20250201122548080440731276965",
                "drCrDesc": "D",
                "balAfter": "939552.54",
                "docNum": "0",
                "traDate": "2025/Feb/03",
                "explEng": "OUTWARD TRANSFER (E)"
              },
              {
                "valDate": "2025/Feb/03",
                "intDate": "2025/Feb/03",
                "traAmt": "1.88",
                "curCode": "NGN",
                "remarks": "MOB: To Sterling Bank|JOSEPH ABISOLA OJO ./000023250201115831008140071859",
                "drCrDesc": "D",
                "balAfter": "957052.54",
                "docNum": "449139",
                "traDate": "2025/Feb/03",
                "explEng": "VAT"
              },
              {
                "valDate": "2025/Feb/03",
                "intDate": "2025/Feb/03",
                "traAmt": "25",
                "curCode": "NGN",
                "remarks": "MOB: To Sterling Bank|JOSEPH ABISOLA OJO ./000023250201115831008140071859",
                "drCrDesc": "D",
                "balAfter": "957054.42",
                "docNum": "449139",
                "traDate": "2025/Feb/03",
                "explEng": "COMMISSION"
              },
              {
                "valDate": "2025/Feb/03",
                "intDate": "2025/Feb/03",
                "traAmt": "25000",
                "curCode": "NGN",
                "remarks": "MOB: To Sterling Bank|JOSEPH ABISOLA OJO ./000023250201115831008140071859",
                "drCrDesc": "D",
                "balAfter": "957079.42",
                "docNum": "449139",
                "traDate": "2025/Feb/03",
                "explEng": "OUTWARD TRANSFER (N)"
              },
              {
                "valDate": "2025/Jan/31",
                "intDate": "2025/Jan/31",
                "traAmt": "516",
                "curCode": "NGN",
                "remarks": " ",
                "drCrDesc": "D",
                "balAfter": "982079.42",
                "docNum": "0",
                "traDate": "2025/Jan/31",
                "explEng": "WITHHOLDING TAX"
              },
              {
                "valDate": "2025/Jan/31",
                "intDate": "2025/Jan/31",
                "traAmt": "5155.43",
                "curCode": "NGN",
                "remarks": "  ",
                "drCrDesc": "C",
                "balAfter": "982595.42",
                "docNum": "0",
                "traDate": "2025/Jan/31",
                "explEng": "INTEREST CAPITALISED"
              },
              {
                "valDate": "2025/Jan/31",
                "intDate": "2025/Jan/31",
                "traAmt": "32",
                "curCode": "NGN",
                "remarks": "SMS ALERT CHARGE for 1st - 31stJAN 2025",
                "drCrDesc": "D",
                "balAfter": "977439.99",
                "docNum": "0",
                "traDate": "2025/Jan/31",
                "explEng": "MISC."
              },
              {
                "valDate": "2025/Jan/31",
                "intDate": "2025/Jan/31",
                "traAmt": "1.88",
                "curCode": "NGN",
                "remarks": "MOB: To MONIEPOINT MICROFINANCE BANK|JAYS AMERICAN DINER LTD ./000023250131213429008140007158",
                "drCrDesc": "D",
                "balAfter": "977471.99",
                "docNum": "1839523",
                "traDate": "2025/Jan/31",
                "explEng": "VAT"
              },
              {
                "valDate": "2025/Jan/31",
                "intDate": "2025/Jan/31",
                "traAmt": "25",
                "curCode": "NGN",
                "remarks": "MOB: To MONIEPOINT MICROFINANCE BANK|JAYS AMERICAN DINER LTD ./000023250131213429008140007158",
                "drCrDesc": "D",
                "balAfter": "977473.87",
                "docNum": "1839523",
                "traDate": "2025/Jan/31",
                "explEng": "COMMISSION"
              },
              {
                "valDate": "2025/Jan/31",
                "intDate": "2025/Jan/31",
                "traAmt": "10300",
                "curCode": "NGN",
                "remarks": "MOB: To MONIEPOINT MICROFINANCE BANK|JAYS AMERICAN DINER LTD ./000023250131213429008140007158",
                "drCrDesc": "D",
                "balAfter": "977498.87",
                "docNum": "1839523",
                "traDate": "2025/Jan/31",
                "explEng": "OUTWARD TRANSFER (N)"
              },
              {
                "valDate": "2025/Jan/30",
                "intDate": "2025/Jan/30",
                "traAmt": "1.88",
                "curCode": "NGN",
                "remarks": "MOB: To MONIEPOINT MICROFINANCE BANK|WINNI STORES  AND DISTRIBUTION ENTERPRISES ./000023250130194349008139855553",
                "drCrDesc": "D",
                "balAfter": "987798.87",
                "docNum": "1311847",
                "traDate": "2025/Jan/30",
                "explEng": "VAT"
              },
              {
                "valDate": "2025/Jan/30",
                "intDate": "2025/Jan/30",
                "traAmt": "25",
                "curCode": "NGN",
                "remarks": "MOB: To MONIEPOINT MICROFINANCE BANK|WINNI STORES  AND DISTRIBUTION ENTERPRISES ./000023250130194349008139855553",
                "drCrDesc": "D",
                "balAfter": "987800.75",
                "docNum": "1311847",
                "traDate": "2025/Jan/30",
                "explEng": "COMMISSION"
              },
              {
                "valDate": "2025/Jan/30",
                "intDate": "2025/Jan/30",
                "traAmt": "16050",
                "curCode": "NGN",
                "remarks": "MOB: To MONIEPOINT MICROFINANCE BANK|WINNI STORES  AND DISTRIBUTION ENTERPRISES ./000023250130194349008139855553",
                "drCrDesc": "D",
                "balAfter": "987825.75",
                "docNum": "1311847",
                "traDate": "2025/Jan/30",
                "explEng": "OUTWARD TRANSFER (N)"
              },
              {
                "valDate": "2025/Jan/30",
                "intDate": "2025/Jan/30",
                "traAmt": "1.88",
                "curCode": "NGN",
                "remarks": "MOB: To GTBank Plc|SHOFULE  TEMITOPE OLUMUYIWA ./000023250130115809008139788746",
                "drCrDesc": "D",
                "balAfter": "1003875.75",
                "docNum": "501849",
                "traDate": "2025/Jan/30",
                "explEng": "VAT"
              },
              {
                "valDate": "2025/Jan/30",
                "intDate": "2025/Jan/30",
                "traAmt": "25",
                "curCode": "NGN",
                "remarks": "MOB: To GTBank Plc|SHOFULE  TEMITOPE OLUMUYIWA ./000023250130115809008139788746",
                "drCrDesc": "D",
                "balAfter": "1003877.63",
                "docNum": "501849",
                "traDate": "2025/Jan/30",
                "explEng": "COMMISSION"
              },
              {
                "valDate": "2025/Jan/30",
                "intDate": "2025/Jan/30",
                "traAmt": "50000",
                "curCode": "NGN",
                "remarks": "MOB: To GTBank Plc|SHOFULE  TEMITOPE OLUMUYIWA ./000023250130115809008139788746",
                "drCrDesc": "D",
                "balAfter": "1003902.63",
                "docNum": "501849",
                "traDate": "2025/Jan/30",
                "explEng": "OUTWARD TRANSFER (N)"
              },
              {
                "valDate": "2025/Jan/30",
                "intDate": "2025/Jan/30",
                "traAmt": "3.75",
                "curCode": "NGN",
                "remarks": "MOB: To GTBank Plc|WILLIAMS DANIEL UKPONO ./000023250130115625008139788483",
                "drCrDesc": "D",
                "balAfter": "1053902.63",
                "docNum": "496910",
                "traDate": "2025/Jan/30",
                "explEng": "VAT"
              },
              {
                "valDate": "2025/Jan/30",
                "intDate": "2025/Jan/30",
                "traAmt": "50",
                "curCode": "NGN",
                "remarks": "MOB: To GTBank Plc|WILLIAMS DANIEL UKPONO ./000023250130115625008139788483",
                "drCrDesc": "D",
                "balAfter": "1053906.38",
                "docNum": "496910",
                "traDate": "2025/Jan/30",
                "explEng": "COMMISSION"
              },
              {
                "valDate": "2025/Jan/30",
                "intDate": "2025/Jan/30",
                "traAmt": "70000",
                "curCode": "NGN",
                "remarks": "MOB: To GTBank Plc|WILLIAMS DANIEL UKPONO ./000023250130115625008139788483",
                "drCrDesc": "D",
                "balAfter": "1053956.38",
                "docNum": "496910",
                "traDate": "2025/Jan/30",
                "explEng": "OUTWARD TRANSFER (N)"
              },
              {
                "valDate": "2025/Jan/30",
                "intDate": "2025/Jan/30",
                "traAmt": "1075",
                "curCode": "NGN",
                "remarks": "MasterCard Enrollment fee. Account:6506200432",
                "drCrDesc": "D",
                "balAfter": "1123956.38",
                "docNum": "0",
                "traDate": "2025/Jan/30",
                "explEng": "CARD ENROLLMENT"
              },
              {
                "valDate": "2025/Jan/30",
                "intDate": "2025/Jan/30",
                "traAmt": "4000",
                "curCode": "NGN",
                "remarks": "MasterCard Lifestyle Enrollment fee. Account:6506200432",
                "drCrDesc": "D",
                "balAfter": "1125031.38",
                "docNum": "0",
                "traDate": "2025/Jan/30",
                "explEng": "CARD ENROLLMENT"
              },
              {
                "valDate": "2025/Jan/29",
                "intDate": "2025/Jan/29",
                "traAmt": "50",
                "curCode": "NGN",
                "remarks": "FROM WEMA/ EDOC ONLINE NIGERIA LIMITED6506200432-Ojo Joseph Abisola/000017250129140255529788095435",
                "drCrDesc": "D",
                "balAfter": "1129031.38",
                "docNum": "782176",
                "traDate": "2025/Jan/29",
                "explEng": "ELECTRONIC MONEY TRANSFER LEVY"
              },
              {
                "valDate": "2025/Jan/29",
                "intDate": "2025/Jan/29",
                "traAmt": "425000",
                "curCode": "NGN",
                "remarks": "FROM WEMA/ EDOC ONLINE NIGERIA LIMITED6506200432-Ojo Joseph Abisola/000017250129140255529788095435",
                "drCrDesc": "C",
                "balAfter": "1129081.38",
                "docNum": "782176",
                "traDate": "2025/Jan/29",
                "explEng": "INWARD TRANSFER (N)"
              },
              {
                "valDate": "2024/Dec/31",
                "intDate": "2024/Dec/31",
                "traAmt": "341",
                "curCode": "NGN",
                "remarks": " ",
                "drCrDesc": "D",
                "balAfter": "704081.38",
                "docNum": "0",
                "traDate": "2024/Dec/31",
                "explEng": "WITHHOLDING TAX"
              },
              {
                "valDate": "2024/Dec/31",
                "intDate": "2024/Dec/31",
                "traAmt": "3406.2",
                "curCode": "NGN",
                "remarks": "  ",
                "drCrDesc": "C",
                "balAfter": "704422.38",
                "docNum": "0",
                "traDate": "2024/Dec/31",
                "explEng": "INTEREST CAPITALISED"
              },
              {
                "valDate": "2024/Dec/31",
                "intDate": "2024/Dec/31",
                "traAmt": "12",
                "curCode": "NGN",
                "remarks": "SMS ALERT CHARGE for 1st - 31stDEC 2024",
                "drCrDesc": "D",
                "balAfter": "701016.18",
                "docNum": "0",
                "traDate": "2024/Dec/31",
                "explEng": "MISC."
              },
              {
                "valDate": "2024/Dec/20",
                "intDate": "2024/Dec/20",
                "traAmt": "50",
                "curCode": "NGN",
                "remarks": "FROM WEMA/ E-DOC ONLINE NIGERIA LIMITED_6506200432-Ojo Joseph Abisola/000017241220135038534998589372",
                "drCrDesc": "D",
                "balAfter": "701028.18",
                "docNum": "781122",
                "traDate": "2024/Dec/20",
                "explEng": "ELECTRONIC MONEY TRANSFER LEVY"
              },
              {
                "valDate": "2024/Dec/20",
                "intDate": "2024/Dec/20",
                "traAmt": "350000",
                "curCode": "NGN",
                "remarks": "FROM WEMA/ E-DOC ONLINE NIGERIA LIMITED_6506200432-Ojo Joseph Abisola/000017241220135038534998589372",
                "drCrDesc": "C",
                "balAfter": "701078.18",
                "docNum": "781122",
                "traDate": "2024/Dec/20",
                "explEng": "INWARD TRANSFER (N)"
              },
              {
                "valDate": "2024/Nov/29",
                "intDate": "2024/Nov/29",
                "traAmt": "17",
                "curCode": "NGN",
                "remarks": " ",
                "drCrDesc": "D",
                "balAfter": "351078.18",
                "docNum": "0",
                "traDate": "2024/Nov/29",
                "explEng": "WITHHOLDING TAX"
              },
              {
                "valDate": "2024/Nov/29",
                "intDate": "2024/Nov/29",
                "traAmt": "161.18",
                "curCode": "NGN",
                "remarks": "  ",
                "drCrDesc": "C",
                "balAfter": "351095.18",
                "docNum": "0",
                "traDate": "2024/Nov/29",
                "explEng": "INTEREST CAPITALISED"
              },
              {
                "valDate": "2024/Nov/29",
                "intDate": "2024/Nov/29",
                "traAmt": "16",
                "curCode": "NGN",
                "remarks": "SMS ALERT CHARGE for 1st - 29thNOV 2024",
                "drCrDesc": "D",
                "balAfter": "350934",
                "docNum": "0",
                "traDate": "2024/Nov/29",
                "explEng": "MISC."
              },
              {
                "valDate": "2024/Nov/29",
                "intDate": "2024/Nov/29",
                "traAmt": "50",
                "curCode": "NGN",
                "remarks": "FROM WEMA/ E-DOC ONLINE NIGERIA LIMITED_6506200432-TRF FRM E-DOC ONLINE NIGERIA LIMITED/000017241129125510236557069813",
                "drCrDesc": "D",
                "balAfter": "350950",
                "docNum": "676835",
                "traDate": "2024/Nov/29",
                "explEng": "ELECTRONIC MONEY TRANSFER LEVY"
              },
              {
                "valDate": "2024/Nov/29",
                "intDate": "2024/Nov/29",
                "traAmt": "350000",
                "curCode": "NGN",
                "remarks": "FROM WEMA/ E-DOC ONLINE NIGERIA LIMITED_6506200432-TRF FRM E-DOC ONLINE NIGERIA LIMITED/000017241129125510236557069813",
                "drCrDesc": "C",
                "balAfter": "351000",
                "docNum": "676835",
                "traDate": "2024/Nov/29",
                "explEng": "INWARD TRANSFER (N)"
              },
              {
                "valDate": "2024/Nov/11",
                "intDate": "2024/Nov/11",
                "traAmt": "1000",
                "curCode": "NGN",
                "remarks": "FROM STERLING/ JOSEPH ABISOLA OJO-Transfer from JOSEPH ABISOLA OJO to Ojo Joseph Abisola/000001241111171903740948908266",
                "drCrDesc": "C",
                "balAfter": "1000",
                "docNum": "3143482",
                "traDate": "2024/Nov/11",
                "explEng": "INWARD TRANSFER (N)"
              }
            ]
          },
      },
      {
        label: "Union",
        value: "Union",
        data: {
          "code": "00",
          "message": "Successful",
          "reference": "",
          "guid": null,
          "items": [
              {
                  "trnDate": 1654038000000,
                  "postDate": 1654038000000,
                  "valueDate": -61460038800000,
                  "narration": "Opening Balance",
                  "reference": null,
                  "ccy": null,
                  "drcrFlag": "C",
                  "crAmount": 7938.84,
                  "drAmount": null,
                  "runningBalance": 7938.84
              },
              {
                  "trnDate": 1655161200000,
                  "postDate": 1655161200000,
                  "valueDate": -61458915600000,
                  "narration": "USSD/QT PYMT/Airtime Recharge on 2348034837816",
                  "reference": "CVU373431122238664610",
                  "ccy": null,
                  "drcrFlag": "D",
                  "crAmount": null,
                  "drAmount": 1000,
                  "runningBalance": 6938.84
              },
              {
                  "trnDate": 1656284400000,
                  "postDate": 1656284400000,
                  "valueDate": -61457792400000,
                  "narration": "USSD/QT PYMT/Airtime Recharge on 2348034837816",
                  "reference": "CVU1416445517967348306",
                  "ccy": null,
                  "drcrFlag": "D",
                  "crAmount": null,
                  "drAmount": 200,
                  "runningBalance": 6738.84
              },
              {
                  "trnDate": 1656284400000,
                  "postDate": 1656284400000,
                  "valueDate": -61457792400000,
                  "narration": "USSD/REVL-P-QT PYMT/Airtime Recharge on 2348034837816",
                  "reference": "CVRU1416445517967348306",
                  "ccy": null,
                  "drcrFlag": "C",
                  "crAmount": 200,
                  "drAmount": null,
                  "runningBalance": 6938.84
              },
              {
                  "trnDate": 1656543600000,
                  "postDate": 1656543600000,
                  "valueDate": -61457533200000,
                  "narration": "ACCOUNT MAINTENANCE FEE",
                  "reference": null,
                  "ccy": null,
                  "drcrFlag": "D",
                  "crAmount": null,
                  "drAmount": 1,
                  "runningBalance": 6937.84
              },
              {
                  "trnDate": 1656543600000,
                  "postDate": 1656543600000,
                  "valueDate": -61457533200000,
                  "narration": "VAT ON  ACCOUNT MAINTENANCE FEE",
                  "reference": null,
                  "ccy": null,
                  "drcrFlag": "D",
                  "crAmount": null,
                  "drAmount": 0.08,
                  "runningBalance": 6937.76
              },
              {
                  "trnDate": 1657839600000,
                  "postDate": 1657839600000,
                  "valueDate": -61456237200000,
                  "narration": "ATR@00000000/000345088276;NXG :TRFIFEOMAFRMABDULLAHIU",
                  "reference": "000345088276",
                  "ccy": null,
                  "drcrFlag": "C",
                  "crAmount": 123000,
                  "drAmount": null,
                  "runningBalance": 129937.76
              },
              {
                  "trnDate": 1657839600000,
                  "postDate": 1657839600000,
                  "valueDate": -61456237200000,
                  "narration": "MOBILE/UNION Transfer to IFEOMA PRECIOUS ONYEMIZE - Exchange",
                  "reference": "CV000018220715140744000410495114",
                  "ccy": null,
                  "drcrFlag": "D",
                  "crAmount": null,
                  "drAmount": 120000,
                  "runningBalance": 9937.76
              },
              {
                  "trnDate": 1657839600000,
                  "postDate": 1657839600000,
                  "valueDate": -61456237200000,
                  "narration": "CHARGES+VAT:MOBILE/UNION Transfer to IFEOMA PRECIOUS ONYEMIZE - Exchange",
                  "reference": "CV000018220715140744000410495114",
                  "ccy": null,
                  "drcrFlag": "D",
                  "crAmount": null,
                  "drAmount": 53.75,
                  "runningBalance": 9884.01
              },
              {
                  "trnDate": 1657839600000,
                  "postDate": 1657839600000,
                  "valueDate": -61456237200000,
                  "narration": "ELECTRONIC MONEY TRANSFER LEVY",
                  "reference": null,
                  "ccy": null,
                  "drcrFlag": "D",
                  "crAmount": null,
                  "drAmount": 50,
                  "runningBalance": 9834.01
              },
              {
                  "trnDate": 1657926000000,
                  "postDate": 1657926000000,
                  "valueDate": -61456150800000,
                  "narration": "SI IFO 0039895360 BO 0067235927",
                  "reference": null,
                  "ccy": null,
                  "drcrFlag": "D",
                  "crAmount": null,
                  "drAmount": 2000,
                  "runningBalance": 7834.01
              },
              {
                  "trnDate": 1657926000000,
                  "postDate": 1657926000000,
                  "valueDate": -61456150800000,
                  "narration": "Mobile Transfer from ENANG AUGUSTINE  C – NA",
                  "reference": "CV6759412367778935680",
                  "ccy": null,
                  "drcrFlag": "C",
                  "crAmount": 12000,
                  "drAmount": null,
                  "runningBalance": 19834.01
              },
              {
                  "trnDate": 1657926000000,
                  "postDate": 1657926000000,
                  "valueDate": -61456150800000,
                  "narration": "ELECTRONIC MONEY TRANSFER LEVY",
                  "reference": null,
                  "ccy": null,
                  "drcrFlag": "D",
                  "crAmount": null,
                  "drAmount": 50,
                  "runningBalance": 19784.01
              },
              {
                  "trnDate": 1658098800000,
                  "postDate": 1658098800000,
                  "valueDate": -61455978000000,
                  "narration": "CHARGES+VAT:MOBILE/UNION Transfer to KUKOYI EVELYN ISIMEME - From Augustine",
                  "reference": "CV000018220718090702000411546470",
                  "ccy": null,
                  "drcrFlag": "D",
                  "crAmount": null,
                  "drAmount": 26.88,
                  "runningBalance": 19757.13
              },
              {
                  "trnDate": 1658098800000,
                  "postDate": 1658098800000,
                  "valueDate": -61455978000000,
                  "narration": "MOBILE/UNION Transfer to KUKOYI EVELYN ISIMEME - From Augustine",
                  "reference": "CV000018220718090702000411546470",
                  "ccy": null,
                  "drcrFlag": "D",
                  "crAmount": null,
                  "drAmount": 12000,
                  "runningBalance": 7757.13
              },
              {
                  "trnDate": 1658444400000,
                  "postDate": 1658444400000,
                  "valueDate": -61455632400000,
                  "narration": "SMS CHARGES,July 2022",
                  "reference": "GF2022072249570880",
                  "ccy": null,
                  "drcrFlag": "D",
                  "crAmount": null,
                  "drAmount": 20,
                  "runningBalance": 7737.13
              },
              {
                  "trnDate": 1658703600000,
                  "postDate": 1658703600000,
                  "valueDate": -61455373200000,
                  "narration": "USSD/QT PYMT/Airtime Recharge on 2348034837816",
                  "reference": "CVU437370585580515640",
                  "ccy": null,
                  "drcrFlag": "D",
                  "crAmount": null,
                  "drAmount": 500,
                  "runningBalance": 7237.13
              },
              {
                  "trnDate": 1659222000000,
                  "postDate": 1659222000000,
                  "valueDate": -61454854800000,
                  "narration": "ACCOUNT MAINTENANCE FEE",
                  "reference": null,
                  "ccy": null,
                  "drcrFlag": "D",
                  "crAmount": null,
                  "drAmount": 132.5,
                  "runningBalance": 7104.63
              },
              {
                  "trnDate": 1659222000000,
                  "postDate": 1659222000000,
                  "valueDate": -61454854800000,
                  "narration": "VAT ON  ACCOUNT MAINTENANCE FEE",
                  "reference": null,
                  "ccy": null,
                  "drcrFlag": "D",
                  "crAmount": null,
                  "drAmount": 9.94,
                  "runningBalance": 7094.69
              }
          ]
      },
      },
      {
        label: "Heritage Bank",
        value: "HeritageBank",
        data: {
          "responseCode": "000",
          "responseMessage": "Account Statement Inquiry Successful",
          "transactionDetail": [
              {
                  "sn": "1",
                  "transactionId": "     S000",
                  "transactionDate": "1/1/2022 12:00:00 AM",
                  "valueDate": "1/1/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "0",
                  "narration": "Opening Balance",
                  "narration2": "------",
                  "remarks": "------",
                  "balance": "6231.49"
              },
              {
                  "sn": "2",
                  "transactionId": "  S909753",
                  "transactionDate": "1/4/2022 12:00:00 AM",
                  "valueDate": "1/4/2022 12:00:00 AM",
                  "debitAmount": "6000",
                  "creditAmount": "0",
                  "narration": "Trf - Refund",
                  "narration2": "000020220104094812004002932953/O0106161053",
                  "remarks": "MOBILE/OKELEKE VINCENT AWELE",
                  "balance": "231.49"
              },
              {
                  "sn": "3",
                  "transactionId": "  S909753",
                  "transactionDate": "1/4/2022 12:00:00 AM",
                  "valueDate": "1/4/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Refund",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/OKELEKE VINCENT AWELE",
                  "balance": "204.61"
              },
              {
                  "sn": "4",
                  "transactionId": "  S544558",
                  "transactionDate": "1/6/2022 12:00:00 AM",
                  "valueDate": "1/6/2022 12:00:00 AM",
                  "debitAmount": "200",
                  "creditAmount": "0",
                  "narration": "Mob Trf self",
                  "narration2": "BLEND-LOCAL TRANSFER:-e02a8856-133c-4ccd-b5cc-7e21",
                  "remarks": "5300017669 / 1900723085/375271",
                  "balance": "4.61"
              },
              {
                  "sn": "5",
                  "transactionId": "  S735020",
                  "transactionDate": "1/24/2022 12:00:00 AM",
                  "valueDate": "1/24/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "137277.12",
                  "narration": "JANUARY 2022 SALARY",
                  "narration2": "",
                  "remarks": "",
                  "balance": "137281.73"
              },
              {
                  "sn": "6",
                  "transactionId": "  S735875",
                  "transactionDate": "1/24/2022 12:00:00 AM",
                  "valueDate": "1/24/2022 12:00:00 AM",
                  "debitAmount": "1000",
                  "creditAmount": "0",
                  "narration": "JAN 2022 ASSBIFI  DUES",
                  "narration2": "",
                  "remarks": "",
                  "balance": "136281.73"
              },
              {
                  "sn": "7",
                  "transactionId": "  S758922",
                  "transactionDate": "1/24/2022 12:00:00 AM",
                  "valueDate": "1/24/2022 12:00:00 AM",
                  "debitAmount": "132000",
                  "creditAmount": "0",
                  "narration": "Mob Trf self",
                  "narration2": "BLEND-LOCAL TRANSFER:-84523b0b-adbc-4918-bbf8-aaa3",
                  "remarks": "5300017669 / 1900723085/291575",
                  "balance": "4281.73"
              },
              {
                  "sn": "8",
                  "transactionId": "  S783284",
                  "transactionDate": "1/26/2022 12:00:00 AM",
                  "valueDate": "1/26/2022 12:00:00 AM",
                  "debitAmount": "4200",
                  "creditAmount": "0",
                  "narration": "INW self",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/637788027849",
                  "balance": "81.73"
              },
              {
                  "sn": "9",
                  "transactionId": "S10777859",
                  "transactionDate": "2/23/2022 12:00:00 AM",
                  "valueDate": "2/23/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "1500",
                  "narration": "web:self",
                  "narration2": "",
                  "remarks": "1900723085/12292934",
                  "balance": "1581.73"
              },
              {
                  "sn": "10",
                  "transactionId": "S10793573",
                  "transactionDate": "2/23/2022 12:00:00 AM",
                  "valueDate": "2/23/2022 12:00:00 AM",
                  "debitAmount": "1075",
                  "creditAmount": "0",
                  "narration": "Being Cost of Verve card ReIssuance And VAT",
                  "narration2": "",
                  "remarks": "Wed011224044/",
                  "balance": "506.73"
              },
              {
                  "sn": "11",
                  "transactionId": "    M4269",
                  "transactionDate": "2/24/2022 12:00:00 AM",
                  "valueDate": "2/24/2022 12:00:00 AM",
                  "debitAmount": "1500",
                  "creditAmount": "0",
                  "narration": "STAFF WELFARE DEDUCTION FOR FEBRUARY 2022",
                  "narration2": "",
                  "remarks": "",
                  "balance": "-993.27"
              },
              {
                  "sn": "12",
                  "transactionId": "S11492937",
                  "transactionDate": "2/24/2022 12:00:00 AM",
                  "valueDate": "2/24/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "137277.12",
                  "narration": "FEBRUARY 2022 SALARY",
                  "narration2": "",
                  "remarks": "",
                  "balance": "136283.85"
              },
              {
                  "sn": "13",
                  "transactionId": "S11492990",
                  "transactionDate": "2/24/2022 12:00:00 AM",
                  "valueDate": "2/24/2022 12:00:00 AM",
                  "debitAmount": "1000",
                  "creditAmount": "0",
                  "narration": "FEB 2022 ASSBIFI  DUES",
                  "narration2": "",
                  "remarks": "",
                  "balance": "135283.85"
              },
              {
                  "sn": "14",
                  "transactionId": "S11516562",
                  "transactionDate": "2/24/2022 12:00:00 AM",
                  "valueDate": "2/24/2022 12:00:00 AM",
                  "debitAmount": "50000",
                  "creditAmount": "0",
                  "narration": "INW ajo",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/637812888625",
                  "balance": "85283.85"
              },
              {
                  "sn": "15",
                  "transactionId": "S11517070",
                  "transactionDate": "2/24/2022 12:00:00 AM",
                  "valueDate": "2/24/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "30000",
                  "narration": "INW self",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/637812889441",
                  "balance": "115283.85"
              },
              {
                  "sn": "16",
                  "transactionId": "S11518270",
                  "transactionDate": "2/24/2022 12:00:00 AM",
                  "valueDate": "2/24/2022 12:00:00 AM",
                  "debitAmount": "100000",
                  "creditAmount": "0",
                  "narration": "Trf - Octiplus: repay",
                  "narration2": "000020220224094450009003544632/O2034907377",
                  "remarks": "USSD/OKELEKE PATRICK CHINEDU",
                  "balance": "15283.85"
              },
              {
                  "sn": "17",
                  "transactionId": "S11518270",
                  "transactionDate": "2/24/2022 12:00:00 AM",
                  "valueDate": "2/24/2022 12:00:00 AM",
                  "debitAmount": "53.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Octiplus: repay",
                  "narration2": "FEE EXEMPT",
                  "remarks": "USSD/OKELEKE PATRICK CHINEDU",
                  "balance": "15230.1"
              },
              {
                  "sn": "18",
                  "transactionId": "S11821398",
                  "transactionDate": "2/24/2022 12:00:00 AM",
                  "valueDate": "2/24/2022 12:00:00 AM",
                  "debitAmount": "3500",
                  "creditAmount": "0",
                  "narration": "BPW MTN Airtime Purchase07033671485-90304",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/196043331586",
                  "balance": "11730.1"
              },
              {
                  "sn": "19",
                  "transactionId": "S11861887",
                  "transactionDate": "2/25/2022 12:00:00 AM",
                  "valueDate": "2/25/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "ATM WD on 25-02-2022@34 Adeniyi Jones S",
                  "narration2": "",
                  "remarks": "10300103/3744/94214824",
                  "balance": "6730.1"
              },
              {
                  "sn": "20",
                  "transactionId": "S12813415",
                  "transactionDate": "2/28/2022 12:00:00 AM",
                  "valueDate": "2/27/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "12000",
                  "narration": "000-OSERU JACKSON O-TRF BO OSERU JACKS",
                  "narration2": "000002220227194025135993319561/I6014125894",
                  "remarks": "NIP/NIP/OSERU JACKSON O",
                  "balance": "18730.1"
              },
              {
                  "sn": "21",
                  "transactionId": "S12813415",
                  "transactionDate": "2/28/2022 12:00:00 AM",
                  "valueDate": "2/27/2022 12:00:00 AM",
                  "debitAmount": "50",
                  "creditAmount": "0",
                  "narration": "STAMP DUTY CHARGE ON DEPOSIT: 5300017669",
                  "narration2": "FEE EXEMPT",
                  "remarks": "NIP/NIP/OSERU JACKSON O",
                  "balance": "18680.1"
              },
              {
                  "sn": "22",
                  "transactionId": "S12815759",
                  "transactionDate": "2/28/2022 12:00:00 AM",
                  "valueDate": "2/27/2022 12:00:00 AM",
                  "debitAmount": "12700",
                  "creditAmount": "0",
                  "narration": "Trf - Octiplus: bills",
                  "narration2": "000020220227202817009003592277/O1100108278",
                  "remarks": "USSD/OCI INTERNATIONAL LIMITED",
                  "balance": "5980.1"
              },
              {
                  "sn": "23",
                  "transactionId": "S12815759",
                  "transactionDate": "2/28/2022 12:00:00 AM",
                  "valueDate": "2/27/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Octiplus: bills",
                  "narration2": "FEE EXEMPT",
                  "remarks": "USSD/OCI INTERNATIONAL LIMITED",
                  "balance": "5953.22"
              },
              {
                  "sn": "24",
                  "transactionId": "S13309691",
                  "transactionDate": "2/28/2022 12:00:00 AM",
                  "valueDate": "2/28/2022 12:00:00 AM",
                  "debitAmount": "3300",
                  "creditAmount": "0",
                  "narration": "Trf - fuel",
                  "narration2": "000020220228183518004003603700/O0018827982",
                  "remarks": "MOBILE/FAUSTINA ULOMA FERGUSON",
                  "balance": "2653.22"
              },
              {
                  "sn": "25",
                  "transactionId": "S13309691",
                  "transactionDate": "2/28/2022 12:00:00 AM",
                  "valueDate": "2/28/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - fuel",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/FAUSTINA ULOMA FERGUSON",
                  "balance": "2642.47"
              },
              {
                  "sn": "26",
                  "transactionId": "S13633269",
                  "transactionDate": "3/1/2022 12:00:00 AM",
                  "valueDate": "3/1/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "107000",
                  "narration": "Mob Trf self",
                  "narration2": "BLEND-LOCAL TRANSFER:-95b38345-1d1c-456d-9c97-2270",
                  "remarks": "1300012264 / 5300017669/580523",
                  "balance": "109642.47"
              },
              {
                  "sn": "27",
                  "transactionId": "S13633755",
                  "transactionDate": "3/1/2022 12:00:00 AM",
                  "valueDate": "3/1/2022 12:00:00 AM",
                  "debitAmount": "25000",
                  "creditAmount": "0",
                  "narration": "Mob Trf contr",
                  "narration2": "BLEND-LOCAL TRANSFER:-0ccab131-4f12-4824-8b26-4627",
                  "remarks": "5300017669 / 1913396342/142592",
                  "balance": "84642.47"
              },
              {
                  "sn": "28",
                  "transactionId": "S13634450",
                  "transactionDate": "3/1/2022 12:00:00 AM",
                  "valueDate": "3/1/2022 12:00:00 AM",
                  "debitAmount": "25000",
                  "creditAmount": "0",
                  "narration": "Trf - support",
                  "narration2": "000020220301100321004003610317/O2012594599",
                  "remarks": "MOBILE/OKELEKE ROSEMARY N",
                  "balance": "59642.47"
              },
              {
                  "sn": "29",
                  "transactionId": "S13634450",
                  "transactionDate": "3/1/2022 12:00:00 AM",
                  "valueDate": "3/1/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - support",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/OKELEKE ROSEMARY N",
                  "balance": "59615.59"
              },
              {
                  "sn": "30",
                  "transactionId": "S13635038",
                  "transactionDate": "3/1/2022 12:00:00 AM",
                  "valueDate": "3/1/2022 12:00:00 AM",
                  "debitAmount": "3000",
                  "creditAmount": "0",
                  "narration": "Mob Trf provision",
                  "narration2": "BLEND-LOCAL TRANSFER:-f078f8b3-646c-40f4-bd5c-e6ce",
                  "remarks": "5300017669 / 1001825680/200918",
                  "balance": "56615.59"
              },
              {
                  "sn": "31",
                  "transactionId": "S13736532",
                  "transactionDate": "3/1/2022 12:00:00 AM",
                  "valueDate": "3/1/2022 12:00:00 AM",
                  "debitAmount": "10000",
                  "creditAmount": "0",
                  "narration": "ATM WD on 01-03-2022@34 Adeniyi Jones S",
                  "narration2": "",
                  "remarks": "10300102/5989/94474317",
                  "balance": "46615.59"
              },
              {
                  "sn": "32",
                  "transactionId": "S13893191",
                  "transactionDate": "3/1/2022 12:00:00 AM",
                  "valueDate": "3/1/2022 12:00:00 AM",
                  "debitAmount": "2400",
                  "creditAmount": "0",
                  "narration": "Trf - Octiplus: fuel",
                  "narration2": "000020220301210140009003617736/O0018827982",
                  "remarks": "USSD/FAUSTINA ULOMA FERGUSON",
                  "balance": "44215.59"
              },
              {
                  "sn": "33",
                  "transactionId": "S13893191",
                  "transactionDate": "3/1/2022 12:00:00 AM",
                  "valueDate": "3/1/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Octiplus: fuel",
                  "narration2": "FEE EXEMPT",
                  "remarks": "USSD/FAUSTINA ULOMA FERGUSON",
                  "balance": "44204.84"
              },
              {
                  "sn": "34",
                  "transactionId": "S14735104",
                  "transactionDate": "3/2/2022 12:00:00 AM",
                  "valueDate": "3/2/2022 12:00:00 AM",
                  "debitAmount": "10000",
                  "creditAmount": "0",
                  "narration": "ATM WD on 02-03-2022@FESTAC TOWN BR",
                  "narration2": "",
                  "remarks": "10440813/813506/206118813506",
                  "balance": "34204.84"
              },
              {
                  "sn": "35",
                  "transactionId": "S16015838",
                  "transactionDate": "3/7/2022 12:00:00 AM",
                  "valueDate": "3/5/2022 12:00:00 AM",
                  "debitAmount": "9100",
                  "creditAmount": "0",
                  "narration": "POS Trf on 05-03-2022@T AL  BAQI VENTURE",
                  "narration2": "",
                  "remarks": "2TEPTS18/14996/14996",
                  "balance": "25104.84"
              },
              {
                  "sn": "36",
                  "transactionId": "S16303258",
                  "transactionDate": "3/7/2022 12:00:00 AM",
                  "valueDate": "3/7/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "Mob Trf repay",
                  "narration2": "BLEND-LOCAL TRANSFER:-aca15dd7-d876-4458-94ad-e424",
                  "remarks": "5300017669 / 1001825965/210895",
                  "balance": "20104.84"
              },
              {
                  "sn": "37",
                  "transactionId": "S16566482",
                  "transactionDate": "3/8/2022 12:00:00 AM",
                  "valueDate": "3/8/2022 12:00:00 AM",
                  "debitAmount": "6500",
                  "creditAmount": "0",
                  "narration": "POS Buy on 08-03-2022@ADEWALE ADELEYE@00021000569",
                  "narration2": "",
                  "remarks": "2058CX44/6287/210005698",
                  "balance": "13604.84"
              },
              {
                  "sn": "38",
                  "transactionId": "S17086305",
                  "transactionDate": "3/8/2022 12:00:00 AM",
                  "valueDate": "3/8/2022 12:00:00 AM",
                  "debitAmount": "10000",
                  "creditAmount": "0",
                  "narration": "ATM WD on 08-03-2022@FESTAC TOWN BR",
                  "narration2": "",
                  "remarks": "10440811/616452/206717616452",
                  "balance": "3604.84"
              },
              {
                  "sn": "39",
                  "transactionId": "S17921729",
                  "transactionDate": "3/10/2022 12:00:00 AM",
                  "valueDate": "3/10/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "50000",
                  "narration": "Mob Trf self",
                  "narration2": "BLEND-LOCAL TRANSFER:-d4ea6601-a708-4ef1-aaf5-84f4",
                  "remarks": "1300012264 / 5300017669/124937",
                  "balance": "53604.84"
              },
              {
                  "sn": "40",
                  "transactionId": "S17923857",
                  "transactionDate": "3/10/2022 12:00:00 AM",
                  "valueDate": "3/10/2022 12:00:00 AM",
                  "debitAmount": "31000",
                  "creditAmount": "0",
                  "narration": "POS Buy on 10-03-2022@PENNYMART GLOBA@00001801296",
                  "narration2": "",
                  "remarks": "2070DW82/12962/18012962",
                  "balance": "22604.84"
              },
              {
                  "sn": "41",
                  "transactionId": "S18131430",
                  "transactionDate": "3/11/2022 12:00:00 AM",
                  "valueDate": "3/11/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "ATM WD on 11-03-2022@34 Adeniyi Jones S",
                  "narration2": "",
                  "remarks": "10300103/5992/95207991",
                  "balance": "17604.84"
              },
              {
                  "sn": "42",
                  "transactionId": "S18657358",
                  "transactionDate": "3/14/2022 12:00:00 AM",
                  "valueDate": "3/13/2022 12:00:00 AM",
                  "debitAmount": "5100",
                  "creditAmount": "0",
                  "narration": "POS Trf on 13-03-2022@T AL  BAQI VENTURE",
                  "narration2": "",
                  "remarks": "2TEPTS18/15568/15568",
                  "balance": "12504.84"
              },
              {
                  "sn": "43",
                  "transactionId": "S18674220",
                  "transactionDate": "3/14/2022 12:00:00 AM",
                  "valueDate": "3/13/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "POS Trf on 13-03-2022@T AL  BAQI VENTURE",
                  "narration2": "",
                  "remarks": "2TEPTS18/15585/15585",
                  "balance": "7504.84"
              },
              {
                  "sn": "44",
                  "transactionId": "S19168899",
                  "transactionDate": "3/15/2022 12:00:00 AM",
                  "valueDate": "3/15/2022 12:00:00 AM",
                  "debitAmount": "7000",
                  "creditAmount": "0",
                  "narration": "ATM WD on 15-03-2022@34 Adeniyi Jones S",
                  "narration2": "",
                  "remarks": "10300102/9376/95442854",
                  "balance": "504.84"
              },
              {
                  "sn": "45",
                  "transactionId": "S19208330",
                  "transactionDate": "3/15/2022 12:00:00 AM",
                  "valueDate": "3/15/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "20000",
                  "narration": "Mob Trf self",
                  "narration2": "BLEND-LOCAL TRANSFER:-03482c81-a8dc-4f70-977d-1b33",
                  "remarks": "1300012264 / 5300017669/102095",
                  "balance": "20504.84"
              },
              {
                  "sn": "46",
                  "transactionId": "S19215242",
                  "transactionDate": "3/15/2022 12:00:00 AM",
                  "valueDate": "3/15/2022 12:00:00 AM",
                  "debitAmount": "10700",
                  "creditAmount": "0",
                  "narration": "POS Trf on 15-03-2022@366 muritala moham",
                  "narration2": "",
                  "remarks": "2044ISX3/981515/4888965840",
                  "balance": "9804.84"
              },
              {
                  "sn": "47",
                  "transactionId": "S19736761",
                  "transactionDate": "3/17/2022 12:00:00 AM",
                  "valueDate": "3/17/2022 12:00:00 AM",
                  "debitAmount": "9000",
                  "creditAmount": "0",
                  "narration": "ATM WD on 17-03-2022@34 Adeniyi Jones S",
                  "narration2": "",
                  "remarks": "10300102/9500/95559264",
                  "balance": "804.84"
              },
              {
                  "sn": "48",
                  "transactionId": "S20308771",
                  "transactionDate": "3/21/2022 12:00:00 AM",
                  "valueDate": "3/20/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "10500",
                  "narration": "Mob Trf self",
                  "narration2": "BLEND-LOCAL TRANSFER:-a37857ac-cc36-4f99-8834-53af",
                  "remarks": "1900723085 / 5300017669/126718",
                  "balance": "11304.84"
              },
              {
                  "sn": "49",
                  "transactionId": "S20318369",
                  "transactionDate": "3/21/2022 12:00:00 AM",
                  "valueDate": "3/20/2022 12:00:00 AM",
                  "debitAmount": "2000",
                  "creditAmount": "0",
                  "narration": "Trf - support",
                  "narration2": "000020220320113723004003858568/O2283550472",
                  "remarks": "MOBILE/MATHEW NYONG NORBERT NY",
                  "balance": "9304.84"
              },
              {
                  "sn": "50",
                  "transactionId": "S20318369",
                  "transactionDate": "3/21/2022 12:00:00 AM",
                  "valueDate": "3/20/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - support",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/MATHEW NYONG NORBERT NY",
                  "balance": "9294.09"
              },
              {
                  "sn": "51",
                  "transactionId": "S20338038",
                  "transactionDate": "3/21/2022 12:00:00 AM",
                  "valueDate": "3/20/2022 12:00:00 AM",
                  "debitAmount": "5100",
                  "creditAmount": "0",
                  "narration": "POS Buy on 20-03-2022@OPAY DIGITAL SE@20320971599",
                  "narration2": "",
                  "remarks": "20501P9P/7593/203209715999",
                  "balance": "4194.09"
              },
              {
                  "sn": "52",
                  "transactionId": "S20356301",
                  "transactionDate": "3/21/2022 12:00:00 AM",
                  "valueDate": "3/20/2022 12:00:00 AM",
                  "debitAmount": "4000",
                  "creditAmount": "0",
                  "narration": "POS Buy on 20-03-2022@PAYCOM NIGERIA @20320030744",
                  "narration2": "",
                  "remarks": "2033WAK9/3261/203200307442",
                  "balance": "194.09"
              },
              {
                  "sn": "53",
                  "transactionId": "S20700327",
                  "transactionDate": "3/21/2022 12:00:00 AM",
                  "valueDate": "3/21/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "20000",
                  "narration": "Mob Trf self",
                  "narration2": "BLEND-LOCAL TRANSFER:-2c2e6232-0edd-4864-9854-7dd2",
                  "remarks": "1300012264 / 5300017669/135105",
                  "balance": "20194.09"
              },
              {
                  "sn": "54",
                  "transactionId": "S20703383",
                  "transactionDate": "3/21/2022 12:00:00 AM",
                  "valueDate": "3/21/2022 12:00:00 AM",
                  "debitAmount": "10150",
                  "creditAmount": "0",
                  "narration": "POS Trf on 21-03-2022@T AL  BAQI VENTURE",
                  "narration2": "",
                  "remarks": "2TEPTS18/104/104",
                  "balance": "10044.09"
              },
              {
                  "sn": "55",
                  "transactionId": "S20872242",
                  "transactionDate": "3/22/2022 12:00:00 AM",
                  "valueDate": "3/22/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "Trf - Borr",
                  "narration2": "000020220322111936004003881009/O0169862700",
                  "remarks": "MOBILE/AKINMOLADUN  PETERS ADE",
                  "balance": "5044.09"
              },
              {
                  "sn": "56",
                  "transactionId": "S20872242",
                  "transactionDate": "3/22/2022 12:00:00 AM",
                  "valueDate": "3/22/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Borr",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/AKINMOLADUN  PETERS ADE",
                  "balance": "5033.34"
              },
              {
                  "sn": "57",
                  "transactionId": "S21251005",
                  "transactionDate": "3/23/2022 12:00:00 AM",
                  "valueDate": "3/23/2022 12:00:00 AM",
                  "debitAmount": "4000",
                  "creditAmount": "0",
                  "narration": "POS Buy on 23-03-2022@ADELEYE ADEWALE@00021000652",
                  "narration2": "",
                  "remarks": "201115OR/7418/210006528",
                  "balance": "1033.34"
              },
              {
                  "sn": "58",
                  "transactionId": "S21492211",
                  "transactionDate": "3/23/2022 12:00:00 AM",
                  "valueDate": "3/23/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "4000",
                  "narration": "Mob Trf self",
                  "narration2": "BLEND-LOCAL TRANSFER:-7d79b0fd-0c9f-4f6d-813c-9a8b",
                  "remarks": "1300012264 / 5300017669/211968",
                  "balance": "5033.34"
              },
              {
                  "sn": "59",
                  "transactionId": "S21493347",
                  "transactionDate": "3/23/2022 12:00:00 AM",
                  "valueDate": "3/23/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "Trf - self",
                  "narration2": "000020220323161425004003898022/O0130187278",
                  "remarks": "MOBILE/UMEADI NKEM PATRICIA",
                  "balance": "33.34"
              },
              {
                  "sn": "60",
                  "transactionId": "S21493347",
                  "transactionDate": "3/23/2022 12:00:00 AM",
                  "valueDate": "3/23/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - self",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/UMEADI NKEM PATRICIA",
                  "balance": "22.59"
              },
              {
                  "sn": "61",
                  "transactionId": "S21831379",
                  "transactionDate": "3/24/2022 12:00:00 AM",
                  "valueDate": "3/24/2022 12:00:00 AM",
                  "debitAmount": "1000",
                  "creditAmount": "0",
                  "narration": "MAR 2022 ASSBIFI  DUES",
                  "narration2": "",
                  "remarks": "",
                  "balance": "-977.41"
              },
              {
                  "sn": "62",
                  "transactionId": "S21831855",
                  "transactionDate": "3/24/2022 12:00:00 AM",
                  "valueDate": "3/24/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "137277.12",
                  "narration": "MARCH 2022 SALARY",
                  "narration2": "",
                  "remarks": "",
                  "balance": "136299.71"
              },
              {
                  "sn": "63",
                  "transactionId": "S22180857",
                  "transactionDate": "3/25/2022 12:00:00 AM",
                  "valueDate": "3/25/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "POS Buy on 25-03-2022@ADEWALE ADELEYE@00021001265",
                  "narration2": "",
                  "remarks": "2058CX43/13588/210012654",
                  "balance": "131299.71"
              },
              {
                  "sn": "64",
                  "transactionId": "S22214263",
                  "transactionDate": "3/25/2022 12:00:00 AM",
                  "valueDate": "3/25/2022 12:00:00 AM",
                  "debitAmount": "45000",
                  "creditAmount": "0",
                  "narration": "Mob Trf Vince_Oye",
                  "narration2": "BLEND-LOCAL TRANSFER:-51e41e9f-31e5-4564-8d01-3564",
                  "remarks": "5300017669 / 5300017614/108164",
                  "balance": "86299.71"
              },
              {
                  "sn": "65",
                  "transactionId": "S22336993",
                  "transactionDate": "3/25/2022 12:00:00 AM",
                  "valueDate": "3/25/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "ATM WD on 25-03-2022@34 Adeniyi Jones S",
                  "narration2": "",
                  "remarks": "10300102/1165/96027361",
                  "balance": "81299.71"
              },
              {
                  "sn": "66",
                  "transactionId": "S22655111",
                  "transactionDate": "3/25/2022 12:00:00 AM",
                  "valueDate": "3/25/2022 12:00:00 AM",
                  "debitAmount": "1880",
                  "creditAmount": "0",
                  "narration": "POS Buy on 25-03-2022@PENNYMART GLOBA@00002001549",
                  "narration2": "",
                  "remarks": "2070DW82/15491/20015491",
                  "balance": "79419.71"
              },
              {
                  "sn": "67",
                  "transactionId": "S22685680",
                  "transactionDate": "3/25/2022 12:00:00 AM",
                  "valueDate": "3/25/2022 12:00:00 AM",
                  "debitAmount": "1000",
                  "creditAmount": "0",
                  "narration": "Mob Recharge-Online: Recharge <Glo> <07051456716>",
                  "narration2": "BLEND-MOBILERECHARGE1-637838389369814799",
                  "remarks": "BLEND / BLD/1210122473",
                  "balance": "78419.71"
              },
              {
                  "sn": "68",
                  "transactionId": "    M1823",
                  "transactionDate": "3/28/2022 12:00:00 AM",
                  "valueDate": "3/28/2022 12:00:00 AM",
                  "debitAmount": "1500",
                  "creditAmount": "0",
                  "narration": "STAFF WELFARE DEDUCTION FOR MARCH 2022",
                  "narration2": "",
                  "remarks": "",
                  "balance": "76919.71"
              },
              {
                  "sn": "69",
                  "transactionId": "S22886519",
                  "transactionDate": "3/28/2022 12:00:00 AM",
                  "valueDate": "3/26/2022 12:00:00 AM",
                  "debitAmount": "10000",
                  "creditAmount": "0",
                  "narration": "POS Trf on 26-03-2022@WT/CHISOM AMARACHU",
                  "narration2": "",
                  "remarks": "2011LC29/507365/15LC29507365",
                  "balance": "66919.71"
              },
              {
                  "sn": "70",
                  "transactionId": "S22919898",
                  "transactionDate": "3/28/2022 12:00:00 AM",
                  "valueDate": "3/26/2022 12:00:00 AM",
                  "debitAmount": "2000",
                  "creditAmount": "0",
                  "narration": "POS Buy on 26-03-2022@LA TUX PLACE   @00021000323",
                  "narration2": "",
                  "remarks": "201140OR/6490/210003230",
                  "balance": "64919.71"
              },
              {
                  "sn": "71",
                  "transactionId": "S23316279",
                  "transactionDate": "3/28/2022 12:00:00 AM",
                  "valueDate": "3/28/2022 12:00:00 AM",
                  "debitAmount": "3000",
                  "creditAmount": "0",
                  "narration": "Mob Trf Vince",
                  "narration2": "BLEND-LOCAL TRANSFER:-1fadd5b5-f80a-43e4-a122-96b4",
                  "remarks": "5300017669 / 1001825680/855121",
                  "balance": "61919.71"
              },
              {
                  "sn": "72",
                  "transactionId": "S23501309",
                  "transactionDate": "3/29/2022 12:00:00 AM",
                  "valueDate": "3/29/2022 12:00:00 AM",
                  "debitAmount": "6500",
                  "creditAmount": "0",
                  "narration": "POS Buy on 29-03-2022@ADEWALE ADELEYE@00021001314",
                  "narration2": "",
                  "remarks": "2058CX43/14110/210013149",
                  "balance": "55419.71"
              },
              {
                  "sn": "73",
                  "transactionId": "S24582777",
                  "transactionDate": "3/30/2022 12:00:00 AM",
                  "valueDate": "3/30/2022 12:00:00 AM",
                  "debitAmount": "2000",
                  "creditAmount": "0",
                  "narration": "POS Buy on 30-03-2022@UGO-BIZ & BROTH@00021000616",
                  "narration2": "",
                  "remarks": "20333F32/9228/210006166",
                  "balance": "53419.71"
              },
              {
                  "sn": "74",
                  "transactionId": "S25197517",
                  "transactionDate": "3/31/2022 12:00:00 AM",
                  "valueDate": "3/31/2022 12:00:00 AM",
                  "debitAmount": "10000",
                  "creditAmount": "0",
                  "narration": "ATM WD on 31-03-2022@FESTAC TOWN BR",
                  "narration2": "",
                  "remarks": "10440813/582152/209017582152",
                  "balance": "43419.71"
              },
              {
                  "sn": "75",
                  "transactionId": "S25467179",
                  "transactionDate": "4/1/2022 12:00:00 AM",
                  "valueDate": "4/1/2022 12:00:00 AM",
                  "debitAmount": "25000",
                  "creditAmount": "0",
                  "narration": "Mob Trf ajo",
                  "narration2": "BLEND-LOCAL TRANSFER:-c800d522-15c2-4aeb-8b3f-31eb",
                  "remarks": "5300017669 / 1001825738/132598",
                  "balance": "18419.71"
              },
              {
                  "sn": "76",
                  "transactionId": "S26176680",
                  "transactionDate": "4/4/2022 12:00:00 AM",
                  "valueDate": "4/3/2022 12:00:00 AM",
                  "debitAmount": "10000",
                  "creditAmount": "0",
                  "narration": "ATM WD on 03-04-2022@Festac2",
                  "narration2": "",
                  "remarks": "17015012/3171/6570837047",
                  "balance": "8419.71"
              },
              {
                  "sn": "77",
                  "transactionId": "S26176697",
                  "transactionDate": "4/4/2022 12:00:00 AM",
                  "valueDate": "4/3/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "10000",
                  "narration": "ATM WD Reversal on 03-04-2022@Festac2",
                  "narration2": "",
                  "remarks": "17015012/3171/6570837047",
                  "balance": "18419.71"
              },
              {
                  "sn": "78",
                  "transactionId": "S26177348",
                  "transactionDate": "4/4/2022 12:00:00 AM",
                  "valueDate": "4/3/2022 12:00:00 AM",
                  "debitAmount": "10000",
                  "creditAmount": "0",
                  "narration": "ATM WD on 03-04-2022@PROVIDUS 51 road 5",
                  "narration2": "",
                  "remarks": "1PRO2051/5476/4975744956",
                  "balance": "8419.71"
              },
              {
                  "sn": "79",
                  "transactionId": "S26749468",
                  "transactionDate": "4/5/2022 12:00:00 AM",
                  "valueDate": "4/5/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "3000",
                  "narration": "000-OKELEKE  VINCENT AWELE-USSD NIP Tr",
                  "narration2": "000013220405134440000139042530/I0106161053",
                  "remarks": "NIP/NIP/OKELEKE  VINCENT AWELE",
                  "balance": "11419.71"
              },
              {
                  "sn": "80",
                  "transactionId": "S26902466",
                  "transactionDate": "4/5/2022 12:00:00 AM",
                  "valueDate": "4/5/2022 12:00:00 AM",
                  "debitAmount": "7100",
                  "creditAmount": "0",
                  "narration": "POS Trf on 05-04-2022@T AL  BAQI VENTURE",
                  "narration2": "",
                  "remarks": "2TEPTS18/1180/1180",
                  "balance": "4319.71"
              },
              {
                  "sn": "81",
                  "transactionId": "S27332625",
                  "transactionDate": "4/7/2022 12:00:00 AM",
                  "valueDate": "4/7/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "2000",
                  "narration": "Mob Trf self",
                  "narration2": "BLEND-LOCAL TRANSFER:-bd259b9b-9d16-466a-80a0-3b35",
                  "remarks": "1300012264 / 5300017669/124747",
                  "balance": "6319.71"
              },
              {
                  "sn": "82",
                  "transactionId": "S27333575",
                  "transactionDate": "4/7/2022 12:00:00 AM",
                  "valueDate": "4/7/2022 12:00:00 AM",
                  "debitAmount": "6000",
                  "creditAmount": "0",
                  "narration": "POS Buy on 07-04-2022@ADELEYE ADEWALE@00021000874",
                  "narration2": "",
                  "remarks": "201115OR/9812/210008743",
                  "balance": "319.71"
              },
              {
                  "sn": "83",
                  "transactionId": "S28028669",
                  "transactionDate": "4/11/2022 12:00:00 AM",
                  "valueDate": "4/9/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "10000",
                  "narration": "Mob Trf self",
                  "narration2": "BLEND-LOCAL TRANSFER:-a75e4823-bfc5-4c30-a1db-17dc",
                  "remarks": "1900723085 / 5300017669/188779",
                  "balance": "10319.71"
              },
              {
                  "sn": "84",
                  "transactionId": "S28033775",
                  "transactionDate": "4/11/2022 12:00:00 AM",
                  "valueDate": "4/9/2022 12:00:00 AM",
                  "debitAmount": "4500",
                  "creditAmount": "0",
                  "narration": "POS Buy on 09-04-2022@ACHONYE FRANCIS@00021000056",
                  "narration2": "",
                  "remarks": "2044IHM4/1404/210000561",
                  "balance": "5819.71"
              },
              {
                  "sn": "85",
                  "transactionId": "S28054568",
                  "transactionDate": "4/11/2022 12:00:00 AM",
                  "valueDate": "4/10/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "POS Trf on 10-04-2022@WT/OMOGBEYISOLA TE",
                  "narration2": "",
                  "remarks": "2011IU05/675383/11IU05675383",
                  "balance": "819.71"
              },
              {
                  "sn": "86",
                  "transactionId": "S28408057",
                  "transactionDate": "4/12/2022 12:00:00 AM",
                  "valueDate": "4/12/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "10000",
                  "narration": "Mob Trf self",
                  "narration2": "BLEND-LOCAL TRANSFER:-e2b30999-1767-4043-9591-61f2",
                  "remarks": "1900723085 / 5300017669/197875",
                  "balance": "10819.71"
              },
              {
                  "sn": "87",
                  "transactionId": "S28413269",
                  "transactionDate": "4/12/2022 12:00:00 AM",
                  "valueDate": "4/12/2022 12:00:00 AM",
                  "debitAmount": "6500",
                  "creditAmount": "0",
                  "narration": "POS Buy on 12-04-2022@ADELEYE ADEWALE@00021000921",
                  "narration2": "",
                  "remarks": "201115OR/10338/210009214",
                  "balance": "4319.71"
              },
              {
                  "sn": "88",
                  "transactionId": "S28874983",
                  "transactionDate": "4/13/2022 12:00:00 AM",
                  "valueDate": "4/13/2022 12:00:00 AM",
                  "debitAmount": "3000",
                  "creditAmount": "0",
                  "narration": "ATM WD on 13-04-2022@34 Adeniyi Jones S",
                  "narration2": "",
                  "remarks": "10300102/3896/97231477",
                  "balance": "1319.71"
              },
              {
                  "sn": "89",
                  "transactionId": "S29003325",
                  "transactionDate": "4/14/2022 12:00:00 AM",
                  "valueDate": "4/14/2022 12:00:00 AM",
                  "debitAmount": "200",
                  "creditAmount": "0",
                  "narration": "Mob Recharge-Online: Recharge <MTN> <07033671485>",
                  "narration2": "BLEND-MOBILERECHARGE1-637855239432997449",
                  "remarks": "BLEND / BLD/739262974",
                  "balance": "1119.71"
              },
              {
                  "sn": "90",
                  "transactionId": "S29629952",
                  "transactionDate": "4/19/2022 12:00:00 AM",
                  "valueDate": "4/18/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "9000",
                  "narration": "Mob Trf self",
                  "narration2": "BLEND-LOCAL TRANSFER:-b97680a8-f8e2-464c-b9aa-8b9c",
                  "remarks": "1900723085 / 5300017669/121721",
                  "balance": "10119.71"
              },
              {
                  "sn": "91",
                  "transactionId": "S29632527",
                  "transactionDate": "4/19/2022 12:00:00 AM",
                  "valueDate": "4/18/2022 12:00:00 AM",
                  "debitAmount": "7000",
                  "creditAmount": "0",
                  "narration": "POS Trf on 18-04-2022@T AL  BAQI VENTURE",
                  "narration2": "",
                  "remarks": "2TEPTS18/1984/1984",
                  "balance": "3119.71"
              },
              {
                  "sn": "92",
                  "transactionId": "S30018085",
                  "transactionDate": "4/20/2022 12:00:00 AM",
                  "valueDate": "4/20/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "10000",
                  "narration": "100-CHUKWUEMEKA  OKELEKE-117-DIAMOND S",
                  "narration2": "100006220420100615345057032493/I0028116908",
                  "remarks": "NIP/NIP/CHUKWUEMEKA  OKELEKE-1",
                  "balance": "13119.71"
              },
              {
                  "sn": "93",
                  "transactionId": "S30018085",
                  "transactionDate": "4/20/2022 12:00:00 AM",
                  "valueDate": "4/20/2022 12:00:00 AM",
                  "debitAmount": "50",
                  "creditAmount": "0",
                  "narration": "STAMP DUTY CHARGE ON DEPOSIT: 5300017669",
                  "narration2": "FEE EXEMPT",
                  "remarks": "NIP/NIP/CHUKWUEMEKA  OKELEKE-1",
                  "balance": "13069.71"
              },
              {
                  "sn": "94",
                  "transactionId": "S30362145",
                  "transactionDate": "4/21/2022 12:00:00 AM",
                  "valueDate": "4/21/2022 12:00:00 AM",
                  "debitAmount": "2000",
                  "creditAmount": "0",
                  "narration": "BPW Eko Electricity Distribution Company Plc Prepa",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/196060745368",
                  "balance": "11069.71"
              },
              {
                  "sn": "95",
                  "transactionId": "S30362145",
                  "transactionDate": "4/21/2022 12:00:00 AM",
                  "valueDate": "4/21/2022 12:00:00 AM",
                  "debitAmount": "100",
                  "creditAmount": "0",
                  "narration": "Fee - Eko Electricity Distribution Company Plc Pre",
                  "narration2": "FEE EXEMPT",
                  "remarks": "OTS45951/196060745368",
                  "balance": "10969.71"
              },
              {
                  "sn": "96",
                  "transactionId": "S30362506",
                  "transactionDate": "4/21/2022 12:00:00 AM",
                  "valueDate": "4/21/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "400",
                  "narration": "INW Transfer to OKELEKE VINCENT from VINCENT  OKEL",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/637861126140",
                  "balance": "11369.71"
              },
              {
                  "sn": "97",
                  "transactionId": "S30388675",
                  "transactionDate": "4/21/2022 12:00:00 AM",
                  "valueDate": "4/21/2022 12:00:00 AM",
                  "debitAmount": "5500",
                  "creditAmount": "0",
                  "narration": "POS Buy on 21-04-2022@ADELEYE ADEWALE@00021000131",
                  "narration2": "",
                  "remarks": "201116OR/1471/210001314",
                  "balance": "5869.71"
              },
              {
                  "sn": "98",
                  "transactionId": "S30584392",
                  "transactionDate": "4/21/2022 12:00:00 AM",
                  "valueDate": "4/21/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "ATM WD on 21-04-2022@34 Adeniyi Jones S",
                  "narration2": "",
                  "remarks": "10300102/5459/97692391",
                  "balance": "869.71"
              },
              {
                  "sn": "99",
                  "transactionId": "S30908718",
                  "transactionDate": "4/22/2022 12:00:00 AM",
                  "valueDate": "4/22/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "137277.12",
                  "narration": "APRIL 2022 SALARY",
                  "narration2": "",
                  "remarks": "",
                  "balance": "138146.83"
              },
              {
                  "sn": "100",
                  "transactionId": "S30908719",
                  "transactionDate": "4/22/2022 12:00:00 AM",
                  "valueDate": "4/22/2022 12:00:00 AM",
                  "debitAmount": "1000",
                  "creditAmount": "0",
                  "narration": "APR 2022 ASSBIFI  DUES",
                  "narration2": "",
                  "remarks": "",
                  "balance": "137146.83"
              },
              {
                  "sn": "101",
                  "transactionId": "S30947674",
                  "transactionDate": "4/22/2022 12:00:00 AM",
                  "valueDate": "4/22/2022 12:00:00 AM",
                  "debitAmount": "50000",
                  "creditAmount": "0",
                  "narration": "Trf - Vince/Ralph",
                  "narration2": "000020220422130500004004267056/O0242882848",
                  "remarks": "MOBILE/OYINOLUWA DEBORAH AROWO",
                  "balance": "87146.83"
              },
              {
                  "sn": "102",
                  "transactionId": "S30947674",
                  "transactionDate": "4/22/2022 12:00:00 AM",
                  "valueDate": "4/22/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Vince/Ralph",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/OYINOLUWA DEBORAH AROWO",
                  "balance": "87119.95"
              },
              {
                  "sn": "103",
                  "transactionId": "S30949987",
                  "transactionDate": "4/22/2022 12:00:00 AM",
                  "valueDate": "4/22/2022 12:00:00 AM",
                  "debitAmount": "10000",
                  "creditAmount": "0",
                  "narration": "ATM WD on 22-04-2022@34 Adeniyi Jones S",
                  "narration2": "",
                  "remarks": "10300102/5605/97747663",
                  "balance": "77119.95"
              },
              {
                  "sn": "104",
                  "transactionId": "S31010632",
                  "transactionDate": "4/22/2022 12:00:00 AM",
                  "valueDate": "4/22/2022 12:00:00 AM",
                  "debitAmount": "3500",
                  "creditAmount": "0",
                  "narration": "Mob Recharge-Online: Recharge <MTN> <07033671485>",
                  "narration2": "BLEND-MOBILERECHARGE1-637862374945727964",
                  "remarks": "BLEND / BLD/471587800",
                  "balance": "73619.95"
              },
              {
                  "sn": "105",
                  "transactionId": "    M1291",
                  "transactionDate": "4/25/2022 12:00:00 AM",
                  "valueDate": "4/25/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "5000",
                  "narration": "0434107 CDP AKINYEMI DAVID OLUWASEGUN",
                  "narration2": "",
                  "remarks": "",
                  "balance": "78619.95"
              },
              {
                  "sn": "106",
                  "transactionId": "S31549563",
                  "transactionDate": "4/25/2022 12:00:00 AM",
                  "valueDate": "4/23/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "ATM WD on 23-04-2022@34 Adeniyi Jones S",
                  "narration2": "",
                  "remarks": "10300102/5698/97816343",
                  "balance": "73619.95"
              },
              {
                  "sn": "107",
                  "transactionId": "S31878753",
                  "transactionDate": "4/25/2022 12:00:00 AM",
                  "valueDate": "4/25/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "POS Buy on 25-04-2022@ADELEYE ADEWALE@00021000207",
                  "narration2": "",
                  "remarks": "201116OR/2271/210002074",
                  "balance": "68619.95"
              },
              {
                  "sn": "108",
                  "transactionId": "S31956988",
                  "transactionDate": "4/25/2022 12:00:00 AM",
                  "valueDate": "4/25/2022 12:00:00 AM",
                  "debitAmount": "3000",
                  "creditAmount": "0",
                  "narration": "INW Vince",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/637864819353",
                  "balance": "65619.95"
              },
              {
                  "sn": "109",
                  "transactionId": "S31957908",
                  "transactionDate": "4/25/2022 12:00:00 AM",
                  "valueDate": "4/25/2022 12:00:00 AM",
                  "debitAmount": "25000",
                  "creditAmount": "0",
                  "narration": "Trf - Octiplus: Vincent",
                  "narration2": "000020220425121734009004302212/O0143060023",
                  "remarks": "USSD/AFOLABI AYOBAMI",
                  "balance": "40619.95"
              },
              {
                  "sn": "110",
                  "transactionId": "S31957908",
                  "transactionDate": "4/25/2022 12:00:00 AM",
                  "valueDate": "4/25/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Octiplus: Vincent",
                  "narration2": "FEE EXEMPT",
                  "remarks": "USSD/AFOLABI AYOBAMI",
                  "balance": "40593.07"
              },
              {
                  "sn": "111",
                  "transactionId": "    M8173",
                  "transactionDate": "4/26/2022 12:00:00 AM",
                  "valueDate": "4/26/2022 12:00:00 AM",
                  "debitAmount": "1500",
                  "creditAmount": "0",
                  "narration": "STAFF WELFARE  FOR APRIL  2022",
                  "narration2": "",
                  "remarks": "",
                  "balance": "39093.07"
              },
              {
                  "sn": "112",
                  "transactionId": "S33072004",
                  "transactionDate": "4/27/2022 12:00:00 AM",
                  "valueDate": "4/27/2022 12:00:00 AM",
                  "debitAmount": "9000",
                  "creditAmount": "0",
                  "narration": "ATM WD on 27-04-2022@34 Adeniyi Jones S",
                  "narration2": "",
                  "remarks": "10300103/5271/98038669",
                  "balance": "30093.07"
              },
              {
                  "sn": "113",
                  "transactionId": "S33376548",
                  "transactionDate": "4/28/2022 12:00:00 AM",
                  "valueDate": "4/28/2022 12:00:00 AM",
                  "debitAmount": "30000",
                  "creditAmount": "0",
                  "narration": "INW brw",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/637867281439",
                  "balance": "93.07"
              },
              {
                  "sn": "114",
                  "transactionId": "S33532427",
                  "transactionDate": "4/28/2022 12:00:00 AM",
                  "valueDate": "4/28/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "5000",
                  "narration": "100-PALMPAY LIMITED-Palmpay",
                  "narration2": "100033220428112135000039406662/I5376817031",
                  "remarks": "NIP/NIP/PALMPAY LIMITED",
                  "balance": "5093.07"
              },
              {
                  "sn": "115",
                  "transactionId": "S33930031",
                  "transactionDate": "4/29/2022 12:00:00 AM",
                  "valueDate": "4/29/2022 12:00:00 AM",
                  "debitAmount": "4000",
                  "creditAmount": "0",
                  "narration": "Mob Trf ATM",
                  "narration2": "BLEND-LOCAL TRANSFER:-4ef69c40-a1fd-49ee-a5c2-4349",
                  "remarks": "5300017669 / 1001597495/174340",
                  "balance": "1093.07"
              },
              {
                  "sn": "116",
                  "transactionId": "S34001013",
                  "transactionDate": "4/29/2022 12:00:00 AM",
                  "valueDate": "4/29/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "30000",
                  "narration": "Mob Trf ife",
                  "narration2": "BLEND-LOCAL TRANSFER:-c080b22e-36ae-485b-9ef6-8af4",
                  "remarks": "1300012219 / 5300017669/180582",
                  "balance": "31093.07"
              },
              {
                  "sn": "117",
                  "transactionId": "S34001013",
                  "transactionDate": "4/29/2022 12:00:00 AM",
                  "valueDate": "4/29/2022 12:00:00 AM",
                  "debitAmount": "50",
                  "creditAmount": "0",
                  "narration": "STAMP DUTY CHARGE ON DEPOSIT: 5300017669",
                  "narration2": "FEE EXEMPT",
                  "remarks": "1300012219 / 5300017669/180582",
                  "balance": "31043.07"
              },
              {
                  "sn": "118",
                  "transactionId": "S34413234",
                  "transactionDate": "5/4/2022 12:00:00 AM",
                  "valueDate": "4/30/2022 12:00:00 AM",
                  "debitAmount": "10000",
                  "creditAmount": "0",
                  "narration": "ATM WD on 30-04-2022@34 Adeniyi Jones S",
                  "narration2": "",
                  "remarks": "10300103/5894/98297077",
                  "balance": "21043.07"
              },
              {
                  "sn": "119",
                  "transactionId": "S34611300",
                  "transactionDate": "5/4/2022 12:00:00 AM",
                  "valueDate": "5/1/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "30000",
                  "narration": "NXG :TRFAchievasFRMCOLECHIORITOVINCENTOK",
                  "narration2": "",
                  "remarks": "/84637/264816221",
                  "balance": "51043.07"
              },
              {
                  "sn": "120",
                  "transactionId": "S34611300",
                  "transactionDate": "5/4/2022 12:00:00 AM",
                  "valueDate": "5/1/2022 12:00:00 AM",
                  "debitAmount": "50",
                  "creditAmount": "0",
                  "narration": "STAMP DUTY CHARGE ON DEPOSIT: 5300017669",
                  "narration2": "FEE EXEMPT",
                  "remarks": "/84637/264816221",
                  "balance": "50993.07"
              },
              {
                  "sn": "121",
                  "transactionId": "S34626624",
                  "transactionDate": "5/4/2022 12:00:00 AM",
                  "valueDate": "5/1/2022 12:00:00 AM",
                  "debitAmount": "20000",
                  "creditAmount": "0",
                  "narration": "POS Trf on 01-05-2022@PAYCOM NIGERIA LIM",
                  "narration2": "",
                  "remarks": "2PAY0UM6/8302/205017786554",
                  "balance": "30993.07"
              },
              {
                  "sn": "122",
                  "transactionId": "S34687016",
                  "transactionDate": "5/4/2022 12:00:00 AM",
                  "valueDate": "5/2/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "30000",
                  "narration": "000-OKELEKE  VINCENT AWELE-USSD NIP Tr",
                  "narration2": "000013220502160013000189007697/I0106161053",
                  "remarks": "NIP/NIP/OKELEKE  VINCENT AWELE",
                  "balance": "60993.07"
              },
              {
                  "sn": "123",
                  "transactionId": "S34687016",
                  "transactionDate": "5/4/2022 12:00:00 AM",
                  "valueDate": "5/2/2022 12:00:00 AM",
                  "debitAmount": "50",
                  "creditAmount": "0",
                  "narration": "STAMP DUTY CHARGE ON DEPOSIT: 5300017669",
                  "narration2": "FEE EXEMPT",
                  "remarks": "NIP/NIP/OKELEKE  VINCENT AWELE",
                  "balance": "60943.07"
              },
              {
                  "sn": "124",
                  "transactionId": "S34751678",
                  "transactionDate": "5/4/2022 12:00:00 AM",
                  "valueDate": "5/3/2022 12:00:00 AM",
                  "debitAmount": "2000",
                  "creditAmount": "0",
                  "narration": "Trf - Octiplus: stuff",
                  "narration2": "000020220503114949009004411588/O0130187278",
                  "remarks": "USSD/UMEADI NKEM PATRICIA",
                  "balance": "58943.07"
              },
              {
                  "sn": "125",
                  "transactionId": "S34751678",
                  "transactionDate": "5/4/2022 12:00:00 AM",
                  "valueDate": "5/3/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Octiplus: stuff",
                  "narration2": "FEE EXEMPT",
                  "remarks": "USSD/UMEADI NKEM PATRICIA",
                  "balance": "58932.32"
              },
              {
                  "sn": "126",
                  "transactionId": "S35302088",
                  "transactionDate": "5/4/2022 12:00:00 AM",
                  "valueDate": "5/4/2022 12:00:00 AM",
                  "debitAmount": "10000",
                  "creditAmount": "0",
                  "narration": "ATM WD on 04-05-2022@FESTAC TOWN BR",
                  "narration2": "",
                  "remarks": "10440813/238043/212418238043",
                  "balance": "48932.32"
              },
              {
                  "sn": "127",
                  "transactionId": "S35378838",
                  "transactionDate": "5/5/2022 12:00:00 AM",
                  "valueDate": "5/5/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "INW initial deposit",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/637873394599",
                  "balance": "43932.32"
              },
              {
                  "sn": "128",
                  "transactionId": "S36109986",
                  "transactionDate": "5/6/2022 12:00:00 AM",
                  "valueDate": "5/6/2022 12:00:00 AM",
                  "debitAmount": "10000",
                  "creditAmount": "0",
                  "narration": "ATM WD on 06-05-2022@GTBank 23 road opp",
                  "narration2": "",
                  "remarks": "10582774/6399/5025866899",
                  "balance": "33932.32"
              },
              {
                  "sn": "129",
                  "transactionId": "S36187527",
                  "transactionDate": "5/9/2022 12:00:00 AM",
                  "valueDate": "5/7/2022 12:00:00 AM",
                  "debitAmount": "7900",
                  "creditAmount": "0",
                  "narration": "POS Buy on 07-05-2022@VINEE GAS LIMIT@00000900591",
                  "narration2": "",
                  "remarks": "2070HC80/5915/9005915",
                  "balance": "26032.32"
              },
              {
                  "sn": "130",
                  "transactionId": "S36285806",
                  "transactionDate": "5/9/2022 12:00:00 AM",
                  "valueDate": "5/7/2022 12:00:00 AM",
                  "debitAmount": "2000",
                  "creditAmount": "0",
                  "narration": "Trf - Octiplus: vince",
                  "narration2": "000020220507164112009004468803/O0130187278",
                  "remarks": "USSD/UMEADI NKEM PATRICIA",
                  "balance": "24032.32"
              },
              {
                  "sn": "131",
                  "transactionId": "S36285806",
                  "transactionDate": "5/9/2022 12:00:00 AM",
                  "valueDate": "5/7/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Octiplus: vince",
                  "narration2": "FEE EXEMPT",
                  "remarks": "USSD/UMEADI NKEM PATRICIA",
                  "balance": "24021.57"
              },
              {
                  "sn": "132",
                  "transactionId": "S36323666",
                  "transactionDate": "5/9/2022 12:00:00 AM",
                  "valueDate": "5/7/2022 12:00:00 AM",
                  "debitAmount": "3600",
                  "creditAmount": "0",
                  "narration": "Trf - Octiplus: vince",
                  "narration2": "000020220507193751009004470362/O0022964170",
                  "remarks": "USSD/NWACHUKWU MUNACHIMSO",
                  "balance": "20421.57"
              },
              {
                  "sn": "133",
                  "transactionId": "S36323666",
                  "transactionDate": "5/9/2022 12:00:00 AM",
                  "valueDate": "5/7/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Octiplus: vince",
                  "narration2": "FEE EXEMPT",
                  "remarks": "USSD/NWACHUKWU MUNACHIMSO",
                  "balance": "20410.82"
              },
              {
                  "sn": "134",
                  "transactionId": "S36543078",
                  "transactionDate": "5/9/2022 12:00:00 AM",
                  "valueDate": "5/8/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "Trf - Octiplus: Olembe",
                  "narration2": "000020220508162447009004478526/O0074186681",
                  "remarks": "USSD/AKOSA  AUGUSTINE  ONYEKA",
                  "balance": "15410.82"
              },
              {
                  "sn": "135",
                  "transactionId": "S36543078",
                  "transactionDate": "5/9/2022 12:00:00 AM",
                  "valueDate": "5/8/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Octiplus: Olembe",
                  "narration2": "FEE EXEMPT",
                  "remarks": "USSD/AKOSA  AUGUSTINE  ONYEKA",
                  "balance": "15400.07"
              },
              {
                  "sn": "136",
                  "transactionId": "S36560187",
                  "transactionDate": "5/9/2022 12:00:00 AM",
                  "valueDate": "5/8/2022 12:00:00 AM",
                  "debitAmount": "7100",
                  "creditAmount": "0",
                  "narration": "POS Buy on 08-05-2022@TEAMAPT LIMITED@00000000326",
                  "narration2": "",
                  "remarks": "207059FU/3268/3268",
                  "balance": "8300.07"
              },
              {
                  "sn": "137",
                  "transactionId": "S37620561",
                  "transactionDate": "5/12/2022 12:00:00 AM",
                  "valueDate": "5/12/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "POS Buy on 12-05-2022@ADEWALE ADELEYE@00021001118",
                  "narration2": "",
                  "remarks": "2058CX44/12037/210011181",
                  "balance": "3300.07"
              },
              {
                  "sn": "138",
                  "transactionId": "S37622307",
                  "transactionDate": "5/12/2022 12:00:00 AM",
                  "valueDate": "5/12/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "6800",
                  "narration": "Mob Trf self",
                  "narration2": "BLEND-LOCAL TRANSFER:-105d01c0-adcb-459a-8a8a-d4b7",
                  "remarks": "1300012264 / 5300017669/311589",
                  "balance": "10100.07"
              },
              {
                  "sn": "139",
                  "transactionId": "S37662735",
                  "transactionDate": "5/12/2022 12:00:00 AM",
                  "valueDate": "5/12/2022 12:00:00 AM",
                  "debitAmount": "10000",
                  "creditAmount": "0",
                  "narration": "ATM WD on 12-05-2022@34 Adeniyi Jones S",
                  "narration2": "",
                  "remarks": "10300103/7964/99001205",
                  "balance": "100.07"
              },
              {
                  "sn": "140",
                  "transactionId": "S37971568",
                  "transactionDate": "5/13/2022 12:00:00 AM",
                  "valueDate": "5/13/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "20000",
                  "narration": "INW self",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/637880426427",
                  "balance": "20100.07"
              },
              {
                  "sn": "141",
                  "transactionId": "S37973793",
                  "transactionDate": "5/13/2022 12:00:00 AM",
                  "valueDate": "5/13/2022 12:00:00 AM",
                  "debitAmount": "10000",
                  "creditAmount": "0",
                  "narration": "INW Ife_Nifty",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/637880427701",
                  "balance": "10100.07"
              },
              {
                  "sn": "142",
                  "transactionId": "S38457592",
                  "transactionDate": "5/16/2022 12:00:00 AM",
                  "valueDate": "5/14/2022 12:00:00 AM",
                  "debitAmount": "1500",
                  "creditAmount": "0",
                  "narration": "BPW GLO Airtime Purchase07051456716-91309",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/196032781963",
                  "balance": "8600.07"
              },
              {
                  "sn": "143",
                  "transactionId": "S38506720",
                  "transactionDate": "5/16/2022 12:00:00 AM",
                  "valueDate": "5/15/2022 12:00:00 AM",
                  "debitAmount": "3500",
                  "creditAmount": "0",
                  "narration": "BPW MTN Airtime Purchase07033671485-90304",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/196057760864",
                  "balance": "5100.07"
              },
              {
                  "sn": "144",
                  "transactionId": "S38941958",
                  "transactionDate": "5/16/2022 12:00:00 AM",
                  "valueDate": "5/16/2022 12:00:00 AM",
                  "debitAmount": "1000",
                  "creditAmount": "0",
                  "narration": "INW refund",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/637883111513",
                  "balance": "4100.07"
              },
              {
                  "sn": "145",
                  "transactionId": "S39416284",
                  "transactionDate": "5/17/2022 12:00:00 AM",
                  "valueDate": "5/17/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "20000",
                  "narration": "Mob Trf self",
                  "narration2": "BLEND-LOCAL TRANSFER:-e3d4c7f9-4297-47cb-a41b-f100",
                  "remarks": "1300012264 / 5300017669/118332",
                  "balance": "24100.07"
              },
              {
                  "sn": "146",
                  "transactionId": "S39456430",
                  "transactionDate": "5/17/2022 12:00:00 AM",
                  "valueDate": "5/17/2022 12:00:00 AM",
                  "debitAmount": "9100",
                  "creditAmount": "0",
                  "narration": "Trf - Octiplus: pos",
                  "narration2": "000020220517183634009004591926/O2011197902",
                  "remarks": "USSD/OKUNADE, ABODUNRIN KUDIRA",
                  "balance": "15000.07"
              },
              {
                  "sn": "147",
                  "transactionId": "S39456430",
                  "transactionDate": "5/17/2022 12:00:00 AM",
                  "valueDate": "5/17/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Octiplus: pos",
                  "narration2": "FEE EXEMPT",
                  "remarks": "USSD/OKUNADE, ABODUNRIN KUDIRA",
                  "balance": "14973.19"
              },
              {
                  "sn": "148",
                  "transactionId": "S39793713",
                  "transactionDate": "5/18/2022 12:00:00 AM",
                  "valueDate": "5/18/2022 12:00:00 AM",
                  "debitAmount": "10000",
                  "creditAmount": "0",
                  "narration": "Trf - Octiplus: refund",
                  "narration2": "000020220518125833009004599541/O0132008555",
                  "remarks": "USSD/AKOMOLAFE OLUSOLA GRACE",
                  "balance": "4973.19"
              },
              {
                  "sn": "149",
                  "transactionId": "S39793713",
                  "transactionDate": "5/18/2022 12:00:00 AM",
                  "valueDate": "5/18/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Octiplus: refund",
                  "narration2": "FEE EXEMPT",
                  "remarks": "USSD/AKOMOLAFE OLUSOLA GRACE",
                  "balance": "4946.31"
              },
              {
                  "sn": "150",
                  "transactionId": "S39978602",
                  "transactionDate": "5/19/2022 12:00:00 AM",
                  "valueDate": "5/19/2022 12:00:00 AM",
                  "debitAmount": "2150",
                  "creditAmount": "0",
                  "narration": "Trf - Octiplus: payment",
                  "narration2": "000020220519000312009004606616/O0068317624",
                  "remarks": "USSD/OBINAKABA JENNIFER CHINAZ",
                  "balance": "2796.31"
              },
              {
                  "sn": "151",
                  "transactionId": "S39978602",
                  "transactionDate": "5/19/2022 12:00:00 AM",
                  "valueDate": "5/19/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Octiplus: payment",
                  "narration2": "FEE EXEMPT",
                  "remarks": "USSD/OBINAKABA JENNIFER CHINAZ",
                  "balance": "2785.56"
              },
              {
                  "sn": "152",
                  "transactionId": "S40821572",
                  "transactionDate": "5/23/2022 12:00:00 AM",
                  "valueDate": "5/21/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "10000",
                  "narration": "INW pos",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/637887172623",
                  "balance": "12785.56"
              },
              {
                  "sn": "153",
                  "transactionId": "S40821914",
                  "transactionDate": "5/23/2022 12:00:00 AM",
                  "valueDate": "5/21/2022 12:00:00 AM",
                  "debitAmount": "9000",
                  "creditAmount": "0",
                  "narration": "Trf - Octiplus: pos",
                  "narration2": "000020220521091117009004637325/O2011197902",
                  "remarks": "USSD/OKUNADE, ABODUNRIN KUDIRA",
                  "balance": "3785.56"
              },
              {
                  "sn": "154",
                  "transactionId": "S40821914",
                  "transactionDate": "5/23/2022 12:00:00 AM",
                  "valueDate": "5/21/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Octiplus: pos",
                  "narration2": "FEE EXEMPT",
                  "remarks": "USSD/OKUNADE, ABODUNRIN KUDIRA",
                  "balance": "3758.68"
              },
              {
                  "sn": "155",
                  "transactionId": "S41329494",
                  "transactionDate": "5/23/2022 12:00:00 AM",
                  "valueDate": "5/23/2022 12:00:00 AM",
                  "debitAmount": "3000",
                  "creditAmount": "0",
                  "narration": "ATM WD on 23-05-2022@34 Adeniyi Jones S",
                  "narration2": "",
                  "remarks": "10300102/9806/99589603",
                  "balance": "758.68"
              },
              {
                  "sn": "156",
                  "transactionId": "S41561094",
                  "transactionDate": "5/24/2022 12:00:00 AM",
                  "valueDate": "5/24/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "137277.12",
                  "narration": "MAY 2022 SALARY",
                  "narration2": "",
                  "remarks": "",
                  "balance": "138035.8"
              },
              {
                  "sn": "157",
                  "transactionId": "S41571075",
                  "transactionDate": "5/24/2022 12:00:00 AM",
                  "valueDate": "5/24/2022 12:00:00 AM",
                  "debitAmount": "1000",
                  "creditAmount": "0",
                  "narration": "MAY 2022 ASSBIFI  DUES",
                  "narration2": "",
                  "remarks": "",
                  "balance": "137035.8"
              },
              {
                  "sn": "158",
                  "transactionId": "S41580450",
                  "transactionDate": "5/24/2022 12:00:00 AM",
                  "valueDate": "5/24/2022 12:00:00 AM",
                  "debitAmount": "50000",
                  "creditAmount": "0",
                  "narration": "INW Repay",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/637889988830",
                  "balance": "87035.8"
              },
              {
                  "sn": "159",
                  "transactionId": "S41584308",
                  "transactionDate": "5/24/2022 12:00:00 AM",
                  "valueDate": "5/24/2022 12:00:00 AM",
                  "debitAmount": "25000",
                  "creditAmount": "0",
                  "narration": "Trf - Octiplus: Vince",
                  "narration2": "000020220524152536009004674086/O2404270120",
                  "remarks": "USSD/AJIBOLA ABIDEMI AMINU",
                  "balance": "62035.8"
              },
              {
                  "sn": "160",
                  "transactionId": "S41584308",
                  "transactionDate": "5/24/2022 12:00:00 AM",
                  "valueDate": "5/24/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Octiplus: Vince",
                  "narration2": "FEE EXEMPT",
                  "remarks": "USSD/AJIBOLA ABIDEMI AMINU",
                  "balance": "62008.92"
              },
              {
                  "sn": "161",
                  "transactionId": "S41589523",
                  "transactionDate": "5/24/2022 12:00:00 AM",
                  "valueDate": "5/24/2022 12:00:00 AM",
                  "debitAmount": "3000",
                  "creditAmount": "0",
                  "narration": "INW Vince",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/637889991496",
                  "balance": "59008.92"
              },
              {
                  "sn": "162",
                  "transactionId": "S41671614",
                  "transactionDate": "5/24/2022 12:00:00 AM",
                  "valueDate": "5/24/2022 12:00:00 AM",
                  "debitAmount": "9000",
                  "creditAmount": "0",
                  "narration": "Trf - Octiplus: Olembe",
                  "narration2": "000020220524162025009004675183/O0010453115",
                  "remarks": "USSD/OSEKHALA MICHEAL ILUOBE",
                  "balance": "50008.92"
              },
              {
                  "sn": "163",
                  "transactionId": "S41671614",
                  "transactionDate": "5/24/2022 12:00:00 AM",
                  "valueDate": "5/24/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Octiplus: Olembe",
                  "narration2": "FEE EXEMPT",
                  "remarks": "USSD/OSEKHALA MICHEAL ILUOBE",
                  "balance": "49982.04"
              },
              {
                  "sn": "164",
                  "transactionId": "S41704081",
                  "transactionDate": "5/24/2022 12:00:00 AM",
                  "valueDate": "5/24/2022 12:00:00 AM",
                  "debitAmount": "10000",
                  "creditAmount": "0",
                  "narration": "ATM WD on 24-05-2022@34 Adeniyi Jones S",
                  "narration2": "",
                  "remarks": "10300102/9900/99656421",
                  "balance": "39982.04"
              },
              {
                  "sn": "165",
                  "transactionId": "   M11382",
                  "transactionDate": "5/25/2022 12:00:00 AM",
                  "valueDate": "5/25/2022 12:00:00 AM",
                  "debitAmount": "1500",
                  "creditAmount": "0",
                  "narration": "STAFF WELFARE  FOR MAY 2022",
                  "narration2": "",
                  "remarks": "",
                  "balance": "38482.04"
              },
              {
                  "sn": "166",
                  "transactionId": "S41759721",
                  "transactionDate": "5/25/2022 12:00:00 AM",
                  "valueDate": "5/25/2022 12:00:00 AM",
                  "debitAmount": "3000",
                  "creditAmount": "0",
                  "narration": "INW Vince4Niffy",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/637890622297",
                  "balance": "35482.04"
              },
              {
                  "sn": "167",
                  "transactionId": "S41783402",
                  "transactionDate": "5/25/2022 12:00:00 AM",
                  "valueDate": "5/25/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "40000",
                  "narration": "000-MOSHOOD AMINU-54651653473752166/FI",
                  "narration2": "000016220525111558000003102719/I2040685018",
                  "remarks": "NIP/NIP/MOSHOOD AMINU",
                  "balance": "75482.04"
              },
              {
                  "sn": "168",
                  "transactionId": "S41783402",
                  "transactionDate": "5/25/2022 12:00:00 AM",
                  "valueDate": "5/25/2022 12:00:00 AM",
                  "debitAmount": "50",
                  "creditAmount": "0",
                  "narration": "STAMP DUTY CHARGE ON DEPOSIT: 5300017669",
                  "narration2": "FEE EXEMPT",
                  "remarks": "NIP/NIP/MOSHOOD AMINU",
                  "balance": "75432.04"
              },
              {
                  "sn": "169",
                  "transactionId": "S41800082",
                  "transactionDate": "5/25/2022 12:00:00 AM",
                  "valueDate": "5/25/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "INW Vincent",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/637890785160",
                  "balance": "70432.04"
              },
              {
                  "sn": "170",
                  "transactionId": "S42118367",
                  "transactionDate": "5/26/2022 12:00:00 AM",
                  "valueDate": "5/26/2022 12:00:00 AM",
                  "debitAmount": "6000",
                  "creditAmount": "0",
                  "narration": "POS Buy on 26-05-2022@ADELEYE ADEWALE@00021001165",
                  "narration2": "",
                  "remarks": "201115OR/13080/210011658",
                  "balance": "64432.04"
              },
              {
                  "sn": "171",
                  "transactionId": "S42259964",
                  "transactionDate": "5/26/2022 12:00:00 AM",
                  "valueDate": "5/26/2022 12:00:00 AM",
                  "debitAmount": "1750",
                  "creditAmount": "0",
                  "narration": "INW vince",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/637891667140",
                  "balance": "62682.04"
              },
              {
                  "sn": "172",
                  "transactionId": "S43130190",
                  "transactionDate": "5/27/2022 12:00:00 AM",
                  "valueDate": "5/27/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "8000",
                  "narration": "ATM Trf 27-05-2022 @ ABC DOPEMU             LAGOS",
                  "narration2": "",
                  "remarks": "1030010O/6179/99876145",
                  "balance": "70682.04"
              },
              {
                  "sn": "173",
                  "transactionId": "S43182816",
                  "transactionDate": "5/27/2022 12:00:00 AM",
                  "valueDate": "5/27/2022 12:00:00 AM",
                  "debitAmount": "10000",
                  "creditAmount": "0",
                  "narration": "ATM WD on 27-05-2022@Festac",
                  "narration2": "",
                  "remarks": "17015011/1865/6884165748",
                  "balance": "60682.04"
              },
              {
                  "sn": "174",
                  "transactionId": "S43244204",
                  "transactionDate": "5/27/2022 12:00:00 AM",
                  "valueDate": "5/27/2022 12:00:00 AM",
                  "debitAmount": "3000",
                  "creditAmount": "0",
                  "narration": "POS Buy on 27-05-2022@SEASON 7 CATERI@00021000562",
                  "narration2": "",
                  "remarks": "2070IS66/7649/210005628",
                  "balance": "57682.04"
              },
              {
                  "sn": "175",
                  "transactionId": "S43244481",
                  "transactionDate": "5/27/2022 12:00:00 AM",
                  "valueDate": "5/27/2022 12:00:00 AM",
                  "debitAmount": "3000",
                  "creditAmount": "0",
                  "narration": "POS Buy on 27-05-2022@SEASON 7 CATERI@00021000562",
                  "narration2": "",
                  "remarks": "2070IS66/7650/210005629",
                  "balance": "54682.04"
              },
              {
                  "sn": "176",
                  "transactionId": "S43428510",
                  "transactionDate": "5/30/2022 12:00:00 AM",
                  "valueDate": "5/28/2022 12:00:00 AM",
                  "debitAmount": "20000",
                  "creditAmount": "0",
                  "narration": "ATM WD on 28-05-2022@34 Adeniyi Jones S",
                  "narration2": "",
                  "remarks": "10300102/1202/99916286",
                  "balance": "34682.04"
              },
              {
                  "sn": "177",
                  "transactionId": "S43428644",
                  "transactionDate": "5/30/2022 12:00:00 AM",
                  "valueDate": "5/28/2022 12:00:00 AM",
                  "debitAmount": "20000",
                  "creditAmount": "0",
                  "narration": "ATM WD on 28-05-2022@34 Adeniyi Jones S",
                  "narration2": "",
                  "remarks": "10300102/1203/99916330",
                  "balance": "14682.04"
              },
              {
                  "sn": "178",
                  "transactionId": "S43559588",
                  "transactionDate": "5/30/2022 12:00:00 AM",
                  "valueDate": "5/28/2022 12:00:00 AM",
                  "debitAmount": "700",
                  "creditAmount": "0",
                  "narration": "Trf - Octiplus: bill_Olembe",
                  "narration2": "000020220528223812009004734825/O1219470682",
                  "remarks": "USSD/D3 LODGE LTD",
                  "balance": "13982.04"
              },
              {
                  "sn": "179",
                  "transactionId": "S43559588",
                  "transactionDate": "5/30/2022 12:00:00 AM",
                  "valueDate": "5/28/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Octiplus: bill_Olembe",
                  "narration2": "FEE EXEMPT",
                  "remarks": "USSD/D3 LODGE LTD",
                  "balance": "13971.29"
              },
              {
                  "sn": "180",
                  "transactionId": "S43739401",
                  "transactionDate": "5/30/2022 12:00:00 AM",
                  "valueDate": "5/29/2022 12:00:00 AM",
                  "debitAmount": "1650",
                  "creditAmount": "0",
                  "narration": "POS Buy on 29-05-2022@ADA PEACE NWOKE@00021000398",
                  "narration2": "",
                  "remarks": "2070HR52/4420/210003981",
                  "balance": "12321.29"
              },
              {
                  "sn": "181",
                  "transactionId": "S44177444",
                  "transactionDate": "5/30/2022 12:00:00 AM",
                  "valueDate": "5/30/2022 12:00:00 AM",
                  "debitAmount": "1400",
                  "creditAmount": "0",
                  "narration": "POS Buy on 30-05-2022@THE APEX H29   @00021000235",
                  "narration2": "",
                  "remarks": "2101AF22/4469/210002351",
                  "balance": "10921.29"
              },
              {
                  "sn": "182",
                  "transactionId": "S44333183",
                  "transactionDate": "5/31/2022 12:00:00 AM",
                  "valueDate": "5/31/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "35000",
                  "narration": "INW self",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/637895755712",
                  "balance": "45921.29"
              },
              {
                  "sn": "183",
                  "transactionId": "S44335635",
                  "transactionDate": "5/31/2022 12:00:00 AM",
                  "valueDate": "5/31/2022 12:00:00 AM",
                  "debitAmount": "10000",
                  "creditAmount": "0",
                  "narration": "Trf - Octiplus: Trousers",
                  "narration2": "000020220531080939009004759726/O0010453115",
                  "remarks": "USSD/OSEKHALA MICHEAL ILUOBE",
                  "balance": "35921.29"
              },
              {
                  "sn": "184",
                  "transactionId": "S44335635",
                  "transactionDate": "5/31/2022 12:00:00 AM",
                  "valueDate": "5/31/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Octiplus: Trousers",
                  "narration2": "FEE EXEMPT",
                  "remarks": "USSD/OSEKHALA MICHEAL ILUOBE",
                  "balance": "35894.41"
              },
              {
                  "sn": "185",
                  "transactionId": "S44359534",
                  "transactionDate": "5/31/2022 12:00:00 AM",
                  "valueDate": "5/31/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "2000",
                  "narration": "000-AKPARANTA IBE CAMPBELL-25616728866",
                  "narration2": "000016220531112120000023320911/I2032866801",
                  "remarks": "NIP/NIP/AKPARANTA IBE CAMPBELL",
                  "balance": "37894.41"
              },
              {
                  "sn": "186",
                  "transactionId": "S44644810",
                  "transactionDate": "5/31/2022 12:00:00 AM",
                  "valueDate": "5/31/2022 12:00:00 AM",
                  "debitAmount": "20000",
                  "creditAmount": "0",
                  "narration": "ATM WD on 31-05-2022@34 Adeniyi Jones S",
                  "narration2": "",
                  "remarks": "10300103/1644/100104115",
                  "balance": "17894.41"
              },
              {
                  "sn": "187",
                  "transactionId": "S45241340",
                  "transactionDate": "6/1/2022 12:00:00 AM",
                  "valueDate": "6/1/2022 12:00:00 AM",
                  "debitAmount": "2500",
                  "creditAmount": "0",
                  "narration": "Trf - Octiplus: bills",
                  "narration2": "000020220601210020009004787256/O0087647863",
                  "remarks": "USSD/NNAMCHI CHIZOBA MARYANN",
                  "balance": "15394.41"
              },
              {
                  "sn": "188",
                  "transactionId": "S45241340",
                  "transactionDate": "6/1/2022 12:00:00 AM",
                  "valueDate": "6/1/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Octiplus: bills",
                  "narration2": "FEE EXEMPT",
                  "remarks": "USSD/NNAMCHI CHIZOBA MARYANN",
                  "balance": "15383.66"
              },
              {
                  "sn": "189",
                  "transactionId": "S45602101",
                  "transactionDate": "6/2/2022 12:00:00 AM",
                  "valueDate": "6/2/2022 12:00:00 AM",
                  "debitAmount": "2000",
                  "creditAmount": "0",
                  "narration": "Trf - Octiplus: follow come",
                  "narration2": "000020220602162939009004799569/O0026113667",
                  "remarks": "USSD/EZEKIEL PRECIOUS",
                  "balance": "13383.66"
              },
              {
                  "sn": "190",
                  "transactionId": "S45602101",
                  "transactionDate": "6/2/2022 12:00:00 AM",
                  "valueDate": "6/2/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Octiplus: follow come",
                  "narration2": "FEE EXEMPT",
                  "remarks": "USSD/EZEKIEL PRECIOUS",
                  "balance": "13372.91"
              },
              {
                  "sn": "191",
                  "transactionId": "S45607199",
                  "transactionDate": "6/2/2022 12:00:00 AM",
                  "valueDate": "6/2/2022 12:00:00 AM",
                  "debitAmount": "1000",
                  "creditAmount": "0",
                  "narration": "Trf - Octiplus: screenguard",
                  "narration2": "000020220602163838009004799736/O8035138661",
                  "remarks": "USSD/Florence Chinasa Mbalewe",
                  "balance": "12372.91"
              },
              {
                  "sn": "192",
                  "transactionId": "S45607199",
                  "transactionDate": "6/2/2022 12:00:00 AM",
                  "valueDate": "6/2/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Octiplus: screenguard",
                  "narration2": "FEE EXEMPT",
                  "remarks": "USSD/Florence Chinasa Mbalewe",
                  "balance": "12362.16"
              },
              {
                  "sn": "193",
                  "transactionId": "S45922743",
                  "transactionDate": "6/3/2022 12:00:00 AM",
                  "valueDate": "6/3/2022 12:00:00 AM",
                  "debitAmount": "1500",
                  "creditAmount": "0",
                  "narration": "BPW GLO Airtime Purchase07051456716-91309",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/196033732749",
                  "balance": "10862.16"
              },
              {
                  "sn": "194",
                  "transactionId": "S45994361",
                  "transactionDate": "6/3/2022 12:00:00 AM",
                  "valueDate": "6/3/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "6100",
                  "narration": "NXG :TRFFRMCHUKWUEMEKAOKELEKETOVINCENTOK",
                  "narration2": "",
                  "remarks": "/176938/297771682",
                  "balance": "16962.16"
              },
              {
                  "sn": "195",
                  "transactionId": "S46442096",
                  "transactionDate": "6/3/2022 12:00:00 AM",
                  "valueDate": "6/3/2022 12:00:00 AM",
                  "debitAmount": "3600",
                  "creditAmount": "0",
                  "narration": "POS Buy on 03-06-2022@SEASON 7 CATERI@00021000242",
                  "narration2": "",
                  "remarks": "2070GN43/3446/210002428",
                  "balance": "13362.16"
              },
              {
                  "sn": "196",
                  "transactionId": "S46662188",
                  "transactionDate": "6/6/2022 12:00:00 AM",
                  "valueDate": "6/4/2022 12:00:00 AM",
                  "debitAmount": "2000",
                  "creditAmount": "0",
                  "narration": "Trf - Octiplus: Olembe_pledge",
                  "narration2": "000020220604072913009004819969/O0063229285",
                  "remarks": "USSD/CASSIUS CHUKWUNENYE OSUJI",
                  "balance": "11362.16"
              },
              {
                  "sn": "197",
                  "transactionId": "S46662188",
                  "transactionDate": "6/6/2022 12:00:00 AM",
                  "valueDate": "6/4/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Octiplus: Olembe_pledge",
                  "narration2": "FEE EXEMPT",
                  "remarks": "USSD/CASSIUS CHUKWUNENYE OSUJI",
                  "balance": "11351.41"
              },
              {
                  "sn": "198",
                  "transactionId": "S47239121",
                  "transactionDate": "6/6/2022 12:00:00 AM",
                  "valueDate": "6/5/2022 12:00:00 AM",
                  "debitAmount": "3500",
                  "creditAmount": "0",
                  "narration": "BPW MTN Airtime Purchase07033671485-90304",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/196097071769",
                  "balance": "7851.41"
              },
              {
                  "sn": "199",
                  "transactionId": "S47377099",
                  "transactionDate": "6/6/2022 12:00:00 AM",
                  "valueDate": "6/5/2022 12:00:00 AM",
                  "debitAmount": "7100",
                  "creditAmount": "0",
                  "narration": "POS Trf on 05-06-2022@T AL  BAQI VENTURE",
                  "narration2": "",
                  "remarks": "2TEPTS18/5213/5213",
                  "balance": "751.41"
              },
              {
                  "sn": "200",
                  "transactionId": "S47640859",
                  "transactionDate": "6/6/2022 12:00:00 AM",
                  "valueDate": "6/6/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "20700",
                  "narration": "INW self",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/637901346419",
                  "balance": "21451.41"
              },
              {
                  "sn": "201",
                  "transactionId": "S47885614",
                  "transactionDate": "6/7/2022 12:00:00 AM",
                  "valueDate": "6/7/2022 12:00:00 AM",
                  "debitAmount": "10000",
                  "creditAmount": "0",
                  "narration": "Trf - Octiplus: Clothes",
                  "narration2": "000020220607061753009004855168/O0010453115",
                  "remarks": "USSD/OSEKHALA MICHEAL ILUOBE",
                  "balance": "11451.41"
              },
              {
                  "sn": "202",
                  "transactionId": "S47885614",
                  "transactionDate": "6/7/2022 12:00:00 AM",
                  "valueDate": "6/7/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Octiplus: Clothes",
                  "narration2": "FEE EXEMPT",
                  "remarks": "USSD/OSEKHALA MICHEAL ILUOBE",
                  "balance": "11424.53"
              },
              {
                  "sn": "203",
                  "transactionId": "S48174903",
                  "transactionDate": "6/7/2022 12:00:00 AM",
                  "valueDate": "6/7/2022 12:00:00 AM",
                  "debitAmount": "7100",
                  "creditAmount": "0",
                  "narration": "POS Trf on 07-06-2022@T AL  BAQI VENTURE",
                  "narration2": "",
                  "remarks": "2TEPTS18/5329/5329",
                  "balance": "4324.53"
              },
              {
                  "sn": "204",
                  "transactionId": "S48568971",
                  "transactionDate": "6/9/2022 12:00:00 AM",
                  "valueDate": "6/9/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "20000",
                  "narration": "000-OKELEKE  VINCENT AWELE-USSD NIP Tr",
                  "narration2": "000013220609171339000260941461/I0106161053",
                  "remarks": "NIP/NIP/OKELEKE  VINCENT AWELE",
                  "balance": "24324.53"
              },
              {
                  "sn": "205",
                  "transactionId": "S48568971",
                  "transactionDate": "6/9/2022 12:00:00 AM",
                  "valueDate": "6/9/2022 12:00:00 AM",
                  "debitAmount": "50",
                  "creditAmount": "0",
                  "narration": "STAMP DUTY CHARGE ON DEPOSIT: 5300017669",
                  "narration2": "FEE EXEMPT",
                  "remarks": "NIP/NIP/OKELEKE  VINCENT AWELE",
                  "balance": "24274.53"
              },
              {
                  "sn": "206",
                  "transactionId": "S48601401",
                  "transactionDate": "6/9/2022 12:00:00 AM",
                  "valueDate": "6/9/2022 12:00:00 AM",
                  "debitAmount": "10000",
                  "creditAmount": "0",
                  "narration": "Trf - Octiplus: Hosting",
                  "narration2": "000020220609185811009004893026/O0602024562",
                  "remarks": "USSD/AKPAN JOSEPH UMANA",
                  "balance": "14274.53"
              },
              {
                  "sn": "207",
                  "transactionId": "S48601401",
                  "transactionDate": "6/9/2022 12:00:00 AM",
                  "valueDate": "6/9/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Octiplus: Hosting",
                  "narration2": "FEE EXEMPT",
                  "remarks": "USSD/AKPAN JOSEPH UMANA",
                  "balance": "14247.65"
              },
              {
                  "sn": "208",
                  "transactionId": "S48616471",
                  "transactionDate": "6/9/2022 12:00:00 AM",
                  "valueDate": "6/9/2022 12:00:00 AM",
                  "debitAmount": "2500",
                  "creditAmount": "0",
                  "narration": "POS Buy on 09-06-2022@ACHONYE FRANCIS@00021000148",
                  "narration2": "",
                  "remarks": "2044IHM4/3140/210001489",
                  "balance": "11747.65"
              },
              {
                  "sn": "209",
                  "transactionId": "S48797702",
                  "transactionDate": "6/10/2022 12:00:00 AM",
                  "valueDate": "6/10/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "POS Buy on 10-06-2022@ADELEYE ADEWALE@00021001320",
                  "narration2": "",
                  "remarks": "201115OR/14768/210013205",
                  "balance": "6747.65"
              },
              {
                  "sn": "210",
                  "transactionId": "S48844044",
                  "transactionDate": "6/10/2022 12:00:00 AM",
                  "valueDate": "6/10/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "ATM WD on 10-06-2022@34 Adeniyi Jones S",
                  "narration2": "",
                  "remarks": "10300102/3345/100818133",
                  "balance": "1747.65"
              },
              {
                  "sn": "211",
                  "transactionId": "S48990760",
                  "transactionDate": "6/10/2022 12:00:00 AM",
                  "valueDate": "6/10/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "20000",
                  "narration": "INW self",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/637904799912",
                  "balance": "21747.65"
              },
              {
                  "sn": "212",
                  "transactionId": "S49002704",
                  "transactionDate": "6/10/2022 12:00:00 AM",
                  "valueDate": "6/10/2022 12:00:00 AM",
                  "debitAmount": "5100",
                  "creditAmount": "0",
                  "narration": "POS Trf on 10-06-2022@Withdrawal at",
                  "narration2": "",
                  "remarks": "2044UX51/821407/5213991593",
                  "balance": "16647.65"
              },
              {
                  "sn": "213",
                  "transactionId": "S49165333",
                  "transactionDate": "6/14/2022 12:00:00 AM",
                  "valueDate": "6/11/2022 12:00:00 AM",
                  "debitAmount": "2000",
                  "creditAmount": "0",
                  "narration": "BPW Eko Electricity Distribution Company Plc Prepa",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/196062922861",
                  "balance": "14647.65"
              },
              {
                  "sn": "214",
                  "transactionId": "S49165333",
                  "transactionDate": "6/14/2022 12:00:00 AM",
                  "valueDate": "6/11/2022 12:00:00 AM",
                  "debitAmount": "100",
                  "creditAmount": "0",
                  "narration": "Fee - Eko Electricity Distribution Company Plc Pre",
                  "narration2": "FEE EXEMPT",
                  "remarks": "OTS45951/196062922861",
                  "balance": "14547.65"
              },
              {
                  "sn": "215",
                  "transactionId": "S49205504",
                  "transactionDate": "6/14/2022 12:00:00 AM",
                  "valueDate": "6/11/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "Trf - Octiplus: Hosting",
                  "narration2": "000020220611152747009004916621/O1530269901",
                  "remarks": "USSD/EZINNA VITA JOSEPH",
                  "balance": "9547.65"
              },
              {
                  "sn": "216",
                  "transactionId": "S49205504",
                  "transactionDate": "6/14/2022 12:00:00 AM",
                  "valueDate": "6/11/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Octiplus: Hosting",
                  "narration2": "FEE EXEMPT",
                  "remarks": "USSD/EZINNA VITA JOSEPH",
                  "balance": "9536.9"
              },
              {
                  "sn": "217",
                  "transactionId": "S49249517",
                  "transactionDate": "6/14/2022 12:00:00 AM",
                  "valueDate": "6/12/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "5000",
                  "narration": "000-UMOH  NDIFREKE MACDONALD-USSD NIP",
                  "narration2": "000013220612085452000265939972/I0012955119",
                  "remarks": "NIP/NIP/UMOH  NDIFREKE MACDONA",
                  "balance": "14536.9"
              },
              {
                  "sn": "218",
                  "transactionId": "S49250050",
                  "transactionDate": "6/14/2022 12:00:00 AM",
                  "valueDate": "6/12/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "ATM WD on 12-06-2022@UBA FESTAC 1 BO AT",
                  "narration2": "",
                  "remarks": "10330376/3672/4559367772",
                  "balance": "9536.9"
              },
              {
                  "sn": "219",
                  "transactionId": "S49277527",
                  "transactionDate": "6/14/2022 12:00:00 AM",
                  "valueDate": "6/12/2022 12:00:00 AM",
                  "debitAmount": "9100",
                  "creditAmount": "0",
                  "narration": "Trf - Vincent",
                  "narration2": "000020220612162437004004926856/O2011197902",
                  "remarks": "MOBILE/OKUNADE, ABODUNRIN KUDI",
                  "balance": "436.9"
              },
              {
                  "sn": "220",
                  "transactionId": "S49277527",
                  "transactionDate": "6/14/2022 12:00:00 AM",
                  "valueDate": "6/12/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Vincent",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/OKUNADE, ABODUNRIN KUDI",
                  "balance": "410.02"
              },
              {
                  "sn": "221",
                  "transactionId": "S49342416",
                  "transactionDate": "6/14/2022 12:00:00 AM",
                  "valueDate": "6/13/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "10000",
                  "narration": "INW self",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/637907250213",
                  "balance": "10410.02"
              },
              {
                  "sn": "222",
                  "transactionId": "S49347003",
                  "transactionDate": "6/14/2022 12:00:00 AM",
                  "valueDate": "6/13/2022 12:00:00 AM",
                  "debitAmount": "5100",
                  "creditAmount": "0",
                  "narration": "POS Trf on 13-06-2022@T VIVIAN VENTURE",
                  "narration2": "",
                  "remarks": "2TEPG709/10952/10952",
                  "balance": "5310.02"
              },
              {
                  "sn": "223",
                  "transactionId": "S50119225",
                  "transactionDate": "6/15/2022 12:00:00 AM",
                  "valueDate": "6/15/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "ATM WD on 15-06-2022@34 Adeniyi Jones S",
                  "narration2": "",
                  "remarks": "10300102/3899/101094230",
                  "balance": "310.02"
              },
              {
                  "sn": "224",
                  "transactionId": "S50226359",
                  "transactionDate": "6/16/2022 12:00:00 AM",
                  "valueDate": "6/16/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "10000",
                  "narration": "INW self",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/637909810488",
                  "balance": "10310.02"
              },
              {
                  "sn": "225",
                  "transactionId": "S50525151",
                  "transactionDate": "6/17/2022 12:00:00 AM",
                  "valueDate": "6/17/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "ATM WD on 17-06-2022@34 Adeniyi Jones S",
                  "narration2": "",
                  "remarks": "10300102/5076/101243260",
                  "balance": "5310.02"
              },
              {
                  "sn": "226",
                  "transactionId": "S50630706",
                  "transactionDate": "6/20/2022 12:00:00 AM",
                  "valueDate": "6/18/2022 12:00:00 AM",
                  "debitAmount": "5100",
                  "creditAmount": "0",
                  "narration": "POS Trf on 18-06-2022@T AL  BAQI VENTURE",
                  "narration2": "",
                  "remarks": "2TEPTS18/6036/6036",
                  "balance": "210.02"
              },
              {
                  "sn": "227",
                  "transactionId": "S50708646",
                  "transactionDate": "6/20/2022 12:00:00 AM",
                  "valueDate": "6/20/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "9100",
                  "narration": "INW self",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/637912990135",
                  "balance": "9310.02"
              },
              {
                  "sn": "228",
                  "transactionId": "S50749628",
                  "transactionDate": "6/20/2022 12:00:00 AM",
                  "valueDate": "6/20/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "5000",
                  "narration": "000-NEL & CHUKS NIGERIA LIMITED-Fd",
                  "narration2": "000015220620113915002769578406/I1011250680",
                  "remarks": "NIP/NIP/NEL & CHUKS NIGERIA LI",
                  "balance": "14310.02"
              },
              {
                  "sn": "229",
                  "transactionId": "S50886496",
                  "transactionDate": "6/20/2022 12:00:00 AM",
                  "valueDate": "6/20/2022 12:00:00 AM",
                  "debitAmount": "7100",
                  "creditAmount": "0",
                  "narration": "Trf - Octiplus: pos",
                  "narration2": "000020220620184148009005023052/O2011197902",
                  "remarks": "USSD/OKUNADE, ABODUNRIN KUDIRA",
                  "balance": "7210.02"
              },
              {
                  "sn": "230",
                  "transactionId": "S50886496",
                  "transactionDate": "6/20/2022 12:00:00 AM",
                  "valueDate": "6/20/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Octiplus: pos",
                  "narration2": "FEE EXEMPT",
                  "remarks": "USSD/OKUNADE, ABODUNRIN KUDIRA",
                  "balance": "7183.14"
              },
              {
                  "sn": "231",
                  "transactionId": "S51769764",
                  "transactionDate": "6/22/2022 12:00:00 AM",
                  "valueDate": "6/22/2022 12:00:00 AM",
                  "debitAmount": "7000",
                  "creditAmount": "0",
                  "narration": "Mob Trf self",
                  "narration2": "BLEND-LOCAL TRANSFER:-6d7be0ec-7e4e-4c47-ad50-7a90",
                  "remarks": "5300017669 / 1001597495/163636",
                  "balance": "183.14"
              },
              {
                  "sn": "232",
                  "transactionId": "S52884607",
                  "transactionDate": "6/24/2022 12:00:00 AM",
                  "valueDate": "6/24/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "10000",
                  "narration": "web:vince",
                  "narration2": "",
                  "remarks": "1300012264/5164634",
                  "balance": "10183.14"
              },
              {
                  "sn": "233",
                  "transactionId": "S53074280",
                  "transactionDate": "6/24/2022 12:00:00 AM",
                  "valueDate": "6/24/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "137277.12",
                  "narration": "JUNE 2022 SALARY",
                  "narration2": "",
                  "remarks": "",
                  "balance": "147460.26"
              },
              {
                  "sn": "234",
                  "transactionId": "S53148798",
                  "transactionDate": "6/24/2022 12:00:00 AM",
                  "valueDate": "6/25/2022 12:00:00 AM",
                  "debitAmount": "50000",
                  "creditAmount": "0",
                  "narration": "INW Invinceable",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/637917259491",
                  "balance": "97460.26"
              },
              {
                  "sn": "235",
                  "transactionId": "S53149203",
                  "transactionDate": "6/24/2022 12:00:00 AM",
                  "valueDate": "6/25/2022 12:00:00 AM",
                  "debitAmount": "2100",
                  "creditAmount": "0",
                  "narration": "Trf - Octiplus: Olembe",
                  "narration2": "000020220625045428009005076476/O0042507295",
                  "remarks": "USSD/AKOSA ONYEKA AUGUSTI",
                  "balance": "95360.26"
              },
              {
                  "sn": "236",
                  "transactionId": "S53149203",
                  "transactionDate": "6/24/2022 12:00:00 AM",
                  "valueDate": "6/25/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Octiplus: Olembe",
                  "narration2": "FEE EXEMPT",
                  "remarks": "USSD/AKOSA ONYEKA AUGUSTI",
                  "balance": "95349.51"
              },
              {
                  "sn": "237",
                  "transactionId": "S53234519",
                  "transactionDate": "6/27/2022 12:00:00 AM",
                  "valueDate": "6/25/2022 12:00:00 AM",
                  "debitAmount": "50000",
                  "creditAmount": "0",
                  "narration": "INW Vince",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/637917483810",
                  "balance": "45349.51"
              },
              {
                  "sn": "238",
                  "transactionId": "S53236471",
                  "transactionDate": "6/27/2022 12:00:00 AM",
                  "valueDate": "6/25/2022 12:00:00 AM",
                  "debitAmount": "10050",
                  "creditAmount": "0",
                  "narration": "Mob Trf Invinceable",
                  "narration2": "BLEND-LOCAL TRANSFER:-4c018aba-6eac-48c6-a3fe-2f8f",
                  "remarks": "5300017669 / 1001597495/571533",
                  "balance": "35299.51"
              },
              {
                  "sn": "239",
                  "transactionId": "S53639600",
                  "transactionDate": "6/27/2022 12:00:00 AM",
                  "valueDate": "6/26/2022 12:00:00 AM",
                  "debitAmount": "3600",
                  "creditAmount": "0",
                  "narration": "Trf - Octiplus: vince",
                  "narration2": "000020220626223658009005101022/O2190658205",
                  "remarks": "USSD/OFFIKWU REJOICE",
                  "balance": "31699.51"
              },
              {
                  "sn": "240",
                  "transactionId": "S53639600",
                  "transactionDate": "6/27/2022 12:00:00 AM",
                  "valueDate": "6/26/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Octiplus: vince",
                  "narration2": "FEE EXEMPT",
                  "remarks": "USSD/OFFIKWU REJOICE",
                  "balance": "31688.76"
              },
              {
                  "sn": "241",
                  "transactionId": "S53655078",
                  "transactionDate": "6/27/2022 12:00:00 AM",
                  "valueDate": "6/27/2022 12:00:00 AM",
                  "debitAmount": "100",
                  "creditAmount": "0",
                  "narration": "BPW MTN Airtime Purchase07033671485-90304",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/196057196565",
                  "balance": "31588.76"
              },
              {
                  "sn": "242",
                  "transactionId": "S53963430",
                  "transactionDate": "6/27/2022 12:00:00 AM",
                  "valueDate": "6/27/2022 12:00:00 AM",
                  "debitAmount": "15000",
                  "creditAmount": "0",
                  "narration": "Mob Trf Mint",
                  "narration2": "BLEND-LOCAL TRANSFER:-62b4c0fd-f10b-4a9b-b057-6fc9",
                  "remarks": "5300017669 / 5300010914/107455",
                  "balance": "16588.76"
              },
              {
                  "sn": "243",
                  "transactionId": "S53973144",
                  "transactionDate": "6/27/2022 12:00:00 AM",
                  "valueDate": "6/27/2022 12:00:00 AM",
                  "debitAmount": "1000",
                  "creditAmount": "0",
                  "narration": "JUNE 2022 ASSBIFI  DUES",
                  "narration2": "",
                  "remarks": "",
                  "balance": "15588.76"
              },
              {
                  "sn": "244",
                  "transactionId": "    M5219",
                  "transactionDate": "6/28/2022 12:00:00 AM",
                  "valueDate": "6/28/2022 12:00:00 AM",
                  "debitAmount": "1500",
                  "creditAmount": "0",
                  "narration": "STAFF WELFARE  FOR JUNE 2022",
                  "narration2": "",
                  "remarks": "",
                  "balance": "14088.76"
              },
              {
                  "sn": "245",
                  "transactionId": "S54532852",
                  "transactionDate": "6/28/2022 12:00:00 AM",
                  "valueDate": "6/28/2022 12:00:00 AM",
                  "debitAmount": "1500",
                  "creditAmount": "0",
                  "narration": "Mob Recharge-Online: Recharge <Glo> <07051456716>",
                  "narration2": "BLEND-MOBILERECHARGE1-637920223157693097",
                  "remarks": "BLEND / BLD/780245980",
                  "balance": "12588.76"
              },
              {
                  "sn": "246",
                  "transactionId": "S54533585",
                  "transactionDate": "6/28/2022 12:00:00 AM",
                  "valueDate": "6/28/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "PART  PYMT 2022 ASSBIFI  DEV L",
                  "narration2": "",
                  "remarks": "",
                  "balance": "7588.76"
              },
              {
                  "sn": "247",
                  "transactionId": "S55140269",
                  "transactionDate": "6/29/2022 12:00:00 AM",
                  "valueDate": "6/29/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "5000",
                  "narration": "ATM Trf 29-06-2022 @ Stanbic@Allen Avenue   LA",
                  "narration2": "",
                  "remarks": "10390021/369/1093542223",
                  "balance": "12588.76"
              },
              {
                  "sn": "248",
                  "transactionId": "S55287950",
                  "transactionDate": "6/30/2022 12:00:00 AM",
                  "valueDate": "6/30/2022 12:00:00 AM",
                  "debitAmount": "4200",
                  "creditAmount": "0",
                  "narration": "Trf - Olembe",
                  "narration2": "000020220630124210004005146922/O5330323505",
                  "remarks": "MOBILE/IKERE PETER",
                  "balance": "8388.76"
              },
              {
                  "sn": "249",
                  "transactionId": "S55287950",
                  "transactionDate": "6/30/2022 12:00:00 AM",
                  "valueDate": "6/30/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Olembe",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/IKERE PETER",
                  "balance": "8378.01"
              },
              {
                  "sn": "250",
                  "transactionId": "S55292927",
                  "transactionDate": "6/30/2022 12:00:00 AM",
                  "valueDate": "6/30/2022 12:00:00 AM",
                  "debitAmount": "4200",
                  "creditAmount": "0",
                  "narration": "Trf - Olembe",
                  "narration2": "000020220630124706004005147000/O5330323505",
                  "remarks": "MOBILE/IKERE PETER",
                  "balance": "4178.01"
              },
              {
                  "sn": "251",
                  "transactionId": "S55292927",
                  "transactionDate": "6/30/2022 12:00:00 AM",
                  "valueDate": "6/30/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Olembe",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/IKERE PETER",
                  "balance": "4167.26"
              },
              {
                  "sn": "252",
                  "transactionId": "S55891148",
                  "transactionDate": "7/1/2022 12:00:00 AM",
                  "valueDate": "7/1/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "26000",
                  "narration": "web:vince",
                  "narration2": "",
                  "remarks": "1300012264/10430734",
                  "balance": "30167.26"
              },
              {
                  "sn": "253",
                  "transactionId": "S55893444",
                  "transactionDate": "7/1/2022 12:00:00 AM",
                  "valueDate": "7/1/2022 12:00:00 AM",
                  "debitAmount": "9000",
                  "creditAmount": "0",
                  "narration": "web:vince",
                  "narration2": "",
                  "remarks": "5300017669/10504134",
                  "balance": "21167.26"
              },
              {
                  "sn": "254",
                  "transactionId": "S56322289",
                  "transactionDate": "7/4/2022 12:00:00 AM",
                  "valueDate": "7/2/2022 12:00:00 AM",
                  "debitAmount": "3500",
                  "creditAmount": "0",
                  "narration": "Mob Recharge-Online: Recharge <MTN> <07033671485>",
                  "narration2": "BLEND-MOBILERECHARGE1-637923544451936586",
                  "remarks": "BLEND / BLD/1085274637",
                  "balance": "17667.26"
              },
              {
                  "sn": "255",
                  "transactionId": "S56364029",
                  "transactionDate": "7/4/2022 12:00:00 AM",
                  "valueDate": "7/2/2022 12:00:00 AM",
                  "debitAmount": "2500",
                  "creditAmount": "0",
                  "narration": "Trf - card",
                  "narration2": "000020220702183444004005184406/O0126836289",
                  "remarks": "MOBILE/MOKOGWU IFEANYI JOSEPHI",
                  "balance": "15167.26"
              },
              {
                  "sn": "256",
                  "transactionId": "S56364029",
                  "transactionDate": "7/4/2022 12:00:00 AM",
                  "valueDate": "7/2/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - card",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/MOKOGWU IFEANYI JOSEPHI",
                  "balance": "15156.51"
              },
              {
                  "sn": "257",
                  "transactionId": "S56405692",
                  "transactionDate": "7/4/2022 12:00:00 AM",
                  "valueDate": "7/3/2022 12:00:00 AM",
                  "debitAmount": "2000",
                  "creditAmount": "0",
                  "narration": "Trf - Olembe",
                  "narration2": "000020220703100007004005189914/O0063229285",
                  "remarks": "MOBILE/CASSIUS CHUKWUNENYE OSU",
                  "balance": "13156.51"
              },
              {
                  "sn": "258",
                  "transactionId": "S56405692",
                  "transactionDate": "7/4/2022 12:00:00 AM",
                  "valueDate": "7/3/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Olembe",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/CASSIUS CHUKWUNENYE OSU",
                  "balance": "13145.76"
              },
              {
                  "sn": "259",
                  "transactionId": "S56433727",
                  "transactionDate": "7/4/2022 12:00:00 AM",
                  "valueDate": "7/3/2022 12:00:00 AM",
                  "debitAmount": "2700",
                  "creditAmount": "0",
                  "narration": "Trf - books",
                  "narration2": "000020220703164020004005194050/O2050859702",
                  "remarks": "MOBILE/CHINO KENNETH",
                  "balance": "10445.76"
              },
              {
                  "sn": "260",
                  "transactionId": "S56433727",
                  "transactionDate": "7/4/2022 12:00:00 AM",
                  "valueDate": "7/3/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - books",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/CHINO KENNETH",
                  "balance": "10435.01"
              },
              {
                  "sn": "261",
                  "transactionId": "S56444976",
                  "transactionDate": "7/4/2022 12:00:00 AM",
                  "valueDate": "7/3/2022 12:00:00 AM",
                  "debitAmount": "2000",
                  "creditAmount": "0",
                  "narration": "Trf - fuel",
                  "narration2": "000020220703192112004005195784/O0022964170",
                  "remarks": "MOBILE/NWACHUKWU MUNACHIMSO",
                  "balance": "8435.01"
              },
              {
                  "sn": "262",
                  "transactionId": "S56444976",
                  "transactionDate": "7/4/2022 12:00:00 AM",
                  "valueDate": "7/3/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - fuel",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/NWACHUKWU MUNACHIMSO",
                  "balance": "8424.26"
              },
              {
                  "sn": "263",
                  "transactionId": "S56738997",
                  "transactionDate": "7/5/2022 12:00:00 AM",
                  "valueDate": "7/5/2022 12:00:00 AM",
                  "debitAmount": "100",
                  "creditAmount": "0",
                  "narration": "Mob Recharge-Online: Recharge <Glo> <07051456716>",
                  "narration2": "BLEND-MOBILERECHARGE1-637926054650983305",
                  "remarks": "BLEND / BLD/1124526938",
                  "balance": "8324.26"
              },
              {
                  "sn": "264",
                  "transactionId": "S56832194",
                  "transactionDate": "7/5/2022 12:00:00 AM",
                  "valueDate": "7/5/2022 12:00:00 AM",
                  "debitAmount": "8000",
                  "creditAmount": "0",
                  "narration": "Mob Trf ATM",
                  "narration2": "BLEND-LOCAL TRANSFER:-a8db13ac-f3b1-465f-9f64-3dd5",
                  "remarks": "5300017669 / 1001597495/188000",
                  "balance": "324.26"
              },
              {
                  "sn": "265",
                  "transactionId": "S57404931",
                  "transactionDate": "7/6/2022 12:00:00 AM",
                  "valueDate": "7/6/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "30000",
                  "narration": "000-OKUNADE BALIKIS ADEYINKA-Vincent R",
                  "narration2": "000013220706155442000315568767/I0154443493",
                  "remarks": "NIP/NIP/OKUNADE BALIKIS ADEYIN",
                  "balance": "30324.26"
              },
              {
                  "sn": "266",
                  "transactionId": "S57404931",
                  "transactionDate": "7/6/2022 12:00:00 AM",
                  "valueDate": "7/6/2022 12:00:00 AM",
                  "debitAmount": "50",
                  "creditAmount": "0",
                  "narration": "STAMP DUTY CHARGE ON DEPOSIT: 5300017669",
                  "narration2": "FEE EXEMPT",
                  "remarks": "NIP/NIP/OKUNADE BALIKIS ADEYIN",
                  "balance": "30274.26"
              },
              {
                  "sn": "267",
                  "transactionId": "S57585692",
                  "transactionDate": "7/6/2022 12:00:00 AM",
                  "valueDate": "7/6/2022 12:00:00 AM",
                  "debitAmount": "2000",
                  "creditAmount": "0",
                  "narration": "Trf - Coolant",
                  "narration2": "000020220706201340004005242426/O6161798270",
                  "remarks": "MOBILE/OBINNA STANLEY OZOEMENA",
                  "balance": "28274.26"
              },
              {
                  "sn": "268",
                  "transactionId": "S57585692",
                  "transactionDate": "7/6/2022 12:00:00 AM",
                  "valueDate": "7/6/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Coolant",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/OBINNA STANLEY OZOEMENA",
                  "balance": "28263.51"
              },
              {
                  "sn": "269",
                  "transactionId": "S57603350",
                  "transactionDate": "7/6/2022 12:00:00 AM",
                  "valueDate": "7/6/2022 12:00:00 AM",
                  "debitAmount": "5100",
                  "creditAmount": "0",
                  "narration": "Trf - pos",
                  "narration2": "000020220706210348004005243060/O0012953373",
                  "remarks": "MOBILE/OMOGBEYISOLA TEMITAYO M",
                  "balance": "23163.51"
              },
              {
                  "sn": "270",
                  "transactionId": "S57603350",
                  "transactionDate": "7/6/2022 12:00:00 AM",
                  "valueDate": "7/6/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - pos",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/OMOGBEYISOLA TEMITAYO M",
                  "balance": "23136.63"
              },
              {
                  "sn": "271",
                  "transactionId": "S58000778",
                  "transactionDate": "7/7/2022 12:00:00 AM",
                  "valueDate": "7/7/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "5000",
                  "narration": "100-eTranzact-NXG :TRFGIFTFRMOZOANIIKE",
                  "narration2": "100006220707201327847740226655/I",
                  "remarks": "NIP/NIP/eTranzact",
                  "balance": "28136.63"
              },
              {
                  "sn": "272",
                  "transactionId": "S58224991",
                  "transactionDate": "7/8/2022 12:00:00 AM",
                  "valueDate": "7/8/2022 12:00:00 AM",
                  "debitAmount": "8000",
                  "creditAmount": "0",
                  "narration": "Mob Trf ATM",
                  "narration2": "BLEND-LOCAL TRANSFER:-c950cfe0-309b-43ed-a443-3a16",
                  "remarks": "5300017669 / 1001597495/178974",
                  "balance": "20136.63"
              },
              {
                  "sn": "273",
                  "transactionId": "S58870005",
                  "transactionDate": "7/13/2022 12:00:00 AM",
                  "valueDate": "7/9/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "70000",
                  "narration": "000-OKELEKE ONYEKACHUKWU ANTHONIA-USSD",
                  "narration2": "000016220709135326000061328989/I3029407999",
                  "remarks": "NIP/NIP/OKELEKE ONYEKACHUKWU A",
                  "balance": "90136.63"
              },
              {
                  "sn": "274",
                  "transactionId": "S58870005",
                  "transactionDate": "7/13/2022 12:00:00 AM",
                  "valueDate": "7/9/2022 12:00:00 AM",
                  "debitAmount": "50",
                  "creditAmount": "0",
                  "narration": "STAMP DUTY CHARGE ON DEPOSIT: 5300017669",
                  "narration2": "FEE EXEMPT",
                  "remarks": "NIP/NIP/OKELEKE ONYEKACHUKWU A",
                  "balance": "90086.63"
              },
              {
                  "sn": "275",
                  "transactionId": "S58938906",
                  "transactionDate": "7/13/2022 12:00:00 AM",
                  "valueDate": "7/10/2022 12:00:00 AM",
                  "debitAmount": "10000",
                  "creditAmount": "0",
                  "narration": "Trf - Olembe",
                  "narration2": "000020220710084632004005282068/O0258157177",
                  "remarks": "MOBILE/KANU CHARLES CHIEMEZIEM",
                  "balance": "80086.63"
              },
              {
                  "sn": "276",
                  "transactionId": "S58938906",
                  "transactionDate": "7/13/2022 12:00:00 AM",
                  "valueDate": "7/10/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Olembe",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/KANU CHARLES CHIEMEZIEM",
                  "balance": "80059.75"
              },
              {
                  "sn": "277",
                  "transactionId": "S58942452",
                  "transactionDate": "7/13/2022 12:00:00 AM",
                  "valueDate": "7/10/2022 12:00:00 AM",
                  "debitAmount": "4200",
                  "creditAmount": "0",
                  "narration": "Trf - Ola",
                  "narration2": "000020220710094848004005282518/O0115705026",
                  "remarks": "MOBILE/OLADUNJOYE OLUMUYIWA GA",
                  "balance": "75859.75"
              },
              {
                  "sn": "278",
                  "transactionId": "S58942452",
                  "transactionDate": "7/13/2022 12:00:00 AM",
                  "valueDate": "7/10/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Ola",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/OLADUNJOYE OLUMUYIWA GA",
                  "balance": "75849"
              },
              {
                  "sn": "279",
                  "transactionId": "S58956419",
                  "transactionDate": "7/13/2022 12:00:00 AM",
                  "valueDate": "7/10/2022 12:00:00 AM",
                  "debitAmount": "9100",
                  "creditAmount": "0",
                  "narration": "Trf - Pos",
                  "narration2": "000020220710133307004005284283/O8000497719",
                  "remarks": "MOBILE/AL - BAQI VENTURES",
                  "balance": "66749"
              },
              {
                  "sn": "280",
                  "transactionId": "S58956419",
                  "transactionDate": "7/13/2022 12:00:00 AM",
                  "valueDate": "7/10/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Pos",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/AL - BAQI VENTURES",
                  "balance": "66722.12"
              },
              {
                  "sn": "281",
                  "transactionId": "S58970821",
                  "transactionDate": "7/13/2022 12:00:00 AM",
                  "valueDate": "7/10/2022 12:00:00 AM",
                  "debitAmount": "50000",
                  "creditAmount": "0",
                  "narration": "Trf - Awele",
                  "narration2": "000020220710170938004005286048/O0028116908",
                  "remarks": "MOBILE/CHUKWUEMEKA  OKELEKE",
                  "balance": "16722.12"
              },
              {
                  "sn": "282",
                  "transactionId": "S58970821",
                  "transactionDate": "7/13/2022 12:00:00 AM",
                  "valueDate": "7/10/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Awele",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/CHUKWUEMEKA  OKELEKE",
                  "balance": "16695.24"
              },
              {
                  "sn": "283",
                  "transactionId": "S58970969",
                  "transactionDate": "7/13/2022 12:00:00 AM",
                  "valueDate": "7/10/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "13000",
                  "narration": "Mob Trf self",
                  "narration2": "BLEND-LOCAL TRANSFER:-218752b3-e111-44ab-9df0-7c96",
                  "remarks": "1300012264 / 5300017669/365191",
                  "balance": "29695.24"
              },
              {
                  "sn": "284",
                  "transactionId": "S58984401",
                  "transactionDate": "7/13/2022 12:00:00 AM",
                  "valueDate": "7/10/2022 12:00:00 AM",
                  "debitAmount": "2000",
                  "creditAmount": "0",
                  "narration": "Trf - Olembe",
                  "narration2": "000020220710204106004005287794/O0026600059",
                  "remarks": "MOBILE/NKWOPARA CHIDINMA",
                  "balance": "27695.24"
              },
              {
                  "sn": "285",
                  "transactionId": "S58984401",
                  "transactionDate": "7/13/2022 12:00:00 AM",
                  "valueDate": "7/10/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Olembe",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/NKWOPARA CHIDINMA",
                  "balance": "27684.49"
              },
              {
                  "sn": "286",
                  "transactionId": "S58985384",
                  "transactionDate": "7/13/2022 12:00:00 AM",
                  "valueDate": "7/10/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "10000",
                  "narration": "000-GBENGA OLAMIDE OGUNBODEDE-Transfer",
                  "narration2": "000001220710205830000179149205/I0085846356",
                  "remarks": "NIP/NIP/GBENGA OLAMIDE OGUNBOD",
                  "balance": "37684.49"
              },
              {
                  "sn": "287",
                  "transactionId": "S58985384",
                  "transactionDate": "7/13/2022 12:00:00 AM",
                  "valueDate": "7/10/2022 12:00:00 AM",
                  "debitAmount": "50",
                  "creditAmount": "0",
                  "narration": "STAMP DUTY CHARGE ON DEPOSIT: 5300017669",
                  "narration2": "FEE EXEMPT",
                  "remarks": "NIP/NIP/GBENGA OLAMIDE OGUNBOD",
                  "balance": "37634.49"
              },
              {
                  "sn": "288",
                  "transactionId": "S59005004",
                  "transactionDate": "7/13/2022 12:00:00 AM",
                  "valueDate": "7/11/2022 12:00:00 AM",
                  "debitAmount": "3600",
                  "creditAmount": "0",
                  "narration": "Trf - temple",
                  "narration2": "000020220711093715004005290460/O6161798270",
                  "remarks": "MOBILE/OBINNA STANLEY OZOEMENA",
                  "balance": "34034.49"
              },
              {
                  "sn": "289",
                  "transactionId": "S59005004",
                  "transactionDate": "7/13/2022 12:00:00 AM",
                  "valueDate": "7/11/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - temple",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/OBINNA STANLEY OZOEMENA",
                  "balance": "34023.74"
              },
              {
                  "sn": "290",
                  "transactionId": "S59009137",
                  "transactionDate": "7/13/2022 12:00:00 AM",
                  "valueDate": "7/11/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "30000",
                  "narration": "000-LANRE S SONUBI-FT MB From Lanre OK",
                  "narration2": "000027220711101932039721451448/I1000008740",
                  "remarks": "NIP/NIP/LANRE S SONUBI",
                  "balance": "64023.74"
              },
              {
                  "sn": "291",
                  "transactionId": "S59009137",
                  "transactionDate": "7/13/2022 12:00:00 AM",
                  "valueDate": "7/11/2022 12:00:00 AM",
                  "debitAmount": "50",
                  "creditAmount": "0",
                  "narration": "STAMP DUTY CHARGE ON DEPOSIT: 5300017669",
                  "narration2": "FEE EXEMPT",
                  "remarks": "NIP/NIP/LANRE S SONUBI",
                  "balance": "63973.74"
              },
              {
                  "sn": "292",
                  "transactionId": "S59059405",
                  "transactionDate": "7/13/2022 12:00:00 AM",
                  "valueDate": "7/11/2022 12:00:00 AM",
                  "debitAmount": "6100",
                  "creditAmount": "0",
                  "narration": "Trf - pos",
                  "narration2": "000020220711211515004005298122/O7038665506",
                  "remarks": "MOBILE/Vivian  Nlemuzor",
                  "balance": "57873.74"
              },
              {
                  "sn": "293",
                  "transactionId": "S59059405",
                  "transactionDate": "7/13/2022 12:00:00 AM",
                  "valueDate": "7/11/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - pos",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/Vivian  Nlemuzor",
                  "balance": "57846.86"
              },
              {
                  "sn": "294",
                  "transactionId": "S59059859",
                  "transactionDate": "7/13/2022 12:00:00 AM",
                  "valueDate": "7/11/2022 12:00:00 AM",
                  "debitAmount": "1500",
                  "creditAmount": "0",
                  "narration": "Mob Recharge-Online: Recharge <Glo> <07051456716>",
                  "narration2": "BLEND-MOBILERECHARGE1-637931679926036457",
                  "remarks": "BLEND / BLD/1218944111",
                  "balance": "56346.86"
              },
              {
                  "sn": "295",
                  "transactionId": "S59076541",
                  "transactionDate": "7/13/2022 12:00:00 AM",
                  "valueDate": "7/12/2022 12:00:00 AM",
                  "debitAmount": "2460",
                  "creditAmount": "0",
                  "narration": "Mob Pymt obile: Payment For <GoTV>, Account Numbe",
                  "narration2": "BLEND-NTBANKI00000001-637932103387396168",
                  "remarks": "5300017669 / BILLER's NAME/124",
                  "balance": "53886.86"
              },
              {
                  "sn": "296",
                  "transactionId": "S59076541",
                  "transactionDate": "7/13/2022 12:00:00 AM",
                  "valueDate": "7/12/2022 12:00:00 AM",
                  "debitAmount": "100",
                  "creditAmount": "0",
                  "narration": "Mob Pymt Fee obile: Payment For <GoTV>, Account Nu",
                  "narration2": "FEE EXEMPT",
                  "remarks": "5300017669 / BILLER's NAME/124",
                  "balance": "53786.86"
              },
              {
                  "sn": "297",
                  "transactionId": "S59080969",
                  "transactionDate": "7/13/2022 12:00:00 AM",
                  "valueDate": "7/12/2022 12:00:00 AM",
                  "debitAmount": "20000",
                  "creditAmount": "0",
                  "narration": "Mob Trf Invinceable",
                  "narration2": "BLEND-LOCAL TRANSFER:-e876d190-f9b3-45e5-9567-8d2b",
                  "remarks": "5300017669 / 1001597495/139926",
                  "balance": "33786.86"
              },
              {
                  "sn": "298",
                  "transactionId": "S59379594",
                  "transactionDate": "7/13/2022 12:00:00 AM",
                  "valueDate": "7/13/2022 12:00:00 AM",
                  "debitAmount": "8100",
                  "creditAmount": "0",
                  "narration": "Trf - pos",
                  "narration2": "000020220713185555004005318748/O8000497719",
                  "remarks": "MOBILE/AL - BAQI VENTURES",
                  "balance": "25686.86"
              },
              {
                  "sn": "299",
                  "transactionId": "S59379594",
                  "transactionDate": "7/13/2022 12:00:00 AM",
                  "valueDate": "7/13/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - pos",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/AL - BAQI VENTURES",
                  "balance": "25659.98"
              },
              {
                  "sn": "300",
                  "transactionId": "S59652346",
                  "transactionDate": "7/14/2022 12:00:00 AM",
                  "valueDate": "7/14/2022 12:00:00 AM",
                  "debitAmount": "100",
                  "creditAmount": "0",
                  "narration": "Mob Recharge-Online: Recharge <Glo> <07051456716>",
                  "narration2": "BLEND-MOBILERECHARGE1-637933836275061295",
                  "remarks": "BLEND / BLD/255784201",
                  "balance": "25559.98"
              },
              {
                  "sn": "301",
                  "transactionId": "S59918249",
                  "transactionDate": "7/14/2022 12:00:00 AM",
                  "valueDate": "7/14/2022 12:00:00 AM",
                  "debitAmount": "20000",
                  "creditAmount": "0",
                  "narration": "Mob Trf dash",
                  "narration2": "BLEND-LOCAL TRANSFER:-b4ae2490-be41-4129-acc3-e726",
                  "remarks": "5300017669 / 1900723133/142056",
                  "balance": "5559.98"
              },
              {
                  "sn": "302",
                  "transactionId": "S60984373",
                  "transactionDate": "7/15/2022 12:00:00 AM",
                  "valueDate": "7/15/2022 12:00:00 AM",
                  "debitAmount": "800",
                  "creditAmount": "0",
                  "narration": "Trf - bal",
                  "narration2": "000020220715192855004005345375/O0115705026",
                  "remarks": "MOBILE/OLADUNJOYE OLUMUYIWA GA",
                  "balance": "4759.98"
              },
              {
                  "sn": "303",
                  "transactionId": "S60984373",
                  "transactionDate": "7/15/2022 12:00:00 AM",
                  "valueDate": "7/15/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - bal",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/OLADUNJOYE OLUMUYIWA GA",
                  "balance": "4749.23"
              },
              {
                  "sn": "304",
                  "transactionId": "S61308232",
                  "transactionDate": "7/18/2022 12:00:00 AM",
                  "valueDate": "7/16/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "5000",
                  "narration": "000-ALOZIE AGBAYI-REF3714878134 BO ALO",
                  "narration2": "000010220716174914111106358223/I5700012284",
                  "remarks": "NIP/NIP/ALOZIE AGBAYI",
                  "balance": "9749.23"
              },
              {
                  "sn": "305",
                  "transactionId": "S61427456",
                  "transactionDate": "7/18/2022 12:00:00 AM",
                  "valueDate": "7/17/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "Trf - ATM",
                  "narration2": "000020220717060610004005359626/O0130187278",
                  "remarks": "MOBILE/UMEADI NKEM PATRICIA",
                  "balance": "4749.23"
              },
              {
                  "sn": "306",
                  "transactionId": "S61427456",
                  "transactionDate": "7/18/2022 12:00:00 AM",
                  "valueDate": "7/17/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - ATM",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/UMEADI NKEM PATRICIA",
                  "balance": "4738.48"
              },
              {
                  "sn": "307",
                  "transactionId": "S61785281",
                  "transactionDate": "7/18/2022 12:00:00 AM",
                  "valueDate": "7/18/2022 12:00:00 AM",
                  "debitAmount": "4600",
                  "creditAmount": "0",
                  "narration": "Trf - pos",
                  "narration2": "000020220718183134004005375993/O8000497719",
                  "remarks": "MOBILE/AL - BAQI VENTURES",
                  "balance": "138.48"
              },
              {
                  "sn": "308",
                  "transactionId": "S61785281",
                  "transactionDate": "7/18/2022 12:00:00 AM",
                  "valueDate": "7/18/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - pos",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/AL - BAQI VENTURES",
                  "balance": "127.73"
              },
              {
                  "sn": "309",
                  "transactionId": "S61800061",
                  "transactionDate": "7/18/2022 12:00:00 AM",
                  "valueDate": "7/18/2022 12:00:00 AM",
                  "debitAmount": "100",
                  "creditAmount": "0",
                  "narration": "Mob Recharge-Online: Recharge <Glo> <07051456716>",
                  "narration2": "BLEND-MOBILERECHARGE1-637937693793062088",
                  "remarks": "BLEND / BLD/2075067574",
                  "balance": "27.73"
              },
              {
                  "sn": "310",
                  "transactionId": "S62583237",
                  "transactionDate": "7/21/2022 12:00:00 AM",
                  "valueDate": "7/21/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "10000",
                  "narration": "100-Chukwuemeka Okeleke-Transfer from",
                  "narration2": "100026220721124232000024152647/I9728766760",
                  "remarks": "NIP/NIP/Chukwuemeka Okeleke",
                  "balance": "10027.73"
              },
              {
                  "sn": "311",
                  "transactionId": "S62583237",
                  "transactionDate": "7/21/2022 12:00:00 AM",
                  "valueDate": "7/21/2022 12:00:00 AM",
                  "debitAmount": "50",
                  "creditAmount": "0",
                  "narration": "STAMP DUTY CHARGE ON DEPOSIT: 5300017669",
                  "narration2": "FEE EXEMPT",
                  "remarks": "NIP/NIP/Chukwuemeka Okeleke",
                  "balance": "9977.73"
              },
              {
                  "sn": "312",
                  "transactionId": "S62600655",
                  "transactionDate": "7/21/2022 12:00:00 AM",
                  "valueDate": "7/21/2022 12:00:00 AM",
                  "debitAmount": "1000",
                  "creditAmount": "0",
                  "narration": "Trf - Ralph",
                  "narration2": "000020220721153819004005410005/O3037984923",
                  "remarks": "MOBILE/FATELE ABIDEMI ROSEMARY",
                  "balance": "8977.73"
              },
              {
                  "sn": "313",
                  "transactionId": "S62600655",
                  "transactionDate": "7/21/2022 12:00:00 AM",
                  "valueDate": "7/21/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Ralph",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/FATELE ABIDEMI ROSEMARY",
                  "balance": "8966.98"
              },
              {
                  "sn": "314",
                  "transactionId": "S62647562",
                  "transactionDate": "7/21/2022 12:00:00 AM",
                  "valueDate": "7/21/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "5000",
                  "narration": "Mob Trf Vince",
                  "narration2": "BLEND-LOCAL TRANSFER:-3fbf739e-57a4-42d4-92ff-1775",
                  "remarks": "1913396531 / 5300017669/158466",
                  "balance": "13966.98"
              },
              {
                  "sn": "315",
                  "transactionId": "S62729909",
                  "transactionDate": "7/21/2022 12:00:00 AM",
                  "valueDate": "7/21/2022 12:00:00 AM",
                  "debitAmount": "5100",
                  "creditAmount": "0",
                  "narration": "Trf - pos",
                  "narration2": "000020220721211756004005414079/O0173800242",
                  "remarks": "MOBILE/AREGBESHOLA GANIYAT OLU",
                  "balance": "8866.98"
              },
              {
                  "sn": "316",
                  "transactionId": "S62729909",
                  "transactionDate": "7/21/2022 12:00:00 AM",
                  "valueDate": "7/21/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - pos",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/AREGBESHOLA GANIYAT OLU",
                  "balance": "8840.1"
              },
              {
                  "sn": "317",
                  "transactionId": "S62850174",
                  "transactionDate": "7/22/2022 12:00:00 AM",
                  "valueDate": "7/22/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "137277.12",
                  "narration": "JULY 2022 SALARY",
                  "narration2": "",
                  "remarks": "",
                  "balance": "146117.22"
              },
              {
                  "sn": "318",
                  "transactionId": "S62864836",
                  "transactionDate": "7/22/2022 12:00:00 AM",
                  "valueDate": "7/22/2022 12:00:00 AM",
                  "debitAmount": "1000",
                  "creditAmount": "0",
                  "narration": "JULY 2022 ASSBIFI  DUES",
                  "narration2": "",
                  "remarks": "",
                  "balance": "145117.22"
              },
              {
                  "sn": "319",
                  "transactionId": "S62865929",
                  "transactionDate": "7/22/2022 12:00:00 AM",
                  "valueDate": "7/22/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "FINAL PYMT 2022 ASSBIFI DEV LE",
                  "narration2": "",
                  "remarks": "",
                  "balance": "140117.22"
              },
              {
                  "sn": "320",
                  "transactionId": "S62960588",
                  "transactionDate": "7/22/2022 12:00:00 AM",
                  "valueDate": "7/22/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "5000",
                  "narration": "000-ALIGBE GODSPOWER OMOAGHE-MOB/OKELE",
                  "narration2": "000004220722185623306728956292/I2055234762",
                  "remarks": "NIP/NIP/ALIGBE GODSPOWER OMOAG",
                  "balance": "145117.22"
              },
              {
                  "sn": "321",
                  "transactionId": "S63020944",
                  "transactionDate": "7/22/2022 12:00:00 AM",
                  "valueDate": "7/22/2022 12:00:00 AM",
                  "debitAmount": "9100",
                  "creditAmount": "0",
                  "narration": "Trf - pos",
                  "narration2": "000020220722195618004005425970/O8000497719",
                  "remarks": "MOBILE/AL - BAQI VENTURES",
                  "balance": "136017.22"
              },
              {
                  "sn": "322",
                  "transactionId": "S63020944",
                  "transactionDate": "7/22/2022 12:00:00 AM",
                  "valueDate": "7/22/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - pos",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/AL - BAQI VENTURES",
                  "balance": "135990.34"
              },
              {
                  "sn": "323",
                  "transactionId": "S63024254",
                  "transactionDate": "7/22/2022 12:00:00 AM",
                  "valueDate": "7/22/2022 12:00:00 AM",
                  "debitAmount": "10000",
                  "creditAmount": "0",
                  "narration": "Mob Trf invinceable",
                  "narration2": "BLEND-LOCAL TRANSFER:-6fe97e20-d6e4-4542-85d5-74e5",
                  "remarks": "5300017669 / 1001597495/168098",
                  "balance": "125990.34"
              },
              {
                  "sn": "324",
                  "transactionId": "S63037376",
                  "transactionDate": "7/22/2022 12:00:00 AM",
                  "valueDate": "7/22/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "Trf - Vince",
                  "narration2": "000020220722201432004005426265/O5700012284",
                  "remarks": "MOBILE/AGBAYI ALOZIE KELVIN",
                  "balance": "120990.34"
              },
              {
                  "sn": "325",
                  "transactionId": "S63037376",
                  "transactionDate": "7/22/2022 12:00:00 AM",
                  "valueDate": "7/22/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Vince",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/AGBAYI ALOZIE KELVIN",
                  "balance": "120979.59"
              },
              {
                  "sn": "326",
                  "transactionId": "S63069608",
                  "transactionDate": "7/22/2022 12:00:00 AM",
                  "valueDate": "7/22/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "Mob Trf invinceable",
                  "narration2": "BLEND-LOCAL TRANSFER:-846230a2-581e-40f7-a5d5-c1bd",
                  "remarks": "5300017669 / 1300012219/205379",
                  "balance": "115979.59"
              },
              {
                  "sn": "327",
                  "transactionId": "S63129567",
                  "transactionDate": "7/22/2022 12:00:00 AM",
                  "valueDate": "7/22/2022 12:00:00 AM",
                  "debitAmount": "30000",
                  "creditAmount": "0",
                  "narration": "Trf - Invinceable",
                  "narration2": "000020220722215052004005427310/O1000008740",
                  "remarks": "MOBILE/LANRE S SONUBI",
                  "balance": "85979.59"
              },
              {
                  "sn": "328",
                  "transactionId": "S63129567",
                  "transactionDate": "7/22/2022 12:00:00 AM",
                  "valueDate": "7/22/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Invinceable",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/LANRE S SONUBI",
                  "balance": "85952.71"
              },
              {
                  "sn": "329",
                  "transactionId": "S63132154",
                  "transactionDate": "7/22/2022 12:00:00 AM",
                  "valueDate": "7/22/2022 12:00:00 AM",
                  "debitAmount": "10000",
                  "creditAmount": "0",
                  "narration": "Trf - invinceable",
                  "narration2": "000020220722215400004005427345/O0028116908",
                  "remarks": "MOBILE/CHUKWUEMEKA  OKELEKE",
                  "balance": "75952.71"
              },
              {
                  "sn": "330",
                  "transactionId": "S63132154",
                  "transactionDate": "7/22/2022 12:00:00 AM",
                  "valueDate": "7/22/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - invinceable",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/CHUKWUEMEKA  OKELEKE",
                  "balance": "75925.83"
              },
              {
                  "sn": "331",
                  "transactionId": "S63204121",
                  "transactionDate": "7/22/2022 12:00:00 AM",
                  "valueDate": "7/23/2022 12:00:00 AM",
                  "debitAmount": "10100",
                  "creditAmount": "0",
                  "narration": "Trf - bills",
                  "narration2": "000020220723004209004005428235/O0817554699",
                  "remarks": "MOBILE/LIFESTYLE DRINKS AND BE",
                  "balance": "65825.83"
              },
              {
                  "sn": "332",
                  "transactionId": "S63204121",
                  "transactionDate": "7/22/2022 12:00:00 AM",
                  "valueDate": "7/23/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - bills",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/LIFESTYLE DRINKS AND BE",
                  "balance": "65798.95"
              },
              {
                  "sn": "333",
                  "transactionId": "    M2396",
                  "transactionDate": "7/25/2022 12:00:00 AM",
                  "valueDate": "7/25/2022 12:00:00 AM",
                  "debitAmount": "1500",
                  "creditAmount": "0",
                  "narration": "JULY 2022 WELFARE CONTRIBUTION",
                  "narration2": "",
                  "remarks": "",
                  "balance": "64298.95"
              },
              {
                  "sn": "334",
                  "transactionId": "S63210516",
                  "transactionDate": "7/25/2022 12:00:00 AM",
                  "valueDate": "7/23/2022 12:00:00 AM",
                  "debitAmount": "15000",
                  "creditAmount": "0",
                  "narration": "Trf - Vince",
                  "narration2": "000020220723060459004005428806/O1481401836",
                  "remarks": "MOBILE/CHIMAOBI  GODWIN",
                  "balance": "49298.95"
              },
              {
                  "sn": "335",
                  "transactionId": "S63210516",
                  "transactionDate": "7/25/2022 12:00:00 AM",
                  "valueDate": "7/23/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Vince",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/CHIMAOBI  GODWIN",
                  "balance": "49272.07"
              },
              {
                  "sn": "336",
                  "transactionId": "S63212640",
                  "transactionDate": "7/25/2022 12:00:00 AM",
                  "valueDate": "7/23/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "7000",
                  "narration": "Mob Trf self",
                  "narration2": "BLEND-LOCAL TRANSFER:-57d12738-afae-41f2-b036-01e9",
                  "remarks": "1300012264 / 5300017669/145082",
                  "balance": "56272.07"
              },
              {
                  "sn": "337",
                  "transactionId": "S63212699",
                  "transactionDate": "7/25/2022 12:00:00 AM",
                  "valueDate": "7/23/2022 12:00:00 AM",
                  "debitAmount": "50000",
                  "creditAmount": "0",
                  "narration": "Trf - Invinceable",
                  "narration2": "000020220723072047004005429593/O2005518283",
                  "remarks": "MOBILE/OYEBOLA RAJI",
                  "balance": "6272.07"
              },
              {
                  "sn": "338",
                  "transactionId": "S63212699",
                  "transactionDate": "7/25/2022 12:00:00 AM",
                  "valueDate": "7/23/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Invinceable",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/OYEBOLA RAJI",
                  "balance": "6245.19"
              },
              {
                  "sn": "339",
                  "transactionId": "S63241409",
                  "transactionDate": "7/25/2022 12:00:00 AM",
                  "valueDate": "7/23/2022 12:00:00 AM",
                  "debitAmount": "3500",
                  "creditAmount": "0",
                  "narration": "Mob Recharge-Online: Recharge <MTN> <07033671485>",
                  "narration2": "BLEND-MOBILERECHARGE1-637941739402578939",
                  "remarks": "BLEND / BLD/1183321333",
                  "balance": "2745.19"
              },
              {
                  "sn": "340",
                  "transactionId": "S63266959",
                  "transactionDate": "7/25/2022 12:00:00 AM",
                  "valueDate": "7/23/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "30000",
                  "narration": "Mob Trf self",
                  "narration2": "BLEND-LOCAL TRANSFER:-c212b975-44a4-46dd-b24a-0511",
                  "remarks": "1300012264 / 5300017669/453776",
                  "balance": "32745.19"
              },
              {
                  "sn": "341",
                  "transactionId": "S63267076",
                  "transactionDate": "7/25/2022 12:00:00 AM",
                  "valueDate": "7/23/2022 12:00:00 AM",
                  "debitAmount": "20000",
                  "creditAmount": "0",
                  "narration": "Trf - Invinceable",
                  "narration2": "000020220723174229004005439246/O0094818753",
                  "remarks": "MOBILE/KEHINDE GLORIA OSERU",
                  "balance": "12745.19"
              },
              {
                  "sn": "342",
                  "transactionId": "S63267076",
                  "transactionDate": "7/25/2022 12:00:00 AM",
                  "valueDate": "7/23/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Invinceable",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/KEHINDE GLORIA OSERU",
                  "balance": "12718.31"
              },
              {
                  "sn": "343",
                  "transactionId": "S63336497",
                  "transactionDate": "7/25/2022 12:00:00 AM",
                  "valueDate": "7/24/2022 12:00:00 AM",
                  "debitAmount": "6000",
                  "creditAmount": "0",
                  "narration": "Trf -",
                  "narration2": "000020220724213739004005451714/O7038665506",
                  "remarks": "MOBILE/Vivian  Nlemuzor",
                  "balance": "6718.31"
              },
              {
                  "sn": "344",
                  "transactionId": "S63336497",
                  "transactionDate": "7/25/2022 12:00:00 AM",
                  "valueDate": "7/24/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee -",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/Vivian  Nlemuzor",
                  "balance": "6691.43"
              },
              {
                  "sn": "345",
                  "transactionId": "S63491236",
                  "transactionDate": "7/25/2022 12:00:00 AM",
                  "valueDate": "7/25/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "Trf - Vince",
                  "narration2": "000020220725151333004005459881/O0169862700",
                  "remarks": "MOBILE/AKINMOLADUN  PETERS ADE",
                  "balance": "1691.43"
              },
              {
                  "sn": "346",
                  "transactionId": "S63491236",
                  "transactionDate": "7/25/2022 12:00:00 AM",
                  "valueDate": "7/25/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Vince",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/AKINMOLADUN  PETERS ADE",
                  "balance": "1680.68"
              },
              {
                  "sn": "347",
                  "transactionId": "S63650403",
                  "transactionDate": "7/25/2022 12:00:00 AM",
                  "valueDate": "7/25/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "15000",
                  "narration": "Mob Trf self",
                  "narration2": "BLEND-LOCAL TRANSFER:-cbe26fe4-4713-4233-9352-0e4e",
                  "remarks": "1300012264 / 5300017669/191632",
                  "balance": "16680.68"
              },
              {
                  "sn": "348",
                  "transactionId": "S63650523",
                  "transactionDate": "7/25/2022 12:00:00 AM",
                  "valueDate": "7/25/2022 12:00:00 AM",
                  "debitAmount": "7100",
                  "creditAmount": "0",
                  "narration": "Trf - opay",
                  "narration2": "000020220725204811004005464655/O7038665506",
                  "remarks": "MOBILE/Vivian  Nlemuzor",
                  "balance": "9580.68"
              },
              {
                  "sn": "349",
                  "transactionId": "S63650523",
                  "transactionDate": "7/25/2022 12:00:00 AM",
                  "valueDate": "7/25/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - opay",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/Vivian  Nlemuzor",
                  "balance": "9553.8"
              },
              {
                  "sn": "350",
                  "transactionId": "S63838296",
                  "transactionDate": "7/26/2022 12:00:00 AM",
                  "valueDate": "7/26/2022 12:00:00 AM",
                  "debitAmount": "2000",
                  "creditAmount": "0",
                  "narration": "Trf - Olembe",
                  "narration2": "000020220726181920004005476264/O4020271610",
                  "remarks": "MOBILE/DIKE VICTOR OSOTULE",
                  "balance": "7553.8"
              },
              {
                  "sn": "351",
                  "transactionId": "S63838296",
                  "transactionDate": "7/26/2022 12:00:00 AM",
                  "valueDate": "7/26/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Olembe",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/DIKE VICTOR OSOTULE",
                  "balance": "7543.05"
              },
              {
                  "sn": "352",
                  "transactionId": "S63889438",
                  "transactionDate": "7/27/2022 12:00:00 AM",
                  "valueDate": "7/27/2022 12:00:00 AM",
                  "debitAmount": "2000",
                  "creditAmount": "0",
                  "narration": "Mob Pymt obile: Payment For <Eko Electricity Dist",
                  "narration2": "BLEND-NTBANKI00000001-637945099345501879",
                  "remarks": "5300017669 / BILLER's NAME/210",
                  "balance": "5543.05"
              },
              {
                  "sn": "353",
                  "transactionId": "S63889438",
                  "transactionDate": "7/27/2022 12:00:00 AM",
                  "valueDate": "7/27/2022 12:00:00 AM",
                  "debitAmount": "100",
                  "creditAmount": "0",
                  "narration": "Mob Pymt Fee obile: Payment For <Eko Electricity D",
                  "narration2": "FEE EXEMPT",
                  "remarks": "5300017669 / BILLER's NAME/210",
                  "balance": "5443.05"
              },
              {
                  "sn": "354",
                  "transactionId": "S64185288",
                  "transactionDate": "7/27/2022 12:00:00 AM",
                  "valueDate": "7/27/2022 12:00:00 AM",
                  "debitAmount": "100",
                  "creditAmount": "0",
                  "narration": "Mob Recharge-Online: Recharge <Glo> <07051456716>",
                  "narration2": "BLEND-MOBILERECHARGE1-637945310207635961",
                  "remarks": "BLEND / BLD/1268916868",
                  "balance": "5343.05"
              },
              {
                  "sn": "355",
                  "transactionId": "S64558760",
                  "transactionDate": "7/29/2022 12:00:00 AM",
                  "valueDate": "7/29/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "Mob Trf ATM",
                  "narration2": "BLEND-LOCAL TRANSFER:-cd17489f-c342-406b-afe8-d697",
                  "remarks": "5300017669 / 1001597495/171660",
                  "balance": "343.05"
              },
              {
                  "sn": "356",
                  "transactionId": "S65057749",
                  "transactionDate": "8/1/2022 12:00:00 AM",
                  "valueDate": "7/30/2022 12:00:00 AM",
                  "debitAmount": "343",
                  "creditAmount": "0",
                  "narration": "Mob Trf self",
                  "narration2": "BLEND-LOCAL TRANSFER:-02fdd113-2247-4ddb-bba7-46fd",
                  "remarks": "5300017669 / 1300012264/135814",
                  "balance": "0.05"
              },
              {
                  "sn": "357",
                  "transactionId": "S65111365",
                  "transactionDate": "8/1/2022 12:00:00 AM",
                  "valueDate": "7/30/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "10000",
                  "narration": "000-CHIDI ANTHONY AGBO-Transfer from C",
                  "narration2": "000001220730170208000183036510/I0031640537",
                  "remarks": "NIP/NIP/CHIDI ANTHONY AGBO",
                  "balance": "10000.05"
              },
              {
                  "sn": "358",
                  "transactionId": "S65111365",
                  "transactionDate": "8/1/2022 12:00:00 AM",
                  "valueDate": "7/30/2022 12:00:00 AM",
                  "debitAmount": "50",
                  "creditAmount": "0",
                  "narration": "STAMP DUTY CHARGE ON DEPOSIT: 5300017669",
                  "narration2": "FEE EXEMPT",
                  "remarks": "NIP/NIP/CHIDI ANTHONY AGBO",
                  "balance": "9950.05"
              },
              {
                  "sn": "359",
                  "transactionId": "S65116580",
                  "transactionDate": "8/1/2022 12:00:00 AM",
                  "valueDate": "7/30/2022 12:00:00 AM",
                  "debitAmount": "8100",
                  "creditAmount": "0",
                  "narration": "Trf - pos",
                  "narration2": "000020220730180656004005525391/O8000497719",
                  "remarks": "MOBILE/AL - BAQI VENTURES",
                  "balance": "1850.05"
              },
              {
                  "sn": "360",
                  "transactionId": "S65116580",
                  "transactionDate": "8/1/2022 12:00:00 AM",
                  "valueDate": "7/30/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - pos",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/AL - BAQI VENTURES",
                  "balance": "1823.17"
              },
              {
                  "sn": "361",
                  "transactionId": "S65189866",
                  "transactionDate": "8/1/2022 12:00:00 AM",
                  "valueDate": "7/31/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "7000",
                  "narration": "NXG :TRFFROM ALLSTARSFRM IKENNA  EZIRIM",
                  "narration2": "",
                  "remarks": "/633522/369234888",
                  "balance": "8823.17"
              },
              {
                  "sn": "362",
                  "transactionId": "S65340013",
                  "transactionDate": "8/1/2022 12:00:00 AM",
                  "valueDate": "8/1/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "1400",
                  "narration": "Mob Trf self",
                  "narration2": "BLEND-LOCAL TRANSFER:-29092071-de1e-465a-b397-6007",
                  "remarks": "1300012264 / 5300017669/161855",
                  "balance": "10223.17"
              },
              {
                  "sn": "363",
                  "transactionId": "S65341453",
                  "transactionDate": "8/1/2022 12:00:00 AM",
                  "valueDate": "8/1/2022 12:00:00 AM",
                  "debitAmount": "100",
                  "creditAmount": "0",
                  "narration": "Mob Recharge-Online: Recharge <Glo> <07033671485>",
                  "narration2": "BLEND-MOBILERECHARGE1-637949544686764110",
                  "remarks": "BLEND / BLD/1552504613",
                  "balance": "10123.17"
              },
              {
                  "sn": "364",
                  "transactionId": "S65341508",
                  "transactionDate": "8/1/2022 12:00:00 AM",
                  "valueDate": "8/1/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "100",
                  "narration": "Rev Mob Pymt ev Blend Pymt-   20060217290754000000",
                  "narration2": "BLEND-MOBILERECHARGE1-2006021729075400000003370968",
                  "remarks": "5300017669 / BILLER's NAME/155",
                  "balance": "10223.17"
              },
              {
                  "sn": "365",
                  "transactionId": "S65343272",
                  "transactionDate": "8/1/2022 12:00:00 AM",
                  "valueDate": "8/1/2022 12:00:00 AM",
                  "debitAmount": "100",
                  "creditAmount": "0",
                  "narration": "Mob Recharge-Online: Recharge <Glo> <07051456716>",
                  "narration2": "BLEND-MOBILERECHARGE1-637949545589063583",
                  "remarks": "BLEND / BLD/163097733",
                  "balance": "10123.17"
              },
              {
                  "sn": "366",
                  "transactionId": "S65592326",
                  "transactionDate": "8/1/2022 12:00:00 AM",
                  "valueDate": "8/1/2022 12:00:00 AM",
                  "debitAmount": "1400",
                  "creditAmount": "0",
                  "narration": "Trf - Lampard",
                  "narration2": "000020220801223530004005551098/O5702041787",
                  "remarks": "MOBILE/ADEYERI NIKE",
                  "balance": "8723.17"
              },
              {
                  "sn": "367",
                  "transactionId": "S65592326",
                  "transactionDate": "8/1/2022 12:00:00 AM",
                  "valueDate": "8/1/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Lampard",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/ADEYERI NIKE",
                  "balance": "8712.42"
              },
              {
                  "sn": "368",
                  "transactionId": "S65689040",
                  "transactionDate": "8/2/2022 12:00:00 AM",
                  "valueDate": "8/2/2022 12:00:00 AM",
                  "debitAmount": "7000",
                  "creditAmount": "0",
                  "narration": "Mob Trf ATM",
                  "narration2": "BLEND-LOCAL TRANSFER:-0a411cf6-8315-446a-8c4d-73a6",
                  "remarks": "5300017669 / 1001597495/209477",
                  "balance": "1712.42"
              },
              {
                  "sn": "369",
                  "transactionId": "S65689266",
                  "transactionDate": "8/2/2022 12:00:00 AM",
                  "valueDate": "8/2/2022 12:00:00 AM",
                  "debitAmount": "500",
                  "creditAmount": "0",
                  "narration": "Mob Recharge-Online: Recharge <Glo> <07051456716>",
                  "narration2": "BLEND-MOBILERECHARGE1-637950499267479224",
                  "remarks": "BLEND / BLD/38249897",
                  "balance": "1212.42"
              },
              {
                  "sn": "370",
                  "transactionId": "S66505852",
                  "transactionDate": "8/5/2022 12:00:00 AM",
                  "valueDate": "8/5/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "2000",
                  "narration": "rev 270722 637945099345501879",
                  "narration2": "",
                  "remarks": "",
                  "balance": "3212.42"
              },
              {
                  "sn": "371",
                  "transactionId": "S66505852",
                  "transactionDate": "8/5/2022 12:00:00 AM",
                  "valueDate": "8/5/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "100",
                  "narration": "rev 270722 637945099345501879",
                  "narration2": "",
                  "remarks": "",
                  "balance": "3312.42"
              },
              {
                  "sn": "372",
                  "transactionId": "S67386356",
                  "transactionDate": "8/8/2022 12:00:00 AM",
                  "valueDate": "8/7/2022 12:00:00 AM",
                  "debitAmount": "3100",
                  "creditAmount": "0",
                  "narration": "Trf - pos",
                  "narration2": "000020220807120253004005631753/O2124672732",
                  "remarks": "MOBILE/Badmus Saheed Abiola",
                  "balance": "212.42"
              },
              {
                  "sn": "373",
                  "transactionId": "S67386356",
                  "transactionDate": "8/8/2022 12:00:00 AM",
                  "valueDate": "8/7/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - pos",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/Badmus Saheed Abiola",
                  "balance": "201.67"
              },
              {
                  "sn": "374",
                  "transactionId": "S67446342",
                  "transactionDate": "8/8/2022 12:00:00 AM",
                  "valueDate": "8/7/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "5000",
                  "narration": "000-ABUBAKAR ABDUL AIREDE-OKELEKE VINC",
                  "narration2": "000012220807224728163055377000/I0022947896",
                  "remarks": "NIP/NIP/ABUBAKAR ABDUL AIREDE",
                  "balance": "5201.67"
              },
              {
                  "sn": "375",
                  "transactionId": "S67446412",
                  "transactionDate": "8/8/2022 12:00:00 AM",
                  "valueDate": "8/7/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "3000",
                  "narration": "000-ABUBAKAR ABDUL AIREDE-OKELEKE VINC",
                  "narration2": "000012220807225116163055580000/I0022947896",
                  "remarks": "NIP/NIP/ABUBAKAR ABDUL AIREDE",
                  "balance": "8201.67"
              },
              {
                  "sn": "376",
                  "transactionId": "S67641630",
                  "transactionDate": "8/8/2022 12:00:00 AM",
                  "valueDate": "8/8/2022 12:00:00 AM",
                  "debitAmount": "500",
                  "creditAmount": "0",
                  "narration": "Mob Recharge-Online: Recharge <Glo> <07051456716>",
                  "narration2": "BLEND-MOBILERECHARGE1-637955675195295187",
                  "remarks": "BLEND / BLD/764165296",
                  "balance": "7701.67"
              },
              {
                  "sn": "377",
                  "transactionId": "S67641733",
                  "transactionDate": "8/8/2022 12:00:00 AM",
                  "valueDate": "8/8/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "500",
                  "narration": "Rev Mob Pymt ev Blend Pymt-   20060217290754000000",
                  "narration2": "BLEND-MOBILERECHARGE1-2006021729075400000003370968",
                  "remarks": "5300017669 / BILLER's NAME/764",
                  "balance": "8201.67"
              },
              {
                  "sn": "378",
                  "transactionId": "S67642520",
                  "transactionDate": "8/8/2022 12:00:00 AM",
                  "valueDate": "8/8/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "Mob Trf ATM",
                  "narration2": "BLEND-LOCAL TRANSFER:-c9c8c912-d746-4578-b4a0-917a",
                  "remarks": "5300017669 / 1001597495/137232",
                  "balance": "3201.67"
              },
              {
                  "sn": "379",
                  "transactionId": "S67644301",
                  "transactionDate": "8/8/2022 12:00:00 AM",
                  "valueDate": "8/8/2022 12:00:00 AM",
                  "debitAmount": "500",
                  "creditAmount": "0",
                  "narration": "Mob Recharge-Online: Recharge <Glo> <07051456716>",
                  "narration2": "BLEND-MOBILERECHARGE1-637955678527939376",
                  "remarks": "BLEND / BLD/669580010",
                  "balance": "2701.67"
              },
              {
                  "sn": "380",
                  "transactionId": "S67644406",
                  "transactionDate": "8/8/2022 12:00:00 AM",
                  "valueDate": "8/8/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "500",
                  "narration": "Rev Mob Pymt ev Blend Pymt-   20060217290754000000",
                  "narration2": "BLEND-MOBILERECHARGE1-2006021729075400000003370968",
                  "remarks": "5300017669 / BILLER's NAME/669",
                  "balance": "3201.67"
              },
              {
                  "sn": "381",
                  "transactionId": "S67949050",
                  "transactionDate": "8/9/2022 12:00:00 AM",
                  "valueDate": "8/9/2022 12:00:00 AM",
                  "debitAmount": "500",
                  "creditAmount": "0",
                  "narration": "Mob Recharge-Online: Recharge <Glo> <07051456716>",
                  "narration2": "BLEND-MOBILERECHARGE1-637956237247116653",
                  "remarks": "BLEND / BLD/1343791947",
                  "balance": "2701.67"
              },
              {
                  "sn": "382",
                  "transactionId": "S68023636",
                  "transactionDate": "8/9/2022 12:00:00 AM",
                  "valueDate": "8/9/2022 12:00:00 AM",
                  "debitAmount": "200",
                  "creditAmount": "0",
                  "narration": "BPW GLO Airtime Purchase07051456716-91309",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/196045267655",
                  "balance": "2501.67"
              },
              {
                  "sn": "383",
                  "transactionId": "S68195453",
                  "transactionDate": "8/10/2022 12:00:00 AM",
                  "valueDate": "8/10/2022 12:00:00 AM",
                  "debitAmount": "2000",
                  "creditAmount": "0",
                  "narration": "Trf - Awele",
                  "narration2": "000020220810121907004005669040/O0806450694",
                  "remarks": "MOBILE/HALID ALI ISMAGEL",
                  "balance": "501.67"
              },
              {
                  "sn": "384",
                  "transactionId": "S68195453",
                  "transactionDate": "8/10/2022 12:00:00 AM",
                  "valueDate": "8/10/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Awele",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/HALID ALI ISMAGEL",
                  "balance": "490.92"
              },
              {
                  "sn": "385",
                  "transactionId": "S68308347",
                  "transactionDate": "8/10/2022 12:00:00 AM",
                  "valueDate": "8/10/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "95393.72",
                  "narration": "2022 LEAVE ALLOWANCE",
                  "narration2": "",
                  "remarks": "",
                  "balance": "95884.64"
              },
              {
                  "sn": "386",
                  "transactionId": "S68677847",
                  "transactionDate": "8/10/2022 12:00:00 AM",
                  "valueDate": "8/10/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "10000",
                  "narration": "000-SONUBI LANRE  SAMUEL-via GTWORLD F",
                  "narration2": "000013220810194903000389599362/I0009552967",
                  "remarks": "NIP/NIP/SONUBI LANRE  SAMUEL",
                  "balance": "105884.64"
              },
              {
                  "sn": "387",
                  "transactionId": "S68677847",
                  "transactionDate": "8/10/2022 12:00:00 AM",
                  "valueDate": "8/10/2022 12:00:00 AM",
                  "debitAmount": "50",
                  "creditAmount": "0",
                  "narration": "STAMP DUTY CHARGE ON DEPOSIT: 5300017669",
                  "narration2": "FEE EXEMPT",
                  "remarks": "NIP/NIP/SONUBI LANRE  SAMUEL",
                  "balance": "105834.64"
              },
              {
                  "sn": "388",
                  "transactionId": "S68683658",
                  "transactionDate": "8/10/2022 12:00:00 AM",
                  "valueDate": "8/10/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "10000",
                  "narration": "100-EMS SKYNET  KREA8TIVE  CONCEPTS-Tr",
                  "narration2": "100004220810190823101553967092/I08100993750",
                  "remarks": "NIP/NIP/EMS SKYNET  KREA8TIVE",
                  "balance": "115834.64"
              },
              {
                  "sn": "389",
                  "transactionId": "S68683658",
                  "transactionDate": "8/10/2022 12:00:00 AM",
                  "valueDate": "8/10/2022 12:00:00 AM",
                  "debitAmount": "50",
                  "creditAmount": "0",
                  "narration": "STAMP DUTY CHARGE ON DEPOSIT: 5300017669",
                  "narration2": "FEE EXEMPT",
                  "remarks": "NIP/NIP/EMS SKYNET  KREA8TIVE",
                  "balance": "115784.64"
              },
              {
                  "sn": "390",
                  "transactionId": "S68688132",
                  "transactionDate": "8/10/2022 12:00:00 AM",
                  "valueDate": "8/10/2022 12:00:00 AM",
                  "debitAmount": "7100",
                  "creditAmount": "0",
                  "narration": "Trf -",
                  "narration2": "000020220810202515004005675279/O7038665506",
                  "remarks": "MOBILE/Vivian  Nlemuzor",
                  "balance": "108684.64"
              },
              {
                  "sn": "391",
                  "transactionId": "S68688132",
                  "transactionDate": "8/10/2022 12:00:00 AM",
                  "valueDate": "8/10/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee -",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/Vivian  Nlemuzor",
                  "balance": "108657.76"
              },
              {
                  "sn": "392",
                  "transactionId": "S68835462",
                  "transactionDate": "8/11/2022 12:00:00 AM",
                  "valueDate": "8/11/2022 12:00:00 AM",
                  "debitAmount": "10000",
                  "creditAmount": "0",
                  "narration": "Trf - Vince",
                  "narration2": "000020220811073050004005677470/O0031640537",
                  "remarks": "MOBILE/CHIDI ANTHONY AGBO",
                  "balance": "98657.76"
              },
              {
                  "sn": "393",
                  "transactionId": "S68835462",
                  "transactionDate": "8/11/2022 12:00:00 AM",
                  "valueDate": "8/11/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Vince",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/CHIDI ANTHONY AGBO",
                  "balance": "98630.88"
              },
              {
                  "sn": "394",
                  "transactionId": "S68939176",
                  "transactionDate": "8/11/2022 12:00:00 AM",
                  "valueDate": "8/11/2022 12:00:00 AM",
                  "debitAmount": "2000",
                  "creditAmount": "0",
                  "narration": "Mob Trf Vince",
                  "narration2": "BLEND-LOCAL TRANSFER:-c3abb09d-c43f-47c6-82c9-f0be",
                  "remarks": "5300017669 / 5300010282/193240",
                  "balance": "96630.88"
              },
              {
                  "sn": "395",
                  "transactionId": "S68946811",
                  "transactionDate": "8/11/2022 12:00:00 AM",
                  "valueDate": "8/11/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "Trf - invinceable",
                  "narration2": "000020220811124153004005680674/O0022947896",
                  "remarks": "MOBILE/ABUBAKAR ABDUL AIREDE",
                  "balance": "91630.88"
              },
              {
                  "sn": "396",
                  "transactionId": "S68946811",
                  "transactionDate": "8/11/2022 12:00:00 AM",
                  "valueDate": "8/11/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - invinceable",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/ABUBAKAR ABDUL AIREDE",
                  "balance": "91620.13"
              },
              {
                  "sn": "397",
                  "transactionId": "S69152491",
                  "transactionDate": "8/11/2022 12:00:00 AM",
                  "valueDate": "8/11/2022 12:00:00 AM",
                  "debitAmount": "55000",
                  "creditAmount": "0",
                  "narration": "Trf - tab",
                  "narration2": "000020220811174112004005685160/O5600121503",
                  "remarks": "MOBILE/RAYZVEN GLOBAL RESOURCE",
                  "balance": "36620.13"
              },
              {
                  "sn": "398",
                  "transactionId": "S69152491",
                  "transactionDate": "8/11/2022 12:00:00 AM",
                  "valueDate": "8/11/2022 12:00:00 AM",
                  "debitAmount": "53.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - tab",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/RAYZVEN GLOBAL RESOURCE",
                  "balance": "36566.38"
              },
              {
                  "sn": "399",
                  "transactionId": "S69242295",
                  "transactionDate": "8/11/2022 12:00:00 AM",
                  "valueDate": "8/11/2022 12:00:00 AM",
                  "debitAmount": "9100",
                  "creditAmount": "0",
                  "narration": "Trf - pos",
                  "narration2": "000020220811195350004005686928/O8000497719",
                  "remarks": "MOBILE/AL - BAQI VENTURES",
                  "balance": "27466.38"
              },
              {
                  "sn": "400",
                  "transactionId": "S69242295",
                  "transactionDate": "8/11/2022 12:00:00 AM",
                  "valueDate": "8/11/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - pos",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/AL - BAQI VENTURES",
                  "balance": "27439.5"
              },
              {
                  "sn": "401",
                  "transactionId": "S69251509",
                  "transactionDate": "8/11/2022 12:00:00 AM",
                  "valueDate": "8/11/2022 12:00:00 AM",
                  "debitAmount": "7100",
                  "creditAmount": "0",
                  "narration": "Trf - pos",
                  "narration2": "000020220811201316004005687156/O2011197902",
                  "remarks": "MOBILE/OKUNADE, ABODUNRIN KUDI",
                  "balance": "20339.5"
              },
              {
                  "sn": "402",
                  "transactionId": "S69251509",
                  "transactionDate": "8/11/2022 12:00:00 AM",
                  "valueDate": "8/11/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - pos",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/OKUNADE, ABODUNRIN KUDI",
                  "balance": "20312.62"
              },
              {
                  "sn": "403",
                  "transactionId": "S69251632",
                  "transactionDate": "8/11/2022 12:00:00 AM",
                  "valueDate": "8/11/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "7100",
                  "narration": "Rev Trf - pos",
                  "narration2": "000020220811201316004005687156/O2011197902",
                  "remarks": "MOBILE/OKUNADE, ABODUNRIN KUDI",
                  "balance": "27412.62"
              },
              {
                  "sn": "404",
                  "transactionId": "S69251632",
                  "transactionDate": "8/11/2022 12:00:00 AM",
                  "valueDate": "8/11/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "26.88",
                  "narration": "Rev Trf Fee - pos",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/OKUNADE, ABODUNRIN KUDI",
                  "balance": "27439.5"
              },
              {
                  "sn": "405",
                  "transactionId": "S69251765",
                  "transactionDate": "8/11/2022 12:00:00 AM",
                  "valueDate": "8/11/2022 12:00:00 AM",
                  "debitAmount": "7100",
                  "creditAmount": "0",
                  "narration": "Trf - pos",
                  "narration2": "000020220811201350004005687162/O2011197902",
                  "remarks": "MOBILE/OKUNADE, ABODUNRIN KUDI",
                  "balance": "20339.5"
              },
              {
                  "sn": "406",
                  "transactionId": "S69251765",
                  "transactionDate": "8/11/2022 12:00:00 AM",
                  "valueDate": "8/11/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - pos",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/OKUNADE, ABODUNRIN KUDI",
                  "balance": "20312.62"
              },
              {
                  "sn": "407",
                  "transactionId": "S69470773",
                  "transactionDate": "8/12/2022 12:00:00 AM",
                  "valueDate": "8/12/2022 12:00:00 AM",
                  "debitAmount": "2000",
                  "creditAmount": "0",
                  "narration": "Trf - Olembe",
                  "narration2": "000020220812104301004005691949/O0026600059",
                  "remarks": "MOBILE/NKWOPARA CHIDINMA",
                  "balance": "18312.62"
              },
              {
                  "sn": "408",
                  "transactionId": "S69470773",
                  "transactionDate": "8/12/2022 12:00:00 AM",
                  "valueDate": "8/12/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Olembe",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/NKWOPARA CHIDINMA",
                  "balance": "18301.87"
              },
              {
                  "sn": "409",
                  "transactionId": "S69484240",
                  "transactionDate": "8/12/2022 12:00:00 AM",
                  "valueDate": "8/12/2022 12:00:00 AM",
                  "debitAmount": "10000",
                  "creditAmount": "0",
                  "narration": "Trf - refund",
                  "narration2": "000020220812113041004005692665/O0130187278",
                  "remarks": "MOBILE/UMEADI NKEM PATRICIA",
                  "balance": "8301.87"
              },
              {
                  "sn": "410",
                  "transactionId": "S69484240",
                  "transactionDate": "8/12/2022 12:00:00 AM",
                  "valueDate": "8/12/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - refund",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/UMEADI NKEM PATRICIA",
                  "balance": "8274.99"
              },
              {
                  "sn": "411",
                  "transactionId": "S69484741",
                  "transactionDate": "8/12/2022 12:00:00 AM",
                  "valueDate": "8/12/2022 12:00:00 AM",
                  "debitAmount": "8100",
                  "creditAmount": "0",
                  "narration": "Trf - pos",
                  "narration2": "000020220812113245004005692696/O8000497719",
                  "remarks": "MOBILE/AL - BAQI VENTURES",
                  "balance": "174.99"
              },
              {
                  "sn": "412",
                  "transactionId": "S69484741",
                  "transactionDate": "8/12/2022 12:00:00 AM",
                  "valueDate": "8/12/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - pos",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/AL - BAQI VENTURES",
                  "balance": "148.11"
              },
              {
                  "sn": "413",
                  "transactionId": "S69523177",
                  "transactionDate": "8/12/2022 12:00:00 AM",
                  "valueDate": "8/12/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "9100",
                  "narration": "RF# REV Trf - pos",
                  "narration2": "000020220811195350004005686928/O8000497719",
                  "remarks": "MOBILE/AL - BAQI VENTURES",
                  "balance": "9248.11"
              },
              {
                  "sn": "414",
                  "transactionId": "S69523242",
                  "transactionDate": "8/12/2022 12:00:00 AM",
                  "valueDate": "8/12/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "7100",
                  "narration": "RF# REV Trf - pos",
                  "narration2": "000020220811201350004005687162/O2011197902",
                  "remarks": "MOBILE/OKUNADE, ABODUNRIN KUDI",
                  "balance": "16348.11"
              },
              {
                  "sn": "415",
                  "transactionId": "S69524918",
                  "transactionDate": "8/12/2022 12:00:00 AM",
                  "valueDate": "8/12/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "26.88",
                  "narration": "RF# REV Trf - pos",
                  "narration2": "000020220811195350004005686928/O8000497719",
                  "remarks": "MOBILE/AL - BAQI VENTURES",
                  "balance": "16374.99"
              },
              {
                  "sn": "416",
                  "transactionId": "S69525102",
                  "transactionDate": "8/12/2022 12:00:00 AM",
                  "valueDate": "8/12/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "26.88",
                  "narration": "RF# REV Trf - pos",
                  "narration2": "000020220811201350004005687162/O2011197902",
                  "remarks": "MOBILE/OKUNADE, ABODUNRIN KUDI",
                  "balance": "16401.87"
              },
              {
                  "sn": "417",
                  "transactionId": "S69567104",
                  "transactionDate": "8/12/2022 12:00:00 AM",
                  "valueDate": "8/12/2022 12:00:00 AM",
                  "debitAmount": "9100",
                  "creditAmount": "0",
                  "narration": "Trf - pos",
                  "narration2": "000020220812160816004005696777/O8000497719",
                  "remarks": "MOBILE/AL - BAQI VENTURES",
                  "balance": "7301.87"
              },
              {
                  "sn": "418",
                  "transactionId": "S69567104",
                  "transactionDate": "8/12/2022 12:00:00 AM",
                  "valueDate": "8/12/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - pos",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/AL - BAQI VENTURES",
                  "balance": "7274.99"
              },
              {
                  "sn": "419",
                  "transactionId": "S70167842",
                  "transactionDate": "8/15/2022 12:00:00 AM",
                  "valueDate": "8/14/2022 12:00:00 AM",
                  "debitAmount": "2000",
                  "creditAmount": "0",
                  "narration": "BPW Eko Electricity Distribution Company Plc Prepa",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/196015997457",
                  "balance": "5274.99"
              },
              {
                  "sn": "420",
                  "transactionId": "S70167842",
                  "transactionDate": "8/15/2022 12:00:00 AM",
                  "valueDate": "8/14/2022 12:00:00 AM",
                  "debitAmount": "100",
                  "creditAmount": "0",
                  "narration": "Fee - Eko Electricity Distribution Company Plc Pre",
                  "narration2": "FEE EXEMPT",
                  "remarks": "OTS45951/196015997457",
                  "balance": "5174.99"
              },
              {
                  "sn": "421",
                  "transactionId": "S70404132",
                  "transactionDate": "8/15/2022 12:00:00 AM",
                  "valueDate": "8/15/2022 12:00:00 AM",
                  "debitAmount": "100",
                  "creditAmount": "0",
                  "narration": "BPW GLO Airtime Purchase07051456716-91309",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/196046031081",
                  "balance": "5074.99"
              },
              {
                  "sn": "422",
                  "transactionId": "S70996108",
                  "transactionDate": "8/16/2022 12:00:00 AM",
                  "valueDate": "8/16/2022 12:00:00 AM",
                  "debitAmount": "3000",
                  "creditAmount": "0",
                  "narration": "Mob Recharge-Online: Recharge <MTN> <07033671485>",
                  "narration2": "BLEND-MOBILERECHARGE1-637962464619618845",
                  "remarks": "BLEND / BLD/2141121015",
                  "balance": "2074.99"
              },
              {
                  "sn": "423",
                  "transactionId": "S71001687",
                  "transactionDate": "8/16/2022 12:00:00 AM",
                  "valueDate": "8/16/2022 12:00:00 AM",
                  "debitAmount": "500",
                  "creditAmount": "0",
                  "narration": "Mob Recharge-Online: Recharge <MTN> <07033671485>",
                  "narration2": "BLEND-MOBILERECHARGE1-637962472020604789",
                  "remarks": "BLEND / BLD/844400603",
                  "balance": "1574.99"
              },
              {
                  "sn": "424",
                  "transactionId": "S71747092",
                  "transactionDate": "8/18/2022 12:00:00 AM",
                  "valueDate": "8/18/2022 12:00:00 AM",
                  "debitAmount": "1500",
                  "creditAmount": "0",
                  "narration": "Trf -",
                  "narration2": "000020220818133027004005762891/O0068317624",
                  "remarks": "MOBILE/OBINAKABA JENNIFER CHIN",
                  "balance": "74.99"
              },
              {
                  "sn": "425",
                  "transactionId": "S71747092",
                  "transactionDate": "8/18/2022 12:00:00 AM",
                  "valueDate": "8/18/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee -",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/OBINAKABA JENNIFER CHIN",
                  "balance": "64.24"
              },
              {
                  "sn": "426",
                  "transactionId": "S72142993",
                  "transactionDate": "8/19/2022 12:00:00 AM",
                  "valueDate": "8/19/2022 12:00:00 AM",
                  "debitAmount": "50",
                  "creditAmount": "0",
                  "narration": "RF# Stamp Duty Transfer from Chukwuemeka Okeleke",
                  "narration2": "100026220819101113000024830136/I5300017669",
                  "remarks": "HERITAGE BANK_20220819_125959/",
                  "balance": "14.24"
              },
              {
                  "sn": "427",
                  "transactionId": "S72143112",
                  "transactionDate": "8/19/2022 12:00:00 AM",
                  "valueDate": "8/19/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "10000",
                  "narration": "RF# Transfer from Chukwuemeka Okeleke",
                  "narration2": "100026220819101113000024830136/I5300017669",
                  "remarks": "HERITAGE BANK_20220819_125959/",
                  "balance": "10014.24"
              },
              {
                  "sn": "428",
                  "transactionId": "S72277194",
                  "transactionDate": "8/22/2022 12:00:00 AM",
                  "valueDate": "8/20/2022 12:00:00 AM",
                  "debitAmount": "9100",
                  "creditAmount": "0",
                  "narration": "Trf - pos",
                  "narration2": "000020220820083600004005783161/O8000497719",
                  "remarks": "MOBILE/AL - BAQI VENTURES",
                  "balance": "914.24"
              },
              {
                  "sn": "429",
                  "transactionId": "S72277194",
                  "transactionDate": "8/22/2022 12:00:00 AM",
                  "valueDate": "8/20/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - pos",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/AL - BAQI VENTURES",
                  "balance": "887.36"
              },
              {
                  "sn": "430",
                  "transactionId": "S72333444",
                  "transactionDate": "8/22/2022 12:00:00 AM",
                  "valueDate": "8/20/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "5000",
                  "narration": "000-OKELEKE  VINCENT AWELE-USSD NIP Tr",
                  "narration2": "000013220820191525000411594669/I0106161053",
                  "remarks": "NIP/NIP/OKELEKE  VINCENT AWELE",
                  "balance": "5887.36"
              },
              {
                  "sn": "431",
                  "transactionId": "S72334105",
                  "transactionDate": "8/22/2022 12:00:00 AM",
                  "valueDate": "8/20/2022 12:00:00 AM",
                  "debitAmount": "1300",
                  "creditAmount": "0",
                  "narration": "Mob Pymt obile: Payment For <GoTV>, Account Numbe",
                  "narration2": "BLEND-NTBANKI00000001-637966167215498395",
                  "remarks": "5300017669 / BILLER's NAME/357",
                  "balance": "4587.36"
              },
              {
                  "sn": "432",
                  "transactionId": "S72334105",
                  "transactionDate": "8/22/2022 12:00:00 AM",
                  "valueDate": "8/20/2022 12:00:00 AM",
                  "debitAmount": "100",
                  "creditAmount": "0",
                  "narration": "Mob Pymt Fee obile: Payment For <GoTV>, Account Nu",
                  "narration2": "FEE EXEMPT",
                  "remarks": "5300017669 / BILLER's NAME/357",
                  "balance": "4487.36"
              },
              {
                  "sn": "433",
                  "transactionId": "S72389633",
                  "transactionDate": "8/22/2022 12:00:00 AM",
                  "valueDate": "8/21/2022 12:00:00 AM",
                  "debitAmount": "4000",
                  "creditAmount": "0",
                  "narration": "Trf - ATM",
                  "narration2": "000020220821182750004005800467/O0130187278",
                  "remarks": "MOBILE/UMEADI NKEM PATRICIA",
                  "balance": "487.36"
              },
              {
                  "sn": "434",
                  "transactionId": "S72389633",
                  "transactionDate": "8/22/2022 12:00:00 AM",
                  "valueDate": "8/21/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - ATM",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/UMEADI NKEM PATRICIA",
                  "balance": "476.61"
              },
              {
                  "sn": "435",
                  "transactionId": "S73947734",
                  "transactionDate": "8/24/2022 12:00:00 AM",
                  "valueDate": "8/24/2022 12:00:00 AM",
                  "debitAmount": "1000",
                  "creditAmount": "0",
                  "narration": "AUGUST 2022 ASSBIFI  DUES",
                  "narration2": "",
                  "remarks": "",
                  "balance": "-523.39"
              },
              {
                  "sn": "436",
                  "transactionId": "S74013079",
                  "transactionDate": "8/24/2022 12:00:00 AM",
                  "valueDate": "8/24/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "137277.12",
                  "narration": "AUGUST 2022 SALARY",
                  "narration2": "",
                  "remarks": "",
                  "balance": "136753.73"
              },
              {
                  "sn": "437",
                  "transactionId": "S74015110",
                  "transactionDate": "8/24/2022 12:00:00 AM",
                  "valueDate": "8/24/2022 12:00:00 AM",
                  "debitAmount": "70000",
                  "creditAmount": "0",
                  "narration": "Mob Trf self",
                  "narration2": "BLEND-LOCAL TRANSFER:-5b281dda-641a-40e6-9b4d-7e83",
                  "remarks": "5300017669 / 1300012264/510840",
                  "balance": "66753.73"
              },
              {
                  "sn": "438",
                  "transactionId": "S74032878",
                  "transactionDate": "8/24/2022 12:00:00 AM",
                  "valueDate": "8/24/2022 12:00:00 AM",
                  "debitAmount": "20000",
                  "creditAmount": "0",
                  "narration": "Trf - tyre",
                  "narration2": "000020220824151402004005834467/O0064693238",
                  "remarks": "MOBILE/CHINAZA PEACE IHEME",
                  "balance": "46753.73"
              },
              {
                  "sn": "439",
                  "transactionId": "S74032878",
                  "transactionDate": "8/24/2022 12:00:00 AM",
                  "valueDate": "8/24/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - tyre",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/CHINAZA PEACE IHEME",
                  "balance": "46726.85"
              },
              {
                  "sn": "440",
                  "transactionId": "S74265090",
                  "transactionDate": "8/24/2022 12:00:00 AM",
                  "valueDate": "8/24/2022 12:00:00 AM",
                  "debitAmount": "9000",
                  "creditAmount": "0",
                  "narration": "Trf - clothes",
                  "narration2": "000020220824201248004005839536/O2275335250",
                  "remarks": "MOBILE/OLALEKAN MICHEAL AIYEOL",
                  "balance": "37726.85"
              },
              {
                  "sn": "441",
                  "transactionId": "S74265090",
                  "transactionDate": "8/24/2022 12:00:00 AM",
                  "valueDate": "8/24/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - clothes",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/OLALEKAN MICHEAL AIYEOL",
                  "balance": "37699.97"
              },
              {
                  "sn": "442",
                  "transactionId": "S74272294",
                  "transactionDate": "8/24/2022 12:00:00 AM",
                  "valueDate": "8/24/2022 12:00:00 AM",
                  "debitAmount": "7000",
                  "creditAmount": "0",
                  "narration": "Trf - Olembe Monthly Dues",
                  "narration2": "000020220824205307004005840095/O2082374471",
                  "remarks": "MOBILE/FESTAC ALLSTARS FOOTBAL",
                  "balance": "30699.97"
              },
              {
                  "sn": "443",
                  "transactionId": "S74272294",
                  "transactionDate": "8/24/2022 12:00:00 AM",
                  "valueDate": "8/24/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Olembe Monthly Dues",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/FESTAC ALLSTARS FOOTBAL",
                  "balance": "30673.09"
              },
              {
                  "sn": "444",
                  "transactionId": "S74272583",
                  "transactionDate": "8/24/2022 12:00:00 AM",
                  "valueDate": "8/24/2022 12:00:00 AM",
                  "debitAmount": "10000",
                  "creditAmount": "0",
                  "narration": "Trf - Awele",
                  "narration2": "000020220824205610004005840123/O1000008740",
                  "remarks": "MOBILE/LANRE S SONUBI",
                  "balance": "20673.09"
              },
              {
                  "sn": "445",
                  "transactionId": "S74272583",
                  "transactionDate": "8/24/2022 12:00:00 AM",
                  "valueDate": "8/24/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Awele",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/LANRE S SONUBI",
                  "balance": "20646.21"
              },
              {
                  "sn": "446",
                  "transactionId": "   M10650",
                  "transactionDate": "8/25/2022 12:00:00 AM",
                  "valueDate": "8/25/2022 12:00:00 AM",
                  "debitAmount": "1500",
                  "creditAmount": "0",
                  "narration": "STAFF WELFARE DEDUCTION FOR AUGUST 2022",
                  "narration2": "",
                  "remarks": "",
                  "balance": "19146.21"
              },
              {
                  "sn": "447",
                  "transactionId": "S74519207",
                  "transactionDate": "8/25/2022 12:00:00 AM",
                  "valueDate": "8/25/2022 12:00:00 AM",
                  "debitAmount": "3000",
                  "creditAmount": "0",
                  "narration": "Trf - Awele",
                  "narration2": "000020220825080353004005843149/O0130187278",
                  "remarks": "MOBILE/Umeadi Nkem Patricia",
                  "balance": "16146.21"
              },
              {
                  "sn": "448",
                  "transactionId": "S74519207",
                  "transactionDate": "8/25/2022 12:00:00 AM",
                  "valueDate": "8/25/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Awele",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/Umeadi Nkem Patricia",
                  "balance": "16135.46"
              },
              {
                  "sn": "449",
                  "transactionId": "S74570270",
                  "transactionDate": "8/25/2022 12:00:00 AM",
                  "valueDate": "8/25/2022 12:00:00 AM",
                  "debitAmount": "9000",
                  "creditAmount": "0",
                  "narration": "Trf -",
                  "narration2": "000020220825122149004005847377/O8000497719",
                  "remarks": "MOBILE/Al - Baqi Ventures",
                  "balance": "7135.46"
              },
              {
                  "sn": "450",
                  "transactionId": "S74570270",
                  "transactionDate": "8/25/2022 12:00:00 AM",
                  "valueDate": "8/25/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee -",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/Al - Baqi Ventures",
                  "balance": "7108.58"
              },
              {
                  "sn": "451",
                  "transactionId": "S74579213",
                  "transactionDate": "8/25/2022 12:00:00 AM",
                  "valueDate": "8/25/2022 12:00:00 AM",
                  "debitAmount": "3500",
                  "creditAmount": "0",
                  "narration": "Trf - Olembe",
                  "narration2": "000020220825133333004005848571/O0028116908",
                  "remarks": "MOBILE/CHUKWUEMEKA  OKELEKE",
                  "balance": "3608.58"
              },
              {
                  "sn": "452",
                  "transactionId": "S74579213",
                  "transactionDate": "8/25/2022 12:00:00 AM",
                  "valueDate": "8/25/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Olembe",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/CHUKWUEMEKA  OKELEKE",
                  "balance": "3597.83"
              },
              {
                  "sn": "453",
                  "transactionId": "S74885561",
                  "transactionDate": "8/25/2022 12:00:00 AM",
                  "valueDate": "8/25/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "11000",
                  "narration": "000-OSERU JACKSON O-TRF BO OSERU JACKS",
                  "narration2": "000002220825220314125026549692/I6014125894",
                  "remarks": "NIP/NIP/OSERU JACKSON O",
                  "balance": "14597.83"
              },
              {
                  "sn": "454",
                  "transactionId": "S74885561",
                  "transactionDate": "8/25/2022 12:00:00 AM",
                  "valueDate": "8/25/2022 12:00:00 AM",
                  "debitAmount": "50",
                  "creditAmount": "0",
                  "narration": "STAMP DUTY CHARGE ON DEPOSIT: 5300017669",
                  "narration2": "FEE EXEMPT",
                  "remarks": "NIP/NIP/OSERU JACKSON O",
                  "balance": "14547.83"
              },
              {
                  "sn": "455",
                  "transactionId": "   M11263",
                  "transactionDate": "8/26/2022 12:00:00 AM",
                  "valueDate": "8/26/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "25000",
                  "narration": "434613 CDP AKINYEMI DAVID OLUWASEGUN",
                  "narration2": "",
                  "remarks": "",
                  "balance": "39547.83"
              },
              {
                  "sn": "456",
                  "transactionId": "S74967805",
                  "transactionDate": "8/26/2022 12:00:00 AM",
                  "valueDate": "8/26/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "25000",
                  "narration": "Mob Trf Ajo",
                  "narration2": "BLEND-LOCAL TRANSFER:-a564d993-56c5-4cc3-a0b3-6500",
                  "remarks": "1001825738 / 5300017669/107455",
                  "balance": "64547.83"
              },
              {
                  "sn": "457",
                  "transactionId": "S74967805",
                  "transactionDate": "8/26/2022 12:00:00 AM",
                  "valueDate": "8/26/2022 12:00:00 AM",
                  "debitAmount": "50",
                  "creditAmount": "0",
                  "narration": "STAMP DUTY CHARGE ON DEPOSIT: 5300017669",
                  "narration2": "FEE EXEMPT",
                  "remarks": "1001825738 / 5300017669/107455",
                  "balance": "64497.83"
              },
              {
                  "sn": "458",
                  "transactionId": "S75076509",
                  "transactionDate": "8/26/2022 12:00:00 AM",
                  "valueDate": "8/26/2022 12:00:00 AM",
                  "debitAmount": "50",
                  "creditAmount": "0",
                  "narration": "STAMP DUTY CHARGE ON DEPOSIT:5300017669",
                  "narration2": "",
                  "remarks": "",
                  "balance": "64447.83"
              },
              {
                  "sn": "459",
                  "transactionId": "S75087023",
                  "transactionDate": "8/26/2022 12:00:00 AM",
                  "valueDate": "8/26/2022 12:00:00 AM",
                  "debitAmount": "50000",
                  "creditAmount": "0",
                  "narration": "Mob Trf self",
                  "narration2": "BLEND-LOCAL TRANSFER:-aab2a821-3c2a-4ff1-b595-09af",
                  "remarks": "5300017669 / 1300012264/102458",
                  "balance": "14447.83"
              },
              {
                  "sn": "460",
                  "transactionId": "S75214451",
                  "transactionDate": "8/29/2022 12:00:00 AM",
                  "valueDate": "8/27/2022 12:00:00 AM",
                  "debitAmount": "2500",
                  "creditAmount": "0",
                  "narration": "Trf - Olembe",
                  "narration2": "000020220827193621004005884466/O0026600059",
                  "remarks": "MOBILE/NKWOPARA CHIDINMA",
                  "balance": "11947.83"
              },
              {
                  "sn": "461",
                  "transactionId": "S75214451",
                  "transactionDate": "8/29/2022 12:00:00 AM",
                  "valueDate": "8/27/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Olembe",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/NKWOPARA CHIDINMA",
                  "balance": "11937.08"
              },
              {
                  "sn": "462",
                  "transactionId": "S75229793",
                  "transactionDate": "8/29/2022 12:00:00 AM",
                  "valueDate": "8/28/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "Trf -",
                  "narration2": "000020220828061241004005887024/O0130187278",
                  "remarks": "MOBILE/Umeadi Nkem Patricia",
                  "balance": "6937.08"
              },
              {
                  "sn": "463",
                  "transactionId": "S75229793",
                  "transactionDate": "8/29/2022 12:00:00 AM",
                  "valueDate": "8/28/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee -",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/Umeadi Nkem Patricia",
                  "balance": "6926.33"
              },
              {
                  "sn": "464",
                  "transactionId": "S75554914",
                  "transactionDate": "8/29/2022 12:00:00 AM",
                  "valueDate": "8/29/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "25000",
                  "narration": "000-OYEBOLA RAJI-OYE's Monthly Contrib",
                  "narration2": "000015220829192359002967049217/I2005518283",
                  "remarks": "NIP/NIP/OYEBOLA RAJI",
                  "balance": "31926.33"
              },
              {
                  "sn": "465",
                  "transactionId": "S75554914",
                  "transactionDate": "8/29/2022 12:00:00 AM",
                  "valueDate": "8/29/2022 12:00:00 AM",
                  "debitAmount": "50",
                  "creditAmount": "0",
                  "narration": "STAMP DUTY CHARGE ON DEPOSIT: 5300017669",
                  "narration2": "FEE EXEMPT",
                  "remarks": "NIP/NIP/OYEBOLA RAJI",
                  "balance": "31876.33"
              },
              {
                  "sn": "466",
                  "transactionId": "S75568368",
                  "transactionDate": "8/29/2022 12:00:00 AM",
                  "valueDate": "8/29/2022 12:00:00 AM",
                  "debitAmount": "4100",
                  "creditAmount": "0",
                  "narration": "Trf - pos",
                  "narration2": "000020220829205757004005909682/O2124672732",
                  "remarks": "MOBILE/Badmus Saheed Abiola",
                  "balance": "27776.33"
              },
              {
                  "sn": "467",
                  "transactionId": "S75568368",
                  "transactionDate": "8/29/2022 12:00:00 AM",
                  "valueDate": "8/29/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - pos",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/Badmus Saheed Abiola",
                  "balance": "27765.58"
              },
              {
                  "sn": "468",
                  "transactionId": "S76022013",
                  "transactionDate": "8/31/2022 12:00:00 AM",
                  "valueDate": "8/31/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "25000",
                  "narration": "Mob Trf maryjane",
                  "narration2": "BLEND-LOCAL TRANSFER:-f3355526-a905-454b-b814-035c",
                  "remarks": "1001803864 / 5300017669/211258",
                  "balance": "52765.58"
              },
              {
                  "sn": "469",
                  "transactionId": "S76022013",
                  "transactionDate": "8/31/2022 12:00:00 AM",
                  "valueDate": "8/31/2022 12:00:00 AM",
                  "debitAmount": "50",
                  "creditAmount": "0",
                  "narration": "STAMP DUTY CHARGE ON DEPOSIT: 5300017669",
                  "narration2": "FEE EXEMPT",
                  "remarks": "1001803864 / 5300017669/211258",
                  "balance": "52715.58"
              },
              {
                  "sn": "470",
                  "transactionId": "S76028756",
                  "transactionDate": "8/31/2022 12:00:00 AM",
                  "valueDate": "8/31/2022 12:00:00 AM",
                  "debitAmount": "9000",
                  "creditAmount": "0",
                  "narration": "Mob Trf Invinceable",
                  "narration2": "BLEND-LOCAL TRANSFER:-67baadf2-c851-4e11-90f4-7d58",
                  "remarks": "5300017669 / 1001597495/738726",
                  "balance": "43715.58"
              },
              {
                  "sn": "471",
                  "transactionId": "S76668270",
                  "transactionDate": "9/1/2022 12:00:00 AM",
                  "valueDate": "9/1/2022 12:00:00 AM",
                  "debitAmount": "3000",
                  "creditAmount": "0",
                  "narration": "Mob Trf Invinceable",
                  "narration2": "BLEND-LOCAL TRANSFER:-2211946c-ccb3-4d1d-be7e-cf4d",
                  "remarks": "5300017669 / 1001825680/190750",
                  "balance": "40715.58"
              },
              {
                  "sn": "472",
                  "transactionId": "S76751051",
                  "transactionDate": "9/2/2022 12:00:00 AM",
                  "valueDate": "9/2/2022 12:00:00 AM",
                  "debitAmount": "20050",
                  "creditAmount": "0",
                  "narration": "Mob Trf invinceable",
                  "narration2": "BLEND-LOCAL TRANSFER:-99076426-67d5-4921-bff4-e8c5",
                  "remarks": "5300017669 / 1001597495/145082",
                  "balance": "20665.58"
              },
              {
                  "sn": "473",
                  "transactionId": "S77066298",
                  "transactionDate": "9/2/2022 12:00:00 AM",
                  "valueDate": "9/2/2022 12:00:00 AM",
                  "debitAmount": "5350",
                  "creditAmount": "0",
                  "narration": "Trf - invinceable",
                  "narration2": "000020220902231342004005978925/O5105810016",
                  "remarks": "MOBILE/OKON MARYLYN ODIONG",
                  "balance": "15315.58"
              },
              {
                  "sn": "474",
                  "transactionId": "S77066298",
                  "transactionDate": "9/2/2022 12:00:00 AM",
                  "valueDate": "9/2/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - invinceable",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/OKON MARYLYN ODIONG",
                  "balance": "15288.7"
              },
              {
                  "sn": "475",
                  "transactionId": "S77075718",
                  "transactionDate": "9/2/2022 12:00:00 AM",
                  "valueDate": "9/3/2022 12:00:00 AM",
                  "debitAmount": "8000",
                  "creditAmount": "0",
                  "narration": "Trf - bill",
                  "narration2": "000020220903002750004005979240/O2526384363",
                  "remarks": "MOBILE/RUTH UJUNWA UDOKORO",
                  "balance": "7288.7"
              },
              {
                  "sn": "476",
                  "transactionId": "S77075718",
                  "transactionDate": "9/2/2022 12:00:00 AM",
                  "valueDate": "9/3/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - bill",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/RUTH UJUNWA UDOKORO",
                  "balance": "7261.82"
              },
              {
                  "sn": "477",
                  "transactionId": "S77163680",
                  "transactionDate": "9/5/2022 12:00:00 AM",
                  "valueDate": "9/3/2022 12:00:00 AM",
                  "debitAmount": "1500",
                  "creditAmount": "0",
                  "narration": "Trf - Meat",
                  "narration2": "000020220903184503004005990933/O0032106982",
                  "remarks": "MOBILE/APUGO VICTOR CHUKWUDI",
                  "balance": "5761.82"
              },
              {
                  "sn": "478",
                  "transactionId": "S77163680",
                  "transactionDate": "9/5/2022 12:00:00 AM",
                  "valueDate": "9/3/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Meat",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/APUGO VICTOR CHUKWUDI",
                  "balance": "5751.07"
              },
              {
                  "sn": "479",
                  "transactionId": "S77169135",
                  "transactionDate": "9/5/2022 12:00:00 AM",
                  "valueDate": "9/3/2022 12:00:00 AM",
                  "debitAmount": "5100",
                  "creditAmount": "0",
                  "narration": "Trf -",
                  "narration2": "000020220903193859004005991774/O7038665506",
                  "remarks": "MOBILE/Vivian  Nlemuzor",
                  "balance": "651.07"
              },
              {
                  "sn": "480",
                  "transactionId": "S77169135",
                  "transactionDate": "9/5/2022 12:00:00 AM",
                  "valueDate": "9/3/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee -",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/Vivian  Nlemuzor",
                  "balance": "624.19"
              },
              {
                  "sn": "481",
                  "transactionId": "S77212475",
                  "transactionDate": "9/5/2022 12:00:00 AM",
                  "valueDate": "9/4/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "50000",
                  "narration": "Mob Trf",
                  "narration2": "BLEND-LOCAL TRANSFER:-5a9fe1f1-18ad-4c99-aa89-db39",
                  "remarks": "1300012264 / 5300017669/107991",
                  "balance": "50624.19"
              },
              {
                  "sn": "482",
                  "transactionId": "S77212584",
                  "transactionDate": "9/5/2022 12:00:00 AM",
                  "valueDate": "9/4/2022 12:00:00 AM",
                  "debitAmount": "10000",
                  "creditAmount": "0",
                  "narration": "Trf - ?",
                  "narration2": "000020220904131549004005998799/O0130187278",
                  "remarks": "MOBILE/Umeadi Nkem Patricia",
                  "balance": "40624.19"
              },
              {
                  "sn": "483",
                  "transactionId": "S77212584",
                  "transactionDate": "9/5/2022 12:00:00 AM",
                  "valueDate": "9/4/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - ?",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/Umeadi Nkem Patricia",
                  "balance": "40597.31"
              },
              {
                  "sn": "484",
                  "transactionId": "S77437341",
                  "transactionDate": "9/5/2022 12:00:00 AM",
                  "valueDate": "9/5/2022 12:00:00 AM",
                  "debitAmount": "9000",
                  "creditAmount": "0",
                  "narration": "INW invinceable",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/637979876973",
                  "balance": "31597.31"
              },
              {
                  "sn": "485",
                  "transactionId": "S77915508",
                  "transactionDate": "9/6/2022 12:00:00 AM",
                  "valueDate": "9/6/2022 12:00:00 AM",
                  "debitAmount": "20000",
                  "creditAmount": "0",
                  "narration": "Trf - Awele_Onyeka",
                  "narration2": "000020220906154329004006027723/O3029407999",
                  "remarks": "MOBILE/OKELEKE ONYEKACHUKWU AN",
                  "balance": "11597.31"
              },
              {
                  "sn": "486",
                  "transactionId": "S77915508",
                  "transactionDate": "9/6/2022 12:00:00 AM",
                  "valueDate": "9/6/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Awele_Onyeka",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/OKELEKE ONYEKACHUKWU AN",
                  "balance": "11570.43"
              },
              {
                  "sn": "487",
                  "transactionId": "S78040020",
                  "transactionDate": "9/6/2022 12:00:00 AM",
                  "valueDate": "9/6/2022 12:00:00 AM",
                  "debitAmount": "1500",
                  "creditAmount": "0",
                  "narration": "BPW Eko Electricity Distribution Company Plc Prepa",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/196058535848",
                  "balance": "10070.43"
              },
              {
                  "sn": "488",
                  "transactionId": "S78040020",
                  "transactionDate": "9/6/2022 12:00:00 AM",
                  "valueDate": "9/6/2022 12:00:00 AM",
                  "debitAmount": "100",
                  "creditAmount": "0",
                  "narration": "Fee - Eko Electricity Distribution Company Plc Pre",
                  "narration2": "FEE EXEMPT",
                  "remarks": "OTS45951/196058535848",
                  "balance": "9970.43"
              },
              {
                  "sn": "489",
                  "transactionId": "S78359285",
                  "transactionDate": "9/7/2022 12:00:00 AM",
                  "valueDate": "9/7/2022 12:00:00 AM",
                  "debitAmount": "1500",
                  "creditAmount": "0",
                  "narration": "Mob Recharge-Online: Recharge <Glo> <07051456716>",
                  "narration2": "BLEND-MOBILERECHARGE1-637981459485184128",
                  "remarks": "BLEND / BLD/660758372",
                  "balance": "8470.43"
              },
              {
                  "sn": "490",
                  "transactionId": "S78359307",
                  "transactionDate": "9/7/2022 12:00:00 AM",
                  "valueDate": "9/7/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "1500",
                  "narration": "Rev Mob Pymt ev Blend Pymt-   20060217290754000000",
                  "narration2": "BLEND-MOBILERECHARGE1-2006021729075400000003370968",
                  "remarks": "5300017669 / BILLER's NAME/660",
                  "balance": "9970.43"
              },
              {
                  "sn": "491",
                  "transactionId": "S78363475",
                  "transactionDate": "9/7/2022 12:00:00 AM",
                  "valueDate": "9/7/2022 12:00:00 AM",
                  "debitAmount": "1500",
                  "creditAmount": "0",
                  "narration": "BPW GLO Airtime Purchase07051456716-91309",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/196063116165",
                  "balance": "8470.43"
              },
              {
                  "sn": "492",
                  "transactionId": "S78721893",
                  "transactionDate": "9/8/2022 12:00:00 AM",
                  "valueDate": "9/8/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "7500",
                  "narration": "Birthday Gift- CXA Welfare",
                  "narration2": "VANSO-LOCAL TRANSFER:-1535HB1662625084801692",
                  "remarks": "6280510103077777776",
                  "balance": "15970.43"
              },
              {
                  "sn": "493",
                  "transactionId": "S78795663",
                  "transactionDate": "9/8/2022 12:00:00 AM",
                  "valueDate": "9/8/2022 12:00:00 AM",
                  "debitAmount": "9000",
                  "creditAmount": "0",
                  "narration": "Mob Trf ??",
                  "narration2": "BLEND-LOCAL TRANSFER:-ed2e719a-6366-4371-b4ef-f098",
                  "remarks": "5300017669 / 1200094467/439594",
                  "balance": "6970.43"
              },
              {
                  "sn": "494",
                  "transactionId": "S79600415",
                  "transactionDate": "9/9/2022 12:00:00 AM",
                  "valueDate": "9/9/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "20000",
                  "narration": "000-OKELEKE ONYEKACHUKWU ANTHONIA-USSD",
                  "narration2": "000016220909102440000071400785/I3029407999",
                  "remarks": "NIP/NIP/OKELEKE ONYEKACHUKWU A",
                  "balance": "26970.43"
              },
              {
                  "sn": "495",
                  "transactionId": "S79600415",
                  "transactionDate": "9/9/2022 12:00:00 AM",
                  "valueDate": "9/9/2022 12:00:00 AM",
                  "debitAmount": "50",
                  "creditAmount": "0",
                  "narration": "STAMP DUTY CHARGE ON DEPOSIT: 5300017669",
                  "narration2": "FEE EXEMPT",
                  "remarks": "NIP/NIP/OKELEKE ONYEKACHUKWU A",
                  "balance": "26920.43"
              },
              {
                  "sn": "496",
                  "transactionId": "S80275713",
                  "transactionDate": "9/12/2022 12:00:00 AM",
                  "valueDate": "9/10/2022 12:00:00 AM",
                  "debitAmount": "6000",
                  "creditAmount": "0",
                  "narration": "Trf - Olembe",
                  "narration2": "000020220910174940004006087991/O0068317624",
                  "remarks": "MOBILE/OBINAKABA JENNIFER CHIN",
                  "balance": "20920.43"
              },
              {
                  "sn": "497",
                  "transactionId": "S80275713",
                  "transactionDate": "9/12/2022 12:00:00 AM",
                  "valueDate": "9/10/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Olembe",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/OBINAKABA JENNIFER CHIN",
                  "balance": "20893.55"
              },
              {
                  "sn": "498",
                  "transactionId": "S80460950",
                  "transactionDate": "9/12/2022 12:00:00 AM",
                  "valueDate": "9/11/2022 12:00:00 AM",
                  "debitAmount": "2000",
                  "creditAmount": "0",
                  "narration": "Trf - Awele",
                  "narration2": "000020220911090534004006093062/O0130187278",
                  "remarks": "MOBILE/Umeadi Nkem Patricia",
                  "balance": "18893.55"
              },
              {
                  "sn": "499",
                  "transactionId": "S80460950",
                  "transactionDate": "9/12/2022 12:00:00 AM",
                  "valueDate": "9/11/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Awele",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/Umeadi Nkem Patricia",
                  "balance": "18882.8"
              },
              {
                  "sn": "500",
                  "transactionId": "S80487174",
                  "transactionDate": "9/12/2022 12:00:00 AM",
                  "valueDate": "9/11/2022 12:00:00 AM",
                  "debitAmount": "100",
                  "creditAmount": "0",
                  "narration": "Mob Recharge-Online: Recharge <Glo> <07051456716>",
                  "narration2": "BLEND-MOBILERECHARGE1-637984872423085959",
                  "remarks": "BLEND / BLD/762434591",
                  "balance": "18782.8"
              },
              {
                  "sn": "501",
                  "transactionId": "S80571772",
                  "transactionDate": "9/12/2022 12:00:00 AM",
                  "valueDate": "9/11/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "5000",
                  "narration": "NXG :TRFOSSYFRM COLE  CHIORI TO  VINCENT",
                  "narration2": "",
                  "remarks": "/369111/439906582",
                  "balance": "23782.8"
              },
              {
                  "sn": "502",
                  "transactionId": "S80796284",
                  "transactionDate": "9/12/2022 12:00:00 AM",
                  "valueDate": "9/12/2022 12:00:00 AM",
                  "debitAmount": "9000",
                  "creditAmount": "0",
                  "narration": "Mob Trf",
                  "narration2": "BLEND-LOCAL TRANSFER:-00ed32a4-03f0-40eb-a7d2-8448",
                  "remarks": "5300017669 / 1001825965/685660",
                  "balance": "14782.8"
              },
              {
                  "sn": "503",
                  "transactionId": "S81709926",
                  "transactionDate": "9/14/2022 12:00:00 AM",
                  "valueDate": "9/14/2022 12:00:00 AM",
                  "debitAmount": "3500",
                  "creditAmount": "0",
                  "narration": "Mob Recharge-Online: Recharge <MTN> <07033671485>",
                  "narration2": "BLEND-MOBILERECHARGE1-637987648270312609",
                  "remarks": "BLEND / BLD/1852173042",
                  "balance": "11282.8"
              },
              {
                  "sn": "504",
                  "transactionId": "S81964866",
                  "transactionDate": "9/15/2022 12:00:00 AM",
                  "valueDate": "9/15/2022 12:00:00 AM",
                  "debitAmount": "9000",
                  "creditAmount": "0",
                  "narration": "Trf - invinceable",
                  "narration2": "000020220915184149004006150253/O8000497719",
                  "remarks": "MOBILE/Al - Baqi Ventures",
                  "balance": "2282.8"
              },
              {
                  "sn": "505",
                  "transactionId": "S81964866",
                  "transactionDate": "9/15/2022 12:00:00 AM",
                  "valueDate": "9/15/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - invinceable",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/Al - Baqi Ventures",
                  "balance": "2255.92"
              },
              {
                  "sn": "506",
                  "transactionId": "S82448570",
                  "transactionDate": "9/16/2022 12:00:00 AM",
                  "valueDate": "9/16/2022 12:00:00 AM",
                  "debitAmount": "2200",
                  "creditAmount": "0",
                  "narration": "Trf - Olembe",
                  "narration2": "000020220916212322004006162833/O0026600059",
                  "remarks": "MOBILE/NKWOPARA CHIDINMA",
                  "balance": "55.92"
              },
              {
                  "sn": "507",
                  "transactionId": "S82448570",
                  "transactionDate": "9/16/2022 12:00:00 AM",
                  "valueDate": "9/16/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Olembe",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/NKWOPARA CHIDINMA",
                  "balance": "45.17"
              },
              {
                  "sn": "508",
                  "transactionId": "S82724420",
                  "transactionDate": "9/19/2022 12:00:00 AM",
                  "valueDate": "9/18/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "80000",
                  "narration": "Mob Trf",
                  "narration2": "BLEND-LOCAL TRANSFER:-cd6a73a8-3edd-4845-a967-3edf",
                  "remarks": "1300012264 / 5300017669/641048",
                  "balance": "80045.17"
              },
              {
                  "sn": "509",
                  "transactionId": "S82724516",
                  "transactionDate": "9/19/2022 12:00:00 AM",
                  "valueDate": "9/18/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "Trf - invinceable",
                  "narration2": "000020220918084329004006178328/O0130187278",
                  "remarks": "MOBILE/Umeadi Nkem Patricia",
                  "balance": "75045.17"
              },
              {
                  "sn": "510",
                  "transactionId": "S82724516",
                  "transactionDate": "9/19/2022 12:00:00 AM",
                  "valueDate": "9/18/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - invinceable",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/Umeadi Nkem Patricia",
                  "balance": "75034.42"
              },
              {
                  "sn": "511",
                  "transactionId": "S82746539",
                  "transactionDate": "9/19/2022 12:00:00 AM",
                  "valueDate": "9/18/2022 12:00:00 AM",
                  "debitAmount": "3000",
                  "creditAmount": "0",
                  "narration": "Trf - coolant",
                  "narration2": "000020220918152758004006181977/O3087184450",
                  "remarks": "MOBILE/SHEFIU TIJANI OLA",
                  "balance": "72034.42"
              },
              {
                  "sn": "512",
                  "transactionId": "S82746539",
                  "transactionDate": "9/19/2022 12:00:00 AM",
                  "valueDate": "9/18/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - coolant",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/SHEFIU TIJANI OLA",
                  "balance": "72023.67"
              },
              {
                  "sn": "513",
                  "transactionId": "S82749415",
                  "transactionDate": "9/19/2022 12:00:00 AM",
                  "valueDate": "9/18/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "Trf - invinceable",
                  "narration2": "000020220918161345004006182374/O0130187278",
                  "remarks": "MOBILE/Umeadi Nkem Patricia",
                  "balance": "67023.67"
              },
              {
                  "sn": "514",
                  "transactionId": "S82749415",
                  "transactionDate": "9/19/2022 12:00:00 AM",
                  "valueDate": "9/18/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - invinceable",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/Umeadi Nkem Patricia",
                  "balance": "67012.92"
              },
              {
                  "sn": "515",
                  "transactionId": "S82759053",
                  "transactionDate": "9/19/2022 12:00:00 AM",
                  "valueDate": "9/18/2022 12:00:00 AM",
                  "debitAmount": "4000",
                  "creditAmount": "0",
                  "narration": "Trf -",
                  "narration2": "000020220918185433004006183590/O0130187278",
                  "remarks": "MOBILE/Umeadi Nkem Patricia",
                  "balance": "63012.92"
              },
              {
                  "sn": "516",
                  "transactionId": "S82759053",
                  "transactionDate": "9/19/2022 12:00:00 AM",
                  "valueDate": "9/18/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee -",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/Umeadi Nkem Patricia",
                  "balance": "63002.17"
              },
              {
                  "sn": "517",
                  "transactionId": "S82788567",
                  "transactionDate": "9/19/2022 12:00:00 AM",
                  "valueDate": "9/19/2022 12:00:00 AM",
                  "debitAmount": "4500",
                  "creditAmount": "0",
                  "narration": "Trf - Olembe",
                  "narration2": "000020220919102726004006187079/O0022964170",
                  "remarks": "MOBILE/NWACHUKWU MUNACHIMSO",
                  "balance": "58502.17"
              },
              {
                  "sn": "518",
                  "transactionId": "S82788567",
                  "transactionDate": "9/19/2022 12:00:00 AM",
                  "valueDate": "9/19/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Olembe",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/NWACHUKWU MUNACHIMSO",
                  "balance": "58491.42"
              },
              {
                  "sn": "519",
                  "transactionId": "S82797881",
                  "transactionDate": "9/19/2022 12:00:00 AM",
                  "valueDate": "9/19/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "10000",
                  "narration": "000-OKELEKE ONYEKACHUKWU ANTHONIA-USSD",
                  "narration2": "000016220919115037000005686154/I3029407999",
                  "remarks": "NIP/NIP/OKELEKE ONYEKACHUKWU A",
                  "balance": "68491.42"
              },
              {
                  "sn": "520",
                  "transactionId": "S82797881",
                  "transactionDate": "9/19/2022 12:00:00 AM",
                  "valueDate": "9/19/2022 12:00:00 AM",
                  "debitAmount": "50",
                  "creditAmount": "0",
                  "narration": "STAMP DUTY CHARGE ON DEPOSIT: 5300017669",
                  "narration2": "FEE EXEMPT",
                  "remarks": "NIP/NIP/OKELEKE ONYEKACHUKWU A",
                  "balance": "68441.42"
              },
              {
                  "sn": "521",
                  "transactionId": "S82798693",
                  "transactionDate": "9/19/2022 12:00:00 AM",
                  "valueDate": "9/19/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "20000",
                  "narration": "000-CHIDI ANTHONY AGBO-Transfer from C",
                  "narration2": "000001220919115222000193490947/I0031640537",
                  "remarks": "NIP/NIP/CHIDI ANTHONY AGBO",
                  "balance": "88441.42"
              },
              {
                  "sn": "522",
                  "transactionId": "S82798693",
                  "transactionDate": "9/19/2022 12:00:00 AM",
                  "valueDate": "9/19/2022 12:00:00 AM",
                  "debitAmount": "50",
                  "creditAmount": "0",
                  "narration": "STAMP DUTY CHARGE ON DEPOSIT: 5300017669",
                  "narration2": "FEE EXEMPT",
                  "remarks": "NIP/NIP/CHIDI ANTHONY AGBO",
                  "balance": "88391.42"
              },
              {
                  "sn": "523",
                  "transactionId": "S82802179",
                  "transactionDate": "9/19/2022 12:00:00 AM",
                  "valueDate": "9/19/2022 12:00:00 AM",
                  "debitAmount": "80000",
                  "creditAmount": "0",
                  "narration": "Trf - Olembe",
                  "narration2": "000020220919120305004006188781/O0043526674",
                  "remarks": "MOBILE/EMEKA JONES EZEUDU",
                  "balance": "8391.42"
              },
              {
                  "sn": "524",
                  "transactionId": "S82802179",
                  "transactionDate": "9/19/2022 12:00:00 AM",
                  "valueDate": "9/19/2022 12:00:00 AM",
                  "debitAmount": "53.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Olembe",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/EMEKA JONES EZEUDU",
                  "balance": "8337.67"
              },
              {
                  "sn": "525",
                  "transactionId": "S82803281",
                  "transactionDate": "9/19/2022 12:00:00 AM",
                  "valueDate": "9/19/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "10000",
                  "narration": "Mob Trf",
                  "narration2": "BLEND-LOCAL TRANSFER:-449a1b85-db55-4420-afb2-6555",
                  "remarks": "1001825965 / 5300017669/953167",
                  "balance": "18337.67"
              },
              {
                  "sn": "526",
                  "transactionId": "S82803281",
                  "transactionDate": "9/19/2022 12:00:00 AM",
                  "valueDate": "9/19/2022 12:00:00 AM",
                  "debitAmount": "50",
                  "creditAmount": "0",
                  "narration": "STAMP DUTY CHARGE ON DEPOSIT: 5300017669",
                  "narration2": "FEE EXEMPT",
                  "remarks": "1001825965 / 5300017669/953167",
                  "balance": "18287.67"
              },
              {
                  "sn": "527",
                  "transactionId": "S82896548",
                  "transactionDate": "9/19/2022 12:00:00 AM",
                  "valueDate": "9/19/2022 12:00:00 AM",
                  "debitAmount": "9000",
                  "creditAmount": "0",
                  "narration": "Mob Trf invinceable",
                  "narration2": "BLEND-LOCAL TRANSFER:-bdc6412a-1b16-40f0-9461-a0b8",
                  "remarks": "5300017669 / 1001597495/169879",
                  "balance": "9287.67"
              },
              {
                  "sn": "528",
                  "transactionId": "S82967371",
                  "transactionDate": "9/19/2022 12:00:00 AM",
                  "valueDate": "9/19/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "130000",
                  "narration": "000-PAUL C CHIORI-FT MB Ossy OKELEKE V",
                  "narration2": "000027220919172946037864578020/I2001422047",
                  "remarks": "NIP/NIP/PAUL C CHIORI",
                  "balance": "139287.67"
              },
              {
                  "sn": "529",
                  "transactionId": "S82967371",
                  "transactionDate": "9/19/2022 12:00:00 AM",
                  "valueDate": "9/19/2022 12:00:00 AM",
                  "debitAmount": "50",
                  "creditAmount": "0",
                  "narration": "STAMP DUTY CHARGE ON DEPOSIT: 5300017669",
                  "narration2": "FEE EXEMPT",
                  "remarks": "NIP/NIP/PAUL C CHIORI",
                  "balance": "139237.67"
              },
              {
                  "sn": "530",
                  "transactionId": "S83009951",
                  "transactionDate": "9/19/2022 12:00:00 AM",
                  "valueDate": "9/19/2022 12:00:00 AM",
                  "debitAmount": "55000",
                  "creditAmount": "0",
                  "narration": "Mob Trf self",
                  "narration2": "BLEND-LOCAL TRANSFER:-ec20ca27-4087-4f55-9cbf-43f3",
                  "remarks": "5300017669 / 1300012264/179510",
                  "balance": "84237.67"
              },
              {
                  "sn": "531",
                  "transactionId": "S83010821",
                  "transactionDate": "9/19/2022 12:00:00 AM",
                  "valueDate": "9/19/2022 12:00:00 AM",
                  "debitAmount": "36400",
                  "creditAmount": "0",
                  "narration": "Trf - Festival Allstars, Invinceable",
                  "narration2": "000020220919194451004006195931/O2125008246",
                  "remarks": "MOBILE/OLA OLUWASEUN ENDURANCE",
                  "balance": "47837.67"
              },
              {
                  "sn": "532",
                  "transactionId": "S83010821",
                  "transactionDate": "9/19/2022 12:00:00 AM",
                  "valueDate": "9/19/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Festival Allstars, Invinceable",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/OLA OLUWASEUN ENDURANCE",
                  "balance": "47810.79"
              },
              {
                  "sn": "533",
                  "transactionId": "S83011760",
                  "transactionDate": "9/19/2022 12:00:00 AM",
                  "valueDate": "9/19/2022 12:00:00 AM",
                  "debitAmount": "20000",
                  "creditAmount": "0",
                  "narration": "Trf - Vince",
                  "narration2": "000020220919194845004006195976/O0031640537",
                  "remarks": "MOBILE/Chidi Anthony Agbo",
                  "balance": "27810.79"
              },
              {
                  "sn": "534",
                  "transactionId": "S83011760",
                  "transactionDate": "9/19/2022 12:00:00 AM",
                  "valueDate": "9/19/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Vince",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/Chidi Anthony Agbo",
                  "balance": "27783.91"
              },
              {
                  "sn": "535",
                  "transactionId": "S83012610",
                  "transactionDate": "9/19/2022 12:00:00 AM",
                  "valueDate": "9/19/2022 12:00:00 AM",
                  "debitAmount": "10000",
                  "creditAmount": "0",
                  "narration": "Trf - Awele",
                  "narration2": "000020220919195211004006196014/O3029407999",
                  "remarks": "MOBILE/OKELEKE ONYEKACHUKWU AN",
                  "balance": "17783.91"
              },
              {
                  "sn": "536",
                  "transactionId": "S83012610",
                  "transactionDate": "9/19/2022 12:00:00 AM",
                  "valueDate": "9/19/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Awele",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/OKELEKE ONYEKACHUKWU AN",
                  "balance": "17757.03"
              },
              {
                  "sn": "537",
                  "transactionId": "S83013573",
                  "transactionDate": "9/19/2022 12:00:00 AM",
                  "valueDate": "9/19/2022 12:00:00 AM",
                  "debitAmount": "10000",
                  "creditAmount": "0",
                  "narration": "Trf - invinceable",
                  "narration2": "000020220919195630004006196066/O0034948976",
                  "remarks": "MOBILE/AYILARA RALPH OLAOLUWA",
                  "balance": "7757.03"
              },
              {
                  "sn": "538",
                  "transactionId": "S83013573",
                  "transactionDate": "9/19/2022 12:00:00 AM",
                  "valueDate": "9/19/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - invinceable",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/AYILARA RALPH OLAOLUWA",
                  "balance": "7730.15"
              },
              {
                  "sn": "539",
                  "transactionId": "S83177771",
                  "transactionDate": "9/20/2022 12:00:00 AM",
                  "valueDate": "9/20/2022 12:00:00 AM",
                  "debitAmount": "6000",
                  "creditAmount": "0",
                  "narration": "Trf - Olembe",
                  "narration2": "000020220920084539004006199640/O3084402984",
                  "remarks": "MOBILE/OKPOMO FRANCISCA OTABUN",
                  "balance": "1730.15"
              },
              {
                  "sn": "540",
                  "transactionId": "S83177771",
                  "transactionDate": "9/20/2022 12:00:00 AM",
                  "valueDate": "9/20/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Olembe",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/OKPOMO FRANCISCA OTABUN",
                  "balance": "1703.27"
              },
              {
                  "sn": "541",
                  "transactionId": "S84256698",
                  "transactionDate": "9/22/2022 12:00:00 AM",
                  "valueDate": "9/22/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "10000",
                  "narration": "Mob Trf self",
                  "narration2": "BLEND-LOCAL TRANSFER:-eeb18252-4e5c-4001-9254-a262",
                  "remarks": "1300012264 / 5300017669/384261",
                  "balance": "11703.27"
              },
              {
                  "sn": "542",
                  "transactionId": "S84258571",
                  "transactionDate": "9/22/2022 12:00:00 AM",
                  "valueDate": "9/22/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "Trf - Fan belt",
                  "narration2": "000020220922172255004006231668/O1002829741",
                  "remarks": "MOBILE/OFORJUO OKWUCHUKWU ALOY",
                  "balance": "6703.27"
              },
              {
                  "sn": "543",
                  "transactionId": "S84258571",
                  "transactionDate": "9/22/2022 12:00:00 AM",
                  "valueDate": "9/22/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Fan belt",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/OFORJUO OKWUCHUKWU ALOY",
                  "balance": "6692.52"
              },
              {
                  "sn": "544",
                  "transactionId": "S84352060",
                  "transactionDate": "9/22/2022 12:00:00 AM",
                  "valueDate": "9/22/2022 12:00:00 AM",
                  "debitAmount": "6000",
                  "creditAmount": "0",
                  "narration": "Trf -",
                  "narration2": "000020220922184759004006232830/O8000497719",
                  "remarks": "MOBILE/Al - Baqi Ventures",
                  "balance": "692.52"
              },
              {
                  "sn": "545",
                  "transactionId": "S84352060",
                  "transactionDate": "9/22/2022 12:00:00 AM",
                  "valueDate": "9/22/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee -",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/Al - Baqi Ventures",
                  "balance": "665.64"
              },
              {
                  "sn": "546",
                  "transactionId": "S84542939",
                  "transactionDate": "9/23/2022 12:00:00 AM",
                  "valueDate": "9/23/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "7000",
                  "narration": "100-Chukwuemeka Okeleke-Transfer from",
                  "narration2": "100026220923104844000025694082/I9728766760",
                  "remarks": "NIP/NIP/Chukwuemeka Okeleke",
                  "balance": "7665.64"
              },
              {
                  "sn": "547",
                  "transactionId": "S84641948",
                  "transactionDate": "9/23/2022 12:00:00 AM",
                  "valueDate": "9/23/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "137277.12",
                  "narration": "SEPTEMBER 2022 SALARY",
                  "narration2": "",
                  "remarks": "",
                  "balance": "144942.76"
              },
              {
                  "sn": "548",
                  "transactionId": "S84643273",
                  "transactionDate": "9/23/2022 12:00:00 AM",
                  "valueDate": "9/23/2022 12:00:00 AM",
                  "debitAmount": "1000",
                  "creditAmount": "0",
                  "narration": "SEPTEMBER 2022 ASSBIFI  DUES",
                  "narration2": "",
                  "remarks": "",
                  "balance": "143942.76"
              },
              {
                  "sn": "549",
                  "transactionId": "S84686627",
                  "transactionDate": "9/23/2022 12:00:00 AM",
                  "valueDate": "9/23/2022 12:00:00 AM",
                  "debitAmount": "90000",
                  "creditAmount": "0",
                  "narration": "Mob Trf self",
                  "narration2": "BLEND-LOCAL TRANSFER:-b8f50bf8-b025-4f26-a1cd-aa67",
                  "remarks": "5300017669 / 1300012264/102631",
                  "balance": "53942.76"
              },
              {
                  "sn": "550",
                  "transactionId": "S84687020",
                  "transactionDate": "9/23/2022 12:00:00 AM",
                  "valueDate": "9/23/2022 12:00:00 AM",
                  "debitAmount": "9000",
                  "creditAmount": "0",
                  "narration": "Mob Trf invinceable",
                  "narration2": "BLEND-LOCAL TRANSFER:-befcf68b-b130-4bf1-8655-0395",
                  "remarks": "5300017669 / 1001597495/198411",
                  "balance": "44942.76"
              },
              {
                  "sn": "551",
                  "transactionId": "S84719981",
                  "transactionDate": "9/23/2022 12:00:00 AM",
                  "valueDate": "9/23/2022 12:00:00 AM",
                  "debitAmount": "3000",
                  "creditAmount": "0",
                  "narration": "Mob Trf invinceable",
                  "narration2": "BLEND-LOCAL TRANSFER:-841b755e-f7bc-4061-ad1b-bf6b",
                  "remarks": "5300017669 / 5300010282/127427",
                  "balance": "41942.76"
              },
              {
                  "sn": "552",
                  "transactionId": "    M4449",
                  "transactionDate": "9/26/2022 12:00:00 AM",
                  "valueDate": "9/26/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "25000",
                  "narration": "0045673/CDP AKINYEMI DAVID .O",
                  "narration2": "",
                  "remarks": "",
                  "balance": "66942.76"
              },
              {
                  "sn": "553",
                  "transactionId": "S84893357",
                  "transactionDate": "9/26/2022 12:00:00 AM",
                  "valueDate": "9/24/2022 12:00:00 AM",
                  "debitAmount": "3500",
                  "creditAmount": "0",
                  "narration": "Trf - Subaru",
                  "narration2": "000020220924123658004006256201/O0118263570",
                  "remarks": "MOBILE/COLE OLAWALE",
                  "balance": "63442.76"
              },
              {
                  "sn": "554",
                  "transactionId": "S84893357",
                  "transactionDate": "9/26/2022 12:00:00 AM",
                  "valueDate": "9/24/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Subaru",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/COLE OLAWALE",
                  "balance": "63432.01"
              },
              {
                  "sn": "555",
                  "transactionId": "S84962930",
                  "transactionDate": "9/26/2022 12:00:00 AM",
                  "valueDate": "9/25/2022 12:00:00 AM",
                  "debitAmount": "9100",
                  "creditAmount": "0",
                  "narration": "Trf -",
                  "narration2": "000020220925095506004006266710/O8000497719",
                  "remarks": "MOBILE/Al - Baqi Ventures",
                  "balance": "54332.01"
              },
              {
                  "sn": "556",
                  "transactionId": "S84962930",
                  "transactionDate": "9/26/2022 12:00:00 AM",
                  "valueDate": "9/25/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee -",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/Al - Baqi Ventures",
                  "balance": "54305.13"
              },
              {
                  "sn": "557",
                  "transactionId": "S85003090",
                  "transactionDate": "9/26/2022 12:00:00 AM",
                  "valueDate": "9/25/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "Trf - Awele",
                  "narration2": "000020220925220545004006273895/O0130187278",
                  "remarks": "MOBILE/Umeadi Nkem Patricia",
                  "balance": "49305.13"
              },
              {
                  "sn": "558",
                  "transactionId": "S85003090",
                  "transactionDate": "9/26/2022 12:00:00 AM",
                  "valueDate": "9/25/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Awele",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/Umeadi Nkem Patricia",
                  "balance": "49294.38"
              },
              {
                  "sn": "559",
                  "transactionId": "S85045095",
                  "transactionDate": "9/26/2022 12:00:00 AM",
                  "valueDate": "9/26/2022 12:00:00 AM",
                  "debitAmount": "50",
                  "creditAmount": "0",
                  "narration": "STAMP DUTY CHARGE ON DEPOSIT:5300017669",
                  "narration2": "",
                  "remarks": "",
                  "balance": "49244.38"
              },
              {
                  "sn": "560",
                  "transactionId": "S85200932",
                  "transactionDate": "9/26/2022 12:00:00 AM",
                  "valueDate": "9/26/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "25000",
                  "narration": "000-OYEBOLA RAJI-Monthly Contribution",
                  "narration2": "000015220926163902003037396284/I2005518283",
                  "remarks": "NIP/NIP/OYEBOLA RAJI",
                  "balance": "74244.38"
              },
              {
                  "sn": "561",
                  "transactionId": "S85200932",
                  "transactionDate": "9/26/2022 12:00:00 AM",
                  "valueDate": "9/26/2022 12:00:00 AM",
                  "debitAmount": "50",
                  "creditAmount": "0",
                  "narration": "STAMP DUTY CHARGE ON DEPOSIT: 5300017669",
                  "narration2": "FEE EXEMPT",
                  "remarks": "NIP/NIP/OYEBOLA RAJI",
                  "balance": "74194.38"
              },
              {
                  "sn": "562",
                  "transactionId": "S85295254",
                  "transactionDate": "9/26/2022 12:00:00 AM",
                  "valueDate": "9/26/2022 12:00:00 AM",
                  "debitAmount": "1500",
                  "creditAmount": "0",
                  "narration": "Trf - Meat",
                  "narration2": "000020220926192322004006285130/O3053748992",
                  "remarks": "MOBILE/SALAUDEEN ABAS KOLAWOLE",
                  "balance": "72694.38"
              },
              {
                  "sn": "563",
                  "transactionId": "S85295254",
                  "transactionDate": "9/26/2022 12:00:00 AM",
                  "valueDate": "9/26/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Meat",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/SALAUDEEN ABAS KOLAWOLE",
                  "balance": "72683.63"
              },
              {
                  "sn": "564",
                  "transactionId": "S85495277",
                  "transactionDate": "9/27/2022 12:00:00 AM",
                  "valueDate": "9/27/2022 12:00:00 AM",
                  "debitAmount": "60000",
                  "creditAmount": "0",
                  "narration": "web:vince",
                  "narration2": "",
                  "remarks": "5300017669/9123034",
                  "balance": "12683.63"
              },
              {
                  "sn": "565",
                  "transactionId": "S85497130",
                  "transactionDate": "9/27/2022 12:00:00 AM",
                  "valueDate": "9/27/2022 12:00:00 AM",
                  "debitAmount": "10000",
                  "creditAmount": "0",
                  "narration": "web:vince",
                  "narration2": "",
                  "remarks": "5300017669/9185334",
                  "balance": "2683.63"
              },
              {
                  "sn": "566",
                  "transactionId": "S85792534",
                  "transactionDate": "9/27/2022 12:00:00 AM",
                  "valueDate": "9/27/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "25000",
                  "narration": "Mob Trf Ajo",
                  "narration2": "BLEND-LOCAL TRANSFER:-90c92c86-0200-4536-a979-c7fe",
                  "remarks": "1001825738 / 5300017669/673209",
                  "balance": "27683.63"
              },
              {
                  "sn": "567",
                  "transactionId": "S85792534",
                  "transactionDate": "9/27/2022 12:00:00 AM",
                  "valueDate": "9/27/2022 12:00:00 AM",
                  "debitAmount": "50",
                  "creditAmount": "0",
                  "narration": "STAMP DUTY CHARGE ON DEPOSIT: 5300017669",
                  "narration2": "FEE EXEMPT",
                  "remarks": "1001825738 / 5300017669/673209",
                  "balance": "27633.63"
              },
              {
                  "sn": "568",
                  "transactionId": "S85817858",
                  "transactionDate": "9/27/2022 12:00:00 AM",
                  "valueDate": "9/27/2022 12:00:00 AM",
                  "debitAmount": "5100",
                  "creditAmount": "0",
                  "narration": "Trf - pos",
                  "narration2": "000020220927212927004006298681/O1531570509",
                  "remarks": "MOBILE/MADINA ABIODUN ADEOGUN",
                  "balance": "22533.63"
              },
              {
                  "sn": "569",
                  "transactionId": "S85817858",
                  "transactionDate": "9/27/2022 12:00:00 AM",
                  "valueDate": "9/27/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - pos",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/MADINA ABIODUN ADEOGUN",
                  "balance": "22506.75"
              },
              {
                  "sn": "570",
                  "transactionId": "    M4215",
                  "transactionDate": "9/28/2022 12:00:00 AM",
                  "valueDate": "9/28/2022 12:00:00 AM",
                  "debitAmount": "1500",
                  "creditAmount": "0",
                  "narration": "STAFF WELFARE DEDUCTION FOR SEPTEMBER 2022",
                  "narration2": "",
                  "remarks": "",
                  "balance": "21006.75"
              },
              {
                  "sn": "571",
                  "transactionId": "S86282866",
                  "transactionDate": "9/28/2022 12:00:00 AM",
                  "valueDate": "9/28/2022 12:00:00 AM",
                  "debitAmount": "1500",
                  "creditAmount": "0",
                  "narration": "Trf - cloth",
                  "narration2": "000020220928182635004006310556/O2071299105",
                  "remarks": "MOBILE/ADENIYI MUKAILA",
                  "balance": "19506.75"
              },
              {
                  "sn": "572",
                  "transactionId": "S86282866",
                  "transactionDate": "9/28/2022 12:00:00 AM",
                  "valueDate": "9/28/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - cloth",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/ADENIYI MUKAILA",
                  "balance": "19496"
              },
              {
                  "sn": "573",
                  "transactionId": "S86451285",
                  "transactionDate": "9/29/2022 12:00:00 AM",
                  "valueDate": "9/29/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "25000",
                  "narration": "Mob Trf mj- ajo",
                  "narration2": "BLEND-LOCAL TRANSFER:-c90e9962-2b3b-4945-80a7-8afe",
                  "remarks": "1001803864 / 5300017669/334288",
                  "balance": "44496"
              },
              {
                  "sn": "574",
                  "transactionId": "S86451285",
                  "transactionDate": "9/29/2022 12:00:00 AM",
                  "valueDate": "9/29/2022 12:00:00 AM",
                  "debitAmount": "50",
                  "creditAmount": "0",
                  "narration": "STAMP DUTY CHARGE ON DEPOSIT: 5300017669",
                  "narration2": "FEE EXEMPT",
                  "remarks": "1001803864 / 5300017669/334288",
                  "balance": "44446"
              },
              {
                  "sn": "575",
                  "transactionId": "S86650468",
                  "transactionDate": "9/29/2022 12:00:00 AM",
                  "valueDate": "9/29/2022 12:00:00 AM",
                  "debitAmount": "9000",
                  "creditAmount": "0",
                  "narration": "Mob Trf invinceable",
                  "narration2": "BLEND-LOCAL TRANSFER:-d5b16490-5d04-49ca-805f-8c87",
                  "remarks": "5300017669 / 1001597495/179178",
                  "balance": "35446"
              },
              {
                  "sn": "576",
                  "transactionId": "S87316047",
                  "transactionDate": "9/30/2022 12:00:00 AM",
                  "valueDate": "9/30/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "Mob Trf invinceable",
                  "narration2": "BLEND-LOCAL TRANSFER:-a7168216-8a20-4a45-9e47-2545",
                  "remarks": "5300017669 / 1001597495/170588",
                  "balance": "30446"
              },
              {
                  "sn": "577",
                  "transactionId": "   M12485",
                  "transactionDate": "10/4/2022 12:00:00 AM",
                  "valueDate": "10/4/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "10000",
                  "narration": "0028831 CDB OKELEKE VINCENT",
                  "narration2": "",
                  "remarks": "",
                  "balance": "40446"
              },
              {
                  "sn": "578",
                  "transactionId": "S87962571",
                  "transactionDate": "10/4/2022 12:00:00 AM",
                  "valueDate": "10/2/2022 12:00:00 AM",
                  "debitAmount": "200",
                  "creditAmount": "0",
                  "narration": "Mob Recharge-Online: Recharge <MTN> <07033671485>",
                  "narration2": "BLEND-MOBILERECHARGE1-638002822303090534",
                  "remarks": "BLEND / BLD/478846846",
                  "balance": "40246"
              },
              {
                  "sn": "579",
                  "transactionId": "S87962830",
                  "transactionDate": "10/4/2022 12:00:00 AM",
                  "valueDate": "10/2/2022 12:00:00 AM",
                  "debitAmount": "10000",
                  "creditAmount": "0",
                  "narration": "Trf - Awele",
                  "narration2": "000020221002055536004006365805/O0028116908",
                  "remarks": "MOBILE/CHUKWUEMEKA  OKELEKE",
                  "balance": "30246"
              },
              {
                  "sn": "580",
                  "transactionId": "S87962830",
                  "transactionDate": "10/4/2022 12:00:00 AM",
                  "valueDate": "10/2/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Awele",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/CHUKWUEMEKA  OKELEKE",
                  "balance": "30219.12"
              },
              {
                  "sn": "581",
                  "transactionId": "S87963081",
                  "transactionDate": "10/4/2022 12:00:00 AM",
                  "valueDate": "10/2/2022 12:00:00 AM",
                  "debitAmount": "1000",
                  "creditAmount": "0",
                  "narration": "Mob Pymt obile: Payment For <Eko Electricity Dist",
                  "narration2": "BLEND-NTBANKI00000001-638002844085262102",
                  "remarks": "5300017669 / BILLER's NAME/676",
                  "balance": "29219.12"
              },
              {
                  "sn": "582",
                  "transactionId": "S87963081",
                  "transactionDate": "10/4/2022 12:00:00 AM",
                  "valueDate": "10/2/2022 12:00:00 AM",
                  "debitAmount": "100",
                  "creditAmount": "0",
                  "narration": "Mob Pymt Fee obile: Payment For <Eko Electricity D",
                  "narration2": "FEE EXEMPT",
                  "remarks": "5300017669 / BILLER's NAME/676",
                  "balance": "29119.12"
              },
              {
                  "sn": "583",
                  "transactionId": "S87966593",
                  "transactionDate": "10/4/2022 12:00:00 AM",
                  "valueDate": "10/2/2022 12:00:00 AM",
                  "debitAmount": "9100",
                  "creditAmount": "0",
                  "narration": "Trf - invinceable",
                  "narration2": "000020221002080015004006366755/O8000497719",
                  "remarks": "MOBILE/Al - Baqi Ventures",
                  "balance": "20019.12"
              },
              {
                  "sn": "584",
                  "transactionId": "S87966593",
                  "transactionDate": "10/4/2022 12:00:00 AM",
                  "valueDate": "10/2/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - invinceable",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/Al - Baqi Ventures",
                  "balance": "19992.24"
              },
              {
                  "sn": "585",
                  "transactionId": "S88005948",
                  "transactionDate": "10/4/2022 12:00:00 AM",
                  "valueDate": "10/2/2022 12:00:00 AM",
                  "debitAmount": "1500",
                  "creditAmount": "0",
                  "narration": "Trf - Olembe",
                  "narration2": "000020221002200550004006375818/O5330323505",
                  "remarks": "MOBILE/IKERE PETER",
                  "balance": "18492.24"
              },
              {
                  "sn": "586",
                  "transactionId": "S88005948",
                  "transactionDate": "10/4/2022 12:00:00 AM",
                  "valueDate": "10/2/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Olembe",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/IKERE PETER",
                  "balance": "18481.49"
              },
              {
                  "sn": "587",
                  "transactionId": "S88026121",
                  "transactionDate": "10/4/2022 12:00:00 AM",
                  "valueDate": "10/3/2022 12:00:00 AM",
                  "debitAmount": "3000",
                  "creditAmount": "0",
                  "narration": "Trf - Awele",
                  "narration2": "000020221003093901004006380478/O0130187278",
                  "remarks": "MOBILE/Umeadi Nkem Patricia",
                  "balance": "15481.49"
              },
              {
                  "sn": "588",
                  "transactionId": "S88026121",
                  "transactionDate": "10/4/2022 12:00:00 AM",
                  "valueDate": "10/3/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Awele",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/Umeadi Nkem Patricia",
                  "balance": "15470.74"
              },
              {
                  "sn": "589",
                  "transactionId": "S88038822",
                  "transactionDate": "10/4/2022 12:00:00 AM",
                  "valueDate": "10/3/2022 12:00:00 AM",
                  "debitAmount": "2000",
                  "creditAmount": "0",
                  "narration": "Trf - Olembe",
                  "narration2": "000020221003123531004006383406/O2939464019",
                  "remarks": "MOBILE/OTABOR BLESSING UCHE",
                  "balance": "13470.74"
              },
              {
                  "sn": "590",
                  "transactionId": "S88038822",
                  "transactionDate": "10/4/2022 12:00:00 AM",
                  "valueDate": "10/3/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Olembe",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/OTABOR BLESSING UCHE",
                  "balance": "13459.99"
              },
              {
                  "sn": "591",
                  "transactionId": "S88132912",
                  "transactionDate": "10/4/2022 12:00:00 AM",
                  "valueDate": "10/4/2022 12:00:00 AM",
                  "debitAmount": "2000",
                  "creditAmount": "0",
                  "narration": "Trf - Rhymes Jersey pay",
                  "narration2": "000020221004144319004006401127/O2389765970",
                  "remarks": "MOBILE/KENNETH CHUKWUNENYE OKO",
                  "balance": "11459.99"
              },
              {
                  "sn": "592",
                  "transactionId": "S88132912",
                  "transactionDate": "10/4/2022 12:00:00 AM",
                  "valueDate": "10/4/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Rhymes Jersey pay",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/KENNETH CHUKWUNENYE OKO",
                  "balance": "11449.24"
              },
              {
                  "sn": "593",
                  "transactionId": "S88378558",
                  "transactionDate": "10/4/2022 12:00:00 AM",
                  "valueDate": "10/4/2022 12:00:00 AM",
                  "debitAmount": "2200",
                  "creditAmount": "0",
                  "narration": "Trf - Olembe",
                  "narration2": "000020221004204939004006407210/O0026600059",
                  "remarks": "MOBILE/NKWOPARA CHIDINMA",
                  "balance": "9249.24"
              },
              {
                  "sn": "594",
                  "transactionId": "S88378558",
                  "transactionDate": "10/4/2022 12:00:00 AM",
                  "valueDate": "10/4/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Olembe",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/NKWOPARA CHIDINMA",
                  "balance": "9238.49"
              },
              {
                  "sn": "595",
                  "transactionId": "S89107331",
                  "transactionDate": "10/6/2022 12:00:00 AM",
                  "valueDate": "10/6/2022 12:00:00 AM",
                  "debitAmount": "2500",
                  "creditAmount": "0",
                  "narration": "Trf - belt",
                  "narration2": "000020221006180035004006438615/O1459791888",
                  "remarks": "MOBILE/JOEBEST AUTO MEGA COMPA",
                  "balance": "6738.49"
              },
              {
                  "sn": "596",
                  "transactionId": "S89107331",
                  "transactionDate": "10/6/2022 12:00:00 AM",
                  "valueDate": "10/6/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - belt",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/JOEBEST AUTO MEGA COMPA",
                  "balance": "6727.74"
              },
              {
                  "sn": "597",
                  "transactionId": "S89356740",
                  "transactionDate": "10/7/2022 12:00:00 AM",
                  "valueDate": "10/7/2022 12:00:00 AM",
                  "debitAmount": "6000",
                  "creditAmount": "0",
                  "narration": "Trf - belt Nd Coolant",
                  "narration2": "000020221007102247004006446692/O1459791888",
                  "remarks": "MOBILE/JOEBEST AUTO MEGA COMPA",
                  "balance": "727.74"
              },
              {
                  "sn": "598",
                  "transactionId": "S89356740",
                  "transactionDate": "10/7/2022 12:00:00 AM",
                  "valueDate": "10/7/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - belt Nd Coolant",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/JOEBEST AUTO MEGA COMPA",
                  "balance": "700.86"
              },
              {
                  "sn": "599",
                  "transactionId": "S89391582",
                  "transactionDate": "10/7/2022 12:00:00 AM",
                  "valueDate": "10/7/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "20000",
                  "narration": "Mob Trf self",
                  "narration2": "BLEND-LOCAL TRANSFER:-930b3e20-d0e8-4fc0-b564-1967",
                  "remarks": "1300012264 / 5300017669/669580",
                  "balance": "20700.86"
              },
              {
                  "sn": "600",
                  "transactionId": "S89393107",
                  "transactionDate": "10/7/2022 12:00:00 AM",
                  "valueDate": "10/7/2022 12:00:00 AM",
                  "debitAmount": "7000",
                  "creditAmount": "0",
                  "narration": "Trf - Alternator",
                  "narration2": "000020221007121718004006448540/O1622263150",
                  "remarks": "MOBILE/EMMANUEL OLANREWAJU OYE",
                  "balance": "13700.86"
              },
              {
                  "sn": "601",
                  "transactionId": "S89393107",
                  "transactionDate": "10/7/2022 12:00:00 AM",
                  "valueDate": "10/7/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Alternator",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/EMMANUEL OLANREWAJU OYE",
                  "balance": "13673.98"
              },
              {
                  "sn": "602",
                  "transactionId": "S89551386",
                  "transactionDate": "10/7/2022 12:00:00 AM",
                  "valueDate": "10/7/2022 12:00:00 AM",
                  "debitAmount": "2500",
                  "creditAmount": "0",
                  "narration": "Trf - welding",
                  "narration2": "000020221007164209004006453188/O1622263150",
                  "remarks": "MOBILE/EMMANUEL OLANREWAJU OYE",
                  "balance": "11173.98"
              },
              {
                  "sn": "603",
                  "transactionId": "S89551386",
                  "transactionDate": "10/7/2022 12:00:00 AM",
                  "valueDate": "10/7/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - welding",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/EMMANUEL OLANREWAJU OYE",
                  "balance": "11163.23"
              },
              {
                  "sn": "604",
                  "transactionId": "S89592143",
                  "transactionDate": "10/7/2022 12:00:00 AM",
                  "valueDate": "10/7/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "Trf - belt",
                  "narration2": "000020221007183428004006455274/O1459791888",
                  "remarks": "MOBILE/JOEBEST AUTO MEGA COMPA",
                  "balance": "6163.23"
              },
              {
                  "sn": "605",
                  "transactionId": "S89592143",
                  "transactionDate": "10/7/2022 12:00:00 AM",
                  "valueDate": "10/7/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - belt",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/JOEBEST AUTO MEGA COMPA",
                  "balance": "6152.48"
              },
              {
                  "sn": "606",
                  "transactionId": "S89609775",
                  "transactionDate": "10/7/2022 12:00:00 AM",
                  "valueDate": "10/7/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "Trf - roller",
                  "narration2": "000020221007193947004006456319/O1622263150",
                  "remarks": "MOBILE/EMMANUEL OLANREWAJU OYE",
                  "balance": "1152.48"
              },
              {
                  "sn": "607",
                  "transactionId": "S89609775",
                  "transactionDate": "10/7/2022 12:00:00 AM",
                  "valueDate": "10/7/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - roller",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/EMMANUEL OLANREWAJU OYE",
                  "balance": "1141.73"
              },
              {
                  "sn": "608",
                  "transactionId": "S89792068",
                  "transactionDate": "10/11/2022 12:00:00 AM",
                  "valueDate": "10/8/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "10000",
                  "narration": "Mob Trf self",
                  "narration2": "BLEND-LOCAL TRANSFER:-e538f1b8-8370-4167-b0ac-702e",
                  "remarks": "1300012264 / 5300017669/304026",
                  "balance": "11141.73"
              },
              {
                  "sn": "609",
                  "transactionId": "S89823985",
                  "transactionDate": "10/11/2022 12:00:00 AM",
                  "valueDate": "10/8/2022 12:00:00 AM",
                  "debitAmount": "5100",
                  "creditAmount": "0",
                  "narration": "Trf - pos",
                  "narration2": "000020221008214813004006474271/O9552777905",
                  "remarks": "MOBILE/TORIGIN STORE",
                  "balance": "6041.73"
              },
              {
                  "sn": "610",
                  "transactionId": "S89823985",
                  "transactionDate": "10/11/2022 12:00:00 AM",
                  "valueDate": "10/8/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - pos",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/TORIGIN STORE",
                  "balance": "6014.85"
              },
              {
                  "sn": "611",
                  "transactionId": "S89824741",
                  "transactionDate": "10/11/2022 12:00:00 AM",
                  "valueDate": "10/8/2022 12:00:00 AM",
                  "debitAmount": "3500",
                  "creditAmount": "0",
                  "narration": "Mob Recharge-Online: Recharge <MTN> <07033671485>",
                  "narration2": "BLEND-MOBILERECHARGE1-638008605454042876",
                  "remarks": "BLEND / BLD/186269350",
                  "balance": "2514.85"
              },
              {
                  "sn": "612",
                  "transactionId": "S89854166",
                  "transactionDate": "10/11/2022 12:00:00 AM",
                  "valueDate": "10/9/2022 12:00:00 AM",
                  "debitAmount": "2000",
                  "creditAmount": "0",
                  "narration": "Trf - food",
                  "narration2": "000020221009181008004006484117/O0130187278",
                  "remarks": "MOBILE/Umeadi Nkem Patricia",
                  "balance": "514.85"
              },
              {
                  "sn": "613",
                  "transactionId": "S89854166",
                  "transactionDate": "10/11/2022 12:00:00 AM",
                  "valueDate": "10/9/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - food",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/Umeadi Nkem Patricia",
                  "balance": "504.1"
              },
              {
                  "sn": "614",
                  "transactionId": "S90126492",
                  "transactionDate": "10/12/2022 12:00:00 AM",
                  "valueDate": "10/12/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "8000",
                  "narration": "INW self",
                  "narration2": "Octiplus Digital Bank Mobile Transaction",
                  "remarks": "OTS45951/638011682644",
                  "balance": "8504.1"
              },
              {
                  "sn": "615",
                  "transactionId": "S90376552",
                  "transactionDate": "10/12/2022 12:00:00 AM",
                  "valueDate": "10/12/2022 12:00:00 AM",
                  "debitAmount": "3000",
                  "creditAmount": "0",
                  "narration": "Trf - Awele",
                  "narration2": "000020221012195728004006530669/O0130187278",
                  "remarks": "MOBILE/Umeadi Nkem Patricia",
                  "balance": "5504.1"
              },
              {
                  "sn": "616",
                  "transactionId": "S90376552",
                  "transactionDate": "10/12/2022 12:00:00 AM",
                  "valueDate": "10/12/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Awele",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/Umeadi Nkem Patricia",
                  "balance": "5493.35"
              },
              {
                  "sn": "617",
                  "transactionId": "S91700428",
                  "transactionDate": "10/17/2022 12:00:00 AM",
                  "valueDate": "10/16/2022 12:00:00 AM",
                  "debitAmount": "5100",
                  "creditAmount": "0",
                  "narration": "Trf - pos",
                  "narration2": "000020221016182426002006589193/O8000497719",
                  "remarks": "INTERNETBANKING/AL - BAQI VENT",
                  "balance": "393.35"
              },
              {
                  "sn": "618",
                  "transactionId": "S91700428",
                  "transactionDate": "10/17/2022 12:00:00 AM",
                  "valueDate": "10/16/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - pos",
                  "narration2": "FEE EXEMPT",
                  "remarks": "INTERNETBANKING/AL - BAQI VENT",
                  "balance": "366.47"
              },
              {
                  "sn": "619",
                  "transactionId": "S91797561",
                  "transactionDate": "10/17/2022 12:00:00 AM",
                  "valueDate": "10/17/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "20000",
                  "narration": "Mob Trf self",
                  "narration2": "BLEND-LOCAL TRANSFER:-6185cdd9-2a02-48c4-b124-5729",
                  "remarks": "1300012264 / 5300017669/149008",
                  "balance": "20366.47"
              },
              {
                  "sn": "620",
                  "transactionId": "S91797955",
                  "transactionDate": "10/17/2022 12:00:00 AM",
                  "valueDate": "10/17/2022 12:00:00 AM",
                  "debitAmount": "2000",
                  "creditAmount": "0",
                  "narration": "Trf - Olembe-Bolaji",
                  "narration2": "000020221017184854004006603584/O0123243097",
                  "remarks": "MOBILE/MOSES BOLAJI OLAYINKA",
                  "balance": "18366.47"
              },
              {
                  "sn": "621",
                  "transactionId": "S91797955",
                  "transactionDate": "10/17/2022 12:00:00 AM",
                  "valueDate": "10/17/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Olembe-Bolaji",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/MOSES BOLAJI OLAYINKA",
                  "balance": "18355.72"
              },
              {
                  "sn": "622",
                  "transactionId": "S91813162",
                  "transactionDate": "10/17/2022 12:00:00 AM",
                  "valueDate": "10/17/2022 12:00:00 AM",
                  "debitAmount": "2000",
                  "creditAmount": "0",
                  "narration": "Trf - Subaru guy",
                  "narration2": "000020221017211327004006605631/O0118263570",
                  "remarks": "MOBILE/COLE OLAWALE",
                  "balance": "16355.72"
              },
              {
                  "sn": "623",
                  "transactionId": "S91813162",
                  "transactionDate": "10/17/2022 12:00:00 AM",
                  "valueDate": "10/17/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Subaru guy",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/COLE OLAWALE",
                  "balance": "16344.97"
              },
              {
                  "sn": "624",
                  "transactionId": "S92180638",
                  "transactionDate": "10/19/2022 12:00:00 AM",
                  "valueDate": "10/19/2022 12:00:00 AM",
                  "debitAmount": "2100",
                  "creditAmount": "0",
                  "narration": "Trf - Awele",
                  "narration2": "000020221019050606004006619840/O0130187278",
                  "remarks": "MOBILE/Umeadi Nkem Patricia",
                  "balance": "14244.97"
              },
              {
                  "sn": "625",
                  "transactionId": "S92180638",
                  "transactionDate": "10/19/2022 12:00:00 AM",
                  "valueDate": "10/19/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Awele",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/Umeadi Nkem Patricia",
                  "balance": "14234.22"
              },
              {
                  "sn": "626",
                  "transactionId": "S92548119",
                  "transactionDate": "10/19/2022 12:00:00 AM",
                  "valueDate": "10/19/2022 12:00:00 AM",
                  "debitAmount": "9000",
                  "creditAmount": "0",
                  "narration": "Mob Trf invinceable",
                  "narration2": "BLEND-LOCAL TRANSFER:-ab0e78f0-b8d8-4f45-aca6-057f",
                  "remarks": "5300017669 / 1001597495/210895",
                  "balance": "5234.22"
              },
              {
                  "sn": "627",
                  "transactionId": "S92824036",
                  "transactionDate": "10/20/2022 12:00:00 AM",
                  "valueDate": "10/20/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "Mob Trf invinceable",
                  "narration2": "BLEND-LOCAL TRANSFER:-c859ddab-f571-427b-839a-dc5a",
                  "remarks": "5300017669 / 1001597495/163636",
                  "balance": "234.22"
              },
              {
                  "sn": "628",
                  "transactionId": "S94305083",
                  "transactionDate": "10/24/2022 12:00:00 AM",
                  "valueDate": "10/24/2022 12:00:00 AM",
                  "debitAmount": "1000",
                  "creditAmount": "0",
                  "narration": "OCTOBER 2022 ASSBIFI  DUES",
                  "narration2": "",
                  "remarks": "",
                  "balance": "-765.78"
              },
              {
                  "sn": "629",
                  "transactionId": "S94305742",
                  "transactionDate": "10/24/2022 12:00:00 AM",
                  "valueDate": "10/24/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "137277.12",
                  "narration": "OCTOBER 2022 SALARY",
                  "narration2": "",
                  "remarks": "",
                  "balance": "136511.34"
              },
              {
                  "sn": "630",
                  "transactionId": "S94312726",
                  "transactionDate": "10/24/2022 12:00:00 AM",
                  "valueDate": "10/24/2022 12:00:00 AM",
                  "debitAmount": "50000",
                  "creditAmount": "0",
                  "narration": "Mob Trf Vincent ajo",
                  "narration2": "BLEND-LOCAL TRANSFER:-fcff85fb-4b74-4450-8c84-c142",
                  "remarks": "5300017669 / 1001825738/174340",
                  "balance": "86511.34"
              },
              {
                  "sn": "631",
                  "transactionId": "S94348452",
                  "transactionDate": "10/25/2022 12:00:00 AM",
                  "valueDate": "10/25/2022 12:00:00 AM",
                  "debitAmount": "3000",
                  "creditAmount": "0",
                  "narration": "web:vince",
                  "narration2": "",
                  "remarks": "5300017669/8211934",
                  "balance": "83511.34"
              },
              {
                  "sn": "632",
                  "transactionId": "S94348632",
                  "transactionDate": "10/25/2022 12:00:00 AM",
                  "valueDate": "10/25/2022 12:00:00 AM",
                  "debitAmount": "3000",
                  "creditAmount": "0",
                  "narration": "web:vince",
                  "narration2": "",
                  "remarks": "5300017669/8240734",
                  "balance": "80511.34"
              },
              {
                  "sn": "633",
                  "transactionId": "S94903963",
                  "transactionDate": "10/26/2022 12:00:00 AM",
                  "valueDate": "10/26/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "2000",
                  "narration": "web:trf",
                  "narration2": "",
                  "remarks": "5300013764/1595734",
                  "balance": "82511.34"
              },
              {
                  "sn": "634",
                  "transactionId": "     M635",
                  "transactionDate": "10/27/2022 12:00:00 AM",
                  "valueDate": "10/27/2022 12:00:00 AM",
                  "debitAmount": "1500",
                  "creditAmount": "0",
                  "narration": "SEPTEMBER WELFARE CONTRIBUTION",
                  "narration2": "",
                  "remarks": "",
                  "balance": "81011.34"
              },
              {
                  "sn": "635",
                  "transactionId": "S95302797",
                  "transactionDate": "10/27/2022 12:00:00 AM",
                  "valueDate": "10/27/2022 12:00:00 AM",
                  "debitAmount": "1500",
                  "creditAmount": "0",
                  "narration": "Mob Recharge-Online: Recharge <MTN> <07051456716>",
                  "narration2": "BLEND-MOBILERECHARGE1-638024607448214159",
                  "remarks": "BLEND / BLD/1400855637",
                  "balance": "79511.34"
              },
              {
                  "sn": "636",
                  "transactionId": "S95304417",
                  "transactionDate": "10/27/2022 12:00:00 AM",
                  "valueDate": "10/27/2022 12:00:00 AM",
                  "debitAmount": "5000",
                  "creditAmount": "0",
                  "narration": "Trf - self",
                  "narration2": "000020221027104936004006732962/O0106161053",
                  "remarks": "MOBILE/Okeleke Vincent Awele",
                  "balance": "74511.34"
              },
              {
                  "sn": "637",
                  "transactionId": "S95304417",
                  "transactionDate": "10/27/2022 12:00:00 AM",
                  "valueDate": "10/27/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - self",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/Okeleke Vincent Awele",
                  "balance": "74500.59"
              },
              {
                  "sn": "638",
                  "transactionId": "S95391046",
                  "transactionDate": "10/27/2022 12:00:00 AM",
                  "valueDate": "10/27/2022 12:00:00 AM",
                  "debitAmount": "8000",
                  "creditAmount": "0",
                  "narration": "Mob Trf invinceable",
                  "narration2": "BLEND-LOCAL TRANSFER:-2ca24da0-7be2-4f35-b721-ec9b",
                  "remarks": "5300017669 / 1001684720/196094",
                  "balance": "66500.59"
              },
              {
                  "sn": "639",
                  "transactionId": "S95391468",
                  "transactionDate": "10/27/2022 12:00:00 AM",
                  "valueDate": "10/27/2022 12:00:00 AM",
                  "debitAmount": "8000",
                  "creditAmount": "0",
                  "narration": "Mob Trf invinceable",
                  "narration2": "BLEND-LOCAL TRANSFER:-2ecaa55f-8444-4840-90b1-f884",
                  "remarks": "5300017669 / 1001684720/201990",
                  "balance": "58500.59"
              },
              {
                  "sn": "640",
                  "transactionId": "S95406520",
                  "transactionDate": "10/27/2022 12:00:00 AM",
                  "valueDate": "10/27/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "8000",
                  "narration": "Mob Trf Refund",
                  "narration2": "BLEND-LOCAL TRANSFER:-4a7f06da-471c-40e8-9f51-79a5",
                  "remarks": "1001684720 / 5300017669/178801",
                  "balance": "66500.59"
              },
              {
                  "sn": "641",
                  "transactionId": "S96022079",
                  "transactionDate": "10/28/2022 12:00:00 AM",
                  "valueDate": "10/28/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "1500",
                  "narration": "rev 271022 638024607448214159",
                  "narration2": "",
                  "remarks": "",
                  "balance": "68000.59"
              },
              {
                  "sn": "642",
                  "transactionId": "S96030088",
                  "transactionDate": "10/28/2022 12:00:00 AM",
                  "valueDate": "10/28/2022 12:00:00 AM",
                  "debitAmount": "66000",
                  "creditAmount": "0",
                  "narration": "Trf - invinceable",
                  "narration2": "000020221028151545004006754009/O5600121503",
                  "remarks": "MOBILE/RAYZVEN GLOBAL RESOURCE",
                  "balance": "2000.59"
              },
              {
                  "sn": "643",
                  "transactionId": "S96030088",
                  "transactionDate": "10/28/2022 12:00:00 AM",
                  "valueDate": "10/28/2022 12:00:00 AM",
                  "debitAmount": "53.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - invinceable",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/RAYZVEN GLOBAL RESOURCE",
                  "balance": "1946.84"
              },
              {
                  "sn": "644",
                  "transactionId": "S96525283",
                  "transactionDate": "10/31/2022 12:00:00 AM",
                  "valueDate": "10/29/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "10000",
                  "narration": "Mob Trf self",
                  "narration2": "BLEND-LOCAL TRANSFER:-52f23227-8bc7-46ca-adea-820d",
                  "remarks": "1300012264 / 5300017669/134742",
                  "balance": "11946.84"
              },
              {
                  "sn": "645",
                  "transactionId": "S96525756",
                  "transactionDate": "10/31/2022 12:00:00 AM",
                  "valueDate": "10/29/2022 12:00:00 AM",
                  "debitAmount": "4000",
                  "creditAmount": "0",
                  "narration": "Trf - exhaust",
                  "narration2": "000020221029095304004006765101/O1622263150",
                  "remarks": "MOBILE/EMMANUEL OLANREWAJU OYE",
                  "balance": "7946.84"
              },
              {
                  "sn": "646",
                  "transactionId": "S96525756",
                  "transactionDate": "10/31/2022 12:00:00 AM",
                  "valueDate": "10/29/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - exhaust",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/EMMANUEL OLANREWAJU OYE",
                  "balance": "7936.09"
              },
              {
                  "sn": "647",
                  "transactionId": "S96654769",
                  "transactionDate": "10/31/2022 12:00:00 AM",
                  "valueDate": "10/29/2022 12:00:00 AM",
                  "debitAmount": "1800",
                  "creditAmount": "0",
                  "narration": "Trf - Plumber",
                  "narration2": "000020221029180603004006773291/O0807282896",
                  "remarks": "MOBILE/UZOCHUKWU UCHECHUKWU UZ",
                  "balance": "6136.09"
              },
              {
                  "sn": "648",
                  "transactionId": "S96654769",
                  "transactionDate": "10/31/2022 12:00:00 AM",
                  "valueDate": "10/29/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Plumber",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/UZOCHUKWU UCHECHUKWU UZ",
                  "balance": "6125.34"
              },
              {
                  "sn": "649",
                  "transactionId": "S96785472",
                  "transactionDate": "10/31/2022 12:00:00 AM",
                  "valueDate": "10/30/2022 12:00:00 AM",
                  "debitAmount": "2500",
                  "creditAmount": "0",
                  "narration": "Trf - meat",
                  "narration2": "000020221030053818004006777476/O3051847612",
                  "remarks": "MOBILE/TAOFIQATH YETUNDE KHAMO",
                  "balance": "3625.34"
              },
              {
                  "sn": "650",
                  "transactionId": "S96785472",
                  "transactionDate": "10/31/2022 12:00:00 AM",
                  "valueDate": "10/30/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - meat",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/TAOFIQATH YETUNDE KHAMO",
                  "balance": "3614.59"
              },
              {
                  "sn": "651",
                  "transactionId": "S96843976",
                  "transactionDate": "10/31/2022 12:00:00 AM",
                  "valueDate": "10/30/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "20000",
                  "narration": "Mob Trf self",
                  "narration2": "BLEND-LOCAL TRANSFER:-d6ffb171-359c-4d9d-9e3c-cc7d",
                  "remarks": "1300012264 / 5300017669/849760",
                  "balance": "23614.59"
              },
              {
                  "sn": "652",
                  "transactionId": "S96844082",
                  "transactionDate": "10/31/2022 12:00:00 AM",
                  "valueDate": "10/30/2022 12:00:00 AM",
                  "debitAmount": "9000",
                  "creditAmount": "0",
                  "narration": "Trf - heritage",
                  "narration2": "000020221030104543004006780378/O8000497719",
                  "remarks": "MOBILE/Al - Baqi Ventures",
                  "balance": "14614.59"
              },
              {
                  "sn": "653",
                  "transactionId": "S96844082",
                  "transactionDate": "10/31/2022 12:00:00 AM",
                  "valueDate": "10/30/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - heritage",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/Al - Baqi Ventures",
                  "balance": "14587.71"
              },
              {
                  "sn": "654",
                  "transactionId": "S96916000",
                  "transactionDate": "10/31/2022 12:00:00 AM",
                  "valueDate": "10/31/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "50000",
                  "narration": "web:for generator",
                  "narration2": "",
                  "remarks": "5300010282/11094634",
                  "balance": "64587.71"
              },
              {
                  "sn": "655",
                  "transactionId": "S97117654",
                  "transactionDate": "10/31/2022 12:00:00 AM",
                  "valueDate": "10/31/2022 12:00:00 AM",
                  "debitAmount": "2100",
                  "creditAmount": "0",
                  "narration": "Trf - vince",
                  "narration2": "000020221031164209002006799286/O0021572251",
                  "remarks": "INTERNETBANKING/OGUNSUSI MUSSA",
                  "balance": "62487.71"
              },
              {
                  "sn": "656",
                  "transactionId": "S97117654",
                  "transactionDate": "10/31/2022 12:00:00 AM",
                  "valueDate": "10/31/2022 12:00:00 AM",
                  "debitAmount": "10.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - vince",
                  "narration2": "FEE EXEMPT",
                  "remarks": "INTERNETBANKING/OGUNSUSI MUSSA",
                  "balance": "62476.96"
              },
              {
                  "sn": "657",
                  "transactionId": "S97521542",
                  "transactionDate": "11/1/2022 12:00:00 AM",
                  "valueDate": "11/1/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "5000",
                  "narration": "000-PETERS ADEMOLA AKINMOLADUN-TRF FRM",
                  "narration2": "000012221101120934000480929061/I0043890412",
                  "remarks": "NIP/NIP/PETERS ADEMOLA AKINMOL",
                  "balance": "67476.96"
              },
              {
                  "sn": "658",
                  "transactionId": "S97651050",
                  "transactionDate": "11/1/2022 12:00:00 AM",
                  "valueDate": "11/1/2022 12:00:00 AM",
                  "debitAmount": "20050",
                  "creditAmount": "0",
                  "narration": "web:vince",
                  "narration2": "",
                  "remarks": "5300017669/5020634",
                  "balance": "47426.96"
              },
              {
                  "sn": "659",
                  "transactionId": "S97903840",
                  "transactionDate": "11/2/2022 12:00:00 AM",
                  "valueDate": "11/2/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "150000",
                  "narration": "100-Anthony Okeleke-Transfer from Anth",
                  "narration2": "100026221102083739000026615191/I1022285512",
                  "remarks": "NIP/NIP/Anthony Okeleke",
                  "balance": "197426.96"
              },
              {
                  "sn": "660",
                  "transactionId": "S97903840",
                  "transactionDate": "11/2/2022 12:00:00 AM",
                  "valueDate": "11/2/2022 12:00:00 AM",
                  "debitAmount": "50",
                  "creditAmount": "0",
                  "narration": "STAMP DUTY CHARGE ON DEPOSIT: 5300017669",
                  "narration2": "FEE EXEMPT",
                  "remarks": "NIP/NIP/Anthony Okeleke",
                  "balance": "197376.96"
              },
              {
                  "sn": "661",
                  "transactionId": "S97959310",
                  "transactionDate": "11/2/2022 12:00:00 AM",
                  "valueDate": "11/2/2022 12:00:00 AM",
                  "debitAmount": "0",
                  "creditAmount": "2000",
                  "narration": "000-STANLEY   NWAGWUOCHA-Trsf from Xta",
                  "narration2": "000015221102111720003137192038/I2374667955",
                  "remarks": "NIP/NIP/STANLEY   NWAGWUOCHA",
                  "balance": "199376.96"
              },
              {
                  "sn": "662",
                  "transactionId": "S98559513",
                  "transactionDate": "11/3/2022 12:00:00 AM",
                  "valueDate": "11/3/2022 12:00:00 AM",
                  "debitAmount": "9500",
                  "creditAmount": "0",
                  "narration": "Trf - fuel",
                  "narration2": "000020221103082351004006846093/O9015723423",
                  "remarks": "MOBILE/RASHIDAT SHEMISIAT IBRA",
                  "balance": "189876.96"
              },
              {
                  "sn": "663",
                  "transactionId": "S98559513",
                  "transactionDate": "11/3/2022 12:00:00 AM",
                  "valueDate": "11/3/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - fuel",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/RASHIDAT SHEMISIAT IBRA",
                  "balance": "189850.08"
              },
              {
                  "sn": "664",
                  "transactionId": "S98596789",
                  "transactionDate": "11/3/2022 12:00:00 AM",
                  "valueDate": "11/3/2022 12:00:00 AM",
                  "debitAmount": "150000",
                  "creditAmount": "0",
                  "narration": "Trf - Part payment IFO Feb 11th",
                  "narration2": "000020221103103621004006848788/O1300013943",
                  "remarks": "MOBILE/HOLY FAMILY CATHOLIC CH",
                  "balance": "39850.08"
              },
              {
                  "sn": "665",
                  "transactionId": "S98596789",
                  "transactionDate": "11/3/2022 12:00:00 AM",
                  "valueDate": "11/3/2022 12:00:00 AM",
                  "debitAmount": "53.75",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Part payment IFO Feb 11th",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/HOLY FAMILY CATHOLIC CH",
                  "balance": "39796.33"
              },
              {
                  "sn": "666",
                  "transactionId": "S98663276",
                  "transactionDate": "11/3/2022 12:00:00 AM",
                  "valueDate": "11/3/2022 12:00:00 AM",
                  "debitAmount": "14500",
                  "creditAmount": "0",
                  "narration": "Trf - Olembe",
                  "narration2": "000020221103134611004006852421/O0803406652",
                  "remarks": "MOBILE/KENNETH OKENWA MBA",
                  "balance": "25296.33"
              },
              {
                  "sn": "667",
                  "transactionId": "S98663276",
                  "transactionDate": "11/3/2022 12:00:00 AM",
                  "valueDate": "11/3/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Olembe",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/KENNETH OKENWA MBA",
                  "balance": "25269.45"
              },
              {
                  "sn": "668",
                  "transactionId": "S98987569",
                  "transactionDate": "11/4/2022 12:00:00 AM",
                  "valueDate": "11/4/2022 12:00:00 AM",
                  "debitAmount": "9000",
                  "creditAmount": "0",
                  "narration": "Mob Trf invinceable",
                  "narration2": "BLEND-LOCAL TRANSFER:-866b8f6e-f1ee-488e-8adb-a62f",
                  "remarks": "5300017669 / 1001597495/201990",
                  "balance": "16269.45"
              },
              {
                  "sn": "669",
                  "transactionId": "S98988683",
                  "transactionDate": "11/4/2022 12:00:00 AM",
                  "valueDate": "11/4/2022 12:00:00 AM",
                  "debitAmount": "10500",
                  "creditAmount": "0",
                  "narration": "Trf - Phone screen",
                  "narration2": "000020221104111403004006867448/O0803406652",
                  "remarks": "MOBILE/KENNETH OKENWA MBA",
                  "balance": "5769.45"
              },
              {
                  "sn": "670",
                  "transactionId": "S98988683",
                  "transactionDate": "11/4/2022 12:00:00 AM",
                  "valueDate": "11/4/2022 12:00:00 AM",
                  "debitAmount": "26.88",
                  "creditAmount": "0",
                  "narration": "Trf Fee - Phone screen",
                  "narration2": "FEE EXEMPT",
                  "remarks": "MOBILE/KENNETH OKENWA MBA",
                  "balance": "5742.57"
              },
              {
                  "sn": "671",
                  "transactionId": "S99169854",
                  "transactionDate": "11/4/2022 12:00:00 AM",
                  "valueDate": "11/4/2022 12:00:00 AM",
                  "debitAmount": "100",
                  "creditAmount": "0",
                  "narration": "Mob Recharge-Online: Recharge <Glo> <07051456716>",
                  "narration2": "BLEND-MOBILERECHARGE1-638031697820336471",
                  "remarks": "BLEND / BLD/314746709",
                  "balance": "5642.57"
              }
          ]
      }
      },
      {
        label: "Polaris",
        value: "Polaris",
        data:{
            "status": "Successful",
            "message": "Transaction processed successfully",
            "data": {
              "provider_response_code": "00",
              "provider": "Polaris",
              "errors": null,
              "error": null,
              "provider_response": {
                "account_number": "1012324741",
                "closing_balance": 3018888,
                "opening_balance": 4510509,
                "statement_list": [
                  {
                    "transaction_reference": "166FTTP25013007U",
                    "transaction_amount": -50000,
                    "balance": 4460509,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-13T00:00:00",
                    "description": "USSD  AIRTIME :08077067317: GLO:81A68D318015416BBA26184F04EEB2F1"
                  },
                  {
                    "transaction_reference": "166FTTP2501300KQ",
                    "transaction_amount": -50000,
                    "balance": 4410509,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-13T00:00:00",
                    "description": "USSD  AIRTIME :08077067317: GLO:33EAE192F07848C2B7DCDA42AE14DE6D"
                  },
                  {
                    "transaction_reference": "166FTTP25013009P",
                    "transaction_amount": -2792,
                    "balance": 4407717,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-13T00:00:00",
                    "description": "MOB/DGBNK2:USSD PLATFORM FEE FOR 01 DECEMBER - 31 DECEMBER 2024"
                  },
                  {
                    "transaction_reference": "166FTOA25013038N",
                    "transaction_amount": -10000,
                    "balance": 4397717,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-13T00:00:00",
                    "description": "MOB/DGBNK2:VULTE BILLSPAY:54150425087:IKEJA ELECTRIC PREPAID: 1AF4DE97496387238428405983991304-CHARGES"
                  },
                  {
                    "transaction_reference": "166FTTP2501300O4",
                    "transaction_amount": -40000,
                    "balance": 4357717,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-13T00:00:00",
                    "description": "MOB/DGBNK2:VULTE BILLSPAY:54150425087:IKEJA ELECTRIC PREPAID: 758D4011F063872384284059780113"
                  },
                  {
                    "transaction_reference": "166EZTX250140EQI",
                    "transaction_amount": -75,
                    "balance": 4357642,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-15T00:00:00",
                    "description": "ETZ:305/AFUSAT  IDOWU BELLO/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: AFUSAT  IDOWU BELLO: B28BCAC79663872485"
                  },
                  {
                    "transaction_reference": "166EZTX250140EQI",
                    "transaction_amount": -210000,
                    "balance": 4147642,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-15T00:00:00",
                    "description": "ETZ:305/AFUSAT  IDOWU BELLO/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: AFUSAT  IDOWU BELLO: B28BCAC79663872485"
                  },
                  {
                    "transaction_reference": "166EZTX250140EQI",
                    "transaction_amount": -1000,
                    "balance": 4146642,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-15T00:00:00",
                    "description": "ETZ:305/AFUSAT  IDOWU BELLO/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: AFUSAT  IDOWU BELLO: B28BCAC79663872485"
                  },
                  {
                    "transaction_reference": "166FTOA2501503F7",
                    "transaction_amount": -10000,
                    "balance": 4136642,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-15T00:00:00",
                    "description": "MOB/DGBNK2:VULTE BILLSPAY:54150425087:IKEJA ELECTRIC PREPAID: 63AFA552F06387253334779305001510-CHARGES"
                  },
                  {
                    "transaction_reference": "166FTTP25015000N",
                    "transaction_amount": -50000,
                    "balance": 4086642,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-15T00:00:00",
                    "description": "MOB/DGBNK2:VULTE BILLSPAY:54150425087:IKEJA ELECTRIC PREPAID: B70E58601563872533347792993615"
                  },
                  {
                    "transaction_reference": "166KLNT250150L82",
                    "transaction_amount": 700000,
                    "balance": 4786642,
                    "transaction_type": "C",
                    "transaction_date": "2025-01-15T00:00:00",
                    "description": "NIBSS:JOHN AYODEJI OYENIRAN:TRANSFER FROM JOHN AYODEJI OYENIRAN:100004250115132953125484299003"
                  },
                  {
                    "transaction_reference": "696FTRE2501500u0",
                    "transaction_amount": 400000,
                    "balance": 5186642,
                    "transaction_type": "C",
                    "transaction_date": "2025-01-15T00:00:00",
                    "description": "SWITCHIT 002279697698APP: TO PO LARIS BANK KA ZE EM 420109  002279697698"
                  },
                  {
                    "transaction_reference": "166FTTP25016000N",
                    "transaction_amount": -200000,
                    "balance": 4986642,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-16T00:00:00",
                    "description": "MOB/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: ADISA  LASISI  ADELEKE: B245730F6163872607621429222116"
                  },
                  {
                    "transaction_reference": "166EZTX250170GS6",
                    "transaction_amount": -75,
                    "balance": 4986567,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-17T00:00:00",
                    "description": "ETZ:305/KAZEEM  OLASUNKANMI OLASUNKANMI ADELEKE/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: KAZEEM  OLASUNKANMI"
                  },
                  {
                    "transaction_reference": "166EZTX250170GS6",
                    "transaction_amount": -100000,
                    "balance": 4886567,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-17T00:00:00",
                    "description": "ETZ:305/KAZEEM  OLASUNKANMI OLASUNKANMI ADELEKE/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: KAZEEM  OLASUNKANMI"
                  },
                  {
                    "transaction_reference": "166EZTX250170GS6",
                    "transaction_amount": -1000,
                    "balance": 4885567,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-17T00:00:00",
                    "description": "ETZ:305/KAZEEM  OLASUNKANMI OLASUNKANMI ADELEKE/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: KAZEEM  OLASUNKANMI"
                  },
                  {
                    "transaction_reference": "696FTRI250170Imz",
                    "transaction_amount": -1300000,
                    "balance": 3585567,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-17T00:00:00",
                    "description": "2ISTJQPE T ZAMFAF UNIQUE ENT 003 239 2ISTJQPE  LA NG 003239  000000003239"
                  },
                  {
                    "transaction_reference": "166FTTP25017002O",
                    "transaction_amount": -20000,
                    "balance": 3565567,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-17T00:00:00",
                    "description": "USSD  AIRTIME :08077067317: GLO:F58DF04094B740DCB8B658A1F18DF164"
                  },
                  {
                    "transaction_reference": "166FTOA2502003ZJ",
                    "transaction_amount": -10000,
                    "balance": 3555567,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-20T00:00:00",
                    "description": "MOB/DGBNK2:VULTE BILLSPAY:54150425087:IKEJA ELECTRIC PREPAID: 9D7182F9DD6387298713467444082004-CHARGES"
                  },
                  {
                    "transaction_reference": "166FTTP2502000JE",
                    "transaction_amount": -40000,
                    "balance": 3515567,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-20T00:00:00",
                    "description": "MOB/DGBNK2:VULTE BILLSPAY:54150425087:IKEJA ELECTRIC PREPAID: 1E9484AFBB63872987134674365820"
                  },
                  {
                    "transaction_reference": "166EZTX250200K6E",
                    "transaction_amount": -75,
                    "balance": 3515492,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-20T00:00:00",
                    "description": "ETZ:044/BUSAYO FOLAKE IJAOLA/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: BUSAYO FOLAKE IJAOLA: CE1AE8B258638730"
                  },
                  {
                    "transaction_reference": "166EZTX250200K6E",
                    "transaction_amount": -150000,
                    "balance": 3365492,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-20T00:00:00",
                    "description": "ETZ:044/BUSAYO FOLAKE IJAOLA/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: BUSAYO FOLAKE IJAOLA: CE1AE8B258638730"
                  },
                  {
                    "transaction_reference": "166EZTX250200K6E",
                    "transaction_amount": -1000,
                    "balance": 3364492,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-20T00:00:00",
                    "description": "ETZ:044/BUSAYO FOLAKE IJAOLA/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: BUSAYO FOLAKE IJAOLA: CE1AE8B258638730"
                  },
                  {
                    "transaction_reference": "166KLNT250200Q6B",
                    "transaction_amount": 700000,
                    "balance": 4064492,
                    "transaction_type": "C",
                    "transaction_date": "2025-01-21T00:00:00",
                    "description": "NIBSS:SHERIFF ADEWALE ADEBAYO:TRANSFER FROM SHERIFF ADEWALE ADEBAYO:100004250120194737125774311119"
                  },
                  {
                    "transaction_reference": "166EZTX250210KE2",
                    "transaction_amount": -188,
                    "balance": 4064304,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-21T00:00:00",
                    "description": "ETZ:305/ ISLAMIYAT OKIKIOLA ABDULAZEEZ/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO:  ISLAMIYAT OKIKIOLA ABDULAZE"
                  },
                  {
                    "transaction_reference": "166EZTX250210KE2",
                    "transaction_amount": -1020000,
                    "balance": 3044304,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-21T00:00:00",
                    "description": "ETZ:305/ ISLAMIYAT OKIKIOLA ABDULAZEEZ/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO:  ISLAMIYAT OKIKIOLA ABDULAZE"
                  },
                  {
                    "transaction_reference": "166EZTX250210KE2",
                    "transaction_amount": -2500,
                    "balance": 3041804,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-21T00:00:00",
                    "description": "ETZ:305/ ISLAMIYAT OKIKIOLA ABDULAZEEZ/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO:  ISLAMIYAT OKIKIOLA ABDULAZE"
                  },
                  {
                    "transaction_reference": "166KLNT250220R5H",
                    "transaction_amount": 30000,
                    "balance": 3071804,
                    "transaction_type": "C",
                    "transaction_date": "2025-01-22T00:00:00",
                    "description": "NIBSS:BUSAYO FOLAKE IJAOLA:NIPKAZEEM OLASUNKANMI ADELEKE1014741BUSAYO FOLAKE IJAOLA:000014250121204210268498680573"
                  },
                  {
                    "transaction_reference": "166EZTX250220L7C",
                    "transaction_amount": -188,
                    "balance": 3071616,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-22T00:00:00",
                    "description": "ETZ:305/ELIJAH  OLUWAJEDALO OGUNJOBI/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: ELIJAH  OLUWAJEDALO OGUNJOBI: "
                  },
                  {
                    "transaction_reference": "166EZTX250220L7C",
                    "transaction_amount": -3000000,
                    "balance": 71616,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-22T00:00:00",
                    "description": "ETZ:305/ELIJAH  OLUWAJEDALO OGUNJOBI/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: ELIJAH  OLUWAJEDALO OGUNJOBI: "
                  },
                  {
                    "transaction_reference": "166EZTX250220L7C",
                    "transaction_amount": -2500,
                    "balance": 69116,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-22T00:00:00",
                    "description": "ETZ:305/ELIJAH  OLUWAJEDALO OGUNJOBI/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: ELIJAH  OLUWAJEDALO OGUNJOBI: "
                  },
                  {
                    "transaction_reference": "166FTTP25023000N",
                    "transaction_amount": -20000,
                    "balance": 49116,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-23T00:00:00",
                    "description": "USSD  AIRTIME :08077067317: GLO:ED53983DFCBF427C961E3366036B67F1"
                  },
                  {
                    "transaction_reference": "166KLNT250230SAZ",
                    "transaction_amount": 1500000,
                    "balance": 1549116,
                    "transaction_type": "C",
                    "transaction_date": "2025-01-23T00:00:00",
                    "description": "NIBSS:EMMANUEL CHIGAMEZU IZUNWANNE:TRANSFER FROM EMMANUEL CHIGAMEZU IZUNWANNE:100004250123081200125900009903"
                  },
                  {
                    "transaction_reference": "166EMTL25023002X",
                    "transaction_amount": -5000,
                    "balance": 1544116,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-23T00:00:00",
                    "description": "ELECTRONIC MONEY TRANSFER LEVYEMTL N15000 TXN AS AT 23-JAN-2025"
                  },
                  {
                    "transaction_reference": "166KLNT250230SEN",
                    "transaction_amount": 900000,
                    "balance": 2444116,
                    "transaction_type": "C",
                    "transaction_date": "2025-01-23T00:00:00",
                    "description": "NIBSS:ADEOYE  SAMUEL OLAMIDE:GEN TO KAZEEM OLASUNKANMI ADELEKE  000013250123103544000104955591:0000132501231035440001049"
                  },
                  {
                    "transaction_reference": "166EZTX250230MZ6",
                    "transaction_amount": -75,
                    "balance": 2444041,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-23T00:00:00",
                    "description": "ETZ:305/KAZEEM  OLASUNKANMI OLASUNKANMI ADELEKE/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: KAZEEM  OLASUNKANMI"
                  },
                  {
                    "transaction_reference": "166EZTX250230MZ6",
                    "transaction_amount": -100000,
                    "balance": 2344041,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-23T00:00:00",
                    "description": "ETZ:305/KAZEEM  OLASUNKANMI OLASUNKANMI ADELEKE/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: KAZEEM  OLASUNKANMI"
                  },
                  {
                    "transaction_reference": "166EZTX250230MZ6",
                    "transaction_amount": -1000,
                    "balance": 2343041,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-23T00:00:00",
                    "description": "ETZ:305/KAZEEM  OLASUNKANMI OLASUNKANMI ADELEKE/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: KAZEEM  OLASUNKANMI"
                  },
                  {
                    "transaction_reference": "696FTRI250230JRJ",
                    "transaction_amount": -1020000,
                    "balance": 1323041,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-23T00:00:00",
                    "description": "2IST64H3 T DANDY HARMONY RES 003 229 2IST64H3  LA NG 003229  000000003229"
                  },
                  {
                    "transaction_reference": "166KLNT250230SNF",
                    "transaction_amount": 1500000,
                    "balance": 2823041,
                    "transaction_type": "C",
                    "transaction_date": "2025-01-23T00:00:00",
                    "description": "NIBSS:EMMANUEL CHIGAMEZU IZUNWANNE:TRANSFER FROM EMMANUEL CHIGAMEZU IZUNWANNE:100004250123121856125914251425"
                  },
                  {
                    "transaction_reference": "166EZTX250230NIU",
                    "transaction_amount": -188,
                    "balance": 2822853,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-23T00:00:00",
                    "description": "ETZ:469/SUNDAY SAMUEL OLAWEPO/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: SUNDAY SAMUEL OLAWEPO: 95A2480B656387"
                  },
                  {
                    "transaction_reference": "166EZTX250230NIU",
                    "transaction_amount": -1400000,
                    "balance": 1422853,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-23T00:00:00",
                    "description": "ETZ:469/SUNDAY SAMUEL OLAWEPO/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: SUNDAY SAMUEL OLAWEPO: 95A2480B656387"
                  },
                  {
                    "transaction_reference": "166EZTX250230NIU",
                    "transaction_amount": -2500,
                    "balance": 1420353,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-23T00:00:00",
                    "description": "ETZ:469/SUNDAY SAMUEL OLAWEPO/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: SUNDAY SAMUEL OLAWEPO: 95A2480B656387"
                  },
                  {
                    "transaction_reference": "166EMTL2502300AX",
                    "transaction_amount": -5000,
                    "balance": 1415353,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-23T00:00:00",
                    "description": "ELECTRONIC MONEY TRANSFER LEVYEMTL N15000 TXN AS AT 23-JAN-2025"
                  },
                  {
                    "transaction_reference": "696FTRI250230ROL",
                    "transaction_amount": -615000,
                    "balance": 800353,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-23T00:00:00",
                    "description": "2IST64H3 T DANDY HARMONY RES 003 232 2IST64H3  LA NG 003232  000000003232"
                  },
                  {
                    "transaction_reference": "166FTOA2502304D4",
                    "transaction_amount": -10000,
                    "balance": 790353,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-23T00:00:00",
                    "description": "MOB/DGBNK2:VULTE BILLSPAY:54150425087:IKEJA ELECTRIC PREPAID: 68E6A526936387324040154812302302-CHARGES"
                  },
                  {
                    "transaction_reference": "166FTTP2502300KT",
                    "transaction_amount": -100000,
                    "balance": 690353,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-23T00:00:00",
                    "description": "MOB/DGBNK2:VULTE BILLSPAY:54150425087:IKEJA ELECTRIC PREPAID: BE979BB6DF63873240401548063323"
                  },
                  {
                    "transaction_reference": "166FTOA2502404GF",
                    "transaction_amount": -10000,
                    "balance": 680353,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-24T00:00:00",
                    "description": "MOB/DGBNK2:VULTE BILLSPAY:54150425087:IKEJA ELECTRIC PREPAID: 43ACC8445C6387330903847327472409-CHARGES"
                  },
                  {
                    "transaction_reference": "166FTTP2502400FR",
                    "transaction_amount": -50000,
                    "balance": 630353,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-24T00:00:00",
                    "description": "MOB/DGBNK2:VULTE BILLSPAY:54150425087:IKEJA ELECTRIC PREPAID: 9780000AA163873309038473227224"
                  },
                  {
                    "transaction_reference": "166FTTP2502700TF",
                    "transaction_amount": -130000,
                    "balance": 500353,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-27T00:00:00",
                    "description": "MOB/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: IDOWU UGOJO EKE: D8C410D79F6387339508754188582509"
                  },
                  {
                    "transaction_reference": "166EZTX250270RV4",
                    "transaction_amount": -75,
                    "balance": 500278,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-27T00:00:00",
                    "description": "ETZ:044/BUSAYO FOLAKE IJAOLA/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: BUSAYO FOLAKE IJAOLA: 47E2AEDDB0638734"
                  },
                  {
                    "transaction_reference": "166EZTX250270RV4",
                    "transaction_amount": -400000,
                    "balance": 100278,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-27T00:00:00",
                    "description": "ETZ:044/BUSAYO FOLAKE IJAOLA/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: BUSAYO FOLAKE IJAOLA: 47E2AEDDB0638734"
                  },
                  {
                    "transaction_reference": "166EZTX250270RV4",
                    "transaction_amount": -1000,
                    "balance": 99278,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-27T00:00:00",
                    "description": "ETZ:044/BUSAYO FOLAKE IJAOLA/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: BUSAYO FOLAKE IJAOLA: 47E2AEDDB0638734"
                  },
                  {
                    "transaction_reference": "166KLNT250270W4L",
                    "transaction_amount": 800000,
                    "balance": 899278,
                    "transaction_type": "C",
                    "transaction_date": "2025-01-27T00:00:00",
                    "description": "NIBSS:AFAINIYA OLUWATOSIN:NIP TRANSFER TO KAZEEM OLASUNKANMI ADELEKE  00001325012710381500011253:00001325012710381500011"
                  },
                  {
                    "transaction_reference": "696FTRI2502727h9",
                    "transaction_amount": -500000,
                    "balance": 399278,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-27T00:00:00",
                    "description": "2TPB0H9F TEAMAPT LIMITED         LA            LA NG 003713  250127252327"
                  },
                  {
                    "transaction_reference": "166KLNT250270WW5",
                    "transaction_amount": 1000000,
                    "balance": 1399278,
                    "transaction_type": "C",
                    "transaction_date": "2025-01-28T00:00:00",
                    "description": "NIBSS:EMMANUEL CHIGAMEZU IZUNWANNE:TRANSFER FROM EMMANUEL CHIGAMEZU IZUNWANNE:100004250127203153126164324723"
                  },
                  {
                    "transaction_reference": "166EZTX250270UFS",
                    "transaction_amount": -188,
                    "balance": 1399090,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-28T00:00:00",
                    "description": "ETZ:305/ISLAMIYAT  OKIKIOLA ABDULAZEEZ/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: ISLAMIYAT  OKIKIOLA ABDULAZE"
                  },
                  {
                    "transaction_reference": "166EZTX250270UFS",
                    "transaction_amount": -520000,
                    "balance": 879090,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-28T00:00:00",
                    "description": "ETZ:305/ISLAMIYAT  OKIKIOLA ABDULAZEEZ/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: ISLAMIYAT  OKIKIOLA ABDULAZE"
                  },
                  {
                    "transaction_reference": "166EZTX250270UFS",
                    "transaction_amount": -2500,
                    "balance": 876590,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-28T00:00:00",
                    "description": "ETZ:305/ISLAMIYAT  OKIKIOLA ABDULAZEEZ/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: ISLAMIYAT  OKIKIOLA ABDULAZE"
                  },
                  {
                    "transaction_reference": "166EMTL2502701U4",
                    "transaction_amount": -5000,
                    "balance": 871590,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-28T00:00:00",
                    "description": "ELECTRONIC MONEY TRANSFER LEVYEMTL N10000 TXN AS AT 27-JAN-2025"
                  },
                  {
                    "transaction_reference": "166KLNT250280X6J",
                    "transaction_amount": 2500000,
                    "balance": 3371590,
                    "transaction_type": "C",
                    "transaction_date": "2025-01-28T00:00:00",
                    "description": "NIBSS:ROSIJI MOJOYINOLA TAIWO:NIP TRANSFER TO KAZEEM OLASUNKANMI ADELEKE  00001325012809530100011451:0000132501280953010"
                  },
                  {
                    "transaction_reference": "166EMTL2502800G9",
                    "transaction_amount": -5000,
                    "balance": 3366590,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-28T00:00:00",
                    "description": "ELECTRONIC MONEY TRANSFER LEVYEMTL N25000 TXN AS AT 28-JAN-2025"
                  },
                  {
                    "transaction_reference": "696FTRI250280BAu",
                    "transaction_amount": -510000,
                    "balance": 2856590,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-28T00:00:00",
                    "description": "2TEP4SY0 T ZAMFAF UNIQUE ENT 003 751 2TEP4SY0  LA NG 003751  000000003751"
                  },
                  {
                    "transaction_reference": "166FTOA2502805F6",
                    "transaction_amount": -10000,
                    "balance": 2846590,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-28T00:00:00",
                    "description": "MOB/DGBNK2:VULTE BILLSPAY:54150425087:IKEJA ELECTRIC PREPAID: A3D52EB11C6387365705788486982810-CHARGES"
                  },
                  {
                    "transaction_reference": "166FTTP2502800F4",
                    "transaction_amount": -150000,
                    "balance": 2696590,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-28T00:00:00",
                    "description": "MOB/DGBNK2:VULTE BILLSPAY:54150425087:IKEJA ELECTRIC PREPAID: E89D7BFD2A63873657057884803128"
                  },
                  {
                    "transaction_reference": "166KLNT250280XEO",
                    "transaction_amount": 1000000,
                    "balance": 3696590,
                    "transaction_type": "C",
                    "transaction_date": "2025-01-28T00:00:00",
                    "description": "NIBSS:OYEKANMI AINA SALIU:REFNGA1933680830 BO OYEKANMI AINA SALIU IFO KAZEE:000010250128120357111361987563"
                  },
                  {
                    "transaction_reference": "166EMTL2502800LZ",
                    "transaction_amount": -5000,
                    "balance": 3691590,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-28T00:00:00",
                    "description": "ELECTRONIC MONEY TRANSFER LEVYEMTL N10000 TXN AS AT 28-JAN-2025"
                  },
                  {
                    "transaction_reference": "166FTOA2502805GM",
                    "transaction_amount": -10000,
                    "balance": 3681590,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-28T00:00:00",
                    "description": "MOB/DGBNK2:VULTE BILLSPAY:54150425087:IKEJA ELECTRIC PREPAID: 4AD1B3B4B26387368059809955432805-CHARGES"
                  },
                  {
                    "transaction_reference": "166FTTP2502800HZ",
                    "transaction_amount": -100000,
                    "balance": 3581590,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-28T00:00:00",
                    "description": "MOB/DGBNK2:VULTE BILLSPAY:54150425087:IKEJA ELECTRIC PREPAID: B50F275CAB63873680598099448728"
                  },
                  {
                    "transaction_reference": "696FTRI250280sit",
                    "transaction_amount": -510000,
                    "balance": 3071590,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-29T00:00:00",
                    "description": "2ISTJQPE T ZAMFAF UNIQUE ENT 003 791 2ISTJQPE  LA NG 003791  000000003791"
                  },
                  {
                    "transaction_reference": "166FTOA2502905JP",
                    "transaction_amount": -10000,
                    "balance": 3061590,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-29T00:00:00",
                    "description": "MOB/DGBNK2:VULTE BILLSPAY:54150425087:IKEJA ELECTRIC PREPAID: 661573FDDC6387374073338579302909-CHARGES"
                  },
                  {
                    "transaction_reference": "166FTTP25029000T",
                    "transaction_amount": -100000,
                    "balance": 2961590,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-29T00:00:00",
                    "description": "MOB/DGBNK2:VULTE BILLSPAY:54150425087:IKEJA ELECTRIC PREPAID: 86AC53DBC263873740733385709729"
                  },
                  {
                    "transaction_reference": "166KLNT250290YNY",
                    "transaction_amount": 200000,
                    "balance": 3161590,
                    "transaction_type": "C",
                    "transaction_date": "2025-01-29T00:00:00",
                    "description": "NIBSS:TEMIDAYO JOHN AJAYI:TRANSFER FROM TEMIDAYO JOHN AJAYI:100004250129144949126258387758"
                  },
                  {
                    "transaction_reference": "696FTRI250290U7Q",
                    "transaction_amount": -510000,
                    "balance": 2651590,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-29T00:00:00",
                    "description": "2TPB0H9F TEAMAPT LIMITED         LA            LA NG 003820  250129922703"
                  },
                  {
                    "transaction_reference": "696FTRI250290U2O",
                    "transaction_amount": -510000,
                    "balance": 2141590,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-29T00:00:00",
                    "description": "2UBNP835 7 NKANNA STREET ILUPE J LAGOSI         NG 282868  502915282868"
                  },
                  {
                    "transaction_reference": "696FTRI250290U7Q",
                    "transaction_amount": 510000,
                    "balance": 2651590,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-30T00:00:00",
                    "description": "2TPB0H9F 003820 250129922703"
                  },
                  {
                    "transaction_reference": "166EZTX250300X8E",
                    "transaction_amount": -75,
                    "balance": 2651515,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-30T00:00:00",
                    "description": "ETZ:050/ADEYEMI  JOSHUA ATOLAGBE/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: ADEYEMI  JOSHUA ATOLAGBE:PROPHETIC"
                  },
                  {
                    "transaction_reference": "166EZTX250300X8E",
                    "transaction_amount": -250000,
                    "balance": 2401515,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-30T00:00:00",
                    "description": "ETZ:050/ADEYEMI  JOSHUA ATOLAGBE/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: ADEYEMI  JOSHUA ATOLAGBE:PROPHETIC"
                  },
                  {
                    "transaction_reference": "166EZTX250300X8E",
                    "transaction_amount": -1000,
                    "balance": 2400515,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-30T00:00:00",
                    "description": "ETZ:050/ADEYEMI  JOSHUA ATOLAGBE/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: ADEYEMI  JOSHUA ATOLAGBE:PROPHETIC"
                  },
                  {
                    "transaction_reference": "696FTRI250300GNV",
                    "transaction_amount": -300000,
                    "balance": 2100515,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-30T00:00:00",
                    "description": "2ISTJQPE T ZAMFAF UNIQUE ENT 003 857 2ISTJQPE  LA NG 003857  000000003857"
                  },
                  {
                    "transaction_reference": "166EZTX250300XJJ",
                    "transaction_amount": -75,
                    "balance": 2100440,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-30T00:00:00",
                    "description": "ETZ:044/BUSAYO FOLAKE IJAOLA/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: BUSAYO FOLAKE IJAOLA: 5ADDD791C0638738"
                  },
                  {
                    "transaction_reference": "166EZTX250300XJJ",
                    "transaction_amount": -500000,
                    "balance": 1600440,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-30T00:00:00",
                    "description": "ETZ:044/BUSAYO FOLAKE IJAOLA/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: BUSAYO FOLAKE IJAOLA: 5ADDD791C0638738"
                  },
                  {
                    "transaction_reference": "166EZTX250300XJJ",
                    "transaction_amount": -1000,
                    "balance": 1599440,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-30T00:00:00",
                    "description": "ETZ:044/BUSAYO FOLAKE IJAOLA/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: BUSAYO FOLAKE IJAOLA: 5ADDD791C0638738"
                  },
                  {
                    "transaction_reference": "696FTRI250300vWj",
                    "transaction_amount": -510000,
                    "balance": 1089440,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-31T00:00:00",
                    "description": "2ISTJQPE T ZAMFAF UNIQUE ENT 003 886 2ISTJQPE  LA NG 003886  000000003886"
                  },
                  {
                    "transaction_reference": "166EZTX250310YNP",
                    "transaction_amount": -75,
                    "balance": 1089365,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-31T00:00:00",
                    "description": "ETZ:305/KAZEEM  OLASUNKANMI OLASUNKANMI ADELEKE/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: KAZEEM  OLASUNKANMI"
                  },
                  {
                    "transaction_reference": "166EZTX250310YNP",
                    "transaction_amount": -100000,
                    "balance": 989365,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-31T00:00:00",
                    "description": "ETZ:305/KAZEEM  OLASUNKANMI OLASUNKANMI ADELEKE/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: KAZEEM  OLASUNKANMI"
                  },
                  {
                    "transaction_reference": "166EZTX250310YNP",
                    "transaction_amount": -1000,
                    "balance": 988365,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-31T00:00:00",
                    "description": "ETZ:305/KAZEEM  OLASUNKANMI OLASUNKANMI ADELEKE/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: KAZEEM  OLASUNKANMI"
                  },
                  {
                    "transaction_reference": "166EZTX250310YY0",
                    "transaction_amount": -75,
                    "balance": 988290,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-31T00:00:00",
                    "description": "ETZ:044/BUSAYO FOLAKE IJAOLA/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: BUSAYO FOLAKE IJAOLA: B177C508C2638739"
                  },
                  {
                    "transaction_reference": "166EZTX250310YY0",
                    "transaction_amount": -100000,
                    "balance": 888290,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-31T00:00:00",
                    "description": "ETZ:044/BUSAYO FOLAKE IJAOLA/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: BUSAYO FOLAKE IJAOLA: B177C508C2638739"
                  },
                  {
                    "transaction_reference": "166EZTX250310YY0",
                    "transaction_amount": -1000,
                    "balance": 887290,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-31T00:00:00",
                    "description": "ETZ:044/BUSAYO FOLAKE IJAOLA/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: BUSAYO FOLAKE IJAOLA: B177C508C2638739"
                  },
                  {
                    "transaction_reference": "166FTTP25031003H",
                    "transaction_amount": -110000,
                    "balance": 777290,
                    "transaction_type": "D",
                    "transaction_date": "2025-01-31T00:00:00",
                    "description": "MOB/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: IDOWU UGOJO EKE: CF80189B2F6387393891372760513104"
                  },
                  {
                    "transaction_reference": "166KLNT2503411NU",
                    "transaction_amount": 30000000,
                    "balance": 30777290,
                    "transaction_type": "C",
                    "transaction_date": "2025-02-03T00:00:00",
                    "description": "NIBSS:JUDITH:PAY:000015250201112137000002017350"
                  },
                  {
                    "transaction_reference": "166EMTL250340051",
                    "transaction_amount": -5000,
                    "balance": 30772290,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-03T00:00:00",
                    "description": "ELECTRONIC MONEY TRANSFER LEVYEMTL N300000 TXN AS AT 01-FEB-2025"
                  },
                  {
                    "transaction_reference": "166EZTX25034102F",
                    "transaction_amount": -75,
                    "balance": 30772215,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-03T00:00:00",
                    "description": "ETZ:469/VICTORIA OJONUGWA EBAKWO/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: VICTORIA OJONUGWA EBAKWO: 204B4686"
                  },
                  {
                    "transaction_reference": "166EZTX25034102F",
                    "transaction_amount": -200000,
                    "balance": 30572215,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-03T00:00:00",
                    "description": "ETZ:469/VICTORIA OJONUGWA EBAKWO/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: VICTORIA OJONUGWA EBAKWO: 204B4686"
                  },
                  {
                    "transaction_reference": "166EZTX25034102F",
                    "transaction_amount": -1000,
                    "balance": 30571215,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-03T00:00:00",
                    "description": "ETZ:469/VICTORIA OJONUGWA EBAKWO/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: VICTORIA OJONUGWA EBAKWO: 204B4686"
                  },
                  {
                    "transaction_reference": "166EZTX2503410AV",
                    "transaction_amount": -75,
                    "balance": 30571140,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-03T00:00:00",
                    "description": "ETZ:305/MICHAEL  OYEWOLE OBISAMI/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: MICHAEL  OYEWOLE OBISAMI: F86E66AE"
                  },
                  {
                    "transaction_reference": "166EZTX2503410AV",
                    "transaction_amount": -180000,
                    "balance": 30391140,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-03T00:00:00",
                    "description": "ETZ:305/MICHAEL  OYEWOLE OBISAMI/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: MICHAEL  OYEWOLE OBISAMI: F86E66AE"
                  },
                  {
                    "transaction_reference": "166EZTX2503410AV",
                    "transaction_amount": -1000,
                    "balance": 30390140,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-03T00:00:00",
                    "description": "ETZ:305/MICHAEL  OYEWOLE OBISAMI/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: MICHAEL  OYEWOLE OBISAMI: F86E66AE"
                  },
                  {
                    "transaction_reference": "696VENP2503408KJ",
                    "transaction_amount": -300000,
                    "balance": 30090140,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-03T00:00:00",
                    "description": "205759QV PAYCOM NIGERIA LIMITED  JAYPAUL HOUSE LA NG 004611  502015576822"
                  },
                  {
                    "transaction_reference": "166KLNT2503412F8",
                    "transaction_amount": 300000,
                    "balance": 30390140,
                    "transaction_type": "C",
                    "transaction_date": "2025-02-03T00:00:00",
                    "description": "NIBSS:SODIQ OLANREWAJU TORIOLA:TRANSFER FROM SODIQ OLANREWAJU TORIOLA:100004250202082015126481939415"
                  },
                  {
                    "transaction_reference": "696FTRI250341dql",
                    "transaction_amount": -300000,
                    "balance": 30090140,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-03T00:00:00",
                    "description": "2TEP4SY0 T ZAMFAF UNIQUE ENT 004 026 2TEP4SY0  LA NG 004026  000000004026"
                  },
                  {
                    "transaction_reference": "166KLNT250341351",
                    "transaction_amount": 1000000,
                    "balance": 31090140,
                    "transaction_type": "C",
                    "transaction_date": "2025-02-03T00:00:00",
                    "description": "NIBSS:ENIOLA OPEOLUWA AROWOSOLA:TRANSFER FROM ENIOLA OPEOLUWA AROWOSOLA:100004250203080111126533059815"
                  },
                  {
                    "transaction_reference": "166EMTL2503400P0",
                    "transaction_amount": -5000,
                    "balance": 31085140,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-03T00:00:00",
                    "description": "ELECTRONIC MONEY TRANSFER LEVYEMTL N10000 TXN AS AT 03-FEB-2025"
                  },
                  {
                    "transaction_reference": "100syaz250350481",
                    "transaction_amount": 250000,
                    "balance": 31335140,
                    "transaction_type": "C",
                    "transaction_date": "2025-02-04T00:00:00",
                    "description": "ETZ REV 2501301034323444583"
                  },
                  {
                    "transaction_reference": "166gd10250354156",
                    "transaction_amount": -15600,
                    "balance": 31319540,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-04T00:00:00",
                    "description": "OUTSTANDING SMS SERVICE CHARGED FOR 39 TRANS : PERIOD APPLIED BTW 27/12/2024 AND 28/01/2025 - 1012324741"
                  },
                  {
                    "transaction_reference": "166EZTX250351305",
                    "transaction_amount": -75,
                    "balance": 31319465,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-04T00:00:00",
                    "description": "ETZ:305/KAZEEM  OLASUNKANMI OLASUNKANMI ADELEKE/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: KAZEEM  OLASUNKANMI"
                  },
                  {
                    "transaction_reference": "166EZTX250351305",
                    "transaction_amount": -100000,
                    "balance": 31219465,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-04T00:00:00",
                    "description": "ETZ:305/KAZEEM  OLASUNKANMI OLASUNKANMI ADELEKE/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: KAZEEM  OLASUNKANMI"
                  },
                  {
                    "transaction_reference": "166EZTX250351305",
                    "transaction_amount": -1000,
                    "balance": 31218465,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-04T00:00:00",
                    "description": "ETZ:305/KAZEEM  OLASUNKANMI OLASUNKANMI ADELEKE/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: KAZEEM  OLASUNKANMI"
                  },
                  {
                    "transaction_reference": "166FTOA2503506GQ",
                    "transaction_amount": -10000,
                    "balance": 31208465,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-04T00:00:00",
                    "description": "MOB/DGBNK2:VULTE BILLSPAY:54150425087:IKEJA ELECTRIC PREPAID: 5C73C92C9F6387428406460033780404-CHARGES"
                  },
                  {
                    "transaction_reference": "166FTTP25035002I",
                    "transaction_amount": -40000,
                    "balance": 31168465,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-04T00:00:00",
                    "description": "MOB/DGBNK2:VULTE BILLSPAY:54150425087:IKEJA ELECTRIC PREPAID: 55887CA03863874284064600276004"
                  },
                  {
                    "transaction_reference": "166EZTX2503513KV",
                    "transaction_amount": -188,
                    "balance": 31168277,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-04T00:00:00",
                    "description": "ETZ:305/ISLAMIYAT  OKIKIOLA ABDULAZEEZ/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: ISLAMIYAT  OKIKIOLA ABDULAZE"
                  },
                  {
                    "transaction_reference": "166EZTX2503513KV",
                    "transaction_amount": -520000,
                    "balance": 30648277,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-04T00:00:00",
                    "description": "ETZ:305/ISLAMIYAT  OKIKIOLA ABDULAZEEZ/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: ISLAMIYAT  OKIKIOLA ABDULAZE"
                  },
                  {
                    "transaction_reference": "166EZTX2503513KV",
                    "transaction_amount": -2500,
                    "balance": 30645777,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-04T00:00:00",
                    "description": "ETZ:305/ISLAMIYAT  OKIKIOLA ABDULAZEEZ/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: ISLAMIYAT  OKIKIOLA ABDULAZE"
                  },
                  {
                    "transaction_reference": "166EZTX2503513L3",
                    "transaction_amount": -75,
                    "balance": 30645702,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-04T00:00:00",
                    "description": "ETZ:035/GBEMISOLA TITI OLADIPUPO/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: GBEMISOLA TITI OLADIPUPO: F82A3C1D"
                  },
                  {
                    "transaction_reference": "166EZTX2503513L3",
                    "transaction_amount": -300000,
                    "balance": 30345702,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-04T00:00:00",
                    "description": "ETZ:035/GBEMISOLA TITI OLADIPUPO/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: GBEMISOLA TITI OLADIPUPO: F82A3C1D"
                  },
                  {
                    "transaction_reference": "166EZTX2503513L3",
                    "transaction_amount": -1000,
                    "balance": 30344702,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-04T00:00:00",
                    "description": "ETZ:035/GBEMISOLA TITI OLADIPUPO/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: GBEMISOLA TITI OLADIPUPO: F82A3C1D"
                  },
                  {
                    "transaction_reference": "166FTOA2503606J5",
                    "transaction_amount": -10000,
                    "balance": 30334702,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-05T00:00:00",
                    "description": "MOB/DGBNK2:VULTE BILLSPAY:54150425087:IKEJA ELECTRIC PREPAID: DEB0620CD66387434649827919300510-CHARGES"
                  },
                  {
                    "transaction_reference": "166FTTP25036000H",
                    "transaction_amount": -50000,
                    "balance": 30284702,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-05T00:00:00",
                    "description": "MOB/DGBNK2:VULTE BILLSPAY:54150425087:IKEJA ELECTRIC PREPAID: E167EBD44563874346498279144905"
                  },
                  {
                    "transaction_reference": "166FTOA2503606J6",
                    "transaction_amount": 10000,
                    "balance": 30294702,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-05T00:00:00",
                    "description": "REV:MOB/DGBNK2:VULTE BILLSPAY:54150425087:IKEJA ELECTRIC PREPAID: DEB0620CD66387434649827919300510-CHARGES"
                  },
                  {
                    "transaction_reference": "166FTTP2503600EI",
                    "transaction_amount": 50000,
                    "balance": 30344702,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-05T00:00:00",
                    "description": "REV:MOB/DGBNK2:VULTE BILLSPAY:54150425087:IKEJA ELECTRIC PREPAID: E167EBD44563874346498279144905"
                  },
                  {
                    "transaction_reference": "696FTRI250360VNv",
                    "transaction_amount": -510000,
                    "balance": 29834702,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-05T00:00:00",
                    "description": "2ISTJQPE T ZAMFAF UNIQUE ENT 004 106 2ISTJQPE  LA NG 004106  000000004106"
                  },
                  {
                    "transaction_reference": "166FTTP2503600HL",
                    "transaction_amount": -50000,
                    "balance": 29784702,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-05T00:00:00",
                    "description": "MOB/DGBNK2:VULTE BILLSPAY:54150425087:IKEJA ELECTRIC PREPAID: A70D4186A063874373351586341205"
                  },
                  {
                    "transaction_reference": "166FTOA2503606L3",
                    "transaction_amount": 10000,
                    "balance": 29794702,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-05T00:00:00",
                    "description": "REV:MOB/DGBNK2:VULTE BILLSPAY:54150425087:IKEJA ELECTRIC PREPAID: FF9D34A1196387437335158643120505-CHARGES"
                  },
                  {
                    "transaction_reference": "166FTTP2503600HM",
                    "transaction_amount": 50000,
                    "balance": 29844702,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-05T00:00:00",
                    "description": "REV:MOB/DGBNK2:VULTE BILLSPAY:54150425087:IKEJA ELECTRIC PREPAID: A70D4186A063874373351586341205"
                  },
                  {
                    "transaction_reference": "166FTOA2503606L1",
                    "transaction_amount": -10000,
                    "balance": 29834702,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-05T00:00:00",
                    "description": "MOB/DGBNK2:VULTE BILLSPAY:54150425087:IKEJA ELECTRIC PREPAID: FF9D34A1196387437335158643120505-CHARGES"
                  },
                  {
                    "transaction_reference": "166FTTP2503600HN",
                    "transaction_amount": -50000,
                    "balance": 29784702,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-05T00:00:00",
                    "description": "MOB/DGBNK2:VULTE BILLSPAY:54150425087:IKEJA ELECTRIC PREPAID: 0B9423BBE363874373608416092105"
                  },
                  {
                    "transaction_reference": "166FTOA2503606L4",
                    "transaction_amount": -10000,
                    "balance": 29774702,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-05T00:00:00",
                    "description": "MOB/DGBNK2:VULTE BILLSPAY:54150425087:IKEJA ELECTRIC PREPAID: 669DA759766387437360841617990505-CHARGES"
                  },
                  {
                    "transaction_reference": "166FTTP2503600HO",
                    "transaction_amount": 50000,
                    "balance": 29824702,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-05T00:00:00",
                    "description": "REV:MOB/DGBNK2:VULTE BILLSPAY:54150425087:IKEJA ELECTRIC PREPAID: 0B9423BBE363874373608416092105"
                  },
                  {
                    "transaction_reference": "166FTOA2503606L5",
                    "transaction_amount": 10000,
                    "balance": 29834702,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-05T00:00:00",
                    "description": "REV:MOB/DGBNK2:VULTE BILLSPAY:54150425087:IKEJA ELECTRIC PREPAID: 669DA759766387437360841617990505-CHARGES"
                  },
                  {
                    "transaction_reference": "696FTRI2503610o6",
                    "transaction_amount": -400000,
                    "balance": 29434702,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-06T00:00:00",
                    "description": "2IST280W T OLIVER FROZEN AND 000 384 2IST280W  LA NG 000384  000000000384"
                  },
                  {
                    "transaction_reference": "166EZTX2503714WU",
                    "transaction_amount": -75,
                    "balance": 29434627,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-06T00:00:00",
                    "description": "ETZ:305/KAZEEM  OLASUNKANMI OLASUNKANMI ADELEKE/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: KAZEEM  OLASUNKANMI"
                  },
                  {
                    "transaction_reference": "166EZTX2503714WU",
                    "transaction_amount": -150000,
                    "balance": 29284627,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-06T00:00:00",
                    "description": "ETZ:305/KAZEEM  OLASUNKANMI OLASUNKANMI ADELEKE/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: KAZEEM  OLASUNKANMI"
                  },
                  {
                    "transaction_reference": "166EZTX2503714WU",
                    "transaction_amount": -1000,
                    "balance": 29283627,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-06T00:00:00",
                    "description": "ETZ:305/KAZEEM  OLASUNKANMI OLASUNKANMI ADELEKE/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: KAZEEM  OLASUNKANMI"
                  },
                  {
                    "transaction_reference": "166FTOA2503706OC",
                    "transaction_amount": -50000,
                    "balance": 29233627,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-06T00:00:00",
                    "description": "MOB/DGBNK2:VULTE AIRTIME RECHARGE::GLO: 4965A526AE6387443561699628650610"
                  },
                  {
                    "transaction_reference": "166r53k250374087",
                    "transaction_amount": 15600,
                    "balance": 29249227,
                    "transaction_type": "C",
                    "transaction_date": "2025-02-06T00:00:00",
                    "description": "REV - OUTSTANDING SMS SERVICE CHARGED FOR 39 TRANS : PERIOD APPLIED BTW 27/12/2024 AND 28/01/2025 - 1012324741"
                  },
                  {
                    "transaction_reference": "166KLNT2503716TR",
                    "transaction_amount": 400000,
                    "balance": 29649227,
                    "transaction_type": "C",
                    "transaction_date": "2025-02-06T00:00:00",
                    "description": "NIBSS:OGUNBUNMI OLUWASEUN AKOLADE:FBNMOBILE KAZEEM OLASUNKANMI ADELEKE NONE:000016250206173545000023261234"
                  },
                  {
                    "transaction_reference": "166EZTX2503715C5",
                    "transaction_amount": -188,
                    "balance": 29649039,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-06T00:00:00",
                    "description": "ETZ:305/AFUSAT  IDOWU BELLO/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: AFUSAT  IDOWU BELLO: C73FE173CE63874461"
                  },
                  {
                    "transaction_reference": "166EZTX2503715C5",
                    "transaction_amount": -510000,
                    "balance": 29139039,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-06T00:00:00",
                    "description": "ETZ:305/AFUSAT  IDOWU BELLO/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: AFUSAT  IDOWU BELLO: C73FE173CE63874461"
                  },
                  {
                    "transaction_reference": "166EZTX2503715C5",
                    "transaction_amount": -2500,
                    "balance": 29136539,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-06T00:00:00",
                    "description": "ETZ:305/AFUSAT  IDOWU BELLO/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: AFUSAT  IDOWU BELLO: C73FE173CE63874461"
                  },
                  {
                    "transaction_reference": "166KLNT2503716Z1",
                    "transaction_amount": 400000,
                    "balance": 29536539,
                    "transaction_type": "C",
                    "transaction_date": "2025-02-06T00:00:00",
                    "description": "NIBSS:IJAOLA BUSAYO FOLAKE:USSD IJAOLA BUSAYO FOLAKE:000016250206195840000024293354"
                  },
                  {
                    "transaction_reference": "166FTTP25037001K",
                    "transaction_amount": -1000000,
                    "balance": 28536539,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-06T00:00:00",
                    "description": "MOB/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: MORAWO OLUSEGUN BAMIDELE: B0F8D9F54F638744705131821686"
                  },
                  {
                    "transaction_reference": "166y87h250384411",
                    "transaction_amount": -26400,
                    "balance": 28510139,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-07T00:00:00",
                    "description": "OUTSTANDING SMS SERVICE CHARGED FOR 66 TRANS : PERIOD APPLIED BTW 27/12/2024 AND 28/01/2025 - 1012324741"
                  },
                  {
                    "transaction_reference": "166KLNT25038178X",
                    "transaction_amount": 800000,
                    "balance": 29310139,
                    "transaction_type": "C",
                    "transaction_date": "2025-02-07T00:00:00",
                    "description": "NIBSS:ROSIJI MOJOYINOLA TAIWO:NIP TRANSFER TO KAZEEM OLASUNKANMI ADELEKE  00001325020709353700013624:0000132502070935370"
                  },
                  {
                    "transaction_reference": "696FTRI250380Ure",
                    "transaction_amount": -510000,
                    "balance": 28800139,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-07T00:00:00",
                    "description": "2TEP8EV9 T DANDY HARMONY RES 003 539 2TEP8EV9  LA NG 003539  000000003539"
                  },
                  {
                    "transaction_reference": "166FTTP25038002E",
                    "transaction_amount": -110000,
                    "balance": 28690139,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-07T00:00:00",
                    "description": "MOB/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: IDOWU UGOJO EKE: 05B2894A2E6387455000480104310706"
                  },
                  {
                    "transaction_reference": "166FTTP25041000T",
                    "transaction_amount": -150000,
                    "balance": 28540139,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-10T00:00:00",
                    "description": "MOB/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: IDOWU UGOJO EKE: AA045C0B8A6387460411757308040809"
                  },
                  {
                    "transaction_reference": "696FTRI250410pDg",
                    "transaction_amount": -410000,
                    "balance": 28130139,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-10T00:00:00",
                    "description": "2TPB0H9F TEAMAPT LIMITED         LA            LA NG 004187  250208266834"
                  },
                  {
                    "transaction_reference": "166KLNT25041192M",
                    "transaction_amount": 20000000,
                    "balance": 48130139,
                    "transaction_type": "C",
                    "transaction_date": "2025-02-10T00:00:00",
                    "description": "NIBSS:JOEL FUNMILAYO HELEN:CONTAINER TO KAZEEM OLASUNKANMI ADELEKE  00001325020911103900014046829:0000132502091110390001"
                  },
                  {
                    "transaction_reference": "166EMTL25041001X",
                    "transaction_amount": -5000,
                    "balance": 48125139,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-10T00:00:00",
                    "description": "ELECTRONIC MONEY TRANSFER LEVYEMTL N200000 TXN AS AT 09-FEB-2025"
                  },
                  {
                    "transaction_reference": "696FTRI250411Epd",
                    "transaction_amount": -510000,
                    "balance": 47615139,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-10T00:00:00",
                    "description": "2TPB0H9F TEAMAPT LIMITED         LA            LA NG 004220  250209003283"
                  },
                  {
                    "transaction_reference": "696FTRI250411lwr",
                    "transaction_amount": -2000000,
                    "balance": 45615139,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-10T00:00:00",
                    "description": "2TEPDYWP T TITILAYO MOTUNRAY 015 501 2TEPDYWP  LA NG 015501  000000015501"
                  },
                  {
                    "transaction_reference": "696FTRI250411nDb",
                    "transaction_amount": -10000000,
                    "balance": 35615139,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-10T00:00:00",
                    "description": "2TEP3ULN T MORIRES TRANSACT  013 864 2TEP3ULN  LA NG 013864  000000013864"
                  },
                  {
                    "transaction_reference": "696FTRI250411nEq",
                    "transaction_amount": -10000000,
                    "balance": 25615139,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-10T00:00:00",
                    "description": "2TEP3ULN T MORIRES TRANSACT  013 865 2TEP3ULN  LA NG 013865  000000013865"
                  },
                  {
                    "transaction_reference": "696FTRI250411nGd",
                    "transaction_amount": -10000000,
                    "balance": 15615139,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-10T00:00:00",
                    "description": "2TEP3ULN T MORIRES TRANSACT  013 866 2TEP3ULN  LA NG 013866  000000013866"
                  },
                  {
                    "transaction_reference": "696FTRI250411nHq",
                    "transaction_amount": -6200000,
                    "balance": 9415139,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-10T00:00:00",
                    "description": "2TEP3ULN T MORIRES TRANSACT  013 867 2TEP3ULN  LA NG 013867  000000013867"
                  },
                  {
                    "transaction_reference": "166FTIB25041005K",
                    "transaction_amount": -2500,
                    "balance": 9412639,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-10T00:00:00",
                    "description": "MOBBNKG:238/CLOCK-S STEEL ENTERPRISE - YOMI OLASUNKANMI OLAJIDE/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: CL"
                  },
                  {
                    "transaction_reference": "166FTIB25041005K",
                    "transaction_amount": -1800000,
                    "balance": 7612639,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-10T00:00:00",
                    "description": "MOBBNKG:238/CLOCK-S STEEL ENTERPRISE - YOMI OLASUNKANMI OLAJIDE/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: CL"
                  },
                  {
                    "transaction_reference": "166FTIB25041005K",
                    "transaction_amount": -188,
                    "balance": 7612450,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-10T00:00:00",
                    "description": "MOBBNKG:238/CLOCK-S STEEL ENTERPRISE - YOMI OLASUNKANMI OLAJIDE/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: CL"
                  },
                  {
                    "transaction_reference": "166KLNT2504119UC",
                    "transaction_amount": 200000,
                    "balance": 7812450,
                    "transaction_type": "C",
                    "transaction_date": "2025-02-10T00:00:00",
                    "description": "NIBSS:JOSEPH ABISOLA OJO:TRANSFER FROM JOSEPH ABISOLA OJO TO KAZEEM OLASUNKANMI ADELEKE:000001250210121509337587186506"
                  },
                  {
                    "transaction_reference": "696FTRI2504125g5",
                    "transaction_amount": -999900,
                    "balance": 6812550,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-10T00:00:00",
                    "description": "2TPB0H9F TEAMAPT LIMITED         LA            LA NG 004253  250210507118"
                  },
                  {
                    "transaction_reference": "166FTTP25041004P",
                    "transaction_amount": -150000,
                    "balance": 6662550,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-10T00:00:00",
                    "description": "IB/SUREGATE:SUREGATE 64F862925834DCE0F7C80554 TRAN1739188988377"
                  },
                  {
                    "transaction_reference": "696FTRI250412Xpd",
                    "transaction_amount": -999900,
                    "balance": 5662651,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-10T00:00:00",
                    "description": "2TPB0H9F TEAMAPT LIMITED         LA            LA NG 004269  250210108384"
                  },
                  {
                    "transaction_reference": "696VENP250410Sp2",
                    "transaction_amount": -820000,
                    "balance": 4842651,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-10T00:00:00",
                    "description": "2070KJ96 ITEX INTEGRATED SERVICE NO 2 ADEYEMO  LA NG 904899  128027894706"
                  },
                  {
                    "transaction_reference": "166FTTP25042001V",
                    "transaction_amount": -700000,
                    "balance": 4142651,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-11T00:00:00",
                    "description": "MOB/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: ADISA  LASISI  ADELEKE: DE52F2A60F63874888067780495111"
                  },
                  {
                    "transaction_reference": "166EZTX2504219VR",
                    "transaction_amount": -75,
                    "balance": 4142576,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-11T00:00:00",
                    "description": "ETZ:305/KAZEEM  OLASUNKANMI OLASUNKANMI ADELEKE/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: KAZEEM  OLASUNKANMI"
                  },
                  {
                    "transaction_reference": "166EZTX2504219VR",
                    "transaction_amount": -100000,
                    "balance": 4042576,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-11T00:00:00",
                    "description": "ETZ:305/KAZEEM  OLASUNKANMI OLASUNKANMI ADELEKE/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: KAZEEM  OLASUNKANMI"
                  },
                  {
                    "transaction_reference": "166EZTX2504219VR",
                    "transaction_amount": -1000,
                    "balance": 4041576,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-11T00:00:00",
                    "description": "ETZ:305/KAZEEM  OLASUNKANMI OLASUNKANMI ADELEKE/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: KAZEEM  OLASUNKANMI"
                  },
                  {
                    "transaction_reference": "166EZTX2504219VS",
                    "transaction_amount": -188,
                    "balance": 4041387,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-11T00:00:00",
                    "description": "ETZ:305/ISLAMIYAT  OKIKIOLA ABDULAZEEZ/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: ISLAMIYAT  OKIKIOLA ABDULAZE"
                  },
                  {
                    "transaction_reference": "166EZTX2504219VS",
                    "transaction_amount": -520000,
                    "balance": 3521387,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-11T00:00:00",
                    "description": "ETZ:305/ISLAMIYAT  OKIKIOLA ABDULAZEEZ/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: ISLAMIYAT  OKIKIOLA ABDULAZE"
                  },
                  {
                    "transaction_reference": "166EZTX2504219VS",
                    "transaction_amount": -2500,
                    "balance": 3518887,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-11T00:00:00",
                    "description": "ETZ:305/ISLAMIYAT  OKIKIOLA ABDULAZEEZ/DGBNK2:VULTE FT FROM: KAZEEM OLASUNKANMI ADELEKE TO: ISLAMIYAT  OKIKIOLA ABDULAZE"
                  },
                  {
                    "transaction_reference": "696FTRI250420waS",
                    "transaction_amount": -500000,
                    "balance": 3018888,
                    "transaction_type": "D",
                    "transaction_date": "2025-02-11T00:00:00",
                    "description": "2TEP6YNW T OLIVER FROZEN AND 000 774 2TEP6YNW  LA NG 000774  000000000774"
                  }
                ]
              },
              "client_info": {
                "name": null,
                "id": null,
                "bank_cbn_code": null,
                "bank_name": null,
                "console_url": null,
                "js_background_image": null,
                "css_url": null,
                "logo_url": null,
                "footer_text": null,
                "show_options_icon": false,
                "paginate": false,
                "paginate_count": 0,
                "options": null,
                "merchant": null,
                "colors": null,
                "meta": null
              }
            }
          },
      },
      {
        label: "Sterling",
        value: "Sterling",
        data:{
          "success": true,
          "content": {
            "message": null,
            "code": 200,
            "data": {
              "accountInformations": {
                "nuban": "0001659598",
                "branchCode": "017",
                "cus_Num": "2654bc33-39ee-47ef-a818-563c4c338c07",
                "countryCode": "NGN",
                "leD_Code": null,
                "customerFullName": "ABIODUN WILLIAMS",
                "accountGroup": "Individual",
                "mobileNumber": "08037162301",
                "email": "abiodunwilliams@hotmail.com",
                "acountType": "Savings - Regular",
                "dateOpened": "04/12/2002 00:00:00",
                "stA_Code_DB": null,
                "bvn": "22157514960",
                "responseCode": null,
                "customerStatusCode": null,
                "customerStatusDeecp": null,
                "usableBalance": "3430.74",
                "accountDescription": "",
                "address": "1 HAKEEM WILLIAMS CLOSE",
                "isHighVolume": false
              },
              "transactions": [
                {
                  "statementEntryId": "205149626233896.010001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/01/2024 00:00:00",
                  "valueDate": "03/01/2024 00:00:00",
                  "processingDate": "03/01/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "02/29/2024 19:11:36",
                  "amount": 5000.0,
                  "narrative": "Unified Payment Service Transaction?POS Purchase from?T IKOYI GLOVER COUR 007072 2057FKGQ NG",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "5000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205151457101535.010001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/01/2024 00:00:00",
                  "valueDate": "03/01/2024 00:00:00",
                  "processingDate": "03/01/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/01/2024 13:58:55",
                  "amount": 6200.0,
                  "narrative": "E-OPS- MASTERCARD POS PAYABLE(ROU)?POS Purchase from?PAYCOM NIGERIA LIMITED PLOT 8 DR NURLANG",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "6200.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205163243100991.050001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/04/2024 00:00:00",
                  "valueDate": "03/04/2024 00:00:00",
                  "processingDate": "03/04/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/02/2024 17:36:32",
                  "amount": 10000.0,
                  "narrative": "BANK?NIP From 00001324030218345500004437?5568 GTBank Plc PAYREF:000044375568?SENDER: OJO MICHAEL KEHINDE REMARK?:",
                  "creditDebit": "credit",
                  "credit": "10000.00",
                  "debit": "",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "C",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205189520166771.050001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/04/2024 00:00:00",
                  "valueDate": "03/04/2024 00:00:00",
                  "processingDate": "03/04/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/04/2024 15:39:31",
                  "amount": 25000.0,
                  "narrative": "BANK?NIP From 00001524030416381047248200?0243 Zenith Bank PAYREF:1901232412?SENDER: ENIOLA VIVIAN KARTEY REMARK?: E",
                  "creditDebit": "credit",
                  "credit": "25000.00",
                  "debit": "",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "C",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205180496853598.050001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/04/2024 00:00:00",
                  "valueDate": "03/04/2024 00:00:00",
                  "processingDate": "03/04/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/04/2024 11:59:58",
                  "amount": 273000.0,
                  "narrative": "BANK?NIP From 00001424030412575826329228?6889 Access Bank PAYREF:00001424030?4125732210680081604 SENDER: AMEH AD?OYI REMARK: TRFFRM AMEH ADOYI T",
                  "creditDebit": "credit",
                  "credit": "273000.00",
                  "debit": "",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "C",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205169807318005.050001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/04/2024 00:00:00",
                  "valueDate": "03/04/2024 00:00:00",
                  "processingDate": "03/04/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/02/2024 22:20:05",
                  "amount": 25.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "25.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205161937993673.060001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/04/2024 00:00:00",
                  "valueDate": "03/04/2024 00:00:00",
                  "processingDate": "03/04/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/02/2024 15:34:33",
                  "amount": 10.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "10.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205187034446478.010001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/04/2024 00:00:00",
                  "valueDate": "03/04/2024 00:00:00",
                  "processingDate": "03/04/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/04/2024 10:01:18",
                  "amount": 0.75,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to WASIU IBRAHIM LAJA?echanic UR",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "0.75",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205169807318005.050002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/04/2024 00:00:00",
                  "valueDate": "03/04/2024 00:00:00",
                  "processingDate": "03/04/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/02/2024 22:20:05",
                  "amount": 30000.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to JUSTINE SHALONSalary",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "30000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205187034446477.060002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/04/2024 00:00:00",
                  "valueDate": "03/04/2024 00:00:00",
                  "processingDate": "03/04/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/04/2024 10:01:17",
                  "amount": 4000.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to WASIU IBRAHIM LAJAMechanic?UR",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "4000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205169807318005.090001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/04/2024 00:00:00",
                  "valueDate": "03/04/2024 00:00:00",
                  "processingDate": "03/04/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/02/2024 22:20:05",
                  "amount": 1.88,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to JUSTINE SHALONSala?y",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "1.88",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205173780525541.100001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/04/2024 00:00:00",
                  "valueDate": "03/04/2024 00:00:00",
                  "processingDate": "03/04/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/03/2024 00:25:41",
                  "amount": 0.75,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to DAVID OGHENERUONA?BOHUR Transport Media",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "0.75",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205164282769788.060002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/04/2024 00:00:00",
                  "valueDate": "03/04/2024 00:00:00",
                  "processingDate": "03/04/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/02/2024 08:56:28",
                  "amount": 5000.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to WASIU IBRAHIM LAJAUrlR mec?anic car check",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "5000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205173780525541.060002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/04/2024 00:00:00",
                  "valueDate": "03/04/2024 00:00:00",
                  "processingDate": "03/04/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/03/2024 00:25:41",
                  "amount": 2000.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to DAVID OGHENERUONA EBOHUR T?ansport Media",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "2000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205164282769789.010001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/04/2024 00:00:00",
                  "valueDate": "03/04/2024 00:00:00",
                  "processingDate": "03/04/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/02/2024 08:56:29",
                  "amount": 0.75,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to WASIU IBRAHIM LAJA?rlR mechanic car check",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "0.75",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205161937993673.100001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/04/2024 00:00:00",
                  "valueDate": "03/04/2024 00:00:00",
                  "processingDate": "03/04/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/02/2024 15:34:33",
                  "amount": 0.75,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to ALEX OYAMA AJAKEDi?patch delivery",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "0.75",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205161937993673.060002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/04/2024 00:00:00",
                  "valueDate": "03/04/2024 00:00:00",
                  "processingDate": "03/04/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/02/2024 15:34:33",
                  "amount": 1700.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to ALEX OYAMA AJAKEDispatch d?livery",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "1700.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205185225437827.040001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/04/2024 00:00:00",
                  "valueDate": "03/04/2024 00:00:00",
                  "processingDate": "03/04/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/04/2024 07:37:07",
                  "amount": 500.0,
                  "narrative": "CREDIT SWITCH SETTLEMENT ACCOUNT?Airtime Purchase for 08037162301",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "500.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205182146037799.030001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/04/2024 00:00:00",
                  "valueDate": "03/04/2024 00:00:00",
                  "processingDate": "03/04/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/04/2024 07:36:39",
                  "amount": 1000.0,
                  "narrative": "CREDIT SWITCH SETTLEMENT ACCOUNT?Airtime Purchase for 07080004110",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "1000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205187034446477.060001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/04/2024 00:00:00",
                  "valueDate": "03/04/2024 00:00:00",
                  "processingDate": "03/04/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/04/2024 10:01:17",
                  "amount": 10.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "10.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205173780525541.060001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/04/2024 00:00:00",
                  "valueDate": "03/04/2024 00:00:00",
                  "processingDate": "03/04/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/03/2024 00:25:41",
                  "amount": 10.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "10.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205164282769788.060001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/04/2024 00:00:00",
                  "valueDate": "03/04/2024 00:00:00",
                  "processingDate": "03/04/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/02/2024 08:56:28",
                  "amount": 10.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "10.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205183342366850.020001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/04/2024 00:00:00",
                  "valueDate": "03/04/2024 00:00:00",
                  "processingDate": "03/04/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/04/2024 15:40:50",
                  "amount": 50.0,
                  "narrative": "STAMP DUTY COLLECTION ACCOUNT.?Capitalise Actvity For EMT Levy?EMT Levy FT24064H10LX",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "50.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205183367547915.010001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/04/2024 00:00:00",
                  "valueDate": "03/04/2024 00:00:00",
                  "processingDate": "03/04/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/04/2024 10:25:15",
                  "amount": 2700.0,
                  "narrative": "E-OPS- MASTERCARD POS PAYABLE(ROU)?POS Purchase from?CAFE ONE LENNOX MALL ALANG",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "2700.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205176356590255.020001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/04/2024 00:00:00",
                  "valueDate": "03/04/2024 00:00:00",
                  "processingDate": "03/04/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/03/2024 18:24:15",
                  "amount": 292.4,
                  "narrative": "SMS NOTIFICATION CHARGE FOR 2024 FE?B 1ST-29TH FEB 2024 - NGN",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "292.40",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205182784353738.050001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/04/2024 00:00:00",
                  "valueDate": "03/04/2024 00:00:00",
                  "processingDate": "03/04/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/04/2024 12:02:18",
                  "amount": 50.0,
                  "narrative": "STAMP DUTY COLLECTION ACCOUNT.?Capitalise Actvity For EMT Levy?EMT Levy FT24064WJ7XN",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "50.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205167068901037.020001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/04/2024 00:00:00",
                  "valueDate": "03/04/2024 00:00:00",
                  "processingDate": "03/04/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/02/2024 17:37:17",
                  "amount": 50.0,
                  "narrative": "STAMP DUTY COLLECTION ACCOUNT.?Capitalise Actvity For EMT Levy?EMT Levy FT24064Z0JT5",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "50.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205162411881458.010001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/04/2024 00:00:00",
                  "valueDate": "03/04/2024 00:00:00",
                  "processingDate": "03/04/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/02/2024 12:10:58",
                  "amount": 12352.0,
                  "narrative": "Unified Payment Service Transaction?POS Purchase from?NORTHWEST PETROLEUM RETKM 14 LEKKI E NG",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "12352.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205195733330372.050001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/05/2024 00:00:00",
                  "valueDate": "03/05/2024 00:00:00",
                  "processingDate": "03/05/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/05/2024 09:19:32",
                  "amount": 50.0,
                  "narrative": "STAMP DUTY COLLECTION ACCOUNT.?Capitalise Actvity For EMT Levy?EMT Levy FT24065DLGM3",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "50.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205198875436760.020001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/05/2024 00:00:00",
                  "valueDate": "03/05/2024 00:00:00",
                  "processingDate": "03/05/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/05/2024 11:06:00",
                  "amount": 2150.0,
                  "narrative": "INTESWITCH TSS ACC?Card transaction?T Milk and Honey La 000919 2TEPDC0M LANG",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "2150.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205193589920565.040001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/05/2024 00:00:00",
                  "valueDate": "03/05/2024 00:00:00",
                  "processingDate": "03/05/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/05/2024 06:36:05",
                  "amount": 20000.0,
                  "narrative": "BANK?NIP From 00001424030507351025029519?3473 Access Bank PAYREF:00001424030?5073432250969294580 SENDER: QUADRI?OLUWAFEMI AKANBI REMARK: TRFFRM",
                  "creditDebit": "credit",
                  "credit": "20000.00",
                  "debit": "",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "C",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205195779830365.010001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/05/2024 00:00:00",
                  "valueDate": "03/05/2024 00:00:00",
                  "processingDate": "03/05/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/05/2024 09:19:25",
                  "amount": 44000.0,
                  "narrative": "E-OPS- MASTERCARD POS PAYABLE(ROU)?POS Purchase from?EVERCARE HOSPITAL LEKKINO 1 ADMIRALTLANG",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "44000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205205437825504.060001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/06/2024 00:00:00",
                  "valueDate": "03/06/2024 00:00:00",
                  "processingDate": "03/06/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/06/2024 11:45:04",
                  "amount": 25.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "25.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205209884314072.100001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/06/2024 00:00:00",
                  "valueDate": "03/06/2024 00:00:00",
                  "processingDate": "03/06/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/06/2024 08:34:32",
                  "amount": 1.88,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to OLADELE JUSTUS OGU?SOLAUR Uber media",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "1.88",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205204755232472.050002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/06/2024 00:00:00",
                  "valueDate": "03/06/2024 00:00:00",
                  "processingDate": "03/06/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/06/2024 13:41:12",
                  "amount": 8000.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to DAVID OGHENERUONA EBOHUR m?dia Conference Transportation",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "8000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205205437825504.100001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/06/2024 00:00:00",
                  "valueDate": "03/06/2024 00:00:00",
                  "processingDate": "03/06/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/06/2024 11:45:04",
                  "amount": 1.88,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to FATOLU IYABO ABABI?ranges evk",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "1.88",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205209884314072.060001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/06/2024 00:00:00",
                  "valueDate": "03/06/2024 00:00:00",
                  "processingDate": "03/06/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/06/2024 08:34:32",
                  "amount": 25.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "25.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205207860225385.020001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/06/2024 00:00:00",
                  "valueDate": "03/06/2024 00:00:00",
                  "processingDate": "03/06/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/06/2024 11:43:05",
                  "amount": 0.75,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to MBAM EZEKIELEar ph?ne",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "0.75",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205204755232472.050001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/06/2024 00:00:00",
                  "valueDate": "03/06/2024 00:00:00",
                  "processingDate": "03/06/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/06/2024 13:41:12",
                  "amount": 25.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "25.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205207860225384.050002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/06/2024 00:00:00",
                  "valueDate": "03/06/2024 00:00:00",
                  "processingDate": "03/06/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/06/2024 11:43:04",
                  "amount": 1500.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to MBAM EZEKIELEar phone",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "1500.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205205639347214.010001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/06/2024 00:00:00",
                  "valueDate": "03/06/2024 00:00:00",
                  "processingDate": "03/06/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/06/2024 17:46:54",
                  "amount": 5000.0,
                  "narrative": "Unified Payment Service Transaction?POS Purchase from?PAYCOM NIGERIA LIMITED PLOT 9, DR. N NG",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "5000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205207860225384.050001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/06/2024 00:00:00",
                  "valueDate": "03/06/2024 00:00:00",
                  "processingDate": "03/06/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/06/2024 11:43:04",
                  "amount": 10.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "10.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205206279788899.010001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/06/2024 00:00:00",
                  "valueDate": "03/06/2024 00:00:00",
                  "processingDate": "03/06/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/06/2024 01:34:59",
                  "amount": 900.0,
                  "narrative": "E-OPS- MASTERCARD POS PAYABLE(ROU)?Web Purchase from?DLO*Spotify Lagos NG",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "900.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205209060446882.010001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/06/2024 00:00:00",
                  "valueDate": "03/06/2024 00:00:00",
                  "processingDate": "03/06/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/06/2024 17:41:22",
                  "amount": 9500.0,
                  "narrative": "Unified Payment Service Transaction?POS Purchase from?PAYCOM NIGERIA LIMITED PLOT 9, DR. N NG",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "9500.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205204755232472.090001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/06/2024 00:00:00",
                  "valueDate": "03/06/2024 00:00:00",
                  "processingDate": "03/06/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/06/2024 13:41:12",
                  "amount": 1.88,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to DAVID OGHENERUONA?BOHUR media Conference Transportat?on",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "1.88",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205209884314072.060002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/06/2024 00:00:00",
                  "valueDate": "03/06/2024 00:00:00",
                  "processingDate": "03/06/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/06/2024 08:34:32",
                  "amount": 8000.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to OLADELE JUSTUS OGUNSOLAUR?ber media",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "8000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205205437825504.060002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/06/2024 00:00:00",
                  "valueDate": "03/06/2024 00:00:00",
                  "processingDate": "03/06/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/06/2024 11:45:04",
                  "amount": 6000.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to FATOLU IYABO ABABIoranges?vk",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "6000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205210959023332.010001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/07/2024 00:00:00",
                  "valueDate": "03/07/2024 00:00:00",
                  "processingDate": "03/07/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/07/2024 14:55:32",
                  "amount": 2180.0,
                  "narrative": "E-OPS- VERVE POS PAYABLE(ROU)?POS Purchase from?OPAY DIGITAL SERVICES LPLOT 9 DR NURLANG",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "2180.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205214873192376.040001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/07/2024 00:00:00",
                  "valueDate": "03/07/2024 00:00:00",
                  "processingDate": "03/07/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/07/2024 06:19:36",
                  "amount": 190000.0,
                  "narrative": "ONEBank Pool Account?OneBank Transfer to ABIODUN WILLIAM?S from ABIODUN WILLIAMS(UR funds)",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "190000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205228320299909.090001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/08/2024 00:00:00",
                  "valueDate": "03/08/2024 00:00:00",
                  "processingDate": "03/08/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/08/2024 12:11:49",
                  "amount": 3.75,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to WASIU IBRAHIM LAJA?shaft 50k front linkage 12k tiero?1s",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "3.75",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205225268321743.010001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/08/2024 00:00:00",
                  "valueDate": "03/08/2024 00:00:00",
                  "processingDate": "03/08/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/08/2024 18:15:43",
                  "amount": 12250.0,
                  "narrative": "E-OPS- MASTERCARD POS PAYABLE(ROU)?POS Purchase from?BLENCO BUSINESS VENTUREBLENCO SUPERMXXNG",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "12250.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205228320299909.050002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/08/2024 00:00:00",
                  "valueDate": "03/08/2024 00:00:00",
                  "processingDate": "03/08/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/08/2024 12:11:49",
                  "amount": 82000.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to WASIU IBRAHIM LAJA2 shaft?0k front linkage 12k tierod 1s",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "82000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205227599089477.060001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/08/2024 00:00:00",
                  "valueDate": "03/08/2024 00:00:00",
                  "processingDate": "03/08/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/08/2024 09:17:57",
                  "amount": 10.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "10.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205227599089477.060002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/08/2024 00:00:00",
                  "valueDate": "03/08/2024 00:00:00",
                  "processingDate": "03/08/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/08/2024 09:17:57",
                  "amount": 2300.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to KYANUM VICTOR DECHIELuber?ispatch delivery",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "2300.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205220417318884.010001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/08/2024 00:00:00",
                  "valueDate": "03/08/2024 00:00:00",
                  "processingDate": "03/08/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/08/2024 17:28:04",
                  "amount": 18770.0,
                  "narrative": "E-OPS- MASTERCARD POS PAYABLE(ROU)?POS Purchase from?RENEE MALLS LIMITED LEK45 CAMEROUN OLANG",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "18770.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205227599089477.100001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/08/2024 00:00:00",
                  "valueDate": "03/08/2024 00:00:00",
                  "processingDate": "03/08/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/08/2024 09:17:57",
                  "amount": 0.75,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to KYANUM VICTOR DECH?ELuber dispatch delivery",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "0.75",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205225707671579.010001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/08/2024 00:00:00",
                  "valueDate": "03/08/2024 00:00:00",
                  "processingDate": "03/08/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/08/2024 04:19:39",
                  "amount": 25000.0,
                  "narrative": "E-OPS- MASTERCARD POS PAYABLE(ROU)?Web Purchase from?MTN NIGERIA COMMUNICAT 4814 NG",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "25000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205228320299909.050001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/08/2024 00:00:00",
                  "valueDate": "03/08/2024 00:00:00",
                  "processingDate": "03/08/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/08/2024 12:11:49",
                  "amount": 50.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "50.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205223948324633.020001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/08/2024 00:00:00",
                  "valueDate": "03/08/2024 00:00:00",
                  "processingDate": "03/08/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/08/2024 19:03:53",
                  "amount": 20715.0,
                  "narrative": "INTESWITCH TSS ACC?Card transaction?170992453669@2HYN0002HYDROGEN LIMITED NG",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "20715.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205226041519344.040001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/08/2024 00:00:00",
                  "valueDate": "03/08/2024 00:00:00",
                  "processingDate": "03/08/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/08/2024 17:35:44",
                  "amount": 3800.0,
                  "narrative": "BANK?NIP From 00001524030818341389997300?9848 Zenith Bank PAYREF:1908070473?SENDER: ENIOLA VIVIAN KARTEY REMARK?: Ap",
                  "creditDebit": "credit",
                  "credit": "3800.00",
                  "debit": "",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "C",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205254006045176.060001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/11/2024 00:00:00",
                  "valueDate": "03/11/2024 00:00:00",
                  "processingDate": "03/11/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/11/2024 08:19:36",
                  "amount": 1.88,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to ANNE kelechi ekezi?ePrinter service UR",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "1.88",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205254519555396.020001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/11/2024 00:00:00",
                  "valueDate": "03/11/2024 00:00:00",
                  "processingDate": "03/11/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/11/2024 11:09:56",
                  "amount": 1.88,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to EKEZIE ANNEUR Prin?ter Servicing",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "1.88",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205255407545145.030001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/11/2024 00:00:00",
                  "valueDate": "03/11/2024 00:00:00",
                  "processingDate": "03/11/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/11/2024 08:19:05",
                  "amount": 1.88,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to ANNE kelechi ekezi?ePrinter service UR",
                  "creditDebit": "credit",
                  "credit": "1.88",
                  "debit": "",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "C",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205254006045176.020002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/11/2024 00:00:00",
                  "valueDate": "03/11/2024 00:00:00",
                  "processingDate": "03/11/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/11/2024 08:19:36",
                  "amount": 30000.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to ANNE kelechi ekeziePrinter?service UR",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "30000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205252757145172.030002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/11/2024 00:00:00",
                  "valueDate": "03/11/2024 00:00:00",
                  "processingDate": "03/11/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/11/2024 08:19:32",
                  "amount": 30000.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to ANNE kelechi ekeziePrinter?service UR",
                  "creditDebit": "credit",
                  "credit": "30000.00",
                  "debit": "",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "C",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205254519555395.060001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/11/2024 00:00:00",
                  "valueDate": "03/11/2024 00:00:00",
                  "processingDate": "03/11/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/11/2024 11:09:55",
                  "amount": 25.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "25.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205252757145172.030001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/11/2024 00:00:00",
                  "valueDate": "03/11/2024 00:00:00",
                  "processingDate": "03/11/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/11/2024 08:19:32",
                  "amount": 25.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "credit",
                  "credit": "25.00",
                  "debit": "",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "C",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205242674292165.060001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/11/2024 00:00:00",
                  "valueDate": "03/11/2024 00:00:00",
                  "processingDate": "03/11/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/10/2024 17:36:05",
                  "amount": 25.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "25.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205238730375872.060001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/11/2024 00:00:00",
                  "valueDate": "03/11/2024 00:00:00",
                  "processingDate": "03/11/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/09/2024 09:17:52",
                  "amount": 25.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "25.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205254006045176.020001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/11/2024 00:00:00",
                  "valueDate": "03/11/2024 00:00:00",
                  "processingDate": "03/11/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/11/2024 08:19:36",
                  "amount": 25.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "25.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205241613589203.050001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/11/2024 00:00:00",
                  "valueDate": "03/11/2024 00:00:00",
                  "processingDate": "03/11/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/10/2024 16:46:43",
                  "amount": 15000.0,
                  "narrative": "BANK?NIP From 00001324031017451700006294?8749 GTBank Plc PAYREF:000062948749?SENDER: KARTEY ENIOLA VIVIAN REMAR?K:",
                  "creditDebit": "credit",
                  "credit": "15000.00",
                  "debit": "",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "C",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205254519555395.060002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/11/2024 00:00:00",
                  "valueDate": "03/11/2024 00:00:00",
                  "processingDate": "03/11/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/11/2024 11:09:56",
                  "amount": 30000.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to EKEZIE ANNEUR Printer Serv?icing",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "30000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205238730375872.060002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/11/2024 00:00:00",
                  "valueDate": "03/11/2024 00:00:00",
                  "processingDate": "03/11/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/09/2024 09:17:52",
                  "amount": 7000.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to UDEME OSSEM IKECHUKWUUR Me?ia Uber",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "7000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205238730375872.100001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/11/2024 00:00:00",
                  "valueDate": "03/11/2024 00:00:00",
                  "processingDate": "03/11/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/09/2024 09:17:52",
                  "amount": 1.88,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to UDEME OSSEM IKECHU?WUUR Media Uber",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "1.88",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205242674292165.100001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/11/2024 00:00:00",
                  "valueDate": "03/11/2024 00:00:00",
                  "processingDate": "03/11/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/10/2024 17:36:05",
                  "amount": 1.88,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to WASIU IBRAHIM LAJA?car Labour",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "1.88",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205242674292165.060002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/11/2024 00:00:00",
                  "valueDate": "03/11/2024 00:00:00",
                  "processingDate": "03/11/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/10/2024 17:36:05",
                  "amount": 10000.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to WASIU IBRAHIM LAJAcar Labo?ur",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "10000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205261498328930.040001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/12/2024 00:00:00",
                  "valueDate": "03/12/2024 00:00:00",
                  "processingDate": "03/12/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/12/2024 07:35:30",
                  "amount": 1.88,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to ALICE QUEEN DENNIS?Hair",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "1.88",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205267584438620.050001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/12/2024 00:00:00",
                  "valueDate": "03/12/2024 00:00:00",
                  "processingDate": "03/12/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/12/2024 10:17:00",
                  "amount": 10.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "10.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205267584438620.050002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/12/2024 00:00:00",
                  "valueDate": "03/12/2024 00:00:00",
                  "processingDate": "03/12/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/12/2024 10:17:00",
                  "amount": 5000.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to EDJOR PETER OGHENEBRUKEUR?Generator Guy Transportation",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "5000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205262102220843.020001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/12/2024 00:00:00",
                  "valueDate": "03/11/2024 00:00:00",
                  "processingDate": "03/12/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/12/2024 05:20:43",
                  "amount": 50.0,
                  "narrative": "STAMP DUTY COLLECTION ACCOUNT.?Capitalise Actvity For EMT Levy?EMT Levy FT24071RNP34",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "50.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205261498328930.000002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/12/2024 00:00:00",
                  "valueDate": "03/12/2024 00:00:00",
                  "processingDate": "03/12/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/12/2024 07:35:30",
                  "amount": 12000.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to ALICE QUEEN DENNISHair",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "12000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205261498328930.000001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/12/2024 00:00:00",
                  "valueDate": "03/12/2024 00:00:00",
                  "processingDate": "03/12/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/12/2024 07:35:30",
                  "amount": 25.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "25.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205267584438621.020001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/12/2024 00:00:00",
                  "valueDate": "03/12/2024 00:00:00",
                  "processingDate": "03/12/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/12/2024 10:17:01",
                  "amount": 0.75,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to EDJOR PETER OGHENE?BRUKEUR Generator Guy Transportati?ion",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "0.75",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205291395616804.020001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/15/2024 00:00:00",
                  "valueDate": "03/15/2024 00:00:00",
                  "processingDate": "03/15/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/15/2024 15:33:24",
                  "amount": 50.0,
                  "narrative": "STAMP DUTY COLLECTION ACCOUNT.?Capitalise Actvity For EMT Levy?EMT Levy FT24075JD67L",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "50.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205293437216745.040001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/15/2024 00:00:00",
                  "valueDate": "03/15/2024 00:00:00",
                  "processingDate": "03/15/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/15/2024 15:32:25",
                  "amount": 40000.0,
                  "narrative": "BANK?OneBank Transfer to ABIODUN WILLIAM?S from ABIODUN WILLIAMS",
                  "creditDebit": "credit",
                  "credit": "40000.00",
                  "debit": "",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "C",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205292492226250.050001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/15/2024 00:00:00",
                  "valueDate": "03/15/2024 00:00:00",
                  "processingDate": "03/15/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/15/2024 18:10:50",
                  "amount": 25.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "25.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205292492226250.050002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/15/2024 00:00:00",
                  "valueDate": "03/15/2024 00:00:00",
                  "processingDate": "03/15/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/15/2024 18:10:50",
                  "amount": 10000.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to WASIU IBRAHIM LAJACar UR",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "10000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205292492226250.090001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/15/2024 00:00:00",
                  "valueDate": "03/15/2024 00:00:00",
                  "processingDate": "03/15/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/15/2024 18:10:50",
                  "amount": 1.88,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to WASIU IBRAHIM LAJA?Car UR",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "1.88",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205320663266108.050001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/18/2024 00:00:00",
                  "valueDate": "03/18/2024 00:00:00",
                  "processingDate": "03/18/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/18/2024 12:48:28",
                  "amount": 25.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "25.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205305320617466.060001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/18/2024 00:00:00",
                  "valueDate": "03/18/2024 00:00:00",
                  "processingDate": "03/18/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/16/2024 19:31:06",
                  "amount": 10.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "10.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205301120395062.090001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/18/2024 00:00:00",
                  "valueDate": "03/18/2024 00:00:00",
                  "processingDate": "03/18/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/16/2024 13:17:42",
                  "amount": 0.75,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to Dauda LukaDispatch?Delivery",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "0.75",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205320663266108.050002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/18/2024 00:00:00",
                  "valueDate": "03/18/2024 00:00:00",
                  "processingDate": "03/18/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/18/2024 12:48:28",
                  "amount": 7000.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to FATOLU IYABO ABABIOranges?EVK",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "7000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205300366570915.090001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/18/2024 00:00:00",
                  "valueDate": "03/18/2024 00:00:00",
                  "processingDate": "03/18/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/16/2024 06:35:15",
                  "amount": 1.88,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to WILLIAMS ABIODUN H?AMEEDAT",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "1.88",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205300366570915.050002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/18/2024 00:00:00",
                  "valueDate": "03/18/2024 00:00:00",
                  "processingDate": "03/18/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/16/2024 06:35:15",
                  "amount": 10000.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to WILLIAMS ABIODUN HAMEEDAT",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "10000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205305320617466.060002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/18/2024 00:00:00",
                  "valueDate": "03/18/2024 00:00:00",
                  "processingDate": "03/18/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/16/2024 19:31:06",
                  "amount": 3300.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to Dada Rotimi Sheudispatch d?elivery",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "3300.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205301120395062.050002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/18/2024 00:00:00",
                  "valueDate": "03/18/2024 00:00:00",
                  "processingDate": "03/18/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/16/2024 13:17:42",
                  "amount": 1500.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to Dauda LukaDispatch Deliver?y",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "1500.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205305320617467.000001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/18/2024 00:00:00",
                  "valueDate": "03/18/2024 00:00:00",
                  "processingDate": "03/18/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/16/2024 19:31:07",
                  "amount": 0.75,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to Dada Rotimi Sheudi?spatch delivery",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "0.75",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205320663266109.000001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/18/2024 00:00:00",
                  "valueDate": "03/18/2024 00:00:00",
                  "processingDate": "03/18/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/18/2024 12:48:29",
                  "amount": 1.88,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to FATOLU IYABO ABABI?Oranges EVK",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "1.88",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205300366570915.050001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/18/2024 00:00:00",
                  "valueDate": "03/18/2024 00:00:00",
                  "processingDate": "03/18/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/16/2024 06:35:15",
                  "amount": 25.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "25.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205301120395062.050001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/18/2024 00:00:00",
                  "valueDate": "03/18/2024 00:00:00",
                  "processingDate": "03/18/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/16/2024 13:17:42",
                  "amount": 10.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "10.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205328439396562.030001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/19/2024 00:00:00",
                  "valueDate": "03/19/2024 00:00:00",
                  "processingDate": "03/19/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/18/2024 21:16:02",
                  "amount": 3500.0,
                  "narrative": "CREDIT SWITCH SETTLEMENT ACCOUNT?Airtime Purchase for 08037162301",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "3500.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205336551057288.060002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/19/2024 00:00:00",
                  "valueDate": "03/19/2024 00:00:00",
                  "processingDate": "03/19/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/19/2024 14:08:08",
                  "amount": 30000.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to AYOOLA IFEANYI OJOOil Filt?er",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "30000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205336551057288.060001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/19/2024 00:00:00",
                  "valueDate": "03/19/2024 00:00:00",
                  "processingDate": "03/19/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/19/2024 14:08:08",
                  "amount": 25.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "25.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205336551057288.100001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/19/2024 00:00:00",
                  "valueDate": "03/19/2024 00:00:00",
                  "processingDate": "03/19/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/19/2024 14:08:08",
                  "amount": 1.88,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to AYOOLA IFEANYI OJO?Oil Filter",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "1.88",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205339254963326.010001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/19/2024 00:00:00",
                  "valueDate": "03/19/2024 00:00:00",
                  "processingDate": "03/19/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/19/2024 15:48:46",
                  "amount": 11000.0,
                  "narrative": "Unified Payment Service Transaction?POS Purchase from?VFD MICROFINANCE BANK A L NG",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "11000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205330210457257.050001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/19/2024 00:00:00",
                  "valueDate": "03/19/2024 00:00:00",
                  "processingDate": "03/19/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/19/2024 14:07:37",
                  "amount": 50000.0,
                  "narrative": "BANK?OneBank Transfer to ABIODUN WILLIAM?S from ABIODUN WILLIAMS(UR)",
                  "creditDebit": "credit",
                  "credit": "50000.00",
                  "debit": "",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "C",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205339988857337.050001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/19/2024 00:00:00",
                  "valueDate": "03/19/2024 00:00:00",
                  "processingDate": "03/19/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/19/2024 14:08:57",
                  "amount": 50.0,
                  "narrative": "STAMP DUTY COLLECTION ACCOUNT.?Capitalise Actvity For EMT Levy?EMT Levy FT24079FZCY3",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "50.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205337614256271.010001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/19/2024 00:00:00",
                  "valueDate": "03/19/2024 00:00:00",
                  "processingDate": "03/19/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/19/2024 13:51:11",
                  "amount": 500.0,
                  "narrative": "CREDIT SWITCH SETTLEMENT ACCOUNT?Airtime Purchase for 08037162301",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "500.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205343162155037.030002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/20/2024 00:00:00",
                  "valueDate": "03/20/2024 00:00:00",
                  "processingDate": "03/20/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/20/2024 17:17:17",
                  "amount": 10289.25,
                  "narrative": "INTESWITCH TSS ACC?Card transaction?63, ADEKUNLE FAJUYI IKEJA LANG",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "10289.25",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205342530647283.020001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/20/2024 00:00:00",
                  "valueDate": "03/20/2024 00:00:00",
                  "processingDate": "03/20/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/20/2024 15:08:03",
                  "amount": 50.0,
                  "narrative": "STAMP DUTY COLLECTION ACCOUNT.?Capitalise Actvity For EMT Levy?EMT Levy FT24080G0270",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "50.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205341098252391.060001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/20/2024 00:00:00",
                  "valueDate": "03/20/2024 00:00:00",
                  "processingDate": "03/20/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/20/2024 16:33:11",
                  "amount": 1.88,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to GODSWILL FRANCISUR?Ac servicing",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "1.88",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205347368847263.050001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/20/2024 00:00:00",
                  "valueDate": "03/20/2024 00:00:00",
                  "processingDate": "03/20/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/20/2024 15:07:43",
                  "amount": 296000.0,
                  "narrative": "BANK?NIP From 00001324032016055100008377?2648 GTBank Plc PAYREF:000083772648?SENDER: AMEH PAUL ADOYI REMARK: Of?fice bills",
                  "creditDebit": "credit",
                  "credit": "296000.00",
                  "debit": "",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "C",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205343162155037.030001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/20/2024 00:00:00",
                  "valueDate": "03/20/2024 00:00:00",
                  "processingDate": "03/20/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/20/2024 17:17:17",
                  "amount": 10.75,
                  "narrative": "AC-NGN1250000022001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "10.75",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205341098252391.020001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/20/2024 00:00:00",
                  "valueDate": "03/20/2024 00:00:00",
                  "processingDate": "03/20/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/20/2024 16:33:11",
                  "amount": 25.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "25.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205332536577311.010001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/20/2024 00:00:00",
                  "valueDate": "03/20/2024 00:00:00",
                  "processingDate": "03/20/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/19/2024 19:41:51",
                  "amount": 5000.0,
                  "narrative": "E-OPS- MASTERCARD POS PAYABLE(ROU)?POS Purchase from?H2O ULTIMATE SUPREME VELA LANG",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "5000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205348170644886.010001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/20/2024 00:00:00",
                  "valueDate": "03/20/2024 00:00:00",
                  "processingDate": "03/20/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/20/2024 14:28:06",
                  "amount": 7600.0,
                  "narrative": "E-OPS- MASTERCARD POS PAYABLE(ROU)?POS Purchase from?MULTIKER 7 BISHOP ABOYLANG",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "7600.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205345994852089.060001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/20/2024 00:00:00",
                  "valueDate": "03/20/2024 00:00:00",
                  "processingDate": "03/20/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/20/2024 16:28:09",
                  "amount": 50.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "50.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205345720652555.050001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/20/2024 00:00:00",
                  "valueDate": "03/20/2024 00:00:00",
                  "processingDate": "03/20/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/20/2024 16:35:55",
                  "amount": 25.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "25.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205345994852090.020001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/20/2024 00:00:00",
                  "valueDate": "03/20/2024 00:00:00",
                  "processingDate": "03/20/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/20/2024 16:28:10",
                  "amount": 3.75,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to CREDENCE ENERGYUR?Diesel N1450 L 150",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "3.75",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205345720652555.050002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/20/2024 00:00:00",
                  "valueDate": "03/20/2024 00:00:00",
                  "processingDate": "03/20/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/20/2024 16:35:55",
                  "amount": 11000.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to DAVID OGHENERUONA EBOHUR T?ransportation media",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "11000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205341098252391.020002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/20/2024 00:00:00",
                  "valueDate": "03/20/2024 00:00:00",
                  "processingDate": "03/20/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/20/2024 16:33:11",
                  "amount": 40000.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to GODSWILL FRANCISUR Ac serv?icing",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "40000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205335052476075.030001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/20/2024 00:00:00",
                  "valueDate": "03/20/2024 00:00:00",
                  "processingDate": "03/20/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/19/2024 19:21:15",
                  "amount": 500.0,
                  "narrative": "CREDIT SWITCH SETTLEMENT ACCOUNT?Airtime Purchase for 07080004110",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "500.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205331438077176.050001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/20/2024 00:00:00",
                  "valueDate": "03/20/2024 00:00:00",
                  "processingDate": "03/20/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/19/2024 19:39:36",
                  "amount": 5000.0,
                  "narrative": "BANK?NIP From 00001324031920374300008221?3520 GTBank Plc PAYREF:000082213520?SENDER: KARTEY ENIOLA VIVIAN REMAR?K:",
                  "creditDebit": "credit",
                  "credit": "5000.00",
                  "debit": "",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "C",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205345994852089.060002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/20/2024 00:00:00",
                  "valueDate": "03/20/2024 00:00:00",
                  "processingDate": "03/20/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/20/2024 16:28:09",
                  "amount": 217500.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to CREDENCE ENERGYUR Diesel N?1450 L 150",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "217500.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205345720652555.090001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/20/2024 00:00:00",
                  "valueDate": "03/20/2024 00:00:00",
                  "processingDate": "03/20/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/20/2024 16:35:55",
                  "amount": 1.88,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to DAVID OGHENERUONA?EBOHUR Transportation media",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "1.88",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205357682400259.040001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/21/2024 00:00:00",
                  "valueDate": "03/21/2024 00:00:00",
                  "processingDate": "03/21/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/21/2024 05:50:59",
                  "amount": 1000.0,
                  "narrative": "CREDIT SWITCH SETTLEMENT ACCOUNT?Airtime Purchase for 09044681891",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "1000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205344367860007.100001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/21/2024 00:00:00",
                  "valueDate": "03/21/2024 00:00:00",
                  "processingDate": "03/21/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/20/2024 18:40:07",
                  "amount": 0.75,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to MARKUS JANARDispat?ch delivery",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "0.75",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205344367860007.060002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/21/2024 00:00:00",
                  "valueDate": "03/21/2024 00:00:00",
                  "processingDate": "03/21/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/20/2024 18:40:07",
                  "amount": 1560.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to MARKUS JANARDispatch deliv?ery",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "1560.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205359644200344.030001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/21/2024 00:00:00",
                  "valueDate": "03/21/2024 00:00:00",
                  "processingDate": "03/21/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/21/2024 05:52:24",
                  "amount": 1000.0,
                  "narrative": "CREDIT SWITCH SETTLEMENT ACCOUNT?Airtime Purchase for 09044681891",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "1000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205358544200350.020001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/21/2024 00:00:00",
                  "valueDate": "03/21/2024 00:00:00",
                  "processingDate": "03/21/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/21/2024 05:52:30",
                  "amount": 1000.0,
                  "narrative": "CREDIT SWITCH SETTLEMENT ACCOUNT?Airtime Purchase for 09044681891",
                  "creditDebit": "credit",
                  "credit": "1000.00",
                  "debit": "",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "C",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205356635500293.030001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/21/2024 00:00:00",
                  "valueDate": "03/21/2024 00:00:00",
                  "processingDate": "03/21/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/21/2024 05:51:33",
                  "amount": 1000.0,
                  "narrative": "CREDIT SWITCH SETTLEMENT ACCOUNT?Airtime Purchase for 09044681891",
                  "creditDebit": "credit",
                  "credit": "1000.00",
                  "debit": "",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "C",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205358525077309.050001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/21/2024 00:00:00",
                  "valueDate": "03/21/2024 00:00:00",
                  "processingDate": "03/21/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/20/2024 23:28:30",
                  "amount": 50.0,
                  "narrative": "STAMP DUTY COLLECTION ACCOUNT.?Capitalise Actvity For EMT Levy?EMT Levy FT24081DM1HT",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "50.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205346969260231.020001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/21/2024 00:00:00",
                  "valueDate": "03/21/2024 00:00:00",
                  "processingDate": "03/21/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/20/2024 18:43:51",
                  "amount": 99512.5,
                  "narrative": "SBN.ORDERING.CUST?Card transaction?001416670824;NXG :TRFFRM ADEREMI SAKA T",
                  "creditDebit": "credit",
                  "credit": "99512.50",
                  "debit": "",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "C",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205353413700073.050001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/21/2024 00:00:00",
                  "valueDate": "03/21/2024 00:00:00",
                  "processingDate": "03/21/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/21/2024 05:47:53",
                  "amount": 25.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "25.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205353413700073.090001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/21/2024 00:00:00",
                  "valueDate": "03/21/2024 00:00:00",
                  "processingDate": "03/21/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/21/2024 05:47:53",
                  "amount": 1.88,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to ALICE QUEEN DENNIS?Hair",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "1.88",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205353413700073.050002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/21/2024 00:00:00",
                  "valueDate": "03/21/2024 00:00:00",
                  "processingDate": "03/21/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/21/2024 05:47:53",
                  "amount": 15000.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to ALICE QUEEN DENNISHair",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "15000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205344367860007.060001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/21/2024 00:00:00",
                  "valueDate": "03/21/2024 00:00:00",
                  "processingDate": "03/21/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/20/2024 18:40:07",
                  "amount": 10.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "10.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205361508977169.010001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/22/2024 00:00:00",
                  "valueDate": "03/22/2024 00:00:00",
                  "processingDate": "03/22/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/22/2024 03:12:49",
                  "amount": 50.0,
                  "narrative": "MASTERCARD QUATERLY CARD MAINTENANC?E CHARGE FOR Q1 2024",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "50.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205361508977170.020001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/22/2024 00:00:00",
                  "valueDate": "03/22/2024 00:00:00",
                  "processingDate": "03/22/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/22/2024 03:12:50",
                  "amount": 3.75,
                  "narrative": "VALUE ADDED TAX?VERVE QUATERLY CARD MAINTENANCE VAT?FOR Q1 2024",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "3.75",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205356403561525.040001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/22/2024 00:00:00",
                  "valueDate": "03/22/2024 00:00:00",
                  "processingDate": "03/22/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/21/2024 22:52:05",
                  "amount": 200000.0,
                  "narrative": "BANK?NIP From 00001524032123501799708002?3773 Zenith Bank PAYREF:1926331299?SENDER: CHIMA IHEME REMARK: Birthda?y Snacks",
                  "creditDebit": "credit",
                  "credit": "200000.00",
                  "debit": "",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "C",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205361508977169.030001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/22/2024 00:00:00",
                  "valueDate": "03/22/2024 00:00:00",
                  "processingDate": "03/22/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/22/2024 03:12:49",
                  "amount": 3.75,
                  "narrative": "VALUE ADDED TAX?MASTERCARD QUATERLY CARD MAINTENANC?E VAT FOR Q1 2024",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "3.75",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205361508977170.000001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/22/2024 00:00:00",
                  "valueDate": "03/22/2024 00:00:00",
                  "processingDate": "03/22/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/22/2024 03:12:50",
                  "amount": 50.0,
                  "narrative": "VERVE QUATERLY CARD MAINTENANCE CHA?RGE FOR Q1 2024",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "50.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205367853067295.050001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/22/2024 00:00:00",
                  "valueDate": "03/22/2024 00:00:00",
                  "processingDate": "03/22/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/22/2024 00:28:15",
                  "amount": 50.0,
                  "narrative": "STAMP DUTY COLLECTION ACCOUNT.?Capitalise Actvity For EMT Levy?EMT Levy FT240826FPMB",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "50.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205375630749485.060001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/25/2024 00:00:00",
                  "valueDate": "03/25/2024 00:00:00",
                  "processingDate": "03/25/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/22/2024 23:18:05",
                  "amount": 1.88,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to DAVID OGHENERUONA?EBOHMediaUber Transportion",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "1.88",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205375630749485.020002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/25/2024 00:00:00",
                  "valueDate": "03/25/2024 00:00:00",
                  "processingDate": "03/25/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/22/2024 23:18:05",
                  "amount": 6800.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to DAVID OGHENERUONA EBOHMedi?aUber Transportion",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "6800.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205378292704178.100001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/25/2024 00:00:00",
                  "valueDate": "03/25/2024 00:00:00",
                  "processingDate": "03/25/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/23/2024 14:29:38",
                  "amount": 1.88,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to WILLIAMS ABIODUN H?AMEEDAT",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "1.88",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205378292704178.060002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/25/2024 00:00:00",
                  "valueDate": "03/25/2024 00:00:00",
                  "processingDate": "03/25/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/23/2024 14:29:38",
                  "amount": 10000.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to WILLIAMS ABIODUN HAMEEDAT",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "10000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205375630749485.020001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/25/2024 00:00:00",
                  "valueDate": "03/25/2024 00:00:00",
                  "processingDate": "03/25/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/22/2024 23:18:05",
                  "amount": 25.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "25.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205378292704178.060001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/25/2024 00:00:00",
                  "valueDate": "03/25/2024 00:00:00",
                  "processingDate": "03/25/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/23/2024 14:29:38",
                  "amount": 25.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "25.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205377000262181.010001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/25/2024 00:00:00",
                  "valueDate": "03/25/2024 00:00:00",
                  "processingDate": "03/25/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/23/2024 02:49:41",
                  "amount": 1660.0,
                  "narrative": "E-OPS- MASTERCARD POS PAYABLE(ROU)?Web Purchase from?UBER*FLW LAGOS ISLAND NG",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "1660.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205407265041428.030001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/26/2024 00:00:00",
                  "valueDate": "03/26/2024 00:00:00",
                  "processingDate": "03/26/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/26/2024 08:23:48",
                  "amount": 6.98,
                  "narrative": "MTN TELCO CHARGE ACCOUNT?USSD/MTN USSD charge for 2603 Ref:9?464339397/ABIODUN.W/MTN.TCA",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "6.98",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205403265441429.000001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/26/2024 00:00:00",
                  "valueDate": "03/26/2024 00:00:00",
                  "processingDate": "03/26/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/26/2024 08:23:49",
                  "amount": 25.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "25.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205401112546142.050001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/26/2024 00:00:00",
                  "valueDate": "03/26/2024 00:00:00",
                  "processingDate": "03/26/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/26/2024 09:42:22",
                  "amount": 25.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "25.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205402760456729.010001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/26/2024 00:00:00",
                  "valueDate": "03/26/2024 00:00:00",
                  "processingDate": "03/26/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/26/2024 12:38:49",
                  "amount": 15175.0,
                  "narrative": "E-OPS- MASTERCARD POS PAYABLE(ROU)?POS Purchase from?PRINCE EBEANO SUPERMARKPRINCE EBEANOXXNG",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "15175.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205409975659069.050001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/26/2024 00:00:00",
                  "valueDate": "03/26/2024 00:00:00",
                  "processingDate": "03/26/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/26/2024 13:17:49",
                  "amount": 500.0,
                  "narrative": "CREDIT SWITCH AIRTIME SUSPENSE?Airtime purchase for 08037162301",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "500.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205391384699355.000001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/26/2024 00:00:00",
                  "valueDate": "03/26/2024 00:00:00",
                  "processingDate": "03/26/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/25/2024 20:42:35",
                  "amount": 27800.0,
                  "narrative": "UP POS SERVICE PAYABLE?POS Purchase from?T KRUGEROSS ENTERPR 013055 2070UX77 NG",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "27800.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205403265441429.040001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/26/2024 00:00:00",
                  "valueDate": "03/26/2024 00:00:00",
                  "processingDate": "03/26/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/26/2024 08:23:49",
                  "amount": 1.88,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ TRF 1700000 FRM ABIODUN WI?LLIAMS TO ETIENNE CODJO",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "1.88",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205401112546142.050002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/26/2024 00:00:00",
                  "valueDate": "03/26/2024 00:00:00",
                  "processingDate": "03/26/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/26/2024 09:42:22",
                  "amount": 10000.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to BALOGUN JELILI ADEKUNLE UR?Car Road Worthiness",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "10000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205403265441429.000002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/26/2024 00:00:00",
                  "valueDate": "03/26/2024 00:00:00",
                  "processingDate": "03/26/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/26/2024 08:23:49",
                  "amount": 17000.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?TRF 1700000 FRM ABIODUN WILLIAMS T?O ETIENNE CODJO",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "17000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205404111672405.020001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/26/2024 00:00:00",
                  "valueDate": "03/26/2024 00:00:00",
                  "processingDate": "03/26/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/26/2024 17:00:05",
                  "amount": 300.0,
                  "narrative": "INTESWITCH TSS ACC?Card transaction?171147231223@2HYN0002HYDROGEN LIMITED NG",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "300.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205401112546142.090001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/26/2024 00:00:00",
                  "valueDate": "03/26/2024 00:00:00",
                  "processingDate": "03/26/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/26/2024 09:42:22",
                  "amount": 1.88,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to BALOGUN JELILI ADE?KUNLE UR Car Road Worthiness",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "1.88",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205401822372281.010001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/26/2024 00:00:00",
                  "valueDate": "03/26/2024 00:00:00",
                  "processingDate": "03/26/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/26/2024 16:58:01",
                  "amount": 6670.0,
                  "narrative": "UP POS SERVICE PAYABLE?POS Purchase from?MILK AND HONEY LIVVYSTW47, ADMIRALTY NG",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "6670.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205417545025737.000001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/27/2024 00:00:00",
                  "valueDate": "03/27/2024 00:00:00",
                  "processingDate": "03/27/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/27/2024 07:48:57",
                  "amount": 3500.0,
                  "narrative": "E-OPS- MASTERCARD POS PAYABLE(ROU)?Web Purchase from?MTN NIGERIA COMMUNICAT 4814 NG",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "3500.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205415721423686.020002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/27/2024 00:00:00",
                  "valueDate": "03/27/2024 00:00:00",
                  "processingDate": "03/27/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/27/2024 07:14:46",
                  "amount": 8000.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to SHOTUNDE KAMOLI ADISA Car?Mirror",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "8000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205413042727838.100001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/27/2024 00:00:00",
                  "valueDate": "03/27/2024 00:00:00",
                  "processingDate": "03/27/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/27/2024 08:23:58",
                  "amount": 1.88,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to ALGABS GLOBAL VENT?URES UR media uber",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "1.88",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205415605523423.060001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/27/2024 00:00:00",
                  "valueDate": "03/27/2024 00:00:00",
                  "processingDate": "03/27/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/27/2024 07:10:23",
                  "amount": 25.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "25.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205415605523423.100001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/27/2024 00:00:00",
                  "valueDate": "03/27/2024 00:00:00",
                  "processingDate": "03/27/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/27/2024 07:10:23",
                  "amount": 1.88,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to UMOH ELIZABETH UDO?UDO UR Cleaning APs office",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "1.88",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205413042727838.060002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/27/2024 00:00:00",
                  "valueDate": "03/27/2024 00:00:00",
                  "processingDate": "03/27/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/27/2024 08:23:58",
                  "amount": 9000.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to ALGABS GLOBAL VENTURES UR?media uber",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "9000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205415721423686.060001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/27/2024 00:00:00",
                  "valueDate": "03/27/2024 00:00:00",
                  "processingDate": "03/27/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/27/2024 07:14:46",
                  "amount": 1.88,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to SHOTUNDE KAMOLI AD?ISA Car Mirror",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "1.88",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205413042727838.060001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/27/2024 00:00:00",
                  "valueDate": "03/27/2024 00:00:00",
                  "processingDate": "03/27/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/27/2024 08:23:58",
                  "amount": 25.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "25.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205411661143302.050001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/27/2024 00:00:00",
                  "valueDate": "03/27/2024 00:00:00",
                  "processingDate": "03/27/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/27/2024 12:41:42",
                  "amount": 25000.0,
                  "narrative": "CREDIT SWITCH AIRTIME SUSPENSE?Data purchase for 08162040810",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "25000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205415721423686.020001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/27/2024 00:00:00",
                  "valueDate": "03/27/2024 00:00:00",
                  "processingDate": "03/27/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/27/2024 07:14:46",
                  "amount": 25.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "25.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205415605523423.060002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/27/2024 00:00:00",
                  "valueDate": "03/27/2024 00:00:00",
                  "processingDate": "03/27/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/27/2024 07:10:23",
                  "amount": 13000.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to UMOH ELIZABETH UDOUDO UR C?leaning APs office",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "13000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205428850059042.090001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/28/2024 00:00:00",
                  "valueDate": "03/28/2024 00:00:00",
                  "processingDate": "03/28/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/28/2024 20:50:42",
                  "amount": 1.88,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to NAFIU UMMARU Orang?es",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "1.88",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205428850059042.050001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/28/2024 00:00:00",
                  "valueDate": "03/28/2024 00:00:00",
                  "processingDate": "03/28/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/28/2024 20:50:42",
                  "amount": 25.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "25.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205428540440488.040001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/28/2024 00:00:00",
                  "valueDate": "03/28/2024 00:00:00",
                  "processingDate": "03/28/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/28/2024 15:41:28",
                  "amount": 0.75,
                  "narrative": "VALUE ADDED TAX?NIPVAT/ OneBank Transfer from ABIO?DUN WILLIAMS to YAKUBU DANIEL disp?atch delivery",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "0.75",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205428540440488.000002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/28/2024 00:00:00",
                  "valueDate": "03/28/2024 00:00:00",
                  "processingDate": "03/28/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/28/2024 15:41:28",
                  "amount": 2000.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to YAKUBU DANIEL dispatch del?ivery",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "2000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205422760429418.010001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/28/2024 00:00:00",
                  "valueDate": "03/28/2024 00:00:00",
                  "processingDate": "03/28/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/28/2024 12:36:58",
                  "amount": 15500.0,
                  "narrative": "UP POS SERVICE PAYABLE?POS Purchase from?PAYCOM NIGERIA LIMITED Ikeja NG",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "15500.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205423181332363.010001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/28/2024 00:00:00",
                  "valueDate": "03/28/2024 00:00:00",
                  "processingDate": "03/28/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/28/2024 13:26:03",
                  "amount": 1600.0,
                  "narrative": "UP POS SERVICE PAYABLE?POS Purchase from?T GOGA AGRO 001796 2070Y0DM NG",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "1600.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205428540440488.000001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/28/2024 00:00:00",
                  "valueDate": "03/28/2024 00:00:00",
                  "processingDate": "03/28/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/28/2024 15:41:28",
                  "amount": 10.0,
                  "narrative": "AC-NGN1250100012001",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "10.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205428850059042.050002",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/28/2024 00:00:00",
                  "valueDate": "03/28/2024 00:00:00",
                  "processingDate": "03/28/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/28/2024 20:50:42",
                  "amount": 7300.0,
                  "narrative": "NIBSS INSTANT PAYMENT TSS?OneBank Transfer from ABIODUN WILL?IAMS to NAFIU UMMARU Oranges",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "7300.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                },
                {
                  "statementEntryId": "205425010430169.010001",
                  "accountNumber": "0001659598",
                  "transactionDate": "03/28/2024 00:00:00",
                  "valueDate": "03/28/2024 00:00:00",
                  "processingDate": "03/28/2024 00:00:00",
                  "dateTime": null,
                  "transactionTime": "03/28/2024 12:49:29",
                  "amount": 3000.0,
                  "narrative": "UP POS SERVICE PAYABLE?POS Purchase from?POS SETTLEMENT ACCOUNT 151 HERBERT M NG",
                  "creditDebit": "debit",
                  "credit": "",
                  "debit": "3000.00",
                  "currency": "NGN",
                  "transactionCode": null,
                  "makerId": "D",
                  "availableBalance": "3430.74"
                }
              ]
            }
          },
          "error": null,
          "message": "Account statment retrieved successfully",
          "request_id": "672d22a27e730061149fa9b5",
          "time_taken": "9.75"
        }
      },
      {
        label: "KeyStone",
        value: "KeyStone",
        data: {
          "codes": 200,
          "data": {
              "data": [
                  {
                      "transactionDate": "23-Aug-2023",
                      "valueDate": "23-Aug-2023",
                      "description": "MTN Airtime top up 2347037606660  Txn Amount - NGN1000.00",
                      "referenceNumber": "FT23235146369421",
                      "amount": -1000
                  },
                  {
                      "transactionDate": "25-Aug-2023",
                      "valueDate": "25-Aug-2023",
                      "description": "MTN Airtime top up 2347037606660  Txn Amount - NGN1000.00",
                      "referenceNumber": "FT23237181244000",
                      "amount": -1000
                  },
                  {
                      "transactionDate": "28-Aug-2023",
                      "valueDate": "28-Aug-2023",
                      "description": "MTN Airtime top up 2347037606660  Txn Amount - NGN1000.00",
                      "referenceNumber": "FT23240128070833",
                      "amount": -1000
                  },
                  {
                      "transactionDate": "28-Aug-2023",
                      "valueDate": "28-Aug-2023",
                      "description": "MTN Airtime top up 2347037606660  Txn Amount - NGN1000.00",
                      "referenceNumber": "FT23240208212530",
                      "amount": -1000
                  },
                  {
                      "transactionDate": "28-Aug-2023",
                      "valueDate": "28-Aug-2023",
                      "description": "STAMP DUTY-221817894951 TRFBYPALMPA STAMP DUTY FT23240084924809 Txn Amount - NGN50.00",
                      "referenceNumber": "FT23240849258883\\BNK",
                      "amount": -50
                  },
                  {
                      "transactionDate": "28-Aug-2023",
                      "valueDate": "28-Aug-2023",
                      "description": "221817894951 TRFBYPALMPAYLIMIT EDPAYROLLFUND IFOBANKOLEOLUTIM ILEHINOLUKAYODE August2023salaTxn Amount - NGN557791.58",
                      "referenceNumber": "FT23240084924809\\BNK",
                      "amount": 557791.58
                  }
              ],
              "message": "Successful",
              "succeeded": true,
              "statucCode": 200
          },
          "message": ""
      },
      },
      {
        label: "FirstBank",
        value: "FirstBank",
        data: {
          responseCode: "00",
          responseMessage: "Successful",
          requestId: "E-Doc20231106030817",
          statementPeriod: "06-Aug-2023 To 06-Nov-2023",
          startDate: "2023-08-06T00:00:00",
          endDate: "2023-11-06T00:00:00",
          transactions: [
            {
              serialNo: 1,
              tranId: "S94797607",
              partTranType: "D",
              tranAmount: "6.98",
              tranParticular:
                "FDT:RUSSD_SESS_CHG:SchdulePayment2348068254493/8/3 Ref000017349555",
              tranParticular2:
                "FPX:17349555/FDT:RUSSD_SESS_CHG:SchdulePay/0000000",
              instrumentNumber: null,
              tranCurrencyCode: "NGN",
              referenceCurrencyCode: "NGN",
              tranType: "T",
              tranSubType: "BI",
              tranRemarks: "000017349555",
              deposit: "0.0",
              withdrawal: "6.98",
              runningBalance: "1812.84",
              totalCredit: "0.0",
              totalDebit: "6.98",
              tranDate: "30-Aug-2023",
              valueDate: "30-Aug-2023",
              postedDate: "2023-08-30T16:27:30Z",
              marker: "20230830162759250",
              tranShortCode: "TRF",
              entryDate: null,
              referenceNumber: null,
            },
          ],
          customerId: "106041738",
          accountNumber: "2009736647",
          accountName: "SHAFE OLAWUMI OMOYEMI",
          address: "BLK. 5 FLAT 6, ABESAN ESTATE IPAJA LAGOS,,",
          openingBalance: "1819.82",
          closingBalance: "1777.94",
          availableBalance: "3908098.38",
          clearedBalance: "3908098.38",
          unclearedBalance: "0.0",
          lienAmount: "0.0",
          totalCredit: "0.0",
          totalDebit: "41.88",
          email: "eloho.a.mukoro@firstbanknigeria.com",
          currency: "NGN",
          productCode: "CA201",
          productName: "CURRENT A/C - PERSONAL",
          mobileNo: "2348068254493",
          branchCode: "106",
          city: "LAGOS",
          state: "LAGOS",
          country: "NIGERIA",
          acid: "41.88",
        },
      },
      {
        label: "FCMB",
        value: "FCMB",
        data: 
        {
          "data": {
            "customerDetails": {
              "customerID": "4843257",
              "acid": "016065023",
              "foracid": "4843257020",
              "customerName": "OYEWUMI OLAOLU AMOS",
              "address": "268, BORNO WAY ADEKUNLE, .2,268, BORNO WAY ADEKUNLE, .2,",
              "clearedAmount": 279910.97,
              "unclearedAmount": 0,
              "systemReservedAmount": 0,
              "lienAmount": 184,
              "sanctionLimit": 0,
              "emailID": "OYEWUMIOLAOLU@GMAIL.COM",
              "currency": "NGN",
              "openingBalance": 279910.97,
              "effectiveBalance": 0,
              "accountType": "PREMIUM SAVINGS",
              "schemeCode": "SB110",
              "availableBalance": 279726.97,
              "drawingPower": 0,
              "mobileNo": "07032819698",
              "branchCode": "082",
              "totalCredit": 23088207.05,
              "totalDebit": 24585662.48,
              "tranTime": "02/13/2025 10:39:26"
            },
            "statementDetails": [
              {
                "tranID": "S45558263",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-02-13T00:00:00",
                "partTranType": "D",
                "tranAmount": 20000,
                "tranParticular": "TRF to MO'CRAFT BOWS/App:opening balance",
                "referenceID": "PPL",
                "valueDate": "2024-02-13T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": 259910.97,
                "withdrawls": 20000,
                "deposit": 0,
                "tranDateString": "02/13/2024 ",
                "valueDateString": "02/13/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "13/02/2024 11:16:23"
              },
              {
                "tranID": "S45678051",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-02-13T00:00:00",
                "partTranType": "D",
                "tranAmount": 420500,
                "tranParticular": "TRF to MARTHAJ PATTERN ENTERPRISE/App: To FCMB MAR",
                "referenceID": "PPL",
                "valueDate": "2024-02-13T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -160589.03,
                "withdrawls": 420500,
                "deposit": 0,
                "tranDateString": "02/13/2024 ",
                "valueDateString": "02/13/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "13/02/2024 11:16:23"
              },
              {
                "tranID": "S52251692",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-02-14T00:00:00",
                "partTranType": "D",
                "tranAmount": 10000,
                "tranParticular": "TRF to IKEJIMBA IFECHUKWU FAITH/App:fund To FCMB I",
                "referenceID": "PPL",
                "valueDate": "2024-02-14T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -170589.03,
                "withdrawls": 10000,
                "deposit": 0,
                "tranDateString": "02/14/2024 ",
                "valueDateString": "02/14/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "14/02/2024 17:54:31"
              },
              {
                "tranID": "S55086903",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-02-15T00:00:00",
                "partTranType": "D",
                "tranAmount": 100,
                "tranParticular": "QR/Q22132989259/Airtime/MTN/2347032819698",
                "referenceID": "USSD",
                "valueDate": "2024-02-15T00:00:00",
                "reference": "USSD/Q22132989259",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -170689.03,
                "withdrawls": 100,
                "deposit": 0,
                "tranDateString": "02/15/2024 ",
                "valueDateString": "02/15/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "15/02/2024 12:54:09"
              },
              {
                "tranID": "S55717472",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-02-15T00:00:00",
                "partTranType": "D",
                "tranAmount": 1250000,
                "tranParticular": "Apppayments for goods To Sterling Bank SUNKANM",
                "referenceID": "NIP/240215141908002872476959",
                "valueDate": "2024-02-15T00:00:00",
                "reference": "082/4843257020/000677873774",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1420689.03,
                "withdrawls": 1250000,
                "deposit": 0,
                "tranDateString": "02/15/2024 ",
                "valueDateString": "02/15/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "15/02/2024 14:26:25"
              },
              {
                "tranID": "S55717524",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-02-15T00:00:00",
                "partTranType": "D",
                "tranAmount": 53.75,
                "tranParticular": "TRANSACTION CHARGE-Apppayments for goods To Sterli",
                "referenceID": " ",
                "valueDate": "2024-02-15T00:00:00",
                "reference": "082/4843257020/000677873774",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1420742.78,
                "withdrawls": 53.75,
                "deposit": 0,
                "tranDateString": "02/15/2024 ",
                "valueDateString": "02/15/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "15/02/2024 14:26:25"
              },
              {
                "tranID": "S55733752",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-02-15T00:00:00",
                "partTranType": "C",
                "tranAmount": 170500,
                "tranParticular": "NIP FRM OYEWUMI OLAOLU AMOS-Inflow from OYEWUM",
                "referenceID": "NIP/240215142028000005691505",
                "valueDate": "2024-02-15T00:00:00",
                "reference": "001/4843257020/000677884904",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1250242.78,
                "withdrawls": 0,
                "deposit": 170500,
                "tranDateString": "02/15/2024 ",
                "valueDateString": "02/15/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "15/02/2024 14:26:25"
              },
              {
                "tranID": "S59453085",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-02-16T00:00:00",
                "partTranType": "C",
                "tranAmount": 60000,
                "tranParticular": "NIP FRM OLAITAN BASIRAT OWOIYA-Transfer from O",
                "referenceID": "NIP/240216115136543254209337",
                "valueDate": "2024-02-16T00:00:00",
                "reference": "001/4843257020/000680328637",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1190242.78,
                "withdrawls": 0,
                "deposit": 60000,
                "tranDateString": "02/16/2024 ",
                "valueDateString": "02/16/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/02/2024 15:04:15"
              },
              {
                "tranID": "S60071138",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-02-16T00:00:00",
                "partTranType": "C",
                "tranAmount": 50000,
                "tranParticular": "NIP FRM OYEWUMI OLAOLU AMOS-Sene",
                "referenceID": "NIP/240216143041000008092403",
                "valueDate": "2024-02-16T00:00:00",
                "reference": "001/4843257020/000680912962",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1140242.78,
                "withdrawls": 0,
                "deposit": 50000,
                "tranDateString": "02/16/2024 ",
                "valueDateString": "02/16/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/02/2024 15:04:15"
              },
              {
                "tranID": "S60195126",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-02-16T00:00:00",
                "partTranType": "D",
                "tranAmount": 55000,
                "tranParticular": "Appfund To VFD Microfinance Bank DAMILOLA MUIN",
                "referenceID": "NIP/240216145527002875468658",
                "valueDate": "2024-02-16T00:00:00",
                "reference": "082/4843257020/000681001287",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1195242.78,
                "withdrawls": 55000,
                "deposit": 0,
                "tranDateString": "02/16/2024 ",
                "valueDateString": "02/16/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/02/2024 15:04:15"
              },
              {
                "tranID": "S60195152",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-02-16T00:00:00",
                "partTranType": "D",
                "tranAmount": 53.75,
                "tranParticular": "TRANSACTION CHARGE-Appfund To VFD Microfinance Ban",
                "referenceID": " ",
                "valueDate": "2024-02-16T00:00:00",
                "reference": "082/4843257020/000681001287",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1195296.53,
                "withdrawls": 53.75,
                "deposit": 0,
                "tranDateString": "02/16/2024 ",
                "valueDateString": "02/16/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "16/02/2024 15:04:15"
              },
              {
                "tranID": "S60229327",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-02-16T00:00:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": "App To GTBank Plc OYEWUMI OLAOLU AMOS",
                "referenceID": "NIP/240216150126002875491659",
                "valueDate": "2024-02-16T00:00:00",
                "reference": "082/4843257020/000681024917",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1200296.53,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "02/16/2024 ",
                "valueDateString": "02/16/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/02/2024 15:04:15"
              },
              {
                "tranID": "S60229372",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-02-16T00:00:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App To GTBank Plc OYEWUMI OLAOL",
                "referenceID": " ",
                "valueDate": "2024-02-16T00:00:00",
                "reference": "082/4843257020/000681024917",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1200307.28,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "02/16/2024 ",
                "valueDateString": "02/16/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "16/02/2024 15:04:15"
              },
              {
                "tranID": "S61194755",
                "accountName": "ANIMASAUN OLAYINKA WALIU",
                "customerCode": null,
                "tranDate": "2024-02-16T00:00:00",
                "partTranType": "C",
                "tranAmount": 10000,
                "tranParticular": "TRF From ANIMASAUN OLAYINKA WALIU/App:olayinka To",
                "referenceID": "PPL",
                "valueDate": "2024-02-16T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1190307.28,
                "withdrawls": 0,
                "deposit": 10000,
                "tranDateString": "02/16/2024 ",
                "valueDateString": "02/16/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/02/2024 18:19:40"
              },
              {
                "tranID": "S62029174",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-02-16T00:00:00",
                "partTranType": "D",
                "tranAmount": 10000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-02-16T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1200307.28,
                "withdrawls": 10000,
                "deposit": 0,
                "tranDateString": "02/16/2024 ",
                "valueDateString": "02/16/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "17/02/2024 00:24:57"
              },
              {
                "tranID": "S82482058",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-02-21T00:00:00",
                "partTranType": "D",
                "tranAmount": 50000,
                "tranParticular": "TRF to SENE MULTIGLOBAL ENTERPRISE/App:opening bal",
                "referenceID": "PPL",
                "valueDate": "2024-02-21T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1250307.28,
                "withdrawls": 50000,
                "deposit": 0,
                "tranDateString": "02/21/2024 ",
                "valueDateString": "02/21/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "21/02/2024 18:38:06"
              },
              {
                "tranID": " 01246596",
                "accountName": "OLABODE JAMIU JIBOLA",
                "customerCode": null,
                "tranDate": "2024-02-23T00:00:00",
                "partTranType": "C",
                "tranAmount": 1250000,
                "tranParticular": "TRANSFER B/O: OLABODE JAMIU JIBOLA",
                "referenceID": "SFR2SFR2",
                "valueDate": "2024-02-23T00:00:00",
                "reference": "082|TRF|OYE13095",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -307.28,
                "withdrawls": 0,
                "deposit": 1250000,
                "tranDateString": "02/23/2024 ",
                "valueDateString": "02/23/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "23/02/2024 15:47:27"
              },
              {
                "tranID": "S99340412",
                "accountName": "PATRICK ESTHER ENE",
                "customerCode": null,
                "tranDate": "2024-02-26T00:00:00",
                "partTranType": "D",
                "tranAmount": 144,
                "tranParticular": "SMS ALERT CHARGES FROM 26TH JAN TO 22ND FEB 2024",
                "referenceID": " ",
                "valueDate": "2024-02-26T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -451.28,
                "withdrawls": 144,
                "deposit": 0,
                "tranDateString": "02/26/2024 ",
                "valueDateString": "02/26/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  70",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "26/02/2024 13:09:52"
              },
              {
                "tranID": " S1018190",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-02-26T00:00:00",
                "partTranType": "C",
                "tranAmount": 50000,
                "tranParticular": "NIP FRM OLALEKAN MATTHEW OLATOKE-Transfer from",
                "referenceID": "NIP/240226132639109618965786",
                "valueDate": "2024-02-26T00:00:00",
                "reference": "001/4843257020/000709128194",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": 49548.72,
                "withdrawls": 0,
                "deposit": 50000,
                "tranDateString": "02/26/2024 ",
                "valueDateString": "02/26/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "26/02/2024 19:46:58"
              },
              {
                "tranID": " S8596379",
                "accountName": "SPECIAL SLOTS CONSULTANCY LIMITED",
                "customerCode": null,
                "tranDate": "2024-02-28T00:00:00",
                "partTranType": "C",
                "tranAmount": 60000,
                "tranParticular": "TRF From SPECIAL SLOTS CONSULTANCY LIMITED/App: To",
                "referenceID": "PPL",
                "valueDate": "2024-02-27T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": 109548.72,
                "withdrawls": 0,
                "deposit": 60000,
                "tranDateString": "02/28/2024 ",
                "valueDateString": "02/27/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "28/02/2024 11:47:35"
              },
              {
                "tranID": " S9163074",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-02-28T00:00:00",
                "partTranType": "D",
                "tranAmount": 35000,
                "tranParticular": "Applogistics To Access Bank TN SYSTEMS",
                "referenceID": "NIP/240228073322002904909852",
                "valueDate": "2024-02-28T00:00:00",
                "reference": "082/4843257020/000714603373",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": 74548.72,
                "withdrawls": 35000,
                "deposit": 0,
                "tranDateString": "02/28/2024 ",
                "valueDateString": "02/28/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "28/02/2024 11:47:35"
              },
              {
                "tranID": " S9163201",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-02-28T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Applogistics To Access Bank TN",
                "referenceID": " ",
                "valueDate": "2024-02-28T00:00:00",
                "reference": "082/4843257020/000714603373",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": 74521.84,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "02/28/2024 ",
                "valueDateString": "02/28/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "28/02/2024 11:47:35"
              },
              {
                "tranID": " S9303464",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-02-28T00:00:00",
                "partTranType": "D",
                "tranAmount": 24,
                "tranParticular": "AUTHENTICATION CODE CHARGE Outstanding JAN 2024",
                "referenceID": " ",
                "valueDate": "2024-02-28T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": 74497.84,
                "withdrawls": 24,
                "deposit": 0,
                "tranDateString": "02/28/2024 ",
                "valueDateString": "02/28/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "28/02/2024 11:47:35"
              },
              {
                "tranID": "S10504724",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-02-28T00:00:00",
                "partTranType": "C",
                "tranAmount": 60000,
                "tranParticular": "NIP FRM CHIDOZIE DAVID UBA-TRFSCUML paymentFRM",
                "referenceID": "NIP/240228112639259755896270",
                "valueDate": "2024-02-28T00:00:00",
                "reference": "001/4843257020/000715360983",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": 134497.84,
                "withdrawls": 0,
                "deposit": 60000,
                "tranDateString": "02/28/2024 ",
                "valueDateString": "02/28/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "28/02/2024 11:47:35"
              },
              {
                "tranID": "S11179057",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-02-28T00:00:00",
                "partTranType": "D",
                "tranAmount": 35000,
                "tranParticular": "App To Access Bank TN SYSTEMS",
                "referenceID": "NIP/240228133634002906003049",
                "valueDate": "2024-02-28T00:00:00",
                "reference": "082/4843257020/000715820969",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": 99497.84,
                "withdrawls": 35000,
                "deposit": 0,
                "tranDateString": "02/28/2024 ",
                "valueDateString": "02/28/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "28/02/2024 14:06:33"
              },
              {
                "tranID": "S11179206",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-02-28T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-App To Access Bank TN SYSTEMS",
                "referenceID": " ",
                "valueDate": "2024-02-28T00:00:00",
                "reference": "082/4843257020/000715820969",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": 99470.96,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "02/28/2024 ",
                "valueDateString": "02/28/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "28/02/2024 14:06:33"
              },
              {
                "tranID": "S14077942",
                "accountName": "JULSIX INTEGRATED CONCEPT",
                "customerCode": null,
                "tranDate": "2024-02-29T00:00:00",
                "partTranType": "C",
                "tranAmount": 430250,
                "tranParticular": "web: TRF Frm JULSIX INTEGRATED CONCEPT/web:FEB SAL",
                "referenceID": "RIB",
                "valueDate": "2024-02-29T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": 529720.96,
                "withdrawls": 0,
                "deposit": 430250,
                "tranDateString": "02/29/2024 ",
                "valueDateString": "02/29/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "29/02/2024 10:13:38"
              },
              {
                "tranID": "S14287378",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-02-29T00:00:00",
                "partTranType": "C",
                "tranAmount": 55000,
                "tranParticular": "NIP FRM ARINZE FELIX EZIKE114HIDA-TRFFRM ARINZ",
                "referenceID": "NIP/240229090209249172907994",
                "valueDate": "2024-02-29T00:00:00",
                "reference": "001/4843257020/000717727441",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": 584720.96,
                "withdrawls": 0,
                "deposit": 55000,
                "tranDateString": "02/29/2024 ",
                "valueDateString": "02/29/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "29/02/2024 11:10:58"
              },
              {
                "tranID": "S15934146",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-02-29T00:00:00",
                "partTranType": "D",
                "tranAmount": 50000,
                "tranParticular": "TRF to INFRATRADE ALTERNATIVES MARKET TECHNOLOGIES",
                "referenceID": "PPL",
                "valueDate": "2024-02-29T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": 534720.96,
                "withdrawls": 50000,
                "deposit": 0,
                "tranDateString": "02/29/2024 ",
                "valueDateString": "02/29/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "29/02/2024 14:23:18"
              },
              {
                "tranID": "S16001075",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-02-29T00:00:00",
                "partTranType": "D",
                "tranAmount": 35000,
                "tranParticular": "Appfund To Access Bank TN SYSTEMS",
                "referenceID": "NIP/240229140415002908731497",
                "valueDate": "2024-02-29T00:00:00",
                "reference": "082/4843257020/000718864241",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": 499720.96,
                "withdrawls": 35000,
                "deposit": 0,
                "tranDateString": "02/29/2024 ",
                "valueDateString": "02/29/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "29/02/2024 14:23:18"
              },
              {
                "tranID": "S16001092",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-02-29T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appfund To Access Bank TN SYSTE",
                "referenceID": " ",
                "valueDate": "2024-02-29T00:00:00",
                "reference": "082/4843257020/000718864241",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": 499694.08,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "02/29/2024 ",
                "valueDateString": "02/29/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "29/02/2024 14:23:18"
              },
              {
                "tranID": "S20053040",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-03-01T12:42:00",
                "partTranType": "D",
                "tranAmount": 70000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-03-01T12:42:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": 429694.08,
                "withdrawls": 70000,
                "deposit": 0,
                "tranDateString": "03/01/2024 12:42 PM",
                "valueDateString": "03/01/2024 12:42 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "01/03/2024 12:42:28"
              },
              {
                "tranID": "S27369579",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-03-03T15:14:00",
                "partTranType": "D",
                "tranAmount": 20000,
                "tranParticular": "TRF to ABIODUN OLUWASEGUN BOLUWATIFE/App:Gboye To",
                "referenceID": "PPL",
                "valueDate": "2024-03-02T15:14:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": 409694.08,
                "withdrawls": 20000,
                "deposit": 0,
                "tranDateString": "03/03/2024 03:14 PM",
                "valueDateString": "03/02/2024 03:14 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "02/03/2024 15:14:48"
              },
              {
                "tranID": "S42217869",
                "accountName": "ANIMASAUN OLAYINKA WALIU",
                "customerCode": null,
                "tranDate": "2024-03-05T16:04:00",
                "partTranType": "C",
                "tranAmount": 10000,
                "tranParticular": "TRF From ANIMASAUN OLAYINKA WALIU/App:olayinka To",
                "referenceID": "PPL",
                "valueDate": "2024-03-05T16:04:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": 419694.08,
                "withdrawls": 0,
                "deposit": 10000,
                "tranDateString": "03/05/2024 04:04 PM",
                "valueDateString": "03/05/2024 04:04 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "05/03/2024 16:04:34"
              },
              {
                "tranID": "S43987150",
                "accountName": "OGUNTONA SUNDAY OLUWASEYI",
                "customerCode": null,
                "tranDate": "2024-03-05T23:27:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY HOLD ON ACCOUNT ON 05-MAR-2024",
                "referenceID": " ",
                "valueDate": "2024-03-05T23:27:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": 419644.08,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "03/05/2024 11:27 PM",
                "valueDateString": "03/05/2024 11:27 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": " 539",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "05/03/2024 23:27:04"
              },
              {
                "tranID": "S50830561",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-03-07T11:27:00",
                "partTranType": "D",
                "tranAmount": 411500,
                "tranParticular": "TRF to WAVELENGTH MULTI-RESOURCES NG/App: To FCMB",
                "referenceID": "PPL",
                "valueDate": "2024-03-07T11:27:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": 8144.08,
                "withdrawls": 411500,
                "deposit": 0,
                "tranDateString": "03/07/2024 11:27 AM",
                "valueDateString": "03/07/2024 11:27 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "07/03/2024 11:27:20"
              },
              {
                "tranID": "S52642532",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-03-07T15:36:00",
                "partTranType": "D",
                "tranAmount": 10000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-03-07T15:36:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1855.92,
                "withdrawls": 10000,
                "deposit": 0,
                "tranDateString": "03/07/2024 03:36 PM",
                "valueDateString": "03/07/2024 03:36 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "07/03/2024 15:36:16"
              },
              {
                "tranID": "S69205502",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-03-11T11:23:00",
                "partTranType": "C",
                "tranAmount": 411500,
                "tranParticular": "NIP FRM OYEWUMI OLAOLU AMOS-Inflow from OYEWUM",
                "referenceID": "NIP/240311093034000063916205",
                "valueDate": "2024-03-11T11:23:00",
                "reference": "001/4843257020/000753672489",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": 409644.08,
                "withdrawls": 0,
                "deposit": 411500,
                "tranDateString": "03/11/2024 11:23 AM",
                "valueDateString": "03/11/2024 11:23 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "11/03/2024 11:23:26"
              },
              {
                "tranID": "S69366168",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-03-11T11:23:00",
                "partTranType": "D",
                "tranAmount": 1800000,
                "tranParticular": "TRF to OLABODE JAMIU JIBOLA/App:logistics To FCM",
                "referenceID": "PPL",
                "valueDate": "2024-03-11T11:23:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1390355.92,
                "withdrawls": 1800000,
                "deposit": 0,
                "tranDateString": "03/11/2024 11:23 AM",
                "valueDateString": "03/11/2024 11:23 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "11/03/2024 11:23:26"
              },
              {
                "tranID": "S71103107",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-03-11T15:46:00",
                "partTranType": "D",
                "tranAmount": 45000,
                "tranParticular": "Appgbadebo ilelaboye oloruntoba To Opay Digita",
                "referenceID": "NIP/240311154408002941749085",
                "valueDate": "2024-03-11T15:46:00",
                "reference": "082/4843257020/000754986313",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1435355.92,
                "withdrawls": 45000,
                "deposit": 0,
                "tranDateString": "03/11/2024 03:46 PM",
                "valueDateString": "03/11/2024 03:46 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "11/03/2024 15:46:04"
              },
              {
                "tranID": "S71103136",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-03-11T15:46:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appgbadebo ilelaboye oloruntoba",
                "referenceID": " ",
                "valueDate": "2024-03-11T15:46:00",
                "reference": "082/4843257020/000754986313",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1435382.8,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "03/11/2024 03:46 PM",
                "valueDateString": "03/11/2024 03:46 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "11/03/2024 15:46:04"
              },
              {
                "tranID": "S71147900",
                "accountName": "OLORUNTOBA GBADEBO ILELABOYE",
                "customerCode": null,
                "tranDate": "2024-03-11T15:56:00",
                "partTranType": "C",
                "tranAmount": 45000,
                "tranParticular": "TRF From OLORUNTOBA GBADEBO ILELABOYE/App: To FCMB",
                "referenceID": "PPL",
                "valueDate": "2024-03-11T15:56:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1390382.8,
                "withdrawls": 0,
                "deposit": 45000,
                "tranDateString": "03/11/2024 03:56 PM",
                "valueDateString": "03/11/2024 03:56 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "11/03/2024 15:56:09"
              },
              {
                "tranID": "S72277399",
                "accountName": "OGUNDIPE OLUKUNLE MICHEAL",
                "customerCode": null,
                "tranDate": "2024-03-11T21:05:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY HOLD ON ACCOUNT ON 11-MAR-2024",
                "referenceID": " ",
                "valueDate": "2024-03-11T21:05:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1390432.8,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "03/11/2024 09:05 PM",
                "valueDateString": "03/11/2024 09:05 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  23",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "11/03/2024 21:05:44"
              },
              {
                "tranID": "  0170482",
                "accountName": "WAVELENGTH MULTI-RESOURCES NG",
                "customerCode": null,
                "tranDate": "2024-03-13T00:00:00",
                "partTranType": "C",
                "tranAmount": 20000,
                "tranParticular": "TRANSFER B/O: WAVELENGTH MULTI-RESOURCES NG",
                "referenceID": "SFR2SFR2",
                "valueDate": "2024-03-13T00:00:00",
                "reference": "082|TRF|BAB4117",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1370432.8,
                "withdrawls": 0,
                "deposit": 20000,
                "tranDateString": "03/13/2024 ",
                "valueDateString": "03/13/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "13/03/2024 15:59:43"
              },
              {
                "tranID": "S80788413",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-03-13T00:00:00",
                "partTranType": "D",
                "tranAmount": 2000,
                "tranParticular": "App To Fidelity Bank DANIEL OLALEKAN KUSHIMO",
                "referenceID": "NIP/240313171901002947520408",
                "valueDate": "2024-03-13T00:00:00",
                "reference": "082/4843257020/000761578109",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1372432.8,
                "withdrawls": 2000,
                "deposit": 0,
                "tranDateString": "03/13/2024 ",
                "valueDateString": "03/13/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "13/03/2024 17:19:25"
              },
              {
                "tranID": "S80788442",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-03-13T00:00:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App To Fidelity Bank DANIEL OLA",
                "referenceID": " ",
                "valueDate": "2024-03-13T00:00:00",
                "reference": "082/4843257020/000761578109",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1372443.55,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "03/13/2024 ",
                "valueDateString": "03/13/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "13/03/2024 17:19:25"
              },
              {
                "tranID": "S85180384",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-03-15T00:00:00",
                "partTranType": "C",
                "tranAmount": 60000,
                "tranParticular": "NIP FRM AJOKE IBIRONKE OLALEYE-Certificate",
                "referenceID": "NIP/240315120133932639030201",
                "valueDate": "2024-03-15T00:00:00",
                "reference": "001/4843257020/000764594057",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1312443.55,
                "withdrawls": 0,
                "deposit": 60000,
                "tranDateString": "03/15/2024 ",
                "valueDateString": "03/15/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "15/03/2024 12:03:32"
              },
              {
                "tranID": "S90630516",
                "accountName": "FUND TRANSFER TSS RECEIVALE A/C",
                "customerCode": null,
                "tranDate": "2024-03-17T00:00:00",
                "partTranType": "C",
                "tranAmount": 30000,
                "tranParticular": "Mbanking Trf: SKY/001408435697;;ALAO IBRAHIM to",
                "referenceID": "FCMB MOBILE",
                "valueDate": "2024-03-17T00:00:00",
                "reference": "4843257020/1408435697/317524/E",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1282443.55,
                "withdrawls": 0,
                "deposit": 30000,
                "tranDateString": "03/17/2024 ",
                "valueDateString": "03/17/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "17/03/2024 13:01:33"
              },
              {
                "tranID": "S91063772",
                "accountName": "GABRIEL EMMANUEL",
                "customerCode": null,
                "tranDate": "2024-03-17T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY HOLD ON ACCOUNT ON 17-MAR-2024",
                "referenceID": " ",
                "valueDate": "2024-03-17T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1282493.55,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "03/17/2024 ",
                "valueDateString": "03/17/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  23",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "17/03/2024 15:25:42"
              },
              {
                "tranID": "S95442901",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-03-18T00:00:00",
                "partTranType": "D",
                "tranAmount": 15000,
                "tranParticular": "TRF to ANIMASAUN OLAYINKA WALIU/App: To FCMB ANIMA",
                "referenceID": "PPL",
                "valueDate": "2024-03-18T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1297493.55,
                "withdrawls": 15000,
                "deposit": 0,
                "tranDateString": "03/18/2024 ",
                "valueDateString": "03/18/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "18/03/2024 16:57:49"
              },
              {
                "tranID": "S95451569",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-03-18T00:00:00",
                "partTranType": "D",
                "tranAmount": 15000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-03-18T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1312493.55,
                "withdrawls": 15000,
                "deposit": 0,
                "tranDateString": "03/18/2024 ",
                "valueDateString": "03/18/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "18/03/2024 16:57:49"
              },
              {
                "tranID": "S96002940",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-03-18T00:00:00",
                "partTranType": "C",
                "tranAmount": 25000,
                "tranParticular": "NIP FRM HEDONUKUN JOHN TASEYON-FBNMOBILEOYEWUM",
                "referenceID": "NIP/240318184940000078689314",
                "valueDate": "2024-03-18T00:00:00",
                "reference": "001/4843257020/000771771373",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1287493.55,
                "withdrawls": 0,
                "deposit": 25000,
                "tranDateString": "03/18/2024 ",
                "valueDateString": "03/18/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "18/03/2024 18:51:27"
              },
              {
                "tranID": " S6386085",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-03-21T00:00:00",
                "partTranType": "C",
                "tranAmount": 50000,
                "tranParticular": "NIP FRM NWODO OBINNA FESTUS117DIAMOND-TRF7771F",
                "referenceID": "NIP/240321113254263768795347",
                "valueDate": "2024-03-21T00:00:00",
                "reference": "001/4843257020/000778218935",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1237493.55,
                "withdrawls": 0,
                "deposit": 50000,
                "tranDateString": "03/21/2024 ",
                "valueDateString": "03/21/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "21/03/2024 18:11:15"
              },
              {
                "tranID": " S7134099",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-03-21T00:00:00",
                "partTranType": "D",
                "tranAmount": 45000,
                "tranParticular": "TRF to HASSAN DAMILOLA MUINAT/App: To FCMB HASSAN",
                "referenceID": "PPL",
                "valueDate": "2024-03-21T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1282493.55,
                "withdrawls": 45000,
                "deposit": 0,
                "tranDateString": "03/21/2024 ",
                "valueDateString": "03/21/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "21/03/2024 14:25:22"
              },
              {
                "tranID": " S7135809",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-03-21T00:00:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": "TRF to ANIMASAUN OLAYINKA WALIU/App: To FCMB ANIMA",
                "referenceID": "PPL",
                "valueDate": "2024-03-21T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1287493.55,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "03/21/2024 ",
                "valueDateString": "03/21/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "21/03/2024 14:25:22"
              },
              {
                "tranID": "S21152822",
                "accountName": "NWAFOR CHIJIOKE KELVIN",
                "customerCode": null,
                "tranDate": "2024-03-25T00:00:00",
                "partTranType": "D",
                "tranAmount": 108,
                "tranParticular": "SMS ALERT CHARGES FROM 23RD FEB TO 22ND MAR 2024",
                "referenceID": " ",
                "valueDate": "2024-03-25T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1287601.55,
                "withdrawls": 108,
                "deposit": 0,
                "tranDateString": "03/25/2024 ",
                "valueDateString": "03/25/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  68",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "25/03/2024 13:49:16"
              },
              {
                "tranID": "S23545216",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-03-25T00:00:00",
                "partTranType": "D",
                "tranAmount": 11000,
                "tranParticular": "App To GTBank Plc OYEWUMI OLAOLU AMOS",
                "referenceID": "NIP/240325153352002970255969",
                "valueDate": "2024-03-25T00:00:00",
                "reference": "082/4843257020/000789335747",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1298601.55,
                "withdrawls": 11000,
                "deposit": 0,
                "tranDateString": "03/25/2024 ",
                "valueDateString": "03/25/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "25/03/2024 21:08:50"
              },
              {
                "tranID": "S23545402",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-03-25T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-App To GTBank Plc OYEWUMI OLAOL",
                "referenceID": "NIP/240325153352002970255969",
                "valueDate": "2024-03-25T00:00:00",
                "reference": "082/4843257020/000789335747",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1298628.43,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "03/25/2024 ",
                "valueDateString": "03/25/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "25/03/2024 21:08:50"
              },
              {
                "tranID": "S23558353",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-03-25T00:00:00",
                "partTranType": "D",
                "tranAmount": 14000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-03-25T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1312628.43,
                "withdrawls": 14000,
                "deposit": 0,
                "tranDateString": "03/25/2024 ",
                "valueDateString": "03/25/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "25/03/2024 21:08:50"
              },
              {
                "tranID": "S26468723",
                "accountName": "MAMA TEE HAIR COLLECTION",
                "customerCode": null,
                "tranDate": "2024-03-26T00:00:00",
                "partTranType": "C",
                "tranAmount": 1323000,
                "tranParticular": "TRF From MAMA TEE HAIR COLLECTION/App: To FCMB OYE",
                "referenceID": "PPL",
                "valueDate": "2024-03-26T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": 10371.57,
                "withdrawls": 0,
                "deposit": 1323000,
                "tranDateString": "03/26/2024 ",
                "valueDateString": "03/26/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "26/03/2024 10:23:19"
              },
              {
                "tranID": "S27115960",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-03-26T00:00:00",
                "partTranType": "D",
                "tranAmount": 1400000,
                "tranParticular": "TRF to MAMA TEE HAIR COLLECTION/App: To FCMB MAMA",
                "referenceID": "PPL",
                "valueDate": "2024-03-26T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1389628.43,
                "withdrawls": 1400000,
                "deposit": 0,
                "tranDateString": "03/26/2024 ",
                "valueDateString": "03/26/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "26/03/2024 12:28:38"
              },
              {
                "tranID": "S27518406",
                "accountName": "MAMA TEE HAIR COLLECTION",
                "customerCode": null,
                "tranDate": "2024-03-26T00:00:00",
                "partTranType": "C",
                "tranAmount": 1400000,
                "tranParticular": "TRF From MAMA TEE HAIR COLLECTION/App: To First Ci",
                "referenceID": "PPL",
                "valueDate": "2024-03-26T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": 10371.57,
                "withdrawls": 0,
                "deposit": 1400000,
                "tranDateString": "03/26/2024 ",
                "valueDateString": "03/26/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "26/03/2024 14:06:35"
              },
              {
                "tranID": "S27563508",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-03-26T00:00:00",
                "partTranType": "D",
                "tranAmount": 1500000,
                "tranParticular": "TRF to MAMA TEE HAIR COLLECTION/App: To FCMB MAMA",
                "referenceID": "PPL",
                "valueDate": "2024-03-26T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1489628.43,
                "withdrawls": 1500000,
                "deposit": 0,
                "tranDateString": "03/26/2024 ",
                "valueDateString": "03/26/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "26/03/2024 14:06:35"
              },
              {
                "tranID": "S27659430",
                "accountName": "MAMA TEE HAIR COLLECTION",
                "customerCode": null,
                "tranDate": "2024-03-26T00:00:00",
                "partTranType": "C",
                "tranAmount": 1500000,
                "tranParticular": "TRF From MAMA TEE HAIR COLLECTION/App: To FCMB OYE",
                "referenceID": "PPL",
                "valueDate": "2024-03-26T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": 10371.57,
                "withdrawls": 0,
                "deposit": 1500000,
                "tranDateString": "03/26/2024 ",
                "valueDateString": "03/26/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "26/03/2024 14:06:35"
              },
              {
                "tranID": "S31124897",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-03-27T00:00:00",
                "partTranType": "D",
                "tranAmount": 35000,
                "tranParticular": "Appfund To Access Bank TN SYSTEMS",
                "referenceID": "NIP/240327100337002974519352",
                "valueDate": "2024-03-27T00:00:00",
                "reference": "082/4843257020/000794610395",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -24628.43,
                "withdrawls": 35000,
                "deposit": 0,
                "tranDateString": "03/27/2024 ",
                "valueDateString": "03/27/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "27/03/2024 12:22:46"
              },
              {
                "tranID": "S31124925",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-03-27T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appfund To Access Bank TN SYSTE",
                "referenceID": "NIP/240327100337002974519352",
                "valueDate": "2024-03-27T00:00:00",
                "reference": "082/4843257020/000794610395",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -24655.31,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "03/27/2024 ",
                "valueDateString": "03/27/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "27/03/2024 12:22:46"
              },
              {
                "tranID": "S31917705",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-03-27T00:00:00",
                "partTranType": "D",
                "tranAmount": 3000,
                "tranParticular": "TRF to OYEWUMI VICTORIOUS FAMILY/App: To FCMB OYEW",
                "referenceID": "PPL",
                "valueDate": "2024-03-27T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -27655.31,
                "withdrawls": 3000,
                "deposit": 0,
                "tranDateString": "03/27/2024 ",
                "valueDateString": "03/27/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "27/03/2024 13:36:51"
              },
              {
                "tranID": "S35307225",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-03-28T00:00:00",
                "partTranType": "D",
                "tranAmount": 16,
                "tranParticular": "AUTHENTICATION CODE CHARGE Outstanding FEB 2024",
                "referenceID": " ",
                "valueDate": "2024-03-28T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -27671.31,
                "withdrawls": 16,
                "deposit": 0,
                "tranDateString": "03/28/2024 ",
                "valueDateString": "03/28/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "28/03/2024 10:56:13"
              },
              {
                "tranID": "S38079808",
                "accountName": "JOTH LOGISTICS",
                "customerCode": null,
                "tranDate": "2024-03-28T00:00:00",
                "partTranType": "C",
                "tranAmount": 5000,
                "tranParticular": "web: TRF Frm JOTH LOGISTICS/web:pay",
                "referenceID": "RIB",
                "valueDate": "2024-03-28T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -22671.31,
                "withdrawls": 0,
                "deposit": 5000,
                "tranDateString": "03/28/2024 ",
                "valueDateString": "03/28/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "28/03/2024 15:38:40"
              },
              {
                "tranID": "S40065912",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-03-28T00:00:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-03-28T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -27671.31,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "03/28/2024 ",
                "valueDateString": "03/28/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "29/03/2024 02:12:57"
              },
              {
                "tranID": "S45310562",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-03-29T00:00:00",
                "partTranType": "D",
                "tranAmount": 20000,
                "tranParticular": "AppMr gboyega To GTBank Plc AMUWA PRECIOUS AME",
                "referenceID": "NIP/240329201844002982031261",
                "valueDate": "2024-03-29T00:00:00",
                "reference": "082/4843257020/000803438057",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -47671.31,
                "withdrawls": 20000,
                "deposit": 0,
                "tranDateString": "03/29/2024 ",
                "valueDateString": "03/29/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "30/03/2024 00:10:42"
              },
              {
                "tranID": "S45310602",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-03-29T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-AppMr gboyega To GTBank Plc AMU",
                "referenceID": "NIP/240329201844002982031261",
                "valueDate": "2024-03-29T00:00:00",
                "reference": "082/4843257020/000803438057",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -47698.19,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "03/29/2024 ",
                "valueDateString": "03/29/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "30/03/2024 00:10:42"
              },
              {
                "tranID": "S45316317",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-03-29T00:00:00",
                "partTranType": "D",
                "tranAmount": 100000,
                "tranParticular": "AppMr gboyega To GTBank Plc ADEOYE DANIEL OLAN",
                "referenceID": "NIP/240329202003002982034693",
                "valueDate": "2024-03-29T00:00:00",
                "reference": "082/4843257020/000803440409",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -147698.19,
                "withdrawls": 100000,
                "deposit": 0,
                "tranDateString": "03/29/2024 ",
                "valueDateString": "03/29/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "30/03/2024 00:10:42"
              },
              {
                "tranID": "S45316344",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-03-29T00:00:00",
                "partTranType": "D",
                "tranAmount": 53.75,
                "tranParticular": "TRANSACTION CHARGE-AppMr gboyega To GTBank Plc ADE",
                "referenceID": "NIP/240329202003002982034693",
                "valueDate": "2024-03-29T00:00:00",
                "reference": "082/4843257020/000803440409",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -147751.94,
                "withdrawls": 53.75,
                "deposit": 0,
                "tranDateString": "03/29/2024 ",
                "valueDateString": "03/29/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "30/03/2024 00:10:42"
              },
              {
                "tranID": "S45323695",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-03-29T00:00:00",
                "partTranType": "D",
                "tranAmount": 40000,
                "tranParticular": "TRF to LAWAL OLUFEMI GBOGBOADE/App:Mr gboyega To F",
                "referenceID": "PPL",
                "valueDate": "2024-03-29T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -187751.94,
                "withdrawls": 40000,
                "deposit": 0,
                "tranDateString": "03/29/2024 ",
                "valueDateString": "03/29/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "30/03/2024 00:10:42"
              },
              {
                "tranID": "S45332788",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-03-29T00:00:00",
                "partTranType": "D",
                "tranAmount": 20000,
                "tranParticular": "AppMr gboyega To Zenith Bank OLAJUWON ABASS MU",
                "referenceID": "NIP/240329202226002982043396",
                "valueDate": "2024-03-29T00:00:00",
                "reference": "082/4843257020/000803449389",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -207751.94,
                "withdrawls": 20000,
                "deposit": 0,
                "tranDateString": "03/29/2024 ",
                "valueDateString": "03/29/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "30/03/2024 00:10:42"
              },
              {
                "tranID": "S45332826",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-03-29T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-AppMr gboyega To Zenith Bank OL",
                "referenceID": "NIP/240329202226002982043396",
                "valueDate": "2024-03-29T00:00:00",
                "reference": "082/4843257020/000803449389",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -207778.82,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "03/29/2024 ",
                "valueDateString": "03/29/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "30/03/2024 00:10:42"
              },
              {
                "tranID": "S67138203",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-04-03T14:31:00",
                "partTranType": "D",
                "tranAmount": 10000,
                "tranParticular": "App To ACCESS BANK PLC DIAMOND OLAOLU AMOS OYE",
                "referenceID": "NIP/240403140525002993699650",
                "valueDate": "2024-04-03T14:31:00",
                "reference": "082/4843257020/000816773037",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -217778.82,
                "withdrawls": 10000,
                "deposit": 0,
                "tranDateString": "04/03/2024 02:31 PM",
                "valueDateString": "04/03/2024 02:31 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "03/04/2024 14:31:48"
              },
              {
                "tranID": "S67138254",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-04-03T14:31:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-App To ACCESS BANK PLC DIAMOND",
                "referenceID": "NIP/240403140525002993699650",
                "valueDate": "2024-04-03T14:31:00",
                "reference": "082/4843257020/000816773037",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -217805.7,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "04/03/2024 02:31 PM",
                "valueDateString": "04/03/2024 02:31 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "03/04/2024 14:31:48"
              },
              {
                "tranID": "S67140850",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-04-03T14:31:00",
                "partTranType": "D",
                "tranAmount": 12000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-04-03T14:31:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -229805.7,
                "withdrawls": 12000,
                "deposit": 0,
                "tranDateString": "04/03/2024 02:31 PM",
                "valueDateString": "04/03/2024 02:31 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "03/04/2024 14:31:48"
              },
              {
                "tranID": "S69006223",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-04-03T20:47:00",
                "partTranType": "C",
                "tranAmount": 411500,
                "tranParticular": "NIP FRM ADEWALE  SHOYEMI-FCMBMarchSalary",
                "referenceID": "NIP/240403195315290583511842",
                "valueDate": "2024-04-03T20:47:00",
                "reference": "001/4843257020/000818082223",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": 181694.3,
                "withdrawls": 0,
                "deposit": 411500,
                "tranDateString": "04/03/2024 08:47 PM",
                "valueDateString": "04/03/2024 08:47 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "03/04/2024 20:47:23"
              },
              {
                "tranID": "S70597858",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-04-04T11:34:00",
                "partTranType": "D",
                "tranAmount": 411400,
                "tranParticular": "TRF to WAVELENGTH MULTI-RESOURCES NG/App: To FCMB",
                "referenceID": "PPL",
                "valueDate": "2024-04-04T11:34:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -229705.7,
                "withdrawls": 411400,
                "deposit": 0,
                "tranDateString": "04/04/2024 11:34 AM",
                "valueDateString": "04/04/2024 11:34 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "04/04/2024 11:34:37"
              },
              {
                "tranID": "S71629414",
                "accountName": "PURPLE STRIPES VENTURES",
                "customerCode": null,
                "tranDate": "2024-04-04T14:04:00",
                "partTranType": "C",
                "tranAmount": 180000,
                "tranParticular": "web: TRF Frm PURPLE STRIPES VENTURES/web:A la o lu",
                "referenceID": "RIB",
                "valueDate": "2024-04-04T14:04:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -49705.7,
                "withdrawls": 0,
                "deposit": 180000,
                "tranDateString": "04/04/2024 02:04 PM",
                "valueDateString": "04/04/2024 02:04 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "04/04/2024 14:04:51"
              },
              {
                "tranID": "S77319590",
                "accountName": "HASSAN DAMILOLA MUINAT",
                "customerCode": null,
                "tranDate": "2024-04-05T15:18:00",
                "partTranType": "C",
                "tranAmount": 5000,
                "tranParticular": "TRF From HASSAN DAMILOLA MUINAT/App: To FCMB OYEWU",
                "referenceID": "PPL",
                "valueDate": "2024-04-05T15:18:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -44705.7,
                "withdrawls": 0,
                "deposit": 5000,
                "tranDateString": "04/05/2024 03:18 PM",
                "valueDateString": "04/05/2024 03:18 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "05/04/2024 15:18:44"
              },
              {
                "tranID": "S86149102",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-04-07T15:13:00",
                "partTranType": "D",
                "tranAmount": 70000,
                "tranParticular": "TRF to TERNA DOOFAN RITA/App:fund To FCMB TER",
                "referenceID": "PPL",
                "valueDate": "2024-04-07T15:13:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -114705.7,
                "withdrawls": 70000,
                "deposit": 0,
                "tranDateString": "04/07/2024 03:13 PM",
                "valueDateString": "04/07/2024 03:13 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "07/04/2024 15:13:43"
              },
              {
                "tranID": "S86713756",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-04-07T16:01:00",
                "partTranType": "D",
                "tranAmount": 15000,
                "tranParticular": "TRF to OCTAVE MUSIC CREATIVE NG/App:fund To FCMB O",
                "referenceID": "PPL",
                "valueDate": "2024-04-07T16:01:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -129705.7,
                "withdrawls": 15000,
                "deposit": 0,
                "tranDateString": "04/07/2024 04:01 PM",
                "valueDateString": "04/07/2024 04:01 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "07/04/2024 16:01:12"
              },
              {
                "tranID": "S87603202",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-04-08T16:31:00",
                "partTranType": "D",
                "tranAmount": 10000,
                "tranParticular": "App To GTBank Plc OYEWUMI OLAWALE SIMON",
                "referenceID": "NIP/240407192043003005335257",
                "valueDate": "2024-04-07T16:31:00",
                "reference": "082/4843257020/000830899029",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -139705.7,
                "withdrawls": 10000,
                "deposit": 0,
                "tranDateString": "04/08/2024 04:31 PM",
                "valueDateString": "04/07/2024 04:31 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "08/04/2024 16:31:42"
              },
              {
                "tranID": "S87603236",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-04-08T16:31:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-App To GTBank Plc OYEWUMI OLAWA",
                "referenceID": "NIP/240407192043003005335257",
                "valueDate": "2024-04-08T16:31:00",
                "reference": "082/4843257020/000830899029",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -139732.58,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "04/08/2024 04:31 PM",
                "valueDateString": "04/08/2024 04:31 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "08/04/2024 16:31:42"
              },
              {
                "tranID": "S90667290",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-04-08T16:31:00",
                "partTranType": "D",
                "tranAmount": 850800,
                "tranParticular": "TRF to WAVELENGTH MULTI-RESOURCES NG/App: To FCMB",
                "referenceID": "PPL",
                "valueDate": "2024-04-08T16:31:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -990532.58,
                "withdrawls": 850800,
                "deposit": 0,
                "tranDateString": "04/08/2024 04:31 PM",
                "valueDateString": "04/08/2024 04:31 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "08/04/2024 16:31:42"
              },
              {
                "tranID": "S90761514",
                "accountName": "JULSIX INTEGRATED CONCEPT",
                "customerCode": null,
                "tranDate": "2024-04-08T16:31:00",
                "partTranType": "C",
                "tranAmount": 430250,
                "tranParticular": "web: TRF Frm JULSIX INTEGRATED CONCEPT/web:MAR SAL",
                "referenceID": "RIB",
                "valueDate": "2024-04-08T16:31:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -560282.58,
                "withdrawls": 0,
                "deposit": 430250,
                "tranDateString": "04/08/2024 04:31 PM",
                "valueDateString": "04/08/2024 04:31 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "08/04/2024 16:31:42"
              },
              {
                "tranID": "S93073092",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-04-08T00:41:00",
                "partTranType": "D",
                "tranAmount": 10000,
                "tranParticular": "App To GTBank Plc AMAO OLAJUMOKE CHRISTIANAH",
                "referenceID": "NIP/240408220033003008406354",
                "valueDate": "2024-04-08T00:41:00",
                "reference": "082/4843257020/000834710759",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -570282.58,
                "withdrawls": 10000,
                "deposit": 0,
                "tranDateString": "04/08/2024 12:41 AM",
                "valueDateString": "04/08/2024 12:41 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "09/04/2024 00:41:05"
              },
              {
                "tranID": "S93073112",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-04-08T00:41:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-App To GTBank Plc AMAO OLAJUMOK",
                "referenceID": "NIP/240408220033003008406354",
                "valueDate": "2024-04-08T00:41:00",
                "reference": "082/4843257020/000834710759",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -570309.46,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "04/08/2024 12:41 AM",
                "valueDateString": "04/08/2024 12:41 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "09/04/2024 00:41:05"
              },
              {
                "tranID": "S93278174",
                "accountName": "OCTAVE MUSIC CREATIVE NG",
                "customerCode": null,
                "tranDate": "2024-04-10T11:59:00",
                "partTranType": "C",
                "tranAmount": 20000,
                "tranParticular": "TRF From OCTAVE MUSIC CREATIVE NG/App:refund To FC",
                "referenceID": "PPL",
                "valueDate": "2024-04-08T11:59:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -550309.46,
                "withdrawls": 0,
                "deposit": 20000,
                "tranDateString": "04/10/2024 11:59 AM",
                "valueDateString": "04/08/2024 11:59 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "09/04/2024 11:59:14"
              },
              {
                "tranID": "S95255761",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-04-10T14:10:00",
                "partTranType": "C",
                "tranAmount": 20000,
                "tranParticular": "NIP FRM DOOFAN RITA TERNA-Inflow from DOOFAN R",
                "referenceID": "NIP/240409130639039733032447",
                "valueDate": "2024-04-09T14:10:00",
                "reference": "001/4843257020/000836141495",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -530309.46,
                "withdrawls": 0,
                "deposit": 20000,
                "tranDateString": "04/10/2024 02:10 PM",
                "valueDateString": "04/09/2024 02:10 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "09/04/2024 14:10:57"
              },
              {
                "tranID": "S96595823",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-04-10T17:50:00",
                "partTranType": "C",
                "tranAmount": 20000,
                "tranParticular": "NIP FRM DOOFAN RITA TERNA-Inflow from DOOFAN R",
                "referenceID": "NIP/240409172611395934005166",
                "valueDate": "2024-04-09T17:50:00",
                "reference": "001/4843257020/000837036885",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -510309.46,
                "withdrawls": 0,
                "deposit": 20000,
                "tranDateString": "04/10/2024 05:50 PM",
                "valueDateString": "04/09/2024 05:50 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "09/04/2024 17:50:08"
              },
              {
                "tranID": " S6206302",
                "accountName": "ANIMASAUN OLAYINKA WALIU",
                "customerCode": null,
                "tranDate": "2024-04-12T10:38:00",
                "partTranType": "C",
                "tranAmount": 160000,
                "tranParticular": "TRF From ANIMASAUN OLAYINKA WALIU/App:sheff To FCM",
                "referenceID": "PPL",
                "valueDate": "2024-04-12T10:38:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -350309.46,
                "withdrawls": 0,
                "deposit": 160000,
                "tranDateString": "04/12/2024 10:38 AM",
                "valueDateString": "04/12/2024 10:38 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "12/04/2024 10:38:31"
              },
              {
                "tranID": " S6374006",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-04-12T10:38:00",
                "partTranType": "C",
                "tranAmount": 50000,
                "tranParticular": "NIP FRM MICHEAL ADEWALE OGUNJIRIN-NIPOYEWUMI O",
                "referenceID": "NIP/240412085640277379121575",
                "valueDate": "2024-04-12T10:38:00",
                "reference": "001/4843257020/000843316039",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -300309.46,
                "withdrawls": 0,
                "deposit": 50000,
                "tranDateString": "04/12/2024 10:38 AM",
                "valueDateString": "04/12/2024 10:38 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "12/04/2024 10:38:31"
              },
              {
                "tranID": "S14144145",
                "accountName": "WAVELENGTH MULTI-RESOURCES NG",
                "customerCode": null,
                "tranDate": "2024-04-14T00:00:00",
                "partTranType": "C",
                "tranAmount": 260500,
                "tranParticular": "TRF From WAVELENGTH MULTI-RESOURCES NG/App: To FCM",
                "referenceID": "PPL",
                "valueDate": "2024-04-13T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -39809.46,
                "withdrawls": 0,
                "deposit": 260500,
                "tranDateString": "04/14/2024 ",
                "valueDateString": "04/13/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "14/04/2024 10:53:31"
              },
              {
                "tranID": "S29916548",
                "accountName": "TERNA DOOFAN RITA",
                "customerCode": null,
                "tranDate": "2024-04-17T00:00:00",
                "partTranType": "C",
                "tranAmount": 30000,
                "tranParticular": "TRF From TERNA DOOFAN RITA/App:Refund To FCMB O",
                "referenceID": "PPL",
                "valueDate": "2024-04-17T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -9809.46,
                "withdrawls": 0,
                "deposit": 30000,
                "tranDateString": "04/17/2024 ",
                "valueDateString": "04/17/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "17/04/2024 22:17:23"
              },
              {
                "tranID": "S30644398",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-04-17T00:00:00",
                "partTranType": "D",
                "tranAmount": 50000,
                "tranParticular": "TRF to CELESTIAL CHURCH OF CHRIST OLUWA ODODOWA PA",
                "referenceID": "PPL",
                "valueDate": "2024-04-17T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -59809.46,
                "withdrawls": 50000,
                "deposit": 0,
                "tranDateString": "04/17/2024 ",
                "valueDateString": "04/17/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "18/04/2024 00:24:55"
              },
              {
                "tranID": "S33223263",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-04-18T00:00:00",
                "partTranType": "D",
                "tranAmount": 10000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-04-18T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -69809.46,
                "withdrawls": 10000,
                "deposit": 0,
                "tranDateString": "04/18/2024 ",
                "valueDateString": "04/18/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "18/04/2024 15:53:09"
              },
              {
                "tranID": "S43156207",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-04-21T00:00:00",
                "partTranType": "D",
                "tranAmount": 2000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-04-20T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -71809.46,
                "withdrawls": 2000,
                "deposit": 0,
                "tranDateString": "04/21/2024 ",
                "valueDateString": "04/20/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "21/04/2024 09:49:28"
              },
              {
                "tranID": "S43434653",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-04-21T00:00:00",
                "partTranType": "D",
                "tranAmount": 2000,
                "tranParticular": "Appfund To VFD Microfinance Bank ORDAAFRICAPLA",
                "referenceID": "NIP/240420224646003036173719",
                "valueDate": "2024-04-20T00:00:00",
                "reference": "082/4843257020/000866529431",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -73809.46,
                "withdrawls": 2000,
                "deposit": 0,
                "tranDateString": "04/21/2024 ",
                "valueDateString": "04/20/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "21/04/2024 09:49:28"
              },
              {
                "tranID": "S43434657",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-04-21T00:00:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-Appfund To VFD Microfinance Ban",
                "referenceID": "NIP/240420224646003036173719",
                "valueDate": "2024-04-21T00:00:00",
                "reference": "082/4843257020/000866529431",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -73820.21,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "04/21/2024 ",
                "valueDateString": "04/21/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "21/04/2024 09:49:28"
              },
              {
                "tranID": "S44403904",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-04-21T00:00:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": "TRF to WAVELENGTH MULTI-RESOURCES NG/App: To FCMB",
                "referenceID": "PPL",
                "valueDate": "2024-04-21T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -78820.21,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "04/21/2024 ",
                "valueDateString": "04/21/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "21/04/2024 11:20:51"
              },
              {
                "tranID": "S44991405",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-04-21T00:00:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": "Appfund To MONIEPOINT MICROFINANCE BANK SHERIF",
                "referenceID": "NIP/240421141709003037060353",
                "valueDate": "2024-04-21T00:00:00",
                "reference": "082/4843257020/000867458283",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -83820.21,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "04/21/2024 ",
                "valueDateString": "04/21/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "21/04/2024 14:19:38"
              },
              {
                "tranID": "S44991413",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-04-21T00:00:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-Appfund To MONIEPOINT MICROFINA",
                "referenceID": "NIP/240421141709003037060353",
                "valueDate": "2024-04-21T00:00:00",
                "reference": "082/4843257020/000867458283",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -83830.96,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "04/21/2024 ",
                "valueDateString": "04/21/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "21/04/2024 14:19:38"
              },
              {
                "tranID": "S46071292",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-04-22T00:00:00",
                "partTranType": "D",
                "tranAmount": 7300,
                "tranParticular": "Appdid To Opay Digital Services Limited AYONIK",
                "referenceID": "NIP/240421192559003037811171",
                "valueDate": "2024-04-21T00:00:00",
                "reference": "082/4843257020/000868182185",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -91130.96,
                "withdrawls": 7300,
                "deposit": 0,
                "tranDateString": "04/22/2024 ",
                "valueDateString": "04/21/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "22/04/2024 10:17:12"
              },
              {
                "tranID": "S46071327",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-04-22T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appdid To Opay Digital Services",
                "referenceID": "NIP/240421192559003037811171",
                "valueDate": "2024-04-22T00:00:00",
                "reference": "082/4843257020/000868182185",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -91157.84,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "04/22/2024 ",
                "valueDateString": "04/22/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "22/04/2024 10:17:12"
              },
              {
                "tranID": "S48984534",
                "accountName": "ANIMASAUN OLAYINKA WALIU",
                "customerCode": null,
                "tranDate": "2024-04-22T00:00:00",
                "partTranType": "C",
                "tranAmount": 5000,
                "tranParticular": "TRF From ANIMASAUN OLAYINKA WALIU/App:refund To FC",
                "referenceID": "PPL",
                "valueDate": "2024-04-22T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -86157.84,
                "withdrawls": 0,
                "deposit": 5000,
                "tranDateString": "04/22/2024 ",
                "valueDateString": "04/22/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "22/04/2024 15:40:51"
              },
              {
                "tranID": "S60182167",
                "accountName": "WAVELENGTH MULTI-RESOURCES NG",
                "customerCode": null,
                "tranDate": "2024-04-25T00:00:00",
                "partTranType": "C",
                "tranAmount": 1000,
                "tranParticular": "TRF From WAVELENGTH MULTI-RESOURCES NG/App: To FCM",
                "referenceID": "PPL",
                "valueDate": "2024-04-25T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -85157.84,
                "withdrawls": 0,
                "deposit": 1000,
                "tranDateString": "04/25/2024 ",
                "valueDateString": "04/25/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "25/04/2024 10:19:36"
              },
              {
                "tranID": "S60216082",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-04-25T00:00:00",
                "partTranType": "D",
                "tranAmount": 1000,
                "tranParticular": "TRF to WAVELENGTH MULTI-RESOURCES NG/App: To First",
                "referenceID": "PPL",
                "valueDate": "2024-04-25T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -86157.84,
                "withdrawls": 1000,
                "deposit": 0,
                "tranDateString": "04/25/2024 ",
                "valueDateString": "04/25/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "25/04/2024 10:19:36"
              },
              {
                "tranID": "S70683866",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-04-28T00:00:00",
                "partTranType": "D",
                "tranAmount": 100000,
                "tranParticular": "TRF to TERNA DOOFAN RITA/App:fund To FCMB TER",
                "referenceID": "PPL",
                "valueDate": "2024-04-27T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -186157.84,
                "withdrawls": 100000,
                "deposit": 0,
                "tranDateString": "04/28/2024 ",
                "valueDateString": "04/27/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "27/04/2024 17:30:54"
              },
              {
                "tranID": "S71823701",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-04-28T00:00:00",
                "partTranType": "D",
                "tranAmount": 70000,
                "tranParticular": "TRF to ANIMASAUN OLAYINKA WALIU/App: To FCMB ANIMA",
                "referenceID": "PPL",
                "valueDate": "2024-04-27T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -256157.84,
                "withdrawls": 70000,
                "deposit": 0,
                "tranDateString": "04/28/2024 ",
                "valueDateString": "04/27/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "27/04/2024 18:35:17"
              },
              {
                "tranID": "S75990467",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-04-28T00:00:00",
                "partTranType": "D",
                "tranAmount": 28,
                "tranParticular": "AUTHENTICATION CODE CHARGE Outstanding MAR 2024",
                "referenceID": " ",
                "valueDate": "2024-04-28T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -256185.84,
                "withdrawls": 28,
                "deposit": 0,
                "tranDateString": "04/28/2024 ",
                "valueDateString": "04/28/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "29/04/2024 01:48:59"
              },
              {
                "tranID": "S77160520",
                "accountName": "TOBS INTERGRATED GLOBAL CONCEPT LTD",
                "customerCode": null,
                "tranDate": "2024-04-29T00:00:00",
                "partTranType": "D",
                "tranAmount": 200,
                "tranParticular": "SMS ALERT CHARGES FROM 23RD MAR TO 26TH APR 2024",
                "referenceID": " ",
                "valueDate": "2024-04-29T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -256385.84,
                "withdrawls": 200,
                "deposit": 0,
                "tranDateString": "04/29/2024 ",
                "valueDateString": "04/29/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  13",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "29/04/2024 12:04:09"
              },
              {
                "tranID": "S86305127",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-04-30T04:20:00",
                "partTranType": "D",
                "tranAmount": 3000,
                "tranParticular": "TRF to ADUNMO RACHEAL OMOWUNMI/App:did. To FCMB AD",
                "referenceID": "PPL",
                "valueDate": "2024-04-30T04:20:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -259385.84,
                "withdrawls": 3000,
                "deposit": 0,
                "tranDateString": "04/30/2024 04:20 AM",
                "valueDateString": "04/30/2024 04:20 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "01/05/2024 04:20:35"
              },
              {
                "tranID": "S94925232",
                "accountName": "JULSIX INTEGRATED CONCEPT",
                "customerCode": null,
                "tranDate": "2024-05-02T18:13:00",
                "partTranType": "C",
                "tranAmount": 430250,
                "tranParticular": "web: TRF Frm JULSIX INTEGRATED CONCEPT/web:APR SAL",
                "referenceID": "RIB",
                "valueDate": "2024-05-02T18:13:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": 170864.16,
                "withdrawls": 0,
                "deposit": 430250,
                "tranDateString": "05/02/2024 06:13 PM",
                "valueDateString": "05/02/2024 06:13 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "02/05/2024 18:13:03"
              },
              {
                "tranID": "S99462552",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-05-03T14:46:00",
                "partTranType": "C",
                "tranAmount": 50000,
                "tranParticular": "NIP FRM EMMANUEL  CHRISTOPHER EFE-Inflow from",
                "referenceID": "NIP/240503140633000042178259",
                "valueDate": "2024-05-03T14:46:00",
                "reference": "001/4843257020/000903901273",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": 220864.16,
                "withdrawls": 0,
                "deposit": 50000,
                "tranDateString": "05/03/2024 02:46 PM",
                "valueDateString": "05/03/2024 02:46 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "03/05/2024 14:46:01"
              },
              {
                "tranID": "  S571973",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-05-03T17:41:00",
                "partTranType": "D",
                "tranAmount": 1000000,
                "tranParticular": "Appfund To ACCESS BANK PLC DIAMOND OLAOLU AMOS",
                "referenceID": "NIP/240503172234003068976017",
                "valueDate": "2024-05-03T17:41:00",
                "reference": "082/4843257020/000904584711",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -779135.84,
                "withdrawls": 1000000,
                "deposit": 0,
                "tranDateString": "05/03/2024 05:41 PM",
                "valueDateString": "05/03/2024 05:41 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "03/05/2024 17:41:48"
              },
              {
                "tranID": "  S572002",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-05-03T17:41:00",
                "partTranType": "D",
                "tranAmount": 53.75,
                "tranParticular": "TRANSACTION CHARGE-Appfund To ACCESS BANK PLC DIAM",
                "referenceID": "NIP/240503172234003068976017",
                "valueDate": "2024-05-03T17:41:00",
                "reference": "082/4843257020/000904584711",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -779189.59,
                "withdrawls": 53.75,
                "deposit": 0,
                "tranDateString": "05/03/2024 05:41 PM",
                "valueDateString": "05/03/2024 05:41 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "03/05/2024 17:41:48"
              },
              {
                "tranID": "  S585442",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-05-03T17:41:00",
                "partTranType": "D",
                "tranAmount": 50000,
                "tranParticular": "TRF to ABIODUN VICTORIA OLUFUNMILOLA/App:Gboye To",
                "referenceID": "PPL",
                "valueDate": "2024-05-03T17:41:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -829189.59,
                "withdrawls": 50000,
                "deposit": 0,
                "tranDateString": "05/03/2024 05:41 PM",
                "valueDateString": "05/03/2024 05:41 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "03/05/2024 17:41:48"
              },
              {
                "tranID": "S13468421",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-05-06T17:28:00",
                "partTranType": "D",
                "tranAmount": 50000,
                "tranParticular": "TRF to FIX ROUND SOLUTION/App:initial balance",
                "referenceID": "PPL",
                "valueDate": "2024-05-06T17:28:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -879189.59,
                "withdrawls": 50000,
                "deposit": 0,
                "tranDateString": "05/06/2024 05:28 PM",
                "valueDateString": "05/06/2024 05:28 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "06/05/2024 17:28:46"
              },
              {
                "tranID": "S17941628",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-05-07T16:09:00",
                "partTranType": "D",
                "tranAmount": 420500,
                "tranParticular": "TRF to WAVELENGTH MULTI-RESOURCES NG/App:fund To F",
                "referenceID": "PPL",
                "valueDate": "2024-05-07T16:09:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1299689.59,
                "withdrawls": 420500,
                "deposit": 0,
                "tranDateString": "05/07/2024 04:09 PM",
                "valueDateString": "05/07/2024 04:09 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "07/05/2024 16:09:09"
              },
              {
                "tranID": "S21682183",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-05-08T13:57:00",
                "partTranType": "C",
                "tranAmount": 411500,
                "tranParticular": "NIP FRM ADEWALE  SHOYEMI-FCMBApril salary",
                "referenceID": "NIP/240508125433394797722025",
                "valueDate": "2024-05-08T13:57:00",
                "reference": "001/4843257020/000917800389",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -888189.59,
                "withdrawls": 0,
                "deposit": 411500,
                "tranDateString": "05/08/2024 01:57 PM",
                "valueDateString": "05/08/2024 01:57 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "08/05/2024 13:57:49"
              },
              {
                "tranID": "S21801263",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-05-08T13:57:00",
                "partTranType": "D",
                "tranAmount": 411500,
                "tranParticular": "TRF to WAVELENGTH MULTI-RESOURCES NG/App: To FCMB",
                "referenceID": "PPL",
                "valueDate": "2024-05-08T13:57:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1299689.59,
                "withdrawls": 411500,
                "deposit": 0,
                "tranDateString": "05/08/2024 01:57 PM",
                "valueDateString": "05/08/2024 01:57 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "08/05/2024 13:57:49"
              },
              {
                "tranID": "S21853482",
                "accountName": "WAVELENGTH MULTI-RESOURCES NG",
                "customerCode": null,
                "tranDate": "2024-05-08T13:57:00",
                "partTranType": "C",
                "tranAmount": 50000,
                "tranParticular": "TRF From WAVELENGTH MULTI-RESOURCES NG/App: To FCM",
                "referenceID": "PPL",
                "valueDate": "2024-05-08T13:57:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1249689.59,
                "withdrawls": 0,
                "deposit": 50000,
                "tranDateString": "05/08/2024 01:57 PM",
                "valueDateString": "05/08/2024 01:57 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "08/05/2024 13:57:49"
              },
              {
                "tranID": "S37125171",
                "accountName": "ANIMASAUN OLAYINKA WALIU",
                "customerCode": null,
                "tranDate": "2024-05-13T00:00:00",
                "partTranType": "C",
                "tranAmount": 155000,
                "tranParticular": "TRF From ANIMASAUN OLAYINKA WALIU/App:cleaning To",
                "referenceID": "PPL",
                "valueDate": "2024-05-12T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1094689.59,
                "withdrawls": 0,
                "deposit": 155000,
                "tranDateString": "05/13/2024 ",
                "valueDateString": "05/12/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "13/05/2024 01:09:16"
              },
              {
                "tranID": "S37539439",
                "accountName": "ANIMASAUN OLAYINKA WALIU",
                "customerCode": null,
                "tranDate": "2024-05-13T00:00:00",
                "partTranType": "C",
                "tranAmount": 250000,
                "tranParticular": "TRF From ANIMASAUN OLAYINKA WALIU/App:rentage To F",
                "referenceID": "PPL",
                "valueDate": "2024-05-12T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -844689.59,
                "withdrawls": 0,
                "deposit": 250000,
                "tranDateString": "05/13/2024 ",
                "valueDateString": "05/12/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "13/05/2024 01:09:16"
              },
              {
                "tranID": "S41712985",
                "accountName": "WAVELENGTH MULTI-RESOURCES NG",
                "customerCode": null,
                "tranDate": "2024-05-13T00:00:00",
                "partTranType": "C",
                "tranAmount": 15500,
                "tranParticular": "TRF From WAVELENGTH MULTI-RESOURCES NG/App: To FCM",
                "referenceID": "PPL",
                "valueDate": "2024-05-13T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -829189.59,
                "withdrawls": 0,
                "deposit": 15500,
                "tranDateString": "05/13/2024 ",
                "valueDateString": "05/13/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "13/05/2024 21:44:13"
              },
              {
                "tranID": "S41716838",
                "accountName": "OKUBOYEJO OYINLOLA RUQAYYAH",
                "customerCode": null,
                "tranDate": "2024-05-13T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY HOLD ON ACCOUNT ON 13-MAY-2024",
                "referenceID": " ",
                "valueDate": "2024-05-13T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -829239.59,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "05/13/2024 ",
                "valueDateString": "05/13/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  15",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "13/05/2024 21:44:13"
              },
              {
                "tranID": "S37127893",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-05-13T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY HOLD ON ACCOUNT ON 13-MAY-2024",
                "referenceID": " ",
                "valueDate": "2024-05-13T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -829289.59,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "05/13/2024 ",
                "valueDateString": "05/13/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "13/05/2024 21:44:13"
              },
              {
                "tranID": "S41716837",
                "accountName": "OKUBOYEJO OYINLOLA RUQAYYAH",
                "customerCode": null,
                "tranDate": "2024-05-13T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY HOLD ON ACCOUNT ON 13-MAY-2024",
                "referenceID": " ",
                "valueDate": "2024-05-13T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -829339.59,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "05/13/2024 ",
                "valueDateString": "05/13/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  15",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "13/05/2024 21:49:49"
              },
              {
                "tranID": "S48677372",
                "accountName": "FUND TRANSFER TSS RECEIVALE A/C",
                "customerCode": null,
                "tranDate": "2024-05-15T00:00:00",
                "partTranType": "C",
                "tranAmount": 90000,
                "tranParticular": "Mbanking Trf: ACB/001604070862;;NXG :TRFPR FOR PEJ",
                "referenceID": "FCMB MOBILE",
                "valueDate": "2024-05-15T00:00:00",
                "reference": "4843257020/1604070862/670020/E",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -739339.59,
                "withdrawls": 0,
                "deposit": 90000,
                "tranDateString": "05/15/2024 ",
                "valueDateString": "05/15/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "15/05/2024 09:01:50"
              },
              {
                "tranID": "S49678150",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-05-15T00:00:00",
                "partTranType": "D",
                "tranAmount": 55000,
                "tranParticular": "TRF to SERIKI OLAPEJU ASSISAT/App:fund To FCMB SER",
                "referenceID": "PPL",
                "valueDate": "2024-05-15T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -794339.59,
                "withdrawls": 55000,
                "deposit": 0,
                "tranDateString": "05/15/2024 ",
                "valueDateString": "05/15/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "15/05/2024 11:12:05"
              },
              {
                "tranID": "S50868986",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-05-15T00:00:00",
                "partTranType": "D",
                "tranAmount": 35000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-05-15T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -829339.59,
                "withdrawls": 35000,
                "deposit": 0,
                "tranDateString": "05/15/2024 ",
                "valueDateString": "05/15/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "15/05/2024 15:31:55"
              },
              {
                "tranID": "S84794138",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-05-23T00:00:00",
                "partTranType": "D",
                "tranAmount": 120000,
                "tranParticular": "Appgboyega To GTBank Plc ADEOYE DANIEL OLANREW",
                "referenceID": "NIP/240523204346003116404848",
                "valueDate": "2024-05-23T00:00:00",
                "reference": "082/4843257020/000958540933",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -949339.59,
                "withdrawls": 120000,
                "deposit": 0,
                "tranDateString": "05/23/2024 ",
                "valueDateString": "05/23/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "23/05/2024 23:24:51"
              },
              {
                "tranID": "S84794311",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-05-23T00:00:00",
                "partTranType": "D",
                "tranAmount": 53.75,
                "tranParticular": "TRANSACTION CHARGE-Appgboyega To GTBank Plc ADEOYE",
                "referenceID": "NIP/240523204346003116404848",
                "valueDate": "2024-05-23T00:00:00",
                "reference": "082/4843257020/000958540933",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -949393.34,
                "withdrawls": 53.75,
                "deposit": 0,
                "tranDateString": "05/23/2024 ",
                "valueDateString": "05/23/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "23/05/2024 23:24:51"
              },
              {
                "tranID": "S93953505",
                "accountName": "OYEDOKUN OLUSEGUN S. ADEOYE ERIC E. AND ADENIJI OLUSEGUN M.",
                "customerCode": null,
                "tranDate": "2024-05-26T00:00:00",
                "partTranType": "D",
                "tranAmount": 76,
                "tranParticular": "SMS ALERT CHARGES FROM 27TH APR TO 23RD MAY 2024",
                "referenceID": " ",
                "valueDate": "2024-05-26T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -949469.34,
                "withdrawls": 76,
                "deposit": 0,
                "tranDateString": "05/26/2024 ",
                "valueDateString": "05/26/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  40",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "26/05/2024 23:11:32"
              },
              {
                "tranID": " S6907832",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-05-29T00:00:00",
                "partTranType": "D",
                "tranAmount": 16,
                "tranParticular": "AUTHENTICATION CODE CHARGE Outstanding APR 2024",
                "referenceID": " ",
                "valueDate": "2024-05-29T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -949485.34,
                "withdrawls": 16,
                "deposit": 0,
                "tranDateString": "05/29/2024 ",
                "valueDateString": "05/29/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "29/05/2024 09:37:31"
              },
              {
                "tranID": "S29351846",
                "accountName": "WAVELENGTH MULTI-RESOURCES NG",
                "customerCode": null,
                "tranDate": "2024-06-02T14:43:00",
                "partTranType": "C",
                "tranAmount": 120000,
                "tranParticular": "TRF From WAVELENGTH MULTI-RESOURCES NG/App To FCMB",
                "referenceID": "PPL",
                "valueDate": "2024-06-02T14:43:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -829485.34,
                "withdrawls": 0,
                "deposit": 120000,
                "tranDateString": "06/02/2024 02:43 PM",
                "valueDateString": "06/02/2024 02:43 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "02/06/2024 14:43:23"
              },
              {
                "tranID": "S29657411",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-06-02T20:56:00",
                "partTranType": "D",
                "tranAmount": 5100,
                "tranParticular": "Appfund To MONIEPOINT MICROFINANCE BANK SHERIF",
                "referenceID": "NIP/240602155428003141189283",
                "valueDate": "2024-06-02T20:56:00",
                "reference": "082/4843257020/000987641253",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -834585.34,
                "withdrawls": 5100,
                "deposit": 0,
                "tranDateString": "06/02/2024 08:56 PM",
                "valueDateString": "06/02/2024 08:56 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "02/06/2024 20:56:49"
              },
              {
                "tranID": "S29657436",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-06-02T20:56:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appfund To MONIEPOINT MICROFINA",
                "referenceID": "NIP/240602155428003141189283",
                "valueDate": "2024-06-02T20:56:00",
                "reference": "082/4843257020/000987641253",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -834612.22,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "06/02/2024 08:56 PM",
                "valueDateString": "06/02/2024 08:56 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "02/06/2024 20:56:49"
              },
              {
                "tranID": "S33056213",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-06-03T13:34:00",
                "partTranType": "D",
                "tranAmount": 200,
                "tranParticular": "QR/Q22375422525/Airtime/MTN/2347032819698",
                "referenceID": "USSD",
                "valueDate": "2024-06-03T13:34:00",
                "reference": "USSD/Q22375422525",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -834812.22,
                "withdrawls": 200,
                "deposit": 0,
                "tranDateString": "06/03/2024 01:34 PM",
                "valueDateString": "06/03/2024 01:34 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "03/06/2024 13:34:01"
              },
              {
                "tranID": "S35252884",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-06-03T21:49:00",
                "partTranType": "D",
                "tranAmount": 300,
                "tranParticular": "QR/Q22376339039/Airtime/MTN/2347032819698",
                "referenceID": "USSD",
                "valueDate": "2024-06-03T21:49:00",
                "reference": "USSD/Q22376339039",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -835112.22,
                "withdrawls": 300,
                "deposit": 0,
                "tranDateString": "06/03/2024 09:49 PM",
                "valueDateString": "06/03/2024 09:49 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "03/06/2024 21:49:10"
              },
              {
                "tranID": "S37673648",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-06-04T12:58:00",
                "partTranType": "D",
                "tranAmount": 500,
                "tranParticular": "QR/Q22377549106/Airtime/MTN/2347032819698",
                "referenceID": "USSD",
                "valueDate": "2024-06-04T12:58:00",
                "reference": "USSD/Q22377549106",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -835612.22,
                "withdrawls": 500,
                "deposit": 0,
                "tranDateString": "06/04/2024 12:58 PM",
                "valueDateString": "06/04/2024 12:58 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "04/06/2024 12:58:13"
              },
              {
                "tranID": " 01168971",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-06-04T19:43:00",
                "partTranType": "D",
                "tranAmount": 3000,
                "tranParticular": "CSHW BY:OLAOLU OYEWUMI|YABA BRANCH",
                "referenceID": "SFR2SFR2|Cash",
                "valueDate": "2024-06-04T19:43:00",
                "reference": "082|CHW|BAB4117",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -838612.22,
                "withdrawls": 3000,
                "deposit": 0,
                "tranDateString": "06/04/2024 07:43 PM",
                "valueDateString": "06/04/2024 07:43 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "04/06/2024 19:43:17"
              },
              {
                "tranID": "S42691059",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-06-05T14:49:00",
                "partTranType": "D",
                "tranAmount": 1200,
                "tranParticular": "TRF to ABIKOYE TEMILOLA OLUWAFUNKE/App: To FCMB AB",
                "referenceID": "PPL",
                "valueDate": "2024-06-05T14:49:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -839812.22,
                "withdrawls": 1200,
                "deposit": 0,
                "tranDateString": "06/05/2024 02:49 PM",
                "valueDateString": "06/05/2024 02:49 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "05/06/2024 14:49:48"
              },
              {
                "tranID": "S42989290",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-06-05T15:41:00",
                "partTranType": "D",
                "tranAmount": 1500,
                "tranParticular": "FCMBMobile:AirtimeTopup0816703727724060514352",
                "referenceID": "MTN/2406051435249324843257020/MOBILE",
                "valueDate": "2024-06-05T15:41:00",
                "reference": "2406051435249324843257020",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -841312.22,
                "withdrawls": 1500,
                "deposit": 0,
                "tranDateString": "06/05/2024 03:41 PM",
                "valueDateString": "06/05/2024 03:41 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "05/06/2024 15:41:39"
              },
              {
                "tranID": "S44546572",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-06-06T09:53:00",
                "partTranType": "D",
                "tranAmount": 4100,
                "tranParticular": "Appdrinks  ofada To Opay Digital Services Limi",
                "referenceID": "NIP/240605203517003149794691",
                "valueDate": "2024-06-05T09:53:00",
                "reference": "082/4843257020/000997730613",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -845412.22,
                "withdrawls": 4100,
                "deposit": 0,
                "tranDateString": "06/06/2024 09:53 AM",
                "valueDateString": "06/05/2024 09:53 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "06/06/2024 09:53:08"
              },
              {
                "tranID": "S44546704",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-06-06T09:53:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-Appdrinks  ofada To Opay Digita",
                "referenceID": "NIP/240605203517003149794691",
                "valueDate": "2024-06-06T09:53:00",
                "reference": "082/4843257020/000997730613",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -845422.97,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "06/06/2024 09:53 AM",
                "valueDateString": "06/06/2024 09:53 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "06/06/2024 09:53:08"
              },
              {
                "tranID": "S44699466",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-06-06T09:53:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-06-05T09:53:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -850422.97,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "06/06/2024 09:53 AM",
                "valueDateString": "06/05/2024 09:53 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "06/06/2024 09:53:08"
              },
              {
                "tranID": "S44745087",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-06-06T09:53:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": " App:fund To United Bank for Africa ADEGBOYEGA",
                "referenceID": "FTN000215036081/4843257020",
                "valueDate": "2024-06-05T09:53:00",
                "reference": "ETRNZ|241236179899",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -855422.97,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "06/06/2024 09:53 AM",
                "valueDateString": "06/05/2024 09:53 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "06/06/2024 09:53:08"
              },
              {
                "tranID": "S44745097",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-06-06T09:53:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App:fund To United Bank for Afr",
                "referenceID": "FTN000215036081/4843257020",
                "valueDate": "2024-06-06T09:53:00",
                "reference": "ETRNZ|241236179899",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -855433.72,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "06/06/2024 09:53 AM",
                "valueDateString": "06/06/2024 09:53 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "06/06/2024 09:53:08"
              },
              {
                "tranID": "S50123599",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-06-07T11:35:00",
                "partTranType": "C",
                "tranAmount": 5000,
                "tranParticular": "TRF From OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-06-07T11:35:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -850433.72,
                "withdrawls": 0,
                "deposit": 5000,
                "tranDateString": "06/07/2024 11:35 AM",
                "valueDateString": "06/07/2024 11:35 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "07/06/2024 11:35:05"
              },
              {
                "tranID": "S52086909",
                "accountName": "JULSIX INTEGRATED CONCEPT",
                "customerCode": null,
                "tranDate": "2024-06-07T17:05:00",
                "partTranType": "C",
                "tranAmount": 430250,
                "tranParticular": "web: TRF Frm JULSIX INTEGRATED CONCEPT/web:MAY SAL",
                "referenceID": "RIB",
                "valueDate": "2024-06-07T17:05:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -420183.72,
                "withdrawls": 0,
                "deposit": 430250,
                "tranDateString": "06/07/2024 05:05 PM",
                "valueDateString": "06/07/2024 05:05 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "07/06/2024 17:05:28"
              },
              {
                "tranID": "S52290807",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-06-07T17:31:00",
                "partTranType": "D",
                "tranAmount": 430250,
                "tranParticular": "TRF to WAVELENGTH MULTI-RESOURCES NG/App:fund To F",
                "referenceID": "PPL",
                "valueDate": "2024-06-07T17:31:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -850433.72,
                "withdrawls": 430250,
                "deposit": 0,
                "tranDateString": "06/07/2024 05:31 PM",
                "valueDateString": "06/07/2024 05:31 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "07/06/2024 17:31:26"
              },
              {
                "tranID": "S67308222",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-06-11T12:51:00",
                "partTranType": "D",
                "tranAmount": 10000,
                "tranParticular": "Appfund To GTBank Plc OYEWUMI OLAWUMI TOLUWANI",
                "referenceID": "NIP/240611123656003162761977",
                "valueDate": "2024-06-11T12:51:00",
                "reference": "082/4843257020/001012837291",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -860433.72,
                "withdrawls": 10000,
                "deposit": 0,
                "tranDateString": "06/11/2024 12:51 PM",
                "valueDateString": "06/11/2024 12:51 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "11/06/2024 12:51:24"
              },
              {
                "tranID": "S67308232",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-06-11T12:51:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appfund To GTBank Plc OYEWUMI O",
                "referenceID": "NIP/240611123656003162761977",
                "valueDate": "2024-06-11T12:51:00",
                "reference": "082/4843257020/001012837291",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -860460.6,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "06/11/2024 12:51 PM",
                "valueDateString": "06/11/2024 12:51 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "11/06/2024 12:51:24"
              },
              {
                "tranID": "S69872858",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-06-11T00:35:00",
                "partTranType": "D",
                "tranAmount": 30000,
                "tranParticular": "Appfund To GTBank Plc OYEWUMI OLAWALE SIMON",
                "referenceID": "NIP/240611212017003164203924",
                "valueDate": "2024-06-11T00:35:00",
                "reference": "082/4843257020/001014684743",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -890460.6,
                "withdrawls": 30000,
                "deposit": 0,
                "tranDateString": "06/11/2024 12:35 AM",
                "valueDateString": "06/11/2024 12:35 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "12/06/2024 00:35:32"
              },
              {
                "tranID": "S69872874",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-06-11T00:35:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appfund To GTBank Plc OYEWUMI O",
                "referenceID": "NIP/240611212017003164203924",
                "valueDate": "2024-06-11T00:35:00",
                "reference": "082/4843257020/001014684743",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -890487.48,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "06/11/2024 12:35 AM",
                "valueDateString": "06/11/2024 12:35 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "12/06/2024 00:35:32"
              },
              {
                "tranID": "S75563588",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-06-13T00:00:00",
                "partTranType": "D",
                "tranAmount": 10000,
                "tranParticular": "Appfund To MONIEPOINT MICROFINANCE BANK AKINOL",
                "referenceID": "NIP/240613103313003167367777",
                "valueDate": "2024-06-13T00:00:00",
                "reference": "082/4843257020/001018720675",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -900487.48,
                "withdrawls": 10000,
                "deposit": 0,
                "tranDateString": "06/13/2024 ",
                "valueDateString": "06/13/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "13/06/2024 16:47:44"
              },
              {
                "tranID": "S75563708",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-06-13T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appfund To MONIEPOINT MICROFINA",
                "referenceID": "NIP/240613103313003167367777",
                "valueDate": "2024-06-13T00:00:00",
                "reference": "082/4843257020/001018720675",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -900514.36,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "06/13/2024 ",
                "valueDateString": "06/13/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "13/06/2024 16:47:44"
              },
              {
                "tranID": "S81283503",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-06-14T00:00:00",
                "partTranType": "C",
                "tranAmount": 10000,
                "tranParticular": "TRF From OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-06-14T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -890514.36,
                "withdrawls": 0,
                "deposit": 10000,
                "tranDateString": "06/14/2024 ",
                "valueDateString": "06/14/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "14/06/2024 15:08:58"
              },
              {
                "tranID": "S86274934",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-06-16T00:00:00",
                "partTranType": "C",
                "tranAmount": 50000,
                "tranParticular": "NIP FRM DOOSHIMA DORIS ASHIRGA-from Rita",
                "referenceID": "NIP/240615145415115695509517",
                "valueDate": "2024-06-15T00:00:00",
                "reference": "001/4843257020/001025922061",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -840514.36,
                "withdrawls": 0,
                "deposit": 50000,
                "tranDateString": "06/16/2024 ",
                "valueDateString": "06/15/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/06/2024 17:04:19"
              },
              {
                "tranID": "S96460030",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-06-18T00:00:00",
                "partTranType": "D",
                "tranAmount": 500,
                "tranParticular": "QR/Q22405336902/Airtime/MTN/2347032819698",
                "referenceID": "USSD",
                "valueDate": "2024-06-18T00:00:00",
                "reference": "USSD/Q22405336902",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -841014.36,
                "withdrawls": 500,
                "deposit": 0,
                "tranDateString": "06/18/2024 ",
                "valueDateString": "06/18/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "18/06/2024 11:32:52"
              },
              {
                "tranID": "S96463798",
                "accountName": "MTN VAS2NET STOCK PAYABLE ACCOUNT",
                "customerCode": null,
                "tranDate": "2024-06-18T00:00:00",
                "partTranType": "C",
                "tranAmount": 500,
                "tranParticular": "Rev - QR/Q22405336902/Airtime/MTN/2347032819698",
                "referenceID": "FLASHMECASH",
                "valueDate": "2024-06-18T00:00:00",
                "reference": "USSD",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -840514.36,
                "withdrawls": 0,
                "deposit": 500,
                "tranDateString": "06/18/2024 ",
                "valueDateString": "06/18/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "18/06/2024 11:32:52"
              },
              {
                "tranID": "S10126694",
                "accountName": "TERNA DOOFAN RITA",
                "customerCode": null,
                "tranDate": "2024-06-21T00:00:00",
                "partTranType": "C",
                "tranAmount": 55000,
                "tranParticular": "TRF From TERNA DOOFAN RITA/App:Refund To FCMB O",
                "referenceID": "PPL",
                "valueDate": "2024-06-21T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -785514.36,
                "withdrawls": 0,
                "deposit": 55000,
                "tranDateString": "06/21/2024 ",
                "valueDateString": "06/21/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "21/06/2024 15:56:06"
              },
              {
                "tranID": "S11450266",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-06-21T00:00:00",
                "partTranType": "D",
                "tranAmount": 10000,
                "tranParticular": "Appfund To Polaris Bank KEHINDE TEMITOPE SHOLA",
                "referenceID": "NIP/240621180540003186959065",
                "valueDate": "2024-06-21T00:00:00",
                "reference": "082/4843257020/001040611991",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -795514.36,
                "withdrawls": 10000,
                "deposit": 0,
                "tranDateString": "06/21/2024 ",
                "valueDateString": "06/21/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "21/06/2024 18:06:55"
              },
              {
                "tranID": "S11450276",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-06-21T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appfund To Polaris Bank KEHINDE",
                "referenceID": "NIP/240621180540003186959065",
                "valueDate": "2024-06-21T00:00:00",
                "reference": "082/4843257020/001040611991",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -795541.24,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "06/21/2024 ",
                "valueDateString": "06/21/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "21/06/2024 18:06:55"
              },
              {
                "tranID": "S27971388",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-06-25T00:00:00",
                "partTranType": "C",
                "tranAmount": 10000,
                "tranParticular": "NIP FRM OYEWUMI OLAOLU AMOS-Inflow from OYEWUM",
                "referenceID": "NIP/240625160641000162802838",
                "valueDate": "2024-06-25T00:00:00",
                "reference": "001/4843257020/001050026605",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -785541.24,
                "withdrawls": 0,
                "deposit": 10000,
                "tranDateString": "06/25/2024 ",
                "valueDateString": "06/25/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "25/06/2024 16:11:36"
              },
              {
                "tranID": "S33636590",
                "accountName": "AIYEPE AJULO-OPIN MOSQUE",
                "customerCode": null,
                "tranDate": "2024-06-26T00:00:00",
                "partTranType": "D",
                "tranAmount": 100,
                "tranParticular": "SMS ALERT CHARGES FROM 24TH MAY TO 24TH JUN 2024",
                "referenceID": " ",
                "valueDate": "2024-06-26T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -785641.24,
                "withdrawls": 100,
                "deposit": 0,
                "tranDateString": "06/26/2024 ",
                "valueDateString": "06/26/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  89",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "26/06/2024 22:25:14"
              },
              {
                "tranID": "S35302441",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-06-27T00:00:00",
                "partTranType": "C",
                "tranAmount": 30000,
                "tranParticular": "NIP FRM OYEWUMI OLAOLU AMOS-Inflow from OYEWUM",
                "referenceID": "NIP/240627090350000166621430",
                "valueDate": "2024-06-27T00:00:00",
                "reference": "001/4843257020/001054365735",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -755641.24,
                "withdrawls": 0,
                "deposit": 30000,
                "tranDateString": "06/27/2024 ",
                "valueDateString": "06/27/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "27/06/2024 10:13:15"
              },
              {
                "tranID": "S43120777",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-06-28T00:00:00",
                "partTranType": "D",
                "tranAmount": 36000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-06-28T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -791641.24,
                "withdrawls": 36000,
                "deposit": 0,
                "tranDateString": "06/28/2024 ",
                "valueDateString": "06/28/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "28/06/2024 18:45:31"
              },
              {
                "tranID": "S43274781",
                "accountName": "ANIMASAUN OLAYINKA WALIU",
                "customerCode": null,
                "tranDate": "2024-06-28T00:00:00",
                "partTranType": "C",
                "tranAmount": 10000,
                "tranParticular": "TRF From ANIMASAUN OLAYINKA WALIU/App:olayinka To",
                "referenceID": "PPL",
                "valueDate": "2024-06-28T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -781641.24,
                "withdrawls": 0,
                "deposit": 10000,
                "tranDateString": "06/28/2024 ",
                "valueDateString": "06/28/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "28/06/2024 19:15:06"
              },
              {
                "tranID": "S43368167",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-06-28T00:00:00",
                "partTranType": "D",
                "tranAmount": 50000,
                "tranParticular": "Appgboyega To GTBank Plc ADEOYE DANIEL OLANREW",
                "referenceID": "NIP/240628193317003204214911",
                "valueDate": "2024-06-28T00:00:00",
                "reference": "082/4843257020/001059512831",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -831641.24,
                "withdrawls": 50000,
                "deposit": 0,
                "tranDateString": "06/28/2024 ",
                "valueDateString": "06/28/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "28/06/2024 19:33:47"
              },
              {
                "tranID": "S43368176",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-06-28T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appgboyega To GTBank Plc ADEOYE",
                "referenceID": "NIP/240628193317003204214911",
                "valueDate": "2024-06-28T00:00:00",
                "reference": "082/4843257020/001059512831",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -831668.12,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "06/28/2024 ",
                "valueDateString": "06/28/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "28/06/2024 19:33:47"
              },
              {
                "tranID": "S43948078",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-06-28T00:00:00",
                "partTranType": "D",
                "tranAmount": 10000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-06-28T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -841668.12,
                "withdrawls": 10000,
                "deposit": 0,
                "tranDateString": "06/28/2024 ",
                "valueDateString": "06/28/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "29/06/2024 05:08:50"
              },
              {
                "tranID": "S46517312",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-06-30T00:00:00",
                "partTranType": "D",
                "tranAmount": 250000,
                "tranParticular": "TRF to SERIKI OLAPEJU ASSISAT/App:fund To FCMB SER",
                "referenceID": "PPL",
                "valueDate": "2024-06-29T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1091668.12,
                "withdrawls": 250000,
                "deposit": 0,
                "tranDateString": "06/30/2024 ",
                "valueDateString": "06/29/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "29/06/2024 14:36:19"
              },
              {
                "tranID": "S48573706",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-06-30T00:00:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": "TRF to OGUNMODEDE WASIU ABIODUN/App:fund To FCMB O",
                "referenceID": "PPL",
                "valueDate": "2024-06-29T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1096668.12,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "06/30/2024 ",
                "valueDateString": "06/29/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "30/06/2024 09:24:59"
              },
              {
                "tranID": "S52231154",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-01T00:00:00",
                "partTranType": "D",
                "tranAmount": 16000,
                "tranParticular": "Appfund To First Bank of Nigeria OGUNMODEDE RA",
                "referenceID": "NIP/240630210205003208847617",
                "valueDate": "2024-06-30T00:00:00",
                "reference": "082/4843257020/001064604975",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1112668.12,
                "withdrawls": 16000,
                "deposit": 0,
                "tranDateString": "07/01/2024 ",
                "valueDateString": "06/30/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "30/06/2024 21:28:28"
              },
              {
                "tranID": "S52231159",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-01T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appfund To First Bank of Nigeri",
                "referenceID": "NIP/240630210205003208847617",
                "valueDate": "2024-07-01T00:00:00",
                "reference": "082/4843257020/001064604975",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1112695,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "07/01/2024 ",
                "valueDateString": "07/01/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "30/06/2024 21:28:28"
              },
              {
                "tranID": "S52756688",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-01T10:48:00",
                "partTranType": "D",
                "tranAmount": 4,
                "tranParticular": "AUTHENTICATION CODE CHARGE Outstanding MAY 2024",
                "referenceID": " ",
                "valueDate": "2024-07-01T10:48:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1112699,
                "withdrawls": 4,
                "deposit": 0,
                "tranDateString": "07/01/2024 10:48 AM",
                "valueDateString": "07/01/2024 10:48 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "01/07/2024 10:48:36"
              },
              {
                "tranID": "S53716933",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-07-01T20:33:00",
                "partTranType": "C",
                "tranAmount": 6000,
                "tranParticular": "NIP FRM ADENIRAN BABATUNDE-Inflow from ADENIRA",
                "referenceID": "NIP/240701100107000176626653",
                "valueDate": "2024-07-01T20:33:00",
                "reference": "001/4843257020/001065390587",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1106699,
                "withdrawls": 0,
                "deposit": 6000,
                "tranDateString": "07/01/2024 08:33 PM",
                "valueDateString": "07/01/2024 08:33 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "01/07/2024 20:33:23"
              },
              {
                "tranID": "S57053505",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-01T00:06:00",
                "partTranType": "D",
                "tranAmount": 100000,
                "tranParticular": "Appgboyega To First Bank of Nigeria ADEYEMO OL",
                "referenceID": "NIP/240701204827003211376810",
                "valueDate": "2024-07-01T00:06:00",
                "reference": "082/4843257020/001067481167",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1206699,
                "withdrawls": 100000,
                "deposit": 0,
                "tranDateString": "07/01/2024 12:06 AM",
                "valueDateString": "07/01/2024 12:06 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "02/07/2024 00:06:26"
              },
              {
                "tranID": "S57053525",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-01T00:06:00",
                "partTranType": "D",
                "tranAmount": 53.75,
                "tranParticular": "TRANSACTION CHARGE-Appgboyega To First Bank of Nig",
                "referenceID": "NIP/240701204827003211376810",
                "valueDate": "2024-07-01T00:06:00",
                "reference": "082/4843257020/001067481167",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1206752.75,
                "withdrawls": 53.75,
                "deposit": 0,
                "tranDateString": "07/01/2024 12:06 AM",
                "valueDateString": "07/01/2024 12:06 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "02/07/2024 00:06:26"
              },
              {
                "tranID": "S60839019",
                "accountName": "WAVELENGTH MULTI-RESOURCES NG",
                "customerCode": null,
                "tranDate": "2024-07-02T17:42:00",
                "partTranType": "C",
                "tranAmount": 150000,
                "tranParticular": "TRF From WAVELENGTH MULTI-RESOURCES NG/App To FCMB",
                "referenceID": "PPL",
                "valueDate": "2024-07-02T17:42:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1056752.75,
                "withdrawls": 0,
                "deposit": 150000,
                "tranDateString": "07/02/2024 05:42 PM",
                "valueDateString": "07/02/2024 05:42 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "02/07/2024 17:42:35"
              },
              {
                "tranID": "S61408617",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-07-02T19:49:00",
                "partTranType": "C",
                "tranAmount": 823000,
                "tranParticular": "NIP FRM ADEWALE  SHOYEMI-FCMB4MayJun Salary",
                "referenceID": "NIP/240702193326476094514406",
                "valueDate": "2024-07-02T19:49:00",
                "reference": "001/4843257020/001070204211",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -233752.75,
                "withdrawls": 0,
                "deposit": 823000,
                "tranDateString": "07/02/2024 07:49 PM",
                "valueDateString": "07/02/2024 07:49 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "02/07/2024 19:49:16"
              },
              {
                "tranID": "S61535689",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-02T23:07:00",
                "partTranType": "D",
                "tranAmount": 823000,
                "tranParticular": "TRF to WAVELENGTH MULTI-RESOURCES NG/App:fund To F",
                "referenceID": "PPL",
                "valueDate": "2024-07-02T23:07:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1056752.75,
                "withdrawls": 823000,
                "deposit": 0,
                "tranDateString": "07/02/2024 11:07 PM",
                "valueDateString": "07/02/2024 11:07 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "02/07/2024 23:07:45"
              },
              {
                "tranID": "S72850363",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-05T12:40:00",
                "partTranType": "D",
                "tranAmount": 34500,
                "tranParticular": "TRF to OBANUBI OYINDAMOLA RUTH/App:fund To FCMB OB",
                "referenceID": "PPL",
                "valueDate": "2024-07-05T12:40:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1091252.75,
                "withdrawls": 34500,
                "deposit": 0,
                "tranDateString": "07/05/2024 12:40 PM",
                "valueDateString": "07/05/2024 12:40 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "05/07/2024 12:40:19"
              },
              {
                "tranID": "S73102244",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-05T13:32:00",
                "partTranType": "D",
                "tranAmount": 15250,
                "tranParticular": "Appdrinks To GTBank Plc OFEIMU ABIODUN TRACY",
                "referenceID": "NIP/240705133147003219960538",
                "valueDate": "2024-07-05T13:32:00",
                "reference": "082/4843257020/001077360621",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1106502.75,
                "withdrawls": 15250,
                "deposit": 0,
                "tranDateString": "07/05/2024 01:32 PM",
                "valueDateString": "07/05/2024 01:32 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "05/07/2024 13:32:25"
              },
              {
                "tranID": "S73102256",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-05T13:32:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appdrinks To GTBank Plc OFEIMU",
                "referenceID": "NIP/240705133147003219960538",
                "valueDate": "2024-07-05T13:32:00",
                "reference": "082/4843257020/001077360621",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1106529.63,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "07/05/2024 01:32 PM",
                "valueDateString": "07/05/2024 01:32 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "05/07/2024 13:32:25"
              },
              {
                "tranID": "S84295078",
                "accountName": "HASSAN DAMILOLA MUINAT",
                "customerCode": null,
                "tranDate": "2024-07-08T09:52:00",
                "partTranType": "C",
                "tranAmount": 5000,
                "tranParticular": "TRF From HASSAN DAMILOLA MUINAT/App:happy birthday",
                "referenceID": "PPL",
                "valueDate": "2024-07-08T09:52:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1101529.63,
                "withdrawls": 0,
                "deposit": 5000,
                "tranDateString": "07/08/2024 09:52 AM",
                "valueDateString": "07/08/2024 09:52 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "08/07/2024 09:52:49"
              },
              {
                "tranID": "S84375253",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-08T10:04:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-07-08T10:04:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1106529.63,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "07/08/2024 10:04 AM",
                "valueDateString": "07/08/2024 10:04 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "08/07/2024 10:04:17"
              },
              {
                "tranID": "S87763413",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-09T09:12:00",
                "partTranType": "C",
                "tranAmount": 250000,
                "tranParticular": "TRF From OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-07-08T09:12:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -856529.63,
                "withdrawls": 0,
                "deposit": 250000,
                "tranDateString": "07/09/2024 09:12 AM",
                "valueDateString": "07/08/2024 09:12 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "09/07/2024 09:12:39"
              },
              {
                "tranID": "S88569222",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-07-09T11:35:00",
                "partTranType": "C",
                "tranAmount": 36000,
                "tranParticular": "NIP FRM OYEWUMI OLAOLU AMOS-Margin for 2CAC",
                "referenceID": "NIP/240709085133000195706698",
                "valueDate": "2024-07-09T11:35:00",
                "reference": "001/4843257020/001086632777",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -820529.63,
                "withdrawls": 0,
                "deposit": 36000,
                "tranDateString": "07/09/2024 11:35 AM",
                "valueDateString": "07/09/2024 11:35 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "09/07/2024 11:35:08"
              },
              {
                "tranID": "S88787876",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-09T10:02:00",
                "partTranType": "D",
                "tranAmount": 36000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-07-09T10:02:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -856529.63,
                "withdrawls": 36000,
                "deposit": 0,
                "tranDateString": "07/09/2024 10:02 AM",
                "valueDateString": "07/09/2024 10:02 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "09/07/2024 10:02:16"
              },
              {
                "tranID": "S99490641",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-11T15:17:00",
                "partTranType": "D",
                "tranAmount": 200000,
                "tranParticular": "TRF to ABIODUN OLUFEMI OLAIDE/App:gboyega To FCMB",
                "referenceID": "PPL",
                "valueDate": "2024-07-11T15:17:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1056529.63,
                "withdrawls": 200000,
                "deposit": 0,
                "tranDateString": "07/11/2024 03:17 PM",
                "valueDateString": "07/11/2024 03:17 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "11/07/2024 15:17:36"
              },
              {
                "tranID": "    S1937",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-11T17:07:00",
                "partTranType": "D",
                "tranAmount": 3300,
                "tranParticular": "AppUber To Opay Digital Services Limited AFOLA",
                "referenceID": "NIP/240711170521003234355536",
                "valueDate": "2024-07-11T17:07:00",
                "reference": "082/4843257020/001093654533",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1059829.63,
                "withdrawls": 3300,
                "deposit": 0,
                "tranDateString": "07/11/2024 05:07 PM",
                "valueDateString": "07/11/2024 05:07 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "11/07/2024 17:07:42"
              },
              {
                "tranID": "    S1979",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-11T17:07:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-AppUber To Opay Digital Service",
                "referenceID": "NIP/240711170521003234355536",
                "valueDate": "2024-07-11T17:07:00",
                "reference": "082/4843257020/001093654533",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1059840.38,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "07/11/2024 05:07 PM",
                "valueDateString": "07/11/2024 05:07 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "11/07/2024 17:07:42"
              },
              {
                "tranID": "  S423758",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-11T18:34:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": "AppUber To Opay Digital Services Limited ANTHO",
                "referenceID": "NIP/240711183213003234584833",
                "valueDate": "2024-07-11T18:34:00",
                "reference": "082/4843257020/001093923273",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1064840.38,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "07/11/2024 06:34 PM",
                "valueDateString": "07/11/2024 06:34 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "11/07/2024 18:34:32"
              },
              {
                "tranID": "  S423775",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-11T18:34:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-AppUber To Opay Digital Service",
                "referenceID": "NIP/240711183213003234584833",
                "valueDate": "2024-07-11T18:34:00",
                "reference": "082/4843257020/001093923273",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1064851.13,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "07/11/2024 06:34 PM",
                "valueDateString": "07/11/2024 06:34 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "11/07/2024 18:34:32"
              },
              {
                "tranID": "  S687910",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-11T19:31:00",
                "partTranType": "D",
                "tranAmount": 50000,
                "tranParticular": "TRF to WAVELENGTH MULTI-RESOURCES NG/App: To FCMB",
                "referenceID": "PPL",
                "valueDate": "2024-07-11T19:31:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1114851.13,
                "withdrawls": 50000,
                "deposit": 0,
                "tranDateString": "07/11/2024 07:31 PM",
                "valueDateString": "07/11/2024 07:31 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "11/07/2024 19:31:25"
              },
              {
                "tranID": " S2188501",
                "accountName": "OBANUBI OYINDAMOLA RUTH",
                "customerCode": null,
                "tranDate": "2024-07-12T09:50:00",
                "partTranType": "C",
                "tranAmount": 45000,
                "tranParticular": "TRF From OBANUBI OYINDAMOLA RUTH/App: To FCMB OYEW",
                "referenceID": "PPL",
                "valueDate": "2024-07-12T09:50:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1069851.13,
                "withdrawls": 0,
                "deposit": 45000,
                "tranDateString": "07/12/2024 09:50 AM",
                "valueDateString": "07/12/2024 09:50 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "12/07/2024 09:50:28"
              },
              {
                "tranID": " S3154826",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-12T13:15:00",
                "partTranType": "D",
                "tranAmount": 10000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-07-12T13:15:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1079851.13,
                "withdrawls": 10000,
                "deposit": 0,
                "tranDateString": "07/12/2024 01:15 PM",
                "valueDateString": "07/12/2024 01:15 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "12/07/2024 13:15:37"
              },
              {
                "tranID": " S3176499",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-12T13:19:00",
                "partTranType": "D",
                "tranAmount": 50000,
                "tranParticular": "TRF to 3RD AKUMBA AGE GRADE LAGOS BRANCH/App:initi",
                "referenceID": "PPL",
                "valueDate": "2024-07-12T13:19:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1129851.13,
                "withdrawls": 50000,
                "deposit": 0,
                "tranDateString": "07/12/2024 01:19 PM",
                "valueDateString": "07/12/2024 01:19 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "12/07/2024 13:19:57"
              },
              {
                "tranID": " S3632683",
                "accountName": "BALOGUN MUYIDEEN SAKA",
                "customerCode": null,
                "tranDate": "2024-07-12T15:04:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY HOLD ON ACCOUNT ON 12-JUL-2024",
                "referenceID": " ",
                "valueDate": "2024-07-12T15:04:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1129901.13,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "07/12/2024 03:04 PM",
                "valueDateString": "07/12/2024 03:04 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  59",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "12/07/2024 15:04:15"
              },
              {
                "tranID": " S4015102",
                "accountName": "ANIMASAUN OLAYINKA WALIU",
                "customerCode": null,
                "tranDate": "2024-07-12T16:21:00",
                "partTranType": "C",
                "tranAmount": 120000,
                "tranParticular": "TRF From ANIMASAUN OLAYINKA WALIU/App:balance To F",
                "referenceID": "PPL",
                "valueDate": "2024-07-12T16:21:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1009901.13,
                "withdrawls": 0,
                "deposit": 120000,
                "tranDateString": "07/12/2024 04:21 PM",
                "valueDateString": "07/12/2024 04:21 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "12/07/2024 16:21:10"
              },
              {
                "tranID": " S4712459",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-12T18:42:00",
                "partTranType": "D",
                "tranAmount": 120000,
                "tranParticular": "TRF to WAVELENGTH MULTI-RESOURCES NG/App: To FCMB",
                "referenceID": "PPL",
                "valueDate": "2024-07-12T18:42:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1129901.13,
                "withdrawls": 120000,
                "deposit": 0,
                "tranDateString": "07/12/2024 06:42 PM",
                "valueDateString": "07/12/2024 06:42 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "12/07/2024 18:42:31"
              },
              {
                "tranID": " S4736973",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-12T18:47:00",
                "partTranType": "D",
                "tranAmount": 18000,
                "tranParticular": "TRF to AMODU MUTIU ADEWALE/App:part pay for sow",
                "referenceID": "PPL",
                "valueDate": "2024-07-12T18:47:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1147901.13,
                "withdrawls": 18000,
                "deposit": 0,
                "tranDateString": "07/12/2024 06:47 PM",
                "valueDateString": "07/12/2024 06:47 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "12/07/2024 18:47:06"
              },
              {
                "tranID": " S5527972",
                "accountName": "JULSIX INTEGRATED CONCEPT",
                "customerCode": null,
                "tranDate": "2024-07-14T00:00:00",
                "partTranType": "C",
                "tranAmount": 430250,
                "tranParticular": "web: TRF Frm JULSIX INTEGRATED CONCEPT/web:JUNE SA",
                "referenceID": "RIB",
                "valueDate": "2024-07-12T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -717651.13,
                "withdrawls": 0,
                "deposit": 430250,
                "tranDateString": "07/14/2024 ",
                "valueDateString": "07/12/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "13/07/2024 10:02:05"
              },
              {
                "tranID": "S13281709",
                "accountName": "WAVELENGTH MULTI-RESOURCES NG",
                "customerCode": null,
                "tranDate": "2024-07-15T00:00:00",
                "partTranType": "C",
                "tranAmount": 200000,
                "tranParticular": "TRF From WAVELENGTH MULTI-RESOURCES NG/App To FCMB",
                "referenceID": "PPL",
                "valueDate": "2024-07-14T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -517651.13,
                "withdrawls": 0,
                "deposit": 200000,
                "tranDateString": "07/15/2024 ",
                "valueDateString": "07/14/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "15/07/2024 09:59:49"
              },
              {
                "tranID": "S14126298",
                "accountName": "JOHN GANA KOLO",
                "customerCode": null,
                "tranDate": "2024-07-15T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY HOLD ON ACCOUNT ON 15-JUL-2024",
                "referenceID": " ",
                "valueDate": "2024-07-15T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -517701.13,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "07/15/2024 ",
                "valueDateString": "07/15/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  91",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "15/07/2024 09:59:49"
              },
              {
                "tranID": "S18075600",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-16T00:00:00",
                "partTranType": "D",
                "tranAmount": 10000,
                "tranParticular": "TRF to ADETORO ADENIRAN SUNDAY O./App:gboyega To F",
                "referenceID": "PPL",
                "valueDate": "2024-07-16T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -527701.13,
                "withdrawls": 10000,
                "deposit": 0,
                "tranDateString": "07/16/2024 ",
                "valueDateString": "07/16/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/07/2024 09:24:53"
              },
              {
                "tranID": "S18963331",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-16T00:00:00",
                "partTranType": "D",
                "tranAmount": 200,
                "tranParticular": "QR/Q22465264776/Airtime/MTN/2347032819698",
                "referenceID": "USSD",
                "valueDate": "2024-07-16T00:00:00",
                "reference": "USSD/Q22465264776",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -527901.13,
                "withdrawls": 200,
                "deposit": 0,
                "tranDateString": "07/16/2024 ",
                "valueDateString": "07/16/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/07/2024 11:33:44"
              },
              {
                "tranID": "S19031566",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-07-16T00:00:00",
                "partTranType": "C",
                "tranAmount": 2428000,
                "tranParticular": "NIP FRM OLAOLU AMOS OYEWUMI-TRF FRM OLAOLU AMO",
                "referenceID": "NIP/240716114341445843332712",
                "valueDate": "2024-07-16T00:00:00",
                "reference": "001/4843257020/001105352749",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": 1900098.87,
                "withdrawls": 0,
                "deposit": 2428000,
                "tranDateString": "07/16/2024 ",
                "valueDateString": "07/16/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/07/2024 11:45:48"
              },
              {
                "tranID": " 01261612",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-16T00:00:00",
                "partTranType": "D",
                "tranAmount": 2480000,
                "tranParticular": "TRANSFER IFO:OCTAVE MUSIC CREATIVE NG",
                "referenceID": "SFR2SFR2",
                "valueDate": "2024-07-16T00:00:00",
                "reference": "082|TRF|BAN20404",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -579901.13,
                "withdrawls": 2480000,
                "deposit": 0,
                "tranDateString": "07/16/2024 ",
                "valueDateString": "07/16/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/07/2024 12:35:35"
              },
              {
                "tranID": " 01261716",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-16T00:00:00",
                "partTranType": "D",
                "tranAmount": 380250,
                "tranParticular": "TRANSFER IFO:JULSIX INTEGRATED CONCEPT",
                "referenceID": "SFR2SFR2",
                "valueDate": "2024-07-16T00:00:00",
                "reference": "082|TRF|BAN20404",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -960151.13,
                "withdrawls": 380250,
                "deposit": 0,
                "tranDateString": "07/16/2024 ",
                "valueDateString": "07/16/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/07/2024 12:35:50"
              },
              {
                "tranID": "S21003444",
                "accountName": "ANIMASAUN OLAYINKA WALIU",
                "customerCode": null,
                "tranDate": "2024-07-16T00:00:00",
                "partTranType": "C",
                "tranAmount": 4000,
                "tranParticular": "TRF From ANIMASAUN OLAYINKA WALIU/App:olayinka To",
                "referenceID": "PPL",
                "valueDate": "2024-07-16T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -956151.13,
                "withdrawls": 0,
                "deposit": 4000,
                "tranDateString": "07/16/2024 ",
                "valueDateString": "07/16/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/07/2024 18:56:38"
              },
              {
                "tranID": "S21011804",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-16T00:00:00",
                "partTranType": "D",
                "tranAmount": 4000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-07-16T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -960151.13,
                "withdrawls": 4000,
                "deposit": 0,
                "tranDateString": "07/16/2024 ",
                "valueDateString": "07/16/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/07/2024 18:59:27"
              },
              {
                "tranID": "S27578274",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-18T00:00:00",
                "partTranType": "D",
                "tranAmount": 3000,
                "tranParticular": "Appaccessing To Opay Digital Services Limited",
                "referenceID": "NIP/240718121654003249877466",
                "valueDate": "2024-07-18T00:00:00",
                "reference": "082/4843257020/001110773049",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -963151.13,
                "withdrawls": 3000,
                "deposit": 0,
                "tranDateString": "07/18/2024 ",
                "valueDateString": "07/18/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "18/07/2024 12:18:27"
              },
              {
                "tranID": "S27578299",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-18T00:00:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-Appaccessing To Opay Digital Se",
                "referenceID": "NIP/240718121654003249877466",
                "valueDate": "2024-07-18T00:00:00",
                "reference": "082/4843257020/001110773049",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -963161.88,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "07/18/2024 ",
                "valueDateString": "07/18/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "18/07/2024 12:18:27"
              },
              {
                "tranID": "S30185356",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-19T00:00:00",
                "partTranType": "D",
                "tranAmount": 2000,
                "tranParticular": "Appfood To Opay Digital Services Limited Franc",
                "referenceID": "NIP/240718224009003251373399",
                "valueDate": "2024-07-18T00:00:00",
                "reference": "082/4843257020/001112416971",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -965161.88,
                "withdrawls": 2000,
                "deposit": 0,
                "tranDateString": "07/19/2024 ",
                "valueDateString": "07/18/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "19/07/2024 08:57:04"
              },
              {
                "tranID": "S30185359",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-19T00:00:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-Appfood To Opay Digital Service",
                "referenceID": "NIP/240718224009003251373399",
                "valueDate": "2024-07-19T00:00:00",
                "reference": "082/4843257020/001112416971",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -965172.63,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "07/19/2024 ",
                "valueDateString": "07/19/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "19/07/2024 08:57:04"
              },
              {
                "tranID": "S31343187",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-19T00:00:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": " App: To United Bank for Africa FELICIA  A OYE",
                "referenceID": "FTN000223014415/4843257020",
                "valueDate": "2024-07-19T00:00:00",
                "reference": "ETRNZ|211632974918",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -970172.63,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "07/19/2024 ",
                "valueDateString": "07/19/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "19/07/2024 11:08:58"
              },
              {
                "tranID": "S31343209",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-19T00:00:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App: To United Bank for Africa",
                "referenceID": "FTN000223014415/4843257020",
                "valueDate": "2024-07-19T00:00:00",
                "reference": "ETRNZ|211632974918",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -970183.38,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "07/19/2024 ",
                "valueDateString": "07/19/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "19/07/2024 11:08:58"
              },
              {
                "tranID": "S32511993",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-19T00:00:00",
                "partTranType": "D",
                "tranAmount": 24000,
                "tranParticular": "Appshoe To ACCESS BANK PLC DIAMOND CALISTA  OD",
                "referenceID": "NIP/240719152934003252720219",
                "valueDate": "2024-07-19T00:00:00",
                "reference": "082/4843257020/001113855953",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -994183.38,
                "withdrawls": 24000,
                "deposit": 0,
                "tranDateString": "07/19/2024 ",
                "valueDateString": "07/19/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "19/07/2024 15:29:50"
              },
              {
                "tranID": "S32512103",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-19T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appshoe To ACCESS BANK PLC DIAM",
                "referenceID": "NIP/240719152934003252720219",
                "valueDate": "2024-07-19T00:00:00",
                "reference": "082/4843257020/001113855953",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -994210.26,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "07/19/2024 ",
                "valueDateString": "07/19/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "19/07/2024 15:29:50"
              },
              {
                "tranID": "S46585450",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-23T00:00:00",
                "partTranType": "D",
                "tranAmount": 200000,
                "tranParticular": "TRF to OCTAVE MUSIC CREATIVE NG/App:fund To FCMB O",
                "referenceID": "PPL",
                "valueDate": "2024-07-23T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1194210.26,
                "withdrawls": 200000,
                "deposit": 0,
                "tranDateString": "07/23/2024 ",
                "valueDateString": "07/23/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "23/07/2024 10:20:57"
              },
              {
                "tranID": "S46596340",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-23T00:00:00",
                "partTranType": "D",
                "tranAmount": 100000,
                "tranParticular": "TRF to OCTAVE MUSIC CREATIVE NG/App:fund To FCMB O",
                "referenceID": "PPL",
                "valueDate": "2024-07-23T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1294210.26,
                "withdrawls": 100000,
                "deposit": 0,
                "tranDateString": "07/23/2024 ",
                "valueDateString": "07/23/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "23/07/2024 10:20:57"
              },
              {
                "tranID": "S46609052",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-23T00:00:00",
                "partTranType": "D",
                "tranAmount": 100000,
                "tranParticular": "TRF to OCTAVE MUSIC CREATIVE NG/App:fund To FCMB O",
                "referenceID": "PPL",
                "valueDate": "2024-07-23T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1394210.26,
                "withdrawls": 100000,
                "deposit": 0,
                "tranDateString": "07/23/2024 ",
                "valueDateString": "07/23/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "23/07/2024 10:20:57"
              },
              {
                "tranID": "S46618355",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-23T00:00:00",
                "partTranType": "D",
                "tranAmount": 100000,
                "tranParticular": "TRF to OCTAVE MUSIC CREATIVE NG/App: To FCMB OCTAV",
                "referenceID": "PPL",
                "valueDate": "2024-07-23T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1494210.26,
                "withdrawls": 100000,
                "deposit": 0,
                "tranDateString": "07/23/2024 ",
                "valueDateString": "07/23/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "23/07/2024 10:24:53"
              },
              {
                "tranID": "S48065721",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-07-23T00:00:00",
                "partTranType": "C",
                "tranAmount": 25000,
                "tranParticular": "NIP FRM SARAH OMOLOLA AJAI-Transfer from SARAH",
                "referenceID": "NIP/240723143048117101969341",
                "valueDate": "2024-07-23T00:00:00",
                "reference": "001/4843257020/001123432951",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1469210.26,
                "withdrawls": 0,
                "deposit": 25000,
                "tranDateString": "07/23/2024 ",
                "valueDateString": "07/23/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "23/07/2024 15:34:34"
              },
              {
                "tranID": "S59697722",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-26T00:00:00",
                "partTranType": "D",
                "tranAmount": 25000,
                "tranParticular": "TRF to SARAJ TASTE/App:initial balance",
                "referenceID": "PPL",
                "valueDate": "2024-07-26T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1494210.26,
                "withdrawls": 25000,
                "deposit": 0,
                "tranDateString": "07/26/2024 ",
                "valueDateString": "07/26/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "26/07/2024 10:48:01"
              },
              {
                "tranID": "S67722566",
                "accountName": "ADESIYAN OLUWASEYI TAIWO",
                "customerCode": null,
                "tranDate": "2024-07-28T00:00:00",
                "partTranType": "D",
                "tranAmount": 196,
                "tranParticular": "SMS ALERT CHARGES FROM 25TH JUN TO 26TH JUL 2024",
                "referenceID": " ",
                "valueDate": "2024-07-28T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1494406.26,
                "withdrawls": 196,
                "deposit": 0,
                "tranDateString": "07/28/2024 ",
                "valueDateString": "07/28/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  96",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "28/07/2024 09:24:45"
              },
              {
                "tranID": "S71222088",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-07-29T00:00:00",
                "partTranType": "D",
                "tranAmount": 52,
                "tranParticular": "AUTHENTICATION CODE CHARGE Outstanding JUN 2024",
                "referenceID": " ",
                "valueDate": "2024-07-29T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1494458.26,
                "withdrawls": 52,
                "deposit": 0,
                "tranDateString": "07/29/2024 ",
                "valueDateString": "07/29/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "29/07/2024 09:02:10"
              },
              {
                "tranID": "S15056695",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-08-07T19:16:00",
                "partTranType": "D",
                "tranAmount": 2000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-08-07T19:16:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1496458.26,
                "withdrawls": 2000,
                "deposit": 0,
                "tranDateString": "08/07/2024 07:16 PM",
                "valueDateString": "08/07/2024 07:16 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "07/08/2024 19:16:50"
              },
              {
                "tranID": "S25605698",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-08-11T10:06:00",
                "partTranType": "D",
                "tranAmount": 300,
                "tranParticular": "QR/Q22516612167/Airtime/MTN/2347032819698",
                "referenceID": "USSD",
                "valueDate": "2024-08-10T10:06:00",
                "reference": "USSD/Q22516612167",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1496758.26,
                "withdrawls": 300,
                "deposit": 0,
                "tranDateString": "08/11/2024 10:06 AM",
                "valueDateString": "08/10/2024 10:06 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "10/08/2024 10:06:50"
              },
              {
                "tranID": "S32623898",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-08-12T13:50:00",
                "partTranType": "D",
                "tranAmount": 100,
                "tranParticular": "QR/Q22520494069/Airtime/MTN/2347032819698",
                "referenceID": "USSD",
                "valueDate": "2024-08-11T13:50:00",
                "reference": "USSD/Q22520494069",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1496858.26,
                "withdrawls": 100,
                "deposit": 0,
                "tranDateString": "08/12/2024 01:50 PM",
                "valueDateString": "08/11/2024 01:50 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "12/08/2024 13:50:53"
              },
              {
                "tranID": "S37612829",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-08-13T00:00:00",
                "partTranType": "D",
                "tranAmount": 200,
                "tranParticular": "FCMBMobile:AirtimeTopup0703281969824081308104",
                "referenceID": "MTN/2408130810456862434843257/MOBILE",
                "valueDate": "2024-08-13T00:00:00",
                "reference": "2408130810456862434843257",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1497058.26,
                "withdrawls": 200,
                "deposit": 0,
                "tranDateString": "08/13/2024 ",
                "valueDateString": "08/13/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "13/08/2024 22:28:06"
              },
              {
                "tranID": "S51119586",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-08-16T00:00:00",
                "partTranType": "D",
                "tranAmount": 200,
                "tranParticular": "QR/Q22528900026/Airtime/MTN/2347032819698",
                "referenceID": "USSD",
                "valueDate": "2024-08-16T00:00:00",
                "reference": "USSD/Q22528900026",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1497258.26,
                "withdrawls": 200,
                "deposit": 0,
                "tranDateString": "08/16/2024 ",
                "valueDateString": "08/16/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/08/2024 10:09:07"
              },
              {
                "tranID": "S96967397",
                "accountName": "MEGBATONE JOSEPH ALABA",
                "customerCode": null,
                "tranDate": "2024-08-27T00:00:00",
                "partTranType": "D",
                "tranAmount": 20,
                "tranParticular": "SMS ALERT CHARGES FROM 26TH JUL TO 24TH AUG 2024",
                "referenceID": " ",
                "valueDate": "2024-08-27T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1497278.26,
                "withdrawls": 20,
                "deposit": 0,
                "tranDateString": "08/27/2024 ",
                "valueDateString": "08/27/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  99",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "27/08/2024 10:16:57"
              },
              {
                "tranID": "S68667557",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-09-11T12:11:00",
                "partTranType": "C",
                "tranAmount": 50000,
                "tranParticular": "NIP FRM KOLLY RALIAT ABIOLA-MOBUTOOYEWUMI OLAO",
                "referenceID": "NIP/240911120436661289482436",
                "valueDate": "2024-09-11T12:11:00",
                "reference": "001/4843257020/001258035547",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1447278.26,
                "withdrawls": 0,
                "deposit": 50000,
                "tranDateString": "09/11/2024 12:11 PM",
                "valueDateString": "09/11/2024 12:11 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "11/09/2024 12:11:19"
              },
              {
                "tranID": "S71442883",
                "accountName": "LAWAL FAIDAT ABIMBOLA",
                "customerCode": null,
                "tranDate": "2024-09-12T08:51:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 11-09-2024",
                "referenceID": " ",
                "valueDate": "2024-09-12T08:51:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1447328.26,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "09/12/2024 08:51 AM",
                "valueDateString": "09/12/2024 08:51 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  63",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "12/09/2024 08:51:02"
              },
              {
                "tranID": "S72086626",
                "accountName": "ANIMASAUN OLAYINKA WALIU",
                "customerCode": null,
                "tranDate": "2024-09-12T09:24:00",
                "partTranType": "C",
                "tranAmount": 5100,
                "tranParticular": "TRF From ANIMASAUN OLAYINKA WALIU/App:olayinka To",
                "referenceID": "PPL",
                "valueDate": "2024-09-12T09:24:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1442228.26,
                "withdrawls": 0,
                "deposit": 5100,
                "tranDateString": "09/12/2024 09:24 AM",
                "valueDateString": "09/12/2024 09:24 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "12/09/2024 09:24:04"
              },
              {
                "tranID": "S73211520",
                "accountName": "SERIKI OLAPEJU ASSISAT",
                "customerCode": null,
                "tranDate": "2024-09-12T19:48:00",
                "partTranType": "C",
                "tranAmount": 10000,
                "tranParticular": "TRF From SERIKI OLAPEJU ASSISAT/App:Transfer from",
                "referenceID": "PPL",
                "valueDate": "2024-09-12T19:48:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1432228.26,
                "withdrawls": 0,
                "deposit": 10000,
                "tranDateString": "09/12/2024 07:48 PM",
                "valueDateString": "09/12/2024 07:48 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "12/09/2024 19:48:01"
              },
              {
                "tranID": "S73418773",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-09-12T13:41:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": "App To GTBank Plc OYEWUMI OLAOLU AMOS",
                "referenceID": "NIP/240912134016003380441372",
                "valueDate": "2024-09-12T13:41:00",
                "reference": "082/4843257020/001261312667",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1437228.26,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "09/12/2024 01:41 PM",
                "valueDateString": "09/12/2024 01:41 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "12/09/2024 13:41:44"
              },
              {
                "tranID": "S73418784",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-09-12T13:41:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App To GTBank Plc OYEWUMI OLAOL",
                "referenceID": "NIP/240912134016003380441372",
                "valueDate": "2024-09-12T13:41:00",
                "reference": "082/4843257020/001261312667",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1437239.01,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "09/12/2024 01:41 PM",
                "valueDateString": "09/12/2024 01:41 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "12/09/2024 13:41:44"
              },
              {
                "tranID": "S73421711",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-09-12T13:41:00",
                "partTranType": "D",
                "tranAmount": 10000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App:fund To FCMB OYE",
                "referenceID": "PPL",
                "valueDate": "2024-09-12T13:41:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1447239.01,
                "withdrawls": 10000,
                "deposit": 0,
                "tranDateString": "09/12/2024 01:41 PM",
                "valueDateString": "09/12/2024 01:41 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "12/09/2024 13:41:44"
              },
              {
                "tranID": "S74529198",
                "accountName": "SOMOYE OLUBUNMI RONKE",
                "customerCode": null,
                "tranDate": "2024-09-12T19:37:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY HOLD ON ACCOUNT ON 12-SEP-2024",
                "referenceID": " ",
                "valueDate": "2024-09-12T19:37:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1447289.01,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "09/12/2024 07:37 PM",
                "valueDateString": "09/12/2024 07:37 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   9",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "12/09/2024 19:37:49"
              },
              {
                "tranID": "S96595077",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-09-18T00:00:00",
                "partTranType": "D",
                "tranAmount": 50000,
                "tranParticular": "TRF to RAPPY ROLLY ENTERPRISES/App:initial balance",
                "referenceID": "PPL",
                "valueDate": "2024-09-18T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1497289.01,
                "withdrawls": 50000,
                "deposit": 0,
                "tranDateString": "09/18/2024 ",
                "valueDateString": "09/18/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "18/09/2024 11:20:39"
              },
              {
                "tranID": "S34440272",
                "accountName": "ISIAKA AZEEZ TAYO",
                "customerCode": null,
                "tranDate": "2024-09-27T00:00:00",
                "partTranType": "D",
                "tranAmount": 24,
                "tranParticular": "SMS ALERT CHARGES FROM 25TH AUG TO 25TH SEP 2024",
                "referenceID": " ",
                "valueDate": "2024-09-27T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1497313.01,
                "withdrawls": 24,
                "deposit": 0,
                "tranDateString": "09/27/2024 ",
                "valueDateString": "09/27/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  11",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "27/09/2024 10:09:01"
              },
              {
                "tranID": "S34996198",
                "accountName": "HASSAN DAMILOLA MUINAT",
                "customerCode": null,
                "tranDate": "2024-09-27T00:00:00",
                "partTranType": "C",
                "tranAmount": 55000,
                "tranParticular": "TRF From HASSAN DAMILOLA MUINAT/App: To FCMB OYEWU",
                "referenceID": "PPL",
                "valueDate": "2024-09-27T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1442313.01,
                "withdrawls": 0,
                "deposit": 55000,
                "tranDateString": "09/27/2024 ",
                "valueDateString": "09/27/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "27/09/2024 16:53:35"
              },
              {
                "tranID": "S38862399",
                "accountName": "IKUDEHINBU OREOLUWA VICTORIA",
                "customerCode": null,
                "tranDate": "2024-09-29T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 27-09-2024",
                "referenceID": " ",
                "valueDate": "2024-09-29T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1442363.01,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "09/29/2024 ",
                "valueDateString": "09/29/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  98",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "28/09/2024 11:25:25"
              },
              {
                "tranID": "S54078499",
                "accountName": "INTEREST  PAYABLE SAVINGS ACCOUNTS",
                "customerCode": null,
                "tranDate": "2024-09-30T06:33:00",
                "partTranType": "D",
                "tranAmount": 12.61,
                "tranParticular": "Withholding Tax for -4843257020",
                "referenceID": " ",
                "valueDate": "2024-09-30T06:33:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1442375.62,
                "withdrawls": 12.61,
                "deposit": 0,
                "tranDateString": "09/30/2024 06:33 AM",
                "valueDateString": "09/30/2024 06:33 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": " 341",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "IP",
                "tranTime": "01/10/2024 06:33:04"
              },
              {
                "tranID": "S54078499",
                "accountName": "INTEREST  PAYABLE SAVINGS ACCOUNTS",
                "customerCode": null,
                "tranDate": "2024-09-30T06:33:00",
                "partTranType": "C",
                "tranAmount": 126.05,
                "tranParticular": "Interest Paid ON balance01-09-2024 to 30-09-2024",
                "referenceID": " ",
                "valueDate": "2024-09-30T06:33:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1442249.57,
                "withdrawls": 0,
                "deposit": 126.05,
                "tranDateString": "09/30/2024 06:33 AM",
                "valueDateString": "09/30/2024 06:33 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": " 339",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "IP",
                "tranTime": "01/10/2024 06:33:04"
              },
              {
                "tranID": "S55673439",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-02T11:31:00",
                "partTranType": "D",
                "tranAmount": 100,
                "tranParticular": "QR/Q22631360155/Airtime/MTN/2347032819698",
                "referenceID": "USSD",
                "valueDate": "2024-10-01T11:31:00",
                "reference": "USSD/Q22631360155",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1442349.57,
                "withdrawls": 100,
                "deposit": 0,
                "tranDateString": "10/02/2024 11:31 AM",
                "valueDateString": "10/01/2024 11:31 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "01/10/2024 11:31:15"
              },
              {
                "tranID": "S56147854",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-02T13:06:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-10-01T13:06:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1447349.57,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "10/02/2024 01:06 PM",
                "valueDateString": "10/01/2024 01:06 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "01/10/2024 13:06:25"
              },
              {
                "tranID": "S60252699",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-10-02T10:57:00",
                "partTranType": "C",
                "tranAmount": 48000,
                "tranParticular": "NIP FRM OYEWUMI OLAOLU AMOS-Inflow from OYEWUM",
                "referenceID": "NIP/241002103630000082219025",
                "valueDate": "2024-10-02T10:57:00",
                "reference": "001/4843257020/001319852727",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1399349.57,
                "withdrawls": 0,
                "deposit": 48000,
                "tranDateString": "10/02/2024 10:57 AM",
                "valueDateString": "10/02/2024 10:57 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "02/10/2024 10:57:17"
              },
              {
                "tranID": "S60596077",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-02T11:47:00",
                "partTranType": "D",
                "tranAmount": 2000,
                "tranParticular": "FCMBMobile:AirtimeTopup0813951264424100210404",
                "referenceID": "MTN/2410021040467337044843257/MOBILE",
                "valueDate": "2024-10-02T11:47:00",
                "reference": "2410021040467337044843257",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1401349.57,
                "withdrawls": 2000,
                "deposit": 0,
                "tranDateString": "10/02/2024 11:47 AM",
                "valueDateString": "10/02/2024 11:47 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "02/10/2024 11:47:32"
              },
              {
                "tranID": "S61292846",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-02T13:48:00",
                "partTranType": "D",
                "tranAmount": 10215,
                "tranParticular": "App To GTBank Plc OYEWUMI OLAOLU AMOS",
                "referenceID": "NIP/241002134751003428067174",
                "valueDate": "2024-10-02T13:48:00",
                "reference": "082/4843257020/001320584485",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1411564.57,
                "withdrawls": 10215,
                "deposit": 0,
                "tranDateString": "10/02/2024 01:48 PM",
                "valueDateString": "10/02/2024 01:48 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "02/10/2024 13:48:28"
              },
              {
                "tranID": "S61292970",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-02T13:48:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-App To GTBank Plc OYEWUMI OLAOL",
                "referenceID": "NIP/241002134751003428067174",
                "valueDate": "2024-10-02T13:48:00",
                "reference": "082/4843257020/001320584485",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1411591.45,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "10/02/2024 01:48 PM",
                "valueDateString": "10/02/2024 01:48 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "02/10/2024 13:48:28"
              },
              {
                "tranID": "S62274721",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-02T16:48:00",
                "partTranType": "D",
                "tranAmount": 65000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App:fund To First Ci",
                "referenceID": "PPL",
                "valueDate": "2024-10-02T16:48:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1476591.45,
                "withdrawls": 65000,
                "deposit": 0,
                "tranDateString": "10/02/2024 04:48 PM",
                "valueDateString": "10/02/2024 04:48 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "02/10/2024 16:48:16"
              },
              {
                "tranID": "S63925906",
                "accountName": "OKEKE UJU SHARON",
                "customerCode": null,
                "tranDate": "2024-10-03T08:43:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 02-10-2024",
                "referenceID": " ",
                "valueDate": "2024-10-03T08:43:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1476641.45,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "10/03/2024 08:43 AM",
                "valueDateString": "10/03/2024 08:43 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  33",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "03/10/2024 08:43:38"
              },
              {
                "tranID": " 01300643",
                "accountName": "TELLER 1",
                "customerCode": null,
                "tranDate": "2024-10-04T13:29:00",
                "partTranType": "C",
                "tranAmount": 30000,
                "tranParticular": "CDP/ OYEWUMI OLAOLU AMOS/024872106",
                "referenceID": " ",
                "valueDate": "2024-10-04T13:29:00",
                "reference": null,
                "tranShortCodeDesc": "CASH LODGEMENT",
                "runningBalance": -1446641.45,
                "withdrawls": 0,
                "deposit": 30000,
                "tranDateString": "10/04/2024 01:29 PM",
                "valueDateString": "10/04/2024 01:29 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "CPT",
                "tranType": "C",
                "tranSubType": "NR",
                "tranTime": "04/10/2024 13:29:27"
              },
              {
                "tranID": "S71194936",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-04T13:56:00",
                "partTranType": "D",
                "tranAmount": 30000,
                "tranParticular": "App To GTBank Plc OYEWUMI OLAOLU AMOS",
                "referenceID": "NIP/241004135510003433829685",
                "valueDate": "2024-10-04T13:56:00",
                "reference": "082/4843257020/001327660266",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1476641.45,
                "withdrawls": 30000,
                "deposit": 0,
                "tranDateString": "10/04/2024 01:56 PM",
                "valueDateString": "10/04/2024 01:56 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "04/10/2024 13:56:08"
              },
              {
                "tranID": "S71194954",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-04T13:56:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-App To GTBank Plc OYEWUMI OLAOL",
                "referenceID": "NIP/241004135510003433829685",
                "valueDate": "2024-10-04T13:56:00",
                "reference": "082/4843257020/001327660266",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1476668.33,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "10/04/2024 01:56 PM",
                "valueDateString": "10/04/2024 01:56 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "04/10/2024 13:56:08"
              },
              {
                "tranID": "S92122966",
                "accountName": "ANIMASAUN OLAYINKA WALIU",
                "customerCode": null,
                "tranDate": "2024-10-09T09:14:00",
                "partTranType": "C",
                "tranAmount": 2000,
                "tranParticular": "TRF From ANIMASAUN OLAYINKA WALIU/App:olayinka To",
                "referenceID": "PPL",
                "valueDate": "2024-10-09T09:14:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1474668.33,
                "withdrawls": 0,
                "deposit": 2000,
                "tranDateString": "10/09/2024 09:14 AM",
                "valueDateString": "10/09/2024 09:14 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "09/10/2024 09:14:00"
              },
              {
                "tranID": "S92258002",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-09T09:14:00",
                "partTranType": "D",
                "tranAmount": 2000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-10-09T09:14:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1476668.33,
                "withdrawls": 2000,
                "deposit": 0,
                "tranDateString": "10/09/2024 09:14 AM",
                "valueDateString": "10/09/2024 09:14 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "09/10/2024 09:14:00"
              },
              {
                "tranID": "S14826615",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-14T00:00:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": "TRF to OGBOZOR GLADYS ISIOMA/App:fund To FCMB OGB",
                "referenceID": "PPL",
                "valueDate": "2024-10-14T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1481668.33,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "10/14/2024 ",
                "valueDateString": "10/14/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "14/10/2024 13:03:56"
              },
              {
                "tranID": "S15542357",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-14T00:00:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-10-14T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1486668.33,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "10/14/2024 ",
                "valueDateString": "10/14/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "14/10/2024 15:26:42"
              },
              {
                "tranID": "S18271177",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-15T00:00:00",
                "partTranType": "D",
                "tranAmount": 500,
                "tranParticular": "TRF to OBANUBI OYINDAMOLA RUTH/App: To FCMB OBANUB",
                "referenceID": "PPL",
                "valueDate": "2024-10-15T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1487168.33,
                "withdrawls": 500,
                "deposit": 0,
                "tranDateString": "10/15/2024 ",
                "valueDateString": "10/15/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "15/10/2024 09:17:14"
              },
              {
                "tranID": "S20846788",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-15T00:00:00",
                "partTranType": "D",
                "tranAmount": 100,
                "tranParticular": "QR/Q22663416192/Airtime/MTN/2347032819698",
                "referenceID": "USSD",
                "valueDate": "2024-10-15T00:00:00",
                "reference": "USSD/Q22663416192",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1487268.33,
                "withdrawls": 100,
                "deposit": 0,
                "tranDateString": "10/15/2024 ",
                "valueDateString": "10/15/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "15/10/2024 17:23:52"
              },
              {
                "tranID": "S21580917",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-15T00:00:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": "FCMBMobile:AirtimeTopup0805653987724101518415",
                "referenceID": "GLO/2410151841560703254843257/MOBILE",
                "valueDate": "2024-10-15T00:00:00",
                "reference": "2410151841560703254843257",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1492268.33,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "10/15/2024 ",
                "valueDateString": "10/15/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "15/10/2024 19:42:49"
              },
              {
                "tranID": "S22888559",
                "accountName": "PURPLE STRIPES VENTURES",
                "customerCode": null,
                "tranDate": "2024-10-16T00:00:00",
                "partTranType": "C",
                "tranAmount": 40000,
                "tranParticular": "web: TRF Frm PURPLE STRIPES VENTURES/web:Laolu2Bal",
                "referenceID": "RIB",
                "valueDate": "2024-10-16T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1452268.33,
                "withdrawls": 0,
                "deposit": 40000,
                "tranDateString": "10/16/2024 ",
                "valueDateString": "10/16/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/10/2024 09:17:24"
              },
              {
                "tranID": "S23005260",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-16T00:00:00",
                "partTranType": "D",
                "tranAmount": 40000,
                "tranParticular": "Appgboyega To WemaALAT BALOGUN ADEWALE MUSLIUD",
                "referenceID": "NIP/241016085700003461329563",
                "valueDate": "2024-10-16T00:00:00",
                "reference": "082/4843257020/001363900175",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1492268.33,
                "withdrawls": 40000,
                "deposit": 0,
                "tranDateString": "10/16/2024 ",
                "valueDateString": "10/16/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/10/2024 09:17:24"
              },
              {
                "tranID": "S23005277",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-16T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appgboyega To WemaALAT BALOGUN",
                "referenceID": "NIP/241016085700003461329563",
                "valueDate": "2024-10-16T00:00:00",
                "reference": "082/4843257020/001363900175",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1492295.21,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "10/16/2024 ",
                "valueDateString": "10/16/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "16/10/2024 09:17:24"
              },
              {
                "tranID": "S23082243",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-10-16T00:00:00",
                "partTranType": "C",
                "tranAmount": 50000,
                "tranParticular": "NIP FRM MOTUNRAYO FATIMA SALAKO-Transfer from",
                "referenceID": "NIP/241016081319120700882011",
                "valueDate": "2024-10-16T00:00:00",
                "reference": "001/4843257020/001363958496",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1442295.21,
                "withdrawls": 0,
                "deposit": 50000,
                "tranDateString": "10/16/2024 ",
                "valueDateString": "10/16/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/10/2024 11:23:30"
              },
              {
                "tranID": "S23351450",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-16T00:00:00",
                "partTranType": "C",
                "tranAmount": 5000,
                "tranParticular": "TRF From OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-10-16T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1437295.21,
                "withdrawls": 0,
                "deposit": 5000,
                "tranDateString": "10/16/2024 ",
                "valueDateString": "10/16/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/10/2024 10:15:28"
              },
              {
                "tranID": "S25133555",
                "accountName": "WAVELENGTH MULTI-RESOURCES NG",
                "customerCode": null,
                "tranDate": "2024-10-16T00:00:00",
                "partTranType": "C",
                "tranAmount": 100,
                "tranParticular": "TRF From WAVELENGTH MULTI-RESOURCES NG/App To FCMB",
                "referenceID": "PPL",
                "valueDate": "2024-10-16T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1437195.21,
                "withdrawls": 0,
                "deposit": 100,
                "tranDateString": "10/16/2024 ",
                "valueDateString": "10/16/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/10/2024 16:26:16"
              },
              {
                "tranID": "S25636182",
                "accountName": "WAVELENGTH MULTI-RESOURCES NG",
                "customerCode": null,
                "tranDate": "2024-10-16T00:00:00",
                "partTranType": "C",
                "tranAmount": 14000,
                "tranParticular": "TRF From WAVELENGTH MULTI-RESOURCES NG/App To Firs",
                "referenceID": "PPL",
                "valueDate": "2024-10-16T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1423195.21,
                "withdrawls": 0,
                "deposit": 14000,
                "tranDateString": "10/16/2024 ",
                "valueDateString": "10/16/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/10/2024 18:04:48"
              },
              {
                "tranID": "S25643694",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-16T00:00:00",
                "partTranType": "D",
                "tranAmount": 14000,
                "tranParticular": "TRF to ANIMASAUN OLAYINKA WALIU/App: To FCMB ANIMA",
                "referenceID": "PPL",
                "valueDate": "2024-10-16T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1437195.21,
                "withdrawls": 14000,
                "deposit": 0,
                "tranDateString": "10/16/2024 ",
                "valueDateString": "10/16/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/10/2024 18:04:48"
              },
              {
                "tranID": "S26223145",
                "accountName": "HALIRU TANIMU",
                "customerCode": null,
                "tranDate": "2024-10-16T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY HOLD ON ACCOUNT ON 16-OCT-2024",
                "referenceID": " ",
                "valueDate": "2024-10-16T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1437245.21,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "10/16/2024 ",
                "valueDateString": "10/16/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  13",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "17/10/2024 00:27:38"
              },
              {
                "tranID": "S26779742",
                "accountName": "ADVENT LAGOS HOSTEL SPC LIMITED/LASU COLLECTIONS ACCOUNT",
                "customerCode": null,
                "tranDate": "2024-10-17T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 16-10-2024",
                "referenceID": " ",
                "valueDate": "2024-10-17T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1437295.21,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "10/17/2024 ",
                "valueDateString": "10/17/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  76",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "17/10/2024 09:13:30"
              },
              {
                "tranID": "S29087125",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-17T00:00:00",
                "partTranType": "D",
                "tranAmount": 45000,
                "tranParticular": "Appfund To VFD Microfinance Bank ISAAC VINCENT",
                "referenceID": "NIP/241017140746003464815091",
                "valueDate": "2024-10-17T00:00:00",
                "reference": "082/4843257020/001368711965",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1482295.21,
                "withdrawls": 45000,
                "deposit": 0,
                "tranDateString": "10/17/2024 ",
                "valueDateString": "10/17/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "17/10/2024 14:09:22"
              },
              {
                "tranID": "S29087143",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-17T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appfund To VFD Microfinance Ban",
                "referenceID": "NIP/241017140746003464815091",
                "valueDate": "2024-10-17T00:00:00",
                "reference": "082/4843257020/001368711965",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1482322.09,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "10/17/2024 ",
                "valueDateString": "10/17/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "17/10/2024 14:09:22"
              },
              {
                "tranID": "S29099710",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-17T00:00:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-10-17T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1487322.09,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "10/17/2024 ",
                "valueDateString": "10/17/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "17/10/2024 14:11:58"
              },
              {
                "tranID": " 01482794",
                "accountName": "TELLER 4",
                "customerCode": null,
                "tranDate": "2024-10-18T00:00:00",
                "partTranType": "C",
                "tranAmount": 50000,
                "tranParticular": "CSH DEPOSIT BY:OLAOLU OYEWUMI|YABA BRANCH",
                "referenceID": "SFR2SFR2|CSH Deposit By:OLAOLU OYEWUMI|YABA BRAN",
                "valueDate": "2024-10-18T00:00:00",
                "reference": "082|CSH|EFF10795|Y",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1437322.09,
                "withdrawls": 0,
                "deposit": 50000,
                "tranDateString": "10/18/2024 ",
                "valueDateString": "10/18/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "18/10/2024 14:24:23"
              },
              {
                "tranID": "S48682290",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-10-22T00:00:00",
                "partTranType": "C",
                "tranAmount": 20000,
                "tranParticular": "NIP FRM Wahab Adetunji Ajose-Transfer To OYEWU",
                "referenceID": "NIP/241021210934344402257162",
                "valueDate": "2024-10-21T00:00:00",
                "reference": "001/4843257020/001383691171",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1417322.09,
                "withdrawls": 0,
                "deposit": 20000,
                "tranDateString": "10/22/2024 ",
                "valueDateString": "10/21/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "22/10/2024 09:37:00"
              },
              {
                "tranID": "S49087460",
                "accountName": "PURPLE STRIPES VENTURES",
                "customerCode": null,
                "tranDate": "2024-10-22T00:00:00",
                "partTranType": "C",
                "tranAmount": 100000,
                "tranParticular": "web: TRF Frm PURPLE STRIPES VENTURES/web:Laolu",
                "referenceID": "RIB",
                "valueDate": "2024-10-22T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1317322.09,
                "withdrawls": 0,
                "deposit": 100000,
                "tranDateString": "10/22/2024 ",
                "valueDateString": "10/22/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "22/10/2024 09:37:00"
              },
              {
                "tranID": "S49837432",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-22T00:00:00",
                "partTranType": "D",
                "tranAmount": 100000,
                "tranParticular": "Appgboye To Zenith Bank OLAJUMOKE VICTORIA OLA",
                "referenceID": "NIP/241022091938003476055146",
                "valueDate": "2024-10-22T00:00:00",
                "reference": "082/4843257020/001384571041",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1417322.09,
                "withdrawls": 100000,
                "deposit": 0,
                "tranDateString": "10/22/2024 ",
                "valueDateString": "10/22/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "22/10/2024 09:37:00"
              },
              {
                "tranID": "S49837442",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-22T00:00:00",
                "partTranType": "D",
                "tranAmount": 53.75,
                "tranParticular": "TRANSACTION CHARGE-Appgboye To Zenith Bank OLAJUMO",
                "referenceID": "NIP/241022091938003476055146",
                "valueDate": "2024-10-22T00:00:00",
                "reference": "082/4843257020/001384571041",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1417375.84,
                "withdrawls": 53.75,
                "deposit": 0,
                "tranDateString": "10/22/2024 ",
                "valueDateString": "10/22/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "22/10/2024 09:37:00"
              },
              {
                "tranID": "S50722832",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-22T00:00:00",
                "partTranType": "D",
                "tranAmount": 50000,
                "tranParticular": "TRF to AMOUR ELECTRICAL WORKS/App:opening balance",
                "referenceID": "PPL",
                "valueDate": "2024-10-22T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1467375.84,
                "withdrawls": 50000,
                "deposit": 0,
                "tranDateString": "10/22/2024 ",
                "valueDateString": "10/22/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "22/10/2024 12:20:24"
              },
              {
                "tranID": "S51094996",
                "accountName": "JACOB OJO AYODELE",
                "customerCode": null,
                "tranDate": "2024-10-22T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY HOLD ON ACCOUNT ON 22-OCT-2024",
                "referenceID": " ",
                "valueDate": "2024-10-22T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1467425.84,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "10/22/2024 ",
                "valueDateString": "10/22/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  35",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "22/10/2024 13:40:10"
              },
              {
                "tranID": "S51789990",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-22T00:00:00",
                "partTranType": "D",
                "tranAmount": 661.25,
                "tranParticular": " App:reservation To Fidelity Bank Remita Check",
                "referenceID": "FTN015582832984/4843257020",
                "valueDate": "2024-10-22T00:00:00",
                "reference": "ETRNZ|495561493894",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1468087.09,
                "withdrawls": 661.25,
                "deposit": 0,
                "tranDateString": "10/22/2024 ",
                "valueDateString": "10/22/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "22/10/2024 15:59:19"
              },
              {
                "tranID": "S51790105",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-22T00:00:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App:reservation To Fidelity Ban",
                "referenceID": "FTN015582832984/4843257020",
                "valueDate": "2024-10-22T00:00:00",
                "reference": "ETRNZ|495561493894",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1468097.84,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "10/22/2024 ",
                "valueDateString": "10/22/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "22/10/2024 15:59:19"
              },
              {
                "tranID": "S53525111",
                "accountName": "IYABO  TIWA  BANJO",
                "customerCode": null,
                "tranDate": "2024-10-23T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 22-10-2024",
                "referenceID": " ",
                "valueDate": "2024-10-23T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1468147.84,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "10/23/2024 ",
                "valueDateString": "10/23/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  90",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "23/10/2024 09:16:45"
              },
              {
                "tranID": "S55463852",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-10-23T00:00:00",
                "partTranType": "C",
                "tranAmount": 50000,
                "tranParticular": "NIP FRM OLALERE SURAJUDEEN AJIBOLA-FBNMOBILEOY",
                "referenceID": "NIP/241023121521000081662360",
                "valueDate": "2024-10-23T00:00:00",
                "reference": "001/4843257020/001388742013",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1418147.84,
                "withdrawls": 0,
                "deposit": 50000,
                "tranDateString": "10/23/2024 ",
                "valueDateString": "10/23/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "23/10/2024 12:30:48"
              },
              {
                "tranID": " 01298375",
                "accountName": "OGUNMODEDE WASIU ABIODUN",
                "customerCode": null,
                "tranDate": "2024-10-23T00:00:00",
                "partTranType": "C",
                "tranAmount": 10000,
                "tranParticular": "TRANSFER B/O: OGUNMODEDE WASIU ABIODUN",
                "referenceID": "SFR2SFR2",
                "valueDate": "2024-10-23T00:00:00",
                "reference": "082|TRF|BAS11325",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1408147.84,
                "withdrawls": 0,
                "deposit": 10000,
                "tranDateString": "10/23/2024 ",
                "valueDateString": "10/23/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "23/10/2024 12:55:52"
              },
              {
                "tranID": "S55642517",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-23T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY / 01298375",
                "referenceID": " ",
                "valueDate": "2024-10-23T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1408197.84,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "10/23/2024 ",
                "valueDateString": "10/23/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "23/10/2024 12:55:52"
              },
              {
                "tranID": "S56604911",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-10-23T00:00:00",
                "partTranType": "C",
                "tranAmount": 30000,
                "tranParticular": "NIP FRM MOTUNRAYO FATIMA SALAKO-Transfer from",
                "referenceID": "NIP/241023145406121037268542",
                "valueDate": "2024-10-23T00:00:00",
                "reference": "001/4843257020/001389616205",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1378197.84,
                "withdrawls": 0,
                "deposit": 30000,
                "tranDateString": "10/23/2024 ",
                "valueDateString": "10/23/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "23/10/2024 15:59:52"
              },
              {
                "tranID": "S58372596",
                "accountName": "ELI CHIJIOKE",
                "customerCode": null,
                "tranDate": "2024-10-24T00:00:00",
                "partTranType": "D",
                "tranAmount": 100,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 23-10-2024",
                "referenceID": " ",
                "valueDate": "2024-10-24T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1378297.84,
                "withdrawls": 100,
                "deposit": 0,
                "tranDateString": "10/24/2024 ",
                "valueDateString": "10/24/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  94",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "24/10/2024 09:21:57"
              },
              {
                "tranID": "S59262195",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-24T00:00:00",
                "partTranType": "D",
                "tranAmount": 50000,
                "tranParticular": "TRF to OCTAVE MUSIC CREATIVE NG/App:savings To FCM",
                "referenceID": "PPL",
                "valueDate": "2024-10-24T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1428297.84,
                "withdrawls": 50000,
                "deposit": 0,
                "tranDateString": "10/24/2024 ",
                "valueDateString": "10/24/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "24/10/2024 09:21:57"
              },
              {
                "tranID": "S59613268",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-24T00:00:00",
                "partTranType": "D",
                "tranAmount": 30000,
                "tranParticular": "Appfund To Access Bank EKEKWE OGECHUKWU CYNTHI",
                "referenceID": "NIP/241024100700003481389625",
                "valueDate": "2024-10-24T00:00:00",
                "reference": "082/4843257020/001391921053",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1458297.84,
                "withdrawls": 30000,
                "deposit": 0,
                "tranDateString": "10/24/2024 ",
                "valueDateString": "10/24/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "24/10/2024 10:10:36"
              },
              {
                "tranID": "S59613280",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-24T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appfund To Access Bank EKEKWE O",
                "referenceID": "NIP/241024100700003481389625",
                "valueDate": "2024-10-24T00:00:00",
                "reference": "082/4843257020/001391921053",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1458324.72,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "10/24/2024 ",
                "valueDateString": "10/24/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "24/10/2024 10:10:36"
              },
              {
                "tranID": "S60016052",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-24T00:00:00",
                "partTranType": "D",
                "tranAmount": 10161.25,
                "tranParticular": "Appregistration To Fidelity Bank Remita Checko",
                "referenceID": "NIP/241024112328003481606362",
                "valueDate": "2024-10-24T00:00:00",
                "reference": "082/4843257020/001392218019",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1468485.97,
                "withdrawls": 10161.25,
                "deposit": 0,
                "tranDateString": "10/24/2024 ",
                "valueDateString": "10/24/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "24/10/2024 11:24:44"
              },
              {
                "tranID": "S60016067",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-24T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appregistration To Fidelity Ban",
                "referenceID": "NIP/241024112328003481606362",
                "valueDate": "2024-10-24T00:00:00",
                "reference": "082/4843257020/001392218019",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1468512.85,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "10/24/2024 ",
                "valueDateString": "10/24/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "24/10/2024 11:24:44"
              },
              {
                "tranID": "S61971256",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-10-24T00:00:00",
                "partTranType": "C",
                "tranAmount": 15000,
                "tranParticular": "NIP FRM OKOLIE CHIBUIKE VICTOR-MOBUTOOYEWUMI O",
                "referenceID": "NIP/241024174224865998773080",
                "valueDate": "2024-10-24T00:00:00",
                "reference": "001/4843257020/001393716825",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1453512.85,
                "withdrawls": 0,
                "deposit": 15000,
                "tranDateString": "10/24/2024 ",
                "valueDateString": "10/24/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "24/10/2024 17:46:06"
              },
              {
                "tranID": "S63124748",
                "accountName": "IROH CHINEDU MORRIS",
                "customerCode": null,
                "tranDate": "2024-10-25T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 24-10-2024",
                "referenceID": " ",
                "valueDate": "2024-10-25T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1453562.85,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "10/25/2024 ",
                "valueDateString": "10/25/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  84",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "25/10/2024 09:32:38"
              },
              {
                "tranID": "S64598285",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-25T00:00:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": " App:card To Keystone Bank DURU OLIVE NNEKA",
                "referenceID": "FTN010360410228/4843257020",
                "valueDate": "2024-10-25T00:00:00",
                "reference": "ETRNZ|052952288919",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1458562.85,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "10/25/2024 ",
                "valueDateString": "10/25/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "25/10/2024 10:44:25"
              },
              {
                "tranID": "S64598295",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-25T00:00:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App:card To Keystone Bank DURU",
                "referenceID": "FTN010360410228/4843257020",
                "valueDate": "2024-10-25T00:00:00",
                "reference": "ETRNZ|052952288919",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1458573.6,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "10/25/2024 ",
                "valueDateString": "10/25/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "25/10/2024 10:44:25"
              },
              {
                "tranID": "S65338397",
                "accountName": "HARCKO WORLD FABRICS",
                "customerCode": null,
                "tranDate": "2024-10-25T00:00:00",
                "partTranType": "C",
                "tranAmount": 15000,
                "tranParticular": "TRF From HARCKO WORLD FABRICS/Appharckofabrics To",
                "referenceID": "PPL",
                "valueDate": "2024-10-25T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1443573.6,
                "withdrawls": 0,
                "deposit": 15000,
                "tranDateString": "10/25/2024 ",
                "valueDateString": "10/25/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "25/10/2024 12:50:40"
              },
              {
                "tranID": "S68157575",
                "accountName": "MOSES SACKITEY",
                "customerCode": null,
                "tranDate": "2024-10-27T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 25-10-2024",
                "referenceID": " ",
                "valueDate": "2024-10-27T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1443623.6,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "10/27/2024 ",
                "valueDateString": "10/27/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  37",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "26/10/2024 10:43:47"
              },
              {
                "tranID": "S69385860",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-10-27T00:00:00",
                "partTranType": "C",
                "tranAmount": 30000,
                "tranParticular": "NIP FRM AYOTUNDE O SENJOBI-OYEWUMI OLAOLU AMOS",
                "referenceID": "NIP/241026105339345654702035",
                "valueDate": "2024-10-26T00:00:00",
                "reference": "001/4843257020/001398753709",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1413623.6,
                "withdrawls": 0,
                "deposit": 30000,
                "tranDateString": "10/27/2024 ",
                "valueDateString": "10/26/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "26/10/2024 13:08:25"
              },
              {
                "tranID": "S69503714",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-27T00:00:00",
                "partTranType": "D",
                "tranAmount": 15000,
                "tranParticular": "TRF to PENIEL GLEAM GLOBAL RESOURCES/App:Dec contr",
                "referenceID": "PPL",
                "valueDate": "2024-10-26T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1428623.6,
                "withdrawls": 15000,
                "deposit": 0,
                "tranDateString": "10/27/2024 ",
                "valueDateString": "10/26/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "26/10/2024 13:08:25"
              },
              {
                "tranID": "S69511593",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-27T00:00:00",
                "partTranType": "D",
                "tranAmount": 15000,
                "tranParticular": "TRF to OYEWUMI VICTORIOUS FAMILY/App:mum and dad u",
                "referenceID": "PPL",
                "valueDate": "2024-10-26T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1443623.6,
                "withdrawls": 15000,
                "deposit": 0,
                "tranDateString": "10/27/2024 ",
                "valueDateString": "10/26/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "26/10/2024 13:08:25"
              },
              {
                "tranID": "S72656971",
                "accountName": "AJAYI MARY",
                "customerCode": null,
                "tranDate": "2024-10-27T00:00:00",
                "partTranType": "D",
                "tranAmount": 160,
                "tranParticular": "SMS ALERT CHARGES FROM 26TH SEP TO 25TH OCT 2024",
                "referenceID": " ",
                "valueDate": "2024-10-27T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1443783.6,
                "withdrawls": 160,
                "deposit": 0,
                "tranDateString": "10/27/2024 ",
                "valueDateString": "10/27/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  78",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "27/10/2024 10:11:48"
              },
              {
                "tranID": "S75459801",
                "accountName": "ADENOWO KAYODE MICHAEL",
                "customerCode": null,
                "tranDate": "2024-10-28T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 27-10-2024",
                "referenceID": " ",
                "valueDate": "2024-10-28T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1443833.6,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "10/28/2024 ",
                "valueDateString": "10/28/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  12",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "28/10/2024 09:24:19"
              },
              {
                "tranID": "S78043198",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-28T00:00:00",
                "partTranType": "D",
                "tranAmount": 10000,
                "tranParticular": "TRF to PENIEL GLEAM GLOBAL RESOURCES/App:Dec contr",
                "referenceID": "PPL",
                "valueDate": "2024-10-28T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1453833.6,
                "withdrawls": 10000,
                "deposit": 0,
                "tranDateString": "10/28/2024 ",
                "valueDateString": "10/28/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "29/10/2024 03:49:15"
              },
              {
                "tranID": "S78694867",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-28T00:00:00",
                "partTranType": "D",
                "tranAmount": 10000,
                "tranParticular": "TRF to OCTAVE MUSIC CREATIVE NG/App: To FCMB OCTAV",
                "referenceID": "PPL",
                "valueDate": "2024-10-28T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1463833.6,
                "withdrawls": 10000,
                "deposit": 0,
                "tranDateString": "10/28/2024 ",
                "valueDateString": "10/28/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "29/10/2024 03:49:15"
              },
              {
                "tranID": "S79548493",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-28T00:00:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-10-28T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1468833.6,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "10/28/2024 ",
                "valueDateString": "10/28/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "29/10/2024 03:49:15"
              },
              {
                "tranID": "S82193845",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-10-29T00:00:00",
                "partTranType": "C",
                "tranAmount": 15000,
                "tranParticular": "NIP FRM OMEH HYCENTH-harckofabricsAT68TRF2MPT3",
                "referenceID": "NIP/241029111747605038214511",
                "valueDate": "2024-10-29T00:00:00",
                "reference": "001/4843257020/001407858367",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1453833.6,
                "withdrawls": 0,
                "deposit": 15000,
                "tranDateString": "10/29/2024 ",
                "valueDateString": "10/29/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "29/10/2024 11:59:04"
              },
              {
                "tranID": "S82243513",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-29T00:00:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": " App:card To Keystone Bank DURU OLIVE NNEKA",
                "referenceID": "FTN011292019369/4843257020",
                "valueDate": "2024-10-29T00:00:00",
                "reference": "ETRNZ|292047026169",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1458833.6,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "10/29/2024 ",
                "valueDateString": "10/29/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "29/10/2024 11:59:04"
              },
              {
                "tranID": "S82243523",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-29T00:00:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App:card To Keystone Bank DURU",
                "referenceID": "FTN011292019369/4843257020",
                "valueDate": "2024-10-29T00:00:00",
                "reference": "ETRNZ|292047026169",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1458844.35,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "10/29/2024 ",
                "valueDateString": "10/29/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "29/10/2024 11:59:04"
              },
              {
                "tranID": "S83118102",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-10-29T00:00:00",
                "partTranType": "C",
                "tranAmount": 15000,
                "tranParticular": "NIP FRM BAMIDELE NIYI OLOWOSHILE-FCMB",
                "referenceID": "NIP/241029141350453169602244",
                "valueDate": "2024-10-29T00:00:00",
                "reference": "001/4843257020/001408574871",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1443844.35,
                "withdrawls": 0,
                "deposit": 15000,
                "tranDateString": "10/29/2024 ",
                "valueDateString": "10/29/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "29/10/2024 14:19:41"
              },
              {
                "tranID": "S85355491",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-10-30T00:00:00",
                "partTranType": "C",
                "tranAmount": 405000,
                "tranParticular": "NIP FRM OYEWUMI OLAOLU AMOS-NIP Transfer to OY",
                "referenceID": "NIP/241029205938000129449819",
                "valueDate": "2024-10-29T00:00:00",
                "reference": "001/4843257020/001410160783",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1038844.35,
                "withdrawls": 0,
                "deposit": 405000,
                "tranDateString": "10/30/2024 ",
                "valueDateString": "10/29/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "31/10/2024 01:56:28"
              },
              {
                "tranID": "S85599705",
                "accountName": "ONU HILLARY CHIDI",
                "customerCode": null,
                "tranDate": "2024-10-30T00:00:00",
                "partTranType": "D",
                "tranAmount": 100,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 29-10-2024",
                "referenceID": " ",
                "valueDate": "2024-10-30T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1038944.35,
                "withdrawls": 100,
                "deposit": 0,
                "tranDateString": "10/30/2024 ",
                "valueDateString": "10/30/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  24",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "31/10/2024 01:56:28"
              },
              {
                "tranID": "S91342285",
                "accountName": "OGAH AMUCHE SARAH",
                "customerCode": null,
                "tranDate": "2024-10-31T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 30-10-2024",
                "referenceID": " ",
                "valueDate": "2024-10-31T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1038994.35,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "10/31/2024 ",
                "valueDateString": "10/31/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  15",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "31/10/2024 12:25:05"
              },
              {
                "tranID": "S94914661",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-31T00:00:00",
                "partTranType": "D",
                "tranAmount": 15000,
                "tranParticular": "Apprefund To GTBank Plc SAHEED AZEEZ ABIODUN",
                "referenceID": "NIP/241031152845003500554272",
                "valueDate": "2024-10-31T00:00:00",
                "reference": "082/4843257020/001415902355",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1053994.35,
                "withdrawls": 15000,
                "deposit": 0,
                "tranDateString": "10/31/2024 ",
                "valueDateString": "10/31/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "31/10/2024 15:30:29"
              },
              {
                "tranID": "S94914684",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-31T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Apprefund To GTBank Plc SAHEED",
                "referenceID": "NIP/241031152845003500554272",
                "valueDate": "2024-10-31T00:00:00",
                "reference": "082/4843257020/001415902355",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1054021.23,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "10/31/2024 ",
                "valueDateString": "10/31/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "31/10/2024 15:30:29"
              },
              {
                "tranID": "S95178825",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-31T00:00:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": "TRF to OYEWUMI VICTORIOUS FAMILY/App:nepa bill To",
                "referenceID": "PPL",
                "valueDate": "2024-10-31T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1059021.23,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "10/31/2024 ",
                "valueDateString": "10/31/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "31/10/2024 16:15:08"
              },
              {
                "tranID": "S96172642",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-10-31T00:00:00",
                "partTranType": "D",
                "tranAmount": 405000,
                "tranParticular": "TRF to WAVELENGTH MULTI-RESOURCES NG/App: To FCMB",
                "referenceID": "PPL",
                "valueDate": "2024-10-31T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1464021.23,
                "withdrawls": 405000,
                "deposit": 0,
                "tranDateString": "10/31/2024 ",
                "valueDateString": "10/31/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "31/10/2024 18:41:55"
              },
              {
                "tranID": " S2110424",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-01T01:28:00",
                "partTranType": "D",
                "tranAmount": 13000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-11-01T01:28:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1477021.23,
                "withdrawls": 13000,
                "deposit": 0,
                "tranDateString": "11/01/2024 01:28 AM",
                "valueDateString": "11/01/2024 01:28 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "02/11/2024 01:28:09"
              },
              {
                "tranID": " S5279997",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-11-03T11:55:00",
                "partTranType": "C",
                "tranAmount": 50000,
                "tranParticular": "NIP FRM MOTUNRAYO FATIMA SALAKO-Transfer from",
                "referenceID": "NIP/241102105728121509516182",
                "valueDate": "2024-11-02T11:55:00",
                "reference": "001/4843257020/001422033787",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1427021.23,
                "withdrawls": 0,
                "deposit": 50000,
                "tranDateString": "11/03/2024 11:55 AM",
                "valueDateString": "11/02/2024 11:55 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "03/11/2024 11:55:41"
              },
              {
                "tranID": "S11863898",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-04T10:03:00",
                "partTranType": "D",
                "tranAmount": 30000,
                "tranParticular": "App To ACCESS BANK PLC DIAMOND OLUWASEUN SOLOM",
                "referenceID": "NIP/241103182517003510691507",
                "valueDate": "2024-11-03T10:03:00",
                "reference": "082/4843257020/001426210571",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1457021.23,
                "withdrawls": 30000,
                "deposit": 0,
                "tranDateString": "11/04/2024 10:03 AM",
                "valueDateString": "11/03/2024 10:03 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "04/11/2024 10:03:53"
              },
              {
                "tranID": "S11864020",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-04T10:03:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-App To ACCESS BANK PLC DIAMOND",
                "referenceID": "NIP/241103182517003510691507",
                "valueDate": "2024-11-04T10:03:00",
                "reference": "082/4843257020/001426210571",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1457048.11,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "11/04/2024 10:03 AM",
                "valueDateString": "11/04/2024 10:03 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "04/11/2024 10:03:53"
              },
              {
                "tranID": "S11869419",
                "accountName": "AJANAKU AYOOLA OLAWALE",
                "customerCode": null,
                "tranDate": "2024-11-04T10:03:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 03-11-2024",
                "referenceID": " ",
                "valueDate": "2024-11-04T10:03:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1457098.11,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "11/04/2024 10:03 AM",
                "valueDateString": "11/04/2024 10:03 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  52",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "04/11/2024 10:03:53"
              },
              {
                "tranID": "S15117904",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-04T13:14:00",
                "partTranType": "D",
                "tranAmount": 200,
                "tranParticular": "FCMBMobile:AirtimeTopup0805653987724110411463",
                "referenceID": "GLO/2411041146324572914843257/MOBILE",
                "valueDate": "2024-11-04T13:14:00",
                "reference": "2411041146324572914843257",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1457298.11,
                "withdrawls": 200,
                "deposit": 0,
                "tranDateString": "11/04/2024 01:14 PM",
                "valueDateString": "11/04/2024 01:14 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "04/11/2024 13:14:04"
              },
              {
                "tranID": "S15427028",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-11-04T13:52:00",
                "partTranType": "C",
                "tranAmount": 50000,
                "tranParticular": "NIP FRM OLUWASEUN SOLOMON AJEIGBE-TRFSupportFR",
                "referenceID": "NIP/241104133727219800690386",
                "valueDate": "2024-11-04T13:52:00",
                "reference": "001/4843257020/001428358325",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1407298.11,
                "withdrawls": 0,
                "deposit": 50000,
                "tranDateString": "11/04/2024 01:52 PM",
                "valueDateString": "11/04/2024 01:52 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "04/11/2024 13:52:10"
              },
              {
                "tranID": "S17934488",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-04T23:25:00",
                "partTranType": "D",
                "tranAmount": 70000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-11-04T23:25:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1477298.11,
                "withdrawls": 70000,
                "deposit": 0,
                "tranDateString": "11/04/2024 11:25 PM",
                "valueDateString": "11/04/2024 11:25 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "04/11/2024 23:25:02"
              },
              {
                "tranID": "S18418217",
                "accountName": "TRIPPLECROSS SCHOOLS",
                "customerCode": null,
                "tranDate": "2024-11-05T14:24:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 04-11-2024",
                "referenceID": " ",
                "valueDate": "2024-11-05T14:24:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1477348.11,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "11/05/2024 02:24 PM",
                "valueDateString": "11/05/2024 02:24 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  20",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "05/11/2024 14:24:13"
              },
              {
                "tranID": "S31193180",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-07T12:20:00",
                "partTranType": "D",
                "tranAmount": 20000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-11-07T12:20:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1497348.11,
                "withdrawls": 20000,
                "deposit": 0,
                "tranDateString": "11/07/2024 12:20 PM",
                "valueDateString": "11/07/2024 12:20 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "07/11/2024 12:20:28"
              },
              {
                "tranID": "S36923647",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-11-08T13:43:00",
                "partTranType": "C",
                "tranAmount": 50000,
                "tranParticular": "NIP FRM ETUMNI MAYORJ-FBNMOBILEOYEWUMI OLAOLU",
                "referenceID": "NIP/241108133129000078503085",
                "valueDate": "2024-11-08T13:43:00",
                "reference": "001/4843257020/001440792955",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1447348.11,
                "withdrawls": 0,
                "deposit": 50000,
                "tranDateString": "11/08/2024 01:43 PM",
                "valueDateString": "11/08/2024 01:43 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "08/11/2024 13:43:37"
              },
              {
                "tranID": "S36951814",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-08T13:40:00",
                "partTranType": "D",
                "tranAmount": 30000,
                "tranParticular": "Appprocess To ACCESS BANK PLC DIAMOND EKEKWE O",
                "referenceID": "NIP/241108133944003524207074",
                "valueDate": "2024-11-08T13:40:00",
                "reference": "082/4843257020/001440809329",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1477348.11,
                "withdrawls": 30000,
                "deposit": 0,
                "tranDateString": "11/08/2024 01:40 PM",
                "valueDateString": "11/08/2024 01:40 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "08/11/2024 13:40:16"
              },
              {
                "tranID": "S36951828",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-08T13:40:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appprocess To ACCESS BANK PLC D",
                "referenceID": "NIP/241108133944003524207074",
                "valueDate": "2024-11-08T13:40:00",
                "reference": "082/4843257020/001440809329",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1477374.99,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "11/08/2024 01:40 PM",
                "valueDateString": "11/08/2024 01:40 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "08/11/2024 13:40:16"
              },
              {
                "tranID": "S39753466",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-10T11:03:00",
                "partTranType": "D",
                "tranAmount": 1800,
                "tranParticular": " App:fund To Opay Digital Services Limited JOY",
                "referenceID": "FTN025000645899/4843257020",
                "valueDate": "2024-11-08T11:03:00",
                "reference": "ETRNZ|781293071454",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1479174.99,
                "withdrawls": 1800,
                "deposit": 0,
                "tranDateString": "11/10/2024 11:03 AM",
                "valueDateString": "11/08/2024 11:03 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "09/11/2024 11:03:30"
              },
              {
                "tranID": "S39753475",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-10T11:03:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App:fund To Opay Digital Servic",
                "referenceID": "FTN025000645899/4843257020",
                "valueDate": "2024-11-10T11:03:00",
                "reference": "ETRNZ|781293071454",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1479185.74,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "11/10/2024 11:03 AM",
                "valueDateString": "11/10/2024 11:03 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "09/11/2024 11:03:30"
              },
              {
                "tranID": "S39765151",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-10T11:03:00",
                "partTranType": "D",
                "tranAmount": 1500,
                "tranParticular": "Appfund To First Bank of Nigeria OMERI IFEYINW",
                "referenceID": "NIP/241108220718003525573505",
                "valueDate": "2024-11-08T11:03:00",
                "reference": "082/4843257020/001442280905",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1480685.74,
                "withdrawls": 1500,
                "deposit": 0,
                "tranDateString": "11/10/2024 11:03 AM",
                "valueDateString": "11/08/2024 11:03 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "09/11/2024 11:03:30"
              },
              {
                "tranID": "S39765162",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-10T11:03:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-Appfund To First Bank of Nigeri",
                "referenceID": "NIP/241108220718003525573505",
                "valueDate": "2024-11-10T11:03:00",
                "reference": "082/4843257020/001442280905",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1480696.49,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "11/10/2024 11:03 AM",
                "valueDateString": "11/10/2024 11:03 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "09/11/2024 11:03:30"
              },
              {
                "tranID": "S39940607",
                "accountName": "OGUNMOKUN ADEKEMI STELLA",
                "customerCode": null,
                "tranDate": "2024-11-10T11:03:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 08-11-2024",
                "referenceID": " ",
                "valueDate": "2024-11-10T11:03:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1480746.49,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "11/10/2024 11:03 AM",
                "valueDateString": "11/10/2024 11:03 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  50",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "09/11/2024 11:03:30"
              },
              {
                "tranID": "S41827432",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-10T13:42:00",
                "partTranType": "D",
                "tranAmount": 16400,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To First City M",
                "referenceID": "PPL",
                "valueDate": "2024-11-09T13:42:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1497146.49,
                "withdrawls": 16400,
                "deposit": 0,
                "tranDateString": "11/10/2024 01:42 PM",
                "valueDateString": "11/09/2024 01:42 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "09/11/2024 13:42:43"
              },
              {
                "tranID": "S44565474",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-11-10T09:20:00",
                "partTranType": "C",
                "tranAmount": 150000,
                "tranParticular": "NIP FRM EZE JUDITH ODINAKA-FBNMOBILEOYEWUMI OL",
                "referenceID": "NIP/241109210828000087886197",
                "valueDate": "2024-11-09T09:20:00",
                "reference": "001/4843257020/001444743029",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1347146.49,
                "withdrawls": 0,
                "deposit": 150000,
                "tranDateString": "11/10/2024 09:20 AM",
                "valueDateString": "11/09/2024 09:20 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "10/11/2024 09:20:38"
              },
              {
                "tranID": "S48019339",
                "accountName": "ROJAIYE OLASIMBO",
                "customerCode": null,
                "tranDate": "2024-11-11T09:58:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 10-11-2024",
                "referenceID": " ",
                "valueDate": "2024-11-11T09:58:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1347196.49,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "11/11/2024 09:58 AM",
                "valueDateString": "11/11/2024 09:58 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  29",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "11/11/2024 09:58:13"
              },
              {
                "tranID": "S49657287",
                "accountName": "OCTAVE MUSIC CREATIVE NG",
                "customerCode": null,
                "tranDate": "2024-11-11T09:58:00",
                "partTranType": "C",
                "tranAmount": 20000,
                "tranParticular": "TRF From OCTAVE MUSIC CREATIVE NG/Apprefund To FCM",
                "referenceID": "PPL",
                "valueDate": "2024-11-11T09:58:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1327196.49,
                "withdrawls": 0,
                "deposit": 20000,
                "tranDateString": "11/11/2024 09:58 AM",
                "valueDateString": "11/11/2024 09:58 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "11/11/2024 09:58:13"
              },
              {
                "tranID": "S51143545",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-11T14:43:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": "FCMBMobile:AirtimeTopup0805653987724111113414",
                "referenceID": "GLO/2411111341457865574843257/MOBILE",
                "valueDate": "2024-11-11T14:43:00",
                "reference": "2411111341457865574843257",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1332196.49,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "11/11/2024 02:43 PM",
                "valueDateString": "11/11/2024 02:43 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "11/11/2024 14:43:35"
              },
              {
                "tranID": "S51451818",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-11T15:32:00",
                "partTranType": "D",
                "tranAmount": 95000,
                "tranParticular": "AppCAC IT de moment brothers To VFD Microfinan",
                "referenceID": "NIP/241111153005003531398423",
                "valueDate": "2024-11-11T15:32:00",
                "reference": "082/4843257020/001448331342",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1427196.49,
                "withdrawls": 95000,
                "deposit": 0,
                "tranDateString": "11/11/2024 03:32 PM",
                "valueDateString": "11/11/2024 03:32 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "11/11/2024 15:32:58"
              },
              {
                "tranID": "S51451854",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-11T15:32:00",
                "partTranType": "D",
                "tranAmount": 53.75,
                "tranParticular": "TRANSACTION CHARGE-AppCAC IT de moment brothers To",
                "referenceID": "NIP/241111153005003531398423",
                "valueDate": "2024-11-11T15:32:00",
                "reference": "082/4843257020/001448331342",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1427250.24,
                "withdrawls": 53.75,
                "deposit": 0,
                "tranDateString": "11/11/2024 03:32 PM",
                "valueDateString": "11/11/2024 03:32 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "11/11/2024 15:32:58"
              },
              {
                "tranID": "S53394259",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-12T09:21:00",
                "partTranType": "D",
                "tranAmount": 4500,
                "tranParticular": " App:fund To Palmpay OMOBOLANLE MUDIRAKAT TAIW",
                "referenceID": "FTN025001542895/4843257020",
                "valueDate": "2024-11-11T09:21:00",
                "reference": "ETRNZ|398340703415",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1431750.24,
                "withdrawls": 4500,
                "deposit": 0,
                "tranDateString": "11/12/2024 09:21 AM",
                "valueDateString": "11/11/2024 09:21 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "12/11/2024 09:21:46"
              },
              {
                "tranID": "S53394264",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-12T09:21:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App:fund To Palmpay OMOBOLANLE",
                "referenceID": "FTN025001542895/4843257020",
                "valueDate": "2024-11-12T09:21:00",
                "reference": "ETRNZ|398340703415",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1431760.99,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "11/12/2024 09:21 AM",
                "valueDateString": "11/12/2024 09:21 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "12/11/2024 09:21:46"
              },
              {
                "tranID": "S53590013",
                "accountName": "ABODUNRIN ADESHOLA RAHEEM",
                "customerCode": null,
                "tranDate": "2024-11-12T09:21:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 11-11-2024",
                "referenceID": " ",
                "valueDate": "2024-11-12T09:21:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1431810.99,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "11/12/2024 09:21 AM",
                "valueDateString": "11/12/2024 09:21 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  17",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "12/11/2024 09:21:46"
              },
              {
                "tranID": "S56691993",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-11-12T16:13:00",
                "partTranType": "C",
                "tranAmount": 102600,
                "tranParticular": "NIP FRM Fincra Technologies Limited-Taptap Sen",
                "referenceID": "NIP/241112151133846312643615",
                "valueDate": "2024-11-12T16:13:00",
                "reference": "001/4843257020/001451063083",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1329210.99,
                "withdrawls": 0,
                "deposit": 102600,
                "tranDateString": "11/12/2024 04:13 PM",
                "valueDateString": "11/12/2024 04:13 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "12/11/2024 16:13:42"
              },
              {
                "tranID": "S56759284",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-12T16:27:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": "Appfund To Opay Digital Services Limited FATAI",
                "referenceID": "NIP/241112162449003534077177",
                "valueDate": "2024-11-12T16:27:00",
                "reference": "082/4843257020/001451106071",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1334210.99,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "11/12/2024 04:27 PM",
                "valueDateString": "11/12/2024 04:27 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "12/11/2024 16:27:08"
              },
              {
                "tranID": "S56759321",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-12T16:27:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-Appfund To Opay Digital Service",
                "referenceID": "NIP/241112162449003534077177",
                "valueDate": "2024-11-12T16:27:00",
                "reference": "082/4843257020/001451106071",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1334221.74,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "11/12/2024 04:27 PM",
                "valueDateString": "11/12/2024 04:27 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "12/11/2024 16:27:08"
              },
              {
                "tranID": "S56790409",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-12T16:30:00",
                "partTranType": "D",
                "tranAmount": 10000,
                "tranParticular": "TRF to PENIEL GLEAM GLOBAL RESOURCES/App:Dec contr",
                "referenceID": "PPL",
                "valueDate": "2024-11-12T16:30:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1344221.74,
                "withdrawls": 10000,
                "deposit": 0,
                "tranDateString": "11/12/2024 04:30 PM",
                "valueDateString": "11/12/2024 04:30 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "12/11/2024 16:30:13"
              },
              {
                "tranID": "S57778887",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-12T19:31:00",
                "partTranType": "D",
                "tranAmount": 10000,
                "tranParticular": "AppOlaolu To Opay Digital Services Limited ADE",
                "referenceID": "NIP/241112192927003534649851",
                "valueDate": "2024-11-12T19:31:00",
                "reference": "082/4843257020/001451684171",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1354221.74,
                "withdrawls": 10000,
                "deposit": 0,
                "tranDateString": "11/12/2024 07:31 PM",
                "valueDateString": "11/12/2024 07:31 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "12/11/2024 19:31:09"
              },
              {
                "tranID": "S57778896",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-12T19:31:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-AppOlaolu To Opay Digital Servi",
                "referenceID": "NIP/241112192927003534649851",
                "valueDate": "2024-11-12T19:31:00",
                "reference": "082/4843257020/001451684171",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1354248.62,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "11/12/2024 07:31 PM",
                "valueDateString": "11/12/2024 07:31 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "12/11/2024 19:31:09"
              },
              {
                "tranID": "S57782654",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-12T19:31:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": "TRF to OBANUBI OYINDAMOLA RUTH/App: To FCMB OBANUB",
                "referenceID": "PPL",
                "valueDate": "2024-11-12T19:31:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1359248.62,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "11/12/2024 07:31 PM",
                "valueDateString": "11/12/2024 07:31 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "12/11/2024 19:31:09"
              },
              {
                "tranID": "S58320428",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-13T00:00:00",
                "partTranType": "D",
                "tranAmount": 70000,
                "tranParticular": "TRF to OCTAVE MUSIC CREATIVE NG/App:savings To FCM",
                "referenceID": "PPL",
                "valueDate": "2024-11-12T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1429248.62,
                "withdrawls": 70000,
                "deposit": 0,
                "tranDateString": "11/13/2024 ",
                "valueDateString": "11/12/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "13/11/2024 09:10:10"
              },
              {
                "tranID": "S58544963",
                "accountName": "GLOBAL LEGAL CONSULTS",
                "customerCode": null,
                "tranDate": "2024-11-13T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 12-11-2024",
                "referenceID": " ",
                "valueDate": "2024-11-13T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1429298.62,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "11/13/2024 ",
                "valueDateString": "11/13/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  21",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "13/11/2024 09:10:10"
              },
              {
                "tranID": "S61670945",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-13T00:00:00",
                "partTranType": "D",
                "tranAmount": 1800,
                "tranParticular": " App: To Opay Digital Services Limited RASHIDA",
                "referenceID": "FTN025001964217/4843257020",
                "valueDate": "2024-11-13T00:00:00",
                "reference": "ETRNZ|818698598907",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1431098.62,
                "withdrawls": 1800,
                "deposit": 0,
                "tranDateString": "11/13/2024 ",
                "valueDateString": "11/13/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "13/11/2024 16:01:43"
              },
              {
                "tranID": "S61670949",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-13T00:00:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App: To Opay Digital Services L",
                "referenceID": "FTN025001964217/4843257020",
                "valueDate": "2024-11-13T00:00:00",
                "reference": "ETRNZ|818698598907",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1431109.37,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "11/13/2024 ",
                "valueDateString": "11/13/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "13/11/2024 16:01:43"
              },
              {
                "tranID": "S63523017",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-14T00:00:00",
                "partTranType": "D",
                "tranAmount": 1200,
                "tranParticular": " App:Olaolu To EcoBank FRANKLIN UWADIEGWU MARS",
                "referenceID": "FTN025002089401/4843257020",
                "valueDate": "2024-11-13T00:00:00",
                "reference": "ETRNZ|337143384672",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1432309.37,
                "withdrawls": 1200,
                "deposit": 0,
                "tranDateString": "11/14/2024 ",
                "valueDateString": "11/13/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "14/11/2024 09:17:08"
              },
              {
                "tranID": "S63523031",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-14T00:00:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App:Olaolu To EcoBank FRANKLIN",
                "referenceID": "FTN025002089401/4843257020",
                "valueDate": "2024-11-14T00:00:00",
                "reference": "ETRNZ|337143384672",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1432320.12,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "11/14/2024 ",
                "valueDateString": "11/14/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "14/11/2024 09:17:08"
              },
              {
                "tranID": "S63625266",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-14T00:00:00",
                "partTranType": "D",
                "tranAmount": 10000,
                "tranParticular": "AppOlaolu To Union Bank ALABI A I MR",
                "referenceID": "NIP/241113221718003537550053",
                "valueDate": "2024-11-13T00:00:00",
                "reference": "082/4843257020/001454571965",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1442320.12,
                "withdrawls": 10000,
                "deposit": 0,
                "tranDateString": "11/14/2024 ",
                "valueDateString": "11/13/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "14/11/2024 09:17:08"
              },
              {
                "tranID": "S63625272",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-14T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-AppOlaolu To Union Bank ALABI A",
                "referenceID": "NIP/241113221718003537550053",
                "valueDate": "2024-11-14T00:00:00",
                "reference": "082/4843257020/001454571965",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1442347,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "11/14/2024 ",
                "valueDateString": "11/14/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "14/11/2024 09:17:08"
              },
              {
                "tranID": "S63804419",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-14T00:00:00",
                "partTranType": "D",
                "tranAmount": 4000,
                "tranParticular": "TRF to OBANUBI OYINDAMOLA RUTH/App:fund To FCMB OB",
                "referenceID": "PPL",
                "valueDate": "2024-11-14T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1446347,
                "withdrawls": 4000,
                "deposit": 0,
                "tranDateString": "11/14/2024 ",
                "valueDateString": "11/14/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "14/11/2024 09:17:08"
              },
              {
                "tranID": "S64500860",
                "accountName": "OGUNLEYE DASOLA",
                "customerCode": null,
                "tranDate": "2024-11-14T00:00:00",
                "partTranType": "C",
                "tranAmount": 205000,
                "tranParticular": "TRF From OGUNLEYE DASOLA/App:CAC To FCMB OYEW",
                "referenceID": "PPL",
                "valueDate": "2024-11-14T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1241347,
                "withdrawls": 0,
                "deposit": 205000,
                "tranDateString": "11/14/2024 ",
                "valueDateString": "11/14/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "14/11/2024 12:34:26"
              },
              {
                "tranID": "S66610476",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-14T00:00:00",
                "partTranType": "D",
                "tranAmount": 4000,
                "tranParticular": "TRF to ADEOSUN AYOBAMI MARY/App:fund To FCMB ADE",
                "referenceID": "PPL",
                "valueDate": "2024-11-14T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1245347,
                "withdrawls": 4000,
                "deposit": 0,
                "tranDateString": "11/14/2024 ",
                "valueDateString": "11/14/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "14/11/2024 15:59:55"
              },
              {
                "tranID": "S66777764",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-14T00:00:00",
                "partTranType": "D",
                "tranAmount": 1000,
                "tranParticular": "TRF to OBANUBI OYINDAMOLA RUTH/App: To FCMB OBANUB",
                "referenceID": "PPL",
                "valueDate": "2024-11-14T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1246347,
                "withdrawls": 1000,
                "deposit": 0,
                "tranDateString": "11/14/2024 ",
                "valueDateString": "11/14/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "14/11/2024 16:28:54"
              },
              {
                "tranID": "S68633818",
                "accountName": "ODUSHINA ABAYOMI FATAI",
                "customerCode": null,
                "tranDate": "2024-11-15T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 14-11-2024",
                "referenceID": " ",
                "valueDate": "2024-11-15T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1246397,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "11/15/2024 ",
                "valueDateString": "11/15/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  83",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "15/11/2024 09:20:11"
              },
              {
                "tranID": "S74051362",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-11-17T00:00:00",
                "partTranType": "C",
                "tranAmount": 4000,
                "tranParticular": "NIP FRM JOSEPH ABISOLA OJO-Transfer from JOSEP",
                "referenceID": "NIP/241116025445150335548337",
                "valueDate": "2024-11-16T00:00:00",
                "reference": "001/4843257020/001459547528",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1242397,
                "withdrawls": 0,
                "deposit": 4000,
                "tranDateString": "11/17/2024 ",
                "valueDateString": "11/16/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "17/11/2024 07:17:59"
              },
              {
                "tranID": "S77050914",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-17T00:00:00",
                "partTranType": "D",
                "tranAmount": 4000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-11-16T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1246397,
                "withdrawls": 4000,
                "deposit": 0,
                "tranDateString": "11/17/2024 ",
                "valueDateString": "11/16/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/11/2024 16:07:43"
              },
              {
                "tranID": "S77711439",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-17T00:00:00",
                "partTranType": "D",
                "tranAmount": 200,
                "tranParticular": "QR/Q22738801770/Airtime/MTN/2347032819698",
                "referenceID": "USSD",
                "valueDate": "2024-11-16T00:00:00",
                "reference": "USSD/Q22738801770",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1246597,
                "withdrawls": 200,
                "deposit": 0,
                "tranDateString": "11/17/2024 ",
                "valueDateString": "11/16/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/11/2024 17:52:28"
              },
              {
                "tranID": "S80539564",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-17T00:00:00",
                "partTranType": "D",
                "tranAmount": 10000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-11-17T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1256597,
                "withdrawls": 10000,
                "deposit": 0,
                "tranDateString": "11/17/2024 ",
                "valueDateString": "11/17/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "17/11/2024 12:32:25"
              },
              {
                "tranID": "S85658475",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-11-18T00:00:00",
                "partTranType": "C",
                "tranAmount": 10000,
                "tranParticular": "NIP FRM SHOBANDE RASAQ ABIODUN-Aro to OYEWUMI",
                "referenceID": "NIP/241118152435000162011427",
                "valueDate": "2024-11-18T00:00:00",
                "reference": "001/4843257020/001464890171",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1246597,
                "withdrawls": 0,
                "deposit": 10000,
                "tranDateString": "11/18/2024 ",
                "valueDateString": "11/18/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "18/11/2024 22:41:55"
              },
              {
                "tranID": "S87918038",
                "accountName": "AKEJU ISMAILA ADEDEJI",
                "customerCode": null,
                "tranDate": "2024-11-19T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 18-11-2024",
                "referenceID": " ",
                "valueDate": "2024-11-19T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1246647,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "11/19/2024 ",
                "valueDateString": "11/19/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  88",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "19/11/2024 10:01:50"
              },
              {
                "tranID": "S89152351",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-19T00:00:00",
                "partTranType": "D",
                "tranAmount": 10000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-11-19T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1256647,
                "withdrawls": 10000,
                "deposit": 0,
                "tranDateString": "11/19/2024 ",
                "valueDateString": "11/19/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "19/11/2024 10:01:50"
              },
              {
                "tranID": "S89661019",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-11-19T00:00:00",
                "partTranType": "C",
                "tranAmount": 30000,
                "tranParticular": "NIP FRM ADIGUN NURUDEEN AREMU-MOBUTOOYEWUMI OL",
                "referenceID": "NIP/241119112324074905648167",
                "valueDate": "2024-11-19T00:00:00",
                "reference": "001/4843257020/001466622809",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1226647,
                "withdrawls": 0,
                "deposit": 30000,
                "tranDateString": "11/19/2024 ",
                "valueDateString": "11/19/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "19/11/2024 15:08:05"
              },
              {
                "tranID": "S90292893",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-19T00:00:00",
                "partTranType": "D",
                "tranAmount": 500,
                "tranParticular": " App: To GTBank Plc OYEWUMI, OLAOLU AMOS",
                "referenceID": "FTN025003908658/4843257020",
                "valueDate": "2024-11-19T00:00:00",
                "reference": "ETRNZ|635025445773",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1227147,
                "withdrawls": 500,
                "deposit": 0,
                "tranDateString": "11/19/2024 ",
                "valueDateString": "11/19/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "19/11/2024 13:18:06"
              },
              {
                "tranID": "S90293010",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-19T00:00:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App: To GTBank Plc OYEWUMI, OLA",
                "referenceID": "FTN025003908658/4843257020",
                "valueDate": "2024-11-19T00:00:00",
                "reference": "ETRNZ|635025445773",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1227157.75,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "11/19/2024 ",
                "valueDateString": "11/19/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "19/11/2024 13:18:06"
              },
              {
                "tranID": "S90485291",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-19T00:00:00",
                "partTranType": "D",
                "tranAmount": 2000,
                "tranParticular": "TRF to HASSAN DAMILOLA MUINAT/App:refund To FCMB H",
                "referenceID": "PPL",
                "valueDate": "2024-11-19T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1229157.75,
                "withdrawls": 2000,
                "deposit": 0,
                "tranDateString": "11/19/2024 ",
                "valueDateString": "11/19/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "19/11/2024 13:54:10"
              },
              {
                "tranID": "S90492101",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-19T00:00:00",
                "partTranType": "D",
                "tranAmount": 18000,
                "tranParticular": "TRF to HASSAN DAMILOLA MUINAT/App:fund To FCMB HAS",
                "referenceID": "PPL",
                "valueDate": "2024-11-19T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1247157.75,
                "withdrawls": 18000,
                "deposit": 0,
                "tranDateString": "11/19/2024 ",
                "valueDateString": "11/19/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "19/11/2024 13:56:46"
              },
              {
                "tranID": "S93243419",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-11-20T00:00:00",
                "partTranType": "C",
                "tranAmount": 50000,
                "tranParticular": "NIP FRM ARO OLAYINKA EMMANUEL-Mints to OYEWUMI",
                "referenceID": "NIP/241119222747000164223684",
                "valueDate": "2024-11-19T00:00:00",
                "reference": "001/4843257020/001468181157",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1197157.75,
                "withdrawls": 0,
                "deposit": 50000,
                "tranDateString": "11/20/2024 ",
                "valueDateString": "11/19/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "20/11/2024 10:22:01"
              },
              {
                "tranID": "S93343040",
                "accountName": "DAWODU OLABISI FUADAT",
                "customerCode": null,
                "tranDate": "2024-11-20T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 19-11-2024",
                "referenceID": " ",
                "valueDate": "2024-11-20T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1197207.75,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "11/20/2024 ",
                "valueDateString": "11/20/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  60",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "20/11/2024 10:22:01"
              },
              {
                "tranID": "S94036418",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-20T00:00:00",
                "partTranType": "D",
                "tranAmount": 20000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-11-20T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1217207.75,
                "withdrawls": 20000,
                "deposit": 0,
                "tranDateString": "11/20/2024 ",
                "valueDateString": "11/20/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "20/11/2024 10:22:01"
              },
              {
                "tranID": "S94413370",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-20T00:00:00",
                "partTranType": "D",
                "tranAmount": 10000,
                "tranParticular": "TRF to ADEOSUN AYOBAMI MARY/App: To FCMB ADEOSUN",
                "referenceID": "PPL",
                "valueDate": "2024-11-20T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1227207.75,
                "withdrawls": 10000,
                "deposit": 0,
                "tranDateString": "11/20/2024 ",
                "valueDateString": "11/20/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "20/11/2024 10:22:01"
              },
              {
                "tranID": "S95315911",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-20T00:00:00",
                "partTranType": "D",
                "tranAmount": 95000,
                "tranParticular": "AppCAC IT WAJTEJ payment To VFD Microfinance B",
                "referenceID": "NIP/241120122948003552527020",
                "valueDate": "2024-11-20T00:00:00",
                "reference": "082/4843257020/001469089017",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1322207.75,
                "withdrawls": 95000,
                "deposit": 0,
                "tranDateString": "11/20/2024 ",
                "valueDateString": "11/20/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "20/11/2024 12:32:20"
              },
              {
                "tranID": "S95315925",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-20T00:00:00",
                "partTranType": "D",
                "tranAmount": 53.75,
                "tranParticular": "TRANSACTION CHARGE-AppCAC IT WAJTEJ payment To VFD",
                "referenceID": "NIP/241120122948003552527020",
                "valueDate": "2024-11-20T00:00:00",
                "reference": "082/4843257020/001469089017",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1322261.5,
                "withdrawls": 53.75,
                "deposit": 0,
                "tranDateString": "11/20/2024 ",
                "valueDateString": "11/20/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "20/11/2024 12:32:20"
              },
              {
                "tranID": "S95788014",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-20T00:00:00",
                "partTranType": "D",
                "tranAmount": 500,
                "tranParticular": " App: To GTBank Plc OYEWUMI, OLAOLU AMOS",
                "referenceID": "FTN025004265983/4843257020",
                "valueDate": "2024-11-20T00:00:00",
                "reference": "ETRNZ|780507530078",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1322761.5,
                "withdrawls": 500,
                "deposit": 0,
                "tranDateString": "11/20/2024 ",
                "valueDateString": "11/20/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "20/11/2024 13:52:03"
              },
              {
                "tranID": "S95788031",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-20T00:00:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App: To GTBank Plc OYEWUMI, OLA",
                "referenceID": "FTN025004265983/4843257020",
                "valueDate": "2024-11-20T00:00:00",
                "reference": "ETRNZ|780507530078",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1322772.25,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "11/20/2024 ",
                "valueDateString": "11/20/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "20/11/2024 13:52:03"
              },
              {
                "tranID": "S97078775",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-20T00:00:00",
                "partTranType": "D",
                "tranAmount": 20000,
                "tranParticular": "TRF to ORSHI TERFA JUDE/App:Olaolu To FCMB O",
                "referenceID": "PPL",
                "valueDate": "2024-11-20T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1342772.25,
                "withdrawls": 20000,
                "deposit": 0,
                "tranDateString": "11/20/2024 ",
                "valueDateString": "11/20/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "20/11/2024 17:20:37"
              },
              {
                "tranID": "S98544223",
                "accountName": "UKEANYA CHUKWUEBUKA MICHAEL",
                "customerCode": null,
                "tranDate": "2024-11-21T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 20-11-2024",
                "referenceID": " ",
                "valueDate": "2024-11-21T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1342822.25,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "11/21/2024 ",
                "valueDateString": "11/21/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  95",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "21/11/2024 09:18:00"
              },
              {
                "tranID": "S99728245",
                "accountName": "FUND TRANSFER TSS RECEIVALE A/C",
                "customerCode": null,
                "tranDate": "2024-11-21T00:00:00",
                "partTranType": "C",
                "tranAmount": 6000,
                "tranParticular": "Mbanking Trf: FCMB/002140633827;;AT124_TRF|2MPT6PK",
                "referenceID": "FCMB MOBILE",
                "valueDate": "2024-11-21T00:00:00",
                "reference": "4843257020/2140633827/231481/E",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1336822.25,
                "withdrawls": 0,
                "deposit": 6000,
                "tranDateString": "11/21/2024 ",
                "valueDateString": "11/21/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "21/11/2024 10:28:57"
              },
              {
                "tranID": "  S577548",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-21T00:00:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-11-21T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1341822.25,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "11/21/2024 ",
                "valueDateString": "11/21/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "21/11/2024 12:34:43"
              },
              {
                "tranID": " S3013437",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-11-21T00:00:00",
                "partTranType": "C",
                "tranAmount": 4000,
                "tranParticular": "NIP FRM JOSEPH ABISOLA OJO-Transfer from JOSEP",
                "referenceID": "NIP/241121200544933038461927",
                "valueDate": "2024-11-21T00:00:00",
                "reference": "001/4843257020/001472718495",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1337822.25,
                "withdrawls": 0,
                "deposit": 4000,
                "tranDateString": "11/21/2024 ",
                "valueDateString": "11/21/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "22/11/2024 01:10:18"
              },
              {
                "tranID": " S4917362",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-22T00:00:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": "Appfund To UBA OYEWUMI JOSEPH OLAYEMI",
                "referenceID": "NIP/241122102532003557157689",
                "valueDate": "2024-11-22T00:00:00",
                "reference": "082/4843257020/001473636995",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1342822.25,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "11/22/2024 ",
                "valueDateString": "11/22/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "22/11/2024 10:30:44"
              },
              {
                "tranID": " S4917375",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-22T00:00:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-Appfund To UBA OYEWUMI JOSEPH O",
                "referenceID": "NIP/241122102532003557157689",
                "valueDate": "2024-11-22T00:00:00",
                "reference": "082/4843257020/001473636995",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1342833,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "11/22/2024 ",
                "valueDateString": "11/22/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "22/11/2024 10:30:44"
              },
              {
                "tranID": " S5601773",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-22T00:00:00",
                "partTranType": "D",
                "tranAmount": 3000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-11-22T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1345833,
                "withdrawls": 3000,
                "deposit": 0,
                "tranDateString": "11/22/2024 ",
                "valueDateString": "11/22/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "22/11/2024 12:18:57"
              },
              {
                "tranID": "S13876833",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-11-24T00:00:00",
                "partTranType": "C",
                "tranAmount": 50000,
                "tranParticular": "NIP FRM TEMITOPE MOSES OLANIYI-Transfer from T",
                "referenceID": "NIP/241124004613194381814357",
                "valueDate": "2024-11-24T00:00:00",
                "reference": "001/4843257020/001478593508",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1295833,
                "withdrawls": 0,
                "deposit": 50000,
                "tranDateString": "11/24/2024 ",
                "valueDateString": "11/24/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "24/11/2024 09:27:19"
              },
              {
                "tranID": "S15007183",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-24T00:00:00",
                "partTranType": "D",
                "tranAmount": 30000,
                "tranParticular": "TRF to OCTAVE MUSIC CREATIVE NG/App:savings To FCM",
                "referenceID": "PPL",
                "valueDate": "2024-11-24T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1325833,
                "withdrawls": 30000,
                "deposit": 0,
                "tranDateString": "11/24/2024 ",
                "valueDateString": "11/24/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "24/11/2024 11:45:06"
              },
              {
                "tranID": "S17107320",
                "accountName": "OGUNAIKE ADEKUNLE ADEMUYIWA",
                "customerCode": null,
                "tranDate": "2024-11-25T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 24-11-2024",
                "referenceID": " ",
                "valueDate": "2024-11-25T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1325883,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "11/25/2024 ",
                "valueDateString": "11/25/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  55",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "25/11/2024 09:32:04"
              },
              {
                "tranID": "S21073745",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-11-25T00:00:00",
                "partTranType": "C",
                "tranAmount": 40000,
                "tranParticular": "NIP FRM PASLAN MUSIC-TRFFRM PASLAN MUSIC TO OY",
                "referenceID": "NIP/241125174547288883880831",
                "valueDate": "2024-11-25T00:00:00",
                "reference": "001/4843257020/001481950137",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1285883,
                "withdrawls": 0,
                "deposit": 40000,
                "tranDateString": "11/25/2024 ",
                "valueDateString": "11/25/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "26/11/2024 02:17:52"
              },
              {
                "tranID": "S22146360",
                "accountName": "HASSAN DAMILOLA MUINAT",
                "customerCode": null,
                "tranDate": "2024-11-25T00:00:00",
                "partTranType": "C",
                "tranAmount": 10000,
                "tranParticular": "TRF From HASSAN DAMILOLA MUINAT/App: To FCMB OYEWU",
                "referenceID": "PPL",
                "valueDate": "2024-11-25T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1275883,
                "withdrawls": 0,
                "deposit": 10000,
                "tranDateString": "11/25/2024 ",
                "valueDateString": "11/25/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "26/11/2024 02:17:52"
              },
              {
                "tranID": "S22436980",
                "accountName": "JIMOH SAHEED",
                "customerCode": null,
                "tranDate": "2024-11-26T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 25-11-2024",
                "referenceID": " ",
                "valueDate": "2024-11-26T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1275933,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "11/26/2024 ",
                "valueDateString": "11/26/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  76",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "27/11/2024 01:35:40"
              },
              {
                "tranID": "S24390228",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-26T00:00:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": "AppOlaolu To UBA OYEWUMI JOSEPH OLAYEMI",
                "referenceID": "NIP/241126115114003567191854",
                "valueDate": "2024-11-26T00:00:00",
                "reference": "082/4843257020/001483535340",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1280933,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "11/26/2024 ",
                "valueDateString": "11/26/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "27/11/2024 01:35:40"
              },
              {
                "tranID": "S24390238",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-26T00:00:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-AppOlaolu To UBA OYEWUMI JOSEPH",
                "referenceID": "NIP/241126115114003567191854",
                "valueDate": "2024-11-26T00:00:00",
                "reference": "082/4843257020/001483535340",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1280943.75,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "11/26/2024 ",
                "valueDateString": "11/26/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "27/11/2024 01:35:40"
              },
              {
                "tranID": "S24394655",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-26T00:00:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": "AppOlaolu To United Bank for Africa FELICIA  A",
                "referenceID": "NIP/241126115225003567194715",
                "valueDate": "2024-11-26T00:00:00",
                "reference": "082/4843257020/001483538135",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1285943.75,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "11/26/2024 ",
                "valueDateString": "11/26/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "27/11/2024 01:35:40"
              },
              {
                "tranID": "S24394669",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-26T00:00:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-AppOlaolu To United Bank for Af",
                "referenceID": "NIP/241126115225003567194715",
                "valueDate": "2024-11-26T00:00:00",
                "reference": "082/4843257020/001483538135",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1285954.5,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "11/26/2024 ",
                "valueDateString": "11/26/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "27/11/2024 01:35:40"
              },
              {
                "tranID": "S27491162",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-26T00:00:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": "TRF to BALOGUN ZAINAB OMOLARA/App:fund To FCMB BAL",
                "referenceID": "PPL",
                "valueDate": "2024-11-26T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1290954.5,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "11/26/2024 ",
                "valueDateString": "11/26/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "27/11/2024 01:35:40"
              },
              {
                "tranID": "S27774417",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-27T00:00:00",
                "partTranType": "D",
                "tranAmount": 15000,
                "tranParticular": "TRF to OCTAVE MUSIC CREATIVE NG/App:savings To FCM",
                "referenceID": "PPL",
                "valueDate": "2024-11-26T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1305954.5,
                "withdrawls": 15000,
                "deposit": 0,
                "tranDateString": "11/27/2024 ",
                "valueDateString": "11/26/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "28/11/2024 00:15:15"
              },
              {
                "tranID": "S28102490",
                "accountName": "KEVIN FAITHMARY CHINASA",
                "customerCode": null,
                "tranDate": "2024-11-27T00:00:00",
                "partTranType": "D",
                "tranAmount": 264,
                "tranParticular": "SMS ALERT CHARGES FROM 26TH OCT TO 24TH NOV 2024",
                "referenceID": " ",
                "valueDate": "2024-11-27T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1306218.5,
                "withdrawls": 264,
                "deposit": 0,
                "tranDateString": "11/27/2024 ",
                "valueDateString": "11/27/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  84",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "28/11/2024 00:15:15"
              },
              {
                "tranID": "S29519248",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-27T00:00:00",
                "partTranType": "D",
                "tranAmount": 10600,
                "tranParticular": "App To GTBank Plc OYEWUMI OLAOLU AMOS",
                "referenceID": "NIP/241127105432003570015427",
                "valueDate": "2024-11-27T00:00:00",
                "reference": "082/4843257020/001486272139",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1316818.5,
                "withdrawls": 10600,
                "deposit": 0,
                "tranDateString": "11/27/2024 ",
                "valueDateString": "11/27/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "28/11/2024 00:15:15"
              },
              {
                "tranID": "S29519272",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-27T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-App To GTBank Plc OYEWUMI OLAOL",
                "referenceID": "NIP/241127105432003570015427",
                "valueDate": "2024-11-27T00:00:00",
                "reference": "082/4843257020/001486272139",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1316845.38,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "11/27/2024 ",
                "valueDateString": "11/27/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "28/11/2024 00:15:15"
              },
              {
                "tranID": "S29638317",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-27T00:00:00",
                "partTranType": "D",
                "tranAmount": 10000,
                "tranParticular": "TRF to PENIEL GLEAM GLOBAL RESOURCES/App:Dec contr",
                "referenceID": "PPL",
                "valueDate": "2024-11-27T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1326845.38,
                "withdrawls": 10000,
                "deposit": 0,
                "tranDateString": "11/27/2024 ",
                "valueDateString": "11/27/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "28/11/2024 00:15:15"
              },
              {
                "tranID": "S30209300",
                "accountName": "OBANUBI OYINDAMOLA RUTH",
                "customerCode": null,
                "tranDate": "2024-11-27T00:00:00",
                "partTranType": "C",
                "tranAmount": 10000,
                "tranParticular": "TRF From OBANUBI OYINDAMOLA RUTH/App: To FCMB OYEW",
                "referenceID": "PPL",
                "valueDate": "2024-11-27T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1316845.38,
                "withdrawls": 0,
                "deposit": 10000,
                "tranDateString": "11/27/2024 ",
                "valueDateString": "11/27/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "28/11/2024 00:15:15"
              },
              {
                "tranID": "S30697933",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-27T00:00:00",
                "partTranType": "D",
                "tranAmount": 30000,
                "tranParticular": "TRF to DE MARTINS CONCEPT APPARELS/App:initial bal",
                "referenceID": "PPL",
                "valueDate": "2024-11-27T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1346845.38,
                "withdrawls": 30000,
                "deposit": 0,
                "tranDateString": "11/27/2024 ",
                "valueDateString": "11/27/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "28/11/2024 00:15:15"
              },
              {
                "tranID": "S30987607",
                "accountName": "ARUBUOLA OLUROTIMI SOLOMON",
                "customerCode": null,
                "tranDate": "2024-11-27T00:00:00",
                "partTranType": "C",
                "tranAmount": 50000,
                "tranParticular": "TRF From ARUBUOLA OLUROTIMI SOLOMON/App:for Alfosc",
                "referenceID": "PPL",
                "valueDate": "2024-11-27T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1296845.38,
                "withdrawls": 0,
                "deposit": 50000,
                "tranDateString": "11/27/2024 ",
                "valueDateString": "11/27/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "28/11/2024 00:15:15"
              },
              {
                "tranID": "S31800275",
                "accountName": "WAVELENGTH MULTI-RESOURCES NG",
                "customerCode": null,
                "tranDate": "2024-11-27T00:00:00",
                "partTranType": "C",
                "tranAmount": 50000,
                "tranParticular": "TRF From WAVELENGTH MULTI-RESOURCES NG/App To FCMB",
                "referenceID": "PPL",
                "valueDate": "2024-11-27T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1246845.38,
                "withdrawls": 0,
                "deposit": 50000,
                "tranDateString": "11/27/2024 ",
                "valueDateString": "11/27/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "28/11/2024 00:15:15"
              },
              {
                "tranID": "S31810818",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-27T00:00:00",
                "partTranType": "D",
                "tranAmount": 50000,
                "tranParticular": "TRF to LIZTECH INNOVATION HUB/App:initial balance",
                "referenceID": "PPL",
                "valueDate": "2024-11-27T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1296845.38,
                "withdrawls": 50000,
                "deposit": 0,
                "tranDateString": "11/27/2024 ",
                "valueDateString": "11/27/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "28/11/2024 00:15:15"
              },
              {
                "tranID": "S32024190",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-27T00:00:00",
                "partTranType": "D",
                "tranAmount": 30000,
                "tranParticular": "Appfund To ACCESS BANK PLC DIAMOND EKEKWE OGEC",
                "referenceID": "NIP/241127180532003571316323",
                "valueDate": "2024-11-27T00:00:00",
                "reference": "082/4843257020/001487590217",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1326845.38,
                "withdrawls": 30000,
                "deposit": 0,
                "tranDateString": "11/27/2024 ",
                "valueDateString": "11/27/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "28/11/2024 00:15:15"
              },
              {
                "tranID": "S32024311",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-27T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appfund To ACCESS BANK PLC DIAM",
                "referenceID": "NIP/241127180532003571316323",
                "valueDate": "2024-11-27T00:00:00",
                "reference": "082/4843257020/001487590217",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1326872.26,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "11/27/2024 ",
                "valueDateString": "11/27/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "28/11/2024 00:15:15"
              },
              {
                "tranID": "S33173942",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-27T00:00:00",
                "partTranType": "D",
                "tranAmount": 6000,
                "tranParticular": "Appfund To StanbicIBTC Bank UBONG AUGUSTINE AK",
                "referenceID": "NIP/241127212622003571845925",
                "valueDate": "2024-11-27T00:00:00",
                "reference": "082/4843257020/001488053331",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1332872.26,
                "withdrawls": 6000,
                "deposit": 0,
                "tranDateString": "11/27/2024 ",
                "valueDateString": "11/27/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "28/11/2024 00:15:15"
              },
              {
                "tranID": "S33173956",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-27T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appfund To StanbicIBTC Bank UBO",
                "referenceID": "NIP/241127212622003571845925",
                "valueDate": "2024-11-27T00:00:00",
                "reference": "082/4843257020/001488053331",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1332899.14,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "11/27/2024 ",
                "valueDateString": "11/27/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "28/11/2024 00:15:15"
              },
              {
                "tranID": "S33559369",
                "accountName": "FOLORUNSHO  GAFARU  SOFOLUWE",
                "customerCode": null,
                "tranDate": "2024-11-28T00:00:00",
                "partTranType": "D",
                "tranAmount": 150,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 27-11-2024",
                "referenceID": " ",
                "valueDate": "2024-11-28T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1333049.14,
                "withdrawls": 150,
                "deposit": 0,
                "tranDateString": "11/28/2024 ",
                "valueDateString": "11/28/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  21",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "28/11/2024 10:27:30"
              },
              {
                "tranID": "S33736827",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-28T00:00:00",
                "partTranType": "D",
                "tranAmount": 4,
                "tranParticular": "AUTHENTICATION CODE CHARGE Outstanding OCT 2024",
                "referenceID": " ",
                "valueDate": "2024-11-28T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1333053.14,
                "withdrawls": 4,
                "deposit": 0,
                "tranDateString": "11/28/2024 ",
                "valueDateString": "11/28/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "28/11/2024 10:27:30"
              },
              {
                "tranID": "S35403307",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-11-28T00:00:00",
                "partTranType": "C",
                "tranAmount": 461500,
                "tranParticular": "NIP FRM OLUSANYA YEWANDE ISABELLE-NIP TRANSFER",
                "referenceID": "NIP/241128112758000178387937",
                "valueDate": "2024-11-28T00:00:00",
                "reference": "001/4843257020/001489077943",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -871553.14,
                "withdrawls": 0,
                "deposit": 461500,
                "tranDateString": "11/28/2024 ",
                "valueDateString": "11/28/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "28/11/2024 11:31:24"
              },
              {
                "tranID": "S39230640",
                "accountName": "AKINMUYIWA MICAIAH",
                "customerCode": null,
                "tranDate": "2024-11-29T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 28-11-2024",
                "referenceID": " ",
                "valueDate": "2024-11-29T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -871603.14,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "11/29/2024 ",
                "valueDateString": "11/29/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  25",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "29/11/2024 09:21:12"
              },
              {
                "tranID": "S39825332",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-29T00:00:00",
                "partTranType": "D",
                "tranAmount": 4000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-11-29T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -875603.14,
                "withdrawls": 4000,
                "deposit": 0,
                "tranDateString": "11/29/2024 ",
                "valueDateString": "11/29/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "29/11/2024 09:21:12"
              },
              {
                "tranID": "S40471979",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-29T00:00:00",
                "partTranType": "D",
                "tranAmount": 16000,
                "tranParticular": "TRF to OYEWUMI VICTORIOUS FAMILY/App:mum & dad up",
                "referenceID": "PPL",
                "valueDate": "2024-11-29T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -891603.14,
                "withdrawls": 16000,
                "deposit": 0,
                "tranDateString": "11/29/2024 ",
                "valueDateString": "11/29/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "29/11/2024 10:36:01"
              },
              {
                "tranID": "S40487295",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-29T00:00:00",
                "partTranType": "D",
                "tranAmount": 461225,
                "tranParticular": "TRF to WAVELENGTH MULTI-RESOURCES NG/App: To FCMB",
                "referenceID": "PPL",
                "valueDate": "2024-11-29T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1352828.14,
                "withdrawls": 461225,
                "deposit": 0,
                "tranDateString": "11/29/2024 ",
                "valueDateString": "11/29/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "29/11/2024 10:36:01"
              },
              {
                "tranID": "S44247498",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-29T00:00:00",
                "partTranType": "D",
                "tranAmount": 1950,
                "tranParticular": " App:fund To Opay Digital Services Limited ABI",
                "referenceID": "FTN025007160645/4843257020",
                "valueDate": "2024-11-29T00:00:00",
                "reference": "ETRNZ|868904301503",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1354778.14,
                "withdrawls": 1950,
                "deposit": 0,
                "tranDateString": "11/29/2024 ",
                "valueDateString": "11/29/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "29/11/2024 19:55:32"
              },
              {
                "tranID": "S44247607",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-29T00:00:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App:fund To Opay Digital Servic",
                "referenceID": "FTN025007160645/4843257020",
                "valueDate": "2024-11-29T00:00:00",
                "reference": "ETRNZ|868904301503",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1354788.89,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "11/29/2024 ",
                "valueDateString": "11/29/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "29/11/2024 19:55:32"
              },
              {
                "tranID": "S45216120",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-30T00:00:00",
                "partTranType": "D",
                "tranAmount": 1200,
                "tranParticular": " App:Olaolu To EcoBank FRANKLIN UWADIEGWU MARS",
                "referenceID": "FTN025007210365/4843257020",
                "valueDate": "2024-11-30T00:00:00",
                "reference": "ETRNZ|349567874092",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1355988.89,
                "withdrawls": 1200,
                "deposit": 0,
                "tranDateString": "11/30/2024 ",
                "valueDateString": "11/30/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "30/11/2024 10:53:44"
              },
              {
                "tranID": "S45216126",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-30T00:00:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App:Olaolu To EcoBank FRANKLIN",
                "referenceID": "FTN025007210365/4843257020",
                "valueDate": "2024-11-30T00:00:00",
                "reference": "ETRNZ|349567874092",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1355999.64,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "11/30/2024 ",
                "valueDateString": "11/30/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "30/11/2024 10:53:44"
              },
              {
                "tranID": "S45641126",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-11-30T00:00:00",
                "partTranType": "C",
                "tranAmount": 405000,
                "tranParticular": "NIP FRM OLUWATOYIN GRACE  ADEOYE-Good",
                "referenceID": "NIP/241130070727177493702003",
                "valueDate": "2024-11-30T00:00:00",
                "reference": "001/4843257020/001493832037",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -950999.64,
                "withdrawls": 0,
                "deposit": 405000,
                "tranDateString": "11/30/2024 ",
                "valueDateString": "11/30/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "30/11/2024 17:37:10"
              },
              {
                "tranID": "S46420848",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-30T00:00:00",
                "partTranType": "D",
                "tranAmount": 1650,
                "tranParticular": " App:fund To Palmpay OLUWATOYIN BOLA OSONOWO",
                "referenceID": "FTN025007278669/4843257020",
                "valueDate": "2024-11-30T00:00:00",
                "reference": "ETRNZ|829986576757",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -952649.64,
                "withdrawls": 1650,
                "deposit": 0,
                "tranDateString": "11/30/2024 ",
                "valueDateString": "11/30/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "30/11/2024 10:53:44"
              },
              {
                "tranID": "S46420866",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-11-30T00:00:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App:fund To Palmpay OLUWATOYIN",
                "referenceID": "FTN025007278669/4843257020",
                "valueDate": "2024-11-30T00:00:00",
                "reference": "ETRNZ|829986576757",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -952660.39,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "11/30/2024 ",
                "valueDateString": "11/30/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "30/11/2024 10:53:44"
              },
              {
                "tranID": "S51153559",
                "accountName": "OLUTUM CHUKWUMA",
                "customerCode": null,
                "tranDate": "2024-12-01T10:03:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 30-11-2024",
                "referenceID": " ",
                "valueDate": "2024-12-01T10:03:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -952710.39,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "12/01/2024 10:03 AM",
                "valueDateString": "12/01/2024 10:03 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  11",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "01/12/2024 10:03:58"
              },
              {
                "tranID": "S51613251",
                "accountName": "OGUNLEYE DASOLA",
                "customerCode": null,
                "tranDate": "2024-12-01T16:22:00",
                "partTranType": "C",
                "tranAmount": 5000,
                "tranParticular": "TRF From OGUNLEYE DASOLA/App:card To FCMB OYE",
                "referenceID": "PPL",
                "valueDate": "2024-12-01T16:22:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -947710.39,
                "withdrawls": 0,
                "deposit": 5000,
                "tranDateString": "12/01/2024 04:22 PM",
                "valueDateString": "12/01/2024 04:22 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "01/12/2024 16:22:40"
              },
              {
                "tranID": "S52724142",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-01T11:28:00",
                "partTranType": "D",
                "tranAmount": 405000,
                "tranParticular": "TRF to WAVELENGTH MULTI-RESOURCES NG/App: To FCMB",
                "referenceID": "PPL",
                "valueDate": "2024-12-01T11:28:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1352710.39,
                "withdrawls": 405000,
                "deposit": 0,
                "tranDateString": "12/01/2024 11:28 AM",
                "valueDateString": "12/01/2024 11:28 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "01/12/2024 11:28:32"
              },
              {
                "tranID": "S53063824",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-01T12:53:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-12-01T12:53:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1357710.39,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "12/01/2024 12:53 PM",
                "valueDateString": "12/01/2024 12:53 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "01/12/2024 12:53:24"
              },
              {
                "tranID": "S55561599",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-02T10:28:00",
                "partTranType": "D",
                "tranAmount": 2900,
                "tranParticular": " App:fund To Zenith Bank 68 OFFICERS MESS WINE",
                "referenceID": "FTN025007858875/4843257020",
                "valueDate": "2024-12-01T10:28:00",
                "reference": "ETRNZ|069539705800",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1360610.39,
                "withdrawls": 2900,
                "deposit": 0,
                "tranDateString": "12/02/2024 10:28 AM",
                "valueDateString": "12/01/2024 10:28 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "02/12/2024 10:28:19"
              },
              {
                "tranID": "S55561707",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-02T10:28:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App:fund To Zenith Bank 68 OFFI",
                "referenceID": "FTN025007858875/4843257020",
                "valueDate": "2024-12-02T10:28:00",
                "reference": "ETRNZ|069539705800",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1360621.14,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "12/02/2024 10:28 AM",
                "valueDateString": "12/02/2024 10:28 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "02/12/2024 10:28:19"
              },
              {
                "tranID": "S63249943",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-03T11:17:00",
                "partTranType": "D",
                "tranAmount": 200,
                "tranParticular": "QR/Q22777815377/Airtime/MTN/2347032819698",
                "referenceID": "USSD",
                "valueDate": "2024-12-03T11:17:00",
                "reference": "USSD/Q22777815377",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1360821.14,
                "withdrawls": 200,
                "deposit": 0,
                "tranDateString": "12/03/2024 11:17 AM",
                "valueDateString": "12/03/2024 11:17 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "03/12/2024 11:17:13"
              },
              {
                "tranID": "S65604849",
                "accountName": "FUND TRANSFER TSS RECEIVALE A/C",
                "customerCode": null,
                "tranDate": "2024-12-03T20:52:00",
                "partTranType": "C",
                "tranAmount": 5000,
                "tranParticular": "Mbanking Trf: FCMB/002182006813;;AT124_TRF|2MPT273",
                "referenceID": "FCMB MOBILE",
                "valueDate": "2024-12-03T20:52:00",
                "reference": "4843257020/2182006813/637616/E",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1355821.14,
                "withdrawls": 0,
                "deposit": 5000,
                "tranDateString": "12/03/2024 08:52 PM",
                "valueDateString": "12/03/2024 08:52 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "03/12/2024 20:52:24"
              },
              {
                "tranID": "S66297665",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-03T19:04:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-12-03T19:04:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1360821.14,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "12/03/2024 07:04 PM",
                "valueDateString": "12/03/2024 07:04 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "03/12/2024 19:04:06"
              },
              {
                "tranID": "S76285364",
                "accountName": "HASSAN DAMILOLA MUINAT",
                "customerCode": null,
                "tranDate": "2024-12-05T13:06:00",
                "partTranType": "C",
                "tranAmount": 10000,
                "tranParticular": "TRF From HASSAN DAMILOLA MUINAT/App: To FCMB OYEWU",
                "referenceID": "PPL",
                "valueDate": "2024-12-05T13:06:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1350821.14,
                "withdrawls": 0,
                "deposit": 10000,
                "tranDateString": "12/05/2024 01:06 PM",
                "valueDateString": "12/05/2024 01:06 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "05/12/2024 13:06:16"
              },
              {
                "tranID": "S78797724",
                "accountName": "WAVELENGTH MULTI-RESOURCES NG",
                "customerCode": null,
                "tranDate": "2024-12-05T19:08:00",
                "partTranType": "C",
                "tranAmount": 60000,
                "tranParticular": "TRF From WAVELENGTH MULTI-RESOURCES NG/App To FCMB",
                "referenceID": "PPL",
                "valueDate": "2024-12-05T19:08:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1290821.14,
                "withdrawls": 0,
                "deposit": 60000,
                "tranDateString": "12/05/2024 07:08 PM",
                "valueDateString": "12/05/2024 07:08 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "05/12/2024 19:08:29"
              },
              {
                "tranID": "S79148410",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-05T00:42:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": " App:for card To Keystone Bank DURU OLIVE NNEK",
                "referenceID": "FTN025009204083/4843257020",
                "valueDate": "2024-12-05T00:42:00",
                "reference": "ETRNZ|828137501792",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1295821.14,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "12/05/2024 12:42 AM",
                "valueDateString": "12/05/2024 12:42 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "06/12/2024 00:42:33"
              },
              {
                "tranID": "S79148451",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-05T00:42:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App:for card To Keystone Bank D",
                "referenceID": "FTN025009204083/4843257020",
                "valueDate": "2024-12-05T00:42:00",
                "reference": "ETRNZ|828137501792",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1295831.89,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "12/05/2024 12:42 AM",
                "valueDateString": "12/05/2024 12:42 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "06/12/2024 00:42:33"
              },
              {
                "tranID": "S79570530",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-05T00:42:00",
                "partTranType": "D",
                "tranAmount": 1500,
                "tranParticular": "Appfund To First Bank of Nigeria OMERI IFEYINW",
                "referenceID": "NIP/241205211801003595703431",
                "valueDate": "2024-12-05T00:42:00",
                "reference": "082/4843257020/001510589049",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1297331.89,
                "withdrawls": 1500,
                "deposit": 0,
                "tranDateString": "12/05/2024 12:42 AM",
                "valueDateString": "12/05/2024 12:42 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "06/12/2024 00:42:33"
              },
              {
                "tranID": "S79570549",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-05T00:42:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-Appfund To First Bank of Nigeri",
                "referenceID": "NIP/241205211801003595703431",
                "valueDate": "2024-12-05T00:42:00",
                "reference": "082/4843257020/001510589049",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1297342.64,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "12/05/2024 12:42 AM",
                "valueDateString": "12/05/2024 12:42 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "06/12/2024 00:42:33"
              },
              {
                "tranID": "S79595550",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-05T00:42:00",
                "partTranType": "D",
                "tranAmount": 1200,
                "tranParticular": " App:fund To Zenith Bank 68 OFFICERS MESS WINE",
                "referenceID": "FTN025009220691/4843257020",
                "valueDate": "2024-12-05T00:42:00",
                "reference": "ETRNZ|529537258575",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1298542.64,
                "withdrawls": 1200,
                "deposit": 0,
                "tranDateString": "12/05/2024 12:42 AM",
                "valueDateString": "12/05/2024 12:42 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "06/12/2024 00:42:33"
              },
              {
                "tranID": "S79595565",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-05T00:42:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App:fund To Zenith Bank 68 OFFI",
                "referenceID": "FTN025009220691/4843257020",
                "valueDate": "2024-12-05T00:42:00",
                "reference": "ETRNZ|529537258575",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1298553.39,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "12/05/2024 12:42 AM",
                "valueDateString": "12/05/2024 12:42 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "06/12/2024 00:42:33"
              },
              {
                "tranID": "S80018082",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-06T09:21:00",
                "partTranType": "D",
                "tranAmount": 50000,
                "tranParticular": "TRF to OCTAVE MUSIC CREATIVE NG/App:savings To FCM",
                "referenceID": "PPL",
                "valueDate": "2024-12-06T09:21:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1348553.39,
                "withdrawls": 50000,
                "deposit": 0,
                "tranDateString": "12/06/2024 09:21 AM",
                "valueDateString": "12/06/2024 09:21 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "06/12/2024 09:21:04"
              },
              {
                "tranID": "S82891834",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-12-06T20:54:00",
                "partTranType": "C",
                "tranAmount": 60000,
                "tranParticular": "NIP FRM OMEH HYCENTH-FBNMOBILEOYEWUMI OLAOLU A",
                "referenceID": "NIP/241206135241000044223667",
                "valueDate": "2024-12-06T20:54:00",
                "reference": "001/4843257020/001512175749",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1288553.39,
                "withdrawls": 0,
                "deposit": 60000,
                "tranDateString": "12/06/2024 08:54 PM",
                "valueDateString": "12/06/2024 08:54 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "06/12/2024 20:54:55"
              },
              {
                "tranID": "S86269080",
                "accountName": "ARCHIBONG BLESSING",
                "customerCode": null,
                "tranDate": "2024-12-08T10:49:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 06-12-2024",
                "referenceID": " ",
                "valueDate": "2024-12-08T10:49:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1288603.39,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "12/08/2024 10:49 AM",
                "valueDateString": "12/08/2024 10:49 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  60",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "07/12/2024 10:49:10"
              },
              {
                "tranID": "S89333901",
                "accountName": "KAY YACHT CRUISE",
                "customerCode": null,
                "tranDate": "2024-12-08T15:06:00",
                "partTranType": "C",
                "tranAmount": 20000,
                "tranParticular": "KAY YACHT CRUISE/OYEWUMI OLAOLU AMOS",
                "referenceID": "WSB",
                "valueDate": "2024-12-07T15:06:00",
                "reference": "001515480041",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1268603.39,
                "withdrawls": 0,
                "deposit": 20000,
                "tranDateString": "12/08/2024 03:06 PM",
                "valueDateString": "12/07/2024 03:06 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "07/12/2024 15:06:40"
              },
              {
                "tranID": "S90148684",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-08T16:49:00",
                "partTranType": "D",
                "tranAmount": 6000,
                "tranParticular": "Appfund To Opay Digital Services Limited OLAWA",
                "referenceID": "NIP/241207164745003600939366",
                "valueDate": "2024-12-07T16:49:00",
                "reference": "082/4843257020/001515900465",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1274603.39,
                "withdrawls": 6000,
                "deposit": 0,
                "tranDateString": "12/08/2024 04:49 PM",
                "valueDateString": "12/07/2024 04:49 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "07/12/2024 16:49:40"
              },
              {
                "tranID": "S90148806",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-08T16:49:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appfund To Opay Digital Service",
                "referenceID": "NIP/241207164745003600939366",
                "valueDate": "2024-12-08T16:49:00",
                "reference": "082/4843257020/001515900465",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1274630.27,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "12/08/2024 04:49 PM",
                "valueDateString": "12/08/2024 04:49 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "07/12/2024 16:49:40"
              },
              {
                "tranID": "S91315009",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-08T19:43:00",
                "partTranType": "D",
                "tranAmount": 6000,
                "tranParticular": "TRF to OYEWUNMI IBUKUN DAVID/App: To First City M",
                "referenceID": "PPL",
                "valueDate": "2024-12-07T19:43:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1280630.27,
                "withdrawls": 6000,
                "deposit": 0,
                "tranDateString": "12/08/2024 07:43 PM",
                "valueDateString": "12/07/2024 07:43 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "07/12/2024 19:43:05"
              },
              {
                "tranID": "S91529424",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-08T10:29:00",
                "partTranType": "D",
                "tranAmount": 10000,
                "tranParticular": "Appads To MONIEPOINT MICROFINANCE BANK BB 1ST",
                "referenceID": "NIP/241207201752003601533186",
                "valueDate": "2024-12-07T10:29:00",
                "reference": "082/4843257020/001516580227",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1290630.27,
                "withdrawls": 10000,
                "deposit": 0,
                "tranDateString": "12/08/2024 10:29 AM",
                "valueDateString": "12/07/2024 10:29 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "08/12/2024 10:29:15"
              },
              {
                "tranID": "S91529434",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-08T10:29:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appads To MONIEPOINT MICROFINAN",
                "referenceID": "NIP/241207201752003601533186",
                "valueDate": "2024-12-08T10:29:00",
                "reference": "082/4843257020/001516580227",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1290657.15,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "12/08/2024 10:29 AM",
                "valueDateString": "12/08/2024 10:29 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "08/12/2024 10:29:15"
              },
              {
                "tranID": "S91940235",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-08T10:29:00",
                "partTranType": "D",
                "tranAmount": 6750,
                "tranParticular": "Appfund To EcoBank AWOFODU AYOOLA GRACE",
                "referenceID": "NIP/241207215510003601722553",
                "valueDate": "2024-12-07T10:29:00",
                "reference": "082/4843257020/001516801389",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1297407.15,
                "withdrawls": 6750,
                "deposit": 0,
                "tranDateString": "12/08/2024 10:29 AM",
                "valueDateString": "12/07/2024 10:29 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "08/12/2024 10:29:15"
              },
              {
                "tranID": "S91940243",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-08T10:29:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appfund To EcoBank AWOFODU AYOO",
                "referenceID": "NIP/241207215510003601722553",
                "valueDate": "2024-12-08T10:29:00",
                "reference": "082/4843257020/001516801389",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1297434.03,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "12/08/2024 10:29 AM",
                "valueDateString": "12/08/2024 10:29 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "08/12/2024 10:29:15"
              },
              {
                "tranID": "S92720790",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-08T10:29:00",
                "partTranType": "D",
                "tranAmount": 8000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-12-08T10:29:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1305434.03,
                "withdrawls": 8000,
                "deposit": 0,
                "tranDateString": "12/08/2024 10:29 AM",
                "valueDateString": "12/08/2024 10:29 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "08/12/2024 10:29:15"
              },
              {
                "tranID": "S96092507",
                "accountName": "BARWA EMMANUEL",
                "customerCode": null,
                "tranDate": "2024-12-09T10:04:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 08-12-2024",
                "referenceID": " ",
                "valueDate": "2024-12-09T10:04:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1305484.03,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "12/09/2024 10:04 AM",
                "valueDateString": "12/09/2024 10:04 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  46",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "09/12/2024 10:04:10"
              },
              {
                "tranID": "S96362794",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-09T10:04:00",
                "partTranType": "D",
                "tranAmount": 200,
                "tranParticular": "QR/Q22795276661/Airtime/MTN/2347032819698",
                "referenceID": "USSD",
                "valueDate": "2024-12-08T10:04:00",
                "reference": "USSD/Q22795276661",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1305684.03,
                "withdrawls": 200,
                "deposit": 0,
                "tranDateString": "12/09/2024 10:04 AM",
                "valueDateString": "12/08/2024 10:04 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "09/12/2024 10:04:10"
              },
              {
                "tranID": "S96414339",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-09T10:04:00",
                "partTranType": "D",
                "tranAmount": 2200,
                "tranParticular": " App:fund To Palmpay LATEEFAT TENIOLA TAIWO",
                "referenceID": "FTN025010225327/4843257020",
                "valueDate": "2024-12-08T10:04:00",
                "reference": "ETRNZ|009973504523",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1307884.03,
                "withdrawls": 2200,
                "deposit": 0,
                "tranDateString": "12/09/2024 10:04 AM",
                "valueDateString": "12/08/2024 10:04 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "09/12/2024 10:04:10"
              },
              {
                "tranID": "S96414345",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-09T10:04:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App:fund To Palmpay LATEEFAT TE",
                "referenceID": "FTN025010225327/4843257020",
                "valueDate": "2024-12-09T10:04:00",
                "reference": "ETRNZ|009973504523",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1307894.78,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "12/09/2024 10:04 AM",
                "valueDateString": "12/09/2024 10:04 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "09/12/2024 10:04:10"
              },
              {
                "tranID": "S96569791",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-09T10:04:00",
                "partTranType": "D",
                "tranAmount": 2000,
                "tranParticular": "Appfund To Zenith Bank 68 OFFICERS MESS WINES",
                "referenceID": "NIP/241208223529003603807540",
                "valueDate": "2024-12-08T10:04:00",
                "reference": "082/4843257020/001519102661",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1309894.78,
                "withdrawls": 2000,
                "deposit": 0,
                "tranDateString": "12/09/2024 10:04 AM",
                "valueDateString": "12/08/2024 10:04 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "09/12/2024 10:04:10"
              },
              {
                "tranID": "S96569798",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-09T10:04:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-Appfund To Zenith Bank 68 OFFIC",
                "referenceID": "NIP/241208223529003603807540",
                "valueDate": "2024-12-09T10:04:00",
                "reference": "082/4843257020/001519102661",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1309905.53,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "12/09/2024 10:04 AM",
                "valueDateString": "12/09/2024 10:04 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "09/12/2024 10:04:10"
              },
              {
                "tranID": " S3450998",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-10T10:55:00",
                "partTranType": "D",
                "tranAmount": 661.25,
                "tranParticular": " App:reservation To Fidelity Bank Remita Check",
                "referenceID": "FTN025010679526/4843257020",
                "valueDate": "2024-12-10T10:55:00",
                "reference": "ETRNZ|573675814466",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1310566.78,
                "withdrawls": 661.25,
                "deposit": 0,
                "tranDateString": "12/10/2024 10:55 AM",
                "valueDateString": "12/10/2024 10:55 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "10/12/2024 10:55:29"
              },
              {
                "tranID": " S3451120",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-10T10:55:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App:reservation To Fidelity Ban",
                "referenceID": "FTN025010679526/4843257020",
                "valueDate": "2024-12-10T10:55:00",
                "reference": "ETRNZ|573675814466",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1310577.53,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "12/10/2024 10:55 AM",
                "valueDateString": "12/10/2024 10:55 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "10/12/2024 10:55:29"
              },
              {
                "tranID": " S3451585",
                "accountName": "ETRANZACT SWITCH SUSPENSE ACCT",
                "customerCode": null,
                "tranDate": "2024-12-10T10:55:00",
                "partTranType": "C",
                "tranAmount": 661.25,
                "tranParticular": " Rev573675814466",
                "referenceID": "FTN025010679526/4843257020",
                "valueDate": "2024-12-10T10:55:00",
                "reference": "ETRNZ|662419262736",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1309916.28,
                "withdrawls": 0,
                "deposit": 661.25,
                "tranDateString": "12/10/2024 10:55 AM",
                "valueDateString": "12/10/2024 10:55 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "10/12/2024 10:55:29"
              },
              {
                "tranID": " S3451706",
                "accountName": "E TRANSACT PAYABLE POS",
                "customerCode": null,
                "tranDate": "2024-12-10T10:55:00",
                "partTranType": "C",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-Rev573675814466",
                "referenceID": " ",
                "valueDate": "2024-12-10T10:55:00",
                "reference": "ETRNZ|662419262736",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1309905.53,
                "withdrawls": 0,
                "deposit": 10.75,
                "tranDateString": "12/10/2024 10:55 AM",
                "valueDateString": "12/10/2024 10:55 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   4",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "10/12/2024 10:55:29"
              },
              {
                "tranID": " S3498665",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-10T10:55:00",
                "partTranType": "D",
                "tranAmount": 661.25,
                "tranParticular": " App:reservation To Fidelity Bank Remita Check",
                "referenceID": "FTN025010681136/4843257020",
                "valueDate": "2024-12-10T10:55:00",
                "reference": "ETRNZ|399316251550",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1310566.78,
                "withdrawls": 661.25,
                "deposit": 0,
                "tranDateString": "12/10/2024 10:55 AM",
                "valueDateString": "12/10/2024 10:55 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "10/12/2024 10:55:29"
              },
              {
                "tranID": " S3498676",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-10T10:55:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App:reservation To Fidelity Ban",
                "referenceID": "FTN025010681136/4843257020",
                "valueDate": "2024-12-10T10:55:00",
                "reference": "ETRNZ|399316251550",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1310577.53,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "12/10/2024 10:55 AM",
                "valueDateString": "12/10/2024 10:55 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "10/12/2024 10:55:29"
              },
              {
                "tranID": " S3499205",
                "accountName": "ETRANZACT SWITCH SUSPENSE ACCT",
                "customerCode": null,
                "tranDate": "2024-12-10T10:55:00",
                "partTranType": "C",
                "tranAmount": 661.25,
                "tranParticular": " Rev399316251550",
                "referenceID": "FTN025010681136/4843257020",
                "valueDate": "2024-12-10T10:55:00",
                "reference": "ETRNZ|129438201610",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1309916.28,
                "withdrawls": 0,
                "deposit": 661.25,
                "tranDateString": "12/10/2024 10:55 AM",
                "valueDateString": "12/10/2024 10:55 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "10/12/2024 10:55:29"
              },
              {
                "tranID": " S3499217",
                "accountName": "E TRANSACT PAYABLE POS",
                "customerCode": null,
                "tranDate": "2024-12-10T10:55:00",
                "partTranType": "C",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-Rev399316251550",
                "referenceID": " ",
                "valueDate": "2024-12-10T10:55:00",
                "reference": "ETRNZ|129438201610",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1309905.53,
                "withdrawls": 0,
                "deposit": 10.75,
                "tranDateString": "12/10/2024 10:55 AM",
                "valueDateString": "12/10/2024 10:55 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   4",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "10/12/2024 10:55:29"
              },
              {
                "tranID": " S8103854",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-12-11T10:53:00",
                "partTranType": "C",
                "tranAmount": 15000,
                "tranParticular": "NIP FRM ADEDAYO MICHAEL FAMODIMU-Amark Homes l",
                "referenceID": "NIP/241211051132123559502712",
                "valueDate": "2024-12-11T10:53:00",
                "reference": "001/4843257020/001526104475",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1294905.53,
                "withdrawls": 0,
                "deposit": 15000,
                "tranDateString": "12/11/2024 10:53 AM",
                "valueDateString": "12/11/2024 10:53 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "11/12/2024 10:53:39"
              },
              {
                "tranID": " S9811196",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-11T11:58:00",
                "partTranType": "D",
                "tranAmount": 3500,
                "tranParticular": "Appfund To UBA OYEWUMI JOSEPH OLAYEMI",
                "referenceID": "NIP/241211115612003610598935",
                "valueDate": "2024-12-11T11:58:00",
                "reference": "082/4843257020/001527046915",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1298405.53,
                "withdrawls": 3500,
                "deposit": 0,
                "tranDateString": "12/11/2024 11:58 AM",
                "valueDateString": "12/11/2024 11:58 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "11/12/2024 11:58:21"
              },
              {
                "tranID": " S9811314",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-11T11:58:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-Appfund To UBA OYEWUMI JOSEPH O",
                "referenceID": "NIP/241211115612003610598935",
                "valueDate": "2024-12-11T11:58:00",
                "reference": "082/4843257020/001527046915",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1298416.28,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "12/11/2024 11:58 AM",
                "valueDateString": "12/11/2024 11:58 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "11/12/2024 11:58:21"
              },
              {
                "tranID": "S10628235",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-12-11T14:13:00",
                "partTranType": "C",
                "tranAmount": 30000,
                "tranParticular": "NIP FRM WAHAB ADETUNJI AJOSE-OYEWUMI OLAOLU AM",
                "referenceID": "NIP/241211141112888640702028",
                "valueDate": "2024-12-11T14:13:00",
                "reference": "001/4843257020/001527511317",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1268416.28,
                "withdrawls": 0,
                "deposit": 30000,
                "tranDateString": "12/11/2024 02:13 PM",
                "valueDateString": "12/11/2024 02:13 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "11/12/2024 14:13:22"
              },
              {
                "tranID": "S10915415",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-11T15:02:00",
                "partTranType": "D",
                "tranAmount": 661.25,
                "tranParticular": " App:reservation To Fidelity Bank Remita Check",
                "referenceID": "FTN025011032673/4843257020",
                "valueDate": "2024-12-11T15:02:00",
                "reference": "ETRNZ|130369774800",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1269077.53,
                "withdrawls": 661.25,
                "deposit": 0,
                "tranDateString": "12/11/2024 03:02 PM",
                "valueDateString": "12/11/2024 03:02 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "11/12/2024 15:02:50"
              },
              {
                "tranID": "S10915432",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-11T15:02:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App:reservation To Fidelity Ban",
                "referenceID": "FTN025011032673/4843257020",
                "valueDate": "2024-12-11T15:02:00",
                "reference": "ETRNZ|130369774800",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1269088.28,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "12/11/2024 03:02 PM",
                "valueDateString": "12/11/2024 03:02 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "11/12/2024 15:02:50"
              },
              {
                "tranID": "S13341895",
                "accountName": "COLLARWALL-A GLOBAL CONCEPTS",
                "customerCode": null,
                "tranDate": "2024-12-12T09:33:00",
                "partTranType": "D",
                "tranAmount": 100,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 11-12-2024",
                "referenceID": " ",
                "valueDate": "2024-12-12T09:33:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1269188.28,
                "withdrawls": 100,
                "deposit": 0,
                "tranDateString": "12/12/2024 09:33 AM",
                "valueDateString": "12/12/2024 09:33 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  16",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "12/12/2024 09:33:16"
              },
              {
                "tranID": "S14531032",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-12-12T11:24:00",
                "partTranType": "C",
                "tranAmount": 5000,
                "tranParticular": "NIP FRM FEYISAYO BANJO-Transfer from FEYISAYO",
                "referenceID": "NIP/241212084441123624539784",
                "valueDate": "2024-12-12T11:24:00",
                "reference": "001/4843257020/001529780931",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1264188.28,
                "withdrawls": 0,
                "deposit": 5000,
                "tranDateString": "12/12/2024 11:24 AM",
                "valueDateString": "12/12/2024 11:24 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "12/12/2024 11:24:56"
              },
              {
                "tranID": "S14585316",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-12T10:55:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": " App:fund To Keystone Bank DURU OLIVE NNEKA",
                "referenceID": "FTN025011238338/4843257020",
                "valueDate": "2024-12-12T10:55:00",
                "reference": "ETRNZ|942835414347",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1269188.28,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "12/12/2024 10:55 AM",
                "valueDateString": "12/12/2024 10:55 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "12/12/2024 10:55:12"
              },
              {
                "tranID": "S14585332",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-12T10:55:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App:fund To Keystone Bank DURU",
                "referenceID": "FTN025011238338/4843257020",
                "valueDate": "2024-12-12T10:55:00",
                "reference": "ETRNZ|942835414347",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1269199.03,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "12/12/2024 10:55 AM",
                "valueDateString": "12/12/2024 10:55 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "12/12/2024 10:55:12"
              },
              {
                "tranID": "S17543141",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-12T18:05:00",
                "partTranType": "D",
                "tranAmount": 200,
                "tranParticular": "QR/Q22805594550/Airtime/MTN/2347032819698",
                "referenceID": "USSD",
                "valueDate": "2024-12-12T18:05:00",
                "reference": "USSD/Q22805594550",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1269399.03,
                "withdrawls": 200,
                "deposit": 0,
                "tranDateString": "12/12/2024 06:05 PM",
                "valueDateString": "12/12/2024 06:05 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "12/12/2024 18:05:30"
              },
              {
                "tranID": "S17555602",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-12T18:08:00",
                "partTranType": "D",
                "tranAmount": 10000,
                "tranParticular": "TRF to PENIEL GLEAM GLOBAL RESOURCES/App:December",
                "referenceID": "PPL",
                "valueDate": "2024-12-12T18:08:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1279399.03,
                "withdrawls": 10000,
                "deposit": 0,
                "tranDateString": "12/12/2024 06:08 PM",
                "valueDateString": "12/12/2024 06:08 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "12/12/2024 18:08:41"
              },
              {
                "tranID": "S18515567",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-12T00:00:00",
                "partTranType": "D",
                "tranAmount": 20000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-12-12T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1299399.03,
                "withdrawls": 20000,
                "deposit": 0,
                "tranDateString": "12/12/2024 ",
                "valueDateString": "12/12/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "13/12/2024 00:17:40"
              },
              {
                "tranID": "S22488479",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-12-13T00:00:00",
                "partTranType": "C",
                "tranAmount": 10000,
                "tranParticular": "NIP FRM OYEWUMI OLAOLU AMOS-NIP Transfer to OY",
                "referenceID": "NIP/241213160642000028479122",
                "valueDate": "2024-12-13T00:00:00",
                "reference": "001/4843257020/001534378507",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1289399.03,
                "withdrawls": 0,
                "deposit": 10000,
                "tranDateString": "12/13/2024 ",
                "valueDateString": "12/13/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "13/12/2024 16:11:31"
              },
              {
                "tranID": "S24805566",
                "accountName": "ISAAC HELEN NNEOMA",
                "customerCode": null,
                "tranDate": "2024-12-15T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 13-12-2024",
                "referenceID": " ",
                "valueDate": "2024-12-15T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1289449.03,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "12/15/2024 ",
                "valueDateString": "12/15/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  19",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "14/12/2024 10:34:55"
              },
              {
                "tranID": "S29904843",
                "accountName": "HASSAN DAMILOLA MUINAT",
                "customerCode": null,
                "tranDate": "2024-12-15T00:00:00",
                "partTranType": "C",
                "tranAmount": 10000,
                "tranParticular": "TRF From HASSAN DAMILOLA MUINAT/App:tin To FCMB OY",
                "referenceID": "PPL",
                "valueDate": "2024-12-14T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1279449.03,
                "withdrawls": 0,
                "deposit": 10000,
                "tranDateString": "12/15/2024 ",
                "valueDateString": "12/14/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "15/12/2024 09:37:46"
              },
              {
                "tranID": "S30360531",
                "accountName": "EAT HEALTHY FARMS",
                "customerCode": null,
                "tranDate": "2024-12-15T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY HOLD ON ACCOUNT ON 15-DEC-2024",
                "referenceID": " ",
                "valueDate": "2024-12-15T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1279499.03,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "12/15/2024 ",
                "valueDateString": "12/15/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  25",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "15/12/2024 14:56:56"
              },
              {
                "tranID": "S34250670",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-12-16T00:00:00",
                "partTranType": "C",
                "tranAmount": 30000,
                "tranParticular": "NIP FRM Wahab Adetunji Ajose-Transfer To OYEWU",
                "referenceID": "NIP/241216080302623676504181",
                "valueDate": "2024-12-16T00:00:00",
                "reference": "001/4843257020/001541343413",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1249499.03,
                "withdrawls": 0,
                "deposit": 30000,
                "tranDateString": "12/16/2024 ",
                "valueDateString": "12/16/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/12/2024 09:29:01"
              },
              {
                "tranID": "S38814481",
                "accountName": "SANNI KEHINDE",
                "customerCode": null,
                "tranDate": "2024-12-17T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 16-12-2024",
                "referenceID": " ",
                "valueDate": "2024-12-17T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1249549.03,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "12/17/2024 ",
                "valueDateString": "12/17/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  81",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "17/12/2024 09:38:52"
              },
              {
                "tranID": "S41057307",
                "accountName": "MAMA TEE HAIR COLLECTION",
                "customerCode": null,
                "tranDate": "2024-12-17T00:00:00",
                "partTranType": "C",
                "tranAmount": 30000,
                "tranParticular": "TRF From MAMA TEE HAIR COLLECTION/App To FCMB OYEW",
                "referenceID": "PPL",
                "valueDate": "2024-12-17T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1219549.03,
                "withdrawls": 0,
                "deposit": 30000,
                "tranDateString": "12/17/2024 ",
                "valueDateString": "12/17/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "17/12/2024 12:20:17"
              },
              {
                "tranID": "S42066304",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-17T00:00:00",
                "partTranType": "D",
                "tranAmount": 30000,
                "tranParticular": "Appfund To ACCESS BANK PLC DIAMOND EKEKWE OGEC",
                "referenceID": "NIP/241217150803003626918197",
                "valueDate": "2024-12-17T00:00:00",
                "reference": "082/4843257020/001546256637",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1249549.03,
                "withdrawls": 30000,
                "deposit": 0,
                "tranDateString": "12/17/2024 ",
                "valueDateString": "12/17/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "17/12/2024 15:08:44"
              },
              {
                "tranID": "S42066348",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-17T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appfund To ACCESS BANK PLC DIAM",
                "referenceID": "NIP/241217150803003626918197",
                "valueDate": "2024-12-17T00:00:00",
                "reference": "082/4843257020/001546256637",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1249575.91,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "12/17/2024 ",
                "valueDateString": "12/17/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "17/12/2024 15:08:44"
              },
              {
                "tranID": "S42415316",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-17T00:00:00",
                "partTranType": "D",
                "tranAmount": 7000,
                "tranParticular": "AppOlaolu To Access Bank ABIMBOLA IBIYINKA JOH",
                "referenceID": "NIP/241217160742003627107996",
                "valueDate": "2024-12-17T00:00:00",
                "reference": "082/4843257020/001546499007",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1256575.91,
                "withdrawls": 7000,
                "deposit": 0,
                "tranDateString": "12/17/2024 ",
                "valueDateString": "12/17/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "17/12/2024 16:07:52"
              },
              {
                "tranID": "S42415365",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-17T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-AppOlaolu To Access Bank ABIMBO",
                "referenceID": "NIP/241217160742003627107996",
                "valueDate": "2024-12-17T00:00:00",
                "reference": "082/4843257020/001546499007",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1256602.79,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "12/17/2024 ",
                "valueDateString": "12/17/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "17/12/2024 16:07:52"
              },
              {
                "tranID": "S42776513",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-17T00:00:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": "FCMBMobile:AirtimeTopup0805653987724121716042",
                "referenceID": "GLO/2412171604269691614843257/MOBILE",
                "valueDate": "2024-12-17T00:00:00",
                "reference": "2412171604269691614843257",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1261602.79,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "12/17/2024 ",
                "valueDateString": "12/17/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "17/12/2024 17:04:44"
              },
              {
                "tranID": "S44068552",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-17T00:00:00",
                "partTranType": "D",
                "tranAmount": 25000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-12-17T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1286602.79,
                "withdrawls": 25000,
                "deposit": 0,
                "tranDateString": "12/17/2024 ",
                "valueDateString": "12/17/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "17/12/2024 23:51:52"
              },
              {
                "tranID": "S44151899",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-18T00:00:00",
                "partTranType": "D",
                "tranAmount": 3000,
                "tranParticular": " App:poster To Opay Digital Services Limited U",
                "referenceID": "FTN025012949530/4843257020",
                "valueDate": "2024-12-17T00:00:00",
                "reference": "ETRNZ|553052182102",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1289602.79,
                "withdrawls": 3000,
                "deposit": 0,
                "tranDateString": "12/18/2024 ",
                "valueDateString": "12/17/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "18/12/2024 09:26:16"
              },
              {
                "tranID": "S44152110",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-18T00:00:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App:poster To Opay Digital Serv",
                "referenceID": "FTN025012949530/4843257020",
                "valueDate": "2024-12-18T00:00:00",
                "reference": "ETRNZ|553052182102",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1289613.54,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "12/18/2024 ",
                "valueDateString": "12/18/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "18/12/2024 09:26:16"
              },
              {
                "tranID": "S44612437",
                "accountName": "JOHN JUSTINE ESHIET",
                "customerCode": null,
                "tranDate": "2024-12-18T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 17-12-2024",
                "referenceID": " ",
                "valueDate": "2024-12-18T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1289663.54,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "12/18/2024 ",
                "valueDateString": "12/18/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  35",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "18/12/2024 09:26:16"
              },
              {
                "tranID": "S46127016",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-18T00:00:00",
                "partTranType": "D",
                "tranAmount": 10500,
                "tranParticular": "TRF to EJEKWU LILIAN IJEOMA/App:registration To",
                "referenceID": "PPL",
                "valueDate": "2024-12-18T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1300163.54,
                "withdrawls": 10500,
                "deposit": 0,
                "tranDateString": "12/18/2024 ",
                "valueDateString": "12/18/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "18/12/2024 11:03:58"
              },
              {
                "tranID": "S47195622",
                "accountName": "DIT NEYO CONSULT",
                "customerCode": null,
                "tranDate": "2024-12-18T00:00:00",
                "partTranType": "C",
                "tranAmount": 10000,
                "tranParticular": "TRF From DIT NEYO CONSULT/App To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-12-18T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1290163.54,
                "withdrawls": 0,
                "deposit": 10000,
                "tranDateString": "12/18/2024 ",
                "valueDateString": "12/18/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "18/12/2024 13:49:16"
              },
              {
                "tranID": "S49223312",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-18T00:00:00",
                "partTranType": "D",
                "tranAmount": 10000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-12-18T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1300163.54,
                "withdrawls": 10000,
                "deposit": 0,
                "tranDateString": "12/18/2024 ",
                "valueDateString": "12/18/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "18/12/2024 19:12:28"
              },
              {
                "tranID": "S50112693",
                "accountName": "AWONIYI DUPE TEMITOPE",
                "customerCode": null,
                "tranDate": "2024-12-19T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 18-12-2024",
                "referenceID": " ",
                "valueDate": "2024-12-19T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1300213.54,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "12/19/2024 ",
                "valueDateString": "12/19/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  44",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "19/12/2024 10:20:15"
              },
              {
                "tranID": "S52717081",
                "accountName": "OYELADE MORUFAT FOLASADE",
                "customerCode": null,
                "tranDate": "2024-12-19T00:00:00",
                "partTranType": "C",
                "tranAmount": 5000,
                "tranParticular": "TRF From OYELADE MORUFAT FOLASADE/App: To FCMB OYE",
                "referenceID": "PPL",
                "valueDate": "2024-12-19T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1295213.54,
                "withdrawls": 0,
                "deposit": 5000,
                "tranDateString": "12/19/2024 ",
                "valueDateString": "12/19/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "19/12/2024 13:05:15"
              },
              {
                "tranID": "S52749591",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-19T00:00:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-12-19T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1300213.54,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "12/19/2024 ",
                "valueDateString": "12/19/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "19/12/2024 13:15:40"
              },
              {
                "tranID": "S58403990",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-20T00:00:00",
                "partTranType": "D",
                "tranAmount": 200,
                "tranParticular": "QR/Q22824727273/Airtime/MTN/2347032819698",
                "referenceID": "USSD",
                "valueDate": "2024-12-20T00:00:00",
                "reference": "USSD/Q22824727273",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1300413.54,
                "withdrawls": 200,
                "deposit": 0,
                "tranDateString": "12/20/2024 ",
                "valueDateString": "12/20/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "20/12/2024 12:15:48"
              },
              {
                "tranID": "S59708230",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-20T00:00:00",
                "partTranType": "D",
                "tranAmount": 16000,
                "tranParticular": "Appfund To VFD Microfinance Bank ISAAC VINCENT",
                "referenceID": "NIP/241220151958003635791256",
                "valueDate": "2024-12-20T00:00:00",
                "reference": "082/4843257020/001557052831",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1316413.54,
                "withdrawls": 16000,
                "deposit": 0,
                "tranDateString": "12/20/2024 ",
                "valueDateString": "12/20/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "20/12/2024 15:20:56"
              },
              {
                "tranID": "S59708247",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-20T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appfund To VFD Microfinance Ban",
                "referenceID": "NIP/241220151958003635791256",
                "valueDate": "2024-12-20T00:00:00",
                "reference": "082/4843257020/001557052831",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1316440.42,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "12/20/2024 ",
                "valueDateString": "12/20/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "20/12/2024 15:20:56"
              },
              {
                "tranID": "S63175008",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-22T00:00:00",
                "partTranType": "D",
                "tranAmount": 200,
                "tranParticular": "QR/Q22826694022/Airtime/MTN/2347032819698",
                "referenceID": "USSD",
                "valueDate": "2024-12-21T00:00:00",
                "reference": "USSD/Q22826694022",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1316640.42,
                "withdrawls": 200,
                "deposit": 0,
                "tranDateString": "12/22/2024 ",
                "valueDateString": "12/21/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "21/12/2024 10:25:08"
              },
              {
                "tranID": "S73335770",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-12-23T00:00:00",
                "partTranType": "C",
                "tranAmount": 1000,
                "tranParticular": "NIP FRM JOSEPH ABISOLA OJO-MobTrf to OYEWUMI O",
                "referenceID": "NIP/241223080901828960762915",
                "valueDate": "2024-12-23T00:00:00",
                "reference": "001/4843257020/001564832189",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1315640.42,
                "withdrawls": 0,
                "deposit": 1000,
                "tranDateString": "12/23/2024 ",
                "valueDateString": "12/23/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "23/12/2024 09:22:20"
              },
              {
                "tranID": "S73839154",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-23T00:00:00",
                "partTranType": "D",
                "tranAmount": 200,
                "tranParticular": "QR/Q22831642984/Airtime/MTN/2347032819698",
                "referenceID": "USSD",
                "valueDate": "2024-12-23T00:00:00",
                "reference": "USSD/Q22831642984",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1315840.42,
                "withdrawls": 200,
                "deposit": 0,
                "tranDateString": "12/23/2024 ",
                "valueDateString": "12/23/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "23/12/2024 11:38:42"
              },
              {
                "tranID": "S80104301",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-12-24T00:00:00",
                "partTranType": "C",
                "tranAmount": 461500,
                "tranParticular": "NIP FRM OLUSANYA YEWANDE ISABELLE-nip ifo OYEW",
                "referenceID": "NIP/241224091513000049913240",
                "valueDate": "2024-12-24T00:00:00",
                "reference": "001/4843257020/001568769511",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -854340.42,
                "withdrawls": 0,
                "deposit": 461500,
                "tranDateString": "12/24/2024 ",
                "valueDateString": "12/24/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "24/12/2024 12:45:09"
              },
              {
                "tranID": "S80437223",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-24T00:00:00",
                "partTranType": "D",
                "tranAmount": 16000,
                "tranParticular": "Appfund To GTBank Plc BTS CONSULT LTD",
                "referenceID": "NIP/241224095954003646088437",
                "valueDate": "2024-12-24T00:00:00",
                "reference": "082/4843257020/001568974843",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -870340.42,
                "withdrawls": 16000,
                "deposit": 0,
                "tranDateString": "12/24/2024 ",
                "valueDateString": "12/24/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "24/12/2024 11:20:33"
              },
              {
                "tranID": "S80437241",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-24T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appfund To GTBank Plc BTS CONSU",
                "referenceID": "NIP/241224095954003646088437",
                "valueDate": "2024-12-24T00:00:00",
                "reference": "082/4843257020/001568974843",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -870367.3,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "12/24/2024 ",
                "valueDateString": "12/24/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "24/12/2024 11:20:33"
              },
              {
                "tranID": " 01352214",
                "accountName": "OCTAVE MUSIC CREATIVE NG",
                "customerCode": null,
                "tranDate": "2024-12-24T00:00:00",
                "partTranType": "C",
                "tranAmount": 110000,
                "tranParticular": "FCMB TRF  B/O OCTAVE MUSIC CREATIVE NG",
                "referenceID": " ",
                "valueDate": "2024-12-24T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -760367.3,
                "withdrawls": 0,
                "deposit": 110000,
                "tranDateString": "12/24/2024 ",
                "valueDateString": "12/24/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "24/12/2024 11:20:33"
              },
              {
                "tranID": "S80468722",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-24T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY / 01352214",
                "referenceID": " ",
                "valueDate": "2024-12-24T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -760417.3,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "12/24/2024 ",
                "valueDateString": "12/24/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "24/12/2024 11:20:33"
              },
              {
                "tranID": "S80514493",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-24T00:00:00",
                "partTranType": "D",
                "tranAmount": 461225,
                "tranParticular": "TRF to WAVELENGTH MULTI-RESOURCES NG/App: To FCMB",
                "referenceID": "PPL",
                "valueDate": "2024-12-24T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1221642.3,
                "withdrawls": 461225,
                "deposit": 0,
                "tranDateString": "12/24/2024 ",
                "valueDateString": "12/24/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "24/12/2024 11:20:33"
              },
              {
                "tranID": "S80884637",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-24T00:00:00",
                "partTranType": "D",
                "tranAmount": 7500,
                "tranParticular": "Appfund To Opay Digital Services Limited JOSEP",
                "referenceID": "NIP/241224105529003646316289",
                "valueDate": "2024-12-24T00:00:00",
                "reference": "082/4843257020/001569247099",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1229142.3,
                "withdrawls": 7500,
                "deposit": 0,
                "tranDateString": "12/24/2024 ",
                "valueDateString": "12/24/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "24/12/2024 11:20:33"
              },
              {
                "tranID": "S80884662",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-24T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appfund To Opay Digital Service",
                "referenceID": "NIP/241224105529003646316289",
                "valueDate": "2024-12-24T00:00:00",
                "reference": "082/4843257020/001569247099",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1229169.18,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "12/24/2024 ",
                "valueDateString": "12/24/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "24/12/2024 11:20:33"
              },
              {
                "tranID": "S81053118",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-24T00:00:00",
                "partTranType": "D",
                "tranAmount": 15000,
                "tranParticular": "App To Opay Digital Services Limited CHIUKWULE",
                "referenceID": "NIP/241224111635003646401761",
                "valueDate": "2024-12-24T00:00:00",
                "reference": "082/4843257020/001569350715",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1244169.18,
                "withdrawls": 15000,
                "deposit": 0,
                "tranDateString": "12/24/2024 ",
                "valueDateString": "12/24/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "24/12/2024 11:20:33"
              },
              {
                "tranID": "S81053148",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-24T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-App To Opay Digital Services Li",
                "referenceID": "NIP/241224111635003646401761",
                "valueDate": "2024-12-24T00:00:00",
                "reference": "082/4843257020/001569350715",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1244196.06,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "12/24/2024 ",
                "valueDateString": "12/24/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "24/12/2024 11:20:33"
              },
              {
                "tranID": "S81743588",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-24T00:00:00",
                "partTranType": "D",
                "tranAmount": 8500,
                "tranParticular": "Appmat To Opay Digital Services Limited SCOTT",
                "referenceID": "NIP/241224124148003646744981",
                "valueDate": "2024-12-24T00:00:00",
                "reference": "082/4843257020/001569783279",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1252696.06,
                "withdrawls": 8500,
                "deposit": 0,
                "tranDateString": "12/24/2024 ",
                "valueDateString": "12/24/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "24/12/2024 12:55:01"
              },
              {
                "tranID": "S81743705",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-24T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appmat To Opay Digital Services",
                "referenceID": "NIP/241224124148003646744981",
                "valueDate": "2024-12-24T00:00:00",
                "reference": "082/4843257020/001569783279",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1252722.94,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "12/24/2024 ",
                "valueDateString": "12/24/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "24/12/2024 12:55:01"
              },
              {
                "tranID": "S81798165",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-24T00:00:00",
                "partTranType": "D",
                "tranAmount": 200,
                "tranParticular": "QR/Q22834948914/Airtime/MTN/2347032819698",
                "referenceID": "USSD",
                "valueDate": "2024-12-24T00:00:00",
                "reference": "USSD/Q22834948914",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1252922.94,
                "withdrawls": 200,
                "deposit": 0,
                "tranDateString": "12/24/2024 ",
                "valueDateString": "12/24/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "24/12/2024 12:55:01"
              },
              {
                "tranID": "S82974767",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-12-24T00:00:00",
                "partTranType": "C",
                "tranAmount": 20000,
                "tranParticular": "NIP FRM Korea tech Roofing-Korea tech R Trf fo",
                "referenceID": "NIP/241224151100192688713543",
                "valueDate": "2024-12-24T00:00:00",
                "reference": "001/4843257020/001570554983",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1232922.94,
                "withdrawls": 0,
                "deposit": 20000,
                "tranDateString": "12/24/2024 ",
                "valueDateString": "12/24/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "24/12/2024 15:16:25"
              },
              {
                "tranID": "S83365125",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-24T00:00:00",
                "partTranType": "D",
                "tranAmount": 20000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-12-24T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1252922.94,
                "withdrawls": 20000,
                "deposit": 0,
                "tranDateString": "12/24/2024 ",
                "valueDateString": "12/24/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "24/12/2024 16:00:03"
              },
              {
                "tranID": "S85552953",
                "accountName": "KAY YACHT CRUISE",
                "customerCode": null,
                "tranDate": "2024-12-24T00:00:00",
                "partTranType": "C",
                "tranAmount": 10000,
                "tranParticular": "KAY YACHT CRUISE/OYEWUMI OLAOLU AMOS",
                "referenceID": "WSB",
                "valueDate": "2024-12-24T00:00:00",
                "reference": "001572160291",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1242922.94,
                "withdrawls": 0,
                "deposit": 10000,
                "tranDateString": "12/24/2024 ",
                "valueDateString": "12/24/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "25/12/2024 01:05:08"
              },
              {
                "tranID": "S85678139",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-24T00:00:00",
                "partTranType": "D",
                "tranAmount": 10000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-12-24T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1252922.94,
                "withdrawls": 10000,
                "deposit": 0,
                "tranDateString": "12/24/2024 ",
                "valueDateString": "12/24/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "25/12/2024 01:05:08"
              },
              {
                "tranID": "S86258623",
                "accountName": "HOLOMICOOL NIGERIA LIMITED",
                "customerCode": null,
                "tranDate": "2024-12-26T00:00:00",
                "partTranType": "D",
                "tranAmount": 150,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 24-12-2024",
                "referenceID": " ",
                "valueDate": "2024-12-26T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1253072.94,
                "withdrawls": 150,
                "deposit": 0,
                "tranDateString": "12/26/2024 ",
                "valueDateString": "12/26/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  80",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "25/12/2024 09:50:41"
              },
              {
                "tranID": " S1215371",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-27T00:00:00",
                "partTranType": "D",
                "tranAmount": 76,
                "tranParticular": "AUTHENTICATION CODE CHARGE Outstanding NOV 2024",
                "referenceID": " ",
                "valueDate": "2024-12-27T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1253148.94,
                "withdrawls": 76,
                "deposit": 0,
                "tranDateString": "12/27/2024 ",
                "valueDateString": "12/27/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "28/12/2024 04:41:15"
              },
              {
                "tranID": " S7188193",
                "accountName": "ADEOYE OLUWATOYIN GRACE",
                "customerCode": null,
                "tranDate": "2024-12-29T00:00:00",
                "partTranType": "C",
                "tranAmount": 355000,
                "tranParticular": "TRF From ADEOYE OLUWATOYIN GRACE/App:good To FCMB",
                "referenceID": "PPL",
                "valueDate": "2024-12-28T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -898148.94,
                "withdrawls": 0,
                "deposit": 355000,
                "tranDateString": "12/29/2024 ",
                "valueDateString": "12/28/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "29/12/2024 08:18:17"
              },
              {
                "tranID": " S7231970",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-12-29T00:00:00",
                "partTranType": "C",
                "tranAmount": 50000,
                "tranParticular": "NIP FRM OLUWATOYIN GRACE  ADEOYE-Good",
                "referenceID": "NIP/241228220447033035702067",
                "valueDate": "2024-12-28T00:00:00",
                "reference": "001/4843257020/001584014133",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -848148.94,
                "withdrawls": 0,
                "deposit": 50000,
                "tranDateString": "12/29/2024 ",
                "valueDateString": "12/28/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "29/12/2024 08:18:17"
              },
              {
                "tranID": " S7243513",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-29T00:00:00",
                "partTranType": "D",
                "tranAmount": 405000,
                "tranParticular": "TRF to WAVELENGTH MULTI-RESOURCES NG/App: To FCMB",
                "referenceID": "PPL",
                "valueDate": "2024-12-28T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1253148.94,
                "withdrawls": 405000,
                "deposit": 0,
                "tranDateString": "12/29/2024 ",
                "valueDateString": "12/28/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "29/12/2024 08:18:17"
              },
              {
                "tranID": " S7467333",
                "accountName": "TIJANI AYABA ESTHER",
                "customerCode": null,
                "tranDate": "2024-12-29T00:00:00",
                "partTranType": "D",
                "tranAmount": 360,
                "tranParticular": "SMS ALERT CHARGES FROM 25TH NOV TO 27TH DEC 2024",
                "referenceID": " ",
                "valueDate": "2024-12-29T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1253508.94,
                "withdrawls": 360,
                "deposit": 0,
                "tranDateString": "12/29/2024 ",
                "valueDateString": "12/29/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  38",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "29/12/2024 08:18:17"
              },
              {
                "tranID": "S10912867",
                "accountName": "ADEPOJU IDAYAT BOLANLE",
                "customerCode": null,
                "tranDate": "2024-12-30T00:00:00",
                "partTranType": "D",
                "tranAmount": 100,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 29-12-2024",
                "referenceID": " ",
                "valueDate": "2024-12-30T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1253608.94,
                "withdrawls": 100,
                "deposit": 0,
                "tranDateString": "12/30/2024 ",
                "valueDateString": "12/30/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  43",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "30/12/2024 13:52:01"
              },
              {
                "tranID": "S18247169",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-31T00:00:00",
                "partTranType": "D",
                "tranAmount": 80000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2024-12-31T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1333608.94,
                "withdrawls": 80000,
                "deposit": 0,
                "tranDateString": "12/31/2024 ",
                "valueDateString": "12/31/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "31/12/2024 10:27:12"
              },
              {
                "tranID": "S18644612",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2024-12-31T00:00:00",
                "partTranType": "C",
                "tranAmount": 55000,
                "tranParticular": "NIP FRM FAMODIMU ADEDAYO MICHAEL-FBNMOBILEOYEW",
                "referenceID": "NIP/241231104503000004855600",
                "valueDate": "2024-12-31T00:00:00",
                "reference": "001/4843257020/001590523513",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1278608.94,
                "withdrawls": 0,
                "deposit": 55000,
                "tranDateString": "12/31/2024 ",
                "valueDateString": "12/31/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "31/12/2024 13:28:50"
              },
              {
                "tranID": "S19354509",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-31T00:00:00",
                "partTranType": "D",
                "tranAmount": 30000,
                "tranParticular": "Appfund To ACCESS BANK PLC DIAMOND EKEKWE OGEC",
                "referenceID": "NIP/241231123307003665888689",
                "valueDate": "2024-12-31T00:00:00",
                "reference": "082/4843257020/001590933487",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1308608.94,
                "withdrawls": 30000,
                "deposit": 0,
                "tranDateString": "12/31/2024 ",
                "valueDateString": "12/31/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "31/12/2024 13:28:50"
              },
              {
                "tranID": "S19354526",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2024-12-31T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appfund To ACCESS BANK PLC DIAM",
                "referenceID": "NIP/241231123307003665888689",
                "valueDate": "2024-12-31T00:00:00",
                "reference": "082/4843257020/001590933487",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1308635.82,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "12/31/2024 ",
                "valueDateString": "12/31/2024 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "31/12/2024 13:28:50"
              },
              {
                "tranID": "S23619143",
                "accountName": "AWONIYI DUPE TEMITOPE",
                "customerCode": null,
                "tranDate": "2025-01-02T08:44:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 31-12-2024",
                "referenceID": " ",
                "valueDate": "2025-01-02T08:44:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1308685.82,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "01/02/2025 08:44 AM",
                "valueDateString": "01/02/2025 08:44 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   5",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "01/01/2025 08:44:10"
              },
              {
                "tranID": "S26898514",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-02T21:25:00",
                "partTranType": "D",
                "tranAmount": 10000,
                "tranParticular": "Appfund To GTBank Plc OYEWUMI OLAWUMI TOLUWANI",
                "referenceID": "NIP/250101193247003669628377",
                "valueDate": "2025-01-01T21:25:00",
                "reference": "082/4843257020/001594975819",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1318685.82,
                "withdrawls": 10000,
                "deposit": 0,
                "tranDateString": "01/02/2025 09:25 PM",
                "valueDateString": "01/01/2025 09:25 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "02/01/2025 21:25:09"
              },
              {
                "tranID": "S26898526",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-02T21:25:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appfund To GTBank Plc OYEWUMI O",
                "referenceID": "NIP/250101193247003669628377",
                "valueDate": "2025-01-02T21:25:00",
                "reference": "082/4843257020/001594975819",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1318712.7,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "01/02/2025 09:25 PM",
                "valueDateString": "01/02/2025 09:25 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "02/01/2025 21:25:09"
              },
              {
                "tranID": " 01232871",
                "accountName": "OCTAVE MUSIC CREATIVE NG",
                "customerCode": null,
                "tranDate": "2025-01-06T10:53:00",
                "partTranType": "C",
                "tranAmount": 1500000,
                "tranParticular": "FCMB TRF B/O OCTAVE MUSIC CREATIVE NG",
                "referenceID": " ",
                "valueDate": "2025-01-06T10:53:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": 181287.3,
                "withdrawls": 0,
                "deposit": 1500000,
                "tranDateString": "01/06/2025 10:53 AM",
                "valueDateString": "01/06/2025 10:53 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "06/01/2025 10:53:59"
              },
              {
                "tranID": "S45901035",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-06T10:53:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY / 01232871",
                "referenceID": " ",
                "valueDate": "2025-01-06T10:53:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": 181237.3,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "01/06/2025 10:53 AM",
                "valueDateString": "01/06/2025 10:53 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "06/01/2025 10:53:59"
              },
              {
                "tranID": "S45945860",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-06T10:53:00",
                "partTranType": "D",
                "tranAmount": 1160000,
                "tranParticular": "AppPAYMENT FOR HOUSE RENT To GTBank Plc DAFE P",
                "referenceID": "NIP/250106094224003678792812",
                "valueDate": "2025-01-06T10:53:00",
                "reference": "082/4843257020/001604634301",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -978762.7,
                "withdrawls": 1160000,
                "deposit": 0,
                "tranDateString": "01/06/2025 10:53 AM",
                "valueDateString": "01/06/2025 10:53 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "06/01/2025 10:53:59"
              },
              {
                "tranID": "S45945875",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-06T10:53:00",
                "partTranType": "D",
                "tranAmount": 53.75,
                "tranParticular": "TRANSACTION CHARGE-AppPAYMENT FOR HOUSE RENT To GT",
                "referenceID": "NIP/250106094224003678792812",
                "valueDate": "2025-01-06T10:53:00",
                "reference": "082/4843257020/001604634301",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -978816.45,
                "withdrawls": 53.75,
                "deposit": 0,
                "tranDateString": "01/06/2025 10:53 AM",
                "valueDateString": "01/06/2025 10:53 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "06/01/2025 10:53:59"
              },
              {
                "tranID": "S46323065",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-06T11:10:00",
                "partTranType": "D",
                "tranAmount": 10000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2025-01-06T11:10:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -988816.45,
                "withdrawls": 10000,
                "deposit": 0,
                "tranDateString": "01/06/2025 11:10 AM",
                "valueDateString": "01/06/2025 11:10 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "06/01/2025 11:10:20"
              },
              {
                "tranID": "S47162665",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-06T13:28:00",
                "partTranType": "D",
                "tranAmount": 8000,
                "tranParticular": "TRF to OBANUBI OYINDAMOLA RUTH/App:fund To FCMB OB",
                "referenceID": "PPL",
                "valueDate": "2025-01-06T13:28:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -996816.45,
                "withdrawls": 8000,
                "deposit": 0,
                "tranDateString": "01/06/2025 01:28 PM",
                "valueDateString": "01/06/2025 01:28 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "06/01/2025 13:28:38"
              },
              {
                "tranID": "S49103774",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-06T00:38:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": " App: To Palmpay SULIYAT ADEYINKA ALAO",
                "referenceID": "FTN025019770954/4843257020",
                "valueDate": "2025-01-06T00:38:00",
                "reference": "ETRNZ|150180620088",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1001816.45,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "01/06/2025 12:38 AM",
                "valueDateString": "01/06/2025 12:38 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "07/01/2025 00:38:54"
              },
              {
                "tranID": "S49103796",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-06T00:38:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App: To Palmpay SULIYAT ADEYINK",
                "referenceID": "FTN025019770954/4843257020",
                "valueDate": "2025-01-06T00:38:00",
                "reference": "ETRNZ|150180620088",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1001827.2,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "01/06/2025 12:38 AM",
                "valueDateString": "01/06/2025 12:38 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "07/01/2025 00:38:54"
              },
              {
                "tranID": "S54438545",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2025-01-08T09:01:00",
                "partTranType": "C",
                "tranAmount": 10000,
                "tranParticular": "NIP FRM SEBIOBA MICHAEL OLUWATOSIN-FBNMOBILEOY",
                "referenceID": "NIP/250107211802000048210333",
                "valueDate": "2025-01-07T09:01:00",
                "reference": "001/4843257020/001609545013",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -991827.2,
                "withdrawls": 0,
                "deposit": 10000,
                "tranDateString": "01/08/2025 09:01 AM",
                "valueDateString": "01/07/2025 09:01 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "08/01/2025 09:01:18"
              },
              {
                "tranID": "S55993160",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-08T11:16:00",
                "partTranType": "D",
                "tranAmount": 7000,
                "tranParticular": "Appfund To Access Bank ABIMBOLA IBIYINKA JOHNS",
                "referenceID": "NIP/250108111653003683925554",
                "valueDate": "2025-01-08T11:16:00",
                "reference": "082/4843257020/001610466781",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -998827.2,
                "withdrawls": 7000,
                "deposit": 0,
                "tranDateString": "01/08/2025 11:16 AM",
                "valueDateString": "01/08/2025 11:16 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "08/01/2025 11:16:54"
              },
              {
                "tranID": "S55993171",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-08T11:16:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appfund To Access Bank ABIMBOLA",
                "referenceID": "NIP/250108111653003683925554",
                "valueDate": "2025-01-08T11:16:00",
                "reference": "082/4843257020/001610466781",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -998854.08,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "01/08/2025 11:16 AM",
                "valueDateString": "01/08/2025 11:16 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "08/01/2025 11:16:54"
              },
              {
                "tranID": "S56547771",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-08T13:06:00",
                "partTranType": "D",
                "tranAmount": 3000,
                "tranParticular": "TRF to OBANUBI OYINDAMOLA RUTH/App: To FCMB OBANUB",
                "referenceID": "PPL",
                "valueDate": "2025-01-08T13:06:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1001854.08,
                "withdrawls": 3000,
                "deposit": 0,
                "tranDateString": "01/08/2025 01:06 PM",
                "valueDateString": "01/08/2025 01:06 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "08/01/2025 13:06:46"
              },
              {
                "tranID": "S56940618",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-08T14:22:00",
                "partTranType": "D",
                "tranAmount": 9200,
                "tranParticular": "Apptoilet To MONIEPOINT MICROFINANCE BANK POS",
                "referenceID": "NIP/250108142207003684399802",
                "valueDate": "2025-01-08T14:22:00",
                "reference": "082/4843257020/001611020750",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1011054.08,
                "withdrawls": 9200,
                "deposit": 0,
                "tranDateString": "01/08/2025 02:22 PM",
                "valueDateString": "01/08/2025 02:22 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "08/01/2025 14:22:16"
              },
              {
                "tranID": "S56940636",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-08T14:22:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Apptoilet To MONIEPOINT MICROFI",
                "referenceID": "NIP/250108142207003684399802",
                "valueDate": "2025-01-08T14:22:00",
                "reference": "082/4843257020/001611020750",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1011080.96,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "01/08/2025 02:22 PM",
                "valueDateString": "01/08/2025 02:22 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "08/01/2025 14:22:16"
              },
              {
                "tranID": "S57323390",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-08T15:43:00",
                "partTranType": "D",
                "tranAmount": 10200,
                "tranParticular": "TRF to OBANUBI OYINDAMOLA RUTH/App: To FCMB OBANUB",
                "referenceID": "PPL",
                "valueDate": "2025-01-08T15:43:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1021280.96,
                "withdrawls": 10200,
                "deposit": 0,
                "tranDateString": "01/08/2025 03:43 PM",
                "valueDateString": "01/08/2025 03:43 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "08/01/2025 15:43:15"
              },
              {
                "tranID": "S59274031",
                "accountName": "PHILIP ULOMA CHRISTIANA",
                "customerCode": null,
                "tranDate": "2025-01-09T09:49:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 08-01-2025",
                "referenceID": " ",
                "valueDate": "2025-01-09T09:49:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1021330.96,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "01/09/2025 09:49 AM",
                "valueDateString": "01/09/2025 09:49 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  68",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "09/01/2025 09:49:30"
              },
              {
                "tranID": "S63166202",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-09T19:29:00",
                "partTranType": "D",
                "tranAmount": 25000,
                "tranParticular": "Apphouse cleanings To Access Bank ROTIMI BOSE",
                "referenceID": "NIP/250109192806003687565679",
                "valueDate": "2025-01-09T19:29:00",
                "reference": "082/4843257020/001614695911",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1046330.96,
                "withdrawls": 25000,
                "deposit": 0,
                "tranDateString": "01/09/2025 07:29 PM",
                "valueDateString": "01/09/2025 07:29 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "09/01/2025 19:29:36"
              },
              {
                "tranID": "S63166218",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-09T19:29:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Apphouse cleanings To Access Ba",
                "referenceID": "NIP/250109192806003687565679",
                "valueDate": "2025-01-09T19:29:00",
                "reference": "082/4843257020/001614695911",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1046357.84,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "01/09/2025 07:29 PM",
                "valueDateString": "01/09/2025 07:29 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "09/01/2025 19:29:36"
              },
              {
                "tranID": "S63611255",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-09T01:38:00",
                "partTranType": "D",
                "tranAmount": 500,
                "tranParticular": "QR/Q22875480597/Airtime/MTN/2347032819698",
                "referenceID": "USSD",
                "valueDate": "2025-01-09T01:38:00",
                "reference": "USSD/Q22875480597",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1046857.84,
                "withdrawls": 500,
                "deposit": 0,
                "tranDateString": "01/09/2025 01:38 AM",
                "valueDateString": "01/09/2025 01:38 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "10/01/2025 01:38:52"
              },
              {
                "tranID": "S63674383",
                "accountName": "TELCO CIMG STOCK PAYABLE ACCOUNT-MTN",
                "customerCode": null,
                "tranDate": "2025-01-09T01:38:00",
                "partTranType": "C",
                "tranAmount": 500,
                "tranParticular": "Rev - QR/Q22875480597/Airtime/MTN/2347032819698",
                "referenceID": "FLASHMECASH",
                "valueDate": "2025-01-09T01:38:00",
                "reference": "USSD",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1046357.84,
                "withdrawls": 0,
                "deposit": 500,
                "tranDateString": "01/09/2025 01:38 AM",
                "valueDateString": "01/09/2025 01:38 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "10/01/2025 01:38:52"
              },
              {
                "tranID": "S63690285",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-09T01:38:00",
                "partTranType": "D",
                "tranAmount": 500,
                "tranParticular": "FCMBMobile:AirtimeTopup0703281969825010919543",
                "referenceID": "MTN/2501091954377525134843257/MOBILE",
                "valueDate": "2025-01-09T01:38:00",
                "reference": "2501091954377525134843257",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1046857.84,
                "withdrawls": 500,
                "deposit": 0,
                "tranDateString": "01/09/2025 01:38 AM",
                "valueDateString": "01/09/2025 01:38 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "10/01/2025 01:38:52"
              },
              {
                "tranID": "S64307720",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2025-01-10T08:45:00",
                "partTranType": "C",
                "tranAmount": 5000,
                "tranParticular": "NIP FRM SEBIOBA MICHAEL OLUWATOSIN-FBNMOBILEOY",
                "referenceID": "NIP/250110065912000060617581",
                "valueDate": "2025-01-10T08:45:00",
                "reference": "001/4843257020/001615215917",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1041857.84,
                "withdrawls": 0,
                "deposit": 5000,
                "tranDateString": "01/10/2025 08:45 AM",
                "valueDateString": "01/10/2025 08:45 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "10/01/2025 08:45:33"
              },
              {
                "tranID": "S69970190",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-12T12:23:00",
                "partTranType": "D",
                "tranAmount": 287000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App:house expenses T",
                "referenceID": "PPL",
                "valueDate": "2025-01-11T12:23:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1328857.84,
                "withdrawls": 287000,
                "deposit": 0,
                "tranDateString": "01/12/2025 12:23 PM",
                "valueDateString": "01/11/2025 12:23 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "11/01/2025 12:23:14"
              },
              {
                "tranID": "S77533585",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-13T00:00:00",
                "partTranType": "C",
                "tranAmount": 5000,
                "tranParticular": "TRF From OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2025-01-13T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1323857.84,
                "withdrawls": 0,
                "deposit": 5000,
                "tranDateString": "01/13/2025 ",
                "valueDateString": "01/13/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "13/01/2025 09:19:32"
              },
              {
                "tranID": "S77922840",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-13T00:00:00",
                "partTranType": "D",
                "tranAmount": 16000,
                "tranParticular": " App:business name To GTBank Plc BTS CONSULT L",
                "referenceID": "FTN025021950518/4843257020",
                "valueDate": "2025-01-13T00:00:00",
                "reference": "ETRNZ|322187567057",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1339857.84,
                "withdrawls": 16000,
                "deposit": 0,
                "tranDateString": "01/13/2025 ",
                "valueDateString": "01/13/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "13/01/2025 10:46:50"
              },
              {
                "tranID": "S77922853",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-13T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-App:business name To GTBank Plc",
                "referenceID": "FTN025021950518/4843257020",
                "valueDate": "2025-01-13T00:00:00",
                "reference": "ETRNZ|322187567057",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1339884.72,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "01/13/2025 ",
                "valueDateString": "01/13/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "13/01/2025 10:46:50"
              },
              {
                "tranID": "S79117133",
                "accountName": "HASSAN DAMILOLA MUINAT",
                "customerCode": null,
                "tranDate": "2025-01-13T00:00:00",
                "partTranType": "C",
                "tranAmount": 15000,
                "tranParticular": "TRF From HASSAN DAMILOLA MUINAT/App: To FCMB OYEWU",
                "referenceID": "PPL",
                "valueDate": "2025-01-13T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1324884.72,
                "withdrawls": 0,
                "deposit": 15000,
                "tranDateString": "01/13/2025 ",
                "valueDateString": "01/13/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "13/01/2025 14:39:58"
              },
              {
                "tranID": "S81317360",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-14T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 13-01-2025",
                "referenceID": " ",
                "valueDate": "2025-01-14T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1324934.72,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "01/14/2025 ",
                "valueDateString": "01/14/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "14/01/2025 09:21:45"
              },
              {
                "tranID": "S82205280",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2025-01-14T00:00:00",
                "partTranType": "C",
                "tranAmount": 1000,
                "tranParticular": "NIP FRM OJO JOSEPH ABISOLA-FBNMOBILEOYEWUMI OL",
                "referenceID": "NIP/250114090404000083139301",
                "valueDate": "2025-01-14T00:00:00",
                "reference": "001/4843257020/001623902741",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1323934.72,
                "withdrawls": 0,
                "deposit": 1000,
                "tranDateString": "01/14/2025 ",
                "valueDateString": "01/14/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "14/01/2025 15:48:09"
              },
              {
                "tranID": "S83198688",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-14T00:00:00",
                "partTranType": "D",
                "tranAmount": 200,
                "tranParticular": "QR/Q22885696091/Airtime/MTN/2347032819698",
                "referenceID": "USSD",
                "valueDate": "2025-01-14T00:00:00",
                "reference": "USSD/Q22885696091",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1324134.72,
                "withdrawls": 200,
                "deposit": 0,
                "tranDateString": "01/14/2025 ",
                "valueDateString": "01/14/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "14/01/2025 12:20:39"
              },
              {
                "tranID": "S86928123",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-15T00:00:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": "FCMBMobile:AirtimeTopup0805653987725011507364",
                "referenceID": "GLO/2501150736417961044843257/MOBILE",
                "valueDate": "2025-01-15T00:00:00",
                "reference": "2501150736417961044843257",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1329134.72,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "01/15/2025 ",
                "valueDateString": "01/15/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "15/01/2025 09:20:43"
              },
              {
                "tranID": "S88552948",
                "accountName": "WAVELENGTH MULTI-RESOURCES NG",
                "customerCode": null,
                "tranDate": "2025-01-15T00:00:00",
                "partTranType": "C",
                "tranAmount": 120000,
                "tranParticular": "TRF From WAVELENGTH MULTI-RESOURCES NG/App To FCMB",
                "referenceID": "PPL",
                "valueDate": "2025-01-15T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1209134.72,
                "withdrawls": 0,
                "deposit": 120000,
                "tranDateString": "01/15/2025 ",
                "valueDateString": "01/15/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "15/01/2025 14:10:12"
              },
              {
                "tranID": "S88778077",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-15T00:00:00",
                "partTranType": "D",
                "tranAmount": 45000,
                "tranParticular": "Appfund To GTBank Plc BTS CONSULT LTD",
                "referenceID": "NIP/250115145540003700767482",
                "valueDate": "2025-01-15T00:00:00",
                "reference": "082/4843257020/001627285065",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1254134.72,
                "withdrawls": 45000,
                "deposit": 0,
                "tranDateString": "01/15/2025 ",
                "valueDateString": "01/15/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "15/01/2025 14:56:36"
              },
              {
                "tranID": "S88778100",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-15T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appfund To GTBank Plc BTS CONSU",
                "referenceID": "NIP/250115145540003700767482",
                "valueDate": "2025-01-15T00:00:00",
                "reference": "082/4843257020/001627285065",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1254161.6,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "01/15/2025 ",
                "valueDateString": "01/15/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "15/01/2025 14:56:36"
              },
              {
                "tranID": "S90584891",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-15T00:00:00",
                "partTranType": "D",
                "tranAmount": 10000,
                "tranParticular": " App:screed materials addition To GTBank Plc R",
                "referenceID": "FTN025023115148/4843257020",
                "valueDate": "2025-01-15T00:00:00",
                "reference": "ETRNZ|462113132655",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1264161.6,
                "withdrawls": 10000,
                "deposit": 0,
                "tranDateString": "01/15/2025 ",
                "valueDateString": "01/15/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "15/01/2025 23:22:26"
              },
              {
                "tranID": "S90585010",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-15T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-App:screed materials addition T",
                "referenceID": "FTN025023115148/4843257020",
                "valueDate": "2025-01-15T00:00:00",
                "reference": "ETRNZ|462113132655",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1264188.48,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "01/15/2025 ",
                "valueDateString": "01/15/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "15/01/2025 23:22:26"
              },
              {
                "tranID": "S90799455",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-16T00:00:00",
                "partTranType": "D",
                "tranAmount": 1000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2025-01-15T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1265188.48,
                "withdrawls": 1000,
                "deposit": 0,
                "tranDateString": "01/16/2025 ",
                "valueDateString": "01/15/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/01/2025 09:45:14"
              },
              {
                "tranID": "S91060099",
                "accountName": "OLATUNJI ABIODUN",
                "customerCode": null,
                "tranDate": "2025-01-16T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 15-01-2025",
                "referenceID": " ",
                "valueDate": "2025-01-16T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1265238.48,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "01/16/2025 ",
                "valueDateString": "01/16/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  26",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "16/01/2025 09:45:14"
              },
              {
                "tranID": "S91178916",
                "accountName": "PURPLE STRIPES VENTURES",
                "customerCode": null,
                "tranDate": "2025-01-16T00:00:00",
                "partTranType": "C",
                "tranAmount": 200000,
                "tranParticular": "web: TRF Frm PURPLE STRIPES VENTURES/web:Laolu",
                "referenceID": "RIB",
                "valueDate": "2025-01-16T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1065238.48,
                "withdrawls": 0,
                "deposit": 200000,
                "tranDateString": "01/16/2025 ",
                "valueDateString": "01/16/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/01/2025 09:45:14"
              },
              {
                "tranID": "S91268994",
                "accountName": "SERIKI OLUWASEUN TEMITOPE",
                "customerCode": null,
                "tranDate": "2025-01-16T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY HOLD ON ACCOUNT ON 16-JAN-2025",
                "referenceID": " ",
                "valueDate": "2025-01-16T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1065288.48,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "01/16/2025 ",
                "valueDateString": "01/16/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   7",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "16/01/2025 09:45:14"
              },
              {
                "tranID": "S92273446",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-16T00:00:00",
                "partTranType": "D",
                "tranAmount": 18600,
                "tranParticular": "Appsocket purchase To United Bank for Africa O",
                "referenceID": "NIP/250116110031003702508480",
                "valueDate": "2025-01-16T00:00:00",
                "reference": "082/4843257020/001629154603",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1083888.48,
                "withdrawls": 18600,
                "deposit": 0,
                "tranDateString": "01/16/2025 ",
                "valueDateString": "01/16/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/01/2025 11:10:47"
              },
              {
                "tranID": "S92273460",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-16T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appsocket purchase To United Ba",
                "referenceID": "NIP/250116110031003702508480",
                "valueDate": "2025-01-16T00:00:00",
                "reference": "082/4843257020/001629154603",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1083915.36,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "01/16/2025 ",
                "valueDateString": "01/16/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "16/01/2025 11:10:47"
              },
              {
                "tranID": "S92283717",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-16T00:00:00",
                "partTranType": "D",
                "tranAmount": 150000,
                "tranParticular": "TRF to BALOGUN HAMZAT OLASENI/App:gboye To FCMB BA",
                "referenceID": "PPL",
                "valueDate": "2025-01-16T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1233915.36,
                "withdrawls": 150000,
                "deposit": 0,
                "tranDateString": "01/16/2025 ",
                "valueDateString": "01/16/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/01/2025 11:10:47"
              },
              {
                "tranID": "S92320139",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-16T00:00:00",
                "partTranType": "D",
                "tranAmount": 50000,
                "tranParticular": "Appgboye To Zenith Bank OLAJUMOKE VICTORIA OLA",
                "referenceID": "NIP/250116110935003702534678",
                "valueDate": "2025-01-16T00:00:00",
                "reference": "082/4843257020/001629182187",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1283915.36,
                "withdrawls": 50000,
                "deposit": 0,
                "tranDateString": "01/16/2025 ",
                "valueDateString": "01/16/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/01/2025 11:10:47"
              },
              {
                "tranID": "S92320147",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-16T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appgboye To Zenith Bank OLAJUMO",
                "referenceID": "NIP/250116110935003702534678",
                "valueDate": "2025-01-16T00:00:00",
                "reference": "082/4843257020/001629182187",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1283942.24,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "01/16/2025 ",
                "valueDateString": "01/16/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "16/01/2025 11:10:47"
              },
              {
                "tranID": "S92974952",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-16T00:00:00",
                "partTranType": "D",
                "tranAmount": 6200,
                "tranParticular": "Appbolt ride to head office To Opay Digital Se",
                "referenceID": "NIP/250116132228003702906131",
                "valueDate": "2025-01-16T00:00:00",
                "reference": "082/4843257020/001629590883",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1290142.24,
                "withdrawls": 6200,
                "deposit": 0,
                "tranDateString": "01/16/2025 ",
                "valueDateString": "01/16/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/01/2025 13:24:59"
              },
              {
                "tranID": "S92974965",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-16T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appbolt ride to head office To",
                "referenceID": "NIP/250116132228003702906131",
                "valueDate": "2025-01-16T00:00:00",
                "reference": "082/4843257020/001629590883",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1290169.12,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "01/16/2025 ",
                "valueDateString": "01/16/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "16/01/2025 13:24:59"
              },
              {
                "tranID": "S93154395",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-16T00:00:00",
                "partTranType": "D",
                "tranAmount": 6900,
                "tranParticular": "Appbolt ride from head office to yaba To Opay",
                "referenceID": "NIP/250116135917003703010114",
                "valueDate": "2025-01-16T00:00:00",
                "reference": "082/4843257020/001629703875",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1297069.12,
                "withdrawls": 6900,
                "deposit": 0,
                "tranDateString": "01/16/2025 ",
                "valueDateString": "01/16/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/01/2025 13:59:55"
              },
              {
                "tranID": "S93154502",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-16T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appbolt ride from head office t",
                "referenceID": "NIP/250116135917003703010114",
                "valueDate": "2025-01-16T00:00:00",
                "reference": "082/4843257020/001629703875",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1297096,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "01/16/2025 ",
                "valueDateString": "01/16/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "16/01/2025 13:59:55"
              },
              {
                "tranID": "S93551368",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-16T00:00:00",
                "partTranType": "D",
                "tranAmount": 25000,
                "tranParticular": "Appfund To ACCESS BANK PLC DIAMOND EKEKWE OGEC",
                "referenceID": "NIP/250116152107003703226688",
                "valueDate": "2025-01-16T00:00:00",
                "reference": "082/4843257020/001629921225",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1322096,
                "withdrawls": 25000,
                "deposit": 0,
                "tranDateString": "01/16/2025 ",
                "valueDateString": "01/16/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/01/2025 15:23:26"
              },
              {
                "tranID": "S93551386",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-16T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appfund To ACCESS BANK PLC DIAM",
                "referenceID": "NIP/250116152107003703226688",
                "valueDate": "2025-01-16T00:00:00",
                "reference": "082/4843257020/001629921225",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1322122.88,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "01/16/2025 ",
                "valueDateString": "01/16/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "16/01/2025 15:23:26"
              },
              {
                "tranID": "S93795918",
                "accountName": "WAVELENGTH MULTI-RESOURCES NG",
                "customerCode": null,
                "tranDate": "2025-01-16T00:00:00",
                "partTranType": "C",
                "tranAmount": 40000,
                "tranParticular": "TRF From WAVELENGTH MULTI-RESOURCES NG/App To FCMB",
                "referenceID": "PPL",
                "valueDate": "2025-01-16T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1282122.88,
                "withdrawls": 0,
                "deposit": 40000,
                "tranDateString": "01/16/2025 ",
                "valueDateString": "01/16/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/01/2025 16:11:07"
              },
              {
                "tranID": "S93817368",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-16T00:00:00",
                "partTranType": "D",
                "tranAmount": 15000,
                "tranParticular": " App:fund To GTBank Plc BTS CONSULT LTD",
                "referenceID": "FTN025023316874/4843257020",
                "valueDate": "2025-01-16T00:00:00",
                "reference": "ETRNZ|964691692779",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1297122.88,
                "withdrawls": 15000,
                "deposit": 0,
                "tranDateString": "01/16/2025 ",
                "valueDateString": "01/16/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/01/2025 16:16:01"
              },
              {
                "tranID": "S93817379",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-16T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-App:fund To GTBank Plc BTS CONS",
                "referenceID": "FTN025023316874/4843257020",
                "valueDate": "2025-01-16T00:00:00",
                "reference": "ETRNZ|964691692779",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1297149.76,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "01/16/2025 ",
                "valueDateString": "01/16/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "16/01/2025 16:16:01"
              },
              {
                "tranID": "S94997376",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-16T00:00:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2025-01-16T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1302149.76,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "01/16/2025 ",
                "valueDateString": "01/16/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/01/2025 22:09:18"
              },
              {
                "tranID": "S95017446",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-16T00:00:00",
                "partTranType": "D",
                "tranAmount": 23000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2025-01-16T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1325149.76,
                "withdrawls": 23000,
                "deposit": 0,
                "tranDateString": "01/16/2025 ",
                "valueDateString": "01/16/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "16/01/2025 22:09:18"
              },
              {
                "tranID": " S3385219",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-19T00:00:00",
                "partTranType": "D",
                "tranAmount": 80000,
                "tranParticular": "TRF to ALEGBE OMOBOLANLE OMODOLAPO/App:for Carpent",
                "referenceID": "PPL",
                "valueDate": "2025-01-18T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1405149.76,
                "withdrawls": 80000,
                "deposit": 0,
                "tranDateString": "01/19/2025 ",
                "valueDateString": "01/18/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "18/01/2025 16:38:26"
              },
              {
                "tranID": "S12703692",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-20T00:00:00",
                "partTranType": "D",
                "tranAmount": 10000,
                "tranParticular": " App:fund To United Bank for Africa OLUDARE EM",
                "referenceID": "FTN025025201712/4843257020",
                "valueDate": "2025-01-20T00:00:00",
                "reference": "ETRNZ|933437736200",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1415149.76,
                "withdrawls": 10000,
                "deposit": 0,
                "tranDateString": "01/20/2025 ",
                "valueDateString": "01/20/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "21/01/2025 02:54:10"
              },
              {
                "tranID": "S12703697",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-20T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-App:fund To United Bank for Afr",
                "referenceID": "FTN025025201712/4843257020",
                "valueDate": "2025-01-20T00:00:00",
                "reference": "ETRNZ|933437736200",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1415176.64,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "01/20/2025 ",
                "valueDateString": "01/20/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "21/01/2025 02:54:10"
              },
              {
                "tranID": "S13205052",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-21T00:00:00",
                "partTranType": "D",
                "tranAmount": 9000,
                "tranParticular": " App:paint To GTBank Plc RAJI AJALA BASIRU",
                "referenceID": "FTN025025224642/4843257020",
                "valueDate": "2025-01-21T00:00:00",
                "reference": "ETRNZ|916870894583",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1424176.64,
                "withdrawls": 9000,
                "deposit": 0,
                "tranDateString": "01/21/2025 ",
                "valueDateString": "01/21/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "21/01/2025 09:07:24"
              },
              {
                "tranID": "S13205060",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-21T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-App:paint To GTBank Plc RAJI AJ",
                "referenceID": "FTN025025224642/4843257020",
                "valueDate": "2025-01-21T00:00:00",
                "reference": "ETRNZ|916870894583",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1424203.52,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "01/21/2025 ",
                "valueDateString": "01/21/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "21/01/2025 09:07:24"
              },
              {
                "tranID": " 01342831",
                "accountName": "OCTAVE MUSIC CREATIVE NG",
                "customerCode": null,
                "tranDate": "2025-01-21T00:00:00",
                "partTranType": "C",
                "tranAmount": 100000,
                "tranParticular": "TRANSFER B/O: OCTAVE MUSIC CREATIVE NG",
                "referenceID": "SFR2SFR2",
                "valueDate": "2025-01-21T00:00:00",
                "reference": "082|TRF|BAS11325",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1324203.52,
                "withdrawls": 0,
                "deposit": 100000,
                "tranDateString": "01/21/2025 ",
                "valueDateString": "01/21/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "21/01/2025 11:53:07"
              },
              {
                "tranID": "S13846863",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-21T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY / 01342831",
                "referenceID": " ",
                "valueDate": "2025-01-21T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1324253.52,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "01/21/2025 ",
                "valueDateString": "01/21/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "21/01/2025 11:53:07"
              },
              {
                "tranID": "S14060928",
                "accountName": "ONI OLUWATOBA IREMIDE",
                "customerCode": null,
                "tranDate": "2025-01-21T00:00:00",
                "partTranType": "C",
                "tranAmount": 11500,
                "tranParticular": "TRF From ONI OLUWATOBA IREMIDE/App: To FCMB OYEWUM",
                "referenceID": "PPL",
                "valueDate": "2025-01-21T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1312753.52,
                "withdrawls": 0,
                "deposit": 11500,
                "tranDateString": "01/21/2025 ",
                "valueDateString": "01/21/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "21/01/2025 12:45:04"
              },
              {
                "tranID": "S16327455",
                "accountName": "ONABANJO OLATUNJI IDRIS",
                "customerCode": null,
                "tranDate": "2025-01-22T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 21-01-2025",
                "referenceID": " ",
                "valueDate": "2025-01-22T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1312803.52,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "01/22/2025 ",
                "valueDateString": "01/22/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  30",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "22/01/2025 08:51:30"
              },
              {
                "tranID": "  0142536",
                "accountName": "ETTEH UKPONG",
                "customerCode": null,
                "tranDate": "2025-01-22T00:00:00",
                "partTranType": "C",
                "tranAmount": 20000,
                "tranParticular": "TRANSFER B/O: ETTEH UKPONG",
                "referenceID": "SFR2SFR2",
                "valueDate": "2025-01-22T00:00:00",
                "reference": "082|TRF|EFF10795",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1292803.52,
                "withdrawls": 0,
                "deposit": 20000,
                "tranDateString": "01/22/2025 ",
                "valueDateString": "01/22/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "22/01/2025 08:51:30"
              },
              {
                "tranID": "S16797311",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-22T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY / 0142536",
                "referenceID": " ",
                "valueDate": "2025-01-22T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1292853.52,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "01/22/2025 ",
                "valueDateString": "01/22/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "22/01/2025 08:51:30"
              },
              {
                "tranID": "S16833177",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2025-01-22T00:00:00",
                "partTranType": "C",
                "tranAmount": 55000,
                "tranParticular": "NIP FRM FRANCIS ANUOLUWAPO TEMITOPE-BASNIPFRAN",
                "referenceID": "NIP/250122073338244152610925",
                "valueDate": "2025-01-22T00:00:00",
                "reference": "001/4843257020/001641431331",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1237853.52,
                "withdrawls": 0,
                "deposit": 55000,
                "tranDateString": "01/22/2025 ",
                "valueDateString": "01/22/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "22/01/2025 08:51:30"
              },
              {
                "tranID": "S17074394",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-22T00:00:00",
                "partTranType": "D",
                "tranAmount": 200,
                "tranParticular": "QR/Q22901850855/Airtime/MTN/2347032819698",
                "referenceID": "USSD",
                "valueDate": "2025-01-22T00:00:00",
                "reference": "USSD/Q22901850855",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1238053.52,
                "withdrawls": 200,
                "deposit": 0,
                "tranDateString": "01/22/2025 ",
                "valueDateString": "01/22/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "22/01/2025 09:49:05"
              },
              {
                "tranID": "S18553567",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-22T00:00:00",
                "partTranType": "D",
                "tranAmount": 30000,
                "tranParticular": "Apppart pay for painter To GTBank Plc RAJI AJA",
                "referenceID": "NIP/250122163006003714923332",
                "valueDate": "2025-01-22T00:00:00",
                "reference": "082/4843257020/001642481953",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1268053.52,
                "withdrawls": 30000,
                "deposit": 0,
                "tranDateString": "01/22/2025 ",
                "valueDateString": "01/22/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "22/01/2025 16:30:44"
              },
              {
                "tranID": "S18553580",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-22T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Apppart pay for painter To GTBa",
                "referenceID": "NIP/250122163006003714923332",
                "valueDate": "2025-01-22T00:00:00",
                "reference": "082/4843257020/001642481953",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1268080.4,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "01/22/2025 ",
                "valueDateString": "01/22/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "22/01/2025 16:30:44"
              },
              {
                "tranID": "S19187356",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-22T00:00:00",
                "partTranType": "C",
                "tranAmount": 30000,
                "tranParticular": "TRF From OYEWUMI OLAOLU AMOS/App:refund painter T",
                "referenceID": "PPL",
                "valueDate": "2025-01-22T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1238080.4,
                "withdrawls": 0,
                "deposit": 30000,
                "tranDateString": "01/22/2025 ",
                "valueDateString": "01/22/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "22/01/2025 21:29:31"
              },
              {
                "tranID": "S20011427",
                "accountName": "SAB U.S TEXTILES LIMITED",
                "customerCode": null,
                "tranDate": "2025-01-23T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 22-01-2025",
                "referenceID": " ",
                "valueDate": "2025-01-23T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1238130.4,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "01/23/2025 ",
                "valueDateString": "01/23/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  35",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "23/01/2025 08:42:17"
              },
              {
                "tranID": "S22811472",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2025-01-23T00:00:00",
                "partTranType": "C",
                "tranAmount": 40000,
                "tranParticular": "NIP FRM HAMZA BASHIR ADEBAYO-MOBUTOOYEWUMI OLA",
                "referenceID": "NIP/250123170535214331807777",
                "valueDate": "2025-01-23T00:00:00",
                "reference": "001/4843257020/001644704161",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1198130.4,
                "withdrawls": 0,
                "deposit": 40000,
                "tranDateString": "01/23/2025 ",
                "valueDateString": "01/23/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "23/01/2025 17:07:11"
              },
              {
                "tranID": "S23502358",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-23T00:00:00",
                "partTranType": "D",
                "tranAmount": 25000,
                "tranParticular": "Appfund To ACCESS BANK PLC DIAMOND EKEKWE OGEC",
                "referenceID": "NIP/250123192443003717322511",
                "valueDate": "2025-01-23T00:00:00",
                "reference": "082/4843257020/001645133987",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1223130.4,
                "withdrawls": 25000,
                "deposit": 0,
                "tranDateString": "01/23/2025 ",
                "valueDateString": "01/23/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "23/01/2025 19:27:27"
              },
              {
                "tranID": "S23502375",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-23T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appfund To ACCESS BANK PLC DIAM",
                "referenceID": "NIP/250123192443003717322511",
                "valueDate": "2025-01-23T00:00:00",
                "reference": "082/4843257020/001645133987",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1223157.28,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "01/23/2025 ",
                "valueDateString": "01/23/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "23/01/2025 19:27:27"
              },
              {
                "tranID": "S24383000",
                "accountName": "QUADRI JAMIU OWOLABI",
                "customerCode": null,
                "tranDate": "2025-01-24T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 23-01-2025",
                "referenceID": " ",
                "valueDate": "2025-01-24T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1223207.28,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "01/24/2025 ",
                "valueDateString": "01/24/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  50",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "24/01/2025 08:45:17"
              },
              {
                "tranID": "S26392916",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-24T00:00:00",
                "partTranType": "D",
                "tranAmount": 14000,
                "tranParticular": " App:fund To Access Bank ABIMBOLA IBIYINKA JOH",
                "referenceID": "FTN025026397389/4843257020",
                "valueDate": "2025-01-24T00:00:00",
                "reference": "ETRNZ|784747131980",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1237207.28,
                "withdrawls": 14000,
                "deposit": 0,
                "tranDateString": "01/24/2025 ",
                "valueDateString": "01/24/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "24/01/2025 13:48:48"
              },
              {
                "tranID": "S26392931",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-24T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-App:fund To Access Bank ABIMBOL",
                "referenceID": "FTN025026397389/4843257020",
                "valueDate": "2025-01-24T00:00:00",
                "reference": "ETRNZ|784747131980",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1237234.16,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "01/24/2025 ",
                "valueDateString": "01/24/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "24/01/2025 13:48:48"
              },
              {
                "tranID": "S35138113",
                "accountName": "ALI USMAN NA",
                "customerCode": null,
                "tranDate": "2025-01-26T00:00:00",
                "partTranType": "D",
                "tranAmount": 228,
                "tranParticular": "SMS ALERT CHARGES FROM 28TH DEC TO 24TH JAN 2025",
                "referenceID": " ",
                "valueDate": "2025-01-26T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1237462.16,
                "withdrawls": 228,
                "deposit": 0,
                "tranDateString": "01/26/2025 ",
                "valueDateString": "01/26/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  54",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "26/01/2025 15:05:24"
              },
              {
                "tranID": "S35856816",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2025-01-26T00:00:00",
                "partTranType": "C",
                "tranAmount": 405000,
                "tranParticular": "NIP FRM OLUWATOYIN GRACE  ADEOYE-Good",
                "referenceID": "NIP/250126174030608697702013",
                "valueDate": "2025-01-26T00:00:00",
                "reference": "001/4843257020/001651698213",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -832462.16,
                "withdrawls": 0,
                "deposit": 405000,
                "tranDateString": "01/26/2025 ",
                "valueDateString": "01/26/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "26/01/2025 22:48:48"
              },
              {
                "tranID": "S36979441",
                "accountName": "OLANREWAJU OLUWAKEMI FEYINTOLA",
                "customerCode": null,
                "tranDate": "2025-01-27T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 26-01-2025",
                "referenceID": " ",
                "valueDate": "2025-01-27T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -832512.16,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "01/27/2025 ",
                "valueDateString": "01/27/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  68",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "27/01/2025 23:34:56"
              },
              {
                "tranID": "S37793248",
                "accountName": "FUND TRANSFER TSS RECEIVALE A/C",
                "customerCode": null,
                "tranDate": "2025-01-27T00:00:00",
                "partTranType": "C",
                "tranAmount": 15000,
                "tranParticular": "Mbanking Trf: ACB/002311368406;;NXG :TRFFRM IYIDA",
                "referenceID": "FCMB MOBILE",
                "valueDate": "2025-01-27T00:00:00",
                "reference": "4843257020/2311368406/154793/E",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -817512.16,
                "withdrawls": 0,
                "deposit": 15000,
                "tranDateString": "01/27/2025 ",
                "valueDateString": "01/27/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "27/01/2025 23:34:56"
              },
              {
                "tranID": "S38906306",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-27T00:00:00",
                "partTranType": "D",
                "tranAmount": 7000,
                "tranParticular": " App:fund To Access Bank ABIMBOLA IBIYINKA JOH",
                "referenceID": "FTN025027319522/4843257020",
                "valueDate": "2025-01-27T00:00:00",
                "reference": "ETRNZ|165214089554",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -824512.16,
                "withdrawls": 7000,
                "deposit": 0,
                "tranDateString": "01/27/2025 ",
                "valueDateString": "01/27/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "27/01/2025 23:34:56"
              },
              {
                "tranID": "S38906320",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-27T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-App:fund To Access Bank ABIMBOL",
                "referenceID": "FTN025027319522/4843257020",
                "valueDate": "2025-01-27T00:00:00",
                "reference": "ETRNZ|165214089554",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -824539.04,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "01/27/2025 ",
                "valueDateString": "01/27/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "27/01/2025 23:34:56"
              },
              {
                "tranID": "S39085298",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-27T00:00:00",
                "partTranType": "D",
                "tranAmount": 405000,
                "tranParticular": "TRF to WAVELENGTH MULTI-RESOURCES NG/App: To FCMB",
                "referenceID": "PPL",
                "valueDate": "2025-01-27T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1229539.04,
                "withdrawls": 405000,
                "deposit": 0,
                "tranDateString": "01/27/2025 ",
                "valueDateString": "01/27/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "27/01/2025 23:34:56"
              },
              {
                "tranID": "S41322415",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-27T00:00:00",
                "partTranType": "D",
                "tranAmount": 56,
                "tranParticular": "AUTHENTICATION CODE CHARGE Outstanding DEC 2024",
                "referenceID": " ",
                "valueDate": "2025-01-27T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1229595.04,
                "withdrawls": 56,
                "deposit": 0,
                "tranDateString": "01/27/2025 ",
                "valueDateString": "01/27/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "27/01/2025 23:34:56"
              },
              {
                "tranID": "S47973022",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-29T00:00:00",
                "partTranType": "D",
                "tranAmount": 8000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2025-01-29T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1237595.04,
                "withdrawls": 8000,
                "deposit": 0,
                "tranDateString": "01/29/2025 ",
                "valueDateString": "01/29/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "29/01/2025 10:52:49"
              },
              {
                "tranID": "S49413866",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2025-01-29T00:00:00",
                "partTranType": "C",
                "tranAmount": 461500,
                "tranParticular": "NIP FRM OLUSANYA YEWANDE ISABELLE-NIP TSF FRM",
                "referenceID": "NIP/250129151152000117472841",
                "valueDate": "2025-01-29T00:00:00",
                "reference": "001/4843257020/001659150921",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -776095.04,
                "withdrawls": 0,
                "deposit": 461500,
                "tranDateString": "01/29/2025 ",
                "valueDateString": "01/29/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "29/01/2025 15:13:33"
              },
              {
                "tranID": "S51464406",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-30T00:00:00",
                "partTranType": "D",
                "tranAmount": 461400,
                "tranParticular": "TRF to WAVELENGTH MULTI-RESOURCES NG/App: To FCMB",
                "referenceID": "PPL",
                "valueDate": "2025-01-29T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1237495.04,
                "withdrawls": 461400,
                "deposit": 0,
                "tranDateString": "01/30/2025 ",
                "valueDateString": "01/29/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "30/01/2025 09:32:40"
              },
              {
                "tranID": "S51598264",
                "accountName": "UGWOKE KINGSLEY EJIKE",
                "customerCode": null,
                "tranDate": "2025-01-30T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 29-01-2025",
                "referenceID": " ",
                "valueDate": "2025-01-30T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1237545.04,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "01/30/2025 ",
                "valueDateString": "01/30/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  65",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "30/01/2025 09:32:40"
              },
              {
                "tranID": "S52483842",
                "accountName": "IWU SUNDAY",
                "customerCode": null,
                "tranDate": "2025-01-30T00:00:00",
                "partTranType": "C",
                "tranAmount": 5000,
                "tranParticular": "USSD/TRF Frm IWU SUNDAY",
                "referenceID": "FLASHMECASH",
                "valueDate": "2025-01-30T00:00:00",
                "reference": "Q22918566515",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1232545.04,
                "withdrawls": 0,
                "deposit": 5000,
                "tranDateString": "01/30/2025 ",
                "valueDateString": "01/30/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "30/01/2025 09:32:40"
              },
              {
                "tranID": " 01302267",
                "accountName": "ETTE ENE",
                "customerCode": null,
                "tranDate": "2025-01-30T00:00:00",
                "partTranType": "C",
                "tranAmount": 85000,
                "tranParticular": "TRANSFER B/O: ETTE ENE",
                "referenceID": "SFR2SFR2",
                "valueDate": "2025-01-30T00:00:00",
                "reference": "082|TRF|BAB4117",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1147545.04,
                "withdrawls": 0,
                "deposit": 85000,
                "tranDateString": "01/30/2025 ",
                "valueDateString": "01/30/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "30/01/2025 10:46:46"
              },
              {
                "tranID": "S52852410",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-30T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY / 01302267",
                "referenceID": " ",
                "valueDate": "2025-01-30T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1147595.04,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "01/30/2025 ",
                "valueDateString": "01/30/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "30/01/2025 10:46:46"
              },
              {
                "tranID": "S52940312",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-30T00:00:00",
                "partTranType": "D",
                "tranAmount": 10000,
                "tranParticular": "Appsavings To Opay Digital Services Limited OL",
                "referenceID": "NIP/250130100933003732146573",
                "valueDate": "2025-01-30T00:00:00",
                "reference": "082/4843257020/001660827645",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1157595.04,
                "withdrawls": 10000,
                "deposit": 0,
                "tranDateString": "01/30/2025 ",
                "valueDateString": "01/30/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "30/01/2025 10:46:46"
              },
              {
                "tranID": "S52940333",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-30T00:00:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appsavings To Opay Digital Serv",
                "referenceID": "NIP/250130100933003732146573",
                "valueDate": "2025-01-30T00:00:00",
                "reference": "082/4843257020/001660827645",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1157621.92,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "01/30/2025 ",
                "valueDateString": "01/30/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "30/01/2025 10:46:46"
              },
              {
                "tranID": "S53632910",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-30T00:00:00",
                "partTranType": "D",
                "tranAmount": 65000,
                "tranParticular": "Appfund To GTBank Plc BTS CONSULT LTD",
                "referenceID": "NIP/250130120734003732526799",
                "valueDate": "2025-01-30T00:00:00",
                "reference": "082/4843257020/001661208627",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1222621.92,
                "withdrawls": 65000,
                "deposit": 0,
                "tranDateString": "01/30/2025 ",
                "valueDateString": "01/30/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "30/01/2025 12:09:28"
              },
              {
                "tranID": "S53632932",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-30T00:00:00",
                "partTranType": "D",
                "tranAmount": 53.75,
                "tranParticular": "TRANSACTION CHARGE-Appfund To GTBank Plc BTS CONSU",
                "referenceID": "NIP/250130120734003732526799",
                "valueDate": "2025-01-30T00:00:00",
                "reference": "082/4843257020/001661208627",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1222675.67,
                "withdrawls": 53.75,
                "deposit": 0,
                "tranDateString": "01/30/2025 ",
                "valueDateString": "01/30/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "30/01/2025 12:09:28"
              },
              {
                "tranID": "S54255490",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-30T00:00:00",
                "partTranType": "D",
                "tranAmount": 400,
                "tranParticular": "FCMBMobile:AirtimeTopup0805653987725013013001",
                "referenceID": "GLO/2501301300195841374843257/MOBILE",
                "valueDate": "2025-01-30T00:00:00",
                "reference": "2501301300195841374843257",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1223075.67,
                "withdrawls": 400,
                "deposit": 0,
                "tranDateString": "01/30/2025 ",
                "valueDateString": "01/30/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "30/01/2025 14:01:55"
              },
              {
                "tranID": "S54301581",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-30T00:00:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": " App:fund To Access Bank ABIMBOLA IBIYINKA JOH",
                "referenceID": "FTN025028482147/4843257020",
                "valueDate": "2025-01-30T00:00:00",
                "reference": "ETRNZ|445117806118",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1228075.67,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "01/30/2025 ",
                "valueDateString": "01/30/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "30/01/2025 14:09:48"
              },
              {
                "tranID": "S54301593",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-30T00:00:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App:fund To Access Bank ABIMBOL",
                "referenceID": "FTN025028482147/4843257020",
                "valueDate": "2025-01-30T00:00:00",
                "reference": "ETRNZ|445117806118",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1228086.42,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "01/30/2025 ",
                "valueDateString": "01/30/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "30/01/2025 14:09:48"
              },
              {
                "tranID": "S61152217",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2025-01-31T00:00:00",
                "partTranType": "C",
                "tranAmount": 25000,
                "tranParticular": "NIP FRM ELIZABETH ADEBANKE ADEOTI-FCMB",
                "referenceID": "NIP/250131170454757209197631",
                "valueDate": "2025-01-31T00:00:00",
                "reference": "001/4843257020/001664746855",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1203086.42,
                "withdrawls": 0,
                "deposit": 25000,
                "tranDateString": "01/31/2025 ",
                "valueDateString": "01/31/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "31/01/2025 17:07:15"
              },
              {
                "tranID": "S61525799",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-01-31T00:00:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": "2839738158 ATM WD @ 12140820/FCMB Yaba Br",
                "referenceID": "VERVE CMD=CWDR/TERM_ID=12140820",
                "valueDate": "2025-01-31T00:00:00",
                "reference": "2839738158/000250/4843257020",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1208086.42,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "01/31/2025 ",
                "valueDateString": "01/31/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "31/01/2025 18:03:51"
              },
              {
                "tranID": "S63267684",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-02T21:32:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 31-01-2025",
                "referenceID": " ",
                "valueDate": "2025-02-02T21:32:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1208136.42,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "02/02/2025 09:32 PM",
                "valueDateString": "02/02/2025 09:32 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "02/02/2025 21:32:53"
              },
              {
                "tranID": "S63291448",
                "accountName": "PURPLE STRIPES VENTURES",
                "customerCode": null,
                "tranDate": "2025-02-02T21:32:00",
                "partTranType": "C",
                "tranAmount": 26000,
                "tranParticular": "web: TRF Frm PURPLE STRIPES VENTURES/web:Laolu",
                "referenceID": "RIB",
                "valueDate": "2025-02-01T21:32:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1182136.42,
                "withdrawls": 0,
                "deposit": 26000,
                "tranDateString": "02/02/2025 09:32 PM",
                "valueDateString": "02/01/2025 09:32 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "02/02/2025 21:32:53"
              },
              {
                "tranID": "S64890500",
                "accountName": "AGOYI OLUSOLA ALEX",
                "customerCode": null,
                "tranDate": "2025-02-02T21:32:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY HOLD ON ACCOUNT ON 02-FEB-2025",
                "referenceID": " ",
                "valueDate": "2025-02-02T21:32:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1182186.42,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "02/02/2025 09:32 PM",
                "valueDateString": "02/02/2025 09:32 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": " 103",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "02/02/2025 21:32:53"
              },
              {
                "tranID": "S74138052",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-03T11:45:00",
                "partTranType": "D",
                "tranAmount": 200,
                "tranParticular": "QR/Q22928881352/Airtime/MTN/2347032819698",
                "referenceID": "USSD",
                "valueDate": "2025-02-03T11:45:00",
                "reference": "USSD/Q22928881352",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1182386.42,
                "withdrawls": 200,
                "deposit": 0,
                "tranDateString": "02/03/2025 11:45 AM",
                "valueDateString": "02/03/2025 11:45 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "03/02/2025 11:45:42"
              },
              {
                "tranID": "S74268093",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2025-02-03T11:45:00",
                "partTranType": "C",
                "tranAmount": 55000,
                "tranParticular": "NIP FRM ADEDAYO MICHAEL FAMODIMU-Transfer from",
                "referenceID": "NIP/250203103520126543066936",
                "valueDate": "2025-02-03T11:45:00",
                "reference": "001/4843257020/001670347246",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1127386.42,
                "withdrawls": 0,
                "deposit": 55000,
                "tranDateString": "02/03/2025 11:45 AM",
                "valueDateString": "02/03/2025 11:45 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "03/02/2025 11:45:42"
              },
              {
                "tranID": "S75131734",
                "accountName": "FUND TRANSFER TSS RECEIVALE A/C",
                "customerCode": null,
                "tranDate": "2025-02-03T14:22:00",
                "partTranType": "C",
                "tranAmount": 50000,
                "tranParticular": "Mbanking Trf: ACB/002333908566;;NXG :TRFFRM IYIDA",
                "referenceID": "FCMB MOBILE",
                "valueDate": "2025-02-03T14:22:00",
                "reference": "4843257020/2333908566/774815/E",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1077386.42,
                "withdrawls": 0,
                "deposit": 50000,
                "tranDateString": "02/03/2025 02:22 PM",
                "valueDateString": "02/03/2025 02:22 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "03/02/2025 14:22:06"
              },
              {
                "tranID": "S75931187",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-03T16:48:00",
                "partTranType": "D",
                "tranAmount": 25000,
                "tranParticular": "Appfund To ACCESS BANK PLC DIAMOND EKEKWE OGEC",
                "referenceID": "NIP/250203164704003743498529",
                "valueDate": "2025-02-03T16:48:00",
                "reference": "082/4843257020/001671299321",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1102386.42,
                "withdrawls": 25000,
                "deposit": 0,
                "tranDateString": "02/03/2025 04:48 PM",
                "valueDateString": "02/03/2025 04:48 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "03/02/2025 16:48:08"
              },
              {
                "tranID": "S75931309",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-03T16:48:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appfund To ACCESS BANK PLC DIAM",
                "referenceID": "NIP/250203164704003743498529",
                "valueDate": "2025-02-03T16:48:00",
                "reference": "082/4843257020/001671299321",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1102413.3,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "02/03/2025 04:48 PM",
                "valueDateString": "02/03/2025 04:48 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "03/02/2025 16:48:08"
              },
              {
                "tranID": "S76052822",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-03T17:08:00",
                "partTranType": "D",
                "tranAmount": 661,
                "tranParticular": " App:reservation To Fidelity Bank Remita Check",
                "referenceID": "FTN025030700982/4843257020",
                "valueDate": "2025-02-03T17:08:00",
                "reference": "ETRNZ|154250718390",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1103074.3,
                "withdrawls": 661,
                "deposit": 0,
                "tranDateString": "02/03/2025 05:08 PM",
                "valueDateString": "02/03/2025 05:08 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "03/02/2025 17:08:46"
              },
              {
                "tranID": "S76052836",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-03T17:08:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App:reservation To Fidelity Ban",
                "referenceID": "FTN025030700982/4843257020",
                "valueDate": "2025-02-03T17:08:00",
                "reference": "ETRNZ|154250718390",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1103085.05,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "02/03/2025 05:08 PM",
                "valueDateString": "02/03/2025 05:08 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "03/02/2025 17:08:46"
              },
              {
                "tranID": "S76053331",
                "accountName": "ETRANZACT SWITCH SUSPENSE ACCT",
                "customerCode": null,
                "tranDate": "2025-02-03T17:08:00",
                "partTranType": "C",
                "tranAmount": 661,
                "tranParticular": " Rev154250718390",
                "referenceID": "FTN025030700982/4843257020",
                "valueDate": "2025-02-03T17:08:00",
                "reference": "ETRNZ|589963660024",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1102424.05,
                "withdrawls": 0,
                "deposit": 661,
                "tranDateString": "02/03/2025 05:08 PM",
                "valueDateString": "02/03/2025 05:08 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "03/02/2025 17:08:46"
              },
              {
                "tranID": "S76053355",
                "accountName": "E TRANSACT PAYABLE POS",
                "customerCode": null,
                "tranDate": "2025-02-03T17:08:00",
                "partTranType": "C",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-Rev154250718390",
                "referenceID": " ",
                "valueDate": "2025-02-03T17:08:00",
                "reference": "ETRNZ|589963660024",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1102413.3,
                "withdrawls": 0,
                "deposit": 10.75,
                "tranDateString": "02/03/2025 05:08 PM",
                "valueDateString": "02/03/2025 05:08 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   4",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "03/02/2025 17:08:46"
              },
              {
                "tranID": "S76082256",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-03T17:13:00",
                "partTranType": "D",
                "tranAmount": 661,
                "tranParticular": " App:reservation To Fidelity Bank Remita Check",
                "referenceID": "FTN025030703128/4843257020",
                "valueDate": "2025-02-03T17:13:00",
                "reference": "ETRNZ|202249266233",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1103074.3,
                "withdrawls": 661,
                "deposit": 0,
                "tranDateString": "02/03/2025 05:13 PM",
                "valueDateString": "02/03/2025 05:13 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "03/02/2025 17:13:49"
              },
              {
                "tranID": "S76082269",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-03T17:13:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App:reservation To Fidelity Ban",
                "referenceID": "FTN025030703128/4843257020",
                "valueDate": "2025-02-03T17:13:00",
                "reference": "ETRNZ|202249266233",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1103085.05,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "02/03/2025 05:13 PM",
                "valueDateString": "02/03/2025 05:13 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "03/02/2025 17:13:49"
              },
              {
                "tranID": "S76082861",
                "accountName": "ETRANZACT SWITCH SUSPENSE ACCT",
                "customerCode": null,
                "tranDate": "2025-02-03T17:13:00",
                "partTranType": "C",
                "tranAmount": 661,
                "tranParticular": " Rev202249266233",
                "referenceID": "FTN025030703128/4843257020",
                "valueDate": "2025-02-03T17:13:00",
                "reference": "ETRNZ|756485089673",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1102424.05,
                "withdrawls": 0,
                "deposit": 661,
                "tranDateString": "02/03/2025 05:13 PM",
                "valueDateString": "02/03/2025 05:13 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "03/02/2025 17:13:49"
              },
              {
                "tranID": "S76082880",
                "accountName": "E TRANSACT PAYABLE POS",
                "customerCode": null,
                "tranDate": "2025-02-03T17:13:00",
                "partTranType": "C",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-Rev202249266233",
                "referenceID": " ",
                "valueDate": "2025-02-03T17:13:00",
                "reference": "ETRNZ|756485089673",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1102413.3,
                "withdrawls": 0,
                "deposit": 10.75,
                "tranDateString": "02/03/2025 05:13 PM",
                "valueDateString": "02/03/2025 05:13 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   4",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "03/02/2025 17:13:49"
              },
              {
                "tranID": "S76523304",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-03T18:27:00",
                "partTranType": "D",
                "tranAmount": 25000,
                "tranParticular": "Appsavings To Opay Digital Services Limited OL",
                "referenceID": "NIP/250203182553003743810756",
                "valueDate": "2025-02-03T18:27:00",
                "reference": "082/4843257020/001671646809",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1127413.3,
                "withdrawls": 25000,
                "deposit": 0,
                "tranDateString": "02/03/2025 06:27 PM",
                "valueDateString": "02/03/2025 06:27 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "03/02/2025 18:27:06"
              },
              {
                "tranID": "S76523319",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-03T18:27:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appsavings To Opay Digital Serv",
                "referenceID": "NIP/250203182553003743810756",
                "valueDate": "2025-02-03T18:27:00",
                "reference": "082/4843257020/001671646809",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1127440.18,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "02/03/2025 06:27 PM",
                "valueDateString": "02/03/2025 06:27 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "03/02/2025 18:27:06"
              },
              {
                "tranID": "S77133394",
                "accountName": "OLAJIDE ISREAL",
                "customerCode": null,
                "tranDate": "2025-02-03T22:06:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY HOLD ON ACCOUNT ON 03-FEB-2025",
                "referenceID": " ",
                "valueDate": "2025-02-03T22:06:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1127490.18,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "02/03/2025 10:06 PM",
                "valueDateString": "02/03/2025 10:06 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": " 377",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "03/02/2025 22:06:22"
              },
              {
                "tranID": "S77469138",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-04T09:18:00",
                "partTranType": "D",
                "tranAmount": 26000,
                "tranParticular": "Appfund To Zenith Bank OLAJUMOKE VICTORIA OLAD",
                "referenceID": "NIP/250203205836003744237560",
                "valueDate": "2025-02-03T09:18:00",
                "reference": "082/4843257020/001672083901",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1153490.18,
                "withdrawls": 26000,
                "deposit": 0,
                "tranDateString": "02/04/2025 09:18 AM",
                "valueDateString": "02/03/2025 09:18 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "04/02/2025 09:18:37"
              },
              {
                "tranID": "S77469152",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-04T09:18:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appfund To Zenith Bank OLAJUMOK",
                "referenceID": "NIP/250203205836003744237560",
                "valueDate": "2025-02-04T09:18:00",
                "reference": "082/4843257020/001672083901",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1153517.06,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "02/04/2025 09:18 AM",
                "valueDateString": "02/04/2025 09:18 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "04/02/2025 09:18:37"
              },
              {
                "tranID": "S77738822",
                "accountName": "AMUSAADEWALE OLUWABUNMI AND AMUSA BOLANLE OLUBUNMI",
                "customerCode": null,
                "tranDate": "2025-02-04T09:18:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 03-02-2025",
                "referenceID": " ",
                "valueDate": "2025-02-04T09:18:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1153567.06,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "02/04/2025 09:18 AM",
                "valueDateString": "02/04/2025 09:18 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   8",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "04/02/2025 09:18:37"
              },
              {
                "tranID": "S78714485",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-04T10:15:00",
                "partTranType": "D",
                "tranAmount": 661,
                "tranParticular": " App:reservation To Fidelity Bank Remita Check",
                "referenceID": "FTN025030886620/4843257020",
                "valueDate": "2025-02-04T10:15:00",
                "reference": "ETRNZ|921410249996",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1154228.06,
                "withdrawls": 661,
                "deposit": 0,
                "tranDateString": "02/04/2025 10:15 AM",
                "valueDateString": "02/04/2025 10:15 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "04/02/2025 10:15:27"
              },
              {
                "tranID": "S78714492",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-04T10:15:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App:reservation To Fidelity Ban",
                "referenceID": "FTN025030886620/4843257020",
                "valueDate": "2025-02-04T10:15:00",
                "reference": "ETRNZ|921410249996",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1154238.81,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "02/04/2025 10:15 AM",
                "valueDateString": "02/04/2025 10:15 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "04/02/2025 10:15:27"
              },
              {
                "tranID": "S78715106",
                "accountName": "ETRANZACT SWITCH SUSPENSE ACCT",
                "customerCode": null,
                "tranDate": "2025-02-04T10:15:00",
                "partTranType": "C",
                "tranAmount": 661,
                "tranParticular": " Rev921410249996",
                "referenceID": "FTN025030886620/4843257020",
                "valueDate": "2025-02-04T10:15:00",
                "reference": "ETRNZ|757252883164",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1153577.81,
                "withdrawls": 0,
                "deposit": 661,
                "tranDateString": "02/04/2025 10:15 AM",
                "valueDateString": "02/04/2025 10:15 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "04/02/2025 10:15:27"
              },
              {
                "tranID": "S78715116",
                "accountName": "E TRANSACT PAYABLE POS",
                "customerCode": null,
                "tranDate": "2025-02-04T10:15:00",
                "partTranType": "C",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-Rev921410249996",
                "referenceID": " ",
                "valueDate": "2025-02-04T10:15:00",
                "reference": "ETRNZ|757252883164",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1153567.06,
                "withdrawls": 0,
                "deposit": 10.75,
                "tranDateString": "02/04/2025 10:15 AM",
                "valueDateString": "02/04/2025 10:15 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   4",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "04/02/2025 10:15:27"
              },
              {
                "tranID": "S79330352",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-04T11:21:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": "FCMBMobile:AirtimeTopup0805653987725020410191",
                "referenceID": "GLO/2502041019163692964843257/MOBILE",
                "valueDate": "2025-02-04T11:21:00",
                "reference": "2502041019163692964843257",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1158567.06,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "02/04/2025 11:21 AM",
                "valueDateString": "02/04/2025 11:21 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "04/02/2025 11:21:09"
              },
              {
                "tranID": "S80810551",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-04T16:02:00",
                "partTranType": "D",
                "tranAmount": 661,
                "tranParticular": " App:fund To Fidelity Bank Remita Checkout -",
                "referenceID": "FTN025031077996/4843257020",
                "valueDate": "2025-02-04T16:02:00",
                "reference": "ETRNZ|430385789215",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1159228.06,
                "withdrawls": 661,
                "deposit": 0,
                "tranDateString": "02/04/2025 04:02 PM",
                "valueDateString": "02/04/2025 04:02 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "04/02/2025 16:02:13"
              },
              {
                "tranID": "S80810564",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-04T16:02:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App:fund To Fidelity Bank Remit",
                "referenceID": "FTN025031077996/4843257020",
                "valueDate": "2025-02-04T16:02:00",
                "reference": "ETRNZ|430385789215",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1159238.81,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "02/04/2025 04:02 PM",
                "valueDateString": "02/04/2025 04:02 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "04/02/2025 16:02:13"
              },
              {
                "tranID": "S80811091",
                "accountName": "ETRANZACT SWITCH SUSPENSE ACCT",
                "customerCode": null,
                "tranDate": "2025-02-04T16:04:00",
                "partTranType": "C",
                "tranAmount": 661,
                "tranParticular": " Rev430385789215",
                "referenceID": "FTN025031077996/4843257020",
                "valueDate": "2025-02-04T16:04:00",
                "reference": "ETRNZ|008426552692",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1158577.81,
                "withdrawls": 0,
                "deposit": 661,
                "tranDateString": "02/04/2025 04:04 PM",
                "valueDateString": "02/04/2025 04:04 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "04/02/2025 16:04:31"
              },
              {
                "tranID": "S80811100",
                "accountName": "E TRANSACT PAYABLE POS",
                "customerCode": null,
                "tranDate": "2025-02-04T16:04:00",
                "partTranType": "C",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-Rev430385789215",
                "referenceID": " ",
                "valueDate": "2025-02-04T16:04:00",
                "reference": "ETRNZ|008426552692",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1158567.06,
                "withdrawls": 0,
                "deposit": 10.75,
                "tranDateString": "02/04/2025 04:04 PM",
                "valueDateString": "02/04/2025 04:04 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   4",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "04/02/2025 16:04:31"
              },
              {
                "tranID": "S80991363",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-04T16:36:00",
                "partTranType": "D",
                "tranAmount": 1000,
                "tranParticular": "TRF to OBANUBI OYINDAMOLA RUTH/App: To FCMB OBANUB",
                "referenceID": "PPL",
                "valueDate": "2025-02-04T16:36:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1159567.06,
                "withdrawls": 1000,
                "deposit": 0,
                "tranDateString": "02/04/2025 04:36 PM",
                "valueDateString": "02/04/2025 04:36 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "04/02/2025 16:36:52"
              },
              {
                "tranID": "S85446648",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-05T14:35:00",
                "partTranType": "D",
                "tranAmount": 3000,
                "tranParticular": " App: To GTBank Plc OYEWUMI OLAOLU AMOS",
                "referenceID": "FTN025031603951/4843257020",
                "valueDate": "2025-02-05T14:35:00",
                "reference": "ETRNZ|751459340472",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1162567.06,
                "withdrawls": 3000,
                "deposit": 0,
                "tranDateString": "02/05/2025 02:35 PM",
                "valueDateString": "02/05/2025 02:35 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "05/02/2025 14:35:42"
              },
              {
                "tranID": "S85446664",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-05T14:35:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App: To GTBank Plc OYEWUMI OLAO",
                "referenceID": "FTN025031603951/4843257020",
                "valueDate": "2025-02-05T14:35:00",
                "reference": "ETRNZ|751459340472",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1162577.81,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "02/05/2025 02:35 PM",
                "valueDateString": "02/05/2025 02:35 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "05/02/2025 14:35:42"
              },
              {
                "tranID": "S85492703",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-05T14:42:00",
                "partTranType": "D",
                "tranAmount": 3000,
                "tranParticular": " App: To GTBank Plc OYEWUMI OLAOLU AMOS",
                "referenceID": "FTN025031610487/4843257020",
                "valueDate": "2025-02-05T14:42:00",
                "reference": "ETRNZ|741619765356",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1165577.81,
                "withdrawls": 3000,
                "deposit": 0,
                "tranDateString": "02/05/2025 02:42 PM",
                "valueDateString": "02/05/2025 02:42 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "05/02/2025 14:42:09"
              },
              {
                "tranID": "S85492724",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-05T14:42:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App: To GTBank Plc OYEWUMI OLAO",
                "referenceID": "FTN025031610487/4843257020",
                "valueDate": "2025-02-05T14:42:00",
                "reference": "ETRNZ|741619765356",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1165588.56,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "02/05/2025 02:42 PM",
                "valueDateString": "02/05/2025 02:42 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "05/02/2025 14:42:09"
              },
              {
                "tranID": "S85823367",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-05T15:45:00",
                "partTranType": "D",
                "tranAmount": 2000,
                "tranParticular": "2846441211 ATM WD @ 12140821/FCMB YABA ATM 2",
                "referenceID": "VERVE CMD=CWDR/TERM_ID=12140821",
                "valueDate": "2025-02-05T15:45:00",
                "reference": "2846441211/002473/4843257020",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1167588.56,
                "withdrawls": 2000,
                "deposit": 0,
                "tranDateString": "02/05/2025 03:45 PM",
                "valueDateString": "02/05/2025 03:45 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "05/02/2025 15:45:02"
              },
              {
                "tranID": "S89350572",
                "accountName": "FUND TRANSFER TSS RECEIVALE A/C",
                "customerCode": null,
                "tranDate": "2025-02-06T11:07:00",
                "partTranType": "C",
                "tranAmount": 5000,
                "tranParticular": "Mbanking Trf: ACB/002343535164;;NXG :TRFIFEXSTEVFR",
                "referenceID": "FCMB MOBILE",
                "valueDate": "2025-02-06T11:07:00",
                "reference": "4843257020/2343535164/917771/E",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1162588.56,
                "withdrawls": 0,
                "deposit": 5000,
                "tranDateString": "02/06/2025 11:07 AM",
                "valueDateString": "02/06/2025 11:07 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "06/02/2025 11:07:25"
              },
              {
                "tranID": "S89427664",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-06T11:21:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2025-02-06T11:21:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1167588.56,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "02/06/2025 11:21 AM",
                "valueDateString": "02/06/2025 11:21 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "06/02/2025 11:21:40"
              },
              {
                "tranID": "S91415432",
                "accountName": "IZAM STELLA IFEANYI",
                "customerCode": null,
                "tranDate": "2025-02-06T17:39:00",
                "partTranType": "C",
                "tranAmount": 65000,
                "tranParticular": "TRF From IZAM STELLA IFEANYI/App:payment for scum",
                "referenceID": "PPL",
                "valueDate": "2025-02-06T17:39:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1102588.56,
                "withdrawls": 0,
                "deposit": 65000,
                "tranDateString": "02/06/2025 05:39 PM",
                "valueDateString": "02/06/2025 05:39 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "06/02/2025 17:39:06"
              },
              {
                "tranID": "S91450111",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-06T17:43:00",
                "partTranType": "D",
                "tranAmount": 25000,
                "tranParticular": "Appfund To ACCESS BANK PLC DIAMOND EKEKWE OGEC",
                "referenceID": "NIP/250206173948003751211630",
                "valueDate": "2025-02-06T17:43:00",
                "reference": "082/4843257020/001678941371",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1127588.56,
                "withdrawls": 25000,
                "deposit": 0,
                "tranDateString": "02/06/2025 05:43 PM",
                "valueDateString": "02/06/2025 05:43 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "06/02/2025 17:43:07"
              },
              {
                "tranID": "S91450126",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-06T17:43:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appfund To ACCESS BANK PLC DIAM",
                "referenceID": "NIP/250206173948003751211630",
                "valueDate": "2025-02-06T17:43:00",
                "reference": "082/4843257020/001678941371",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1127615.44,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "02/06/2025 05:43 PM",
                "valueDateString": "02/06/2025 05:43 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "06/02/2025 17:43:07"
              },
              {
                "tranID": "S92529561",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-07T09:25:00",
                "partTranType": "D",
                "tranAmount": 1500,
                "tranParticular": " App:barber To GTBank Plc CLIFFORD UGOCHUKWU G",
                "referenceID": "FTN025032304154/4843257020",
                "valueDate": "2025-02-06T09:25:00",
                "reference": "ETRNZ|336346144424",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1129115.44,
                "withdrawls": 1500,
                "deposit": 0,
                "tranDateString": "02/07/2025 09:25 AM",
                "valueDateString": "02/06/2025 09:25 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "07/02/2025 09:25:31"
              },
              {
                "tranID": "S92529584",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-07T09:25:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App:barber To GTBank Plc CLIFFO",
                "referenceID": "FTN025032304154/4843257020",
                "valueDate": "2025-02-07T09:25:00",
                "reference": "ETRNZ|336346144424",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1129126.19,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "02/07/2025 09:25 AM",
                "valueDateString": "02/07/2025 09:25 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "07/02/2025 09:25:31"
              },
              {
                "tranID": "S92852735",
                "accountName": "TADE ADEWOLE ADENIYI",
                "customerCode": null,
                "tranDate": "2025-02-07T09:25:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 06-02-2025",
                "referenceID": " ",
                "valueDate": "2025-02-07T09:25:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1129176.19,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "02/07/2025 09:25 AM",
                "valueDateString": "02/07/2025 09:25 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  91",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "07/02/2025 09:25:31"
              },
              {
                "tranID": "S94138392",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-07T10:29:00",
                "partTranType": "D",
                "tranAmount": 50000,
                "tranParticular": "TRF to GAMA AND SONS VENTURES/App:initial balance",
                "referenceID": "PPL",
                "valueDate": "2025-02-07T10:29:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1179176.19,
                "withdrawls": 50000,
                "deposit": 0,
                "tranDateString": "02/07/2025 10:29 AM",
                "valueDateString": "02/07/2025 10:29 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "07/02/2025 10:29:15"
              },
              {
                "tranID": "S94149284",
                "accountName": "HASSAN DAMILOLA MUINAT",
                "customerCode": null,
                "tranDate": "2025-02-07T10:29:00",
                "partTranType": "C",
                "tranAmount": 10000,
                "tranParticular": "TRF From HASSAN DAMILOLA MUINAT/App: To FCMB OYEWU",
                "referenceID": "PPL",
                "valueDate": "2025-02-07T10:29:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1169176.19,
                "withdrawls": 0,
                "deposit": 10000,
                "tranDateString": "02/07/2025 10:29 AM",
                "valueDateString": "02/07/2025 10:29 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "07/02/2025 10:29:15"
              },
              {
                "tranID": "S94204528",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-07T10:29:00",
                "partTranType": "D",
                "tranAmount": 10000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2025-02-07T10:29:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1179176.19,
                "withdrawls": 10000,
                "deposit": 0,
                "tranDateString": "02/07/2025 10:29 AM",
                "valueDateString": "02/07/2025 10:29 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "07/02/2025 10:29:15"
              },
              {
                "tranID": "S96310593",
                "accountName": "OGUNLEYE DASOLA",
                "customerCode": null,
                "tranDate": "2025-02-07T19:35:00",
                "partTranType": "C",
                "tranAmount": 18000,
                "tranParticular": "TRF From OGUNLEYE DASOLA/App:tin payment To F",
                "referenceID": "PPL",
                "valueDate": "2025-02-07T19:35:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1161176.19,
                "withdrawls": 0,
                "deposit": 18000,
                "tranDateString": "02/07/2025 07:35 PM",
                "valueDateString": "02/07/2025 07:35 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "07/02/2025 19:35:07"
              },
              {
                "tranID": "S97721841",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-07T02:05:00",
                "partTranType": "D",
                "tranAmount": 1000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2025-02-07T02:05:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1162176.19,
                "withdrawls": 1000,
                "deposit": 0,
                "tranDateString": "02/07/2025 02:05 AM",
                "valueDateString": "02/07/2025 02:05 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "08/02/2025 02:05:44"
              },
              {
                "tranID": "S98135622",
                "accountName": "ISHOLA YUSUF",
                "customerCode": null,
                "tranDate": "2025-02-09T17:15:00",
                "partTranType": "D",
                "tranAmount": 100,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 07-02-2025",
                "referenceID": " ",
                "valueDate": "2025-02-09T17:15:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1162276.19,
                "withdrawls": 100,
                "deposit": 0,
                "tranDateString": "02/09/2025 05:15 PM",
                "valueDateString": "02/09/2025 05:15 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   8",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "09/02/2025 17:15:56"
              },
              {
                "tranID": "  S745772",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-09T17:15:00",
                "partTranType": "D",
                "tranAmount": 20000,
                "tranParticular": "App To Opay Digital Services Limited OLAOLU AM",
                "referenceID": "NIP/250208151401003755966243",
                "valueDate": "2025-02-08T17:15:00",
                "reference": "082/4843257020/001683535231",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1182276.19,
                "withdrawls": 20000,
                "deposit": 0,
                "tranDateString": "02/09/2025 05:15 PM",
                "valueDateString": "02/08/2025 05:15 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "09/02/2025 17:15:56"
              },
              {
                "tranID": "  S745786",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-09T17:15:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-App To Opay Digital Services Li",
                "referenceID": "NIP/250208151401003755966243",
                "valueDate": "2025-02-09T17:15:00",
                "reference": "082/4843257020/001683535231",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1182303.07,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "02/09/2025 05:15 PM",
                "valueDateString": "02/09/2025 05:15 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "09/02/2025 17:15:56"
              },
              {
                "tranID": " S1751523",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-09T17:15:00",
                "partTranType": "D",
                "tranAmount": 55000,
                "tranParticular": "TRF to AMARK HOMES LIMITED/App:initial balance",
                "referenceID": "PPL",
                "valueDate": "2025-02-08T17:15:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1237303.07,
                "withdrawls": 55000,
                "deposit": 0,
                "tranDateString": "02/09/2025 05:15 PM",
                "valueDateString": "02/08/2025 05:15 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "09/02/2025 17:15:56"
              },
              {
                "tranID": " S2350187",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-09T17:15:00",
                "partTranType": "D",
                "tranAmount": 4000,
                "tranParticular": " App: To UBA OYEWUMI JOSEPH OLAYEMI",
                "referenceID": "FTN025033046777/4843257020",
                "valueDate": "2025-02-08T17:15:00",
                "reference": "ETRNZ|355077150247",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1241303.07,
                "withdrawls": 4000,
                "deposit": 0,
                "tranDateString": "02/09/2025 05:15 PM",
                "valueDateString": "02/08/2025 05:15 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "09/02/2025 17:15:56"
              },
              {
                "tranID": " S2350310",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-09T17:15:00",
                "partTranType": "D",
                "tranAmount": 10.75,
                "tranParticular": "TRANSACTION CHARGE-App: To UBA OYEWUMI JOSEPH OLAY",
                "referenceID": "FTN025033046777/4843257020",
                "valueDate": "2025-02-09T17:15:00",
                "reference": "ETRNZ|355077150247",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1241313.82,
                "withdrawls": 10.75,
                "deposit": 0,
                "tranDateString": "02/09/2025 05:15 PM",
                "valueDateString": "02/09/2025 05:15 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "09/02/2025 17:15:56"
              },
              {
                "tranID": " S3467294",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-09T17:15:00",
                "partTranType": "D",
                "tranAmount": 7100,
                "tranParticular": "Appfund To GTBank Plc BALOGUN RAHMOT MOSUNMOLA",
                "referenceID": "NIP/250209100716003757382022",
                "valueDate": "2025-02-09T17:15:00",
                "reference": "082/4843257020/001685011067",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1248413.82,
                "withdrawls": 7100,
                "deposit": 0,
                "tranDateString": "02/09/2025 05:15 PM",
                "valueDateString": "02/09/2025 05:15 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "09/02/2025 17:15:56"
              },
              {
                "tranID": " S3467303",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-09T17:15:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-Appfund To GTBank Plc BALOGUN R",
                "referenceID": "NIP/250209100716003757382022",
                "valueDate": "2025-02-09T17:15:00",
                "reference": "082/4843257020/001685011067",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1248440.7,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "02/09/2025 05:15 PM",
                "valueDateString": "02/09/2025 05:15 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "09/02/2025 17:15:56"
              },
              {
                "tranID": " S7400691",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2025-02-10T09:56:00",
                "partTranType": "C",
                "tranAmount": 5000,
                "tranParticular": "NIP FRM ADEDAYO MICHAEL FAMODIMU-Transfer from",
                "referenceID": "NIP/250210084150126961398093",
                "valueDate": "2025-02-10T09:56:00",
                "reference": "001/4843257020/001687085135",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1243440.7,
                "withdrawls": 0,
                "deposit": 5000,
                "tranDateString": "02/10/2025 09:56 AM",
                "valueDateString": "02/10/2025 09:56 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "10/02/2025 09:56:22"
              },
              {
                "tranID": " S7455962",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-10T09:56:00",
                "partTranType": "D",
                "tranAmount": 5000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2025-02-10T09:56:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1248440.7,
                "withdrawls": 5000,
                "deposit": 0,
                "tranDateString": "02/10/2025 09:56 AM",
                "valueDateString": "02/10/2025 09:56 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "10/02/2025 09:56:22"
              },
              {
                "tranID": " S7919156",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-10T11:24:00",
                "partTranType": "D",
                "tranAmount": 14000,
                "tranParticular": "App To Access Bank ABIMBOLA IBIYINKA JOHNSON",
                "referenceID": "NIP/250210112331003759571594",
                "valueDate": "2025-02-10T11:24:00",
                "reference": "082/4843257020/001687365773",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1262440.7,
                "withdrawls": 14000,
                "deposit": 0,
                "tranDateString": "02/10/2025 11:24 AM",
                "valueDateString": "02/10/2025 11:24 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "10/02/2025 11:24:13"
              },
              {
                "tranID": " S7919163",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-10T11:24:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-App To Access Bank ABIMBOLA IBI",
                "referenceID": "NIP/250210112331003759571594",
                "valueDate": "2025-02-10T11:24:00",
                "reference": "082/4843257020/001687365773",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1262467.58,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "02/10/2025 11:24 AM",
                "valueDateString": "02/10/2025 11:24 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "10/02/2025 11:24:13"
              },
              {
                "tranID": "S11758015",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-11T09:11:00",
                "partTranType": "D",
                "tranAmount": 3000,
                "tranParticular": "TRF to OBANUBI OYINDAMOLA RUTH/App: To FCMB OBANUB",
                "referenceID": "PPL",
                "valueDate": "2025-02-11T09:11:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1265467.58,
                "withdrawls": 3000,
                "deposit": 0,
                "tranDateString": "02/11/2025 09:11 AM",
                "valueDateString": "02/11/2025 09:11 AM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "11/02/2025 09:11:22"
              },
              {
                "tranID": "S13593114",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-11T13:37:00",
                "partTranType": "D",
                "tranAmount": 11000,
                "tranParticular": "App To GTBank Plc OYEWUMI OLAOLU AMOS",
                "referenceID": "NIP/250211133602003762462194",
                "valueDate": "2025-02-11T13:37:00",
                "reference": "082/4843257020/001690433027",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1276467.58,
                "withdrawls": 11000,
                "deposit": 0,
                "tranDateString": "02/11/2025 01:37 PM",
                "valueDateString": "02/11/2025 01:37 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "11/02/2025 13:37:52"
              },
              {
                "tranID": "S13593136",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-11T13:37:00",
                "partTranType": "D",
                "tranAmount": 26.88,
                "tranParticular": "TRANSACTION CHARGE-App To GTBank Plc OYEWUMI OLAOL",
                "referenceID": "NIP/250211133602003762462194",
                "valueDate": "2025-02-11T13:37:00",
                "reference": "082/4843257020/001690433027",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1276494.46,
                "withdrawls": 26.88,
                "deposit": 0,
                "tranDateString": "02/11/2025 01:37 PM",
                "valueDateString": "02/11/2025 01:37 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "11/02/2025 13:37:52"
              },
              {
                "tranID": "S15003649",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-11T18:02:00",
                "partTranType": "D",
                "tranAmount": 3000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2025-02-11T18:02:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1279494.46,
                "withdrawls": 3000,
                "deposit": 0,
                "tranDateString": "02/11/2025 06:02 PM",
                "valueDateString": "02/11/2025 06:02 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "11/02/2025 18:02:03"
              },
              {
                "tranID": "S18517711",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-12T13:36:00",
                "partTranType": "D",
                "tranAmount": 20000,
                "tranParticular": "TRF to OYEWUMI OLAOLU AMOS/App: To FCMB OYEWUMI",
                "referenceID": "PPL",
                "valueDate": "2025-02-12T13:36:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1299494.46,
                "withdrawls": 20000,
                "deposit": 0,
                "tranDateString": "02/12/2025 01:36 PM",
                "valueDateString": "02/12/2025 01:36 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "12/02/2025 13:36:30"
              },
              {
                "tranID": "S19322439",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2025-02-12T19:44:00",
                "partTranType": "C",
                "tranAmount": 15000,
                "tranParticular": "NIP FRM KANYINSOLA ARIKE ADEBUNMI-KANYINSOLA A",
                "referenceID": "NIP/250212151330806855533686",
                "valueDate": "2025-02-12T19:44:00",
                "reference": "001/4843257020/001693151935",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1284494.46,
                "withdrawls": 0,
                "deposit": 15000,
                "tranDateString": "02/12/2025 07:44 PM",
                "valueDateString": "02/12/2025 07:44 PM",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "12/02/2025 19:44:48"
              },
              {
                "tranID": "S21015743",
                "accountName": "ABIKOYE TEMILOLA OLUWAFUNKE",
                "customerCode": null,
                "tranDate": "2025-02-13T00:00:00",
                "partTranType": "D",
                "tranAmount": 50,
                "tranParticular": "EMT LEVY ON ACCOUNT ON 12-02-2025",
                "referenceID": " ",
                "valueDate": "2025-02-13T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1284544.46,
                "withdrawls": 50,
                "deposit": 0,
                "tranDateString": "02/13/2025 ",
                "valueDateString": "02/13/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "  88",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "BI",
                "tranTime": "13/02/2025 09:29:18"
              },
              {
                "tranID": "S22356227",
                "accountName": "WAVELENGTH MULTI-RESOURCES NG",
                "customerCode": null,
                "tranDate": "2025-02-13T00:00:00",
                "partTranType": "C",
                "tranAmount": 80000,
                "tranParticular": "TRF From WAVELENGTH MULTI-RESOURCES NG/App To FCMB",
                "referenceID": "PPL",
                "valueDate": "2025-02-13T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1204544.46,
                "withdrawls": 0,
                "deposit": 80000,
                "tranDateString": "02/13/2025 ",
                "valueDateString": "02/13/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "13/02/2025 10:39:26"
              },
              {
                "tranID": "S22480269",
                "accountName": "NIBSS RECEIVABLE A/C",
                "customerCode": null,
                "tranDate": "2025-02-13T00:00:00",
                "partTranType": "C",
                "tranAmount": 2000,
                "tranParticular": "NIP FRM JOSEPH ABISOLA OJO-Transfer from JOSEP",
                "referenceID": "NIP/250213103001613673177061",
                "valueDate": "2025-02-13T00:00:00",
                "reference": "001/4843257020/001694475451",
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1202544.46,
                "withdrawls": 0,
                "deposit": 2000,
                "tranDateString": "02/13/2025 ",
                "valueDateString": "02/13/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   2",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "13/02/2025 10:43:06"
              },
              {
                "tranID": "S22510523",
                "accountName": "OYEWUMI OLAOLU AMOS",
                "customerCode": null,
                "tranDate": "2025-02-13T00:00:00",
                "partTranType": "D",
                "tranAmount": 15000,
                "tranParticular": "TRF to HASSAN DAMILOLA MUINAT/App:refund To FCMB H",
                "referenceID": "PPL",
                "valueDate": "2025-02-13T00:00:00",
                "reference": null,
                "tranShortCodeDesc": "TRANSFER",
                "runningBalance": -1217544.46,
                "withdrawls": 15000,
                "deposit": 0,
                "tranDateString": "02/13/2025 ",
                "valueDateString": "02/13/2025 ",
                "hasTransaction": "Y",
                "statementRequester": "",
                "statementPeriod": "13-Feb-2024 To 13-Feb-2025",
                "maskedAccountNumber": "******7020",
                "tranSerialNo": "   1",
                "tranShortCode": "TRF",
                "tranType": "T",
                "tranSubType": "CI",
                "tranTime": "13/02/2025 10:39:26"
              }
            ]
          },
          "requestId": "r1739441622597",
          "code": "00",
          "description": "Success"
        },
      },
      {
        label: "Access Bank",
        value: "AccessBank",
        data: {
          message: "Request successful.",
          code: "000",
          success: true,
          data: [
            {
              "accountNumber": 120200123123,
              "accountType": "Savings",
              "customerFirstName": "John",
              "customerLastName": "Doe",
              "email": "pokedex2k17@gmail.com",
              "balance": {
                  "currency": "NGN",
                  "amount": 5000
              },
              "businessPartner": {
                  "id": 344,
                  "products": [
                      {
                          "productCode": "affordability_full"
                      }
                  ]
              },
              "transactions": [
                  {
                      "transactionId": "S1000345",
                      "transactionDate": "2023-06-01T10:30:10",
                      "transactoinInformation": "SMS Alert Charges for the Month of SEPTEMBER",
                      "status": "Completed",
                      "creditType": "Credit",
                      "referenceId": "332550537787",
                      "sourceTransaction": "McDonalds",
                      "amount": {
                          "currency": "NGN",
                          "amount": 100
                      }
                  },
                  {
                      "transactionId": "S1000356",
                      "transactionDate": "2023-06-02T10:30:10",
                      "transactoinInformation": "SMS Alert Charges for the Month of SEPTEMBER",
                      "status": "Pending",
                      "creditType": "Debit",
                      "referenceId": "2039BF65",
                      "sourceTransaction": "McDonalds",
                      "amount": {
                          "currency": "NGN",
                          "amount": 100
                      }
                  }
              ]
          }
          ],
        },
      },
      {
        label: "m36",
        value: "m36",
        data: {
          message: "Request successful.",
          code: "000",
          success: true,
          data: [
            {
              transactionList: [
                {
                  type: "Treasury Bills",
                  transactionDirection: "DEBIT",
                  amount: 14717.44,
                  category: null,
                  status: true,
                  surcharge: null,
                  beneficiaryName: "M36",
                  bankName: null,
                  referenceNumber: "M36-TBILL-1704709550464",
                  paymentReference: null,
                  narration:
                    "INVESTMENT/14717.437499999999994348750000/3397d486-5da6-4739-81f7-062439c7bd46",
                  comment: null,
                  balance: null,
                  initialBalance: null,
                  creditAccountName: null,
                  creditAccountNumber: null,
                  debitAccountName: null,
                  debitAccountNumber: null,
                  currency: "NGN",
                  date: "2024-01-08T00:00:00.000+00:00",
                  createdAt: "2024-01-08T10:25:50.977+00:00",
                  updatedAt: null,
                },
              ],
              currency: "NGN",
            },
          ],
        },
      },
    ];
    setBankName(bankName);
  }, []);
  return (
    <>
      <Stack direction="row" justifyContent={"space-between"}>
        <Box />
        <Grid
          container
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={"column"}
          gap={2}
        >
          <Grid item display={"flex"} justifyContent={"end"}>
            <TextField
              id="standard-select-currency"
              select
              value={selectedBank}
              onChange={(e) => {
                setSelectedBank(e.target.value);
              }}
            >
              {bankName.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item>
            <MainCard>
              <ReactJson
                name={"response"}
                displayDataTypes={false}
                displayObjectSize={false}
                onEdit={false}
                collapsed={false}
                onDelete={false}
                enableClipboard={false}
                src={bankName.find((item) => item.value === selectedBank)?.data}
              />
            </MainCard>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default SampleResponse;
