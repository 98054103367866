import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// material-ui
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  OutlinedInput,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import DeleteIcon from '@mui/icons-material/Delete';
import { shouldForwardProp } from '@mui/system';

// assets and third-party libraries
import getAxiosInstance from 'api';
import InfoImg from 'assets/images/info.png';
import { ReactComponent as CloseIcon } from 'assets/images/svg/close.svg';
import moment from 'moment';
import toast from 'react-hot-toast';
import MainCard from 'ui-component/cards/MainCard';
import { ReactComponent as PdfIcon } from 'assets/images/svg/pdf.svg';

// Custom styled components
const StyledTableCellHead = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#F0F0F0',
  color: 'rgba(33, 33, 32, 0.60)',
  border: '1px solid #DEDEDE',
  '&:last-child': {
    width: '30px', // Adjust width as needed
  },
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '24px',
  color: 'rgba(33, 33, 32, 0.6)',
}));

const ContentTypography = styled(Typography)(({ theme }) => ({
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '24px',
  color: theme.palette.common.black,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  border: '1px solid #DEDEDE',
  color: '#212120',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '500',
}));

const ReviewButton = styled(Button)(({ theme }) => ({
  padding: '4px 12px',
  gap: '4px',
  borderRadius: '10px',
  background: '#FFF6F3',
  border: '1px solid #FC5719',
  color: theme.palette.secondary.main,
  ':hover': {
    background: '#FFF6F3',
    border: '1px solid #FC5719',
    color: theme.palette.secondary.main,
  },
}));

const ProductCard = styled(MainCard)(({ theme }) => ({
  border: '1px solid rgba(0, 24, 35, 0.1)',
  height: '100%',
  cursor: 'pointer',
  ':hover': {
    background: 'rgba(252, 87, 25, 0.05)',
    border: '1px solid rgba(252, 87, 25, 0.2)',
  },
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(
  ({ theme }) => ({
    width: 350,
    marginLeft: 16,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: '8px',
    border: '1px solid rgba(33, 33, 32, 0.10)',
    background: '#FFFFFF',
    '& input': {
      background: 'transparent !important',
      paddingLeft: '4px !important',
    },
    [theme.breakpoints.down('lg')]: {
      width: 250,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginLeft: 4,
      background:
        theme.palette.mode === 'dark' ? theme.palette.dark[800] : '#fff',
    },
  })
);

// Table header cells
const headCells = [
  {
    id: 'no',
    numeric: false,
    disablePadding: false,
    label: '#',
  },
  {
    id: 'appName',
    numeric: true,
    disablePadding: false,
    label: 'Client Name',
  },
  {
    id: 'created',
    numeric: true,
    disablePadding: false,
    label: 'Created',
  },
  {
    id: 'Action',
    disablePadding: false,
    label: 'Action',
    style: { width: '25%' },
  },
];

// Enhanced Table Head Component
function EnhancedTableHead({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCellHead
            style={{ width: headCell.style?.width }}
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : undefined}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCellHead>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

// Enhanced Table Toolbar Component
const EnhancedTableToolbar = ({ numSelected }) => (
  <Toolbar
    sx={{
      p: 0,
      pl: 1,
      pr: 1,
      ...(numSelected > 0 && {
        color: (theme) => theme.palette.secondary.main,
      }),
    }}
  >
    {numSelected > 0 ? (
      <Typography color="inherit" variant="subtitle1">
        {numSelected} selected
      </Typography>
    ) : (
      <Typography variant="h4" id="tableTitle">
        Nutrition
      </Typography>
    )}
    <Box sx={{ flexGrow: 1 }} />
    {numSelected > 0 && (
      <Tooltip title="Delete">
        <IconButton size="large">
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    )}
  </Toolbar>
);

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

// Main Component: ApproveBusinessApp
export default function ApproveBusinessApp() {
  const theme = useTheme();
  const userData = JSON.parse(localStorage.getItem('userData'));
  const [order, setOrder] = useState('DESC');
  const [orderBy, setOrderBy] = useState('createdAt');
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState('');
  const [ProductPage, setProductPage] = useState(0);
  const [KYCPage, setKYCPage] = useState(0);
  const [dense] = useState(false);
  const [KYCLimit, setKYCLimit] = useState(10);
  const [ProductLimit, setProductLimit] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [apiList, setApiList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [productTotal, setProductTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [isFilter, setIsFilter] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isProductOpen, setIsProductOpen] = useState(false);
  const [selectedAppId, setSelectedAppId] = useState();
  const [selectedData, setSelectedData] = useState();
  const [isOpenIntegrationModel, setIsOpenIntegrationModel] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();

  // Toggle approval modal dialog for business partner
  const handleManageModel = () => {
    setIsOpen(!isOpen);
  };

  // Fetch KYC Approval Data
  const fetchKYCApproval = () => {
    const payload = {
      page: KYCPage + 1,
      limit: KYCLimit,
      sortBy: orderBy,
      sortDirection: order,
      search: search,
      filter: {
        isActive: true,
        partnerType: 'API',
        reviewStatus: 'KYC_PENDING',
      },
    };

    const businessPartnerURL = 'list';
    getAxiosInstance(
      `adminOrchestrator/businessPartner/${businessPartnerURL}`,
      'post',
      payload,
      { 'journey-type': 'Dashboard' }
    ).then((res) => {
      if (res?.data?.codes === 200) {
        setApiList(res?.data?.data?.items);
        setTotal(res?.data?.data?.total);
      }
    });
  };

  useEffect(() => {
    fetchKYCApproval();
  }, [KYCPage, KYCLimit]);

  // Fetch Product Approval Data
  const fetchProductApproval = () => {
    const payload = {
      page: ProductPage + 1,
      limit: ProductLimit,
      sortBy: orderBy,
      sortDirection: order,
      search: search,
      filter: {
        isActive: true,
        partnerType: 'API',
        reviewStatus: 'PENDING',
      },
    };
    getAxiosInstance(
      `adminOrchestrator/businessPartner/listProductEntitlement`,
      'post',
      payload,
      { 'journey-type': 'Business-Partner-Dashboard' }
    ).then((res) => {
      if (res?.data?.codes === 200) {
        setProductList(res?.data?.data?.items);
        setProductTotal(res?.data?.data?.total);
      }
    });
  };

  useEffect(() => {
    fetchProductApproval();
  }, [ProductPage, ProductLimit]);

  const handleMenuClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setIsOpen(!isOpen);
    setAnchorEl(null);
    setIsOpenIntegrationModel(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'ASC';
    setOrder(isAsc ? 'DESC' : 'ASC');
    setOrderBy(property);
  };

  const isSelected = (name) => {
    return selected.indexOf(name) !== -1;
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  useEffect(() => {
    const data = apiList.find((item) => item?.id === selectedAppId);
    setSelectedData(data);
  }, [selectedAppId, apiList]);

  const handleProductChangePage = (event, newPage) => {
    setProductPage(newPage);
  };

  const handleProductChangeRowsPerPage = (event) => {
    setProductLimit(parseInt(event.target.value, 10));
  };

  const handleKYCChangePage = (event, newPage) => {
    setKYCPage(newPage);
  };

  const handleKYCChangeRowsPerPage = (event) => {
    setKYCLimit(parseInt(event.target.value, 10));
  };

  const handleReject = () => {
    getAxiosInstance(
      `adminOrchestrator/businessPartner/reviewBusinessPartner`,
      'post',
      {
        id: selectedAppId,
        reviewStatus: 'KYC_REJECTED',
      },
      { 'journey-type': 'Business-Partner-Dashboard' }
    )
      .then((res) => {
        if (res?.data?.codes === 200) {
          toast.success('App rejected successfully');
          handleManageModel();
          fetchKYCApproval();
        }
      })
      .catch((err) => {
        toast.error('Failed to reject app');
      });
  };

  const handleProductReject = () => {
    getAxiosInstance(
      `adminOrchestrator/businessPartner/reviewProductEntitlement`,
      'post',
      {
        productEntitlementId: selectedAppId,
        status: 'REJECTED',
      },
      { 'journey-type': 'Business-Partner-Dashboard' }
    )
      .then((res) => {
        if (res?.data?.codes === 200) {
          toast.success('Product rejected successfully');
          setIsProductOpen(!isProductOpen);
          fetchProductApproval();
        }
      })
      .catch((err) => {
        toast.error('Failed to reject Product');
      });
  };

  const handleApprove = () => {
    getAxiosInstance(
      `adminOrchestrator/businessPartner/reviewBusinessPartner`,
      'post',
      {
        id: selectedAppId,
        reviewStatus: 'KYC_ACCEPTED',
      },
      { 'journey-type': 'Business-Partner-Dashboard' }
    )
      .then((res) => {
        if (res?.data?.codes === 200) {
          toast.success('App approved successfully');
          handleManageModel();
          fetchKYCApproval();
          handleClose();
        }
      })
      .catch((err) => {
        toast.error('Failed to approve app');
      });
  };

  const handleProductApprove = () => {
    getAxiosInstance(
      `adminOrchestrator/businessPartner/reviewProductEntitlement`,
      'post',
      {
        productEntitlementId: selectedAppId,
        status: 'APPROVED',
      },
      { 'journey-type': 'Business-Partner-Dashboard' }
    )
      .then((res) => {
        if (res?.data?.codes === 200) {
          toast.success('Product approved successfully');
          setIsProductOpen(!isProductOpen);
          fetchProductApproval();
        }
      })
      .catch((err) => {
        toast.error('Failed to approve Product');
      });
  };

  return (
    <Box mt={3}>
      {/* Business Partner KYC Approval List */}
      <Stack
        direction={'row'}
        flexWrap={'wrap'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography variant="h3">
          API Business Partner KYC Approval List
        </Typography>
      </Stack>
      <Card sx={{ mt: 2 }}>
        <>
          {/* Table for KYC Approvals */}
          <TableContainer>
            <Table
              sx={{
                minWidth: 750,
                backgroundColor: 'white',
              }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={() => {}}
                onRequestSort={handleRequestSort}
                rowCount={total}
              />
              <TableBody>
                {apiList.length ? (
                  apiList.map((row, index) => {
                    if (typeof row === 'number') return null;
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        selected={isItemSelected}
                      >
                        <StyledTableCell id={labelId}>
                          {KYCPage === 0
                            ? index + 1
                            : KYCPage * KYCLimit + (index + 1)}
                        </StyledTableCell>
                        <StyledTableCell>{row?.companyName}</StyledTableCell>
                        <StyledTableCell>
                          {moment(row?.createdAt).format('MMM DD, YYYY')}
                        </StyledTableCell>
                        <StyledTableCell>
                          <ReviewButton
                            variant="contained"
                            size="small"
                            fullWidth
                            onClick={() => {
                              setSelectedAppId(row?.id);
                              handleManageModel();
                            }}
                            endIcon={
                              <Box
                                component={'img'}
                                src={InfoImg}
                                sx={{ height: 24 }}
                              />
                            }
                          >
                            Review
                          </ReviewButton>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={KYCLimit}
            page={KYCPage}
            onPageChange={handleKYCChangePage}
            onRowsPerPageChange={handleKYCChangeRowsPerPage}
          />
        </>
      </Card>

      {/* Business Partner Product Approval List */}
      <Stack
        direction={'row'}
        flexWrap={'wrap'}
        justifyContent={'space-between'}
        alignItems={'center'}
        mt={3}
      >
        <Typography variant="h3">
          API Business Partner Product Approval List
        </Typography>
      </Stack>
      <Card sx={{ mt: 2 }}>
        <>
          {/* Table for Product Approvals */}
          <TableContainer>
            <Table
              sx={{
                minWidth: 750,
                backgroundColor: 'white',
              }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={() => {}}
                onRequestSort={handleRequestSort}
                rowCount={productTotal}
              />
              <TableBody>
                {productList.length ? (
                  productList.map((row, index) => {
                    if (typeof row === 'number') return null;
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        selected={isItemSelected}
                      >
                        <StyledTableCell id={labelId}>
                          {ProductPage === 0
                            ? index + 1
                            : ProductPage * ProductLimit + (index + 1)}
                        </StyledTableCell>

                        <StyledTableCell>
                          {row?.business?.companyName}
                        </StyledTableCell>
                        <StyledTableCell>
                          {moment(row?.createdAt).format('MMM DD, YYYY')}
                        </StyledTableCell>
                        <StyledTableCell>
                          <ReviewButton
                            variant="contained"
                            size="small"
                            fullWidth
                            onClick={() => {
                              setSelectedAppId(row?.id);
                              setSelectedProduct(row?.product?.name);
                              setIsProductOpen(!isProductOpen);
                            }}
                            endIcon={
                              <Box
                                component={'img'}
                                src={InfoImg}
                                sx={{ height: 24 }}
                              />
                            }
                          >
                            Review
                          </ReviewButton>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={productTotal}
            rowsPerPage={ProductLimit}
            page={ProductPage}
            onPageChange={handleProductChangePage}
            onRowsPerPageChange={handleProductChangeRowsPerPage}
          />
        </>
      </Card>

      {/* Dialog for Approving/Rejecting Business Partner KYC */}
      <Dialog
        open={isOpen}
        onClose={handleClose}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '580px',
            },
          },
        }}
      >
        <Stack px={2} my={2}>
          <Typography variant="h4" fontWeight={500}>
            Do you want to approve this Business Partner?
          </Typography>
          <IconButton
            sx={{ position: 'absolute', right: 5, top: 5 }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TitleTypography>Email:</TitleTypography>
            </Grid>
            <Grid item xs={6}>
              <ContentTypography>
                {selectedData?.emailAddress}
              </ContentTypography>
            </Grid>
            {selectedData?.accountType === 'Individual' ? (
              <>
                <Grid item xs={6}>
                  <TitleTypography>NIN Number:</TitleTypography>
                </Grid>
                <Grid item xs={6}>
                  <ContentTypography>
                    {selectedData?.ninNumber}
                  </ContentTypography>
                </Grid>
                <Grid item xs={6}>
                  <TitleTypography>International Passport:</TitleTypography>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction={'row'} alignItems={'center'} gap={1}>
                    <PdfIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        window.open(selectedData?.identityDocumentUrl)
                      }
                    />
                    <ContentTypography>passport.pdf</ContentTypography>
                  </Stack>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={6}>
                  <TitleTypography>Business Description:</TitleTypography>
                </Grid>
                <Grid item xs={6}>
                  <ContentTypography>
                    {selectedData?.businessDescription}
                  </ContentTypography>
                </Grid>
                <Grid item xs={6}>
                  <TitleTypography>Website:</TitleTypography>
                </Grid>
                <Grid item xs={6}>
                  <ContentTypography>{selectedData?.website}</ContentTypography>
                </Grid>
                <Grid item xs={6}>
                  <TitleTypography>
                    Certificate of Incorporation:
                  </TitleTypography>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction={'row'} alignItems={'center'} gap={1}>
                    <PdfIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        window.open(selectedData?.certificateOfIncorporationUrl)
                      }
                    />
                    <ContentTypography>
                      Certificate-of-incorporation.pdf
                    </ContentTypography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <TitleTypography>Director Id:</TitleTypography>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction={'row'} alignItems={'center'} gap={1}>
                    <PdfIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => window.open(selectedData?.directorIdUrl)}
                    />
                    <ContentTypography>Director_ID.pdf</ContentTypography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <TitleTypography>CAC Status Report:</TitleTypography>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction={'row'} alignItems={'center'} gap={1}>
                    <PdfIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        window.open(selectedData?.cacStatusReportUrl)
                      }
                    />
                    <ContentTypography>CAC-Status.pdf</ContentTypography>
                  </Stack>
                </Grid>
              </>
            )}
          </Grid>
          <Stack
            direction={'row'}
            justifyContent={'flex-end'}
            alignItems={'flex-end'}
            gap={1.5}
            mt={3}
          >
            <Button variant="contained" color="error" onClick={handleReject}>
              Reject
            </Button>
            <Button variant="contained" color="success" onClick={handleApprove}>
              Approve
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>

      {/* Dialog for Approving/Rejecting Business Partner Product Entitlement */}
      <Dialog
        open={isProductOpen}
        onClose={() => setIsProductOpen(!isProductOpen)}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '580px',
            },
          },
        }}
      >
        <Stack px={2} my={2}>
          <Typography variant="h4" fontWeight={500}>
            Do you want to give access of the following products to this User?
          </Typography>
          <IconButton
            sx={{ position: 'absolute', right: 5, top: 5 }}
            onClick={() => setIsProductOpen(!isProductOpen)}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TitleTypography>{selectedProduct}</TitleTypography>
            </Grid>
          </Grid>
          <Stack
            direction={'row'}
            justifyContent={'flex-end'}
            alignItems={'flex-end'}
            gap={1.5}
            mt={3}
          >
            <Button
              variant="contained"
              color="error"
              onClick={handleProductReject}
            >
              Reject
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={handleProductApprove}
            >
              Approve
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
