import {
  Box,
  Button,
  Card,
  CircularProgress,
  OutlinedInput,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  useTheme,
} from '@mui/material';
import { shouldForwardProp } from '@mui/system';
import { visuallyHidden } from '@mui/utils';
import getAxiosInstance from 'api';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DateRangePicker } from 'rsuite';
import LeftArrow from '../../assets/images/svg/left-arrow.svg';
import toast from 'react-hot-toast';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  border: '1px solid #DEDEDE',
  color: '#212120',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '500',
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(
  ({ theme }) => ({
    width: 350,
    marginLeft: 16,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: '8px',
    border: '1px solid rgba(33, 33, 32, 0.10)',
    background: '#FFFFFF',

    '& input': {
      background: 'transparent !important',
      paddingLeft: '4px !important',
    },
    [theme.breakpoints.down('lg')]: {
      width: 250,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginLeft: 4,
      background:
        theme.palette.mode === 'dark' ? theme.palette.dark[800] : '#fff',
    },
  })
);
const headCells = [
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email Address',
  },
  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'callDate',
    numeric: true,
    disablePadding: false,
    label: 'Call date',
  },
];
const ProductType = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Direct Debit',
    value: 'mandate',
  },
  {
    label: 'BVN',
    value: 'bvn',
  },
  {
    label: 'Credit Risk',
    value: 'consent',
  },
];

function EnhancedTableHead({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const StyledTableCellHead = styled(TableCell)(({ theme }) => ({
    backgroundColor: '#F0F0F0',
    color: 'rgba(33, 33, 32, 0.60)',
    border: '1px solid #DEDEDE',
  }));

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCellHead
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : undefined}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCellHead>
        ))}
      </TableRow>
    </TableHead>
  );
}
const BackButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#fff',
  color: '#000',
  borderRadius: '50%',
  textTransform: 'none',
  fontWeight: 400,
  padding: '4px',
  minWidth: '32px',
  height: '32px',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
}));
const CallsDetails = () => {
  const theme = useTheme();
  const { id } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState('DESC');
  const [orderBy, setOrderBy] = useState('id');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense] = useState(false);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState();
  const [calls, setCalls] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState({
    0: moment().subtract(1, 'months')._d,
    1: moment()._d,
  });
  useEffect(() => {
    fetchData();
  }, [selectedDate, page, limit]);
  const fetchData = () => {
    setLoading(true);
    setCalls([]);
    getAxiosInstance(
      `adminOrchestrator/dashboard/${tableName()?.apiUrl}`,
      'post',
      {
        startDate: moment(selectedDate[0]).format('MM-DD-YYYY'),
        endDate: moment(selectedDate[1]).format('MM-DD-YYYY'),
        page: page + 1,
        limit: limit,
        filter: {
          product: 'all',
          type: id === 'api' ? 'api' : 'online',
        },
      },
      { 'journey-type': 'Dashboard' }
    )
      .then((res) => {
        if (res?.data?.codes === 200) {
          setLoading(false);
          setCalls(res?.data?.data?.items);
          setTotal(res?.data?.data?.total);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };
  const tableName = () => {
    switch (id) {
      case 'api':
        return { title: 'API Calls', apiUrl: 'getActiveProducts' };
      case 'dashboard':
        return { title: 'API Calls', apiUrl: 'getActiveProducts' };
      case 'credit-assessment':
        return {
          title: 'Credit Assessment Calls',
          apiUrl: 'getConsentByDateRange',
        };
      case 'direct-debit':
        return { title: 'Direct Debit Calls', apiUrl: 'getMandateByDateRange' };
      case 'income-verification':
        return {
          title: 'Income Verification Calls',
          apiUrl: 'getIncomeVerificationByDateRange',
        };
      case 'bvn':
        return { title: 'BVN Calls', apiUrl: 'getBVNByDateRange' };
    }
  };
  useEffect(() => {
    const route = tableName();
    if (!route) {
      navigate(-1);
    }
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'ASC';
    setOrder(isAsc ? 'DESC' : 'ASC');
    setOrderBy(property);
  };
  const isSelected = () => {};
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(event.target.value);
  };
  return (
    <>
      <Stack
        direction={'row'}
        flexWrap={'wrap'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Box display="flex" alignItems="baseline">
          <BackButton
            direction="row"
            alignItems="center"
            onClick={() => navigate(-1)}
          >
            <Box component={'img'} src={LeftArrow} />
          </BackButton>
          <Stack mr={4}>
            <Typography variant="h3">{tableName().title}</Typography>
          </Stack>
        </Box>

        <Stack
          direction={'row'}
          flexWrap={'wrap'}
          justifyContent={'space-between'}
          alignItems={'center'}
          gap={2}
        >
          <DateRangePicker
            appearance="default"
            placeholder="Select Date Range"
            value={selectedDate}
            onChange={(value) => {
              setSelectedDate({
                0: value[0],
                1: value[1],
              });
            }}
            showOneCalendar
          />
        </Stack>
      </Stack>

      <Card sx={{ mt: 2 }}>
        {loading ? (
          <Box
            sx={{ display: 'flex', justifyContent: 'center', height: '100px' }}
          >
            <CircularProgress color="secondary" />
          </Box>
        ) : (
          <TableContainer>
            <Table
              sx={{
                minWidth: 750,
                backgroundColor: 'white',
              }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={total}
              />
              <TableBody>
                {calls.length ? (
                  <>
                    {calls.map((row, index) => {
                      return (
                        <TableRow hover tabIndex={-1} key={row.name}>
                          <StyledTableCell component="th" scope="row">
                            {row?.email || row?.userEmail}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row?.name ||
                              row?.userName ||
                              `${row?.firstName} ${row?.lastName}`}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {moment(row?.createdAt).format(
                              `MMM DD, YYYY hh:mm A`
                            )}
                          </StyledTableCell>
                        </TableRow>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <>
          {/* table pagination */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      </Card>
    </>
  );
};

export default CallsDetails;
