import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { debounce } from 'lodash';
import { styled, useTheme } from '@mui/styles';
import { Box } from '@mui/system';
import ChoosePlanIcon from 'common/svg/ChoosePlanIcon';
import { useEffect, useState } from 'react';
import SkeletonPopularCard from 'ui-component/cards/Skeleton/PopularCard';

import CloseIcon from '@mui/icons-material/Close';
import getAxiosInstance from 'api';
import NgCurrencySymbol from 'common/svg/NgCurrencySymbol';
import { toast } from 'react-hot-toast';
const options = [
  { label: 'Flat Fee', id: 'Flat' },
  { label: 'Units', id: 'Wallet' },
];
const ChoosePlan = ({ isOpen, handleClose, selected }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('lg');
  const [planData, setPlanData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [selectedPlanType, setSelectedPlanType] = useState(options[0]);

  //
  const BoxHeaderWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px',
    // background: "#F0F0F0",
    borderRadius: '10px',
    margin: '10px 0 12px 0',
  }));

  const DisabledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
    backgroundColor: '#F0F0F0',
    width: '115px',
    '& input': {
      backgroundColor: '#F0F0F0',
      color: '#767676',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '24px',
      padding: '10px 0',
    },
  }));

  const OutlinedInputSet = styled(OutlinedInput)(({ theme }) => ({
    width: '115px',
    backgroundColor: '#ffffff',

    '& input': {
      backgroundColor: '#ffffff',
      color: '#000000',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '24px',
      padding: '10px 0',
    },
  }));

  const PlanTypeLabel = styled(Typography)(({ theme }) => ({
    color: '#212120',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '28px',
  }));

  const PlanTable = styled(Table)(({ theme }) => ({
    '& tbody .MuiTableCell-root': {
      borderBottom: '1px solid transparent !important',
      padding: '8px 16px',
    },
  }));

  const PlanType = styled(Typography)(({ theme }) => ({
    color: '#212120',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '30px',
  }));

  const PlanTypeWrapper = styled(Box)(({ theme }) => ({
    border: '1px solid  #DEDEDE',
    borderRadius: '10px',
    overflow: 'auto',
  }));

  const CancelButton = styled(Button)(({ theme }) => ({
    background: '#ffffff',
    color: '#000000',
    border: '1px solid #dedede',

    '&:hover': {
      background: '#ffffff',
      color: '#000000',
      border: '1px solid #dedede',
    },
  }));

  const TableCellPlanType = styled(TableCell)(({ theme }) => ({
    color: '#212120',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '28px',
  }));

  const TableCellHeading = styled(TableCell)(({ theme }) => ({
    color: '#212120',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    opacity: '0.6',
  }));
  useEffect(() => {
    setLoading(true);
    if (isOpen) {
      getAxiosInstance(
        `adminOrchestrator/businessPartner/${selected}/pricing`,
        'get',
        {},
        { 'journey-type': 'Business-PartnerDashboard' }
      ).then((res) => {
        const productsList = [];
        if (res?.data?.data?.products?.length) {
          res?.data?.data?.products.map((item) => {
            productsList.push({ ...item, balanceToAdd: 0 });
          });
        }
        const selectedPlanTYpe = options.find(
          (item) => item.id === res?.data?.data?.pricingPlan
        );
        setPlanData(productsList);
        setLoading(false);
      });
    }
  }, [isOpen, selectedPlanType]);
  const handleCheck = (e, value) => {
    let findIndex = planData.findIndex(
      (item) => item.productCode === value?.productCode
    );
    planData.splice(findIndex, 1, {
      ...value,
      isActive: e.target.checked,
    });
    setPlanData([...planData]);
  };
  const handleChanges = (e, value) => {
    let findIndex = planData.findIndex(
      (item) => item.productCode === value?.productCode
    );
    planData.splice(findIndex, 1, {
      ...value,
      price: e.target.value,
    });
    setPlanData([...planData]);
  };
  const handleSubmit = (e) => {
    getAxiosInstance(
      `adminOrchestrator/businessPartner/${selected}/pricing`,
      'post',
      { productPricing: planData, pricingPlan: selectedPlanType.id },
      { 'journey-type': 'Business-PartnerDashboard' }
    ).then((res) => {
      if (selectedPlanType.id === 'Flat') {
        toast.success('Set price successfully.');
      } else {
        toast.success('Save Price successfully.');
      }
      handleClose();
    });
  };
  const handleUnitChanges = (item, value, name) => {
    const updatedUnits = planData.map((unit) =>
      unit.productCode === item.productCode
        ? {
            ...unit,
            [name]: parseInt(value) || 0,
          }
        : unit
    );
    setPlanData(updatedUnits);
  };
  // const debouncedHandleUnitChanges = debounce(handleUnitChanges, 2000);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => handleClose}
        fullWidth={fullScreen}
        maxWidth="lg"
      >
        <DialogTitle>
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Stack direction={'row'} alignItems={'center'} gap={1.5}>
              <Box
                sx={{
                  borderRadius: '10px',
                  background: 'rgba(252, 87, 25, 0.05)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 'fit-content',
                  padding: '14px',
                }}
              >
                <ChoosePlanIcon color={theme.palette.secondary.main} />
              </Box>
              <Typography variant="h2" fontWeight={600}>
                {' '}
                Choose Plan
              </Typography>
            </Stack>

            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent>
          <BoxHeaderWrapper>
            <PlanTypeLabel>Plan Type:</PlanTypeLabel>
            <PlanType>
              <Autocomplete
                options={options}
                id="combo-box-demo"
                sx={{ width: 150 }}
                // value={{ label: "Flat Fee", id: 1 }}
                value={selectedPlanType}
                renderInput={(params) => <TextField {...params} />}
                onChange={(e, newValue) => setSelectedPlanType(newValue)}
              />
            </PlanType>
          </BoxHeaderWrapper>
          {selectedPlanType?.id === 'Flat' ? (
            <PlanTypeWrapper>
              <PlanTable aria-labelledby="choose plan">
                <TableHead>
                  <TableRow>
                    <TableCell />

                    <TableCellHeading
                      sx={{
                        width: '250px',
                      }}
                    >
                      Product Name
                    </TableCellHeading>
                    <TableCellHeading>Current Price</TableCellHeading>
                    <TableCellHeading>New Set Price</TableCellHeading>
                  </TableRow>
                </TableHead>
                {!isLoading ? (
                  <TableBody>
                    {planData?.length ? (
                      <>
                        {planData?.map((item) => (
                          <TableRow>
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={item?.isActive}
                                onClick={(e) => handleCheck(e, item)}
                              />
                            </TableCell>
                            <TableCellPlanType>
                              {item?.productName}
                            </TableCellPlanType>
                            <TableCell>
                              <DisabledOutlinedInput
                                startAdornment={
                                  <InputAdornment position="start">
                                    <NgCurrencySymbol />
                                    <Divider
                                      sx={{
                                        height: 20,
                                        m: 1,
                                        borderColor: '#dedede',
                                      }}
                                      orientation="vertical"
                                    />
                                  </InputAdornment>
                                }
                                disabled
                                value={item?.price}
                              />
                            </TableCell>
                            <TableCell>
                              <OutlinedInputSet
                                startAdornment={
                                  <InputAdornment position="start">
                                    <NgCurrencySymbol />
                                    <Divider
                                      sx={{ height: 28, m: 1 }}
                                      orientation="vertical"
                                    />
                                  </InputAdornment>
                                }
                                disabled={!item?.isActive}
                                value={item?.price}
                                onChange={(e) => handleChanges(e, item)}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </TableBody>
                ) : (
                  <SkeletonPopularCard />
                )}
              </PlanTable>
            </PlanTypeWrapper>
          ) : (
            <PlanTypeWrapper>
              <PlanTable aria-labelledby="choose plan">
                <TableHead>
                  <TableRow>
                    {/* <TableCell /> */}

                    <TableCellHeading
                      sx={{
                        width: '250px',
                      }}
                    >
                      Product Name
                    </TableCellHeading>
                    <TableCellHeading>Available Unit</TableCellHeading>
                    <TableCellHeading>Price Per Unit</TableCellHeading>
                    <TableCellHeading>Quantity</TableCellHeading>
                    <TableCellHeading>Total Amount</TableCellHeading>
                  </TableRow>
                </TableHead>
                {!isLoading ? (
                  <TableBody>
                    {planData.length ? (
                      <>
                        {planData.map((item) => (
                          <TableRow>
                            <TableCellPlanType>
                              {item?.productName}
                            </TableCellPlanType>
                            <TableCellPlanType align="center">
                              {' '}
                              {item?.walletBalance}
                            </TableCellPlanType>
                            <TableCell>
                              <OutlinedInputSet
                                startAdornment={
                                  <InputAdornment position="start">
                                    <NgCurrencySymbol />
                                    <Divider
                                      sx={{
                                        height: 20,
                                        m: 1,
                                        borderColor: '#dedede',
                                      }}
                                      orientation="vertical"
                                    />
                                  </InputAdornment>
                                }
                                value={item?.price}
                                onChange={(e) =>
                                  handleUnitChanges(
                                    item,
                                    e.target.value,
                                    'price'
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <OutlinedInputSet
                                key={item.productCode} // Ensure each input has a stable key
                                startAdornment={
                                  <InputAdornment position="start"></InputAdornment>
                                }
                                value={item.balanceToAdd} // Controlled component
                                onChange={(e) =>
                                  handleUnitChanges(
                                    item,
                                    e.target.value,
                                    'balanceToAdd'
                                  )
                                }
                              />
                            </TableCell>
                            <TableCellPlanType align="center">
                              {' '}
                              <NgCurrencySymbol />
                              {item?.price * item?.balanceToAdd || 0}
                            </TableCellPlanType>
                          </TableRow>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </TableBody>
                ) : (
                  <SkeletonPopularCard />
                )}
              </PlanTable>
            </PlanTypeWrapper>
          )}

          <Stack
            direction={'row'}
            justifyContent={'flex-end'}
            alignItems={'center'}
            gap={1.5}
            mt={3.5}
          >
            <CancelButton variant="contained" onClick={handleClose}>
              {' '}
              Cancel
            </CancelButton>

            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
            >
              {selectedPlanType?.id === 'Flat' ? 'Set Price' : 'Save'}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};;;

export default ChoosePlan;
