import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import { Navigate } from 'react-router-dom';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import ApiBusinessPartner from 'views/api-business-partner';
import ApproveApp from 'views/api-business-partner/appApprove';
import Calls from 'views/calls';
import ApproveBusinessApp from 'views/dashboard/Business/approveBusinessApp';
import SampleResponse from 'views/SampleResponse';
import SettlementAccount from 'views/settlement-account';
import UsersDetails from 'views/usersDetails';
import CallsDetails from 'views/callsDetails';

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import('views/dashboard/Default'))
);
const DashboardAnalytics = Loadable(
  lazy(() => import('views/dashboard/Analytics'))
);
const DashboardBusiness = Loadable(
  lazy(() => import('views/dashboard/Business'))
);
const Reports = Loadable(lazy(() => import('views/reports')));

const TestBanks = Loadable(lazy(() => import('views/testBanks')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/dashboard',
      element: <DashboardDefault />,
    },
    {
      path: '/customer-dashboard',
      element: <DashboardAnalytics />,
    },
    {
      path: '/business-dashboard',
      element: <DashboardBusiness />,
    },
    {
      path: '/business-dashboard/app-approve',
      element: <ApproveBusinessApp />,
    },
    {
      path: '/reports',
      element: <Reports />,
    },
    {
      path: '/api-business-partner',
      element: <ApiBusinessPartner />,
    },
    {
      path: '/api-business-partner/app-approve',
      element: <ApproveApp />,
    },

    {
      path: '/sample-bank-response',
      element: <SampleResponse />,
    },
    {
      path: '/test-banks',
      element: <TestBanks />,
    },
    {
      path: '/settlement-account',
      element: <SettlementAccount />,
    },
    {
      path: '/calls',
      element: <Calls />,
    },
    {
      path: '/calls/:id',
      element: <CallsDetails />,
    },
    {
      path: '/user-details/:id',
      element: <UsersDetails />,
    },
    { path: '*', element: <Navigate to="/dashboard" replace /> },
  ],
};

export default MainRoutes;
